/* @flow */

import type { QuoteItem as TQuoteItem } from "../../types/quote.flow";
import type { Order } from "../../types/order.flow";

import React, { useState, useEffect, useContext } from "react";
import { useData, useSendMessage } from "crustate/react";
import { QuoteData } from "../../data";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { useClient } from "../../entrypoint/shared";
import cn from "classnames";
import { useTranslate } from "@out-of-home/use-translate";
import { useFormat } from "../../helpers/use-format";
import { Wrapper } from "../Wrapper";
import { Spinner } from "../Spinner";
import { Button } from "../Button";
import { ProductCard } from "../ProductCard";
import { Map } from "../Map";
import { QuoteItem } from "../QuoteItem";
import breakpoints from "../../helpers/breakpoints";
import OKIcon from "../../icons/checkmark.svg";
import DownloadIcon from "../../icons/download.svg";
import CloseIcon from "../../icons/close.svg";
import ExclamationIcon from "../../icons/exclamation.svg";
import ShippingIcon from "../../icons/shipping.svg";
import PackedIcon from "../../icons/list.svg";
import ArrivedIcon from "../../icons/arrow.svg";
import { addMessage } from "../../state/messages";
import styles from "./styles.scss";
import {
  orderShippingStatus,
  orderInvoiceStatus,
} from "../../helpers/orderHelpers";
import { restProductToGQLProduct } from "../../helpers";
import { UIContext } from "../../context/ui";
import { setItemQty } from "../../state/quote-items";
import { useOverdueInvoices } from "../../helpers/use-overdue-invoices";
import { customerOrder } from "../../queries";
import { AnalyticsContext } from "../../helpers/analytics/context";

const isSalable = (item) => item.product.oohStock?.qty;

const renderShipmentStatus = ({ shipmentStatus, addimotionBox = false }, t) => {
  if (addimotionBox) {
    // Valid statuses: Packed, En route, Arrived, Delivered
    return (
      <div className={styles.status}>
        {addimotionBox.SStatusTxt === "Delivered" && (
          <span className={(styles.iconWrapper, styles.iconWrapper__success)}>
            <OKIcon className={styles.icon} />
          </span>
        )}
        {addimotionBox.SStatusTxt === "Arrived" && (
          <span className={cn(styles.iconWrapper, styles.iconWrapper__success)}>
            <ArrivedIcon className={styles.icon} />
          </span>
        )}
        {addimotionBox.SStatusTxt === "En route" && (
          <span className={cn(styles.iconWrapper, styles.iconWrapper__success)}>
            <ShippingIcon className={styles.icon} />
          </span>
        )}
        {addimotionBox.SStatusTxt === "Packed" && (
          <span className={cn(styles.iconWrapper, styles.iconWrapper__success)}>
            <PackedIcon className={styles.icon} />
          </span>
        )}
        {["Delivered", "Arrived", "En route", "Packed"].includes(
          addimotionBox.SStatusTxt
        ) === -1 && (
          <span className={cn(styles.iconWrapper, styles.iconWrapper__error)}>
            <ExclamationIcon className={styles.icon} />
          </span>
        )}

        <span className={styles.statusText}>
          {t("ADDIMOTION." + addimotionBox.SStatusTxt)}
        </span>
      </div>
    );
  }

  if (shipmentStatus) {
    return (
      <div className={styles.status}>
        {["DELIVERED", "PICKED", "SHIPPED"].indexOf(shipmentStatus) !== -1 && (
          <span className={cn(styles.iconWrapper, styles.iconWrapper__success)}>
            <OKIcon className={styles.icon} />
          </span>
        )}
        {["DELAYED", "CANCELED", "BLOCKED"].indexOf(shipmentStatus) !== -1 && (
          <span className={cn(styles.iconWrapper, styles.iconWrapper__error)}>
            <ExclamationIcon className={styles.icon} />
          </span>
        )}
        {[
          "DELAYED",
          "PICKED",
          "DELIVERED",
          "SHIPPED",
          "CANCELED",
          "BLOCKED",
        ].indexOf(shipmentStatus) === -1 && (
          <span className={cn(styles.iconWrapper, styles.iconWrapper__warning)}>
            <ExclamationIcon className={styles.icon} />
          </span>
        )}

        <span className={styles.statusText}>
          {t("SHIPMENT." + shipmentStatus)}
        </span>
      </div>
    );
  }

  return <div className={cn(styles.orderColumn, styles.shipment)}>&mdash;</div>;
};

export const OrderHistoryDetailView = ({
  orderId,
}: {
  orderId: string,
}): React$Node => {
  const t = useTranslate();
  const client = useClient();
  const sendMessage = useSendMessage();
  const { formatPrice, formatDateLong } = useFormat();
  const { browserWidth } = useContext(UIContext);
  const isSmall = browserWidth < breakpoints.small;
  const [order, setOrder] = useState<Order | null>(null);
  const [loading, setLoading] = useState(true);
  const quoteData = useData(QuoteData);
  const cartLoading = quoteData.state !== "LOADED";
  const { gotOverdueInvoice } = useOverdueInvoices();
  const { registerModifyCart } = useContext(AnalyticsContext);

  const addOrderToCart = () => {
    if (quoteData.state === "LOADED" && order !== null) {
      const items = order.items.filter((i) => isSalable(i)) || [];

      if (items.length < (order.items.length || 0)) {
        sendMessage(addMessage(t("ORDER.ITEMS_NOT_MATCHING"), "error"));
      }

      for (const item of items) {
        // @TODO: Maybe Martin will add support for adding multiple items at once.
        // Otherwise we might have to do one request per item to the new API to get the buyrequsts
        if (item.product.buyRequest) {
          sendMessage(setItemQty(item.product.buyRequest, item.qty));
          let qty = item.qty;
          const quoteItem = quoteData.data.items.find(x => x.product.buyRequest === item.product.buyRequest);

          if (quoteItem) {
            qty -= quoteItem.qty;
          }

          registerModifyCart([
            {
              product: {
                ...item.product,
                qty: Math.abs(qty),
              }
            }
          ],
            qty > 0 ? "add_to_cart" : "remove_from_cart",
            "order_history"
          );
        }
      }
    }
  };

  useEffect(() => {
    client(customerOrder, { id: orderId })
      .then((x) => {
        setOrder(x.orderById);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading || !order) {
    return null;
  }

  const shippingAddress = order.addresses.find((x) => x.type === "shipping");

  if (!shippingAddress) {
    return null;
  }

  const { vismaInvoiceId } = order;
  const invoiceStatus = orderInvoiceStatus(order);
  const shipmentStatus = orderShippingStatus(order, gotOverdueInvoice);
  const orderLocation = [
    shippingAddress.street[0],
    shippingAddress.city,
    shippingAddress.postcode,
  ];
  const quoteHasSalableItems = order.items.some((i) => isSalable(i));
  // @TODO: fix addimotion?
  // const addimotionBox = addimotion && addimotion.YourBoxes ? addimotion.YourBoxes[0] : null;

  const renderShippingMethod = () => {
    if (!order.shipping) {
      return null;
    }

    // @TODO: make sure this logic is correct
    if (order.shipping !== null) {
      return (
        <tr>
          <td>{t("ORDER.SHIPPING")}</td>
          <td>{formatPrice(order.shipping.total.exVat)}</td>
        </tr>
      );
    }

    if (order.shipping.method.code === "freeshipping_freeshipping") {
      return (
        <tr>
          <td>{t("SHIPPING_METHOD." + order.shipping.method.code)}</td>
          <td></td>
        </tr>
      );
    }

    if (order.shipping.method.code !== "freeshipping_freeshipping") {
      return (
        <tr>
          <td>{t("SHIPPING_METHOD." + order.shipping.method.code)}</td>
          <td>{formatPrice(order.shipping.total.exVat)}</td>
        </tr>
      );
    }
  };

  const renderSummary = (orderLocation: Array<string>) => {
    // dont render anything if the order is empty
    if (!order.items.length) {
      return (
        <div className={styles.boxBody}>
          {isSmall && (
            <Map
              address={orderLocation}
              className={styles.mapNode}
              identifier={order.id}
            />
          )}
        </div>
      );
    }

    return (
      <div className={styles.boxBody}>
        {isSmall && (
          <Map
            address={orderLocation}
            className={styles.mapNode}
            identifier={order.id}
          />
        )}
        <div>
          <h3>{t("ORDER.ORDER_INFO")}</h3>
          <table className={styles.summary}>
            <tbody>
              {order.deposit > 0 && (
                <tr>
                  <td>{t("ORDER.PANT")}</td>
                  <td>{formatPrice(order.deposit || 0)}</td>
                </tr>
              )}
              <tr>
                <td>{t("ORDER.SUBTOTAL")}</td>
                <td>{formatPrice(order.subTotal.exVat)}</td>
              </tr>
              {renderShippingMethod()}
            </tbody>
          </table>
        </div>
        <table className={styles.grandTotal}>
          <tbody>
            <tr>
              <td>{t("ORDER.TOTAL_EXCL_TAX")}</td>
              <td>{formatPrice(order.grandTotal.exVat)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div>
      <Wrapper
        className={cn(
          styles.block,
          styles["block__invoice_" + (invoiceStatus || "")],
          styles["block__shipment_" + (shipmentStatus || "")]
        )}
      >
        <Helmet title={t("ORDER.TITLE")} />

        <div className={styles.top}>
          <div>
            <h2 className={styles.date}>
              {formatDateLong(new Date(order.createdAt))}
            </h2>
            <h1 className={styles.id}>{order.id}</h1>
          </div>
          {isSmall && (
            <Link className={styles.back} to={"/" + t("ROUTES.ORDER_HISTORY")}>
              <CloseIcon className={styles.icon} />
            </Link>
          )}
        </div>

        <div className={styles.stretch}>
          <section className={styles.container}>
            <div className={styles.shipping}>
              <div className={styles.box}>
                <h5 className={styles.boxHeading}>{t("ORDER.SHIPPING")}</h5>

                <div className={styles.boxHeader}>
                  <div className={styles.shipmentStatus}>
                    {renderShipmentStatus({ shipmentStatus }, t)}
                  </div>

                  <div className={styles.trackingUrls}>
                    {order.trackingUrls.map((track, i) => (
                      <div key={track.code}>
                        <a
                          href={track.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button variant="ghost small">
                            {order.trackingUrls.length > 1
                              ? t("ORDER.TRACK", { index: "#" + (i + 1) })
                              : t("ORDER.TRACK", { index: "" })}
                          </Button>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.boxBody}>
                  {!isSmall && (
                    <Map
                      address={orderLocation}
                      className={styles.mapNode}
                      identifier={order.id}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.billing}>
              <div className={styles.box}>
                <h5 className={styles.boxHeading}>{t("ORDER.INVOICE")}</h5>

                <div className={styles.boxHeader}>
                  {invoiceStatus ? (
                    <div className={styles.status}>
                      {invoiceStatus === "PAID" && (
                        <span
                          className={cn(
                            styles.iconWrapper,
                            styles.iconWrapper__success
                          )}
                        >
                          <OKIcon className={styles.icon} />
                        </span>
                      )}
                      {invoiceStatus === "INVOICED" && (
                        <span
                          className={cn(
                            styles.iconWrapper,
                            styles.iconWrapper__warning
                          )}
                        >
                          <ExclamationIcon className={styles.icon} />
                        </span>
                      )}
                      {(invoiceStatus === "OVERDUE" ||
                        invoiceStatus === "CANCELED") && (
                        <span
                          className={cn(
                            styles.iconWrapper,
                            styles.iconWrapper__error
                          )}
                        >
                          <ExclamationIcon className={styles.icon} />
                        </span>
                      )}

                      <span className={styles.statusText}>
                        {order.payment.code === "Crossroads_Collector" &&
                        invoiceStatus === "PAID"
                          ? t("VISMA.PAID_WITH_COLLECTOR")
                          : t("VISMA." + invoiceStatus)}
                      </span>
                    </div>
                  ) : (
                    <div className={cn(styles.orderColumn, styles.invoice)}>
                      &mdash;
                    </div>
                  )}

                  <div>
                    {vismaInvoiceId &&
                      (order.payment.code === "CrossInvoice" ||
                        order.payment.code === "Crossroads_Stripe_PaymentIntents") && (
                        <Button
                          variant="ghost small"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`/api/vismainvoice/getpdf?visma_invoice_id=${vismaInvoiceId}`}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <DownloadIcon className={styles.downloadIcon} />
                          {order.payment.code === "Crossroads_Stripe_PaymentIntents"
                            ? t("ORDER.DOWNLOAD_STRIPE")
                            : t("ORDER.DOWNLOAD")}
                        </Button>
                      )}
                  </div>
                </div>

                {!isSmall && renderSummary(orderLocation)}
              </div>
            </div>
          </section>
        </div>

        {isSmall && renderSummary(orderLocation)}

        <section className={styles.actions}>
          {!isSmall && (
            <div className={styles.actions__column}>
              <h1 className={styles.id}>{t("ORDER.ORDERED_PRODUCTS")}</h1>
            </div>
          )}
          <div className={styles.actions__column}>
            <Button
              variant="small stretch primary"
              loading={cartLoading}
              disabled={!quoteHasSalableItems}
              onClick={addOrderToCart}
            >
              {t("ORDER.REORDER")}
            </Button>
          </div>
          <div className={styles.actions__column}>
            <Button
              variant="small stretch ghost"
              target="_blank"
              href={`/api/order/getexcel?increment_id=${order.id}`}
            >
              {t("ORDER.DOWNLOAD_XLSX")}
            </Button>
          </div>
        </section>

        <table className={styles.items}>
          <tbody>
            {order.items.map((x) => (
              <QuoteItem
                split={isSmall}
                key={x.product.sku}
                item={x}
                qty={x.qty}
                listName="order_history"
                nameAsLink={true}
              />
            ))}
          </tbody>
        </table>
      </Wrapper>
    </div>
  );
};

/* @flow */

import type {
  ProductCardProduct,
  ProductImage,
  Gallery,
} from "../../types/product.flow";

type Location = {
  +state?: {
    [key: string]: any,
  },
};

import React, { memo, useState } from "react";
import cn from "classnames";
import { useTranslate } from "@out-of-home/use-translate";
import { useDevice } from "../../helpers";
import { useBrowser } from "../../helpers/use-browser";
import { MediaImage } from "./media-image";
import { Button } from "../Button";
import styles from "./styles.scss";

/* @flow */

type Props = {
  location: Location,
  alt?: string,
  title?: string,
  gallery?: Gallery,
  image: ProductImage,
  CloseIcon?: React$ComponentType<any>,
  galleryPerRow?: 1 | 2 | 3 | 4 | 5,
  className?: string,
  children?: React$Node,
};

const sortGalleryByImage = (gallery: Gallery, src: string) => {
  return gallery;
  /*
  let first = gallery[0];

  // Find image in gallery by src
  for (const i of gallery) {
    if (Object.values(i).some((x: any) => x.x1 && x.x1.includes(src))) {
      first = i;

      break;
    }
  }

  // Return new array with image first
  return [first, ...gallery.filter(i => i !== first)];
  */
};

export const Media = ({
  children,
  location,
  alt,
  gallery,
  image,
  galleryPerRow,
  CloseIcon,
  className,
  ...props
}: Props): React$Node => {
  const [currentImage, setCurrentImage] = useState(image);
  const sortedGallery = React.useMemo(
    () =>
      Array.isArray(gallery) && gallery.length > 1
        ? sortGalleryByImage(
            gallery,
            currentImage.smallImage
              ? currentImage.smallImage.x2.split("/").pop()
              : ""
          )
        : [],
    [currentImage]
  );

  return (
    <>
      <MediaImage
        {...props}
        gallery={gallery ? sortedGallery : undefined}
        alt={alt}
        location={location}
        currentImage={currentImage}
        setCurrentImage={setCurrentImage}
        galleryPerRow={galleryPerRow}
        className={className}
      >
        {children}
      </MediaImage>
    </>
  );
};

/* @flow */

import { shippingMethods } from "../queries";

type DeliverySchedule = $ReadOnlyArray<boolean>;

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

type Data =
  | { state: "PENDING", data: null }
  | { state: "LOADING", data: null, postcode: string, shippingMethod: string }
  | {
      state: "LOADED",
      data: DeliverySchedule,
      postcode: string,
      shippingMethod: string,
    }
  | {
      state: "ERROR",
      data: null,
      error: string,
      postcode: string,
      shippingMethod: string,
    };

export type DeliveryScheduleEffectResponse = Response<
  typeof DELIVERY_SCHEDULE_EFFECT_LOAD_RESPONSE,
  { data: DeliverySchedule, postcode: string, shippingMethod: string }
>;

export type DeliveryScheduleEffectRequest = {
  tag: typeof DELIVERY_SCHEDULE_EFFECT_LOAD_REQUEST,
  postcode: string,
  shippingMethod: string,
};

type DeliveryScheduleRequest = {
  tag: typeof DELIVERY_SCHEDULE_LOAD_REQUEST,
  postcode: string,
  shippingMethod: string,
};

export const DELIVERY_SCHEDULE_EFFECT_LOAD_RESPONSE: "delivery_schedule/effect/load/response" =
  "delivery_schedule/effect/load/response";
export const DELIVERY_SCHEDULE_EFFECT_LOAD_REQUEST: "delivery_schedule/effect/load/request" =
  "delivery_schedule/effect/load/request";
export const DELIVERY_SCHEDULE_LOAD_REQUEST: "delivery_schedule/load/request" =
  "delivery_schedule/load/request";

export const loadDeliverySchedule = (
  postcode: string,
  shippingMethod: string
): DeliveryScheduleRequest => ({
  tag: DELIVERY_SCHEDULE_LOAD_REQUEST,
  postcode,
  shippingMethod,
});

export const DeliveryScheduleModel: Model<
  Data,
  {},
  | DeliveryScheduleRequest
  | DeliveryScheduleEffectRequest
  | DeliveryScheduleEffectResponse
> = {
  id: "delivery_schedule",
  init: () => updateData({ state: "PENDING", data: null }),
  update: (state: Data, msg) => {
    switch (msg.tag) {
      case DELIVERY_SCHEDULE_LOAD_REQUEST:
        return updateData(
          {
            state: "LOADING",
            data: null,
            postcode: msg.postcode,
            shippingMethod: msg.shippingMethod,
          },
          {
            tag: DELIVERY_SCHEDULE_EFFECT_LOAD_REQUEST,
            postcode: msg.postcode,
            shippingMethod: msg.shippingMethod,
          }
        );
      case DELIVERY_SCHEDULE_EFFECT_LOAD_RESPONSE:
        if (msg.error) {
          return updateData({
            state: "ERROR",
            data: null,
            error: msg.error,
            postcode: typeof state.postcode === "string" ? state.postcode : "",
            shippingMethod:
              typeof state.shippingMethod === "string"
                ? state.shippingMethod
                : "",
          });
        }

        if (msg.data) {
          return updateData({
            state: "LOADED",
            data: msg.data.data,
            postcode: msg.data.postcode,
            shippingMethod: msg.data.shippingMethod,
          });
        }

        break;
      default:
    }
  },
};

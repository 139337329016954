/* @flow */

import React from "react";
import { Helmet } from "react-helmet-async";
import { Route } from "react-router";
import styles from "./styles.scss";
import { useTranslate } from "@out-of-home/use-translate";
import { Dashboard } from "./Dashboard";
import { EditUser } from "./EditUser";
import { EditOrgNumber } from "./EditOrgNumber";
import { EditOrderCopyEmail } from "./EditOrderCopyEmail";
import { EditCustomerType } from "./EditCustomerType";
import { EditAddress } from "./EditAddress";
import { AlcoholLicenseDetailView } from "./AlcoholLicenseDetailView";

export const AccountView = (): React$Node => {
  const t = useTranslate();
  return (
    <div className={styles.className}>
      <Route path={`/${t("ROUTES.ACCOUNT")}`} exact component={Dashboard} />
      <Route
        path={`/${t("ROUTES.ACCOUNT")}/${t("ROUTES.USER_DATA")}`}
        exact
        component={EditUser}
      />
      <Route
        path={`/${t("ROUTES.ACCOUNT")}/${t("ROUTES.ORG_NUMBER")}`}
        exact
        component={EditOrgNumber}
      />
      <Route
        path={`/${t("ROUTES.ACCOUNT")}/${t("ROUTES.ORDER_COPY_EMAIL")}`}
        exact
        component={EditOrderCopyEmail}
      />
      <Route
        path={`/${t("ROUTES.ACCOUNT")}/${t("ROUTES.CUSTOMER_TYPE")}`}
        exact
        component={EditCustomerType}
      />
      <Route
        path={`/${t("ROUTES.ACCOUNT")}/${t("ROUTES.ADDRESS")}`}
        exact
        component={EditAddress}
      />
      <Route
        path={`/${t("ROUTES.ACCOUNT")}/${t("ROUTES.ADDRESS")}/:id`}
        exact
        component={EditAddress}
      />
      <Route
        path={`/${t("ROUTES.ACCOUNT")}/${t("ROUTES.ALCOHOL_LICENSE")}/:id`}
        exact
        component={AlcoholLicenseDetailView}
      />

      <Helmet title={t("ACCOUNT.HEADING")} />
    </div>
  );
};

/* @flow */

import type { TFacet } from "../../types/filter.flow";

import React, { useRef, useContext, useState } from "react";
import { useBrowserLayoutEffect } from "../../helpers";
import { useData } from "crustate/react";
import cn from "classnames";
import { Wrapper } from "../Wrapper";
import { Link } from "react-router-dom";
import ArrowIcon from "../../icons/arrow.svg";
import { useTranslate } from "@out-of-home/use-translate";
import styles from "./styles.scss";
import { useCustomer } from "../../helpers/use-customer";
import { queryToLocation } from "../../helpers/filterHelpers";
import { UIContext } from "../../context/ui";
import { QuoteData } from "../../data";

type Props = {
  facet: ?TFacet,
  filterToggleURL: (facet: string, bucket: string) => string,
};

const scrollToTop = () => {
  window.scroll({
    top: 0,
    behavior: "smooth",
  });
};

const MIN = 900;
const FOOTER_HEIGHT = 2050;

export const ToTop = ({ facet, filterToggleURL }: Props): React$Node => {
  const [visible, setVisible] = useState(false);
  const t = useTranslate();
  const { browserWidth, browserHeight, headerVisible } = useContext(UIContext);
  const menuElement = useRef();
  const { loggedIn } = useCustomer();
  const quoteData = useData(QuoteData);
  const cartEmpty =
    quoteData.state !== "LOADED" || quoteData.data.items.length === 0;
  const { headerHeight } = useContext(UIContext);

  useBrowserLayoutEffect(() => {
    let raf;
    const scrollEv = window.addEventListener("scroll", (e) => {
      raf = requestAnimationFrame(() => {
        const st = window.scrollY;
        const sb = st + browserHeight;
        const min = MIN + browserHeight;
        const offsetHeight = document.body ? document.body.offsetHeight : 0;

        if (st < min || sb > offsetHeight - FOOTER_HEIGHT) {
          setVisible(false);
        } else if (st > min && sb < offsetHeight - FOOTER_HEIGHT) {
          setVisible(true);
        }
      });
    });

    return () => {
      window.removeEventListener("scroll", scrollEv);
      cancelAnimationFrame(raf);
    };
  }, []);

  return (
    <div className={cn("no-underline", styles.toTop)} style={{ "--header-height": `${headerHeight}px`}}>
      {facet && (
        <div
          ref={menuElement}
          className={cn(
            styles.menu,
            { [styles.menu__pushed]: headerVisible },
            { [styles.menu__loggedIn]: loggedIn },
            { [styles.menu__visible]: visible }
          )}
        >
          <strong className={styles.menuHeading}>{facet.title}</strong>

          {facet.buckets.map((bucket) => (
            <Link
              key={facet.key + bucket.key}
              className={styles.menuLink}
              to={filterToggleURL(facet.key, bucket.key)}
            >
              <span>{bucket.key}</span>
              <span className={styles.menuCount}>({bucket.count})</span>
            </Link>
          ))}
        </div>
      )}
      <div
        onClick={scrollToTop}
        className={cn(
          styles.block,
          { [styles.block__visible]: visible },
          { [styles.block__cartNotEmpty]: !cartEmpty }
        )}
      >
        <div className={styles.button}>
          <ArrowIcon className={styles.button__icon} />
        </div>
        <div className={styles.block__content}>{t("OTHER.TO_TOP")}</div>
      </div>
    </div>
  );
};

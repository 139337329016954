/* @flow */

import type { Node } from "react";

import type { ProductSubset, GAProduct } from "./types.flow";
import type { Quote, QuoteItem, QuotePayment, QuoteShipping } from "../../types/quote.flow";
import type { Order } from "../../types/order.flow";

import React, { useRef, useEffect, createContext, useLayoutEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import {
  noop,
  callAccounts,
  onlyKeys,
  next,
  productToGAProduct,
  quoteItemToGAProduct,
  ANALYTICS_PROPERTY,
} from "./helpers";
import { CustomerStateChange } from "./customer-state-change";

type PageViewProps = {
  accounts: Array<string>,
  g: (...params: Array<any>) => void,
};

type AnalyticsProviderProps = {
  accounts: Array<string>,
  currencyCode: string,
  timeout?: number,
  margin?: number,
  children: React$Node,
};

type ModifyCartType = "add_to_cart" | "remove_from_cart";

type AnalyticsContextType = {|
  registerListProductClicked: (product: ProductSubset, listName?: string, category?: string, position?: number) => void,
  registerProductDetailsView: (product: ProductSubset) => void,
  registerModifyCart: (
    products: Array<{ product: ProductSubset, position?: number }>,
    type: ModifyCartType,
    listName?: string,
    category?: string,
  ) => void,
  registerBeginCheckout: (quote: Quote) => void,
  registerCheckoutSuccess: (order: Order) => void,
  registerAddShippingInfo: (quote: { ...Quote, shipping: QuoteShipping }) => void,
  registerAddPaymentInfo: (quote: { ...Quote, payment: QuotePayment }) => void,
  registerLogin: () => void,
  registerBeginSignUp: () => void,
  registerSignUpStep: (step: number) => void,
  registerToChoosePrice: () => void,
  registerSignUp: () => void,
  grantConsent: () => void,
|};

export const AnalyticsContext: React$Context<AnalyticsContextType> = createContext<AnalyticsContextType>({
  registerListProductClicked: noop,
  registerProductDetailsView: noop,
  registerModifyCart: noop,
  registerBeginCheckout: noop,
  registerCheckoutSuccess: noop,
  registerAddShippingInfo: noop,
  registerAddPaymentInfo: noop,
  grantConsent: noop,
  registerLogin: noop,
  registerBeginSignUp: noop,
  registerSignUpStep: noop,
  registerSignUp: noop,
  registerToChoosePrice: noop,
});

const PageView = ({ accounts, g }: PageViewProps): React$Node => {
  const { pathname, search, hash } = useLocation();
  /**
   * Set a timeout for 1 second to allow helmet to
   * set the correct page title. See link for info:
   * https://github.com/nfl/react-helmet/issues/189
   */
  useEffect((): void => {
    setTimeout((): void => {
      accounts.forEach((account): void => {
        g("event", "page_view", {
          page_path: pathname + (search || "") + (hash || ""), // eslint-disable-line camelcase
          page_title: window.document.title, // eslint-disable-line camelcase
          page_location: window.location.href, // eslint-disable-line camelcase
          send_to: account, // eslint-disable-line camelcase
        });
      });
    }, 1000);
  }, [pathname]);

  return null;
};

type EventSelectItemData = {|
  item_list_id?: string,
  item_list_name?: string,
  items: GAProduct[],
|};

type EventViewItemData = {|
  currency: string,
  value: number,
  items: GAProduct[],
|};

type EventModifyCartData = {|
  currency: string,
  value: number,
  item_list_name: ?string,
  items: GAProduct[],
|};

type EventBeginCheckoutData = {|
  currency: string,
  value: number,
  coupon?: string,
  items: GAProduct[],
|};

type EventPurchaseData = {|
  currency: string,
  transaction_id: string,
  value: number,
  coupon?: string,
  shipping?: number,
  tax?: number,
  items: GAProduct[],
|};

type EventAddShippingInfo = {|
  currency: string,
  value: number,
  coupon?: string,
  shipping_tier: string,
  items: GAProduct[],
|};

type EventAddPaymentInfo = {|
  currency: string,
  value: number,
  coupon?: string,
  payment_type: string,
  items: GAProduct[],
|};

/**
 * Component adding a google analytics callback to the context.
 *
 * Renders a single wrapped child.
 */
export const WithAnalytics = (props: AnalyticsProviderProps): Node => {
  const { children, accounts, currencyCode } = props;
  const timeout = props.timeout === undefined ? 50 : props.timeout;
  const margin = props.margin === undefined ? -50 : props.margin;
  const throttle = useRef<TimeoutID | null>(null);
  const g = callAccounts(accounts);

  const registerListProductClicked = (
    product: ProductSubset,
    listName?: string,
    category?: string,
    position?: number,
  ): void => {
    g(
      "event",
      "select_item",
      ({
        item_list_name: listName,
        items: [productToGAProduct(product, listName, category, position)],
      }: EventSelectItemData),
    );
  };

  const registerProductDetailsView = (product: ProductSubset): void => {
    g(
      "event",
      "view_item",
      ({
        currency: currencyCode,
        value: product.qty * product.price.exVat,
        items: [productToGAProduct(product)],
      }: EventViewItemData),
    );
  };

  const registerModifyCart = (
    products: Array<{ product: ProductSubset, position?: number }>,
    type: ModifyCartType,
    listName?: string,
    category?: string,
  ): void => {
    g(
      "event",
      type,
      ({
        currency: currencyCode,
        item_list_name: listName,
        value: products.reduce((a, c) => (a += c.product.qty * c.product.price.exVat), 0),
        items: products.map((x) => productToGAProduct(x.product, listName, category, x.position)),
      }: EventModifyCartData),
    );
  };

  const registerBeginCheckout = (quote: Quote, coupon?: string): void => {
    g(
      "event",
      "begin_checkout",
      ({
        currency: currencyCode,
        value: quote.grandTotal.exVat,
        coupon: quote.coupon?.label,
        items: quote.items.map((x) => quoteItemToGAProduct(x)),
      }: EventBeginCheckoutData),
    );
  };

  const registerCheckoutSuccess = (order: Order): void => {
    const items = order.items.map((item) => {
      const product = item.configOption ? item.configOption.product : item.product;

      return ({
        item_id: product.sku,
        item_name: product.name,
        item_brand: product.attributes.manufacturer,
        price: product.price.exVat,
        quantity: item.qty,
      }: GAProduct);
    });

    g(
      "event",
      "purchase",
      ({
        currency: currencyCode,
        transaction_id: order.id,
        value: order.grandTotal.exVat,
        tax: order.grandTotal.vat,
        shipping: order.shipping?.total.exVat,
        items,
      }: EventPurchaseData),
    );
  };

  const registerAddPaymentInfo = (quote: { ...Quote, payment: QuotePayment }): void => {
    g(
      "event",
      "add_payment_info",
      ({
        currency: currencyCode,
        value: quote.grandTotal.exVat,
        coupon: quote.coupon?.label,
        payment_type: quote.payment.code,
        items: quote.items.map((x) => quoteItemToGAProduct(x)),
      }: EventAddPaymentInfo),
    );
  };

  const registerAddShippingInfo = (quote: { ...Quote, shipping: QuoteShipping }): void => {
    g(
      "event",
      "add_shipping_info",
      ({
        currency: currencyCode,
        value: quote.grandTotal.exVat,
        coupon: quote.coupon?.label,
        shipping_tier: quote.shipping.method.code,
        items: quote.items.map((x) => quoteItemToGAProduct(x)),
      }: EventAddShippingInfo),
    );
  };

  const registerLogin = () => g("event", "login");
  const registerSignUp = () => g("event", "sign_up");
  const registerBeginSignUp = () => g("event", "begin_sign_up");
  const registerSignUpStep = (step: number) => g("event", `sign_up_step_${step}`);
  const registerToChoosePrice = () => g("event", `register_to_choose_price`);

  const loadKlaviyo = () => {
    const script = document.createElement("script");
    script.src = "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=V3WUWz";
    document.body?.appendChild(script);
  };

  const grantConsent = (): void => {
    g("consent", "update", {
      analytics_storage: "granted", // eslint-disable-line camelcase
    });

    loadKlaviyo();
  };

  if (process.env.NODE_ENV !== "production" && children === null) {
    console.error("<AnalyticsProvider /> need at least one child");
  }

  const config = useMemo(
    () =>
      accounts.reduce((acc: string, account): string => {
        acc += `gtag('config', '${account}', { send_page_view: false, anonymize_ip: true, currency: '${currencyCode}' });\n`;

        return acc;
      }, ""),
    [accounts],
  );

  return (
    <>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('consent', 'default', {
              'analytics_storage': 'denied'
            });
            gtag('js', new Date());
            ${config}
          `}
        </script>
      </Helmet>
      <AnalyticsContext.Provider
        value={{
          registerListProductClicked,
          registerProductDetailsView,
          registerModifyCart,
          registerBeginCheckout,
          registerCheckoutSuccess,
          registerAddPaymentInfo,
          registerAddShippingInfo,
          grantConsent,
          registerLogin,
          registerSignUp,
          registerBeginSignUp,
          registerSignUpStep,
          registerToChoosePrice,
        }}
      >
        <PageView accounts={accounts} g={g} />
        <CustomerStateChange />
        {children}
      </AnalyticsContext.Provider>
    </>
  );
};

/* @flow */

import type { ProductAbstract } from "../../types/product.flow";
import type { Customer } from "../../types/customer.flow";

import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useSendMessage } from "crustate/react";
import { useTranslate } from "@out-of-home/use-translate";
import styles from "./styles.scss";
import cn from "classnames";
import { StockIndicator } from "../StockIndicator";
import { QtyContext } from "../../context/qty";
import { TimeContext } from "../../context/time";
import { MessagesData, useItemQty } from "../../data";
import { addMessage, clearMessages } from "../../state/stock-messages";
import { incrementItemQty, setItemQty } from "../../state/quote-items";
import { stockStatus, formatStockDate } from "../../helpers/productHelper";
import stockMessageStyles from "../StockMessages/styles.scss";
import { getNextWeekDay, addTwoWeekDays } from "../../helpers/dateHelpers";

import isWeekend from "date-fns/isWeekend";
import format from "date-fns/format";

import MinusIcon from "../../icons/minus.svg";
import PlusIcon from "../../icons/plus.svg";
import { AnalyticsContext } from "../../helpers/analytics/context";

type Props = {
  className?: string,
  variant?: string,
  product: ProductAbstract,
  customer: ?Customer,
  position?: number,
  listName?: string,
  children?: React$Node,
  buyRequest?: string,
};

const KEY_ENTER = 13;

const skipQtyCheck = ({ buyRequest, oohStock }: ProductAbstract) => {
  if (!buyRequest) {
    return false;
  }

  return oohStock?.backorders;
};

const outOfStock = (product: ProductAbstract) => {
  return !skipQtyCheck(product) && product.oohStock?.qty === 0;
};

const timeAt = (now: Date, hour: number) => {
  const d = new Date(now);
  d.setHours(9);
  d.setMinutes(0);
  d.setSeconds(0);

  return d;
};

const getMarkup = (
  t: (string, any) => string,
  now: Date,
  qty: number,
  product: ProductAbstract
) => {
  const { brand } = product.attributes;
  const { estimatedDeliveryDate } = product;
  const date = new Date(estimatedDeliveryDate);
  const dateFormatted = formatStockDate(estimatedDeliveryDate);
  const stock = product.oohStock?.qty || 0;
  const currentDay = now.getDay();
  const time9 = timeAt(now, 9);
  const time16 = timeAt(now, 16);
  const time12 = timeAt(now, 12);
  const nextWeekDay = getNextWeekDay(new Date(now));
  const twoWeekDays = addTwoWeekDays(new Date(now));
  const status = stockStatus(product);

  // Arla
  // if (
  //   product.attributes.brand === "Arla" &&
  //   (product.attributes.cheeseType?.length || 0) === 0 &&
  //   stock === 0
  // ) {
  //   let sendText;

  //   // Monday to Thurday
  //   if (currentDay > 0 && currentDay < 5) {
  //     if (now < time9) {
  //       sendText = ` - Skickas tidigast ${format(nextWeekDay, "d/MM")}`;
  //     } else {
  //       sendText = ` - Skickas tidigast ${format(twoWeekDays, "d/MM")}`;
  //     }
  //   }
  //   // Friday
  //   else if (currentDay === 5) {
  //     if (now < time16) {
  //       sendText = ` - Skickas tidigast ${format(nextWeekDay, "d/MM")}`;
  //     } else {
  //       sendText = ` - Skickas tidigast ${format(twoWeekDays, "d/MM")}`;
  //     }
  //   }
  //   // Saturday and Sunday
  //   else {
  //     sendText = " - Skickas tidigast tisdag";
  //   }

  //   return (
  //     <>
  //       <div>
  //         <h3>Färsk vid beställning!</h3>
  //         <p>
  //           Varje dag vid 09.00 beställer vi färska
  //           mejeriprodukter från Arla som ankommer och lämnar lagret
  //           nästkommande dag. Detta gör vi för att ge dig en så färsk produkt
  //           som möjligt och för att undvika kassation.
  //         </p>

  //         <p className={stockMessageStyles.warning}>
  //           OBS: Hela ordern avgår först när beställda färskvaror ankommit!
  //         </p>
  //       </div>
  //       <div className={stockMessageStyles.product}>
  //         <p>
  //           <b>{product.name}</b>
  //         </p>
  //         <span>{sendText}</span>
  //       </div>
  //     </>
  //   );
  // }

  // Skånemejerier
  // if (product.attributes.brand === "Skånemejerier" && stock === 0) {
  //   let sendText;

  //   // Monday to Thurday
  //   if (currentDay > 0 && currentDay < 5) {
  //     if (now < time12) {
  //       sendText = ` - Skickas tidigast ${format(nextWeekDay, "d/MM")}`;
  //     } else {
  //       sendText = ` - Skickas tidigast ${format(twoWeekDays, "d/MM")}`;
  //     }
  //   }
  //   // Friday
  //   else if (currentDay === 5) {
  //     if (now < time16) {
  //       sendText = ` - Skickas tidigast ${format(nextWeekDay, "d/MM")}`;
  //     } else {
  //       sendText = ` - Skickas tidigast ${format(twoWeekDays, "d/MM")}`;
  //     }
  //   }
  //   // Saturday and Sunday
  //   else {
  //     sendText = " - Skickas tidigast tisdag";
  //   }

  //   return (
  //     <>
  //       <div>
  //         <h3>Färsk vid beställning!</h3>
  //         <p>
  //           Varje dag vid 12.00 beställer vi färska
  //           mejeriprodukter från Skånemejerier som ankommer och lämnar lagret
  //           nästkommande dag. Detta gör vi för att ge dig en så färsk produkt
  //           som möjligt och för att undvika kassation.
  //         </p>
  //         <p className={stockMessageStyles.warning}>
  //           OBS: Hela ordern avgår först när beställda färskvaror ankommit!
  //         </p>
  //       </div>
  //       <div className={stockMessageStyles.product}>
  //         <p>
  //           <b>{product.name}</b>
  //         </p>
  //         <span>{sendText}</span>
  //       </div>
  //     </>
  //   );
  // }

  // Everfresh
  // if (product.attributes.brand === "Everfresh" && stock === 0) {
  //   let sendText;

  //   // Monday to Thurday
  //   if (currentDay > 0 && currentDay < 5) {
  //     if (now < time12) {
  //       sendText = ` - Skickas tidigast ${format(nextWeekDay, "d/MM")}`;
  //     } else {
  //       sendText = ` - Skickas tidigast ${format(twoWeekDays, "d/MM")}`;
  //     }
  //   }
  //   // Friday
  //   else if (currentDay === 5) {
  //     if (now < time16) {
  //       sendText = ` - Skickas tidigast ${format(nextWeekDay, "d/MM")}`;
  //     } else {
  //       sendText = ` - Skickas tidigast ${format(twoWeekDays, "d/MM")}`;
  //     }
  //   }
  //   // Saturday and Sunday
  //   else {
  //     sendText = " - Skickas tidigast tisdag";
  //   }

  //   return (
  //     <>
  //       <div>
  //         <h3>Färsk vid beställning!</h3>
  //         <p>
  //           Varje dag vid 12.00 beställer vi frukt & grönt från Everfresh som
  //           ankommer och lämnar lagret nästkommande dag. Detta gör vi för att ge
  //           dig en så färsk produkt som möjligt och för att undvika kassation.
  //         </p>
  //         <p className={stockMessageStyles.warning}>
  //           OBS: Hela ordern avgår först när beställda färskvaror ankommit!
  //         </p>
  //       </div>
  //       <div className={stockMessageStyles.product}>
  //         <p>
  //           <b>{product.name}</b>
  //         </p>
  //         <span>{sendText}</span>
  //       </div>
  //     </>
  //   );
  // }

  if (status === 3 && qty === stock) {
    return (
      <>
        <div>
          <h3>{t("STOCK.STATUS_3_TITLE")}</h3>
          <p
            dangerouslySetInnerHTML={{ __html: t("STOCK.STATUS_3_MESSAGE") }}
          />
        </div>
        <div className={styles.product}>
          <p>
            <b>{product.name}</b>
          </p>
          <span>{t("STOCK.STATUS_3", { stock: Math.max(0, stock) })}</span>
        </div>
      </>
    );
  }

  if (qty > stock) {
    // if (status === 2 && qty > stock) {
    //   return (
    //     <>
    //     <div>
    //       <h3>{t("STOCK.STATUS_2_TITLE")}</h3>
    //       <p dangerouslySetInnerHTML={{ __html: t("STOCK.STATUS_2_MESSAGE") }} />
    //     </div>
    //     <div className={stockMessageStyles.product}>
    //       <p><b>{product.name}</b></p>
    //       <span>{dateFormatted ? t(now >= date ? "STOCK.NEXT_DELIVERY_TODAY" : "STOCK.NEXT_DELIVERY", { stock: Math.max(0, stock), date: dateFormatted }) : "-"}</span>
    //     </div>
    //     </>
    //   );
    // }

    if (status === 8 && qty > stock) {
      return (
        <>
          <div>
            <h3>{t("STOCK.STATUS_8_TITLE")}</h3>
            <p
              dangerouslySetInnerHTML={{
                __html: t(
                  stock > 0
                    ? "STOCK.STATUS_8_MESSAGE"
                    : "STATUS_8_OUTOFSTOCK_MESSAGE",
                  { stock: Math.max(0, stock), brand }
                ),
              }}
            />
          </div>
          <div className={stockMessageStyles.product}>
            <p>
              <b>{product.name}</b>
            </p>
            {!stock ? (
              <span>
                {dateFormatted
                  ? t("STOCK.STATUS_8_BOTTOM_DATE", {
                      stock: Math.max(0, stock),
                      date: dateFormatted,
                    })
                  : t("STOCK.STATUS_8_BOTTOM", { stock })}
              </span>
            ) : (
              <span>
                {dateFormatted
                  ? t("STOCK.STATUS_8_OUTOFSTOCK_BOTTOM_DATE", {
                      stock: Math.max(0, stock),
                      date: dateFormatted,
                      brand,
                    })
                  : t("STOCK.STATUS_8_OUTOFSTOCK_BOTTOM", { stock, brand })}
              </span>
            )}
          </div>
        </>
      );
    }
  }
};

export const QtyPicker: React$AbstractComponent<Props, mixed> = React.memo(
  ({ product, customer, listName, position, ...props }: Props): React$Node => {
    const [hasShownWarning, setHasShownWarning] = useState(false);
    const buyRequest = props.buyRequest || product.buyRequest;
    const sendMessage = useSendMessage();
    const { registerModifyCart } = useContext(AnalyticsContext);
    const t = useTranslate();
    const qty = useItemQty(buyRequest);
    const input = useRef<HTMLInputElement | null>(null);
    const [focus, setFocus] = useState(false);
    const status = stockStatus(product);
    const stock = product.oohStock?.qty || 0;
    const exceeding = qty >= stock;
    const { now } = useContext(TimeContext);
    const isAllowed = Boolean(
      (product.attributes.ooh3AlcoholRequirement !== true ||
        customer?.hasAlcoholLicense) &&
        Boolean(product.buyRequest)
    );

    const sendModifyCartNotice = useCallback((modification: number) => {
      registerModifyCart(
        [{
          product: {
            sku: product.sku,
            name: product.name,
            qty: Math.abs(modification),
            price: product.price,
            attributes: { manufacturer: product.attributes.manufacturer }
          }
        }
      ],
        modification > 0 ? "add_to_cart" : "remove_from_cart",
        listName
      );
    }, [product, listName, registerModifyCart]);

    useEffect(() => {
      if (input.current) {
        input.current.value = qty + "";
      }
    }, [qty, input]);

    useEffect(() => {
      if (focus && input.current) {
        input.current.focus();
      }
    }, [focus]);

    const triggerMessages = (qty: number, prevQty: number) => {
      if (prevQty > qty && qty === stock) {
        sendMessage(clearMessages());
      }

      if (hasShownWarning || qty < prevQty) {
        return;
      }

      setHasShownWarning(true);

      const markup = getMarkup(t, now, qty, product);

      if (markup) {
        sendMessage(addMessage(markup));
      }
    };

    const modify = (modification) => {
      if (buyRequest) {
        const prevQty = qty;
        triggerMessages(qty + modification, prevQty);
        sendMessage(incrementItemQty(buyRequest, modification));
        sendModifyCartNotice(modification);
      }
    };

    const onFocus = () => {
      if (outOfStock(product) || !isAllowed) {
        return;
      }

      setFocus(true);

      // Auto-select content of <input />
      if (input.current) {
        input.current.setSelectionRange(0, input.current.value.length);
      }
    };

    const submit = (e: Event) => {
      e.preventDefault();
      e.stopPropagation();

      setFocus(false);

      if (input.current == null || input.current.value === "") {
        return;
      }

      const nextQty = parseInt(input.current.value, 10);

      const stock = product.oohStock != null ? product.oohStock.qty : 0;

      if (!isAllowed || !buyRequest) {
        return;
      }

      sendMessage(setItemQty(buyRequest, nextQty));
      triggerMessages(nextQty, qty);

      const modification = nextQty - qty;
      sendModifyCartNotice(modification);
    };

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.keyCode === KEY_ENTER && input.current) {
        input.current.blur();
        submit(e);
      }
    };

    const decrement = () => modify(-1);
    const increment = () => modify(1);

    return (
      <div
        className={cn(
          styles.block,
          props.className,
          { [styles["block__" + (props.variant || "")]]: props.variant },
          { [styles.block__active]: qty > 0 }
        )}
      >
        <div
          className={cn({
            [styles.qty]: true,
            [styles.qtyFocus]: focus,
            [styles.qtyOutOfStock]: outOfStock(product),
            [styles.exceeding]: exceeding,
            [styles["status_" + status]]: true,
          })}
          onClick={onFocus}
        >
          <StockIndicator product={product} qty={qty} />
          {props.children}
          {focus && (
            <input
              ref={input}
              className={styles.input}
              type="text"
              pattern="\d*"
              onFocus={onFocus}
              onBlur={(e) => {
                // @TODO: Race condition triggering the minus button after
                setTimeout(() => {
                  submit(e);
                }, 100);
              }}
              onKeyDown={onKeyDown}
            />
          )}
          {!focus && (
            <div>
              <span className={styles.input}>{qty} st</span>
            </div>
          )}
        </div>
        {focus && (
          <div key="controls" className={styles.controls}>
            <button
              type="button"
              aria-label="Lägg till valt antal i varukorg"
              className={cn(styles.button, styles.buttonOk)}
              onClick={submit}
            >
              OK
            </button>
          </div>
        )}
        {!focus && (
          <div key="controls" className={styles.controls}>
            <button
              type="button"
              aria-label="Ta bort produkt från varukorg"
              className={styles.button}
              disabled={qty <= 0 || !isAllowed}
              onClick={decrement}
            >
              <div className={styles.iconWrapper}>
                <MinusIcon />
              </div>
            </button>

            <button
              type="button"
              aria-label="Lägg till produkt i varukorg"
              className={styles.button}
              disabled={
                (!skipQtyCheck(product) &&
                  qty >= (product.oohStock?.qty || 0)) ||
                !isAllowed
              }
              onClick={increment}
            >
              <div className={styles.iconWrapper}>
                <PlusIcon />
              </div>
            </button>
          </div>
        )}
      </div>
    );
  }
);

export const Qty = ({ product }: { product: ProductAbstract }): React$Node => {
  // const stock = product.oohStock?.qty || 0;
  const buyRequest = product.buyRequest;
  const qty = useItemQty(buyRequest);

  return (
    <div>
      <span className={styles.input}>{qty} st</span>
    </div>
  );
};

/* @flow */

import type { QuoteItem } from "../../types/quote.flow";
import type { ProductSubset, GAProduct } from "./types.flow";

/**
 * Queues up an action to run on next render.
 */
/* eslint-disable no-unused-vars */
// TODO: Fix proper typings
export const next: any =
  typeof window !== "undefined" && window.requestAnimationFrame
    ? (cb: () => any, _: any): any => window.requestAnimationFrame(cb)
    : (cb: () => any, timeout: any): any => window.setTimeout(cb, timeout);
/* eslint-enable no-unused-vars */

/**
 * Copies the listed keys to a new object.
 * @param {} object
 * @param {*} keys
 */
export const onlyKeys = (object: {}, keys: Array<string>): {} =>
  keys.reduce((a: {}, k: string): {} => {
    if (Object.hasOwnProperty.call(object, k)) {
      a[k] = object[k];
    }

    return a;
  }, {});

export const ANALYTICS_PROPERTY = "gtag";

/**
 * Converts a tracker to a suitable GoogleAnalytics identifier.
 */
const trackerToName = (t: string): string => t.replace(/-/g, "");

/**
 * This uses the global window.ga to queue up google analytics actions.
 * @param {Array<string>} accounts
 */
export const callAccounts =
  (accounts: Array<string>): any =>
  (...args: Array<any>): any => {
    const isBrowser = typeof window !== "undefined";

    if (!Array.isArray(accounts)) {
      throw new TypeError("GA accounts missing");
    }

    if (!isBrowser) {
      throw new Error("Attempting to call ga.callAccounts on server.");
    }

    // Skip if GA is not initialized yet
    if (!window[ANALYTICS_PROPERTY]) {
      return;
    }

    // Create commands cannot be prefixed
    if (args[0] === "config") {
      return window[ANALYTICS_PROPERTY].apply(null, args);
    }

    if (args[0] === "consent") {
      return window[ANALYTICS_PROPERTY].apply(null, args);
    }

    if (args[0] === "event" && args[1] === "page_view") {
      return window[ANALYTICS_PROPERTY].apply(null, args);
    }

    /**
     * Set a timeout for 1 second to allow helmet to
     * set the correct page title. See link for info:
     * https://github.com/nfl/react-helmet/issues/189
     */
    setTimeout((): void => {
      if (args[0] === "event") {
        window[ANALYTICS_PROPERTY].apply(null, args);
      } else {
        accounts.forEach((account): void =>
          window[ANALYTICS_PROPERTY].apply(
            null,
            args.map((a: string, i: number): string =>
              i === 0 ? `${trackerToName(account)}.${a.toString()}` : a
            )
          )
        );
      }
    }, 1000);
  };

/**
 * No operation function
 */
export const noop = (): typeof undefined => undefined;

/**
 * Convert a subset product to GA compatible product
 * @param {ProductSubset} product
 * @param {string} list
 * @param {number} position
 * @param {string} category
 */
export const productToGAProduct = (
  product: ProductSubset,
  listName?: string,
  category?: string,
  position?: number,
): GAProduct => ({
  item_id: product.sku,
  item_name: product.name,
  item_brand: product.attributes.manufacturer,
  item_category: category,
  item_list_name: listName,
  price: product.price.exVat,
  quantity: product.qty,
  index: position,
});

/**
 * Convert a quote item with additional props to GA compatible product
 * @param {QuoteItem} item
 * @param {string} list
 * @param {number} position
 */
export const quoteItemToGAProduct = (
  item: QuoteItem,
): GAProduct => ({
  item_id: item.product.sku,
  item_name: item.product.name,
  item_brand: item.product.attributes.manufacturer,
  price: item.product.price.exVat,
  quantity: item.qty,
});

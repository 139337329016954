/* @flow */

import type {
  AlcoholLicense,
  AlcoholLicenseInput,
} from "../types/alcohol-license.flow";
import type { Model, EffectErrorMessage } from "crustate";
import { updateData, EFFECT_ERROR } from "crustate";

export type Data =
  | {| +state: "INITING" |}
  | {| +state: "LOADED", +data: Array<AlcoholLicense> |}
  | {| +state: "ADDING", +data: Array<AlcoholLicense> |}
  | {| +state: "REMOVING", +data: Array<AlcoholLicense>, removingId: string |};

export type AlcoholLicensesRequest =
  | {
      tag: typeof ALCOHOL_LICENSES_INIT_REQUEST,
    }
  | {
      tag: typeof ALCOHOL_LICENSES_ADD_REQUEST,
      data: AlcoholLicenseInput,
    }
  | {
      tag: typeof ALCOHOL_LICENSES_REMOVE_REQUEST,
      id: string,
    };

export type AlcoholLicensesResponse =
  | {
      tag: typeof ALCOHOL_LICENSES_INIT_RESPONSE,
      data: Array<AlcoholLicense>,
    }
  | {
      tag: typeof ALCOHOL_LICENSES_ADD_RESPONSE,
      data: Array<AlcoholLicense>,
    }
  | {
      tag: typeof ALCOHOL_LICENSES_REMOVE_RESPONSE,
      data: Array<AlcoholLicense>,
    }
  | EffectErrorMessage;

export const ALCOHOL_LICENSES_INIT_REQUEST: "alcohol-licenses/init/request" =
  "alcohol-licenses/init/request";

export const ALCOHOL_LICENSES_INIT_RESPONSE: "alcohol-licenses/init/response" =
  "alcohol-licenses/init/response";

export const ALCOHOL_LICENSES_ADD_REQUEST: "alcohol-licenses/add/request" =
  "alcohol-licenses/add/request";

export const ALCOHOL_LICENSES_ADD_RESPONSE: "alcohol-licenses/add/response" =
  "alcohol-licenses/add/response";

export const ALCOHOL_LICENSES_REMOVE_REQUEST: "alcohol-licenses/remove/request" =
  "alcohol-licenses/remove/request";

export const ALCOHOL_LICENSES_REMOVE_RESPONSE: "alcohol-licenses/remove/response" =
  "alcohol-licenses/remove/response";

// Actions
export const add = (data: AlcoholLicenseInput): AlcoholLicensesRequest => ({
  tag: ALCOHOL_LICENSES_ADD_REQUEST,
  data,
});

export const remove = (id: string): AlcoholLicensesRequest => ({
  tag: ALCOHOL_LICENSES_REMOVE_REQUEST,
  id,
});

export const AlcoholLicensesModel: Model<
  Data,
  {},
  AlcoholLicensesRequest | AlcoholLicensesResponse
> = {
  id: "alcohol-licenses",
  init: () =>
    updateData({ state: "INITING" }, { tag: ALCOHOL_LICENSES_INIT_REQUEST }),
  update: (state, msg) => {
    switch (msg.tag) {
      case ALCOHOL_LICENSES_INIT_RESPONSE:
        if (state.state === "INITING") {
          return updateData({ state: "LOADED", data: msg.data });
        }

        throw new Error("Invalid state transition");
      case ALCOHOL_LICENSES_ADD_RESPONSE:
        if (state.state === "ADDING") {
          return updateData({ state: "LOADED", data: msg.data });
        }

        throw new Error("Invalid state transition");
      case ALCOHOL_LICENSES_REMOVE_RESPONSE:
        if (state.state === "REMOVING") {
          return updateData({ state: "LOADED", data: msg.data });
        }

        throw new Error("Invalid state transition");

      case ALCOHOL_LICENSES_ADD_REQUEST:
        if (state.state === "LOADED") {
          return updateData({ state: "ADDING", data: state.data }, msg);
        }

        break;

      case ALCOHOL_LICENSES_REMOVE_REQUEST:
        if (state.state === "LOADED") {
          return updateData(
            { state: "REMOVING", removingId: msg.id, data: state.data },
            msg
          );
        }

        break;
      default:
    }
  },
};

export default AlcoholLicensesModel;

/* @flow */

import React from "react";
import styles from "./styles.scss";
import { Wrapper } from "../../../components/Wrapper";
import { Button } from "../../../components/Button";
import TickIcon from "../../../icons/checkmark.svg";

type Props = {
  title: string,
  text: string,
};

export const Success = ({ title, text }: Props): React$Node => {
  return (
    <div className={styles.block}>
      <div className={styles.status}>
        <div className={styles.statusTick}>
          <div className={styles.statusTickMask}>
            <TickIcon />
          </div>
        </div>

        <div className={styles.successText}>
          <p className={styles.statusText}>{title}</p>
        </div>
      </div>
      <p className={styles.text}>{text}</p>

      <div>
        <Button
          onClick={() => {
            window.location.reload();
          }}
          className={styles.button}
          variant="small ghost"
        >
          Annat ärende
        </Button>
        <Button className={styles.button} variant="small ghost" to="/">
          Till startsidan
        </Button>
      </div>
    </div>
  );
};

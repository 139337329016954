// @flow

/*
 * Constants.
 */ import type { FrontChatOptions, FrontChatType } from "../types/front-chat.flow";

const scriptSrc = "https://chat-assets.frontapp.com/v1/chat.bundle.js";

/*
 * Helper.
 */

export async function boot(element: HTMLElement, options?: FrontChatOptions): Promise<FrontChatType> {
  const scriptTag = document.createElement("script");
  scriptTag.setAttribute("type", "text/javascript");
  scriptTag.setAttribute("src", scriptSrc);

  if (options?.nonce) {
    scriptTag.setAttribute("nonce", options.nonce);
  }

  const loadScriptPromise = new Promise<FrontChatType>((resolve) => {
    scriptTag.onload = () => {
      if (!window.FrontChat) {
        throw new Error("[front-chat-sdk] Could not set up window.FrontChat");
      }

      resolve(window.FrontChat);
    };
  });

  element.appendChild(scriptTag);

  return loadScriptPromise;
}

/* @flow */

import React, { useState, useCallback, useRef, useEffect } from "react";
import styles from "./styles.scss";

type Props = {
  speed: number,
  direction: "left" | "right",
  children: React$Node,
};

export default function InfiniteLooper({ speed, direction, children }: Props): React$Node {
	const [looperInstances, setLooperInstances] = useState(1);
	const outerRef = useRef(null);
	const innerRef = useRef(null);

	function resetAnimation() {
		if (innerRef.current) {
			innerRef.current.setAttribute("data-animate", "false");

			setTimeout(() => {
				if (innerRef.current) {
					innerRef.current.setAttribute("data-animate", "true");
				}
			}, 50);
		}
	}


	const setupInstances = useCallback((innerRef: HTMLDivElement, outerRef: HTMLDivElement) => {
		const { width } = innerRef.getBoundingClientRect();

		const { width: parentWidth } = outerRef.getBoundingClientRect();

		const instanceWidth = width / innerRef.children.length;

		if (width < parentWidth + instanceWidth && width !== 0) {
			setLooperInstances(looperInstances + Math.ceil(parentWidth / width));
		}

		resetAnimation();
	}, [looperInstances]);

	useEffect(() => {
		if (innerRef.current && outerRef.current) {
			setupInstances(innerRef.current, outerRef.current);
		}
	}, [setupInstances, innerRef, outerRef]);

	useEffect(() => {
		const setup = () => {
			if (innerRef.current && outerRef.current) {
				setupInstances(innerRef.current, outerRef.current);
			}
		};

		window.addEventListener("resize", setup);

		return () => {
			window.removeEventListener("resize", setup);
		};
	}, [setupInstances]);

	// @TODO: calculate speed depending on the number of items

	return (
		<div className={styles.looper} ref={outerRef}>
			<div className={styles.looper__innerList} ref={innerRef}>
				{[...Array(looperInstances)].map((_, i) => (
					<div
						key={i}
						className={styles.looper__listInstance}
						style={{
							animationDuration: `${speed}s`,
							animationDirection: direction === "right" ? "reverse" : "normal",
						}}
					>
						{children}
					</div>
				))}
			</div>
		</div>
	);
}

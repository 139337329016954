/* @flow */

import type { RadioProps } from "../RadioGroup";

import React from "react";
import cn from "classnames";
import { useTranslate } from "@out-of-home/use-translate";
import { Link } from "react-router-dom";
import styles from "./styles.scss";

type Props = RadioProps & {
  label?: string,
  to?: ?string,
  ctaText?: string,
  checked: boolean,
  disabled?: boolean,
  processing?: boolean,
  onEdit?: (e: Event) => void,
  onChange: ({ name: string, id: string }) => void,
  children?: React$Node,
  className?: string,
};

export const RadioField = ({
  name,
  id,
  label,
  value,
  to,
  onEdit,
  ctaText,
  checked,
  onChange,
  processing = false,
  disabled = false,
  ...props
}: Props): React$Node => {
  const t = useTranslate();

  const Radio = (
    <label htmlFor={id || name} className={styles.radio}>
      <input
        name={String(name) || id}
        id={id || name}
        type="radio"
        checked={checked}
        disabled={disabled}
        onChange={() => onChange({ name: String(name), id: String(id) })}
      />
    </label>
  );

  const Field = (
    <div
      className={styles.field__content}
      onClick={() => onChange({ name: String(name), id: String(id) })}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <span className={styles.radio} />
        <div className={styles.field__input}>
          {label && <span className={styles.field__key}>{label}</span>}
          <span className={styles.field__value}>{value}</span>
        </div>
      </div>
      {to && (
        <Link
          to={to}
          onClick={(e) => e.stopPropagation()}
          className={styles.field__cta}
        >
          {ctaText || t("ACCOUNT.EDIT")}
        </Link>
      )}
      {!onEdit && !to && <span className={styles.field__cta}>&nbsp;</span>}
      {onEdit && (
        <span
          className={cn(styles.field__cta, styles.field__ctaCallback)}
          onClick={(e) => {
            e.stopPropagation();
            onEdit(e);
          }}
        >
          {ctaText || t("ACCOUNT.EDIT")}
        </span>
      )}
    </div>
  );

  return (
    <div
      className={cn(
        styles.block,
        { [styles.block__disabled]: disabled },
        { [styles.active]: checked },
        { [styles.processing]: processing },
        props.className
      )}
    >
      <div className={styles.radioRow}>
        <label htmlFor={id || name} className={styles.field}>
          {Field}
        </label>
        {props.children}
      </div>
    </div>
  );
};

/* @flow */

import type { ProductAbstract } from "../../types/product.flow";

import React, { memo } from "react";
import styles from "./styles.scss";
import cn from "classnames";
import { stockStatus, isExeeding } from "../../helpers/productHelper";

type Props = {
  qty: number,
  product: ProductAbstract,
  className?: string,
};

export const StockIndicator: React$AbstractComponent<Props, mixed> = memo(
  ({ product, qty, className }: Props): React$Node => {
    const stock = product.oohStock?.qty || 0;
    const status = stockStatus(product);
    const exceeding = isExeeding(status, qty, stock || 0);

    return (
      <div
        className={cn({
          [styles.block]: true,
          [styles["block__" + status]]: true,
          [styles.block__exceeding]: exceeding,
          [styles.block__outofstock]: stock === 0,
          className,
        })}
      >
        <div id="symbol" className={styles.symbol} />
      </div>
    );
  }
);

/* @flow */

import React, { useState, useEffect } from "react";
import { useRestClient } from "../../entrypoint/shared";
import { addMessage, addMessageTranslated } from "../../state/messages";
import { useSendMessage } from "crustate/react";
import { useTranslate } from "@out-of-home/use-translate";
import { sendJson } from "@out-of-home/fetch-utils";
import StarIcon from "../../icons/star.svg";
import cn from "classnames";
import styles from "./styles.scss";
import { Button } from "../Button";
import { Foldable } from "../Foldable";

export const Review = (): React$Node => {
  const client = useRestClient();
  const sendMessage = useSendMessage();
  const t = useTranslate();
  const [selected, setSelected] = useState<?number>(null);
  const [reviewText, setReviewText] = useState<?string>("");
  const [reviewState, setReviewState] = useState<string>("PENDING");
  const [id, setId] = useState(null);

  const submit = async (e) => {
    e.preventDefault();

    if (!id || !reviewText) {
      return;
    }

    try {
      setReviewState("SUBMITTING");
      const result = await client(
        `/api/review/${id}`,
        sendJson({ method: "PUT", body: ({ feedback: reviewText }: any) })
      )
        .then((x) => x.json())
        .catch((x) => ({ error: true }));
      sendMessage(addMessageTranslated(t("REVIEW.SUBMITTED"), "success"));
      setReviewState("SUBMITTED");
    } catch (error) {
      setReviewState("");
      throw error;
    }
  };

  const select = (i: number) => {
    setSelected(i);
    client(`/api/review?rating=${i}`, { method: "POST" })
      .then((x) => x.json())
      .then((x) => setId(x));
  };

  if (reviewState === "SUBMITTED") {
    return null;
  }

  return (
    <div className={cn(styles.block, { [styles.block__selected]: selected })}>
      <p className={styles.title}>{t("REVIEW.TITLE")}</p>

      <div className={styles.starsWrapper}>
        <div className={styles.stars}>
          {[1, 2, 3, 4, 5].map((x) => (
            <div
              key={x}
              className={cn(styles.star, {
                [styles.star__active]: (selected || 0) >= x,
              })}
            >
              <StarIcon onClick={() => select(x)} />
            </div>
          ))}
        </div>
      </div>

      <Foldable open={!!selected} wrapperClass={styles.foldableForm}>
        <form className={styles.form} onSubmit={submit}>
          <textarea
            className={styles.input}
            value={reviewText}
            onChange={(e) => setReviewText(e.target.value)}
            rows="3"
            required
          />
          <Button
            loading={reviewState === "SUBMITTING"}
            variant="ghost"
            className={styles.button}
            type="submit"
          >
            {t("REVIEW.SUBMIT")}
          </Button>
        </form>
      </Foldable>
    </div>
  );
};

/* @flow */

import React, { useRef, useEffect, useState, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import cn from "classnames";
import CountUp from "react-countup";
import { useData } from "crustate/react";
import { BlogListData, BrandsData } from "../../data";
import { Link } from "react-router-dom";
import styles from "./styles.scss";
import { Wrapper } from "../Wrapper";
import { Button } from "../Button";
import { Input } from "../Input";
import { Promotion } from "../Promotion";
import { Pills } from "../Pills";
import { BlogPostCard } from "../BlogPostCard";
import { Testimonials } from "../Testimonials";
import { Excerpt } from "../Excerpt";
import { useTranslate } from "@out-of-home/use-translate";
import { getFrontPageMeta, metaToHelmetProps } from "../../helpers/get-meta";
import { restProductToGQLProduct, shuffle } from "../../helpers";
import { ReferenceCustomersSection, formatCountUp } from "../ReferenceCustomersView/reference-customers-section";
import { ArrowLink } from "../ArrowLink";
import { Hero } from "./Hero";
import { Categories } from "./Categories";
import { Register } from "./Register";
import { AnimatedBrands } from "./AnimatedBrands";
import { useBrowser } from "../../helpers/use-browser";
import { useScrollTopOrElement } from "../../helpers/use-scroll-top-or-element";

type AnchorProps = {
  className?: string,
  to: string,
  children: React$Node,
};

export const HomeView = (): React$Node => {
  const browser = useBrowser();
  const t = useTranslate();
  const meta = getFrontPageMeta(t("FRONTPAGE.TITLE"), t("FRONTPAGE.DESCRIPTION"));
  const blogList = useData(BlogListData);
  const myCountUpA = useRef();
  const myCountUpB = useRef();

  const blogPosts = blogList.state === "LOADED" ? blogList.data.posts : [];

  const brandsData = useData(BrandsData);
  const brands = useMemo(() => (brandsData.state === "LOADED" ? shuffle(brandsData.data) : []), [brandsData.state]);

  const loading = blogList.state === "LOADING" || brandsData.state === "LOADING";
  useScrollTopOrElement({ smoothScrollIntoView: true }, loading);

  return (
    <div className={styles.block}>
      <Wrapper>
        <Hero />
      </Wrapper>

      <Wrapper className={styles.categories}>
        <Categories />
      </Wrapper>

      <ReferenceCustomersSection />

      <div className={cn(styles.container, styles.container__promotion)}>
        <Wrapper className={styles.top}>
          <div className={styles.topLeft}>
            <Promotion />
          </div>
          <div className={styles.topRight}>
            <div>
              <div>
                <h3>
                  Just nu har vi <span className="power-link">4 594</span> lagerförda artiklar från{" "}
                  <span className="power-link">578</span> varumärken
                </h3>

                <p>
                  Hos oss får producenten ge bra villkor till dig som kund utan vår inblandning. Handla 4 594 lagerförda
                  artiklar från 578 varumärken, men håll utkik – vi uppdaterar sortimentet varje dag. Undvik
                  anbudsträsket, bonusar, kickbacks och sponsrade förkläden – få rätt pris direkt istället. Det är ju
                  ändå du som betalar i slutändan.
                </p>
              </div>
              <div>
                <ul className={styles.topLinks}>
                  <li>
                    <ArrowLink to="/bli-leverantor">Bli leverantör</ArrowLink>
                  </li>
                  <li>
                    <ArrowLink to="/varumarken">Se alla varumärken</ArrowLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Wrapper>
      </div>

      <section className={styles.section__brands}>
        <AnimatedBrands items={brands} speed={4} browser={browser} />
      </section>

      <section className={cn("no-underline", styles.section__news)}>
        <Wrapper>
          <div className={styles.news}>
            {blogPosts.map((x) => (
              <BlogPostCard {...x} key={"blog_post" + x.id} full={true} grayBg={true} />
            ))}
          </div>
          <div style={{ textAlign: "center" }}>
            <Button to={"/" + t("ROUTES.NEWS")} variant="primary">
              {t("NEWS.MORE")}
            </Button>
          </div>
        </Wrapper>
      </section>

      <Register id="register" />

      <Helmet title={meta.title}>
        <meta name="description" content={t("FRONTPAGE.DESCRIPTION")} />
        <meta property="og:description" content={t("FRONTPAGE.DESCRIPTION")} />
        <meta property="og:title" content={t("FRONTPAGE.TITLE")} />

        {metaToHelmetProps(t, "meta", meta.data)}
      </Helmet>
    </div>
  );
};

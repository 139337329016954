// @flow

import type { ValidationError as FormaggioError } from "@awardit/formaggio";

type FormDataValue = boolean | string | number | FormData;
export type FormData = { [key: string]: FormDataValue };
export type ValidationError = FormaggioError & { ... };
export type Validator<T> = (t: T) => Array<ValidationError>;

export const focusInvalidField = (
  e: SyntheticEvent<HTMLFormElement>,
  errors: Array<ValidationError>
) => {
  const field = e.currentTarget.querySelector(`[name='${errors[0].field}']`);

  if (field) {
    field.focus();
  }
};

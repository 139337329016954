/* @flow */

import React from "react";
import cn from "classnames";
import styles from "./styles.scss";
import ExclamationIcon from "../../icons/exclamation.svg";
import TickIcon from "../../icons/checkmark.svg";
import { useTranslate } from "@out-of-home/use-translate";

type Props = {
  approvedAt: ?string,
};

export const AlcoholLicenseStatus = ({ approvedAt }: Props): React$Node => {
  const t = useTranslate();

  if (approvedAt) {
    return (
      <span className={styles.block}>
        <TickIcon className={cn(styles.icon, styles.iconSuccess)} />
        <span>Godkänt</span>
      </span>
    );
  }

  return (
    <span className={styles.block}>
      <ExclamationIcon className={cn(styles.icon, styles.iconPending)} />
      <span>Väntar på godkännande</span>
    </span>
  );
};

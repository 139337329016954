// @flow

import type { TFacet, TFilterQuery, TSort } from "../types/filter.flow";
import type { Translations } from "@out-of-home/use-translate";
import type { MetaResponse } from "./get-meta";
import type { Location } from "react-router-dom";

import {
  parseParams,
  stringifyParams,
} from "@out-of-home/location-search-string";

export const DEFAULT_SORT = "popularity";

const normalizeFilter = (o: {
  [k: string]: string | Array<string>,
}): { [k: string]: Array<string> } =>
  Object.keys(o).reduce((n, k) => {
    // Preserve truthy values and skip all google tracking parameters,
    // mailchimp tracking, klaviyo tracking and query-parameters starting with an underscore
    if (o[k] && !/^(?:utm_|mc_|_|variation)/.test(k)) {
      n[k] = Array.isArray(o[k]) ? o[k] : [o[k]];
    }

    return n;
  }, {});

const containsValue = (v: mixed) =>
  typeof v === "string" && v.trim().length > 0;

export function removeSearchURL(
  currentQuery: TFilterQuery,
  routes: Translate
): string {
  const location = queryToLocation(
    {
      ...currentQuery,
      search: "",
    },
    routes
  );

  return location.pathname + location.search;
}

export function removeBrandURL(
  currentQuery: TFilterQuery,
  routes: Object
): string {
  const query = {
    ...currentQuery,
    filter: {
      ...currentQuery.filter,
    },
  };

  delete query.filter.brand;

  const location = queryToLocation(query, routes);

  return location.pathname + location.search;
}

export function removeCategoryURL(
  currentQuery: TFilterQuery,
  routes: Object
): string {
  const query = {
    ...currentQuery,
    filter: {
      ...currentQuery.filter,
    },
  };

  delete query.filter.main_category;

  const location = queryToLocation(query, routes);

  return location.pathname + location.search;
}

export function filterToggleURL(
  currentQuery: TFilterQuery,
  facet: string,
  bucket: string,
  routes: Translations
): string {
  if (Object.keys(currentQuery).length === 0) {
    throw new Error("Filter Query is empty");
  }

  const currentFacet = (currentQuery.filter[facet] || []).slice();
  const i = currentFacet.indexOf(bucket);

  // toggle
  if (i === -1) {
    currentFacet.push(bucket);
  } else {
    currentFacet.splice(i, 1);
  }

  const location = queryToLocation(
    {
      ...currentQuery,
      filter: {
        ...currentQuery.filter,
        [facet]: currentFacet,
      },
    },
    routes
  );

  return location.pathname + location.search;
}

export function filterResetURL(
  currentQuery: TFilterQuery,
  routes: Object
): string {
  const location = queryToLocation(
    {
      ...currentQuery,
      filter: {},
      search: "",
    },
    routes
  );

  return location.pathname + location.search;
}

export function setSortURL(
  currentQuery: TFilterQuery,
  sort: TSort | "",
  routes: Object
): string {
  const oldSort = currentQuery.sort;

  const location = queryToLocation(
    {
      ...currentQuery,
      sort: (sort === oldSort || !sort) ? null : sort,
    },
    routes
  );

  return location.pathname + location.search;
}

export function queryToLocation(
  query: TFilterQuery,
  routes: Object
): { pathname: string, search: string } {
  let { main_category = [], brand = [], ...filter } = query.filter;
  let prefix = query.customerType
    ? routes["RECOMMENDED"] + "/" + query.customerType
    : query.product
    ? routes["RECOMMENDED"] + "/" + routes["PRODUCT"] + "/" + query.product
    : "p";

  if (query.onWishlist === true) {
    prefix = routes["MY_PRODUCTS"];
  }

  // sort by 'popularity' is the default
  if (query.sort && query.sort === DEFAULT_SORT) {
    delete query.sort;
  }

  if (!Array.isArray(brand)) {
    brand = [brand];
  }

  if (!Array.isArray(main_category)) {
    main_category = [main_category];
  }

  main_category.sort();
  brand.sort();

  const params = {};

  if (!Array.isArray(query.sort) && parseSort(query.sort) && query.sort) {
    params["sort"] = query.sort;
  }

  if (!Array.isArray(query.search) && query.search) {
    params["search"] = query.search;
  }

  const searchParams = ({ ...filter, ...params }: Object);

  return {
    pathname: `/${prefix}/${main_category.join(".") || ""}_${
      brand.join(".") || ""
    }`.replace(/_+$/, ""),
    search: stringifyParams(searchParams),
  };
}

const parseSort = (a?: ?string | Array<string>): TSort | null => {
  if (
    a === "name" ||
    a === "brand" ||
    a === "purchases" ||
    a === "popularity" ||
    a === "position" ||
    a === "main_category" ||
    a === "ooh_unitprice"
  ) {
    return a;
  }

  return null;
};

/**
 * category = "name"
 * brand    = "_name"


 * p/(:main_category)_(:brand)
 * recommended/product/:productUrl
 * recommended/:customerType
 */
export function locationToQuery(
  location: Location,
  routes: Object
): TFilterQuery {
  if (!location) {
    return {};
  }
  const parts = location.pathname.split("/").filter((x) => x);
  const {
    search = null,
    sort = DEFAULT_SORT,
    ...searchParams
  } = parseParams(location.search);
  let customerType = null;
  let product = null;
  let onWishlist = false;

  if (parts[0] === routes["MY_PRODUCTS"]) {
    onWishlist = true;
  }

  switch (parts[0]) {
    case routes["RECOMMENDED"]:
      if (parts[1] === routes["PRODUCT"]) {
        product = parts[2];

        parts.splice(0, 2);
      } else {
        customerType = parts[1];

        parts.splice(0, 1);
      }

    // fallthrough intended
    case routes["MY_PRODUCTS"]:
    case "p":
      const [main_category, brand] = (parts[1] || "")
        .replace(/[_ ]+$/g, "")
        .split("_")
        .map((s) => s.trim());

      if (main_category) {
        searchParams.main_category = main_category
          .split(".")
          .map(decodeURIComponent)
          .filter(containsValue);
      }
      if (brand) {
        searchParams.brand = brand
          .split(".")
          .map(decodeURIComponent)
          .filter(containsValue);
      }

      break;
  }

  return {
    sort: Array.isArray(sort) ? null : parseSort(sort),
    search: Array.isArray(search) ? null : search,
    customerType: customerType,
    product: product,
    onWishlist: onWishlist,
    filter: normalizeFilter(searchParams),
  };
}

const filterToString =
  (string: Array<string>, query: Object) =>
  (field, dependencies = [], onlyShowOnSingle = true) => {
    const allowed = dependencies.filter((x) => query.filter[x]).length === 0;
    if (query.filter[field] && allowed) {
      if (onlyShowOnSingle && query.filter[field].length === 1) {
        if (string.length) {
          string.push(query.filter[field][0].toLowerCase());
        } else {
          string.push(query.filter[field][0]);
        }
      }
      if (!onlyShowOnSingle) {
        for (var i = 0; i < query.filter[field].length; i++) {
          if (string.length) {
            string.push(query.filter[field][i].toLowerCase());
          } else {
            string.push(query.filter[field][i]);
          }
          string.push(", ");
        }
        string.pop();
      }
    }
  };

const PRIMARY_ATTRIBUTES = [
  "brand",
  "certified",
  "color",
  "main_category",
  "ooh_product_type",
];

const CANONICAL_ATTRIBUTES = [
  "brand",
  "certified",
  "main_category",
  "ooh_product_type",
];

export const generateCanonical = (pathname: string, filterQuery: TFilterQuery, facets: TFacet[], routes: Object): { pathname: string, search: string } => {
  let canonicalFilters = {};
  let activePosition3FacetKey: null | string = null;
  let filterKeys = Object.keys(filterQuery.filter);

  for (const facet of facets) {
    // only add facet if position is 3, and the facet doesnt contain more than 1 selected option
    if (!activePosition3FacetKey && facet.position === 3 && facet.containsActive && facet.buckets.filter(b => b.active).length === 1) {
      activePosition3FacetKey = facet.key;
      break;
    }
  }

  if (activePosition3FacetKey) {
    canonicalFilters[activePosition3FacetKey] = filterQuery.filter[activePosition3FacetKey];
  }

  for (let i = 0; i < filterKeys.length; i++) {
    const key = filterKeys[i];

    if (!CANONICAL_ATTRIBUTES.includes(key)) {
      continue;
    }

    canonicalFilters[key] = filterQuery.filter[key];
  }

  const result = queryToLocation({
    ...filterQuery,
    filter: canonicalFilters,
  }, routes);

  result.pathname = `https://outofhome.se${result.pathname}`;
  return result;
}

const decodeCustomerType = (customerType: string): string => {
  const parts = decodeURIComponent(customerType).split("/");

  if (parts.length === 1) {
    return parts[0];
  }

  return parts.reduce((acc, curr, idx, array) => {
    if(idx === 0) {
      return curr;
    }

    if (idx === array.length - 1) {
      return `${acc} & ${curr}`
    }

    return `${acc}, ${curr}`;
  }, "");
}

export function filterTitle(t: Translate, query: TFilterQuery, facets: TFacet[], product: any): [string, string] {
  let title = [];
  const subTitle = [];
  const filteredFacets = [];
  let activePosition3Facet = null;

  for (const facet of facets) {
    // facets with position 3 are attributes like "Typ av Olja"
    if (facet.position === 3 && facet.containsActive) {

      if (!activePosition3Facet) {
        activePosition3Facet = facet;
      }

      continue;
    }

    if (
      facet.containsActive &&
      PRIMARY_ATTRIBUTES.includes(facet.key) === false
    ) {
      // use facet key for boolean type attributes with bucket key "Ja"
      if (
        facet.buckets.find(
          (x) => x.key === t("BOOLEAN_VALUES.YES") && x.active
        )
      ) {
        filteredFacets.push({
          key: t("BOOLEAN_VALUES.WITH") + " " + facet.title,
        });
        continue;
      }

      // use facet key for boolean type attributes with bucket key "Nej"
      if (
        facet.buckets.find(
          (x) => x.key === t("BOOLEAN_VALUES.NO") && x.active
        )
      ) {
        filteredFacets.push({
          key: t("BOOLEAN_VALUES.WITHOUT") + " " + facet.title,
        });
        continue;
      }

      const activeBuckets = facet.buckets.filter((x) => x.active);

      // add all active buckets
      if (activeBuckets.length > 0) {
        filteredFacets.push({
          position: facet.position,
          buckets: activeBuckets.map((x) => x.key),
        });
      }
    }
  }

  if (query.search) {
    title.push(t("FILTER.TITLE_SEARCH", { search: query.search }));
  }

  const toTitle = filterToString(title, query);
  const toSubTitle = filterToString(subTitle, query);

  for (const facet of filteredFacets) {
    const buckets = facet.buckets || [];

    // skip if multiple buckets is selected
    if (buckets.length > 1) {
      continue;
    }

    const titleSegment = buckets[0] || facet.key || "";

    // prefix main title with attributes with position 5
    if (facet.position === 5) {
      title.push(titleSegment);
    } else {
      // prefix sub title with attributes without position 5
      subTitle.push(titleSegment);
    }
  }

  toTitle("certified");
  toTitle("color", [], false);

  if (activePosition3Facet) {
    const buckets = activePosition3Facet.buckets.filter(x => x.active);
    let text = buckets
      .map(x => x.key)
      .reduce((acc, curr, idx, arr) => {
        if (!acc) {
          return curr;
        }

        if (idx > 0 && idx === arr.length - 1) {
          return `${acc} & ${curr.toLowerCase()}`
        }
        else if (idx > 0) {
          return `${acc}, ${curr.toLowerCase()}`
        }

        return acc;
      }, "");

    // lowercase if not first in title
    text = title.length ? text.toLowerCase() : text

    title.push(text);
  }
  else {
    toTitle("main_category", ["ooh_product_type"], false);
    toTitle("ooh_product_type");
  }

  if (query.customerType) {
    title = [t("FILTER.TITLE_CUSTOMER_TYPE_HEAD"), ...title.map(x => x.toLowerCase())]
  }

  if (product) {
    title.push(t("FILTER.TITLE_RELATED_PRODUCTS", { product }));
  }

  if (query.filter.brand) {
    if (
      !title.length ||
      (!query.filter.ooh_product_type && !query.filter.main_category && !activePosition3Facet)
    ) {
      title.push(t("FILTER.TITLE_BRAND_ALONE", { brand: query.filter.brand }));
    } else {
      title.push(t("FILTER.TITLE_BRAND", { brand: query.filter.brand }));
    }
  }

  if (query.filter.manufacturer && !title.length) {
    title.push(
      t("FILTER.TITLE_MANUFACTURER_ALONE", {
        manufacturer: query.filter.manufacturer,
      })
    );
  }

  if (query.customerType) {
    const customerType: string = decodeCustomerType(query.customerType);
    title.push(t("FILTER.TITLE_CUSTOMER_TYPE_TAIL", { customerType: customerType.toLowerCase() }));
  }

  if (Object.keys(query.filter).includes("season")) {
    return [query.filter.season.join(" "), ""];
  }

  if (title.length) {
    return [title.join(" "), subTitle.join(", ")];
  }

  return [t("FILTER.TITLE_EMPTY"), subTitle.join(", ")];
}

export function filterMetaDescription(
  t: Translate,
  query: TFilterQuery,
  meta: MetaResponse
): string {
  let description = [];
  const toTitle = filterToString(description, query);
  const fallbackMeta = meta.data.find((x) => x.name === "description");

  toTitle("main_category", ["ooh_product_type"], false);
  toTitle("ooh_product_type");

  if (query.filter.brand) {
    description.push(t("FILTER.TITLE_BRAND", { brand: query.filter.brand }));
  }

  if (description.length) {
    return t("FILTER.DESCRIPTION_FILTERED", { str: description.join(" ") });
  }

  return (fallbackMeta && fallbackMeta.content) || "";
}

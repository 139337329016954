/* @flow */

import type { CmsBlock } from "../../types/cmsPage.flow";

import React, { useRef, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./styles.scss";
import { Wrapper } from "../Wrapper";
import { Input } from "../Input";
import { Button } from "../Button";
import { Nag } from "../Nag";
import { useTranslate } from "@out-of-home/use-translate";
import { useRestClient } from "../../entrypoint/shared";
import { useFrontChatContext } from "../../context/front-chat";

const BLOCK_IDS = [
  "omoss_1",
  "omoss_2",
  "omoss_3",
  "omoss_4",
  "omoss_5",
  "omoss_6",
  "omoss_7",
];

export const AboutUsView = (): React$Node => {
  const t = useTranslate();
  const client = useRestClient();
  const [blocks, setBlocks] = useState();
  const { open: openChatWindow, loading: chatLoading } = useFrontChatContext();

  useEffect(() => {
    client(`/api/staticblocks/${BLOCK_IDS.join(",")}`)
      .then((x) => x.json())
      .then((x) => {
        const o = {};
        const keys = Object.keys(x);

        for (const key of keys) {
          o[x[key].id] = x[key];
        }

        setBlocks(o);
      });
  }, []);

  if (!blocks) {
    return null;
  }

  return (
    <div className={styles.block}>
      <section className={styles.cms1}>
        <Wrapper className={styles.section}>
          <h1>{t("ABOUT_US.HEADING")}</h1>
          <div className={styles.container}>
            <div
              className={styles.cms1Content}
              dangerouslySetInnerHTML={{ __html: blocks["omoss_1"].content }}
            />
            <div className={styles.cms1Image}>
              <img src="/assets/images/ooh_ring.png" alt="Outofhome" />
            </div>
          </div>
        </Wrapper>
        <img
          className={styles.cms1ImageSmall}
          src="/assets/images/ooh_ring.png"
          alt="Outofhome"
        />
      </section>

      <div className={styles.section}>
        <section className={styles.cms2}>
          <div className={styles.cms2Image}>
            <img src="/assets/images/about_us.jpg" alt="Om oss" />
          </div>
          <div
            className={styles.cms2Content}
            dangerouslySetInnerHTML={{ __html: blocks["omoss_2"].content }}
          />
        </section>
      </div>

      <Wrapper className={styles.section}>
        <div className={styles.two}>
          <div
            className={styles.cms3}
            dangerouslySetInnerHTML={{ __html: blocks["omoss_3"].content }}
          />
          <div
            className={styles.cms4}
            dangerouslySetInnerHTML={{ __html: blocks["omoss_4"].content }}
          />
        </div>
        <div className={styles.two}>
          <div
            className={styles.cms5}
            dangerouslySetInnerHTML={{ __html: blocks["omoss_5"].content }}
          />
          <div
            className={styles.cms6}
            dangerouslySetInnerHTML={{ __html: blocks["omoss_6"].content }}
          />
        </div>

        <div className={styles.links}>
          <ul>
            <li>
              <Link className="link" to="/">
                {t("ABOUT_US.FRONTPAGE")}
              </Link>
            </li>
            <li>
              <Link className="link" to={t("ROUTES.BECOME_SUPPLIER")}>
                {t("ABOUT_US.BECOME_SUPPLIER")}
              </Link>
            </li>
            <li>
              <Link className="link" to={t("ROUTES.EMPLOYEES")}>
                {t("ABOUT_US.EMPLOYEES")}
              </Link>
            </li>
            <li>
              <Link className="link" to={t("ROUTES.BRANDS")}>
                {t("ABOUT_US.BRANDS")}
              </Link>
            </li>
          </ul>
        </div>
      </Wrapper>

      <Nag>
        <Button className={styles.nagButton} to={"/" + t("ROUTES.REGISTER") + "/1"} variant="primary">
          {t("NAG.REGISTER")}
        </Button>

        <Button className={cn(styles.nagButton)} variant="ghost" onClick={openChatWindow} loading={chatLoading}>
          {t("NAG.CONTACT_US")}
        </Button>
      </Nag>

      <Helmet title={t("ABOUT_US.TITLE")}>
        <meta name="description" content={t("ABOUT_US.DESCRIPTION")} />
        <meta property="og:description" content={t("ABOUT_US.DESCRIPTION")} />
        <meta property="og:title" content={t("ABOUT_US.TITLE")} />
      </Helmet>
    </div>
  );
};

/* @flow */

import React from "react";
import { useFormat } from "../../helpers/use-format";

type Props = {
  className?: string,
  price: number,
};

/**
 * Styles for a small component displaying product price(s)
 */
export const ProductPrice = ({ price, className }: Props): React$Node => {
  const { formatPrice } = useFormat();

  return <div className={className}>{formatPrice(price)}</div>;
};

/* @flow */

import type { Level, Content } from "../../state/messages";

import React, { useState, useRef, useEffect } from "react";
import { LoginForm } from "../Login";
import { useData } from "crustate/react";
import { MessagesData } from "../../data";
import styles from "./styles.scss";
import CloseIcon from "../../icons/close.svg";
import { Dialogue } from "../Dialogue";
import SystemMessages from "../SystemMessages";
import { useTranslate } from "@out-of-home/use-translate";

type Props = {
  open: boolean,
  setOpen: boolean => void,
};

type MsgProps = {
  content: Content,
  level: Level,
  translated: boolean,
};

const Message = ({ content, level, translated }: MsgProps) => {
  const t = useTranslate();
  const text =
    typeof content === "string"
      ? t("STATUS_CODE." + content)
      : t("STATUS_CODE." + content.translationKey, content.variable);

  return (
    <div className={styles.message}>
      {translated && typeof content === "string" ?
        content :
        text
      }
    </div>
  );

}

export const LoginModal = ({ open, setOpen }: Props): React$Node => {
  const messages = useData(MessagesData);

  return (
    <Dialogue
      open={open}
      setOpen={setOpen}
      className={styles.content}
    >
      <header className={styles.header}>
        <p className={styles.heading}>Logga in</p>
        <button onClick={() => setOpen(false)}>
          <CloseIcon />
        </button>
      </header>
      <div className={styles.messages}>
        {messages.length > 0 && (
          <Message key={messages[0].id} {...messages[0]} />
        )}
      </div>
      <LoginForm />
    </Dialogue>
  );
}

/* @flow */

import type {
  CustomerAddressInput,
  CustomerAddress,
} from "../../types/customer.flow";
import type { QuoteAddress } from "../../types/quote.flow";

import React from "react";
import styles from "./styles.scss";
import cn from "classnames";
import { Field } from "../Field";
import { useTranslate } from "@out-of-home/use-translate";
import { Input } from "../Input";
import { Dropdown } from "../Dropdown";
import { useCustomer } from "../../helpers/use-customer";

type Props = {
  className?: string,
  heading?: string,
  name: string,
  type: "billing" | "shipping",
  readOnly?: boolean,
};

type AddressLabelInput = {
  street: Array<string>,
  postcode: string,
  city: string,
  company: string,
};

const ID = (x) => x;

export const addressLabel = (address: AddressLabelInput): ?string => {
  const street =
    typeof address.street === "object" && Object.values(address.street);

  return address
    ? [
        address.company,
        (street || []).filter(ID).join(" "),
        [address.postcode, address.city].filter(ID).join(" "),
      ]
        .filter(ID)
        .join(", ")
    : null;
};

const normalizeAddress = (a: any): QuoteAddress | null => {
  if (!a) {
    return null;
  }

  const {
    type,
    id,
    countryCode,
    isDefaultBilling,
    isDefaultShipping,
    street_1,
    street_2,
    ...address
  } = a;

  const r = { ...a };

  if (countryCode) {
    r.country = { code: countryCode };
  }

  if (street_1) {
    r.street = [street_1, street_2];
  }

  return r;
};

export const addressCmp =
  (addressA: any): ((any) => boolean) =>
  (addressB: any): boolean => {
    const a = normalizeAddress(addressA);
    const b = normalizeAddress(addressB);

    return (
      a !== null &&
      b !== null &&
      a.firstname === b.firstname &&
      a.lastname === b.lastname &&
      a.company === b.company &&
      a.street &&
      b.street &&
      a.street[0] === b.street[0] &&
      a.street[1] === b.street[1] &&
      a.postcode === b.postcode &&
      a.city === b.city &&
      a.country.code === b.country.code &&
      a.telephone === b.telephone
    );
  };

export const Address = ({
  className,
  readOnly = false,
  name,
  type,
  heading,
}: Props): React$Node => {
  const t = useTranslate();
  const { loggedIn } = useCustomer();

  return (
    <div className={cn(className, styles.block)}>
      {heading ? <h2 className={styles.heading}>{heading}</h2> : null}

      <fieldset className={styles.fieldset}>
        <div className={cn(styles.row, styles.row__full)}>
          <Field
            name={name + ".company"}
            className={styles.input}
            readOnly={readOnly}
            variant="boxed"
            autocomplete="organization"
            label={t("ADDRESS.COMPANY")}
            variant="box"
          />
        </div>

        <div className={styles.row}>
          <div className={styles.column}>
            <Field
              name={name + ".firstname"}
              className={styles.input}
              readOnly={readOnly}
              autoComplete={type + " given-name"}
              label={t("ADDRESS.FIRSTNAME")}
              variant="box"
            />
          </div>

          <div className={styles.column}>
            <Field
              name={name + ".lastname"}
              className={styles.input}
              readOnly={readOnly}
              autoComplete={type + " family-name"}
              label={t("ADDRESS.LASTNAME")}
              variant="box"
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.column}>
            <Field
              name={name + ".street.0"}
              className={styles.input}
              readOnly={readOnly}
              autoComplete={type + " address-line1"}
              label={t("ADDRESS.STREET")}
              variant="box"
            />
          </div>

          <div className={styles.column}>
            <Field
              name={name + ".street.1"}
              className={styles.input}
              readOnly={readOnly}
              autoComplete={type + " address-line2"}
              label={t("ADDRESS.STREET_2")}
              variant="box"
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.column}>
            <Field
              name={name + ".postcode"}
              className={styles.input}
              readOnly={readOnly}
              autoComplete={type + " postal-code"}
              label={t("ADDRESS.POSTCODE")}
              variant="box"
            />
          </div>

          <div className={styles.column}>
            <Field
              name={name + ".city"}
              className={styles.input}
              readOnly={readOnly}
              autoComplete={type + " address-level2"}
              label={t("ADDRESS.CITY")}
              variant="box"
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.column}>
            <Field
              name={name + ".telephone"}
              className={styles.input}
              readOnly={readOnly}
              autoComplete={type + " tel"}
              label={t("ADDRESS.TELEPHONE")}
              variant="box"
            />
          </div>

          {type === "shipping" && !loggedIn && (
            <div className={styles.column}>
              <Field
                name="email"
                className={styles.input}
                readOnly={readOnly}
                autoComplete="email"
                type="email"
                label={t("ADDRESS.EMAIL")}
                variant="box"
              />
            </div>
          )}
        </div>
      </fieldset>
    </div>
  );
};

/* @flow */

import React, { useState, useContext } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { useTranslate } from "@out-of-home/use-translate";
import { StripeContext } from "./context";

type CSSModule = { [key: ?string]: string };

type StripeCardProps = {
  disabled: boolean,
  processing: boolean,
  styles: CSSModule,
  Button: React$ComponentType<any>,
  options?: {
    style: {
      base: { [string]: string | { [string]: string } },
      invalid: { [string]: string | { [string]: string } },
    },
  },
};

type ElementEvent = {
  elementType: string,
  error: {
    elementType: string,
    message: string,
  },
};

const defaultOptions = {
  style: {
    base: {
      color: "#222",
      letterSpacing: "0.025em",
      fontSize: "16px",
      "::placeholder": {
        color: "#555",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

export const StripeCardComponent = ({
  options = defaultOptions,
  processing,
  disabled,
  styles,
  Button,
}: StripeCardProps) => {
  const t = useTranslate();
  const [fieldsFilled, setFieldsFilled] = useState<{ [k: string]: boolean }>(
    {}
  );
  const [dirty, setDirty] = useState(false);
  const [error, setError] = useState(null);
  const FIELDS = {
    cardNumber: "Card number missing",
    cardExpiry: "Card expiry date missing",
    cardCvc: "Card CVC missing",
  };

  const onChange = (e: ElementEvent) => {
    if (e.error) {
      return setError({ type: [e.elementType], msg: e.error.message });
    }

    if (e.empty) {
      setFieldsFilled({ ...fieldsFilled, [e.elementType]: false });
    } else {
      setFieldsFilled({ ...fieldsFilled, [e.elementType]: true });
      setError(null);
    }
  };

  const fieldEmptyError = Object.keys(FIELDS)
    .map((f) => (!fieldsFilled[f] ? FIELDS[f] : null))
    .find((x) => x);

  return (
    <div className={styles.stripeBlock}>
      <div className={styles.stripeRow}>
        <label className={styles.stripeLabel}>
          {t("STRIPE.CARD_NUMBER")}
          <CardNumberElement {...options} onChange={onChange} />
        </label>
      </div>

      <div className={styles.stripeRow}>
        <div className={styles.stripeSplit}>
          <div className={styles.stripeLeft}>
            <label className={styles.stripeLabel}>
              {t("STRIPE.MM/YY")}
              <CardExpiryElement {...options} onChange={onChange} />
            </label>
          </div>
          <div className={styles.stripeRight}>
            <label className={styles.stripeLabel}>
              {t("STRIPE.CVC")}
              <CardCvcElement {...options} onChange={onChange} />
            </label>
          </div>
        </div>
      </div>

      {error && <div className={styles.stripeError}>{error.msg}</div>}
      {dirty && fieldEmptyError && (
        <div className={styles.stripeError}>{fieldEmptyError}</div>
      )}

      <Button
        className={styles.stripeButton}
        variant="primary"
        loading={processing}
        disabled={processing || disabled}
        onClick={(e) => {
          setDirty(true);
          if (error || fieldEmptyError) {
            e.preventDefault();
          }
        }}
      >
        {processing ? t("STRIPE.PROCESSING") : t("CHECKOUT.PAY_WITH_CARD")}
      </Button>
    </div>
  );
};

const CustomPaymentRequestButton = ({
  Button,
  styles,
  disabled,
}: {
  Button: React$ComponentType<any>,
  styles: CSSModule,
  disabled: boolean,
}) => {
  const t = useTranslate();

  return (
    <div>
      <Button variant="primary" disabled={disabled} className={styles.button}>
        {t("PAYMENT.USE_SAVED_CARD")}
      </Button>
    </div>
  );
};

const ApplePayButton = ({ disabled }: { disabled: boolean }) => (
  <button
    lang="en"
    aria-label="Apple Pay"
    role="button"
    type="submit"
    className="apple-pay-button"
    style={{ width: "100%", marginBottom: "1em" }}
    disabled={disabled}
  />
);

export const StripeButtonComponent = ({
  processing,
  styles,
  disabled,
  Button,
}: {
  processing: boolean,
  styles: CSSModule,
  disabled: boolean,
  Button: React$ComponentType<any>,
}) => {
  const { browser, canMakeButtonPayment } = useContext(StripeContext);

  if (!canMakeButtonPayment) {
    return null;
  }

  if (processing) {
    return (
      <Button loading className={styles.stripeButton} variant="primary">
        &nbsp;
      </Button>
    );
  }

  return (
    <div>
      {browser === "apple" ? (
        <ApplePayButton disabled={disabled} />
      ) : (
        <CustomPaymentRequestButton
          Button={Button}
          disabled={disabled}
          variant="primary"
          browser={browser}
          styles={styles}
        />
      )}
    </div>
  );
};

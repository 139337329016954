/* @flow */

import type {
  WFMOrder,
  WFMListOrder,
  WFMOrderProduct,
} from "../../types/wfm.flow";

import React, { useState, useEffect, useRef } from "react";
import { jsonResponse } from "@out-of-home/fetch-utils";
import cn from "classnames";
import { Dropdown, DropdownItem } from "../Dropdown";
import { useClient } from "../../entrypoint/shared";
import { useCustomer } from "../../helpers/use-customer";
import { useFormat } from "../../helpers/use-format";
import sharedStyles from "./shared-styles.scss";
import { getWFMOrders, WFMOrderById } from "../../queries";

type Props = {
  className?: string,
  order: ?WFMOrder,
  setOrder: (WFMOrder) => void,
};

type State = {
  loading: boolean,
  orderToLoad: number,
};

const MAX_NUM_ORDERS = 3;

const today = new Date();

const filterOrder = (x: WFMListOrder, i: number) => {
  return true;
  if (i >= MAX_NUM_ORDERS) {
    return false;
  }

  return (
    today - new Date(x.createdAt) <
    1000 /*ms*/ * 60 /*s*/ * 60 /*min*/ * 24 /*h*/ * 30 /*days*/ * 3 /*months*/
  );
};

export const OrderSelect = ({ order, setOrder }: Props): React$Node => {
  const [orders, setOrders] = useState<Array<WFMListOrder>>([]);
  const [loading, setLoading] = useState(true);
  const [orderToLoad, setOrderToLoad] = useState<string | null>(null);
  const { loggedIn } = useCustomer();
  const client = useClient();
  const { formatDate } = useFormat();
  const aborted = useRef(false);

  const loadOrder = async (id: string) => {
    const x = await client(WFMOrderById, { id });

    if (!aborted.current) {
      setOrder(x.orderById);
      setLoading(false);
    }
  };

  const loadOrders = async () => {
    const x = await client(getWFMOrders);

    if (!aborted.current) {
      setLoading(false);
      setOrders(x.customer.orders.items.filter(filterOrder));
    }
  };

  useEffect(() => {
    if (loggedIn) {
      loadOrders();
    }

    return () => {
      aborted.current = true; // to make sure the async functions doesnt try to update state when the component is unmounted
    };
  }, []);

  useEffect(() => {
    if (orderToLoad !== null) {
      loadOrder(orderToLoad);
      setOrderToLoad(null);
    }
  }, [orderToLoad]);

  if (orders.length === 0 && !loading) {
    return (
      <p className={sharedStyles.message}>
        Ni har inte lagt någon order de senaste tre månaderna
      </p>
    );
  }
  return (
    <Dropdown
      label="Välj en order"
      maxHeight={500}
      value={order && order.id}
      disabled={loading}
      onChange={(x) => {
        setLoading(true);
        setOrderToLoad(String(x.value));
      }}
    >
      {orders.map((x) => (
        <DropdownItem key={x.id} value={x.id}>
          <span>
            {x.id}&nbsp;&nbsp;&nbsp;<small>({formatDate(x.createdAt)})</small>
          </span>
        </DropdownItem>
      ))}
    </Dropdown>
  );
};

/* @flow */

import type { Model } from "crustate";
import { updateData } from "crustate";
import type { ProductAbstract } from "../types/product.flow";

export type Message = {
  markup: React$Node,
};

type Data = Array<Message & { id: number }>;

export type AddMessage = { tag: typeof ADD_MESSAGE, message: Message };
export type AddMessageTranslated = {
  tag: typeof ADD_MESSAGE,
  message: Message,
};
export type RemoveMessage = { tag: typeof REMOVE_MESSAGE, id: number };
export type ClearMessages = { tag: typeof CLEAR_MESSAGES };

const maxId = (messages: Data) =>
  messages.reduce((a, t) => Math.max(a, t.id), 0);

export const ADD_MESSAGE: "stock-messages/add" = "stock-messages/add";
export const REMOVE_MESSAGE: "stock-messages/remove" = "stock-messages/remove";
export const CLEAR_MESSAGES: "stock-messages/clear" = "stock-messages/clear";

export const addMessage = (markup: React$Node): AddMessage => ({
  tag: ADD_MESSAGE,
  message: { markup },
});

export const removeMessage = (id: number): RemoveMessage => ({
  tag: REMOVE_MESSAGE,
  id,
});

export const clearMessages = (): ClearMessages => ({
  tag: CLEAR_MESSAGES,
});

export const StockMessagesModel: Model<
  Data,
  any,
  AddMessage | RemoveMessage | ClearMessages
> = {
  id: "stock-messages",
  init: () => {
    return updateData([]);
  },
  update: (state, msg) => {
    switch (msg.tag) {
      case ADD_MESSAGE:
        return updateData([
          ...state,
          {
            ...msg.message,
            id: maxId(state) + 1,
          },
        ]);
      case REMOVE_MESSAGE:
        return updateData(state.filter((x) => x.id !== msg.id));
      case CLEAR_MESSAGES:
        return updateData([]);
      default:
        break;
    }
  },
};

/* @flow */

import React from "react";
import { createTaskBodyShortDate } from "../../../helpers";
import { ReclaimGeneric } from "../reclaim-generic";

type Props = {
  onSuccess: (title: string, text: string) => void,
};

export const ShortDate = (props: Props): React$Node => (
  <ReclaimGeneric
    {...props}
    createTaskBodyFunc={createTaskBodyShortDate}
    onSuccessMessageKey={"STATUS_CODE.WFM_SHORT_DATE__SUCCESS"}
  />
);

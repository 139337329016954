/* @flow */

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useCustomer } from "./use-customer";
import { useTranslate } from "@out-of-home/use-translate";

export const useRedirectCustomerOnMissingCustomerType = (): void => {
  const t = useTranslate();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { loggedIn, customer } = useCustomer();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (!checked && !pathname.includes("checkout") && customer) {
      const { ooh3Type, ooh3Subtype } = customer;
      setChecked(true);

      if (
        !ooh3Type ||
        ooh3Type === "0" ||
        !ooh3Subtype ||
        !ooh3Subtype.length
      ) {
        push(`/${t("ROUTES.ACCOUNT")}/${t("ROUTES.CUSTOMER_TYPE")}`);
      }
    }
  }, [customer, pathname]);
};

// @flow

import type { Model } from "crustate";
import type { Response } from "./util";

import type {
  FilterLocation,
  ProductFilterInput,
  ProductFilterInputPrice,
  ProductSortInput,
  TFilterQuery,
} from "../types/filter.flow.js";

import type { RestAPIProductCard } from "../types/product.flow";
import type { FrontPageBlock } from "../types/my-front-page.flow";

import {
  parseParams,
  stringifyParams,
} from "@out-of-home/location-search-string";
import { updateData, updateNone } from "crustate";

import {
  removeSearchURL,
  removeBrandURL,
  removeCategoryURL,
  filterToggleURL,
  filterResetURL,
  setSortURL,
  queryToLocation,
  locationToQuery,
} from "../helpers/filterHelpers";

export type Pages = Array<{
  page: number,
  items: Array<RestAPIProductCard>,
}>;

export const DEFAULT_SORT = {
  order: "DESC",
  code: "",
};

const endsWith = (str: any, match: any): boolean => {
  if (typeof str !== "string") {
    return false;
  }

  if (str.length <= match.length) {
    return false;
  }

  return str.slice(-match.length) === match;
};

export const getInputFilters = (
  location: FilterLocation,
  incVat: boolean = true,
  pointsToPrice?: (number) => number = (value) => value
): Array<ProductFilterInput | ProductFilterInputPrice> => {
  /* eslint-disable no-unused-vars */
  const { sortOrder, sort, page, ...rest } = parseParams(location.search);
  /* eslint-enable no-unused-vars */

  const filters = Object.keys(rest).reduce((acc, curr: string) => {
    if (curr.startsWith("filter_")) {
      acc[curr.substring(7)] = rest[curr];
    }

    return acc;
  }, {});

  const ranges = Object.keys(filters)
    .filter((x) => endsWith(x, "_min") || endsWith(x, "_max"))
    .reduce((acc, key) => {
      const paramName = key.slice(0, -4);
      const label = key.slice(-4) === "_min" ? "min" : "max";

      if (!acc[paramName]) {
        acc[paramName] = {};
      }

      acc[paramName][label] = parseFloat(filters[key]);
      return acc;
    }, {});

  const rangeFilters = Object.keys(ranges).reduce((acc, key) => {
    let filter = {
      code: key,
    };

    if (key === "price" || key.startsWith("points:")) {
      filter = {
        ...filter,
        incVat,
      };

      if (!isNaN(ranges[key].min)) {
        filter = {
          ...filter,
          minValue:
            key === "price" ? pointsToPrice(ranges[key].min) : ranges[key].min,
        };
      }

      if (!isNaN(ranges[key].max)) {
        filter = {
          ...filter,
          maxValue:
            key === "price" ? pointsToPrice(ranges[key].max) : ranges[key].max,
        };
      }
    } else {
      if (!isNaN(ranges[key].min)) {
        filter = {
          ...filter,
          minValue: ranges[key].min,
        };
      }

      if (!isNaN(ranges[key].max)) {
        filter = {
          ...filter,
          maxValue: ranges[key].max,
        };
      }
    }

    return [...acc, filter];
  }, []);

  const valueFilters = Object.keys(filters)
    .filter((x) => !(endsWith(x, "_min") || endsWith(x, "_max")))
    .reduce((acc, key) => [...acc, { code: key, value: filters[key] }], []);

  return [...rangeFilters, ...valueFilters];
};

export const getInputSort = (
  location: FilterLocation,
  defaultSort: ProductSortInput = DEFAULT_SORT
): ?ProductSortInput => {
  const { sortOrder = defaultSort.order, sort = defaultSort.code } =
    parseParams(location.search);

  if (
    (sortOrder !== "ASC" && sortOrder !== "DESC") ||
    typeof sort !== "string" ||
    sort === ""
  ) {
    return null;
  }

  return {
    order: sortOrder,
    code: sort,
  };
};

export const getPage = (location: FilterLocation): ?number => {
  const page = parseInt(parseParams(location.search).page, 10);

  return page > 0 ? page : null;
};

export const getSearchFromFilter = (
  filters: Array<ProductFilterInput | ProductFilterInputPrice>,
  order?: ?ProductSortInput,
  page?: ?number
) => {
  const params = {};

  if (order) {
    params.sortOrder = order.order;
    params.sort = order.code;
  }

  if (page && page > 0) {
    params.page = page.toString(10);
  }

  filters.forEach((f) => {
    if (!f.minValue && !f.maxValue) {
      params[`filter_${f.code}`] = f.value;
    }

    if (f.minValue) {
      params[`filter_${f.code}_min`] = f.minValue;
    }

    if (f.maxValue) {
      params[`filter_${f.code}_max`] = f.maxValue;
    }
  });

  return stringifyParams(params);
};

type Data =
  | {
      state: "LOADING",
      data: {
        pages: Pages,
        requestedPages: Array<number>,
        pages: Pages,
        total: number,
        query: ?TFilterQuery,
        staticBlocks: Array<FrontPageBlock>,
        pageSize: number,
      },
    }
  | {
      state: "LOADED",
      data: {
        requestedPages: Array<number>,
        pages: Pages,
        total: number,
        query: ?TFilterQuery,
        staticBlocks: Array<FrontPageBlock>,
        pageSize: number,
        suggestions: Array<any>,
      },
    };

export type FilterResponse = {
  tag: typeof FILTER_LOAD_RESPONSE,
  data: {
    pages: Pages,
    query: TFilterQuery,
    staticBlocks: Array<FrontPageBlock>,
    total: number,
    suggestions: Array<any>,
  },
};
export type FilterRequest =
  | {
      tag: typeof FILTER_LOAD_REQUEST,
      location: Location,
      requestedPages: Array<number>,
    }
  | { tag: typeof FILTER_RESET };

export const FILTER_LOAD_RESPONSE: "filter/load/response" =
  "filter/load/response";
export const FILTER_LOAD_REQUEST: "filter/load/request" = "filter/load/request";
export const FILTER_RESET: "filter/reset" = "filter/reset";

export const initialState: Data = {
  state: "LOADING",
  data: {
    pages: [],
    requestedPages: [],
    staticBlocks: [],
    total: 60,
    pageSize: 60,
    query: null,
    suggestions: [],
  },
};

export const reset = (): FilterRequest => ({
  tag: FILTER_RESET,
});

export const load = (
  location: Location,
  requestedPages: Array<number>
): FilterRequest => ({
  tag: FILTER_LOAD_REQUEST,
  location,
  requestedPages,
});

export const FilterModel: Model<
  Data,
  { location: Location, requestedPages: Array<number>, pageSize: number },
  FilterRequest | FilterResponse
> = {
  id: "filter",
  init: ({ location, requestedPages, pageSize }) => {
    // This will run when scrolling down too so maybe make this the only action
    return updateData(
      { ...initialState, state: "LOADING", requestedPages, pageSize },
      { tag: FILTER_LOAD_REQUEST, location, requestedPages, pageSize }
    );
  },
  update: (state: Data, msg) => {
    const prevLoadedPages = state.data.pages.map((x) => x.page);

    let pages;
    switch (msg.tag) {
      case FILTER_LOAD_REQUEST:
        const pagesToLoad = msg.requestedPages.filter(
          (x) => !prevLoadedPages.includes(x)
        );

        if (pagesToLoad.length === 0) {
          return updateNone();
        }

        return updateData(
          {
            state: "LOADING",
            data: {
              ...state.data,
              location: msg.location,
              requestedPages: pagesToLoad,
            },
          },
          { ...msg, pageSize: state.data.pageSize }
        );
      case FILTER_LOAD_RESPONSE:
        if (msg.data.pages) {
          return updateData({
            state: "LOADED",
            data: {
              ...state.data,
              pages: [
                ...state.data.pages,
                ...msg.data.pages.filter(
                  (x) => !prevLoadedPages.includes(x.page)
                ),
              ],
              requestedPages: [],
              staticBlocks: msg.data.staticBlocks,
              total: msg.data.total,
              query: msg.data.query,
              suggestions: msg.data.suggestions,
            },
          });
        }

        break;
      case FILTER_RESET:
        return updateData(initialState);
      default:
    }
  },
};

/* @flow */;

import React from "react";
import styles from "./styles.scss";
import { useTranslate } from "@out-of-home/use-translate";
import CloseIcon from "../../icons/close.svg";

type TFormError = {
  error: string,
  field: string,
};

type Props = {
  dirty: boolean,
  errors: Array<TFormError>,
  getMessage: (string, string) => string,
};

export const FormErrors = ({ dirty, errors, getMessage }: Props) => {
  const t = useTranslate();

  const defaultGetMessage = (error: string, field: string) => {
    return t("VALIDATION." + error);
  };

  if (!dirty) {
    return null;
  }

  return (
    <div>
      {errors.map((x) => (
        <p className={styles.error}>
          <div className={styles.errorIcon}>
            <CloseIcon />
          </div>
          <span>
            {getMessage
              ? getMessage(x.error, x.field)
              : defaultGetMessage(x.error, x.field)}
          </span>
        </p>
      ))}
    </div>
  );
};

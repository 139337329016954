/* @flow */

import type {
  TBucket,
  TFacet,
  TSort,
  TFilterQuery,
} from "../../types/filter.flow";

import React, { useContext, useState } from "react";
import { useTranslate } from "@out-of-home/use-translate";
import styles from "./styles.scss";
import cn from "classnames";
import { UIContext } from "../../context/ui";
import { filterToggleURL } from "../../helpers/filterHelpers";
import { StoreInfoContext } from "../../entrypoint/shared";
import { Drawer, DrawerItem } from "../Drawer";
import { Button } from "../Button";
import { Foldable } from "../Foldable";
import CloseIcon from "../../icons/close.svg";
import FreezeIcon from "../../icons/freeze.svg";
import ColdIcon from "../../icons/cold.svg";
import ChevronIcon from "../../icons/chevron.svg";
import { filterResetURL as bindFilterResetURL } from "../../helpers/filterHelpers";

const OPEN_FILTERS = 4;

const FROZEN_BUCKETS = [
  "Vegetariskt",
  "Kött & Fågel",
  "Fisk & Skaldjur",
  "Frukt & Grönt",
  "Glass",
  "Färdiglagat",
  "Bröd, Bakverk & Dessert",
];

const COLD_BUCKETS = ["Fisk & Skaldjur", "Ost, Mejeri & Ägg"];

type Layout = "LARGE" | "SMALL";

type Props = {
  className?: string,
  facets: Array<TFacet>,
  loading?: boolean,
  layout?: Layout,
  query: TFilterQuery,
};

type FacetProps = {
  layout?: Layout,
  facet: TFacet,
  filterToggleURL: (string, string) => string,
  open: string,
  setOpen: (string) => void,
  toggle?: () => void,
};

type BucketProps = {
  facet: TFacet,
  bucket: TBucket,
  active: boolean,
  layout?: Layout,
  url: string,
  loading: boolean,
};

// transitions
const enter = {
  from: { transform: "scaleY(0)", opacity: "0" },
  to: { transform: "", opacity: "" },
};

const leave = {
  from: { transform: "scaleY(1)", opacity: "1" },
  to: { transform: "scaleY(0)", opacity: "0" },
};

const Facet = ({
  layout,
  facet,
  filterToggleURL,
  toggle,
  open,
  setOpen,
}: FacetProps) => {
  const loading = false;

  const active = facet.buckets.find((x) => x.active);
  const items = facet.buckets;

  const splitFacets = (at) => {
    return [items.slice(0, OPEN_FILTERS), items.slice(OPEN_FILTERS)];
  };

  let visibleItems = items;
  let hiddenItems = [];

  if (facet.expand && active) {
    visibleItems = [active];
  }

  visibleItems = items.slice(0, OPEN_FILTERS);

  if (!facet.expand) {
    hiddenItems = items.slice(OPEN_FILTERS);
  }

  if (layout === "SMALL") {
    return (
      <Drawer
        title={facet.title}
        open={open === facet.title || facet.containsActive}
        onClickTitle={() =>
          open === facet.title ? setOpen("") : setOpen(facet.title)
        }
      >
        {(facet.expand && active ? [active] : items).map((bucket) => {
          const active = bucket.active;
          const toggleURL = filterToggleURL(facet.key, bucket.key);
          return (
            <DrawerItem
              key={facet.key + bucket.key}
              className={cn(styles.drawerItem, {
                [styles.drawerItem__active]: active,
              })}
              disabled={loading}
              active={active}
              to={toggleURL}
            >
              <span>
                {bucket.key}
                {FROZEN_BUCKETS.indexOf(bucket.key) !== -1 && (
                  <FreezeIcon className={styles.icon} />
                )}
                {COLD_BUCKETS.indexOf(bucket.key) !== -1 && (
                  <ColdIcon className={styles.icon} />
                )}
              </span>
              {!active && (
                <span className={styles.bucketCount}>({bucket.count})</span>
              )}
              {active && <CloseIcon className={styles.closeIcon} />}
            </DrawerItem>
          );
        })}
      </Drawer>
    );
  }

  return (
    <div key={facet.key} className={styles.facet}>
      <h3 className={styles.heading}>{facet.title}</h3>

      <div>
        {visibleItems.map((bucket) => {
          const toggleURL = filterToggleURL(facet.key, bucket.key);
          return (
            <Bucket
              key={facet.key + bucket.key}
              active={bucket.active}
              facet={facet}
              bucket={bucket}
              layout={layout}
              url={toggleURL}
              loading={false}
            />
          );
        })}

        {hiddenItems.length > 0 && (
          <Foldable open={Boolean(open)}>
            {hiddenItems.map((bucket) => {
              const toggleURL = filterToggleURL(facet.key, bucket.key);
              return (
                <Bucket
                  key={facet.key + bucket.key}
                  active={bucket.active}
                  facet={facet}
                  bucket={bucket}
                  layout={layout}
                  url={toggleURL}
                  loading={false}
                />
              );
            })}
          </Foldable>
        )}

        {items.length > OPEN_FILTERS && hiddenItems.length > 0 && (
          <div>
            <span className={cn(styles.link, styles.showMore)} onClick={toggle}>
              {!open && <span>Visa alla {facet.title}</span>}{" "}
              {open && <span>Visa färre</span>}
              <ChevronIcon
                className={cn(styles.showMoreIcon, {
                  [styles.showMoreIcon__open]: open,
                })}
              />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const Bucket = ({
  facet,
  bucket,
  loading,
  active,
  layout,
  url,
}: BucketProps): React$Node => {
  const t = useTranslate();
  const name = bucket.key;

  return (
    <Button
      disabled={loading}
      to={url}
      className={cn({
        [styles.link]: true,
        [styles.link__active]: active,
      })}
      onClick={(e) => {
        if (loading) {
          e.preventDefault();
        }
      }}
    >
      <span className={styles.name}>
        <span>{name}</span>
        {FROZEN_BUCKETS.includes(name) && (
          <FreezeIcon className={styles.icon} alt={t("PRODUCT.FROZEN")} />
        )}
        {COLD_BUCKETS.includes(name) && (
          <ColdIcon className={styles.icon} alt={t("PRODUCT.COLD")} />
        )}
      </span>
      {active && <CloseIcon className={cn(styles.icon, styles.iconClose)} />}
      {!active && <span className={styles.count}>({bucket.count})</span>}
    </Button>
  );
};

export const Filters: React$AbstractComponent<Props, mixed> = React.memo(
  ({
    query,
    loading = false,
    className,
    layout = "LARGE",
    ...props
  }: Props): React$Node => {
    const t = useTranslate();
    const queryFilter = query.filter || {};
    const facets = props.facets.filter(
      (x) => x.buckets.length || queryFilter[x.key]
    );
    const routes = useContext(StoreInfoContext).translations.ROUTES;
    const { setFilterModalOpen } = useContext(UIContext);
    const filterResetURL = bindFilterResetURL(query, routes);
    const boundFilterToggleURL = (facet: string, bucket: string) =>
      filterToggleURL(query, facet, bucket, routes);
    const [drawerOpen, setDrawerOpen] = useState("");
    const queryIsPristine = !Object.keys(queryFilter).length && !query.search;

    const [openFacets, setOpenFacets] = useState({});

    const toggleFacet = (facetKey) => {
      const s = openFacets[facetKey];
      setOpenFacets({
        ...openFacets,
        [facetKey]: typeof s === "undefined" ? true : !s,
      });
    };

    if (layout === "SMALL") {
      return (
        <div
          className={cn(styles.block, className, { [styles.loading]: loading })}
        >
          <div className={styles.top}>
            {facets.map((f) => (
              <Facet
                key={f.key}
                open={drawerOpen}
                setOpen={setDrawerOpen}
                facet={f}
                filterToggleURL={boundFilterToggleURL}
                layout={layout}
              />
            ))}
          </div>
          <div className={styles.bottom}>
            <Button
              className={styles.button}
              variant="blank"
              to={filterResetURL}
            >
              {t("FILTER.RESET")}
            </Button>
            <Button
              className={styles.button}
              variant="primary"
              onClick={() => setFilterModalOpen(false)}
            >
              {t("FILTER.ACTIVATE_FILTER")}
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div
        className={cn(styles.block, className, { [styles.loading]: loading })}
      >
        {!queryIsPristine && (
          <Button
            to={filterResetURL}
            alt="Alla produkter"
            className={styles.reset}
            variant="small ghost"
          >
            {t("FILTER.RESET_FILTER")}
          </Button>
        )}

        <div>
          {facets.map((f) => (
            <div key={f.key}>
              <Facet
                open={drawerOpen}
                setOpen={setDrawerOpen}
                facet={f}
                filterToggleURL={boundFilterToggleURL}
                layout={layout}
                toggle={() => toggleFacet(f.key)}
                open={openFacets[f.key]}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
);

/* @flow */

import React from "react";
import styles from "./styles.scss";
import cn from "classnames";

type Props = {
  className?: string,
  children?: React$Node,
};

export const AlcoholMessage = ({ children, className }: Props): React$Node => {
  return (
    <div className={cn(styles.block, className)}>
      <span>{children}</span>
    </div>
  );
};

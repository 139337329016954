/* @flow */

import type { WFMOrder, FaultyDeliveryRows } from "../../../../types/wfm.flow";

import React, { useState, useCallback } from "react";
import cn from "classnames";
import { Button } from "../../../Button";
import { Input } from "../../../Input";
import { useTranslate } from "@out-of-home/use-translate";
import { OrderSelect } from "../../order-select";
import { Dropdown, DropdownItem } from "../../../Dropdown";
import { Field } from "../../../Field";
import { Row } from "../../Row";
import { nextKeyId } from "../../helpers";
import { focusInvalidField } from "../../../../helpers/formHelpers";
import { addMessageTranslated } from "../../../../state/messages";
import PlusIcon from "../../../../icons/plus.svg";
// import { renderContactButton } from "../../../../containers/Order";
import sharedStyles from "../../shared-styles.scss";
import styles from "./styles.scss";
import { nestedRule } from "../../../../helpers/validationRules";
import {
  Form,
  rules,
  conditional,
  isRequired,
  isEmail,
  isNumeric,
} from "@awardit/formaggio";
import { createTaskBodyFaultyDelivery } from "../../helpers";
import { useSubmitCustomerServiceTask } from "../../../../helpers/use-submit-customer-service-task";

type Props = {
  onSuccess: (title: string, text: string) => void,
};

type State = {
  items: FaultyDeliveryRows,
  order: ?WFMOrder,
};

export const FaultyDelivery = (props: Props): React$Node => {
  const t = useTranslate();
  const [processing, setProcessing] = useState(false);
  const [state, setState] = useState<State>({ items: {}, order: null });
  const onSuccess = useCallback(
    () =>
      props.onSuccess("Tack!", t("STATUS_CODE.WFM_FAULTY_DELIVERY__SUCCESS")),
    [t]
  );
  const submit = useSubmitCustomerServiceTask(
    state,
    createTaskBodyFaultyDelivery,
    setProcessing,
    onSuccess
  );

  const renderDropdownHeader = (x) => {
    const item = state.order?.items.find((a) => a.product.sku === x.value);

    if (item) {
      return (
        <span>
          {item.product.name}&nbsp;&nbsp;&nbsp;
          <small>({item.product.attributes.supplierSku})</small>
        </span>
      );
    }

    return <span />;
  };

  const renderRow = (id: string) => {
    const item = state.items[id];

    return (
      <Row
        key={id}
        id={id}
        headerTitle={item.type === "sku" ? "Fel artikel" : "Fel antal"}
        rows={state.items}
        setState={(x) => setState({ ...state, items: x })}
      >
        {item.type === "sku" && (
          <div>
            <div
              className={cn(
                sharedStyles.inputRow,
                sharedStyles.inputRow__shrinkLastChild
              )}
            >
              <Field
                name={`items.${id}.missing_sku`}
                render={(props) => (
                  <Dropdown
                    {...props}
                    headerContent={renderDropdownHeader}
                    label="Artikel som saknas"
                    maxHeight={500}
                    value={item.missing_sku}
                    onChange={(x) =>
                      setState({
                        ...state,
                        items: {
                          ...state.items,
                          [id]: { ...item, missing_sku: x.value },
                        },
                      })
                    }
                  >
                    {(state.order?.items || []).map((x) => (
                      <DropdownItem key={x.product.sku} value={x.product.sku}>
                        <span>
                          {x.product.name}&nbsp;&nbsp;&nbsp;
                          <small>({x.product.attributes.supplierSku})</small>
                        </span>
                      </DropdownItem>
                    ))}
                  </Dropdown>
                )}
              />

              <Field
                label="Antal"
                name={`items.${id}.missing_qty`}
                value={item.missing_qty}
                type="number"
                variant="box"
              />
            </div>

            <div
              className={cn(
                sharedStyles.inputRow,
                sharedStyles.inputRow__shrinkLastChild
              )}
            >
              <Field
                label="Artikel som levererades"
                name={`items.${id}.delivered_sku`}
                value={item.delivered_sku}
                variant="box"
              />

              <Field
                label="Antal"
                name={`items.${id}.delivered_qty`}
                type="number"
                variant="box"
              />
            </div>
          </div>
        )}

        {item.type === "qty" && (
          <div>
            <div className={sharedStyles.inputRow}>
              <Field
                name={`items.${id}.ordered_sku`}
                render={(props) => (
                  <Dropdown
                    {...props}
                    headerContent={renderDropdownHeader}
                    label="Artikel som skulle skickats"
                    maxHeight={500}
                    value={item.ordered_sku}
                    onChange={(x) =>
                      setState({
                        ...state,
                        items: {
                          ...state.items,
                          [id]: { ...item, ordered_sku: x.value },
                        },
                      })
                    }
                  >
                    {(state.order?.items || []).map((x) => (
                      <DropdownItem key={x.product.sku} value={x.product.sku}>
                        <span>
                          {x.product.name}&nbsp;&nbsp;&nbsp;
                          <small>({x.product.attributes.supplierSku})</small>
                        </span>
                      </DropdownItem>
                    ))}
                  </Dropdown>
                )}
              />
            </div>
            <div className={sharedStyles.inputRow}>
              <Field
                label="Antal på följesedel"
                name={`items.${id}.ordered_qty`}
                variant="box"
                type="number"
              />

              <Field
                label="Antal på leverans"
                name={`items.${id}.delivered_qty`}
                variant="box"
                type="number"
              />
            </div>
          </div>
        )}
      </Row>
    );
  };

  const validation = rules(
    Object.keys(state.items).map((x) =>
      state.items[x].type === "sku"
        ? rules([
            nestedRule(
              "items",
              rules([
                nestedRule(
                  x,
                  rules([
                    isRequired("missing_sku"),
                    isRequired("missing_qty"),
                    isNumeric("missing_qty"),
                    isRequired("delivered_sku"),
                    isRequired("delivered_qty"),
                    isNumeric("delivered_qty"),
                  ])
                ),
              ])
            ),
          ])
        : rules([
            nestedRule(
              "items",
              rules([
                nestedRule(
                  x,
                  rules([
                    isRequired("ordered_sku"),
                    isRequired("ordered_qty"),
                    isNumeric("ordered_qty"),
                    isRequired("delivered_qty"),
                    isNumeric("delivered_qty"),
                  ])
                ),
              ])
            ),
          ])
    )
  );

  const errors = validation((state: any));

  const addSkuRow = (e) => {
    e.preventDefault();
    setState({
      ...state,
      items: { ...state.items, [nextKeyId(state.items)]: { type: "sku" } },
    });
  };

  const addQtyRow = (e) => {
    e.preventDefault();
    setState({
      ...state,
      items: { ...state.items, [nextKeyId(state.items)]: { type: "qty" } },
    });
  };

  return (
    <Form
      className={sharedStyles.type}
      value={(state: any)}
      errors={errors}
      onError={focusInvalidField}
      onChange={(x: any) => {
        setState({ ...state, items: x.items });
      }}
      onSubmit={submit}
    >
      {!state.order && (
        <div className="row--large">
          <OrderSelect
            order={state.order}
            setOrder={(x) => setState({ ...state, order: x })}
          />
        </div>
      )}

      {state.order && (
        <div className="row--large">
          <div className="row--large">
            <div className={sharedStyles.rows}>
              {Object.keys(state.items).map((k) => renderRow(k))}
            </div>

            <h2>
              {Object.keys(state.items).length > 0
                ? "Något annat som var fel?"
                : "Vad är det som är fel?"}
            </h2>

            <div className={styles.buttons}>
              <Button
                className={styles.button}
                variant="ghost small"
                onClick={addSkuRow}
              >
                <PlusIcon />
                Fel artikel
              </Button>
              <Button
                className={styles.button}
                variant="ghost small"
                onClick={addQtyRow}
              >
                <PlusIcon />
                Fel antal
              </Button>
            </div>
          </div>
          <div className="row--large">
            {Object.keys(state.items).length > 0 && (
              <Button type="submit" loading={processing} variant="primary">
                Skicka
              </Button>
            )}
          </div>
        </div>
      )}
    </Form>
  );
};

/* @flow */

import React, { useState } from "react";
import cn from "classnames";
import { useSendMessage } from "crustate/react";
import { sendJson } from "@out-of-home/fetch-utils";
import { Dropdown, DropdownItem } from "../../../Dropdown";
import { Input } from "../../../Input";
import { TextArea } from "../../../TextArea";
import { Button } from "../../../Button";
import { Field } from "../../../Field";
import { CustomerService } from "../../../CustomerServiceView";
import { focusInvalidField } from "../../../../helpers/formHelpers";
import { useCustomer } from "../../../../helpers/use-customer";
import { useTranslate } from "@out-of-home/use-translate";
import { useRestClient } from "../../../../entrypoint/shared";
import { addMessageTranslated } from "../../../../state/messages";
import sharedStyles from "../../shared-styles.scss";
import styles from "./styles.scss";

import {
  Form,
  rules,
  conditional,
  isRequired,
  isEmail,
} from "@awardit/formaggio";

type Props = {
  className?: string,
  onSuccess: (title: string, text: string) => void,
};

type State = {
  department: string, // "sales" | "finance" | "customer-service",
  inquiry: ?string,
  inquiry_describe: string,
  feedback: string,
  feedback_phone: ?string,
  loggedIn: boolean,

  email?: string,
};

const validation = rules([
  rules([
    isRequired("inquiry_describe"),
    isRequired("feedback"),
    conditional((s) => !s.loggedIn, rules([isRequired("email")])),
    conditional(
      (s) => s.feedback === "Telefon",
      rules([isRequired("feedback_phone")])
    ),
  ]),
]);

export const ContactUs = (props: Props): React$Node => {
  const sendMessage = useSendMessage();
  const t = useTranslate();
  const { loggedIn, customer } = useCustomer();
  const [processing, setProcessing] = useState(false);
  const [state, setState] = useState<State>({});
  const errors = validation((state: any));
  const client = useRestClient();
  const { email: customerEmail } = customer || { email: null };

  const renderFeedback = () => {
    return (
      <div>
        <h2>Hur vill du få återkoppling</h2>

        <div className="row--large">
          <Field
            value={state.feedback}
            name="feedback"
            render={(p) => (
              <Dropdown
                className={cn(styles.addresses, {
                  [sharedStyles["dropdownErrors"]]:
                    p.submitted && p.errors.length,
                })}
                label="Hur vill du få återkoppling?"
                name="feedback"
                maxHeight={500}
                value={state.feedback}
                onChange={(x) => setState({ ...state, feedback: x.value + "" })}
              >
                <DropdownItem value="Skicka epost">
                  Svara oss på mail
                </DropdownItem>
                <DropdownItem value="Telefon">Bli uppringd</DropdownItem>
              </Dropdown>
            )}
          />
        </div>

        {state.feedback === "Telefon" && (
          <div className="row--large">
            <Field
              name="feedback_phone"
              variant="box"
              label="Telefonnummer"
              autoComplete="telephone"
              type="tel"
            />
          </div>
        )}
      </div>
    );
  };

  const submit = (e) => {
    e.preventDefault();
    const email = state.email || customerEmail;
    const inquiry = state.department === "sales" ? "Sälj" : state.inquiry;
    setProcessing(true);

    if (email === null) {
      return;
    }

    client(
      "/api/contact",
      sendJson({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: ({
          ...state,
          inquiry,
          email,
        }: any),
      })
    )
      .then((x) => x.json())
      .then((x: any) => {
        setProcessing(false);

        if (!x.errorCode) {
          props.onSuccess("Tack!", t("STATUS_CODE.WFM_CONTACT_US__SUCCESS"));
        } else {
          sendMessage(
            addMessageTranslated(`STATUS_CODE.${x.message}`, "error")
          );
        }
      })
      .catch((e) => {
        setProcessing(false);
        sendMessage(addMessageTranslated("STATUS_CODE.WFM__ERROR", "error"));
      });
  };

  const renderEmailField = () => {
    if (loggedIn) {
      return null;
    }

    return (
      <div className="row--large">
        <Field
          name="email"
          variant="box"
          label="E-postadress"
          autoComplete="email"
          type="email"
        />
      </div>
    );
  };

  return (
    <div className={sharedStyles.type}>
      <Form
        value={({ ...state, email: state.email || customerEmail }: any)}
        errors={errors}
        onError={focusInvalidField}
        onChange={(x) => {
          setState({ ...state, ...(x: any) });
        }}
        onSubmit={submit}
      >
        <div className="row--large">
          <h2>Vilken avdelning söker du?</h2>
          <Dropdown
            label="Avdelning"
            name="department"
            headerContent={(x) => {
              return <span>{x.label}</span>;
            }}
            maxHeight={500}
            value={state.department}
            onChange={(x) =>
              setState({ ...state, department: x.value + "", inquiry: null })
            }
          >
            <DropdownItem value="finance">Ekonomi</DropdownItem>
            <DropdownItem value="customer-service">Kundservice</DropdownItem>
            <DropdownItem value="sales">Sälj</DropdownItem>
          </Dropdown>
        </div>

        {state.department === "finance" && (
          <div className="row--large">
            <h2>Vad gäller ditt ärende?</h2>
            <Dropdown
              label="Vad gäller ditt ärende?"
              name="inquiry"
              maxHeight={500}
              value={state.inquiry}
              onChange={(x) => {
                setTimeout(
                  () => setState({ ...state, inquiry: x.value + "" }),
                  0
                );
              }}
            >
              <DropdownItem value="Faktura">Faktura</DropdownItem>
              <DropdownItem value="Fakturaadress">Fakturaadress</DropdownItem>
              <DropdownItem value="Inkassoärende">Inkassoärende</DropdownItem>
              <DropdownItem value="Övrigt">Övrigt</DropdownItem>
            </Dropdown>

            {state.inquiry && (
              <div className="row--large">
                <div className="row--large">
                  <div>
                    <Field
                      variant="box"
                      rows={7}
                      name="inquiry_describe"
                      label="Beskriv ditt ärende"
                      component={TextArea}
                    />
                  </div>
                </div>

                {renderEmailField()}

                <div className="row--large">{renderFeedback()}</div>
              </div>
            )}
          </div>
        )}

        {state.department === "customer-service" && (
          <div className="row--large">
            <div className="row--large">
              <Dropdown
                label="Vad gäller ditt ärende?"
                name="inquiry"
                maxHeight={500}
                value={state.inquiry}
                onChange={(x) => {
                  setTimeout(() => {
                    setState({ ...state, inquiry: x.value + "" });
                  }, 0);
                }}
              >
                <DropdownItem value="Spårning">Spårning</DropdownItem>
                <DropdownItem value="Felplock">Felplock</DropdownItem>
                <DropdownItem value="Transportskada">
                  Transportskada
                </DropdownItem>
                <DropdownItem value="Kort datum">
                  Kort datum vid ankomst
                </DropdownItem>
                <DropdownItem value="Övrigt">Övrigt</DropdownItem>
              </Dropdown>
            </div>
            {state.inquiry && (
              <div className="row--large">
                <div
                  className={cn(
                    sharedStyles.inputRow,
                    sharedStyles.inputRow__tall
                  )}
                >
                  <Field
                    variant="box"
                    rows={7}
                    name="inquiry_describe"
                    label="Beskriv ditt ärende"
                    component={TextArea}
                  />
                </div>

                {renderEmailField()}

                <div className="row--large">{renderFeedback()}</div>
              </div>
            )}
          </div>
        )}

        {state.department === "sales" && (
          <div>
            <div className="row--large">
              <Field
                variant="box"
                rows={7}
                name="inquiry_describe"
                label="Någonting särskilt vi skall förbereda?"
                component={TextArea}
              />
            </div>

            <div className="row--large">{renderEmailField()}</div>

            <div className="row--large">{renderFeedback()}</div>
          </div>
        )}

        {state.department && (state.inquiry || state.department === "sales") && (
          <div className={"row--large"}>
            <div className="row--large">
              <Button loading={processing} variant="primary" type="submit">
                Skicka
              </Button>
            </div>
          </div>
        )}
      </Form>

      <div className={styles.customerService}>
        <CustomerService layout="small" employeeClassName={styles.employee} />
      </div>
    </div>
  );
};

/* @flow */

import type { Node } from "react";

import React, { useMemo } from "react";
import cn from "classnames";
import { getNextInteger } from "../../helpers";
import styles from "./styles.scss";
import CloseIcon from "../../icons/close.svg";

type InputProps = React$ElementProps<"input"> & {
  labelClassName?: string,
  wrapperClassName?: string,
  label?: string,
  variant?: "float" | "box",
  errors?: Array<string>,
  submitted?: boolean,
  required?: boolean,

  slotLeft?: Node,
  slotRight?: Node,

  loading?: boolean,

  forwardedRef?: React$ElementRef<null>,
};

type InputErrorProps = {
  className?: string,
  errors: Array<string>,
};

const getId = (id: ?string): string => {
  return id !== null && typeof id !== "undefined"
    ? id
    : `_id_${getNextInteger()}`;
};

export const InputErrors = ({
  errors,
  className,
}: InputErrorProps): React$Node => {
  return (
    <div className={cn(styles.errors, className)}>
      {errors.map((x) => (
        <div key={x} className={styles.errors__error}>
          {x}
        </div>
      ))}
    </div>
  );
};

export const Input = ({
  slotLeft,
  slotRight,
  id = null,
  className,
  wrapperClassName,
  labelClassName,
  name,
  label,
  errors,
  value,
  forwardedRef,
  variant,
  submitted,
  dirty,
  required = false,
  loading = false,
  ...props
}: InputProps): React$Node => {
  const idProp = useMemo(() => getId(id), [id]);

  const inputLabel =
    label !== undefined ? (
      <label htmlFor={idProp} className={cn(styles.label, labelClassName)}>
        {label}
        {required && <span className={styles.required}>*</span>}
      </label>
    ) : null;

  const input = (
    <input
      {...props}
      id={idProp}
      className={cn(styles.input, className)}
      name={name}
      value={value}
      ref={forwardedRef}
    />
  );

  return (
    <div>
      <div
        className={cn(
          styles.block,
          { [styles.block__active]: value.length > 0 },
          { [styles[variant]]: Boolean(variant) },
          { [styles.block__error]: errors && errors.length > 0 },
          {
            [styles.empty]:
              typeof value === "undefined" || value === null || value === "",
          },
          { [styles.hasSlotLeft]: Boolean(slotLeft) },
          { [styles.hasSlotRight]: Boolean(slotRight) },
          wrapperClassName
        )}
      >
        {slotLeft !== null && typeof slotLeft !== "undefined" && (
          <div className={styles.slotLeft}>
            <div className={styles.slot}>{slotLeft}</div>
          </div>
        )}

        <div style={{ flex: "1 1 auto", minWidth: 0 }}>
          {input}
          {inputLabel}
        </div>

        {(dirty || submitted) && errors && errors[0] && (
          <div className={styles.error}>
            <div className={styles.errorIcon}>
              <CloseIcon />
            </div>
            <p className={styles.errorMessage}>{errors[0]}</p>
          </div>
        )}

        {loading && <div className={styles.loader} />}

        {slotRight !== null && typeof slotRight !== "undefined" && (
          <div className={styles.slotRight}>
            <div className={styles.slot}>{slotRight}</div>
          </div>
        )}
      </div>
    </div>
  );
};

// @flow

import type { TInfoBlock } from "../types/filter.flow";
import type { FrontPageBlock } from "../types/my-front-page.flow";

export function blocksToUIRows(blocks: Array<FrontPageBlock>): Array<{}> {
  const rows: Array<{}> = [];

  for (var i = 0; i < blocks.length; i++) {
    // merge two columns
    let lastRow = rows[rows.length - 1];

    if (!lastRow) {
      lastRow = { products: [], featured: false };
    }

    if (!lastRow.products) {
      lastRow.products = [];
    }

    if (blocks[i].products && blocks[i].products.length === 2) {
      if (
        lastRow.products.length === 2 &&
        !blocks[i].featured &&
        !lastRow.featured
      ) {
        // add to row
        rows[rows.length - 1] = [lastRow, blocks[i]];
      } else {
        // create new row
        rows.push(blocks[i]);
      }
    } else {
      rows.push(blocks[i]);
    }
  }

  return rows;
}

export function infoBlockToUIRow(infoBlock: ?TInfoBlock): ?{} {
  if (!infoBlock?.featuredProducts?.items.length) {
    return null;
  }

  return {
    featured: true,
    products: infoBlock.featuredProducts.items,
    title: infoBlock.featuredProducts.title,
    url: infoBlock.featuredProducts.url,
    type: "MY_CAT_TAKEOVER",
  };
}

export function rowKey(row: FrontPageBlock): string {
  if (row.title !== undefined) {
    return row.title;
  }
  else if (Array.isArray(row)) {
    return row.map(x => x.title).join("-");

  }

  return "";
}

export function removeDuplicates(rows: Array<FrontPageBlock>): Array<FrontPageBlock> {
  const oldTitles = [];

  return rows.filter(x => {
    const key = rowKey(x);
    const result = !oldTitles.includes(key);

    oldTitles.push(key);

    return result;
  });
}


/* @flow */

import type { QuoteItem as TQuoteItem } from "../../types/quote.flow";

import React, { useMemo, useState } from "react";
import styles from "./styles.scss";
import cn from "classnames";
import RemoveIcon from "../../icons/close.svg";
import { outputSalesUnit } from "../../helpers/productHelper";
import { Button } from "../Button";
import { ProductPrice } from "../ProductPrice";
import { QtyPicker } from "../QtyPicker";
import { Prolabels } from "../Prolabels";
import { WishlistToggle } from "../WishlistToggle";
import { useCustomer } from "../../helpers/use-customer";
import { ProductLink } from "../ProductCard";
import { tr } from "date-fns/esm/locale";

type Props = {
  className?: string,
  item: TQuoteItem,
  onRemove?: () => void,
  listName?: string,
  nameAsLink?: boolean,
  split?: boolean,
};

type ConditionalSplitProps = {
  left: () => React$Node,
  right: () => React$Node,
  split?: boolean,
  className?: string,
};
const ConditionalSplit = ({ className, split, left, right }: ConditionalSplitProps) => {
  if (Boolean(split)) {
    return (
      <>
        <tr className={cn(styles.block, className)}>
          <td className={cn(styles.column, styles.column__name)} colSpan="10">
            {left()}
          </td>
        </tr>
        <tr className={cn(styles.block, className, styles.split)}>{right()}</tr>
        <tr className={styles.split__last_row} colSpan="10">
          <td></td>
        </tr>
      </>
    );
  }

  return (
    <tr className={cn(styles.block, className)}>
      <td className={cn(styles.column, styles.column__name)}>{left()}</td>
      {right()}
    </tr>
  );
};

/**
 * Item in OrderOverview and in checkout cart
 */
export const QuoteItem = ({
  item,
  onRemove,
  className,
  listName,
  nameAsLink = false,
  split = false,
}: Props): React$Node => {
  const { customer } = useCustomer();
  const imageSrc = item.product.attributes.smallImage?.x1 || "";

  return (
    <ConditionalSplit
      split={split}
      className={className}
      left={() => (
        <>
          {nameAsLink ? (
            <ProductLink className={styles.name} product={item.product} image={imageSrc} listName={listName}>
              {item.product.name}
            </ProductLink>
          ) : (
            <span className={styles.name}>{item.product.name}</span>
          )}
          <span className={styles.brand}>{item.product.attributes && item.product.attributes.brand}</span>

          <div className={styles.prolabelWrapper}>
            <Prolabels
              className={styles.prolabels}
              labels={item.product.attributeLabels.certified}
              itemClassName={styles.prolabel}
              variant="small inline"
            />
            {/*<WishlistToggle product={item.product} />*/}
          </div>
        </>
      )}
      right={() => (
        <>
          <td className={cn(styles.column, styles.column__weight)}>{outputSalesUnit(item.product.attributes)}</td>
          <td className={cn(styles.column, styles.column__sku)}>#{item.product.attributes.supplierSku}</td>
          <td className={cn(styles.column, styles.column__price)}>
            <ProductPrice className={styles.price} price={item.rowTotal.exVat / item.qty} />
          </td>
          {onRemove && (
            <td className={cn(styles.column, styles.column__qty)}>
              <QtyPicker
                className={styles.qtyPicker}
                product={item.product}
                variant="orderOverview"
                qty={item.qty}
                customer={customer}
                listName={listName}
              />
            </td>
          )}
          {!onRemove && (
            <td className={cn(styles.column, styles.column__qtyLocked)}>
              <span>{item.qty} st</span>
            </td>
          )}
          <td className={cn(styles.column, styles.column__total)}>
            <ProductPrice className={styles.price} price={item.rowTotal.exVat} />
          </td>
          {onRemove && (
            <td className={cn(styles.column, styles.column__remove)}>
              <Button onClick={onRemove}>
                <RemoveIcon className={styles.icon} />
              </Button>
            </td>
          )}
        </>
      )}
    />
  );
};

/* @flow */

import type { Client } from "@awardit/graphql-ast-client";

const OK_RESPONSES = ["success", "notModified"];

export const handleEffectErrors = async <T>(
  request: Promise<T>,
  handledErrors: Array<string>,
  okResponses: Array<string> = OK_RESPONSES
): Promise<{ success: boolean, response: T }> => {
  try {
    const x: any = await request;
    const firstKey = Object.keys(x)[0];

    const s = x[firstKey].result;

    if (OK_RESPONSES.includes(s)) {
      return { response: x, success: true };
    }
    if (handledErrors.includes(s)) {
      return { response: x, success: false };
    }

    throw new Error();
  } catch (e) {
    console.error(e);

    throw e;
  }
};

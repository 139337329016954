/* @flow */

import type {
  Quote,
  QuoteAddress,
  SetQuoteAddressInput,
} from "../types/quote.flow";
import type { CreateAccountParams } from "./customer";
import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData, updateNone } from "crustate";
import { EVENT_CUSTOMER_LOGOUT, EVENT_CART_MODIFY } from "./events";

type Data =
  | {| state: "LOADING", cartOpen: boolean |}
  | {| state: "LOADED", data: Quote, cartOpen: boolean |}
  | {| state: "SETTING_PAYMENT_METHOD", data: Quote, method: string, cartOpen: boolean |}
  | {| state: "SETTING_ADDRESS", data: Quote, address: SetQuoteAddressInput, cartOpen: boolean |}
  | {| state: "SETTING_EMAIL", data: Quote, cartOpen: boolean |}
  | {| state: "SETTING_ORDER_REFERENCE", data: Quote, cartOpen: boolean |}
  | {| state: "SETTING_BRING_FIELD", data: Quote, cartOpen: boolean |}
  | {| state: "PLACING_ORDER", data: Quote, cartOpen: boolean |};

type StripeType = {};

export type PlaceOrderParams = {|
  +email?: string,
  +address?: QuoteAddress,
  +stripe?: StripeType,
|};

export type QuoteResponse =
  | Response<typeof QUOTE_LOAD_RESPONSE, Quote>
  | Response<typeof QUOTE_SET_PAYMENT_METHOD_RESPONSE, Quote>
  | Response<typeof QUOTE_SET_ADDRESS_RESPONSE, Quote>
  | Response<typeof QUOTE_SAVE_ADDRESS_RESPONSE, Quote>
  | Response<typeof QUOTE_UPDATE_SHIPPING_METHOD_RESPONSE, Quote>
  | Response<typeof QUOTE_SET_DISCOUNT_CODE_RESPONSE, Quote>
  | Response<typeof QUOTE_REMOVE_DISCOUNT_CODE_RESPONSE, Quote>
  | Response<typeof QUOTE_SET_EMAIL_RESPONSE, Quote>
  | Response<typeof QUOTE_CHECKOUT_SAVE_CUSTOMER_RESPONSE, Quote>
  | Response<typeof QUOTE_PLACE_ORDER_RESPONSE, Quote>
  | { tag: typeof QUOTE_SYNC_RESPONSE, data: Quote }
  | Response<typeof QUOTE_SET_ORDER_REFERENCE_RESPONSE, Quote>
  | Response<typeof QUOTE_SET_PREFERRED_DELIVERY_DATE_RESPONSE, Quote>;

export type QuoteRequest =
  | { tag: typeof QUOTE_LOAD_REQUEST }
  | {
      tag: typeof QUOTE_SET_PAYMENT_METHOD_REQUEST,
      method: string,
      ssn: string,
    }
  | { tag: typeof QUOTE_SET_ADDRESS_REQUEST, address: SetQuoteAddressInput }
  | {
      tag: typeof QUOTE_SAVE_ADDRESS_REQUEST,
      address: SetQuoteAddressInput,
      id?: string,
    }
  | { tag: typeof QUOTE_SET_DISCOUNT_CODE_REQUEST, code: string }
  | { tag: typeof QUOTE_REMOVE_DISCOUNT_CODE_REQUEST }
  | { tag: typeof QUOTE_SET_EMAIL_REQUEST, email: string }
  | { tag: typeof QUOTE_UPDATE_SHIPPING_METHOD_REQUEST }
  | {
      tag: typeof QUOTE_CHECKOUT_SAVE_CUSTOMER_REQUEST,
      customerFormData: CreateAccountParams,
    }
  | { tag: typeof QUOTE_SET_ORDER_REFERENCE_REQUEST, value: string }
  | { tag: typeof QUOTE_SET_PREFERRED_DELIVERY_DATE_REQUEST, value: ?string }
  | {
      tag: typeof QUOTE_SET_PREFERRED_DELIVERY_DATE_EFFECT_REQUEST,
      value: ?string,
      oldQuote: Quote,
    };

export const QUOTE_LOAD_RESPONSE: "quote/init/response" = "quote/init/response";
export const QUOTE_LOAD_REQUEST: "quote/init/request" = "quote/init/request";

export const QUOTE_SET_PAYMENT_METHOD_RESPONSE: "quote/set-payment-method/response" =
  "quote/set-payment-method/response";
export const QUOTE_SET_PAYMENT_METHOD_REQUEST: "quote/set-payment-method/request" =
  "quote/set-payment-method/request";

export const QUOTE_SET_ADDRESS_RESPONSE: "quote/set-address/response" =
  "quote/set-address/response";
export const QUOTE_SET_ADDRESS_REQUEST: "quote/set-address/request" =
  "quote/set-address/request";

export const QUOTE_SAVE_ADDRESS_RESPONSE: "quote/save-address/response" =
  "quote/save-address/response";
export const QUOTE_SAVE_ADDRESS_REQUEST: "quote/save-address/request" =
  "quote/save-address/request";

export const QUOTE_PLACE_ORDER_RESPONSE: "quote/place-order/response" =
  "quote/place-order/response";
export const QUOTE_PLACE_ORDER_REQUEST: "quote/place-order/request" =
  "quote/place-order/request";

export const QUOTE_UPDATE_SHIPPING_METHOD_RESPONSE: "quote/update-shipping-method/response" =
  "quote/update-shipping-method/response";
export const QUOTE_UPDATE_SHIPPING_METHOD_REQUEST: "quote/update-shipping-method/request" =
  "quote/update-shipping-method/request";

export const QUOTE_SET_DISCOUNT_CODE_RESPONSE: "quote/set-discount-code/response" =
  "quote/set-discount-code/response";
export const QUOTE_SET_DISCOUNT_CODE_REQUEST: "quote/set-discount-code/request" =
  "quote/set-discount-code/request";

export const QUOTE_REMOVE_DISCOUNT_CODE_RESPONSE: "quote/remove-discount-code/response" =
  "quote/remove-discount-code/response";
export const QUOTE_REMOVE_DISCOUNT_CODE_REQUEST: "quote/remove-discount-code/request" =
  "quote/remove-discount-code/request";

export const QUOTE_SET_EMAIL_RESPONSE: "quote/set-email/response" =
  "quote/set-email/response";
export const QUOTE_SET_EMAIL_REQUEST: "quote/set-email/request" =
  "quote/set-email/request";

export const QUOTE_SYNC_RESPONSE: "quote/sync/response" = "quote/sync/response";

export const QUOTE_CHECKOUT_SAVE_CUSTOMER_RESPONSE: "quote/checkout-save-customer/response" =
  "quote/checkout-save-customer/response";
export const QUOTE_CHECKOUT_SAVE_CUSTOMER_REQUEST: "quote/checkout-save-customer/request" =
  "quote/checkout-save-customer/request";

export const QUOTE_SET_ORDER_REFERENCE_REQUEST: "quote/set-order-reference/request" =
  "quote/set-order-reference/request";
export const QUOTE_SET_ORDER_REFERENCE_RESPONSE: "quote/set-order-reference/response" =
  "quote/set-order-reference/response";

export const QUOTE_SET_PREFERRED_DELIVERY_DATE_REQUEST: "quote/set-preferred-delivery-date/request" =
  "quote/set-preferred-delivery-date/request";
export const QUOTE_SET_PREFERRED_DELIVERY_DATE_EFFECT_REQUEST: "quote/set-preferred-delivery-date/effect-request" =
  "quote/set-preferred-delivery-date/effect-request";
export const QUOTE_SET_PREFERRED_DELIVERY_DATE_RESPONSE: "quote/set-preferred-delivery-date/response" =
  "quote/set-preferred-delivery-date/response";

export const updateQuote = (data: Quote): QuoteResponse => ({
  tag: QUOTE_LOAD_RESPONSE,
  data,
});

export const setPaymentMethod = (
  method: string,
  ssn: string
): QuoteRequest => ({
  tag: QUOTE_SET_PAYMENT_METHOD_REQUEST,
  method,
  ssn,
});

export const setAddress = (address: SetQuoteAddressInput): QuoteRequest => ({
  tag: QUOTE_SET_ADDRESS_REQUEST,
  address,
});

export const saveAddress = (
  address: SetQuoteAddressInput & { email?: string },
  id?: string
): QuoteRequest => ({
  tag: QUOTE_SAVE_ADDRESS_REQUEST,
  address,
  id,
});

export const updateShippingMethod = (): QuoteRequest => ({
  tag: QUOTE_UPDATE_SHIPPING_METHOD_REQUEST,
});

export const setQuoteDiscountCode = (code: string): QuoteRequest => ({
  tag: QUOTE_SET_DISCOUNT_CODE_REQUEST,
  code,
});

export const removeQuoteDiscountCode = (): QuoteRequest => ({
  tag: QUOTE_REMOVE_DISCOUNT_CODE_REQUEST,
});

export const setQuoteEmail = (email: string): QuoteRequest => ({
  tag: QUOTE_SET_EMAIL_REQUEST,
  email,
});

export const checkoutSaveCustomer = (
  customerFormData: CreateAccountParams
): QuoteRequest => ({
  tag: QUOTE_CHECKOUT_SAVE_CUSTOMER_REQUEST,
  customerFormData,
});

export const setOrderReference = (value: string): QuoteRequest => ({
  tag: QUOTE_SET_ORDER_REFERENCE_REQUEST,
  value,
});

export const setPreferredDeliveryDate = (value: ?string): QuoteRequest => ({
  tag: QUOTE_SET_PREFERRED_DELIVERY_DATE_REQUEST,
  value,
});

export const QuoteModel: Model<Data, {}, QuoteRequest | QuoteResponse> = {
  id: "quote",
  init: () => updateData({ state: "LOADING", cartOpen: false }, { tag: QUOTE_LOAD_REQUEST }),
  update: (state: Data, msg) => {
    switch (msg.tag) {
      case QUOTE_SET_PAYMENT_METHOD_REQUEST:
        if (
          state.state === "LOADED" ||
          state.state === "SETTING_PAYMENT_METHOD"
        ) {
          return updateData(
            {
              state: "SETTING_PAYMENT_METHOD",
              data: state.data,
              method: msg.method,
              cartOpen: state.cartOpen,
            },
            msg
          );
        }

        break;

      case QUOTE_SET_PREFERRED_DELIVERY_DATE_REQUEST:
        if (
          state.state === "LOADED" ||
          state.state === "SETTING_PAYMENT_METHOD" ||
          state.state === "SETTING_ORDER_REFERENCE" ||
          state.state === "SETTING_BRING_FIELD"
        ) {
          return updateData(
            {
              state: "SETTING_BRING_FIELD",
              data: state.data,
              cartOpen: state.cartOpen,
            },
            {
              tag: QUOTE_SET_PREFERRED_DELIVERY_DATE_EFFECT_REQUEST,
              value: msg.value,
              oldQuote: state.data,
            }
          );
        }
        break;

      case QUOTE_SET_ORDER_REFERENCE_REQUEST:
        if (
          state.state === "LOADED" ||
          state.state === "SETTING_PAYMENT_METHOD" ||
          state.state === "SETTING_ORDER_REFERENCE"
        ) {
          return updateData(
            {
              state: "SETTING_ORDER_REFERENCE",
              data: state.data,
              cartOpen: state.cartOpen,
            },
            msg
          );
        }
        break;

      case QUOTE_SET_ADDRESS_REQUEST:
      case QUOTE_SAVE_ADDRESS_REQUEST:
        if (state.state === "LOADED") {
          return updateData(
            {
              state: "SETTING_ADDRESS",
              data: state.data,
              address: msg.address,
              cartOpen: state.cartOpen,
            },
            msg
          );
        }

        return updateNone();
      case QUOTE_CHECKOUT_SAVE_CUSTOMER_REQUEST:
      case QUOTE_SET_EMAIL_REQUEST:
        if (state.state === "LOADED") {
          return updateData(
            {
              state: "SETTING_EMAIL",
              data: state.data,
              cartOpen: state.cartOpen,
            },
            msg
          );
        }

        break;
      case QUOTE_SET_DISCOUNT_CODE_REQUEST:
      case QUOTE_REMOVE_DISCOUNT_CODE_REQUEST:
      case QUOTE_UPDATE_SHIPPING_METHOD_REQUEST:
        return updateData(
          {
            ...state,
          },
          msg
        );
      case QUOTE_PLACE_ORDER_REQUEST:
        if (state.state === "LOADED") {
          return updateData(
            {
              data: state.data,
              state: "PLACING_ORDER",
              cartOpen: state.cartOpen,
            },
            msg
          );
        }

        throw new Error(
          'Tried to handle place order request with state="' +
            state.state +
            '".'
        );
      case QUOTE_SET_PREFERRED_DELIVERY_DATE_RESPONSE:
      case QUOTE_SET_ORDER_REFERENCE_RESPONSE:
      case QUOTE_SET_ADDRESS_RESPONSE:
      case QUOTE_LOAD_RESPONSE:
      case QUOTE_SET_PAYMENT_METHOD_RESPONSE:
      case QUOTE_UPDATE_SHIPPING_METHOD_RESPONSE:
      case QUOTE_SET_DISCOUNT_CODE_RESPONSE:
      case QUOTE_REMOVE_DISCOUNT_CODE_RESPONSE:
      case QUOTE_SET_EMAIL_RESPONSE:
      case QUOTE_CHECKOUT_SAVE_CUSTOMER_RESPONSE:
      case QUOTE_SAVE_ADDRESS_RESPONSE:
        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data, cartOpen: state.cartOpen });
        }

        break;
      case QUOTE_SYNC_RESPONSE:
        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data, cartOpen: state.cartOpen });
        }

        break;
      case QUOTE_PLACE_ORDER_RESPONSE:
        if (state.state === "PLACING_ORDER") {
          if (msg.data) {
            return updateData({ state: "LOADED", data: msg.data, cartOpen: false });
          }
          return updateData({ state: "LOADED", data: state.data, cartOpen: false });
        }
        // return updateData({ state: "LOADED", data: state.data });

        throw new Error(
          'Tried to handle place order response with state="' +
            state.state +
            '".'
        );
      case EVENT_CUSTOMER_LOGOUT:
        return updateData({ state: "LOADED", data: msg.quote, cartOpen: false });
      case EVENT_CART_MODIFY:
        return updateData({ ...state, cartOpen: true });
      default:
    }
  },
};

/* @flow */

import React, { useState, useRef, useEffect } from "react";
import { Input } from "../Input";
import { Field } from "../Field";
import { useRestClient } from "../../entrypoint/shared";
import { debounce } from "@out-of-home/diskho";

type Props = {
  name: string,
  value?: string,
  available: boolean,
  setAvailable: (boolean, string) => void,
};

export const NewEmailInput = ({
  value,
  available,
  setAvailable,
  ...props
}: Props): React$Node => {
  const [loading, setLoading] = useState(false);
  const [pristine, setPrisitine] = useState(true);
  const client = useRestClient();

  const checkEmail = useRef(
    debounce((value) => {
      if (value && value.length > 2) {
        setLoading(true);
        client(`/api/customer/email_available?email=${value}`)
          .then((x) => x.json())
          .then((x) => {
            setPrisitine(false);
            setAvailable(x.available, value);
            setLoading(false);
          });
      }
    }, 300)
  );

  useEffect(() => {
    if (value && value.length > 2) {
      checkEmail.current(value);
    }
  }, [value]);

  return (
    <Field
      {...props}
      type="email"
      value={value}
      loading={loading}
      forceShowError={!pristine}
    />
  );
};

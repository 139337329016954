/** @flow */
import type { TabContent } from "../tabs";
import type { Tab } from "../../../types/header.flow";
import React from "react";
import cn from "classnames";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import styles from "./styles.scss";
import { Wrapper } from "../../Wrapper";
import { Button } from "../../Button";
import { useClickOutside } from "../../../helpers";
import { useTranslate } from "@out-of-home/use-translate";
import { SECTIONS } from "../tabs";
import { Submenu } from "./submenu";
import { Link, useLocation } from "react-router-dom";
import { useCustomer } from "../../../helpers/use-customer";
import { useOverdueInvoices } from "../../../helpers/use-overdue-invoices";
import { UIContext } from "../../../context/ui";

type Props = {
  active: boolean,
  setActive: (boolean) => void,
};

const breakpointMedium = parseInt(styles.breakpointMedium, 10);
const breakpointLarge = parseInt(styles.breakpointLarge, 10);

export const MegaMenu = ({ active, setActive }: Props): React$Node => {
  const t = useTranslate();
  const [openTab, setOpenTab] = useState<?Tab>();
  const element = useRef();
  const location = useLocation();
  const customer = useCustomer();
  const { gotOverdueInvoice } = useOverdueInvoices();
  const { browserWidth } = useContext(UIContext);
  const [numSubmenuCards, setNumSubmenuCards] = useState<number>(0);
  const openRef = useRef<?TimeoutID>(null);

  useEffect(() => {
    if (browserWidth <= breakpointMedium - 1) {
      setNumSubmenuCards(0);
    } else if (browserWidth <= breakpointLarge - 1) {
      setNumSubmenuCards(2);
    } else {
      setNumSubmenuCards(4);
    }
  }, [browserWidth]);

  const open = useCallback((tab: Tab) => {
    if (openRef.current) {
      clearTimeout(openRef.current);
    }

    // use delay before opening the submenu, to prevent opening by accident
    openRef.current = setTimeout(() => {
      setOpenTab(tab);
      setActive(true);
    }, 80);
  }, []);

  const close = useCallback(() => {
    if (openRef.current) {
      clearTimeout(openRef.current);
      openRef.current = null;
    }
    setOpenTab();
    setActive(false);
  }, []);

  const toggleTab = useCallback(
    (tab: Tab) => {
      if (!openTab || openTab !== tab) {
        open(tab);
      } else {
        close();
      }
    },
    [openTab, setActive],
  );

  useEffect(() => {
    if (!active) {
      setOpenTab();
    }
  }, [active]);

  useClickOutside(element, () => {
    if (openTab) {
      setOpenTab();
      setActive(false);
    }
  });

  return (
    <div className={cn(styles.mega_menu, "no-underline")} ref={element} onMouseLeave={() => close()}>
      <Wrapper className={styles.wrapper}>
        <section className={cn(styles.nav, "no-underline")}>
          <div className={styles.navLeft}>
            <Button
              data-active={openTab === "drinks"}
              onTouchStart={() => toggleTab("drinks")}
              onMouseEnter={() => open("drinks")}
            >
              {t("NAV.DRINKS")}
            </Button>

            <Button
              data-active={openTab === "eatables"}
              onTouchStart={() => toggleTab("eatables")}
              onMouseEnter={() => open("eatables")}
            >
              {t("NAV.EATABLES")}
            </Button>

            <Button
              data-active={openTab === "snacks"}
              onTouchStart={() => toggleTab("snacks")}
              onMouseEnter={() => open("snacks")}
            >
              {t("NAV.SNACKS")}
            </Button>

            <Button
              data-active={openTab === "accessories"}
              onTouchStart={() => toggleTab("accessories")}
              onMouseEnter={() => open("accessories")}
            >
              {t("NAV.ACCESSORIES")}
            </Button>

            {!customer.loggedIn && (
              <>
                <Button
                  data-active={openTab === "brands"}
                  onTouchStart={() => toggleTab("brands")}
                  onMouseEnter={() => open("brands")}
                >
                  {t("NAV.BRANDS")}
                </Button>

                <Button
                  data-active={openTab === "customer_type"}
                  onTouchStart={() => toggleTab("customer_type")}
                  onMouseEnter={() => open("customer_type")}
                >
                  {t("NAV.CUSTOMER_TYPE")}
                </Button>
              </>
            )}
          </div>

          {customer.loggedIn && (
            <div className={styles.navRight} onMouseEnter={() => close()} onTouchStart={() => close()}>
              <Button to="/p/?certified=Kampanjer">{t("NAV.MY_DISCOUNTS")}</Button>

              <Button to="/inkopslista">{t("NAV.WISHLIST")}</Button>

              <Button to={"/" + t("ROUTES.ORDER_HISTORY")}>
                {gotOverdueInvoice && <div className={styles.overdueInvoiceWarning} />}
                {t("NAV.ORDER_HISTORY")}
              </Button>

              <Button to={"/" + t("ROUTES.CUSTOMER_SERVICE")}>{t("NAV.CUSTOMER_SERVICE")}</Button>
            </div>
          )}
        </section>
      </Wrapper>

      <div className={styles.submenu}>
        <nav className={cn(styles.submenu__inner, active && openTab && styles.active)}>
          <Submenu tab={"drinks"} active={openTab === "drinks"} numCards={numSubmenuCards} />
          <Submenu tab={"eatables"} active={openTab === "eatables"} numCards={numSubmenuCards} />
          <Submenu tab={"snacks"} active={openTab === "snacks"} numCards={numSubmenuCards} />
          <Submenu tab={"accessories"} active={openTab === "accessories"} numCards={numSubmenuCards} />
          <Submenu tab={"brands"} active={openTab === "brands"} numCards={numSubmenuCards} />
          <Submenu tab={"customer_type"} active={openTab === "customer_type"} numCards={numSubmenuCards} />
        </nav>
      </div>
    </div>
  );
};

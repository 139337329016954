/* @flow */

import type { Quote } from "../../types/quote.flow";

import React, { useContext, useState, useEffect } from "react";
import { Switch, Route, NavLink, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import styles from "./styles.scss";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import { useData, useSendMessage } from "crustate/react";
import { useFormat } from "../../helpers/use-format";
import { useCustomer } from "../../helpers/use-customer";
import { StoreInfoContext } from "../../entrypoint/shared";
import { useTranslate } from "@out-of-home/use-translate";
import { Wrapper } from "../Wrapper";
import { Button } from "../Button";
import SystemMessages from "../SystemMessages";
import { StripePaymentProvider, STRIPE_PAYMENT_METHOD_CODE } from "../../stripe";
import { Logo } from "../AppHeader/logo";
import { useFrontChatContext } from "../../context/front-chat";
import { useClient } from "../../entrypoint/shared";
import CloseIcon from "../../icons/close.svg";
import {
  setPaymentMethod,
  updateShippingMethod,
  setPreferredDeliveryDate,
  setAddress,
} from "../../state/quote";
import {
  confirmStripePaymentIntent,
  createStripePaymentIntent,
  setQuotePaymentMethodStripe,
} from "../../queries";
import { CheckoutCart } from "./CheckoutCart";
import { CheckoutSuggestions } from "./CheckoutSuggestions";
import { CheckoutConfirm } from "./CheckoutConfirm";
import {
  QuoteData,
  PaymentMethodsData,
  useGetQuoteShippingAddress,
  DeliveryScheduleData,
} from "../../data";
import { addressCmp } from "../Address";
import { NotFoundView } from "../NotFoundView";
import { Cart } from "../AppHeader/Cart";
import { Cart as AppCart } from "../Cart";
import { useScrollTopOrElement } from "../../helpers/use-scroll-top-or-element";
import { AnalyticsContext } from "../../helpers/analytics/context";

const STEPS = [
  { url: "/checkout/varukorg", title: "Varukorg" },
  { url: "/checkout/forslag", title: "Anpassade förslag" },
  { url: "/checkout/bekrafta", title: "Bekräfta order" },
];

const PAYMENT_METHODS_PRIORITY = [
  "free",
  "CrossInvoice",
  "Crossroads_Collector",
  "Crossroads_Stripe_PaymentIntents",
];

// Reset address if it doesn't match one of the saved ones
const useResetAddressOnNoMatch = (addresses, shippingAddress) => {
  const sendMessage = useSendMessage();
  const hasAddress = addresses.some(x => addressCmp(shippingAddress)(x));

  useEffect(() => {
    if (!hasAddress) {
      const defaultAddress = addresses.find(x => x.isDefaultShipping);

      const addr = defaultAddress || addresses[0];

      if (addr) {
        const { country, id, isDefaultBilling, isDefaultShipping, ...address } = addr;
        sendMessage(setAddress({ ...address, countryCode: country.code }));
      }
    }
  }, [hasAddress]);
};

type HeaderProps = {
  notFound?: boolean,
  backURL: string,
  step: number,
  nextStepIdx: number,
  quoteItemsCount: number,
};
const Header = ({ notFound = false, backURL, step, nextStepIdx, quoteItemsCount }: HeaderProps) => {
  const [cartOpen, setCartOpen] = useState<boolean>(false);

  return (
    <header className={styles.header}>
      <Wrapper className={styles.header__wrapper}>
        <div className={styles.logo}>
          <Logo />
        </div>

        {!notFound && (
          <>
            <nav className={styles.menu}>
              {STEPS.map((x, i) => (
                <React.Fragment key={x.url}>
                  <NavLink to={x.url} activeClassName={styles.active}>
                    {x.title}
                  </NavLink>

                  {i < STEPS.length - 1 && <span className={styles.navSeparator} />}
                </React.Fragment>
              ))}
            </nav>
            <nav className={styles.menuSmall}>
              <div className={styles.progress}>
                <div className={cn(styles.circleBorder, styles[`circleBorder__${step}`])}>
                  <div className={styles.circle} />
                </div>
                <div className={styles.progressContent}>
                  {step + 1}/{STEPS.length}
                </div>
              </div>
              <div>
                <span className={styles.current}>{STEPS[step].title}</span>
                {STEPS[nextStepIdx] ? (
                  <span className={styles.next}>Nästa: {STEPS[nextStepIdx].title}</span>
                ) : (
                  <span className={styles.next}>Detta är sista steget</span>
                )}
              </div>
            </nav>
          </>
        )}

        <div className={styles.headerButtons}>
          <Cart cartOpen={cartOpen} setCartOpen={setCartOpen} quoteItemsCount={quoteItemsCount} />
          <Button className={styles.backButton} to={backURL}>
            <CloseIcon />
          </Button>
        </div>

        {cartOpen &&
          <AppCart
            className={styles.appCart}
            showButtons={false}
            setCartOpen={setCartOpen} />
        }

      </Wrapper>
    </header>
  );
};
const RegisterBeginCheckout = ({  quote }: { quote: Quote }): React$Node => {
  const { registerBeginCheckout } = useContext(AnalyticsContext);

  useEffect(() => {
    registerBeginCheckout(quote);
  }, []);

  return null;
}

export const CheckoutView = (): React$Node => {
  const { push } = useHistory();
  const [backURL, setBackURL] = useState<string>("/p");
  const quoteData = useData(QuoteData);
  const sendMessage = useSendMessage();
  const { info } = useContext(StoreInfoContext);
  const { customer, state } = useCustomer();
  const t = useTranslate();
  const { formatPrice } = useFormat();
  const quote = quoteData.state !== "LOADING" ? quoteData.data : null;
  const location = useLocation();
  const step = STEPS.findIndex(x => x.url === location.pathname);
  const nextStepIdx = step + 1;
  const nextStepUrl = nextStepIdx < STEPS.length ? STEPS[nextStepIdx].url : null;
  const paymentMethods = useData(PaymentMethodsData).data;
  const defaultPaymentMethod = customer?.defaultPaymentMethod || null;
  const client = useClient();
  const payment = quote?.payment || null;
  const addresses = customer?.addresses || [];
  const { shutdown } = useFrontChatContext();

  useEffect(() => { shutdown(); });

  useScrollTopOrElement();

  const shippingAddress = useGetQuoteShippingAddress();
  useResetAddressOnNoMatch(addresses, shippingAddress);

  // Set back URL when opening the checkout to use in back btn
  useEffect(() => {
    if (location.state?.prevPathname) {
      setBackURL(location.state.prevPathname);
    }
  }, []);

  useEffect(() => {
    if (quote && quote.items.length === 0) {
      push("/");
    }

    if (quote && quote.grandTotal.incVat > 0) {
      const avaibleMethods = paymentMethods.map(x => x.code);
      const { payment } = quote;
      const paymentCode = payment && payment.code;

      // If null, set to customer default or first available
      const ssn = customer?.customerPno || "";
      if (!paymentCode && avaibleMethods.length > 0) {
        if (defaultPaymentMethod && avaibleMethods.find(x => x === defaultPaymentMethod)) {
          sendMessage(setPaymentMethod(defaultPaymentMethod, ssn));
        } else {
          const validMethods = PAYMENT_METHODS_PRIORITY.filter(x => avaibleMethods.includes(x));
          if (validMethods.length > 0) {
            sendMessage(setPaymentMethod(validMethods[0], ssn));
          }
        }
      }
    }
  }, [quote, paymentMethods]);

  if (!STEPS.hasOwnProperty(step)) {
    return (
      <div>
        <Header
          notFound={true}
          backURL={backURL}
          step={step}
          nextStepIdx={nextStepIdx}
          quoteItemsCount={quote?.items.length || 0}
        />
        <Wrapper>
          <NotFoundView />
        </Wrapper>
      </div>
    );
  }

  if (!quote) {
    return (
      <div>
        <h1>Din varukorg är tom</h1>
      </div>
    );
  }

  const { items } = quote;
  const qty = items.reduce((a, c) => (a += c.qty), 0);

  return (
    <>
    <RegisterBeginCheckout quote={quote} />
    <div>
      <Header backURL={backURL} step={step} nextStepIdx={nextStepIdx} quoteItemsCount={quote.items.length} />
      <SystemMessages />

      <StripePaymentProvider
        client={client}
        amountToPay={quote.grandTotal.incVat}
        stripeKey={
          payment?.code === "Crossroads_Stripe_PaymentIntents" ? payment.publishableKey : ""
        }
        confirmStripePaymentIntentQuery={confirmStripePaymentIntent}
        createStripePaymentIntentQuery={createStripePaymentIntent}
        storeInfo={info}
      >
        <Switch>
          <Route
            path='/checkout/varukorg'
            render={props => <CheckoutCart {...props} quote={quote} />}
          />
          <Route
            path='/checkout/forslag'
            render={props => <CheckoutSuggestions {...props} quote={quote} />}
          />

          <Route
            path='/checkout/bekrafta'
            render={props => <CheckoutConfirm {...props} quote={quote} />}
          />
        </Switch>
      </StripePaymentProvider>

      <div className={styles.cartSpacer} />

      <footer className={styles.footer}>
        <Wrapper className={styles.footerInner}>
          <div className={styles.sum}>
            <span className={styles.sumLabel}>Summa</span>
            <span className={styles.sumValue}>{formatPrice(quote.grandTotal.exVat)}</span>
          </div>
          <div className={styles.footerButtons}>
            <Button variant='ghost' size='small' to={backURL}>
              Tillbaka
            </Button>

            {nextStepUrl && (
              <Button className={styles.nextButton} variant='primary' size='small' to={nextStepUrl}>
                Gå vidare
              </Button>
            )}
            <div className={styles.cartButtonConfirm} id='cart-btn-portal' />
          </div>
        </Wrapper>
      </footer>

      <Helmet title={STEPS[step].title + " | Outofhome.se"} />
    </div>
    </>
  );
};

/* @flow */

import styles from "./styles.scss";
import * as React from "react";
import { createPortal } from "react-dom";
import cn from "classnames";

type Props = {
  className?: string,
  children: React.Node,
  noClose?: boolean,
  isolationMode?: boolean,
};

type UseModalReturnValue = {|
  Modal: (Props) => React$Element<React$FragmentType>,
  close: () => void,
  isOpen: boolean,
  open: () => void,
  setOpen: (v: boolean) => void,
  toggle: () => void,
|};

type Ref = { current: ?HTMLElement };

const FOCUSABLE_ELEMENTS = [
  "a[href]",
  "area[href]",
  "input:not([disabled])",
  "select:not([disabled])",
  "textarea:not([disabled])",
  "button:not([disabled])",
  '[tabindex="0"]',
].join(",");

const KEY_TAB = 9;
const KEY_ESC = 27;

const getDocumentBody = () => {
  const body =
    typeof window === "object" &&
    window &&
    window.document &&
    window.document.body;
  return body || null;
};

const getFirstAndLastFocusableElement = (element: HTMLElement) => {
  const nodeList = element.querySelectorAll(FOCUSABLE_ELEMENTS);

  return [nodeList[0], nodeList[nodeList.length - 1]];
};

const trapTab = (event: KeyboardEvent, element: HTMLElement) => {
  const [firstFocusable, lastFocusable] =
    getFirstAndLastFocusableElement(element);

  if (event.keyCode === KEY_TAB) {
    if (event.shiftKey) {
      if (
        (document.activeElement === firstFocusable ||
          document.activeElement === element) &&
        lastFocusable
      ) {
        event.preventDefault();
        lastFocusable.focus();
      }
    } else if (document.activeElement === lastFocusable && firstFocusable) {
      event.preventDefault();
      firstFocusable.focus();
    }
  }
};

export const ModalElementContext: React$Context<HTMLElement | null> =
  React.createContext(getDocumentBody());

export const useModal = (
  isOpen: boolean,
  setOpen: (v: boolean) => void,
  fixed: boolean = false
): UseModalReturnValue => {
  const [prevFocused, setPrevFocused] =
    React.useState<HTMLElement | null>(null);
  const element: Ref = React.useRef(null);

  const rootEl = React.useContext(ModalElementContext);
  const close = () => setOpen(false);
  const open = () => setOpen(true);
  const toggle = () => (isOpen ? close() : open());
  const Modal = ({
    className,
    children,
    noClose = false,
    isolationMode = true,
  }: Props) => {
    // Focus correct element
    React.useEffect(() => {

      if (isolationMode && (!rootEl || document.activeElement !== rootEl.querySelector("input"))) {
        if (isOpen) {
          setPrevFocused(document.activeElement);
          if (element.current) {
            element.current.focus();
          }
        } else if (prevFocused) {
          prevFocused.focus();
        }
      }
    }, []);

    React.useEffect(() => {
      const re = rootEl;
      if (!isOpen) {
        return;
      }

      const onScroll = (e: Event) => {
        e.preventDefault();
      };

      const onKeyDown = (event: KeyboardEvent) => {
        if (element.current) {
          trapTab(event, element.current);
        }

        if (event.metaKey || event.ctrlKey || event.altKey || event.shiftKey) {
          return;
        }

        if (noClose) {
          return;
        }

        if (event.which === KEY_ESC) {
          close();
        }
      };

      if (isolationMode && re) {
        re.addEventListener("scroll", onScroll);
        re.addEventListener("keydown", onKeyDown);

        re.style.overflow = "hidden";
        re.style.height = "100%";
      }

      return () => {
        if (isolationMode && re) {
          re.removeEventListener("scroll", onScroll);
          re.removeEventListener("keydown", onKeyDown);

          re.style.overflow = "";
          re.style.height = "";
        }
      };
    }, []);

    if (isOpen && !rootEl) {
      throw new Error("Attempting to render a Modal without a root element");
    }

    if (!isOpen) {
      return <></>;
    }

    if (fixed) {
      return (
        <>
          {isOpen &&
            rootEl &&
            createPortal(
              <div
                ref={element}
                tabIndex="-1"
                className={cn(styles.block, styles.fixed, className)}
                role="dialog"
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
              >
                {isolationMode && (
                  <div className={styles.background} onClick={close} />
                )}
                {children}
              </div>,
              rootEl
            )}
        </>
      );
    }

    return (
      <>
        {isOpen && (
          <div
            ref={element}
            tabIndex="-1"
            className={cn(styles.block, className)}
            role="dialog"
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
          >
            {isolationMode && (
              <div className={styles.background} onClick={close} />
            )}
            {children}
          </div>
        )}
      </>
    );
  };

  return {
    Modal,
    setOpen,
    isOpen,
    open,
    close,
    toggle,
  };
};

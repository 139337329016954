/* @flow */

import type { Quote } from "../../types/quote.flow";

import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useData } from "crustate/react";
import styles from "./styles.scss";
import cn from "classnames";
import { useFormat } from "../../helpers/use-format";
import { useTranslate } from "@out-of-home/use-translate";
import { useCustomer } from "../../helpers/use-customer";
import { Button } from "../Button";
import { SimpleMeter } from "../Meter/simple";
import { LevelledMeter } from "../Meter/levelled";
import { DISCOUNT_METER_CONFIG, KOLONIAL_FREE_SHIPPING_LIMIT, FROZEN_FREE_SHIPPING_LIMIT } from "./meter-config";
import { getNumMetersShowing } from "../../helpers/cartHelpers";
import { QuoteData } from "../../data";
import { UIContext } from "../../context/ui";
import { useClickOutside } from "../../helpers";

type Props = {
  className?: string,
  showButtons?: boolean,
  setCartOpen: (boolean) => void,
};

type MeterProps = {
  quote: Quote,
  className?: string,
};

type PillProps = {
  visible: boolean,
  type: string,
  text: string,
};

const Pill = ({ visible, type, text }: PillProps) => {
  if (!visible) {
    return null;
  }

  return (
    <div
      className={cn(styles.pill, styles[type], {
        [styles.pillActive]: visible,
      })}
    >
      {text}
    </div>
  );
};

const mediumBreakpoint = parseInt(styles.breakpointMedium, 10);

export const CartMeters = ({ quote }: MeterProps): React$Node => {
  const t = useTranslate();
  const { formatPrice } = useFormat();
  const { customer, state } = useCustomer();
  const subTotalExclTax = quote.subTotal.exVat;
  const { browserWidth } = useContext(UIContext);
  const mediumView = browserWidth > 0 && browserWidth < mediumBreakpoint;

  if (!quote.items.length) {
    return null;
  }

  const customerCode = (customer?.group || "GUEST").toUpperCase().trim();
  const meterConfig = DISCOUNT_METER_CONFIG.hasOwnProperty(customerCode) ? DISCOUNT_METER_CONFIG[customerCode] : [];

  const meterConfigMaxValue = meterConfig.reduce((acc, curr) => {
    return curr.of > acc ? curr.of : acc;
  }, 0);

  const kolonialFreeShippingLimit = KOLONIAL_FREE_SHIPPING_LIMIT.hasOwnProperty(customerCode)
    ? KOLONIAL_FREE_SHIPPING_LIMIT[customerCode]
    : KOLONIAL_FREE_SHIPPING_LIMIT["DEFAULT"];

  return (
    <div className={styles.meters}>
      <div className={styles.meter}>
        {/*2 200 kr till mangdrabatt (1%)*/}
        <SimpleMeter
          amount={subTotalExclTax}
          to={kolonialFreeShippingLimit}
          type="freeshipping_colonial"
          text={t("CART.FREE_SHIPPING_KOLONIAL", {
            amount: formatPrice(kolonialFreeShippingLimit - subTotalExclTax),
          })}
          textFilled={t("CART.FREE_SHIPPING_KOLONIAL__FULL")}
        />
      </div>

      {meterConfig.length > 0 && (
        <div className={styles.meter}>
          {/*200 kr till fri frakt*/}
          <LevelledMeter
            hidden={!subTotalExclTax || (!mediumView && subTotalExclTax >= meterConfigMaxValue)}
            amount={subTotalExclTax}
            of={meterConfig.map((x) => x.of)}
            discount={meterConfig.map((x) => x.discount)}
            type="quantity_discount"
            text={"CART.QUANTITY_DISCOUNT"}
            textFilled={"CART.QUANTITY_DISCOUNT__FULL"}
          />
        </div>
      )}
    </div>
  );
};

export const Cart = ({ className, showButtons = true, setCartOpen }: Props): React$Node => {
  const t = useTranslate();
  const { formatPrice } = useFormat();
  const quoteData = useData(QuoteData);
  const { customer, state } = useCustomer();
  const { pathname } = useLocation();
  const blockRef = useRef();
  useClickOutside(blockRef, () => {
    setCartOpen(false);
  });

  const quote = quoteData.state === "LOADED" ? quoteData.data : null;

  if (!quote || !quote.items.length) {
    return (
      <div className={styles.className}>
        <div className={styles.block} ref={blockRef}>
          <p className={styles.heading}>Översikt av varukorg</p>
          <p>
            Det är lite öde i varukorgen, som en öken utan oasis. Börja med att fylla den med varor och kom sen
            tillbaka.
          </p>
        </div>
      </div>
    );
  }

  const qty = quote.items.reduce((a, c) => (a += c.qty), 0);
  const shipping = quote.shipping?.total.exVat || 0;
  const subTotalExclTax = quote.subTotal.exVat;
  const grandTotalExclTax = quote.grandTotal.exVat - shipping;

  return (
    <div className={className}>
      <div className={styles.block} ref={blockRef}>
        <p className={styles.heading}>Översikt av varukorg</p>
        <CartMeters quote={quote} />
        <div className={styles.summary}>
          <div className={styles.summaryRow}>
            <label>Antal artiklar</label>
            <span>{qty}</span>
          </div>

          <div className={styles.summaryRow}>
            <label>Ordervärde</label>
            <span>{formatPrice(subTotalExclTax)}</span>
          </div>

          {quote.discounts?.map((x, i) => (
            <div key={`${x.type}_${x.label}_${i}`} className={cn(styles.summaryRow, styles.summaryDiscount)}>
              <div>Rabattkod</div>
              <div>{x.label}</div>
            </div>
          ))}
        </div>

        <div className={styles.total}>
          <label>Totalt</label>
          <span>{formatPrice(grandTotalExclTax)}</span>
        </div>

        {showButtons && (
          <div className={styles.buttons}>
            <Button
              to={{
                pathname: "/" + t("ROUTES.CART"),
                state: { prevPathname: pathname },
              }}
              className={styles.submit}
              variant="primary"
              size="small"
            >
              {t("CART.OPEN_DRAWER")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

/* @flow */

import React from "react";
import cn from "classnames";
import styles from "./styles.scss";
import { RangeSlider } from "../RangeSlider";
import { useFormat } from "../../helpers/use-format";
import { Button } from "../Button";
import { useTranslate } from "@out-of-home/use-translate";

const priceGroups = [
  {
    heading: "Flex",
    freeShippingLimit: 2000,
    discount: 0,
    id: 1,
  },
  {
    heading: "Bra pris",
    freeShippingLimit: 3000,
    discount: 4,
    id: 15,
  },
  {
    heading: "Bättre pris",
    freeShippingLimit: 4000,
    discount: 8,
    id: 19,
  },
  {
    heading: "Ännu bättre pris",
    freeShippingLimit: 10000,
    discount: 12,
    id: 76,
  },
];


type PriceGroup = {
  heading: string,
  freeShippingLimit: number,
  discount: number,
  id: number
};

type Props = {
  ooh3Type?: string,
  value: number,
  onChange: number => void,
};

type PriceGroupsProps = {
  ooh3Type?: string,
  className?: string,
  tableClassName?: string,
};

export const PriceGroups = (props: PriceGroupsProps): React$Node => {
  const { formatPrice } = useFormat();

  return (
    <div className={cn(styles.pricesInfo, props.className)}>
      <div className={cn(styles.pricesTable, props.tableClassName)}>
        <div className={styles.pricesTable__header}>
          <span>Prisgrupper</span>
          {priceGroups.map((x) => (
            <span key={x.heading}>{x.heading}</span>
          ))}
        </div>
        <div className={styles.pricesTable__row}>
          <strong>Fast rabatt*</strong>
          {priceGroups.map((x) => (
            <span key={x.heading}>{x.discount}%</span>
          ))}
        </div>
        <div className={styles.pricesTable__row}>
          <strong>Fri fraktgräns**</strong>
          {priceGroups.map((x) => (
            <span key={x.heading}>{formatPrice(x.freeShippingLimit)}</span>
          ))}
        </div>
      </div>

      <div className={styles.pricesDisclaimer}>
        <p>
          * Rabatt är generell och gäller majoriteten av artiklarna i vårt sortiment. Det
          finns undantag med högre och lägre rabatt.
        </p>
        <p>
          ** Orders under fri fraktgräns går bra men då tillkommer en kostnad för frakt på
          ungefär 552 SEK. Kolla i kassan för att se exakt pris för frakt innan ni klickar
          iväg ordern. Det baseras på vikt, volym och verklig fraktkostnad.
        </p>
      </div>
    </div>
  );
}

export const PriceSlider = ({
  value,
  ooh3Type,
  onChange,
  ...props
}: Props): React$Node => {
  const t = useTranslate();
  const price = priceGroups[value - 1];
  const { formatPrice } = useFormat();

  return (
    <div className={cn(styles.row, styles.rowSubmit)}>
      <span className={styles.priceselect__header}>{price.heading}</span>
      <div className={styles.sliderContainer}>
        <RangeSlider
          step={1}
          min={1}
          max={priceGroups.length}
          values={[value]}
          ariaLabel="Välj prisgrupp"
          onChange={(values) => {
            onChange(values[0]);
          }}
        />
        <div className={styles.pips}>
          {priceGroups.map((_, i) => (
            <span key={i} />
          ))}
        </div>

        <div className={styles.sliderValues}>
          <div>
            <span className={styles.sliderValues__label}>Fri fraktgräns</span>
            <span className={styles.sliderValues__value}>
              {formatPrice(price.freeShippingLimit)}
            </span>
          </div>
          <div>
            <span className={styles.sliderValues__label}>Fast rabatt*</span>
            <span className={styles.sliderValues__value}>{price.discount}%</span>
          </div>
        </div>
      </div>
    </div>
  );
}

/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { OrderRequest, OrderResponse } from "../state/order";

import { ORDER_REQUEST, ORDER_RESPONSE } from "../state/order";
import { lastOrder } from "../queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: OrderRequest) => {
      if (msg.type === "order") {
        const { lastOrder: data } = await client(lastOrder);

        return ({
          tag: ORDER_RESPONSE,
          data,
        }: OrderResponse);
      }
    },
    subscribe: { [ORDER_REQUEST]: true },
  });
};

export default registerClient;

/** @flow */

import type { TabContent } from "../tabs";
import type { MenuCategoryBlock, Tab } from "../../../types/header.flow";

import React from "react";
import { useContext } from "react";
import cn from "classnames";
import { Wrapper } from "../../Wrapper";
import { getContent } from "../tabs";
import { useTranslate } from "@out-of-home/use-translate";
import styles from "./submenu-styles.scss";
import { Link } from "react-router-dom";
import { StoreInfoContext } from "../../../entrypoint/shared";

type UseGetContentReturnType = {|
  main: TabContent["main"],
  second: TabContent["second"],
  third: TabContent["third"],
  fourth: {
    title: string,
    list: MenuCategoryBlock[],
  },
|};

export const useGetContent = (tab: Tab): UseGetContentReturnType => {
  const content = getContent(tab);
  const { menuCategoryBlocks } = useContext(StoreInfoContext);

  return ({
    main: content.main,
    second: content.second,
    third: content.third,
    fourth: {
      title: "Utvalt",
      list: menuCategoryBlocks[tab] ?? [],
    },
  }: UseGetContentReturnType);
};

type ContentProps = {
  tab: Tab,
  active: boolean,
  numCards: number,
};
export const Submenu = ({ tab, active, numCards }: ContentProps): React$Node => {
  const t = useTranslate();
  const content = useGetContent(tab);

  return (
    <div className={cn(styles.submenu, active && styles.active)}>
      <Wrapper className={styles.wrapper}>
        <div className={styles.left}>
          <div className={styles.first}>
            <h4>{content.main.title}</h4>
            <ul>
              {content.main.links.map(l => (
                <li key={l.text}>
                  <Link to={l.target}>{l.text}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.second}>
            <h4>{content.second.title}</h4>
            <ul>
              {content.second.links.map(l => (
                <li key={l.text}>
                  <Link to={l.target}>{l.text}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.third}>
            <h4>{content.third.title}</h4>
            <ul>
              {content.third.links.map(l => (
                <li key={l.text}>
                  <Link to={l.target}>{l.text}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles.right}>
          <h4>{content.fourth.title}</h4>
          <div className={styles.right_cards}>
            {content.fourth.list.map((l, i) => (
              <Card key={l.title} content={l} visible={active && i < numCards} />
            ))}
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

const Card = ({ content, visible }: { content: MenuCategoryBlock, visible: boolean }): React$Node => {
  return (
    <Link to={content.url} className={styles.card}>
      <img className={cn({ [styles.showImage]: visible })} src={content.image} alt={content.title} loading='lazy' />
      <p>{content.title}</p>
    </Link>
  );
};

/* @flow */

import type { CmsPage } from "../../types/cmsPage.flow";

import React, { useRef, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Wrapper } from "../Wrapper";
// import Breadcrumbs from "../Breadcrumbs";
import { getCmsMeta, metaToHelmetProps } from "../../helpers/get-meta";
import { useTranslate } from "@out-of-home/use-translate";
import { useScrollTopOrElement } from "../../helpers/use-scroll-top-or-element";

type Props = {
  cmsPage: CmsPage,
};

const toggleAccordionTitle = (e: MouseEvent) => {
  if (e.target instanceof HTMLElement) {
    const element = e.target.parentElement;

    if (element) {
      const isOpen = element.classList.contains("open");

      if (!isOpen) {
        element.classList.add("open");
      } else {
        element.classList.remove("open");
      }
    }
  }
};

export const CmsView = ({ cmsPage }: Props): React$Node => {
  const meta = getCmsMeta(cmsPage);
  const t = useTranslate();
  useScrollTopOrElement();

  return (
    <Wrapper>
      <Helmet title={meta.title}>
        {metaToHelmetProps(t, "meta", meta.data)}
        {metaToHelmetProps(t, "link", meta.link)}
      </Helmet>

      {/*
      <Breadcrumbs current={cmsPage.contentHeading} />
      */}

      {cmsPage.contentHeading && <h1>{cmsPage.contentHeading}</h1>}

      <div
        key={cmsPage.url}
        className="typography"
        dangerouslySetInnerHTML={{ __html: cmsPage.content }}
      />
    </Wrapper>
  );
};

/* @flow */

import type { Customer } from "../../../types/customer.flow";

import React, { useState } from "react";
import cn from "classnames";
import { Link, useHistory } from "react-router-dom";
import { useTranslate } from "@out-of-home/use-translate";
import styles from "./styles.scss";
import { Wrapper } from "../../Wrapper";
import { Input } from "../../Input";
import { Button } from "../../Button";
import { Checkbox } from "../../Checkbox";
import { Field } from "../../Field";
import { NewEmailInput } from "../../NewEmailInput";
import { focusInvalidField } from "../../../helpers/formHelpers";
import { useCustomer } from "../../../helpers/use-customer";
import { emailValid, isPassword } from "../../../helpers/validationRules";
import { updateCustomer } from "../../../state/customer";
import { useSendMessage } from "crustate/react";
import { addMessage } from "../../../state/messages";
import {
  Form,
  rules,
  conditional,
  match,
  isRequired,
  isEmail,
} from "@awardit/formaggio";

const validation = rules([
  isEmail("email"),
  emailValid("email", "emailAvailable"),
  isRequired("firstname"),
  isRequired("lastname"),
  match("password", "password_repeat"),
  conditional((s) => Boolean(s.password), isPassword("password")),
]);

type Props = {
  className?: string,
};

export const EditUser = (): React$Node => {
  const t = useTranslate();
  const { customer, state: customerState } = useCustomer();
  const loading = customerState === "UPDATING";
  const sendMessage = useSendMessage();
  const { push } = useHistory();
  const [emailAvailable, setEmailAvailable] = useState(false);
  const [state, setState] = useState({
    email: customer?.email || "",
    firstname: customer?.firstname || "",
    lastname: customer?.lastname || "",
    emailAvailable: true,
    password: "",
    password_repeat: "",
    isSubscribedToNewsletter: customer?.isSubscribedToNewsletter || false,
  });

  const formState = { ...state, emailAvailable };

  const validations = validation((formState: any));

  const submit = async (e) => {
    e.preventDefault();

    const resp = await sendMessage(
      updateCustomer(
        state.email,
        state.password,
        state.isSubscribedToNewsletter,
        {
          firstname: state.firstname,
          lastname: state.lastname,
        }
      )
    );
  };

  const toggleIsSubscribedToNewsletter = () => {
    setState({
      ...state,
      isSubscribedToNewsletter: !state.isSubscribedToNewsletter,
    });
  };

  return (
    <Wrapper variant="thin" className={styles.block}>
      <h3 className="text-color--muted">{t("ACCOUNT.EDIT_USER")}</h3>

      <Form
        errors={validations}
        value={(formState: any)}
        onError={focusInvalidField}
        onChange={(x: any) => setState(x)}
        onSubmit={submit}
      >
        <div className="row row--large">
          <NewEmailInput
            name="email"
            label={t("ADDRESS.EMAIL")}
            value={formState.email}
            variant="box"
            available={formState.emailAvailable}
            setAvailable={(x, value) => {
              setEmailAvailable(x || value === customer?.email);
            }}
          />
        </div>

        <div className="row row--large">
          <Field
            name="firstname"
            label={t("ADDRESS.FIRSTNAME")}
            autoComplete="given-name"
            variant="box"
          />
        </div>

        <div className="row row--large">
          <Field
            name="lastname"
            label={t("ADDRESS.LASTNAME")}
            autoComplete="family-name"
            variant="box"
          />
        </div>

        <div className="row row--large">
          <Field
            name="password"
            type="password"
            label={t("ACCOUNT.CHANGE_PASSWORD")}
            autoComplete="new-password"
            variant="box"
          />
        </div>

        <div className="row row--large">
          <Field
            name="password_repeat"
            type="password"
            label={t("ACCOUNT.PASSWORD_REPEAT")}
            autoComplete="new-password"
            variant="box"
          />
        </div>

        <div className="row row--large">
          <Field
            name="isSubscribedToNewsletter"
            render={(props) => (
              <Checkbox
                {...props}
                name="isSubscribedToNewsletter"
                value={state.isSubscribedToNewsletter}
                onChange={toggleIsSubscribedToNewsletter}
              >
                {t("ACCOUNT.SUBSCRIBE_TO_NEWSLETTER")}
              </Checkbox>
            )}
          />
        </div>

        <div className={cn("row--large", styles.buttons)}>
          <Button
            disabled={loading}
            className={styles.button}
            variant="ghost large"
            to={"/" + t("ROUTES.ACCOUNT")}
          >
            {t("ACCOUNT.BACK")}
          </Button>
          <Button
            loading={loading}
            className={styles.button}
            variant="primary large"
            type="submit"
          >
            {t("ACCOUNT.SAVE")}
          </Button>
        </div>
      </Form>
    </Wrapper>
  );
};

/* @flow */

import React, { useCallback, useEffect, useContext, useRef } from "react";
import { useTranslate } from "@out-of-home/use-translate";
import styles from "./styles.scss";
import { useData, useSendMessage } from "crustate/react";
import cn from "classnames";
import { AMTables } from "./amtables";
import ShippingIcon from "../../icons/shipping.svg";
import { Counter } from "../Counter";
import { TimeContext } from "../../context/time";
import {
  updateShippingMethod,
  setPreferredDeliveryDate,
} from "../../state/quote";
import { QuoteData } from "../../data";
import { useEffectOnChange, useOnShippingChange } from "../../helpers";
import { Spinner } from "../Spinner";
import type { QuoteShipping } from "../../types/quote.flow";
import { AnalyticsContext } from "../../helpers/analytics/context";

type Props = {
  className?: string,
  method: ?string,
  loading: boolean,
  setLoading: (boolean) => void,
  setStockPopupOpen: (boolean) => void,
};

export const ShippingMethods = ({
  method,
  loading,
  setLoading,
  className,
  setStockPopupOpen,
}: Props): React$Node => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const { now } = useContext(TimeContext);
  const { registerAddShippingInfo } = useContext(AnalyticsContext)
  const hasMounted = useRef();
  const quoteData = useData(QuoteData);
  const quote = quoteData.state !== "LOADING" ? quoteData.data : null;
  const cartQty = quote?.items.reduce((a, c) => (a += c.qty), 0);
  const openStockPopup = useCallback(() => setStockPopupOpen(true), []);
  const prevShipping = useRef<QuoteShipping["method"]["code"] | null>(null);

  // Update shipping method automatically when cart is changed
  useEffect(() => {
    if (hasMounted.current || !quote?.shipping) {
      sendMessage(updateShippingMethod());
    }

    hasMounted.current = true;
  }, [cartQty]);

  // send event add_shipping_info to GA on initial load and when updated
  useEffect(() => {
    if (quoteData.state === "LOADED") {
      const shipping = quoteData.data.shipping;
      const prevShippingCode = prevShipping.current;

      if (shipping && shipping.method !== prevShippingCode) {
        prevShipping.current = shipping.method.code;
        registerAddShippingInfo({ ...quoteData.data, shipping })
      }
    }
  }, [quoteData.state]);

  if (!method || !quote) {
    return (
      <div className={cn(styles.block, className)}>
        <Spinner className={styles.spinner} variant="large inverted" />
      </div>
    );
  }

  return (
    <div className={cn(styles.block, className)}>
      <AMTables
        quote={quote}
        loading={loading}
        setLoading={setLoading}
        openStockPopup={openStockPopup}
      />
      <Counter className={styles.counter} />
    </div>
  );
};

/* @flow */

import type { Validator } from "@awardit/formaggio";
import type { CustomerAddress } from "../../types/customer.flow";
import type { AlcoholLicense } from "../../types/alcohol-license.flow";

import React, { useRef, useEffect, useState, useContext } from "react";
import cn from "classnames";
import { rules, isRequired } from "@awardit/formaggio";
import { QuoteData, AlcoholLicensesData } from "../../data";
import { Link, NavLink } from "react-router-dom";
import { useTranslate } from "@out-of-home/use-translate";
import { Button } from "../Button";
import { Field } from "../Field";
import { FileUpload } from "../FileUpload";
import { Dropdown, DropdownItem } from "../Dropdown";
import { addressLabel } from "../Address";
import { useData, useSendMessage } from "crustate/react";
import SystemMessages from "../SystemMessages";
import { focusInvalidField } from "../../helpers/formHelpers";
import { range } from "../../helpers";
import { TimeContext } from "../../context/time";
import styles from "./styles.scss";

export type FormData = {
  year: ?string,
  month: ?string,
  day: ?string,
  filename: ?string,
  data: ?string,
  address: ?string,
};

type Props = {|
  className?: string,
  addresses?: Array<CustomerAddress>,
  data: FormData,
  setData: (FormData) => void,
  prefix?: string,
|};

export const AlcoholLicenseFieldset = ({
  data,
  setData,
  prefix,
  ...props
}: Props): React$Node => {
  const t = useTranslate();
  const { now } = useContext(TimeContext);
  const year = now.getFullYear();
  const addresses = props.addresses || null;
  const dataAddress = typeof data.address === "string" ? data.address : null;

  const minYear = now.getFullYear();
  const isCurrentYear = data.year === now.getFullYear();
  const isCurrentMonth = data.month === now.getMonth() + 1;

  const minMonth = isCurrentYear ? now.getMonth() + 1 : 1;
  const minDay = isCurrentYear && isCurrentMonth ? now.getDate() + 1 : 1;

  const prefixName = (name) => {
    if (prefix) {
      return prefix + "." + name;
    }
    return name;
  };

  const onChange = (x) => {
    setData(x);
  };

  return (
    <div className={cn(styles.block, props.className)}>
      {addresses && (
        <div className={styles.col}>
          <label className={styles.label}>
            {t("ALCOHOL_LICENSE.ADDRESS_LABEL")}
          </label>

          <Field
            value={dataAddress || ""}
            name={prefixName("address")}
            render={(props) => (
              <Dropdown
                {...props}
                className={cn(styles.addresses, {
                  [styles["dropdownErrors"]]:
                    props.submitted && props.errors.length,
                })}
                name={prefixName("address")}
                label={t("ALCOHOL_LICENSE.ADDRESS_PLACEHOLDER")}
                value={dataAddress}
                maxHeight={300}
                headerContent={(x) => {
                  const selectedAddress = addresses.find(
                    (x) => x.id === dataAddress
                  );
                  const label =
                    selectedAddress && addressLabel(selectedAddress);

                  if (!label) {
                    return null;
                  }

                  return label;
                }}
                onChange={(x) => {
                  const v: string = String(x.value);
                  onChange({ ...data, address: v });
                }}
              >
                {addresses
                  .filter((x) => x.id)
                  .map((x) => (
                    <DropdownItem key={x.id} value={x.id}>
                      {addressLabel(x)}
                    </DropdownItem>
                  ))}
              </Dropdown>
            )}
          />
        </div>
      )}

      <div className={styles.col}>
        <label className={styles.label}>
          {t("ALCOHOL_LICENSE.VALID_TO_LABEL")}
        </label>

        <div className={styles.dates}>
          <Field
            name={prefixName("year")}
            render={(props) => (
              <div>
                <Dropdown
                  {...props}
                  name={prefixName("year")}
                  label={t("ALCOHOL_LICENSE.YEAR_PLACEHOLDER")}
                  value={String(data.year)}
                  maxHeight={300}
                  defaultLabel={t("ALCOHOL_LICENSE.YEAR_PLACEHOLDER")}
                  onChange={(x) => onChange({ ...data, year: String(x.value) })}
                >
                  {range(minYear || year, year + 10).map((x) => (
                    <DropdownItem key={x} value={x}>
                      {x}
                    </DropdownItem>
                  ))}
                </Dropdown>
              </div>
            )}
          />

          <Field
            name={prefixName("month")}
            render={(props) => (
              <div>
                <Dropdown
                  {...props}
                  name={prefixName("month")}
                  label={t("ALCOHOL_LICENSE.MONTH_PLACEHOLDER")}
                  value={String(data.month)}
                  maxHeight={300}
                  onChange={(x) =>
                    onChange({ ...data, month: String(x.value) })
                  }
                >
                  {range(minMonth || 1, 12).map((x) => (
                    <DropdownItem key={x} value={x}>
                      {x}
                    </DropdownItem>
                  ))}
                </Dropdown>
              </div>
            )}
          />

          <Field
            name={prefixName("day")}
            render={(props) => (
              <div>
                <Dropdown
                  {...props}
                  name={prefixName("day")}
                  label={t("ALCOHOL_LICENSE.DAY_PLACEHOLDER")}
                  value={String(data.day)}
                  maxHeight={300}
                  onChange={(x) => onChange({ ...data, day: String(x.value) })}
                >
                  {range(minDay || 1, 31).map((x) => (
                    <DropdownItem key={x} value={x}>
                      {x}
                    </DropdownItem>
                  ))}
                </Dropdown>
              </div>
            )}
          />
        </div>
      </div>

      <div className={styles.col}>
        <label className={styles.label}>
          {t("ALCOHOL_LICENSE.FILE_LABEL")}
        </label>

        <FileUpload
          className={styles.upload}
          name={prefixName("filename")}
          value={data.filename}
          accept="application/pdf, image/*"
          onChange={(x) =>
            onChange({
              ...data,
              filename: x.name,
              data: x.content,
            })
          }
        />
      </div>
    </div>
  );
};

/* @flow */

import React, { useCallback } from "react";
import { useTranslate } from "@out-of-home/use-translate";
import { createTaskBodyReturnItem } from "../../../helpers";
import { ReclaimGeneric } from "../reclaim-generic";

type Props = {
  onSuccess: (title: string, text: string) => void,
};

export const ReturnItem = (props: Props): React$Node => (
  <ReclaimGeneric
    {...props}
    createTaskBodyFunc={createTaskBodyReturnItem}
    onSuccessMessageKey={"STATUS_CODE.WFM_RETURN_ITEM__SUCCESS"}
    fileUploadNotice={"Observera att bild på skadan måste bifogas för att vi ska kunna hantera din reklamation"}
  />
);

/* @flow */

import React, { useState, useEffect, useRef, Component } from "react";
import { Link } from "react-router-dom";
import { useTranslate } from "@out-of-home/use-translate";
import StarIcon from "../../icons/star.svg";
import cn from "classnames";
import { Button } from "../Button";
import styles from "./styles.scss";
import { ArrowLink } from "../ArrowLink";

const START_DELAY = 500;
const LETTER_DELAY = 70;

const brands = [
  // { url: "/p/_Ar%20Functional", name: "Ar functional" },
  //{ url: "/p/_Bacardi", name: "Bacardi" },
  { url: "/p/_Clean%20Drink", name: "Clean Drink" },
  // { url: "/p/_Cloetta", name: "Cloetta" },
  //{ url: "/p/_Duni", name: "Duni" },
  //{ url: "/p/_ECOLAB", name: "Ecolab" },
  { url: "/p/_Guldfågeln", name: "Guldfågeln" },
  //{ url: "/p/_Homie%20life%20in%20balance", name: "Homie" },
  // { url: "/p/_Legendz", name: "Legendz" },
  // { url: "/p/_Löfbergs", name: "Löfbergs" },
  { url: "/p/_Loka", name: "Loka" },
  //{ url: "/p?manufacturer=Matkompaniet", name: "Matkompaniet" },
  { url: "/p?manufacturer=McCain%20Foods%20Europe%20BV", name: "McCain" },
  //{ url: "/p/_Oatlaws", name: "Oatlaws" },
  //{ url: "/p/_Oatly", name: "Oatly" },
  // { url: "/p/_Red%20Bull", name: "Red Bull" },
  //{ url: "/p/_Santa%20Maria", name: "Santa Maria" },
  { url: "/p/_Trocadero", name: "Trocadero" },
  { url: "/p?manufacturer=Vitamin%20Well%20AB", name: "Vitamin Well" },
  { url: "/p/_Zeta", name: "Zeta" },
  //{ url: "/p/_Oscar", name: "Oscar" },
  //{ url: "/p/_Good%20Gum", name: "Good Gum" },
  //{ url: "/p?manufacturer=Getraw%20AB", name: "GET RAW" },
  //{ url: "/p/_Kiviks%20Musteri", name: "Kiviks Musteri" },
  //{ url: "/p/_Pedros", name: "Pedros" },
  //{ url: "/p/_Önos", name: "Önos" },
  //{ url: "/p/_Felix", name: "Felix" },
  // { url: "/p/_The%20Naia%20Initiative", name: "Naia" },
  //{ url: "/p/_Oh%20Mungood", name: "Oh Mungood" },
  //{ url: "/p/_Delicato", name: "Delicato" },
  //{ url: "/p/_Oddbird", name: "Oddbird" },

  { url: "/p/?manufacturer=HKScan Sweden AB", name: "Scan" },
  { url: "/p/_Celsius", name: "Celsius" },
  // { url: "/p/_REST%20Drinks", name: "Rest Drinks" },
  // { url: "/p/_Blockhead", name: "Blockhead" },
  { url: "/p/_Billys", name: "Billys" },
];

type Props = {
  className?: string,
  t: (string) => string,
};

type State = {
  current: number,
  currentLetter: number,
  brandVisible: boolean,
  imageVisible: boolean,
  textVisible: boolean,
  webpSupport: boolean,
  showBrand: boolean,
  images: { [string]: string },
};

class PromotionInner extends Component<Props, State> {
  img: ?HTMLImageElement;

  timeout: ?TimeoutID = null;
  promise: ?Promise<any> = null;
  mouseOver: { value: boolean } = { value: false };

  state = {
    current: 0,
    currentLetter: 0,
    brandVisible: false,
    imageVisible: false,
    textVisible: false,
    images: {},
    webpSupport: false,
    showBrand: false,
  };

  componentDidMount() {
    const current = Math.floor(Math.random() * brands.length);

    if (typeof Modernizr !== "undefined") {
      Modernizr.on("webp", (result) => {
        this.setState(
          {
            current: current,
            showBrand: true,
            webpSupport: result ? true : false,
          },
          () => {
            this.animate();
          },
        );
      });
    } else {
      this.setState({ showBrand: true, current: current }, () => {
        this.animate();
      });
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    if (this.promise) {
      // @TODO: use cancellable promises to prevent potential leaks
      this.promise = null;
    }
  }

  delay = (time) => (t) =>
    new Promise((resolve, _reject) => {
      this.timeout = setTimeout(() => resolve(t), time);
      return this.timeout;
    });

  next = (): Promise<void> => {
    return new Promise((resolve) => {
      this.setState(
        {
          current: this.state.current < brands.length - 1 ? this.state.current + 1 : 0,
          currentLetter: 0,
        },
        resolve,
      );
    });
  };

  runThroughLetters = (): Promise<void> => {
    const name = brands[this.state.current].name + ".";
    return new Promise((resolve) => {
      this.setState({ brandVisible: true });
      this.timeout = setTimeout(() => {
        const interval = setInterval(() => {
          if (this.state.currentLetter > name.length) {
            clearInterval(interval);
            resolve();
          }
          this.setState({ currentLetter: this.state.currentLetter + 1 });
        }, LETTER_DELAY);
      }, START_DELAY);
    });
  };

  fadeInContent = (): Promise<void> => new Promise((resolve) => this.setState({ textVisible: true }, resolve));

  hideImage = (): Promise<void> =>
    new Promise((resolve) => this.setState({ imageVisible: false, textVisible: false, brandVisible: false }, resolve));

  showImage = (): Promise<void> => new Promise((resolve) => this.setState({ imageVisible: true }, resolve));

  downloadImage = () => {
    const brand = brands[this.state.current].name;
    const src = `/assets/images/splash_screens/fit/${brand}.${this.state.webpSupport ? "webp" : "png"}`;

    // wait until image is downloaded and wait atleast START_DELAY
    return new Promise((resolve) => (this.timeout = setTimeout(resolve, START_DELAY))).then(() => {
      const downloadImage = new Image();
      downloadImage.src = src;

      return new Promise((resolve) => {
        downloadImage.onload = () => {
          this.setState(
            {
              images: {
                ...this.state.images,
                [String(brand)]: src,
              },
            },
            resolve,
          );
        };
      });
    });
  };

  onMouseEnter = () => {
    this.mouseOver.value = true;
  };

  onMouseLeave = () => {
    this.mouseOver.value = false;
  };

  checkMouseOver = (): Promise<void> =>
    new Promise(async (resolve) => {
      while (this.mouseOver.value) {
        await new Promise((resolve, _reject) => setTimeout(() => resolve(), 1000));
      }
      resolve();
    });

  animate = () => {
    this.runThroughLetters()
      .then(this.downloadImage)
      .then(this.showImage)
      .then(this.delay(1000))
      .then(this.fadeInContent)
      .then(this.delay(4000))
      .then(this.checkMouseOver)
      .then(this.hideImage)
      .then(this.delay(1000))
      .then(this.next)
      .then(this.animate);
  };

  render() {
    const { t, className } = this.props;
    const { current, currentLetter, imageVisible, brandVisible, textVisible, webpSupport, showBrand } = this.state;
    const brand = brands[current];
    const name = brand.name + ".";

    return (
      <div className={cn(styles.block, className)} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        <div className={styles.background} />
        <div className={styles.imgContainer}>
          {showBrand && (
            <div className={cn(styles.imgContainerInner, { [styles["imgContainerInnerVisible"]]: imageVisible })}>
              <img
                alt={brand.name}
                ref={(x) => (this.img = x)}
                className={styles.img}
                src={`/assets/images/splash_screens/fit/${brand.name}.${webpSupport ? "webp" : "png"}`}
              />
            </div>
          )}
        </div>
        <div className={styles.content}>
          <p className={styles.text}>{t("SPLASH.SHOP_DIRECTLY_FROM")}</p>
          <div>
            <p
              className={cn("power-link", styles.text, styles.brandName, {
                [styles.text__hidden]: !brandVisible || currentLetter === 0,
              })}
            >
              {name.substr(0, currentLetter) || " "}
            </p>
            <p
              className={cn(styles.text, {
                [styles.text__hidden]: !textVisible,
              })}
            >
              {t("SPLASH.ALMOST")}.
            </p>
          </div>

          {brand.url && (
            <ArrowLink
              to={brand.url}
              className={cn(styles.cta, {
                [styles["visible"]]: imageVisible,
              })}
            >
              Se allt från {name.substr(0, name.length - 1)}
            </ArrowLink>
          )}
        </div>
      </div>
    );
  }
}

export const Promotion = ({ className }: { className?: string }): React$Node => {
  const t = useTranslate();
  return <PromotionInner className={className} t={t} />;
};

/* @flow */

import type { Customer, CustomerAddress } from "../../../types/customer.flow";

import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useTranslate } from "@out-of-home/use-translate";
import { find } from "@out-of-home/diskho";
import { useSendMessage } from "crustate/react";
import { Link, useParams, useHistory } from "react-router-dom";
import { addMessage } from "../../../state/messages";
import styles from "../EditUser/styles.scss";
import { Wrapper } from "../../Wrapper";
import { Input } from "../../Input";
import { Button } from "../../Button";
import { Field } from "../../Field";
import { focusInvalidField } from "../../../helpers/formHelpers";
import { lengthLte, nestedRule } from "../../../helpers/validationRules";
import { useCustomer } from "../../../helpers/use-customer.js";
import {
  createCustomerAddress,
  updateCustomerAddress,
  deleteCustomerAddress,
} from "../../../state/customer";

import { Form, rules, isRequired } from "@awardit/formaggio";

const validation = rules([
  isRequired("firstname"),
  isRequired("lastname"),
  isRequired("company"),
  nestedRule("street", rules([isRequired("0"), lengthLte("1", 20)])),
  isRequired("postcode"),
  isRequired("city"),
  isRequired("telephone"),
]);

type Props = {
  className?: string,
  customer: Customer,
  address: CustomerAddress,
  saveCustomer: Function,
  update: Function,
  loading: boolean,
  error: boolean,
  addressState: string,
  lastEvent: string,
  history: { push: Function },
};

export const EditAddress = (props: Props): React$Node => {
  const sendMessage = useSendMessage();
  const params = useParams();
  const { push } = useHistory();
  const t = useTranslate();
  const { customer, state: customerState } = useCustomer();
  const id: string | null = params.id || null;
  const addresses = customer?.addresses || [];
  const address = find(addresses, (x) => String(x.id) === String(id));
  const loading = customerState === "UPDATING";

  const [state, setState] = useState({
    ...address,
    street: [
      (address && address.street[0]) || "",
      (address && address.street[1]) || undefined,
    ].filter((x) => x),
  });

  const deleteAddress = () => {
    if (id !== null) {
      sendMessage(deleteCustomerAddress(id));
    }
  };

  const submit = (e) => {
    e.preventDefault();
    const data = {
      firstname: state.firstname,
      lastname: state.lastname,
      company: state.company,
      postcode: state.postcode,
      city: state.city,
      telephone: state.telephone,
      street: state.street,
      countryCode: t("LOCALE.SHORT"),
    };

    if (address && id !== null) {
      sendMessage(updateCustomerAddress(id, data, true));
    } else {
      sendMessage(createCustomerAddress(data, true));
    }
  };

  const validations = validation(state);

  return (
    <Wrapper variant="thin" className={styles.block}>
      <h3 className="text-color--muted">
        {t(id !== null ? "ACCOUNT.EDIT_ADDRESS" : "ACCOUNT.CREATE_ADDRESS")}
      </h3>

      <Form
        value={(state: any)}
        errors={validations}
        onChange={(x: any) => setState(x)}
        onError={focusInvalidField}
        onSubmit={submit}
      >
        <div className="row row--large">
          <Field
            name="firstname"
            label={t("ADDRESS.FIRSTNAME")}
            autoComplete="given-name"
            variant="box"
          />
        </div>

        <div className="row row--large">
          <Field
            name="lastname"
            label={t("ADDRESS.LASTNAME")}
            autoComplete="family-name"
            variant="box"
          />
        </div>

        <div className="row row--large">
          <Field
            name="company"
            label={t("ADDRESS.COMPANY")}
            autoComplete="organization"
            variant="box"
          />
        </div>

        <div className="row row--large">
          <Field
            name="street.0"
            label={t("ADDRESS.STREET")}
            autoComplete="address-line1"
            variant="box"
          />
        </div>

        <div className="row row--large">
          <Field
            name="postcode"
            label={t("ADDRESS.POSTCODE")}
            autoComplete="postal-code"
            variant="box"
          />
        </div>

        <div className="row row--large">
          <Field
            name="city"
            label={t("ADDRESS.CITY")}
            autoComplete="city"
            variant="box"
          />
        </div>

        <div className="row row--large">
          <Field
            name="telephone"
            label={t("ADDRESS.TELEPHONE")}
            autoComplete="tel"
            variant="box"
          />
        </div>

        <div className="row row--large">
          <Field
            name="street.1"
            label={t("ADDRESS.STREET_2")}
            autoComplete="address-line2"
            variant="box"
          />
        </div>

        <div className={cn("row", "row--large", styles.buttons)}>
          <Button
            disabled={loading}
            className={styles.button}
            variant="ghost
            large"
            to={"/" + t("ROUTES.ACCOUNT")}
          >
            {t("ACCOUNT.BACK")}
          </Button>
          <Button
            loading={loading}
            className={styles.button}
            variant="primary large"
            type="submit"
          >
            {t("ACCOUNT.SAVE")}
          </Button>
        </div>

        {address && id !== null && (
          <div className="row row--huge">
            <h4>{t("ACCOUNT.MORE_ALTERNATIVES")}</h4>
            <span onClick={deleteAddress} className={styles.link}>
              {t("ADDRESS.DELETE")}
            </span>
          </div>
        )}
      </Form>
    </Wrapper>
  );
};

/* @flow */

import type { Quote, QuoteItem } from "../../../types/quote.flow";

import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import cn from "classnames";
import { useSendMessage } from "crustate/react";
import styles from "./styles.scss";
import { CheckoutCart } from "../CheckoutCart";
import { Button } from "../../Button";
import { QtyPicker, Qty } from "../../QtyPicker";
import { useCustomer } from "../../../helpers/use-customer";
import { useFormat } from "../../../helpers/use-format";
import breakpoints from "../../../helpers/breakpoints";
import RemoveIcon from "../../../icons/close.svg";
import { setItemQty } from "../../../state/quote-items";
import { Spinner } from "../../Spinner";
import { Foldable } from "../../Foldable";
import { useData } from "crustate/react";
import { QuoteData, useIsQuoteItemsFetching } from "../../../data";
import { UIContext } from "../../../context/ui";
import { useTranslate } from "@out-of-home/use-translate";
import { itemOutOfStock, itemStockOverflow } from "./helpers";
import { setPreferredDeliveryDate } from "../../../state/quote";
import { useClient } from "../../../entrypoint/shared";
import { stockAdjustLog } from "../../../queries";
import { AnalyticsContext } from "../../../helpers/analytics/context";

type Props = {
  className?: string,
  quote: Quote,
  onClose: (Event) => void,
  onConfirm: (e?: Event, placeOrder?: boolean) => void,
};

const Cart = ({
  items,
  disabled,
}: {
  items: Array<QuoteItem>,
  disabled: boolean,
}) => {
  const sendMessage = useSendMessage();
  const { customer } = useCustomer();
  const { browserWidth } = useContext(UIContext);
  const { registerModifyCart } = useContext(AnalyticsContext);

  const isSmall = browserWidth < breakpoints.tiny;

  return (
    <table className={styles.cart}>
      <thead>
        <tr>
          <th>Produktnamn</th>
          <th>I lager</th>
          <th>{disabled ? "Justerat antal" : "Beställt"}</th>
          {!isSmall && <th></th>}
        </tr>
      </thead>
      <tbody>
        {items.map((item) => {
          const stockQty = item.product.oohStock?.qty || 0;
          const allowBackorders = Boolean(item.product.oohStock?.backorders);

          const onRemove = () => {
            if (item.product.buyRequest) {
              sendMessage(setItemQty(item.product.buyRequest, 0));
              registerModifyCart([{ product: { ...item.product, qty: item.qty } }], "remove_from_cart", "stock_popup");
            }
          };

          return (
            <tr key={item.product.sku} className={styles.cartRow}>
              <td className={cn(styles.column, styles.column__name)}>
                <div className={styles.ellipsis}>
                  <span className={styles.name}>{item.product.name}</span>
                  <span className={styles.brand}>
                    {item.product.attributes && item.product.attributes.brand}
                  </span>
                </div>
              </td>
              <td className={cn(styles.column, styles.column__inStock)}>
                {stockQty} {!isSmall && "st"}
              </td>
              {isSmall && (
                <td className={cn(styles.column, styles.column__qty)}>
                  <Qty product={item.product} />
                </td>
              )}
              {!isSmall && (
                <td className={cn(styles.column, styles.column__qty)}>
                  <QtyPicker
                    className={styles.qtyPicker}
                    product={item.product}
                    variant="orderOverview"
                    qty={item.qty}
                    customer={customer}
                  />
                </td>
              )}
              {!isSmall && (
                <td
                  className={cn(styles.column, styles.column__remove, {
                    [styles.column__hidden]:
                      disabled && stockQty === 0 && !allowBackorders,
                  })}
                >
                  <Button onClick={() => !disabled && onRemove()}>
                    <RemoveIcon className={styles.icon} />
                  </Button>
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

/*
 * Popup for low stock warning. Displayed at checkout confirm
 */
export const StockPopup = ({
  className,
  onClose,
  onConfirm,
}: Props): React$Node => {
  const t = useTranslate();
  const { formatDate } = useFormat();
  const sendMessage = useSendMessage();
  const [mounted, setMounted] = useState(false);
  const [markedQuoteItems, setMarkedQuoteItems] = useState([]);
  const quoteData = useData(QuoteData);
  const quote = quoteData.state !== "LOADING" ? quoteData.data : null;
  const [loading, setLoading] = useState(false);
  const noItemsInStock = quote ? quote.items.every(itemOutOfStock) : false;
  const allItemsInStock = quote ? !quote.items.some(itemStockOverflow) : false;
  const isCartFetching = useIsQuoteItemsFetching();
  const client = useClient();
  const { registerModifyCart } = useContext(AnalyticsContext);

  useEffect(() => {
    if (!mounted && quote) {
      setMarkedQuoteItems(quote.items.filter(itemStockOverflow));
      setMounted(true);
    }
  });

  useEffect(() => {
    if (allItemsInStock) {
      setLoading(false);

      // Set earliest delivery date after confirming the cart adjustment
      if (quote) {
        sendMessage(setPreferredDeliveryDate(quote.nextDeliveryDate));
      }
    }
  }, [allItemsInStock]);

  const onAdjust = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);

    const itemsToRemove = quote ? quote.items.filter(itemStockOverflow) : [];

    const logItems = itemsToRemove.map((x) => ({
      sku: x.product.sku,
      qty_removed: x.qty - (x.product.oohStock?.qty || 0),
    }));

    itemsToRemove.forEach((x) => {
      sendMessage(
        setItemQty(x.product.buyRequest || "", x.product.oohStock?.qty || 0)
      );
    });

    registerModifyCart(
      itemsToRemove.map(x => ({ product: { ...x.product, qty: x.qty - (x.product.oohStock?.qty || 0) }})),
      "remove_from_cart",
      "stock_popup"
    );
    // Log to stock adjust log. We don't really care about handling an error here
    // since it doesn't really matter to the end-user.
    client(stockAdjustLog, { items: logItems });
  };

  if (!quote) {
    return null;
  }

  if (noItemsInStock && !mounted) {
    return null;
  }

  return (
    <div>
      {allItemsInStock ? (
        <div className={cn(className, styles.block, styles.block__complete)}>
          <div className={styles.wrapper}>
            <div className="row row--large">
              <header className={styles.header}>
                <h2>Snyggt, nu är vi redo för din beställning!</h2>

                <Button className={styles.closeBtn} onClick={onClose}>
                  <RemoveIcon />
                </Button>
              </header>
              <p>{t("SHIPPING.STOCK_POPUP_ALL_ITEMS_IN_STOCK")}</p>
              <Cart
                disabled={true}
                items={markedQuoteItems.map((x) => {
                  const newItem = quote.items.find(
                    (a) => a.itemBuyRequest === x.itemBuyRequest
                  );
                  return {
                    ...x,
                    product: {
                      ...x.product,
                      // oohStock: newItem ? newItem.product.oohStock : { backOrders: false, qty: 0 }
                    },
                  };
                })}
              />
            </div>
            <div className="row row--large">
              <div className={styles.footer}>
                <Button
                  variant="primary"
                  loading={isCartFetching}
                  onClick={onConfirm}
                >
                  {t("SHIPPING.STOCK_POPUP_CLOSE")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={cn(className, styles.block)}>
          <div className={styles.wrapper}>
            <div className="row row--large">
              <header className={styles.header}>
                <h2>Whoops... Vårt saldo är för lågt :(</h2>

                <Button className={styles.closeBtn} onClick={onClose}>
                  <RemoveIcon />
                </Button>
              </header>
              <p>
                Här är de artiklar som vi inte har tillräckigt med saldo på för
                att skicka er order direkt.
              </p>
              <Foldable open={loading}>
                <Spinner className={styles.spinner} variant="large inverted" />
              </Foldable>
              <Foldable open={!loading}>
                <Cart
                  disabled={false}
                  items={quote.items.filter(itemStockOverflow)}
                />
              </Foldable>
            </div>
            <div className="row row--large">
              <div className={styles.footer}>
                <Button variant="ghost" onClick={(e) => onConfirm(e, true)}>
                  Invänta komplett order
                </Button>
                {!noItemsInStock && (
                  <Button variant="primary" onClick={onAdjust}>
                    {t("SHIPPING.STOCK_POPUP_DELIVERY_IMMEDIATELY")}
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="row row--large">
            <div className={styles.warning}>
              <div className={styles.wrapper}>
                <p>
                  Om du väljer att invänta komplett order bekräftar du en längre
                  leveranstid. Vi kommer att invänta att lagret fylls på så att
                  din order kan skickas i sin helhet. Observera att frakt kan
                  komma att debiteras om ordern blir för liten när artiklarna
                  stryks från er beställning.
                </p>
                <p>
                  <strong>När kommer lagret vara påfyllt?</strong>
                </p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("SHIPPING.STOCK_POPUP_INFO", {
                      deliveryDate: formatDate(
                        new Date(
                          quote.preferredDeliveryDate || quote.nextDeliveryDate
                        )
                      ),
                    }),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

/* @flow */

import type { WFMOrder, WFMOrderProduct } from "../../../../types/wfm.flow";

import React, { useState } from "react";
import cn from "classnames";
import { useCustomer } from "../../../../helpers/use-customer";
import { useRestClient } from "../../../../entrypoint/shared";
import { useSendMessage } from "crustate/react";
import { Button } from "../../../Button";
import { Field } from "../../../Field";
import { TextArea } from "../../../TextArea";
import { useTranslate } from "@out-of-home/use-translate";
import { OrderSelect } from "../../order-select";
import { focusInvalidField } from "../../../../helpers/formHelpers";
import { addMessageTranslated } from "../../../../state/messages";
// import { renderContactButton } from "../../../../containers/Order";
import sharedStyles from "../../shared-styles.scss";

import { Form, rules, isRequired } from "@awardit/formaggio";

type Props = {
  className?: string,
  onSuccess: (title: string, text: string) => void,
};

const validation = rules([isRequired("description")]);

export const InvoiceError = (props: Props): React$Node => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const [state, setState] = useState({ description: "" });
  const [order, setOrder] = useState(null);
  const { customer } = useCustomer();
  const { email: customerEmail } = customer || { email: null };
  const [processing, setProcessing] = useState(false);
  const validations = validation(state);
  const client = useRestClient();

  const submit = (e) => {
    e.preventDefault();

    setProcessing(true);

    client("/api/invoiceError", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        order_id: order?.id,
        email: customerEmail,
        description: state.description,
      }),
    })
      .then((x) => x.json())
      .then((x) => {
        setProcessing(false);

        if (!x.errorCode) {
          props.onSuccess("Tack!", t("STATUS_CODE.WFM_INVOICE_ERROR__SUCCESS"));
        } else {
          sendMessage(
            addMessageTranslated(t(`STATUS_CODE.${x.message}`), "error")
          );
        }
      })
      .catch((e) => {
        setProcessing(false);
        sendMessage(addMessageTranslated(t("STATUS_CODE.WFM__ERROR"), "error"));
      });
  };

  return (
    <Form
      className={sharedStyles.type}
      value={(state: any)}
      errors={validations}
      onError={focusInvalidField}
      onChange={(x: any) => setState({ ...state, ...x })}
      onSubmit={(e) => submit(e)}
    >
      {!order && (
        <div className="row--large">
          <OrderSelect order={order} setOrder={(x) => setOrder(x)} />
        </div>
      )}

      {order && (
        <div className={"row--large"}>
          <div className="row--large">
            <Field
              label="Beskriv vad som är fel"
              rows={7}
              name="description"
              value={state.description}
              component={TextArea}
            />
          </div>

          <div className="row--large">
            <Button loading={processing} variant="primary" type="submit">
              Skicka
            </Button>
          </div>
        </div>
      )}
    </Form>
  );
};

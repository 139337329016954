/* @flow */

import type { Quote, QuoteItem } from "../../../types/quote.flow";

export const itemStockOverflow = (x: QuoteItem): boolean =>
  !x.product.oohStock || x.product.oohStock.qty < x.qty;
export const itemOutOfStock = (x: QuoteItem): boolean =>
  !x.product.oohStock || x.product.oohStock.qty === 0;

export const getQuoteItemsDeliveryDate = (q: Quote): Date => {
  if (q.items.length === 0) {
    return new Date();
  }

  const dates = q.items
    .map((x) => new Date(x.product.estimatedDeliveryDate))
    .sort((a, b) => b - a);

  return dates[0];
};

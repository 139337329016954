/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type {
  PaymentMethodsRequest,
  PaymentMethodsResponse,
} from "../state/payment-methods";
import {
  PAYMENT_METHODS_LOAD_RESPONSE,
  PAYMENT_METHODS_LOAD_REQUEST,
} from "../state/payment-methods";
import { paymentMethods } from "../queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: PaymentMethodsRequest) => {
      const data = await client(paymentMethods);

      return ({
        tag: PAYMENT_METHODS_LOAD_RESPONSE,
        data: data.paymentMethods,
      }: PaymentMethodsResponse);
    },
    subscribe: { [PAYMENT_METHODS_LOAD_REQUEST]: true },
  });
};

export default registerClient;

/* @flow */

import type { Storage } from "crustate";
import type { BrandsRequest, BrandsResponse } from "../state/brands";
import { BRANDS_LOAD_REQUEST, BRANDS_LOAD_RESPONSE } from "../state/brands";

const shuffle = (arr) => {
  let ctr = arr.length;
  let temp;
  let index;

  while (ctr > 0) {
    index = Math.floor(Math.random() * ctr);
    ctr--;
    temp = arr[ctr];
    arr[ctr] = arr[index];
    arr[index] = temp;
  }

  return arr;
};

const registerClient = (storage: Storage, restClient: Fetch) => {
  storage.addEffect({
    effect: async (msg: BrandsRequest) => {
      const data = await restClient("/api/data_brands").then((x) => x.json());

      return ({
        tag: BRANDS_LOAD_RESPONSE,
        data: shuffle(data),
      }: BrandsResponse);
    },
    subscribe: { [BRANDS_LOAD_REQUEST]: true },
  });
};

export default registerClient;

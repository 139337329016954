/* @flow */

import type { Model } from "crustate";
import type { Response } from "./util";
import type { OverdueInvoice } from "../types/order.flow";

import { updateData } from "crustate";

import { EVENT_CUSTOMER_LOGIN, EVENT_CUSTOMER_LOGOUT } from "./events";

type Data =
  | {| state: "PENDING" |}
  | {| state: "LOADING" |}
  | {| state: "LOADED", data: $ReadOnlyArray<OverdueInvoice> |}
  | {| state: "ERROR", error: string |};

export type OverdueInvoicesEffectResponse = Response<
  typeof OVERDUE_INVOICES_EFFECT_RESPONSE,
  $ReadOnlyArray<OverdueInvoice>
>;

export type OverdueInvoicesEffectRequest = {
  tag: typeof OVERDUE_INVOICES_EFFECT_REQUEST,
};

export const OVERDUE_INVOICES_EFFECT_RESPONSE: "overdue_invoices/effect_response" =
  "overdue_invoices/effect_response";
export const OVERDUE_INVOICES_EFFECT_REQUEST: "overdue_invoices/effect_request" =
  "overdue_invoices/effect_request";

export const OverdueInvoicesModel: Model<
  Data,
  {},
  OverdueInvoicesEffectRequest | OverdueInvoicesEffectResponse
> = {
  id: "overdue_invoices",
  init: () => {
    return updateData(
      { state: "LOADING" },
      { tag: OVERDUE_INVOICES_EFFECT_REQUEST }
    );
  },
  update: (state: Data, msg) => {
    switch (msg.tag) {
      case OVERDUE_INVOICES_EFFECT_RESPONSE:
        if (msg.error) {
          return updateData({
            state: "ERROR",
            error: msg.error,
          });
        }

        if (msg.data && state.state === "LOADING") {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      case EVENT_CUSTOMER_LOGIN:
        if (state.state !== "LOADING") {
          return updateData(
            { state: "LOADING" },
            { tag: OVERDUE_INVOICES_EFFECT_REQUEST }
          );
        }
        break;

      case EVENT_CUSTOMER_LOGOUT:
        return updateData({ state: "PENDING" });
      default:
    }
  },
};

/* @flow */
import type { HoursRange } from "../../helpers/deliveryCalendarHelpers";
import type { Quote } from "../../types/quote.flow";
import type { Customer } from "../../types/customer.flow";

import { useClient } from "../../entrypoint/shared";
import {
  clamp,
  dateToISO8601,
  formatTime,
  isHoliday,
  numberToDate,
  round,
  timeToNumber,
  timeWindowToHourRange,
  validateHoursRange,
} from "../../helpers/deliveryCalendarHelpers";
import {
  deliverySchedule,
  quote as quoteQuery,
  setQuotePreferredDeliveryDate,
  setQuoteTimeWindow,
} from "../../queries";
import { useData, useSendMessage } from "crustate/react";
import { addDays, isSameDay } from "date-fns/esm";
import format from "date-fns/format";
import { isWeekend, min } from "date-fns/esm/fp";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { QUOTE_SYNC_RESPONSE, updateQuote } from "../../state/quote";
import { debounce } from "@out-of-home/diskho";
import { useCustomer } from "../../helpers/use-customer";

export type UseDeliveryScheduleReturnOK = {
  type: "OK",
  loading: false,
  getNextAvailableDeliveryDay: (date: Date) => Date,
  isDayEnabled: (d: Date) => boolean,
  deliveryInfo: DeliveryInfo,
  minDate: Date,
};

export type UseDeliveryScheduleReturn = UseDeliveryScheduleReturnOK  | { type: "LOADING", loading: true } | { type: "ERROR", loading: false, error: "COULD_NOT_FETCH_DELIVERY_INFO"}

export type TimeWindow ={|
  minimum: number | null,
  range: HoursRange
|};

export type DeliveryInfoOK = {|
  status: "OK",
  postcode: string,
  deliveryDays: $ReadOnlyArray<boolean>,
  carrier: string,
  leadDays: number,
  timeWindow: Array<TimeWindow>,
|}

export type DeliveryInfoError = {|
  status: "NO_POSTCODE" | "NO_TIMETABLE" | "BELOW_WEIGHT",
  postcode: string,
  deliveryDays: $ReadOnlyArray<boolean>
|}

export type DeliveryInfo =
| DeliveryInfoOK
| DeliveryInfoError

export const fromHoursRangeToString = (time: HoursRange): string => `${format(numberToDate(time.from), "H:mm")}-${format(numberToDate(time.to), "H:mm")}`;

/**
 * Clamp the given `HoursRange` value to range set in `TimeWindow` and returns a string represantation of the time in the format H:MM
 */
const limitTimeWindow = (timeWindow: TimeWindow) => (value: HoursRange): HoursRange => {
    const minSpan = timeWindow.minimum !== null ? timeWindow.minimum : (timeWindow.range.to - timeWindow.range.from);

    let time = round(clamp(value, timeWindow.range.from, timeWindow.range.to));

    if (time.to - time.from < minSpan) {
      time.from = time.to - minSpan;
      time.to = time.from + minSpan;
    }

    if (time.from < timeWindow.range.from) {
      time.from = timeWindow.range.from;
      time.to = timeWindow.range.from + minSpan;
    }

    return time;
};

/**
 * Hook that is used by `useDeliverySchedule` to fetch the delivery schedule
 */
const useFetchDeliveryInfo = () => {
  const client = useClient();

  return useCallback(async (postcode: string) => {
    try {
      const result = await client(deliverySchedule, { postcode: postcode.replace(/[^0-9]/g, "") });

      if (result.deliverySchedule.status === "OK") {
        const deliverySchedule = result.deliverySchedule;
        const timeWindow = result.deliverySchedule.timeWindow;

        const data: DeliveryInfoOK = {
          status: "OK",
          postcode,
          carrier: deliverySchedule.carrierName || "",
          deliveryDays: deliverySchedule.days,
          timeWindow: timeWindow.map(x => ({
            range: {
              from: timeToNumber(x.substr(0, 5)),
              to: timeToNumber(x.substr(6)),
            },
            minimum: deliverySchedule.minimumTimespan,
          })),
          leadDays: deliverySchedule.leadTime || 0,
        };

        return data;
      }

      return ({
        status: result.deliverySchedule.status,
        postcode,
        deliveryDays: result.deliverySchedule.days,
      }: DeliveryInfoError)

    }
    catch {
      return null;
    }
  });
}

/**
 * Hook that is used to fetch the DeliverySchedule for the given postcode
 */
export const useDeliverySchedule = (quote: Quote, setLoadingExternal: boolean => void): UseDeliveryScheduleReturn => {
  const { customer } = useCustomer();
  const [loading, setLoading] = useState<boolean>(false);
  const minDate = useMemo(() => new Date(quote.nextDeliveryDate), [quote.nextDeliveryDate]);
  const _setLoading = useCallback((value: boolean) => {
    setLoading(value);
    setLoadingExternal(value);
  }, []);
  const fetchDeliveryInfo = useFetchDeliveryInfo();
  const [deliveryInfo, setDeliveryInfo] = useState<DeliveryInfo | null>(null);
  const setQuoteTimeWindow = useSetQuoteTimeWindow();
  const setPreferredDeliveryDate = useSetPreferredDeliveryDate();
  const syncQuote = useSyncQuote();

  const isDeliveryDay = (deliveryInfo: DeliveryInfo) => (d: Date): boolean => {
    if (!deliveryInfo) {
      return false;
    }

    const dayOfWeek = (d.getDay() + 6) % 7;

    return deliveryInfo.deliveryDays[dayOfWeek];
  };

  const isSameOrGreaterDay = (a: Date, b: Date) => {
    if (isSameDay(a, b)) {
      return true;
    }

    return a > b;
  }

  const isDayEnabled = (deliveryInfo: DeliveryInfo) => (d: Date) => {

    return !isHoliday(d) && !isWeekend(d) && isSameOrGreaterDay(d, minDate) && isDeliveryDay(deliveryInfo)(d);
  };

  const getNextAvailableDeliveryDay = (deliveryInfo: DeliveryInfo) => {
    const isEnabled = isDayEnabled(deliveryInfo);
    return (date: Date): Date => {
      let d: Date = new Date(date);

      let count = 0;
      // TODO: hur hantera loop här om det inte finns någon dag tillgänglig för postnr?
      while (count < 100) {
        d = addDays(d, 1);

        if (isEnabled(d)) {
          break;
        }

        count++;
      }

      return d;
    };
  }

  useEffect(() => {
    (async () => {
      const postcode = quote.addresses.find((a) => a.type === "billing" && a.isUsedAsShipping)?.postcode;

      if (postcode && (!deliveryInfo?.postcode || deliveryInfo.postcode !== postcode)) {
        _setLoading(true);

        const data = await fetchDeliveryInfo(postcode);

        if (data) {
          setDeliveryInfo(data);

          // validate and sync the timewindow set on the quote of the cusrtomer
          // with the current postcodes timewindow
          let timeWindow = null;
          let newTimeWindow = null;

          // if there isnt a preferredDate on the quote or the current preferredDate isnt valid, set the nextAvailable
          let prefDate = quote.preferredDeliveryDate ? new Date(quote.preferredDeliveryDate) : null;
          const prefDateWeekDayNum = prefDate ? prefDate.getDay() : 1;

          if (data.status === "OK") {
            if (quote.timeWindow) {
              // start by validating the current set timewindow on the quote, if any is set
              const quoteTW = timeWindowToHourRange(quote.timeWindow);

              if (quoteTW && validateHoursRange(data.timeWindow[prefDateWeekDayNum - 1], quoteTW)) {
                timeWindow = fromHoursRangeToString(quoteTW);
              }
            }

            if (!timeWindow && customer?.bringDefaultTimeWindow) {
              // if the quote didnt have a set timewindow
              // check the default timewindow on the customer
              const customerTW = timeWindowToHourRange(customer.bringDefaultTimeWindow);

              if (customerTW && validateHoursRange(data.timeWindow[prefDateWeekDayNum - 1], customerTW)) {
                timeWindow = fromHoursRangeToString(customerTW);
              }
            }
          }

          // if none of the above timewindows didnt validate, set the timewindow to null on the quote
          const result = await setQuoteTimeWindow(quote, timeWindow, false);

          if ((result.setQuoteTimeWindow === "success" || result.setQuoteTimeWindow === "notModified") && timeWindow) {
            newTimeWindow = timeWindow;
          }

          if (!prefDate || !isDayEnabled(data)(prefDate)) {
            const nextDate = new Date(quote.nextDeliveryDate);
            const result = await setPreferredDeliveryDate(quote, nextDate, false);

            if (result.setQuotePreferredDeliveryDate) {
              prefDate = nextDate;
            }
          }

          syncQuote({
            ...quote,
            timeWindow: newTimeWindow,
            preferredDeliveryDate: prefDate ? dateToISO8601(prefDate) : null
          });
        }

        _setLoading(false);
      }
    })();
  }, [quote]);


  if (loading) {
    return { type: "LOADING", loading: true }
  }

  if (!deliveryInfo) {
    return { type: "ERROR", loading: false, error: "COULD_NOT_FETCH_DELIVERY_INFO" };
  }

  return {
    type: "OK",
    loading: false,
    minDate,
    isDayEnabled: isDayEnabled(deliveryInfo),
    getNextAvailableDeliveryDay: getNextAvailableDeliveryDay(deliveryInfo),
    deliveryInfo,
  };
};

/**
 * Hook that is used to set a new Preferred Delivery Date on the Quote
 */
export const usePreferredDeliveryDate = (quote: Quote, isDayEnabled: (d: Date) => boolean): {|
  date: null | Date,
  set: (value: Date) => Promise<void>,
  loading: boolean,
|} => {
  const [loading, setLoading] = useState<boolean>(false);
  const [prefDeliveryDate, setPrefDeliveryDate] = useState(quote.preferredDeliveryDate ? new Date(quote.preferredDeliveryDate) : null);
  const setPreferredDeliveryDate = useSetPreferredDeliveryDate();
  const prevPrefDeliveryDate = useRef(quote.preferredDeliveryDate ? dateToISO8601(new Date(quote.preferredDeliveryDate)) : null);

  const setPreferredDeliveryDateFn = useCallback(async (value: Date) => {
    setLoading(true);

    try {
      prevPrefDeliveryDate.current = dateToISO8601(value); // prevPrefDeliveryDate need to be in sync
      await setPreferredDeliveryDate(quote, value)
      setPrefDeliveryDate(value);
    }
    finally {
      setLoading(false);
    }
  }, [quote]);

  useEffect(() => {
    // if quote.preferredDeliveryDate is changed, there was an adjustment done from the stockpopup or the address was changed
    // and we need to update the local prefDeliveryDate
    if (prevPrefDeliveryDate.current !== quote.preferredDeliveryDate) {
        prevPrefDeliveryDate.current = quote.preferredDeliveryDate;
        setPrefDeliveryDate(quote.preferredDeliveryDate ? new Date(quote.preferredDeliveryDate) : null);
    }
  }, [quote.preferredDeliveryDate]);

  return {
    loading,
    date: prefDeliveryDate,
    set: setPreferredDeliveryDateFn,
  }
}

/**
 * Syncs the Quote in Crustate
 */
const useSyncQuote = () => {
  const sendMessage = useSendMessage();

  return (quote: Quote) => sendMessage({ tag: QUOTE_SYNC_RESPONSE, data: quote })
}

/**
 * Hook that is used to set the preferred delivery date on the quote in magento and Crustate.
 * syncLocal can be used to turn off sync with Crustate
 */
const useSetPreferredDeliveryDate = () => {
  const gqlClient = useClient();
  const syncQuote = useSyncQuote();

  return async (quote, value: Date, syncLocal: boolean = true) => {
    const date = dateToISO8601(value);

    // update preferredDeliveryDate on quote in magento
    const result = await gqlClient(setQuotePreferredDeliveryDate, { value: date });

    if (result.setQuotePreferredDeliveryDate && syncLocal){
      const quoteData = await gqlClient(quoteQuery);
      // update quote in crustate
      syncQuote({
        ...quoteData.quote,
        preferredDeliveryDate: date,
      });
    }

    return result;
  }
}


/**
 * Hook that is used to set the timewindow on the quote in magento and Crustate.
 * syncLocal can be used to turn off sync with Crustate
 */
const useSetQuoteTimeWindow = () => {
  const gqlClient = useClient();
  const syncQuote = useSyncQuote();

  return async (quote: Quote, timeWindow: string | null, syncLocal: boolean = true) => {
    const result = await gqlClient(setQuoteTimeWindow, { timeWindow });

    if ((result.setQuoteTimeWindow === "success"  || result.setQuoteTimeWindow === "notModified") && syncLocal) {
      syncQuote({
        ...quote,
        timeWindow,
     })
    }

    return result;
  }
}

type UseTimeWindowReturn = {|
  current: HoursRange,
  range: HoursRange,
  set: (value: HoursRange) => void
|};
/**
 * Hook that is used to set the timewindow on the quote
 */
export const useTimeWindow = (timeWindow: TimeWindow, quote: Quote, customer: ?Customer, setLoadingExternal: (value: boolean) => void): UseTimeWindowReturn => {
  const [loading, setLoading] = useState(false);
  const setQuoteTimeWindow = useSetQuoteTimeWindow();
  const _limitTimeWindow = useCallback(limitTimeWindow(timeWindow), [timeWindow]);
  const _setLoading = useCallback((value: boolean) => {
    setLoading(value);
    setLoadingExternal(value);
  }, []);

  // string range (HH:MM-HH:MM)
  const [currentTimeWindow, setCurrentTimeWindow] = useState<HoursRange>(() => {
    const _time = quote.timeWindow ? timeWindowToHourRange(quote.timeWindow) : null;

    return _time || timeWindow.range;
  });

  // used to set the timewindow on the quote, debounced 500ms
  const setTimeWindowDebounced = useCallback<(string) => void>(
    debounce((v) => {
      if (typeof v === "string") {
        _setLoading(true);

        setQuoteTimeWindow(quote, v).finally(() => {
          _setLoading(false);
        });
      }
    }, 500)
  , [quote]);

  const _setTimeWindow = useCallback((value: HoursRange) => {
    const time = _limitTimeWindow(value);

    setCurrentTimeWindow(time);
    setTimeWindowDebounced(fromHoursRangeToString(time));
  }, [_limitTimeWindow, setTimeWindowDebounced])

  useEffect(() => {
    if (quote.timeWindow) {
      const time = timeWindowToHourRange(quote.timeWindow);
      if (time) {
        setCurrentTimeWindow(time);
      }
    }
  }, [quote.preferredDeliveryDate]);


  return {
    range: timeWindow.range,
    current: currentTimeWindow,
    set: _setTimeWindow,
  }
}

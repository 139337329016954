/* @flow */

import React, { useRef } from "react";
import cn from "classnames";
import styles from "./styles.scss";

type Props = {
  children?: React$Node,
  className?: string,
};

export const Well = ({ children, className }: Props): React$Node => (
  <div className={cn(className, styles.block)}>{children}</div>
);

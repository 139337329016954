/** @flow */

import React, { useContext, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import styles from "./styles.scss";
import cn from "classnames";
import { UIContext } from "../../context/ui";
import { find } from "@out-of-home/diskho";
import { Button } from "../Button";
import { Dropdown, DropdownItem } from "../Dropdown";
import { useTranslate } from "@out-of-home/use-translate";
// import PreventOutsideScroll from "../PreventOutsideScroll";
import { StoreInfoContext } from "../../entrypoint/shared";
import { DEFAULT_SORT, setSortURL } from "../../helpers/filterHelpers";
import { Drawer, DrawerItem } from "../../components/Drawer";
import type { TFilterQuery, TSort } from "../../types/filter.flow";

type Props = {
  query: TFilterQuery,
  loading: boolean,
  open: boolean,
  layout: "LARGE" | "SMALL",
  options?: ?Array<string>,
};


export const SortMenu = ({ layout = "LARGE", query, open, options }: Props) => {
  const t = useTranslate();
  const { push } = useHistory();
  const { translations } = useContext(StoreInfoContext);
  const sortReset = setSortURL(query, "", translations.ROUTES);
  const { setSortModalOpen } = useContext(UIContext);
  const sortOptions = useMemo(() => ([
    { id: "popularity", label: t("SORT.POPULAR") },
    { id: "brand", label: t("SORT.BRAND") },
    { id: "name", label: t("SORT.NAME") },
    { id: "main_category", label: t("SORT.CATEGORY") },
    { id: "ooh_unitprice", label: t("SORT.COMPARE_PRICE") },
  ]), []);

  if (!options) {
    options = sortOptions.map((x) => x.id);
  }
  const renderSortOption = (key: TSort, label: string) => {
    const sort = query.sort;
    const url = setSortURL(query, key, translations.ROUTES);

    return (
      <Link
        key={key}
        to={url}
        className={cn(styles.link, { [styles.link__active]: sort === key || (!Boolean(sort) && key === DEFAULT_SORT) })}
        onClick={(e) => {
          if (sort === key) {
            e.preventDefault();
          }
        }}
      >
        {label}
      </Link>
    );
  };

  if (layout === "SMALL") {
    return (
      <>
        <div className={styles.top}>
          {options.map((x) => {
            const o = find(sortOptions, (a) => a.id === x);
            if (!o) {
              return;
            }
            return (
              <DrawerItem
                key={o.id}
                active={query.sort === o.id || (!Boolean(query.sort) && o.id === DEFAULT_SORT)}
                to={setSortURL(query, o.id, translations.ROUTES)}
              >
                {o.label}
              </DrawerItem>
            );
          })}
        </div>

        <div className={styles.bottom}>
          <Button className={styles.button} variant="blank" to={sortReset}>
            {t("FILTER.RESET")}
          </Button>
          <Button
            className={styles.button}
            variant="primary"
            onClick={() => setSortModalOpen(false)}
          >
            {t("FILTER.ACTIVATE_FILTER")}
          </Button>
        </div>
      </>
    );
  }

  return (
    <div className={styles.block}>
      {!query.search && (
        <div>
          <div className={styles.block__full}>
            <strong>{t("FILTER.SORT_BY")}</strong>
            {options.map((x) => {
              const o = find(sortOptions, (a) => a.id === x);
              if (!o) {
                return;
              }
              return renderSortOption(o.id, o.label);
            })}
          </div>
          <div className={styles.block__limited}>
            <Dropdown
              label={t("FILTER.SORT_BY")}
              headerContent={(x) => x.label}
              className={styles.dropdown}
              value={query.sort || DEFAULT_SORT}
              onChange={(x) => {
                push(setSortURL(query, x.value, translations.ROUTES));
              }}
            >
              {sortOptions.map((x) => (
                <DropdownItem key={x.id} value={x.id}>
                  {x.label}
                </DropdownItem>
              ))}
            </Dropdown>
          </div>
        </div>
      )}
    </div>
  );
};

/* @flow */

import type {
  ServiceTaskBody,
  WFMOrder,
  WFMOrderItem,
  WFMRow,
  WFMRows,
} from "../../../../types/wfm.flow";
import type { Customer } from "../../../../types/customer.flow";

import React, { useState, useCallback } from "react";
import cn from "classnames";
import { useTranslate } from "@out-of-home/use-translate";
import { Dropdown, DropdownItem } from "../../../Dropdown";
import { TextArea } from "../../../TextArea";
import { Button } from "../../../Button";
import { nextKeyId } from "../../helpers";
import { OrderSelect } from "../../order-select";
import { Row } from "../../Row";
import { Field } from "../../../Field";
import sharedStyles from "../../shared-styles.scss";
import { focusInvalidField } from "../../../../helpers/formHelpers";
import { nestedRule } from "../../../../helpers/validationRules";
import { Form, rules, isRequired } from "@awardit/formaggio";
import { useSubmitCustomerServiceTask } from "../../../../helpers/use-submit-customer-service-task";
import { FileUpload } from "../../../FileUpload";
import styles from "./styles.scss";

type State = {
  items: WFMRows,
  order: ?WFMOrder,
};

type Props = {
  createTaskBodyFunc: (
    order: WFMOrder,
    items: WFMRows,
    customer: Customer
  ) => ServiceTaskBody,
  onSuccessMessageKey: string,
  onSuccess: (title: string, text: string) => void,
  fileUploadNotice?: string
};

export const ReclaimGeneric = ({
  onSuccess,
  onSuccessMessageKey,
  createTaskBodyFunc,
  fileUploadNotice
}: Props): React$Node => {
  const t = useTranslate();
  const [state, setState] = useState<State>({ items: {}, order: null });
  const [itemToAdd, setItemToAdd] = useState<WFMOrderItem | null>(null);
  const [processing, setProcessing] = useState(false);
  const _onSuccess = useCallback(
    () => onSuccess("Tack!", t(onSuccessMessageKey)),
    [t]
  );
  const submit = useSubmitCustomerServiceTask(
    state,
    createTaskBodyFunc,
    setProcessing,
    _onSuccess
  );

  const renderRow = (id: string) => {
    const item = state.items[id];

    return (
      <Row
        key={id}
        id={id}
        headerTitle={item.product.name}
        rows={state.items}
        setState={(x) => setState({ ...state, items: x })}
      >
        <div
            className={cn(sharedStyles.inputRow, sharedStyles.inputRow__tall)}
          >
        <Field label='Beskriv ditt ärende' rows={7} name={`items.${id}.description`} component={TextArea} />
        </div>

        <div className={sharedStyles.inputRow}>
          <Field
            name={`items.${id}.image.content`}
            render={({ errors, ...props }) => (
              <FileUpload
                {...props}
                id={`items.${id}.image.content`}
                className={sharedStyles.fileUpload}
                name={`items.${id}.image.content`}
                accept='application/pdf, image/*'
                onChange={x => {
                  const newItem: WFMRow = { ...item, image: { filename: x.name, content: x.content }};
                  setState({
                    ...state,
                    items: {
                      ...state.items,
                      [id]: newItem,
                    },
                  });
                }}
              />
            )}
          />
        </div>
        {fileUploadNotice && <p className={styles.fileUploadNotice}>{fileUploadNotice}</p>}
      </Row>
    );
  };

  const validation = rules(
    Object.keys(state.items).map((x) =>
      rules([
        nestedRule(
          "items",
          rules([nestedRule(x, rules([isRequired("description")]))])
        ),
      ])
    )
  );

  const errors = validation((state: any));

  const addRow = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (itemToAdd !== null) {
      setState({
        ...state,
        items: {
          ...state.items,
          [nextKeyId(state.items)]: {
            qty: itemToAdd.qty,
            product: itemToAdd.product,
          },
        },
      });

      setItemToAdd(null);
    }
  };

  return (
    <Form
      className={sharedStyles.type}
      value={(state: any)}
      errors={errors}
      onError={focusInvalidField}
      onChange={(x: any) => setState({ ...state, items: x.items })}
      onSubmit={submit}
    >
      {!state.order && (
        <div className="row--large">
          <OrderSelect
            order={state.order}
            setOrder={(x) => setState({ ...state, order: x })}
          />
        </div>
      )}

      {state.order && (
        <div className="row--large">
          <div className={cn(sharedStyles.rows, "row--large")}>
            {Object.keys(state.items).map((k) => renderRow(k))}
          </div>

          <div className="row--large">
            <h2>
              {Object.keys(state.items).length > 0
                ? "En till artikel?"
                : "Vilken artikel gäller det?"}
            </h2>

            <div
              className={cn(
                sharedStyles.inputRow,
                sharedStyles.inputRow__shrinkLastChild,
                "row--large"
              )}
            >
              <Dropdown
                name="itemToAdd"
                maxHeight={260}
                value={itemToAdd?.product.sku}
                label="Artikel"
                headerContent={(x) => {
                  if (!itemToAdd) {
                    return null;
                  }

                  return (
                    <span>
                      {itemToAdd.product.name}&nbsp;&nbsp;&nbsp;
                      <small>
                        ({itemToAdd.product.attributes.supplierSku})
                      </small>
                    </span>
                  );
                }}
                onChange={(x) => {
                  const item = state.order?.items.find(
                    (a) => a.product.sku === x.value
                  );

                  if (item) {
                    setItemToAdd(item);
                  }
                }}
              >
                {(state.order?.items || []).map((x) => (
                  <DropdownItem key={x.product.sku} value={x.product.sku}>
                    <span>
                      {x.product.name}&nbsp;&nbsp;&nbsp;
                      <small>({x.product.attributes.supplierSku})</small>
                    </span>
                  </DropdownItem>
                ))}
              </Dropdown>

              <Button variant="primary" onClick={addRow}>
                Lägg till
              </Button>
            </div>
          </div>
          <div className="row--large">
            {Object.keys(state.items).length > 0 && (
              <Button type="submit" loading={processing} variant="primary">
                Skicka
              </Button>
            )}
          </div>
        </div>
      )}
    </Form>
  );
};

/* @flow */

import React, { useRef } from "react";
import cn from "classnames";
import styles from "./styles.scss";
import { useTranslate } from "@out-of-home/use-translate";
import CloseIcon from "../../icons/close.svg";

type Props = {
  children?: React$Node,
  className?: string,
  errors: Array<string>,
  id: string,
  label: string,
  value: string,
  variant: string,
  required: boolean,
  dirty: boolean,
  submitted: boolean,
  name: string,
};

/**
 * Usage:
 *
 * <TextArea
 *     label="x"
 *     ...inputProps
 * />
 */
export const TextArea = ({
  className,
  submitted,
  dirty,
  children,
  errors,
  ...props
}: Props): React$Node => {
  const t = useTranslate();

  const modifiers = (props.variant ? props.variant.split(" ") : []).map(
    (x) => styles[`block__${x}`]
  );
  const id = props.id || props.name + "_field";

  return (
    <div
      className={cn(
        className,
        styles.block,
        ...modifiers,
        { [styles.block__hasChildren]: !!children },
        {
          [styles["block__active"]]: props.value
            ? props.value.length > 0
            : false,
        },
        { [styles["block__dirty"]]: dirty },
        { [styles["block__submitted"]]: submitted },
        { [styles["block__error"]]: (dirty || submitted) && errors.length > 0 }
      )}
    >
      <div className={styles.inputWrapper}>
        <textarea
          {...props}
          key={id}
          id={id}
          invalid={String(errors.length > 0)}
          className={cn(styles.input, {
            [styles["input__empty"]]: !(props.value && props.value.length > 0),
          })}
        >
          {props.value}
        </textarea>

        <label className={styles.label} htmlFor={id}>
          {props.label}

          {props.required && <span className={styles.required}>*</span>}
        </label>
      </div>

      {children !== undefined && (
        <div className={styles.children}>{children}</div>
      )}

      {(dirty || submitted) && errors && errors[0] && (
        <div className={styles.error}>
          <div className={styles.errorIcon}>
            <CloseIcon />
          </div>
          <p className={styles.errorMessage}>{errors[0]}</p>
        </div>
      )}
    </div>
  );
};

/* @flow */

import type {
  Quote,
  QuoteItem,
  QuoteAddress,
  SetQuoteAddressInput,
} from "../../../types/quote.flow";
import type { CustomerAddress } from "../../../types/customer.flow";
import type { QuoteResponse } from "../../../state/quote";

import React, { useState, useEffect, useContext } from "react";
import { createPortal } from "react-dom";
import styles from "./styles.scss";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import { useData, useSendMessage } from "crustate/react";
import { useTranslate } from "@out-of-home/use-translate";
import { addMessage, addMessageTranslated } from "../../../state/messages";
import { useFormat } from "../../../helpers/use-format";
import { useCustomer } from "../../../helpers/use-customer";
import { Button } from "../../Button";
import { Wrapper } from "../../Wrapper";
import { CartSummary } from "../../CartSummary";
import { PaymentMethods } from "../../PaymentMethods";
import { ShippingMethods } from "../../ShippingMethods";
import { RadioField } from "../../RadioField";
import { AddressFields, customerAddressToCustomerAddressInput } from "./AddressFields";
import { NewCustomer } from "./AddressFields/new-customer";
import { Well } from "../../Well";
import { useClient } from "../../../entrypoint/shared";
import BackIcon from "../../../icons/arrow.svg";
import PlusIcon from "../../../icons/plus.svg";
import {
  StripeContext,
  STRIPE_PAYMENT_METHOD_CODE,
  useInitStripeQuotePaymentMethod,
} from "../../../stripe";
import { useBrowser } from "../../../helpers/use-browser";
import {
  setPaymentMethod,
  setAddress,
  saveAddress,
  setQuoteEmail,
  QUOTE_PLACE_ORDER_RESPONSE,
  QUOTE_PLACE_ORDER_REQUEST,
} from "../../../state/quote";
import { syncCustomer } from "../../../state/customer";
import {
  PaymentMethodsData,
  QuoteData,
  useGetQuoteShippingAddress,
} from "../../../data";
import { addressCmp } from "../../Address";
import { customer as customerQuery, quote as quoteQuery, placeOrder } from "../../../queries";
import { StockPopup } from "../StockPopup";
import { itemStockOverflow } from "../StockPopup/helpers";

type Props = {
  quote: Quote,
};

type AddressListProps = {
  addresses: Array<CustomerAddress>,
  active: QuoteAddress | null,
  onSet: (QuoteAddress) => void,
  onEdit: (CustomerAddress) => void,
  processingAddress?: SetQuoteAddressInput,
};

const addressToText = (a: CustomerAddress) =>
  [a.company, a.street[0], a.postcode, a.city].join(", ");

const AddressList = ({ addresses, active, onSet, onEdit, processingAddress }: AddressListProps) => {
  const cmp = active && addressCmp(active);
  const cmpProcessing = processingAddress && addressCmp(processingAddress);
  return (
    <div
      className={cn(styles.addresses, {
        [styles.processing]: Boolean(processingAddress),
      })}
    >
      {addresses.length === 0 && (
        <p>Du har inga adresser sparade. Vänligen klicka på plus till höger för att välja en.</p>
      )}
      {addresses.map((x) => (
        <RadioField
          key={x.id}
          className={styles.address}
          checked={Boolean(cmp && cmp(x))}
          disabled={false}
          processing={cmpProcessing && cmpProcessing(x)}
          value={addressToText(x)}
          onChange={() => onSet((x: any))}
          onEdit={() => onEdit(x)}
        />
      ))}
    </div>
  );
};

export const CheckoutConfirm = ({ quote }: Props): React$Node => {
  const client = useClient();
  const quoteData = useData(QuoteData);
  const t = useTranslate();
  const { push } = useHistory();
  const [stockPopupOpen, setStockPopupOpen] = useState(false);
  const [stockPopupComplete, setStockPopupComplete] = useState(false);
  const [popupOpenedFromShippingMethod, setPopupOpenedFromShippingMethod] = useState(false);
  const [state, setState] = useState({});
  const [isShippingLoading, setIsShippingLoading] = useState(false);
  const [isPlacingOrder, setIsPlacingOrder] = useState(false);
  const { formatPrice } = useFormat();
  const sendMessage = useSendMessage();
  const { items } = quote;
  const qty = quote.items.reduce((a, c) => (a += c.qty), 0);
  const { customer, loggedIn } = useCustomer();
  const paymentMethods = useData(PaymentMethodsData).data;
  const { payment } = quote;
  const selectedPayment = payment?.code || null;
  const [editAddress, setEditAddress] = useState<CustomerAddress | null | "new">(null);
  const addresses = customer?.addresses || [];
  const { stripe, stripePaymentReq, payWithStripe } = useContext(StripeContext);
  const shippingAddress = useGetQuoteShippingAddress();
  const ssn =
    payment?.code === "Crossroads_Collector"
      ? payment.socialSecurityNumber
      : customer?.customerPno || "";

  const setShippingAddress = (v: any) => {
    const { country, id, isDefaultBilling, isDefaultShipping, ...address } = v;
    sendMessage(setAddress({ ...address, countryCode: country.code }));
  };

  const submit = async (e?: Event, skip: boolean = false) => {
    e && e.preventDefault();
    const stockOverflow = quote.items.some(itemStockOverflow);

    if (!skip && stockOverflow && !stockPopupComplete) {
      setStockPopupOpen(true);
      return;
    }

    if (!payment) {
      return null;
    }

    setIsPlacingOrder(true);

    if (payment.code === STRIPE_PAYMENT_METHOD_CODE) {
      try {
        await payWithStripe();
      } catch (e_) {
        sendMessage(addMessageTranslated(e_.message, "error"));
        setIsPlacingOrder(false);
        return;
      }
    }

    sendMessage({ tag: QUOTE_PLACE_ORDER_REQUEST }).then(() => {
      setIsPlacingOrder(false);
    });
  };

  const SubmitBtn = () => {
    const browser = useBrowser();

    if (!browser) {
      return null;
    }

    return createPortal(
      <Button
        variant="primary"
        size="small"
        onClick={submit}
        disabled={
          isPlacingOrder ||
          isShippingLoading ||
          quoteData.state !== "LOADED" ||
          quoteData.data.validationErrors.length > 0 ||
          editAddress !== null ||
          !loggedIn
        }
        loading={isPlacingOrder}
      >
        Lägg order
      </Button>,
      document.getElementById("cart-btn-portal"),
    );
  };

  useInitStripeQuotePaymentMethod((quote: any));

  return (
    <Wrapper>
      <h2>Bekräfta order</h2>

      {stockPopupOpen && (
        <div className={styles.popupBG}>
          <div className={styles.popup}>
            <StockPopup
              quote={quote}
              onClose={() => {
                setStockPopupOpen(false);
                setPopupOpenedFromShippingMethod(false);
              }}
              onConfirm={(e, placeOrder) => {
                setStockPopupComplete(true);
                setStockPopupOpen(false);

                if (placeOrder && !popupOpenedFromShippingMethod) {
                  submit(e, true);
                }

                setPopupOpenedFromShippingMethod(false);
              }}
            />
          </div>
        </div>
      )}

      <div className={styles.container}>
        <div className={styles.left}>
          <h3>Leverans</h3>

          <Well>
            {!loggedIn ? (
              <NewCustomer />
            ) : (
              <>
                {editAddress === null ? (
                  <div>
                    <h3 className={styles.stepHeading}>
                      <span>1. Leveransadress</span>
                      {loggedIn && (
                        <Button onClick={() => setEditAddress("new")}>
                          <PlusIcon />
                        </Button>
                      )}
                    </h3>
                    {loggedIn && (
                      <AddressList
                        addresses={addresses}
                        onEdit={setEditAddress}
                        active={shippingAddress}
                        onSet={setShippingAddress}
                        processingAddress={
                          quoteData.state === "SETTING_ADDRESS" ? quoteData.address : undefined
                        }
                      />
                    )}
                  </div>
                ) : (
                  <div>
                    {!loggedIn ? (
                      <h3 className={styles.stepHeading}>
                        <span>Adress</span>
                      </h3>
                    ) : (
                      <h3 className={styles.stepHeading}>
                        <span>{editAddress === "new" ? "Ny adress" : "Ändra adress"}</span>

                        <Button onClick={() => setEditAddress(null)}>
                          <BackIcon />
                        </Button>
                      </h3>
                    )}
                    <AddressFields
                      isProcessing={quoteData.state === "SETTING_ADDRESS"}
                      address={editAddress}
                      onSubmit={(v) => {
                        const { id, ...address } = v;

                        sendMessage(saveAddress(address, id)).finally(() => {
                          if (loggedIn) {
                            setEditAddress(null);
                          }
                        });
                      }}
                    />
                  </div>
                )}

                <ShippingMethods
                  className={styles.shippingMethods}
                  method={quote.shipping?.method.code}
                  loading={isShippingLoading}
                  setLoading={setIsShippingLoading}
                  setStockPopupOpen={() => {
                    setStockPopupOpen(true);
                    setPopupOpenedFromShippingMethod(true);
                  }}
                />
              </>
            )}
          </Well>

          <div className={cn(styles.shippingDescription, styles.shippingDescription__amtables)}>
            <p>
              {t("SHIPPING.INFO")}{" "}
              <a target="_blank" href={t("SHIPPING.INFO_URL")}>
                {t("SHIPPING.INFO_LINK")}
              </a>
              .
            </p>
          </div>
        </div>
        <div
          className={cn(styles.right, {
            [styles.disabled]: editAddress !== null || !loggedIn || !shippingAddress,
          })}
        >
          <h3>Betalning</h3>

          <form onSubmit={submit}>
            <PaymentMethods
              processing={isPlacingOrder || quoteData.state !== "LOADED"}
              methods={paymentMethods}
              value={selectedPayment}
              customerOrgNumber={ssn}
              onChange={(v, ssn) => {
                sendMessage(setPaymentMethod(v, ssn));
              }}
            />

            <SubmitBtn />
          </form>

          <h3>Sammanfattning</h3>

          <CartSummary quote={quote} />
        </div>
      </div>
    </Wrapper>
  );
};

// @flow

import type { Model } from "crustate";
import type { Response } from "./util";

import {
  parseParams,
  stringifyParams,
} from "@out-of-home/location-search-string";
import { updateData } from "crustate";

import type { TFacet, TFilterQuery, TInfoBlock } from "../types/filter.flow";
import type { ProductCardProduct } from "../types/product.flow";
import { locationToQuery } from "../helpers/filterHelpers";

type Data =
  | {
      state: "LOADING",
      facets: Array<TFacet>,
      infoBlock: ?TInfoBlock,
      query: TFilterQuery,
      product: ?ProductCardProduct,
    }
  | {
      state: "LOADED",
      facets: Array<TFacet>,
      infoBlock: ?TInfoBlock,
      query: TFilterQuery,
      product: ?ProductCardProduct,
    };

export type FacetsResponse = Response<
  typeof FACETS_LOAD_RESPONSE,
  {
    facets: Array<TFacet>,
    query: TFilterQuery,
    infoBlock: ?TInfoBlock,
    product: ?ProductCardProduct,
  }
>;
export type FacetsRequest = {
  tag: typeof FACETS_LOAD_REQUEST,
  location: Location,
};

export const FACETS_LOAD_RESPONSE: "facets/load/response" =
  "facets/load/response";
export const FACETS_LOAD_REQUEST: "facets/load/request" = "facets/load/request";

export const initialState: Data = {
  state: "LOADING",
  facets: [],
  query: {},
  infoBlock: null,
  product: null,
};

export const prepareFacets = (
  query: TFilterQuery,
  facets: Array<TFacet>
): Array<TFacet> =>
  facets
    .filter((x) => x.buckets.length)
    .map((facet) => {
      facet.containsActive = false;
      facet.buckets = facet.buckets.map((bucket) => {
        bucket.active = Boolean(
          query.filter &&
            query.filter[facet.key] &&
            query.filter[facet.key].indexOf(bucket.key) !== -1
        );
        if (bucket.active) {
          facet.containsActive = true;
        }
        return bucket;
      });
      return facet;
    });

export const load = (location: Location): FacetsRequest => ({
  tag: FACETS_LOAD_REQUEST,
  location,
});

export const FacetsModel: Model<Data, {}, FacetsRequest | FacetsResponse> = {
  id: "facets",
  init: () => {
    return updateData({ ...initialState, state: "LOADING" });
  },
  update: (state: Data, msg) => {
    switch (msg.tag) {
      case FACETS_LOAD_RESPONSE:
        if (msg.data) {
          return updateData({
            state: "LOADED",
            facets: prepareFacets(msg.data.query, msg.data.facets || []),
            infoBlock: msg.data.infoBlock,
            query: msg.data.query,
            product: msg.data.product,
          });
        }

        break;
      default:
    }
  },
};

/* @flow */

import type { Query, Client } from "@awardit/graphql-ast-client";
import type { QuoteAddressBilling } from ".";
import type { CreateStripePaymentIntent, PaymentMode } from "./context";

import React from "react";
import { useElements, CardNumberElement } from "@stripe/react-stripe-js";

type StripeType = {
  handleCardAction: (
    clientSecret: string
  ) => Promise<
    {| error: { message: string } |} | {| paymentIntent: { id: string } |}
  >,
  createPaymentMethod: ({
    type: string,
    card: any,
    billing_details: any,
  }) => Promise<
    | {| error: { message: string } |}
    | {|
        paymentIntent: { id: string },
        paymentMethod: { id: string },
      |}
  >,
};

export type StripePaymentReq = {
  off: (name: string) => void,
  on: (
    string,
    ({
      reject: () => void,
      error?: ?string,
      complete: (i: string) => void,
      paymentMethod: { id: string },
    }) => void
  ) => void,
  canMakePayment: () => Promise<?{ applePay: boolean }>,
  show: () => void,
};

const formatStripeBillingAddress = (
  a: ?QuoteAddressBilling,
  email: ?string
) => {
  if (!a) {
    return;
  }

  const address = {
    city: (a.city || "").trim(),
    country: a.country.code,
    line1: (a.street[0] || "").trim(),
    line2: "",
    /* eslint-disable camelcase */
    postal_code: (a.postcode || "").replace(/\s/, ""),
    /* eslint-enable camelcase */
  };

  if (a.street[1]) {
    const line2 = a.street[1].trim();

    if (line2.length > 0) {
      address.line2 = line2;
    }
  }

  return {
    email: email || "",
    name: `${a.firstname} ${a.lastname}`,
    phone: a.telephone,
    address,
  };
};

export const paymentRequestButtonShow = (
  stripePaymentReq: StripePaymentReq
) => {
  stripePaymentReq.show();

  return new Promise<{ paymentMethod: { id: string } }>((resolve, reject) => {
    if (!stripePaymentReq) {
      return;
    }

    // Remove event listeners
    stripePaymentReq.off("cancel");
    stripePaymentReq.off("paymentmethod");

    stripePaymentReq.on("cancel", () => {
      reject();
    });

    stripePaymentReq.on("paymentmethod", (ev) => {
      if (ev.error) {
        ev.complete("fail");
        reject(new Error(ev.error));
      }

      ev.complete("success");
      resolve(ev);
    });
  });
};

export const useCompletePayment = (
  email: ?string,
  billingAddress: ?QuoteAddressBilling
) => {
  const elements = useElements();

  return ({
    stripe,
    client,
    paymentMode,
    paymentMethodID,
    createStripePaymentIntentQuery,
    confirmStripePaymentIntentQuery,
  }: {
    stripe: StripeType,
    client: Client<{}>,
    paymentMode: PaymentMode,
    paymentMethodID?: string,
    confirmStripePaymentIntentQuery: Query<{ intent: string }, any>,
    createStripePaymentIntentQuery: Query<
      { paymentMethod: string },
      { createStripePaymentIntent: CreateStripePaymentIntent }
    >,
  }): Promise<mixed> => {
    return new Promise((resolve, reject) => {
      if (!stripe) {
        reject(new Error("Stripe.js hasn't loaded yet."));
      }

      const handleResponse = (response: CreateStripePaymentIntent) => {
        console.log(response.result);
        switch (response.result) {
          case "success":
            return resolve(response.result);
          case "errorMessage":
            return reject(new Error(response.error));
          case "error":
            return reject(new Error(response.error));
          case "errorInvalidIntentStatus":
            return reject(new Error(response.error));
          case "recreateIntent":
            return createPayment();
          case "requiresAction":
            if (stripe) {
              stripe
                .handleCardAction(response.clientSecret)
                .then((stripeResponse) => {
                  if (stripeResponse.error) {
                    // Translate stripeResponse.error.code?
                    return reject(new Error(stripeResponse.error.message));
                  }

                  client(confirmStripePaymentIntentQuery, {
                    intent: stripeResponse.paymentIntent.id,
                  }).then(
                    ({ confirmStripePaymentIntent }) =>
                      confirmStripePaymentIntent &&
                      handleResponse(confirmStripePaymentIntent)
                  );
                });
            }

            break;
          default:
            break;
        }
      };

      const handlePaymentMethodResponse = (payload) => {
        if (payload.error) {
          return reject(new Error(payload.error.message));
        }

        const paymentId = payload.paymentMethod.id;

        if (typeof paymentId === "undefined") {
          return reject(new Error("NO_PAYMENT_INTENT_ID"));
        }

        client(createStripePaymentIntentQuery, {
          paymentMethod: paymentId,
        }).then(({ createStripePaymentIntent }) => {
          if (createStripePaymentIntent.result !== "success") {
            if (createStripePaymentIntent.error) {
              return reject(new Error(createStripePaymentIntent.error));
            }
          }

          handleResponse(createStripePaymentIntent);
        });
      };

      const createPayment = () => {
        if (stripe) {
          if (paymentMode === "CARD") {
            const cardNumberElement = elements.getElement(CardNumberElement);

            if (!cardNumberElement) {
              throw new Error("Missing <CardNumberElement />");
            }

            stripe
              .createPaymentMethod({
                type: "card",
                card: cardNumberElement,
                /* eslint-disable camelcase */
                billing_details: formatStripeBillingAddress(
                  billingAddress,
                  email
                ),
                /* eslint-enable camelcase */
              })
              .then((payload) => handlePaymentMethodResponse(payload));
          } else {
            handlePaymentMethodResponse({
              paymentMethod: { id: paymentMethodID },
            });
          }
        }
      };

      createPayment();
    });
  };
};

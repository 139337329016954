/** @flow */
import type { Link, Section, TabContent } from "../tabs";
import type { Tab } from "../../../types/header.flow";

import React from "react";
import cn from "classnames";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import styles from "./styles.scss";
import { useLocation } from "react-router-dom";
import { Wrapper } from "../../Wrapper";
import { useClickOutside, usePrevious } from "../../../helpers";
import { Button } from "../../Button";
import { useTranslate } from "@out-of-home/use-translate";
import { getContent, getMainSections } from "../tabs";
import { useOverdueInvoices } from "../../../helpers/use-overdue-invoices";
import { UIContext } from "../../../context/ui";
import { StoreInfoContext } from "../../../entrypoint/shared";

type Props = {
  close: void => void,
  loggedIn: boolean,
};

type ItemProps = {
  children: React$Node,
};

const NavItem = ({ children }: ItemProps) => {
  return (
    <div className={styles.navItem}>
      <Wrapper className={styles.wrapper}>{children}</Wrapper>
    </div>
  );
};

const Submenu = ({ section, open }: { section: Section, open: boolean }) => {
  return (
    <div className={cn(styles.submenu, open && styles.submenuOpen)}>
      <div>
        {section.categories.map(x => (
          <NavItem key={x.target}>
            <Button to={x.target} className={styles.submenuItem}>
              <span>{x.text}</span>
              {x.count > 0 &&
                <span className={styles.count}>{x.count}</span>
              }
            </Button>
          </NavItem>
        ))}
      </div>
    </div>
  );
};

const NavSection = ({
  tab,
  isOpen,
  section,
  toggleOpen,
}: {
  tab: Tab,
  isOpen: boolean,
  section: Section,
  toggleOpen: Event => void,
}) => {
  const t = useTranslate();

  return (
    <div className={styles.navSection}>
      <NavItem>
        <Button onClick={toggleOpen}>
          <span>{t(`NAV.${tab.toUpperCase()}`)}</span>
          {section.count > 0 &&
            <span className={styles.count}>{section.count}</span>
          }
        </Button>
      </NavItem>
      <Submenu section={section} open={isOpen} />
    </div>
  );
};

export const HamburgerMenu = ({ close, loggedIn }: Props): React$Node => {
  const t = useTranslate();
  const location = useLocation();
  const element = useRef();
  const [openTab, setOpenTab] = useState<Tab | void>();
  const prevLocation = usePrevious(location);
  const storeInfo = useContext(StoreInfoContext);
  const mainSections = useMemo(() => getMainSections(storeInfo), [storeInfo]);
  const { gotOverdueInvoice } = useOverdueInvoices();
  const { setLoginModalOpen } = useContext(UIContext);

  const toggleTab = useCallback((tab: Tab) => {
    return (e: Event) => {
      e.stopPropagation();
      setOpenTab(old => (!old || old !== tab ? tab : undefined));
    };
  }, []);

  const openLoginModal = useCallback(() => {
    close();
    setLoginModalOpen(true);
  }, []);

  useClickOutside(element, () => {
    close();
  });

  return (
    <section className={styles.hamburgerMenu} ref={element}>
      <nav className={cn(styles.nav, "no-underline")}>
        <NavSection
          tab='drinks'
          isOpen={openTab === "drinks"}
          section={mainSections["drinks"]}
          toggleOpen={toggleTab("drinks")}
        />
        <NavSection
          tab='eatables'
          isOpen={openTab === "eatables"}
          section={mainSections["eatables"]}
          toggleOpen={toggleTab("eatables")}
        />
        <NavSection
          tab='snacks'
          isOpen={openTab === "snacks"}
          section={mainSections["snacks"]}
          toggleOpen={toggleTab("snacks")}
        />
        <NavSection
          tab='accessories'
          isOpen={openTab === "accessories"}
          section={mainSections["accessories"]}
          toggleOpen={toggleTab("accessories")}
        />

        {!loggedIn && (
          <>
            <NavSection
              tab='brands'
              isOpen={openTab === "brands"}
              section={mainSections["brands"]}
              toggleOpen={toggleTab("brands")}
            />
            <NavSection
              tab='customer_type'
              isOpen={openTab === "customer_type"}
              section={mainSections["customer_type"]}
              toggleOpen={toggleTab("customer_type")}
            />
          </>
        )}

        <NavItem>
          <Button to={"/" + t("ROUTES.ABOUT_US")}>{t("NAV.ABOUT_US")}</Button>
        </NavItem>

        <NavItem>
          <Button to={"/" + t("ROUTES.CUSTOMER_SERVICE")}>{t("NAV.CUSTOMER_SERVICE")}</Button>
        </NavItem>

        <NavItem>
          <Button to={"/" + t("ROUTES.NEWS")}>{t("NAV.NEWS")}</Button>
        </NavItem>

        {!loggedIn && (
          <>
            <NavItem>
              <Button onClick={() => openLoginModal()}>{t("NAV.LOGIN")}</Button>
            </NavItem>

            <NavItem>
              <Button to={"/" + t("ROUTES.REGISTER")}>{t("NAV.REGISTER")}</Button>
            </NavItem>
          </>
        )}

        {loggedIn && (
          <>
            <NavItem>
              <Button to="/p/?certified=Kampanjer">{t("NAV.MY_DISCOUNTS")}</Button>
            </NavItem>

            <NavItem>
              <Button to="/inkopslista">{t("NAV.WISHLIST")}</Button>
            </NavItem>

            <NavItem>
              <Button to={"/" + t("ROUTES.ORDER_HISTORY")}>
                {t("NAV.ORDER_HISTORY")}
                {gotOverdueInvoice && (
                  <>
                    &nbsp;
                    <div className={styles.overdueInvoiceWarning} />
                  </>
                )}
              </Button>
            </NavItem>

            <NavItem>
              <Button to={"/" + t("ROUTES.CUSTOMER_SERVICE")}>{t("NAV.CUSTOMER_SERVICE")}</Button>
            </NavItem>
          </>
        )}
      </nav>
    </section>
  );
};

/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { Translations } from "@out-of-home/use-translate";
import type { Fetch } from "@out-of-home/fetch-utils";

//import bindHomeEffects from "./home";
import bindQuoteEffects from "./quote";
import bindQuoteItemsEffects from "./quote-items";
import bindRouteEffects from "./route";
import bindTestimonialsEffects from "./testimonials";
import bindBlogEffects from "./blog";
import bindBlogListEffects from "./blogList";
import bindCustomerEffects from "./customer";
import bindFacetsEffects from "./facets";
import bindFilterEffects from "./filter";
import bindBrandsEffects from "./brands";
import bindFrontPageRecommendedEffects from "./frontpage-recommended";
import bindAlcoholLicensesEffects from "./alcohol-licenses";
import bindPaymentMethodsEffects from "./payment-methods";
import bindMyProductsEffects from "./my-products";
import bindWishlistToggleEffects from "./wishlist-toggle";
import bindOrderEffects from "./order";
import bindWishlistEffects from "./wishlist";
import bindDeliveryScheduleEffects from "./delivery-schedule";
import bindOverdueInvoicesEffects from "./overdue-invoices";
//import bindBrandEffects from "./brand";
//import bindPopularEffects from "./popular";
//import bindTermsEffects from "./terms";
//import bindSearchEffects from "./search";
//import bindAllProductsEffects from "./all-products";

type History = {
  push: (path: string) => void,
};

export const registerClient = (
  storage: Storage,
  client: Client<{}>,
  restClient: Fetch,
  history: History,
  translations: {}
) => {
  bindRouteEffects(storage, client);
  bindCustomerEffects(storage, client, restClient, history, translations);
  bindTestimonialsEffects(storage, restClient);
  bindBlogEffects(storage, restClient);
  bindBlogListEffects(storage, restClient);
  bindFacetsEffects(storage, restClient, translations);
  bindFilterEffects(storage, restClient, translations);
  bindBrandsEffects(storage, restClient);
  bindFrontPageRecommendedEffects(storage, restClient);
  bindAlcoholLicensesEffects(storage, restClient, history);
  //bindHomeEffects(storage, client);
  bindQuoteEffects(storage, client, restClient, history);
  bindQuoteItemsEffects(storage, client);
  bindPaymentMethodsEffects(storage, client);
  bindMyProductsEffects(storage, restClient);
  bindWishlistToggleEffects(storage, client);
  bindOrderEffects(storage, client);
  bindWishlistEffects(storage, restClient);
  //bindBrandEffects(storage, client);
  //bindPopularEffects(storage, client);
  //bindTermsEffects(storage, client);
  //bindSearchEffects(storage, client, history);
  //bindAllProductsEffects(storage, client);
  bindDeliveryScheduleEffects(storage, client);
  bindOverdueInvoicesEffects(storage, client);
};

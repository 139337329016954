/* @flow */

import type { ProductCardProduct } from "../types/product.flow";
import type { TFilterQuery } from "../types/filter.flow";

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";
import { deepEquals } from "@out-of-home/diskho";

type State = {
  data: Object,
  showContracted: boolean,
  query?: TFilterQuery,
};

type Data =
  | {| state: "LOADING", query: ?TFilterQuery |}
  | {| state: "LOADED", query: ?TFilterQuery, data: State |};

export type MyProductsResponse = Response<
  typeof MY_PRODUCTS_LOAD_RESPONSE,
  State
>;

export type MyProductsRequest =
  | {
      tag: typeof MY_PRODUCTS_LOAD_REQUEST,
      query: TFilterQuery,
    }
  | {
      tag: typeof MY_PRODUCTS_LOAD_TARGETED_REQUEST,
      name: string,
    };

export const MY_PRODUCTS_LOAD_RESPONSE: "my_products/load/response" =
  "my_products/load/response";
export const MY_PRODUCTS_LOAD_REQUEST: "my_products/load/request" =
  "my_products/load/request";

export const MY_PRODUCTS_LOAD_TARGETED_RESPONSE: "my_products/load_targeted/response" =
  "my_products/load_targeted/response";
export const MY_PRODUCTS_LOAD_TARGETED_REQUEST: "my_products/load_targeted/request" =
  "my_products/load_targeted/request";

export const load = (query: TFilterQuery): MyProductsRequest => ({
  tag: MY_PRODUCTS_LOAD_REQUEST,
  query,
});

export const loadTargetedProducts = (name: string): MyProductsRequest => ({
  tag: MY_PRODUCTS_LOAD_TARGETED_REQUEST,
  name,
});

export const MyProductsModel: Model<
  Data,
  { query: TFilterQuery },
  MyProductsRequest | MyProductsResponse
> = {
  id: "my-products",
  init: ({ query }: { query: TFilterQuery }) =>
    updateData(
      { state: "LOADING", query },
      { tag: MY_PRODUCTS_LOAD_REQUEST, query }
    ),
  update: (state: Data, msg) => {
    switch (msg.tag) {
      case MY_PRODUCTS_LOAD_REQUEST:
        if (deepEquals(state.query, msg.query)) {
          return;
        }

        return updateData({ state: "LOADING", query: msg.query }, msg);

      case MY_PRODUCTS_LOAD_RESPONSE:
        if (msg.data && state.state === "LOADING") {
          return updateData({
            state: "LOADED",
            query: state.query,
            data: msg.data,
          });
        }

        break;
      default:
    }
  },
};

/* @flow */

import React from "react";
import styles from "./styles.scss";
import cn from "classnames";
import CloseIcon from "../../icons/close.svg";
import { MultiSelectPills } from "./multiple";

type Pill<T> = {
  +id: T | null,
  title: any,
  className?: string,
};

type Props<T> = {
  className?: string,
  items: Array<Pill<T>>,
  active: T,
  onClick: (T | null) => void,
};

export const Pills = <T>({
  className,
  onClick,
  items,
  active,
}: Props<T>): React$Node => {
  if (items.length === 0) {
    return null;
  }

  return (
    <div
      className={cn(styles.block, className, {
        [styles.active]: active !== null,
      })}
    >
      {items
        .filter((x) => x)
        .map((x) => ({ ...x, active: active === x.id }))
        .filter((x) =>
          active !== null && active !== undefined ? x.active : true
        )
        .map((x) => (
          <button
            key={x.title}
            className={cn(styles.segment, x.className, {
              [styles.active]: x.active,
            })}
            onClick={(e) => onClick(x.active ? null : x.id)}
          >
            {x.title}
            {x.active && (
              <svg className={styles.icon} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="14" cy="14" r="14" fill="#FFD966"/>
                <rect x="8.88885" y="8" width="15.7134" height="1.25708" transform="rotate(45 8.88885 8)" fill="currentColor"/>
                <rect x="8" y="19.1113" width="15.7134" height="1.25708" transform="rotate(-45 8 19.1113)" fill="currentColor"/>
              </svg>
            )}
          </button>
        ))}
    </div>
  );
};

export { MultiSelectPills };

/* @flow */

import type { Quote } from "../../../types/quote.flow";
import type { ProductCardProduct } from "../../../types/product.flow";

import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import cn from "classnames";
import { ProductList } from "../../ProductList";
import { Wrapper } from "../../Wrapper";
import { Button } from "../../Button";
import { Spinner } from "../../Spinner";
import { ButtonGroup } from "../../ButtonGroup";
import { TimeContext } from "../../../context/time";
import { useTranslate } from "@out-of-home/use-translate";
import styles from "./styles.scss";
import { StoreInfoContext, useRestClient } from "../../../entrypoint/shared";
import { useCustomer } from "../../../helpers/use-customer";
import { restProductToGQLProduct, shuffle } from "../../../helpers";
import RefreshIcon from "../../../icons/refresh.svg";

type Props = {
  quote: Quote,
};

const API_LIMIT = 30;
const LIST_LIMIT = 10;

type Filter = "oktoberfest" | "top10" | "previous-purchases" | "campaigns" | "fresh" | "certified";

const url = (filter: Filter, type: string): string => {
  switch (filter) {
    case "oktoberfest":
      return `/api/search?aggregate=true&certified=Kampanjer&limit=200&query=LAGERRENSNING`;
      // text_campaign = LAGERRENSNING
      // return `/api/search?aggregate=true&certified=Kampanjer&limit=20&query=LAGERRENSNING*`;
    case "top10":
      // @TODO: get 100 and shuffle and limit on in stock and limit 10
      return `/api/products_customertype/${encodeURIComponent(type)}?limit=${API_LIMIT}`;
    case "previous-purchases":
      // @TODO: filter in cart, shuffle and limit on in stock and 10
      return `/api/shoppinglist?skipRecommended=true&limit=${API_LIMIT}`;
    case "campaigns":
      // @TODO: get 100 and shuffle and limit on in stock and 10
      return `/api/products_customertype/${encodeURIComponent(type)}?certified=Kampanjer&limit=${API_LIMIT}`;

    case "fresh":
      // @TODO: get 100 and shuffle and limit on in stock and 10
      return `/api/products_customertype/${encodeURIComponent(type)}?temperature=Färskt&limit=${API_LIMIT}`;
    case "certified":
      // @TODO: get 100 and shuffle and limit on in stock and 10
      return `/api/products_customertype/${encodeURIComponent(type)}?certified=Kort%20datum&limit=${API_LIMIT}`;
    default:
      // This won't really be possible
      return "";
  }
};

const overflowingAt = (max, arr) => {
  let a = 0;

  if (arr.length === 0) {
    return 0;
  }

  for (let i = 0; i < arr.length; i++) {
    a += arr[i];
    if (a > max) {
      return i + 1;
    }
  }

  return arr.length;
};

type Option = {|
  value: Filter,
  label: string,
  heading: string,
  loggedIn: boolean,
|};

// 1: Restaurang
// 2: Café
// 4: Hotell & konferens
// 6: Gym/Träning/Förening
// 7: Dagligvaruhandel
// 8: Kontor/Arbetsplats
// 9: Produktion
// 10: Offentlig Verksamhet
// 11: Servicehandel
// 12: Detaljhandel
// 13: Grossist

// "oktoberfest" - Oktoberfest
// "certified" - Out of Date
// "fresh" - Färskt
// "campaigns" - Kampanj
// "top10" - Top 10
// "previous-purchases" - Tidigare handlat

const getFilterOrder = (id: number | null): Filter[] => {
  switch (id) {
    case 1:
      return [
        "certified",
        "fresh",
        "campaigns",
        "top10",
        "previous-purchases"
      ];

    case 2:
    case 4:
    case 7:
      return [
        "fresh",
        "certified",
        "campaigns",
        "top10",
        "previous-purchases"
      ];

    case 6:
    case 8:
    case 9:
    case 11:
    case 12:
      return [
        "top10",
        "campaigns",
        "certified",
        "fresh",
        "previous-purchases"
      ];

    case 13:
      return [
        "previous-purchases",
        "top10",
        "campaigns",
        "certified",
        "fresh",
      ];

    default:
      return [
        "certified",
        "fresh",
        "top10",
        "campaigns",
        "previous-purchases",
      ]
  }
}

const useGetOptions = (ooh3Type: string | null): Array<Option> => {
  const { now } = useContext(TimeContext);
  const { customerTypes } = useContext(StoreInfoContext);

  const options = useMemo(() => {
    const customerTypeId = customerTypes.find(x => x.title === ooh3Type)?.id || null;
    const filterOrder = getFilterOrder(customerTypeId);
    // const oktoberFestEnabled = now.getMonth() === 9 && now.getDate() < 26; // If before Oct and beofore the 26th

    const available: { [key: Filter]: Option } = {
      "certified": {
        value: "certified",
        label: "Out of Date",
        heading: "Rädda varor med kort datum",
        loggedIn: false,
      },
      "fresh": {
        value: "fresh",
        label: "Färskt",
        heading: "Färskvaror i lager",
        loggedIn: false,
      },
      "top10": {
        value: "top10",
        label: "Top 10",
        heading: "Topplista för liknande kunder",
        loggedIn: true,
      },
      "campaigns": {
        value: "campaigns",
        label: "Kampanjer",
        heading: "Aktiva kampanjprodukter",
        loggedIn: false,
      },
      "previous-purchases": {
        value: "previous-purchases",
        label: "Tidigare handlat",
        heading: "Varor du brukar handla",
        loggedIn: true,
      }
    };

    // const options: Array<Option> = oktoberFestEnabled ? [{
    //   value: "oktoberfest",
    //   label: "Oktoberfest",
    //   heading: "Lagerrensning - allt skall ut",
    //   loggedIn: false,
    // }] : [];

    const options: Array<Option> = [];

    filterOrder.forEach(filter => {
      options.push(available[filter]);
    });

    return options;
  }, [now, ooh3Type]);


  return options;
}


export const CheckoutSuggestions = ({ quote }: Props): React$Node => {
  const t = useTranslate();
  const client = useRestClient();
  const { customer, loggedIn } = useCustomer();
  const hasPrevOrders = customer ? customer.orders.items.length > 0 : false;
  const ooh3Type = customer?.ooh3Type || null;
  const options = useGetOptions(ooh3Type)
    .filter((x) => !loggedIn ? !x.loggedIn : true)
    .filter((x) => (!hasPrevOrders ? x.value !== "previous-purchases" : true));

  const [filter, setFilter] = useState<Filter>(options[0].value);
  const [products, setProducts] = useState<Array<ProductCardProduct | null>>([]);
  const fullProductsList = useRef<Array<ProductCardProduct | null> | typeof undefined>();
  const [processing, setProcessing] = useState(false);
  const type = ooh3Type !== "0" && ooh3Type ? ooh3Type : "restaurang";

  const refresh = useCallback((e?: Event) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (fullProductsList.current) {
      setProducts(
        shuffle(fullProductsList.current)
          .filter((_, i) => i < LIST_LIMIT)
      );
    }
  }, []);

  const isInStock = useCallback((x: ProductCardProduct) => x.oohStock && x.oohStock.qty > 0,[]);
  const isInQuote = useMemo(() => {
   const skus = quote.items.map(x => x.product.sku);
   return (x: ProductCardProduct): boolean => skus.includes(x.sku);
  }, [quote]);

  useEffect(() => {
    setProcessing(true);

    const data = client(url(filter, type))
      .then((x) => x.json())
      .then((x) => {
        fullProductsList.current = x.products
          .map(restProductToGQLProduct)
          .filter((x) => x && isInStock(x) && !isInQuote(x));

        refresh();
      })
      .finally(() => {
        setProcessing(false);
      });
  }, [type, filter]);

  return (
    <div>
      <Wrapper>
        <div className={styles.header}>
          <div className={styles.left}>
            <h2>
              {options.find((x) => x.value === filter)?.heading}
            </h2>
            <div className={styles.refresh}>
              <Button onClick={refresh} className={styles.refresh} variant="primary" size="small">
                <RefreshIcon/>
              </Button>
            </div>
          </div>

          <div className={styles.buttonGroup}>
            <ButtonGroup
              options={options.map((x) => ({ value: x.value, label: x.label }))}
              value={filter}
              onChange={setFilter}
              />
          </div>
        </div>

        {processing ? (
          <Spinner className={styles.spinner} variant="large inverted" />
        ) : (
          <ProductList
            className={styles.products}
            displayMode="GRID"
            listName={"checkout_suggestions_" + filter}
            products={products}
          />
        )}
      </Wrapper>
    </div>
  );
};

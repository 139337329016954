/* @flow */

import React, { useState, useEffect, useRef } from "react";
import InfiniteLooper from "../../InfiniteLooper";
import { Link } from "react-router-dom";
import styles from "./styles.scss";

const NUM_ROWS = 4;

type Item = {
  icon: string,
  iconSmall: string,
  title: string,
};

type Props = {
  browser: boolean,
  speed: number,
  items: $ReadOnlyArray<Item>,
};

function chunk(array, chunkSize) {
  let chunks = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }

  return chunks;
}

export function AnimatedBrands({ browser, items, speed = 5 }: Props): React$Node {
  const chunkSize = Math.ceil(items.length / NUM_ROWS);
  const rows = chunk(items, chunkSize);
  const caculatedSpeed = items.length / (speed / 10);

  return (
    <div style={{ "--num-rows": NUM_ROWS }} className={styles.animatedBrands}>
      {browser &&
        rows.map((row, i) => (
          <div key={`row-${i}`} className={styles.row}>
            <InfiniteLooper speed={caculatedSpeed} direction={i % 2 === 0 ? "right" : "left"}>
              {row.map((x) => (
                <Link key={x.title} className={styles.item} to={`/p/_${x.title}`}>
                  <img width="64" height="64" src={x.iconSmall} loading="lazy" alt={x.title} />
                </Link>
              ))}
            </InfiniteLooper>
          </div>
        ))}
    </div>
  );
}

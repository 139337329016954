/* @flow */

import type { FrontPageBlock as FrontPageBlockType } from "../../types/my-front-page.flow";

import React, { useState, useContext, useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams, useLocation } from "react-router-dom";
import cn from "classnames";
import { useData } from "crustate/react";
import { useTranslate } from "@out-of-home/use-translate";
import { useCustomer } from "../../helpers/use-customer";
import { useRestClient } from "../../entrypoint/shared";
import { Wrapper } from "../Wrapper";
import { Button } from "../Button";
import { FrontPageBlock } from "../FrontPageBlock";
import { Spinner } from "../Spinner";
import { HeadingRow } from "../HeadingRow";
import { ProductList } from "../ProductList";
import { OffCanvasMenu } from "../OffCanvasMenu";
import { locationToQuery } from "../../helpers/filterHelpers";
import breakpoints from "../../helpers/breakpoints";
import StarIcon from "../../icons/star.svg";
import styles from "../FilterView/styles.scss";
import { restProductToGQLProduct } from "../../helpers";
import { UIContext } from "../../context/ui";
import { DisplayModeSwitcher } from "../DisplayModeSwitcher";
import { MyProductsData } from "../../data";
import { Menu } from "./Menu";
import { getFilterMeta, metaToHelmetProps } from "../../helpers/get-meta";

const breakpoint = parseInt(styles.breakpointSmall);

export const MyProductsView = (): React$Node => {
  const t = useTranslate();
  const { filterModalOpen, setFilterModalOpen, browserWidth } =
    useContext(UIContext);
  const useCompactView = browserWidth > 0 && browserWidth < breakpoint;
  const client = useRestClient();
  const [loadingTargetedList, setLoadingTargetedList] = useState(false);
  const [showContracted, setShowContracted] = useState(false);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [rows, setRows] = useState([]);
  const [blocksFetching, setBlocksFetching] = useState(true);
  const { customer } = useCustomer();
  const data = useData(MyProductsData);
  const [targetedProducts, setTargetedProducts] = useState({});
  const params = useParams();
  const targetedList = params.id;
  const [hasOrdered, setHasOrdered] = useState(false);
  const customerType = customer?.ooh3Type || "";
  const location = useLocation();
  const canonicalUrl = location.pathname;
  const targetedProductLists = customer?.targetedProductlists || [];

  useEffect(() => {
    client("/api/orders")
      .then((x) => x.json())
      .then((x) => {
        setHasOrdered(x.length > 0);
      });

    // @TODO: this won't work on other languages
    client(
      `/api/shoppinglist?aggregate=true&limit=0&shoppinglist=Avtalade%20produkter&skipRecommended=true`
    )
      .then((x) => x.json())
      .then(
        (x) => x.facets.find((x) => x.key === "shoppinglist")?.buckets || []
      )
      .then((x) => {
        setShowContracted(
          x.find((x) => x.key === "Avtalade produkter") !== undefined
        );
      });
  }, []);

  useEffect(() => {
    if (targetedList) {
      setLoadingTargetedList(true);
      setTargetedProducts({});
      client(`/api/TargetedProducts/byName?name=${encodeURI(targetedList)}`)
        .then((x) => x.json())
        .then((x) => {
          const categories = {}; //x.products.map(x => x.attributes.main_category[0]);
          for (let i = 0; i < x.products.length; i++) {
            const k = x.products[i].attributes.main_category[0];
            if (categories[k]) {
              categories[k].push(x.products[i]);
            } else {
              categories[k] = [x.products[i]];
            }
          }

          setTargetedProducts(categories);
          setLoadingTargetedList(false);
        });
    }
  }, [targetedList]);

  if (!customer) {
    return null;
  }

  const description = (() => {
    if (data.query?.filter.shoppinglist) {
      switch (data.query.filter.shoppinglist[0].toLowerCase()) {
        case "favoriter":
          return t("WISHLIST.FAVORITES_DESCRIPTION");
        case "avtalade produkter":
          return t("WISHLIST.CONTRACTED_DESCRIPTION");
        case "tidigare köpta varor":
          return t("WISHLIST.PREVIOUSLY_BOUGHT_DESCRIPTION");
        case "produktförslag":
          return t("WISHLIST.RECOMMENDED_DESCRIPTION", { customerType });
      }
    }
    return hasOrdered
      ? t("WISHLIST.DEFAULT_DESCRIPTION", { customerType })
      : t("WISHLIST.DESCRIPTION_NO_PREVIOUS_ORDERS", { customerType });
  })();

  const title = () => {
    // const query = locationToQuery(location, this.props.routes);
    const query = data.query;
    let title = [];

    title = title.concat(Object.values(query.filter));

    if (title.length) {
      return title.join(", ");
    }

    return hasOrdered
      ? t("WISHLIST.TITLE")
      : t("WISHLIST.TITLE_NO_PREVIOUS_ORDERS");
  };

  const pageTitle = title();
  const meta = getFilterMeta(
    pageTitle,
    "",
    data.query,
    null,
    t("FILTER.DESCRIPTION"),
    canonicalUrl
  );

  const hideRecommended = Boolean(
    data.query?.filter.shoppinglist &&
      ["favoriter", "avtalade produkter"].includes(
        data.query.filter.shoppinglist[0].toLowerCase()
      )
  );

  let list = data.state === "LOADED" ? data.data : [];

  if (hideRecommended) {
    // remove empty categories
    list = list
      .map((c) => {
        return c.products.length ? c : null;
      })
      .filter((x) => x);
  }

  const facetsLoading = data.state !== "LOADED";

  return (
    <div>
      {useCompactView && (
        <OffCanvasMenu
          setOpen={setFilterModalOpen}
          modal="FILTER_OCM"
          open={filterModalOpen}
        >
          <Menu
            layout="SMALL"
            items={targetedProductLists}
            showContracted={showContracted}
            loading={facetsLoading}
            onClose={() => setFilterModalOpen(false)}
          />
        </OffCanvasMenu>
      )}
      <Wrapper className={styles.wrapper}>
        <div className={styles.block} style={{ flexDirection: "row" }}>
          <div className={styles.sidebar}>
            {!useCompactView && (
              <Menu
                items={targetedProductLists}
                showContracted={showContracted}
                loading={facetsLoading}
              />
            )}
          </div>

          <div
            className={cn(styles.main, {
              [styles.main__loading]: targetedList ? false : facetsLoading,
            })}
          >
            {targetedList ? (
              <header className={styles.titleHeader}>
                <h1 className={styles.title}>{targetedList}</h1>
                <br />
              </header>
            ) : (
              <header className={styles.titleHeader}>
                <h1 className={styles.title}>
                  {pageTitle[0].toUpperCase() + pageTitle.slice(1)}
                </h1>
              </header>
            )}

            {!targetedList && description && <p>{description}</p>}

            {(targetedList ? loadingTargetedList : data.state !== "LOADED") && (
              <div className={styles.spinner}>
                <Spinner variant="large inverted" />
              </div>
            )}

            {targetedList ? (
              <div>
                {Object.keys(targetedProducts).map((category) => (
                  <div key={category} className="row--large">
                    <HeadingRow title={category} url={`/p/${category}`} />
                    <ProductList
                      displayMode="LIST"
                      products={targetedProducts[category].map(
                        restProductToGQLProduct
                      )}
                      listName="my_products"
                    />
                  </div>
                ))}
              </div>
            ) : (
              <>
                {list.length > 0 &&
                  list.map((category) => (
                    <div key={category.key} className="row--large">
                      <HeadingRow
                        title={category.key}
                        url={`/p/${category.key}`}
                      />

                      <ProductList
                        displayMode="LIST"
                        products={category.products.map(
                          restProductToGQLProduct
                        )}
                        recommendedProducts={
                          hideRecommended
                            ? []
                            : category.recommended.map(restProductToGQLProduct)
                        }
                        listName="my_products"
                      />
                    </div>
                  ))}
              </>
            )}
          </div>
        </div>
      </Wrapper>

      <Helmet title={meta.title}>
        {metaToHelmetProps(t, "meta", meta.data)}
      </Helmet>
    </div>
  );
};

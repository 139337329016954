/* @flow */

import type {
  Product,
  ProductCardProduct,
  ProductCategory,
} from "../../types/product.flow";

import React, { useState, useContext } from "react";
import { useData, useSendMessage } from "crustate/react";
import { useTranslate } from "@out-of-home/use-translate";
import { useRestClient } from "../../entrypoint/shared";
import { Form, rules, isRequired } from "@awardit/formaggio";
import { focusInvalidField } from "../../helpers/formHelpers";
import { sendJson } from "@out-of-home/fetch-utils";
import { addMessage } from "../../state/messages";
import { Button } from "../Button";
import { Field } from "../Field";

const validation = rules([
  isRequired("sku"),
  isRequired("brand"),
  isRequired("product"),
]);

export const NoResultsSearchForm = (): React$Node => {
  const t = useTranslate();
  const client = useRestClient();
  const sendMessage = useSendMessage();
  const [state, setState] = useState();
  const [processing, setProcessing] = useState({
    sku: "",
    brand: "",
    product: "",
  });
  const errors = validation((state: any));

  const submit = (e) => {
    // @TODO: error handling?
    e.preventDefault();
    setProcessing(true);
    client("/api/productTip", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(state),
    })
      .then((x) => x.json())
      .then((x) => {
        sendMessage(addMessage("-500", "success"));
        // setState({ company: "", email: "" });
        setProcessing(false);
      });
  };

  return (
    <Form
      errors={errors}
      value={(state: any)}
      onChange={(x) => {
        setState({ ...state, ...(x: any) });
      }}
      onError={focusInvalidField}
      onSubmit={submit}
    >
      <Field
        label={t("PRODUCT_TIP_FORM.PRODUCT_NAME")}
        name="product"
        required
        variant="float"
      />
      <Field label={t("PRODUCT_TIP_FORM.SKU")} name="sku" variant="float" />
      <Field label={t("PRODUCT_TIP_FORM.BRAND")} name="brand" variant="float" />
      <div className="row--large">
        <Button type="submit" variant="primary" processing={processing}>
          {t("PRODUCT_TIP_FORM.SUBMIT")}
        </Button>
      </div>
    </Form>
  );
};

/* @flow */

import { useContext } from "react";
import { useTranslate } from "@out-of-home/use-translate";
import { StoreInfoContext } from "../entrypoint/shared";

const MONTHS_SV_SE = [
  "Januari",
  "Februari",
  "Mars",
  "April",
  "Maj",
  "Juni",
  "Juli",
  "Augusti",
  "September",
  "Oktober",
  "November",
  "December",
];

// Finds groups of three digits in a number.
const MATCH_NUMBER_SET = /\B(?=(\d{3})+(?!\d))/g;

// Formats a number-as-string into 12 345.67 format.
export const formatNumber = (n: string): string =>
  n.replace(".", ",").replace(MATCH_NUMBER_SET, " ");

const formatDate = (date: string | Date): string => {
  const dt = new Date(date);
  return `${dt.getFullYear()}-${("00" + (dt.getMonth() + 1)).slice(-2)}-${(
    "00" + dt.getDate()
  ).slice(-2)}`;
};

/*
 * Returns an object with a couple of different
 * methods used in formatting dates and prices
 */
export const useFormat = (): ({
  /* eg. 2021-06-01 */
  formatDate: (date: string | Date) => string,
  /* eg. 11 juni 2021 */
  formatDateLong: (date: string | Date) => string,
  formatPriceLong: (price: number | string, currency: ?string) => string,
  formatPrice: (price: number | string) => string,
}) => {
  const t = useTranslate();
  const { info } = useContext(StoreInfoContext);
  const currencyCode = info.baseCurrencyCode;

  const locale = "sv-SE";

  const dateTimeFormat = new Intl.DateTimeFormat(locale, {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const formatDateLong = (date: string | Date): string => {
    const dateObj = typeof date === "string" ? new Date(date) : date;

    return (
      dateObj.getDate() +
      " " +
      MONTHS_SV_SE[dateObj.getMonth()] +
      " " +
      dateObj.getFullYear()
    );
  };

  return {
    formatDate,
    formatDateLong,
    formatPriceLong: (price: number | string, currency: ?string) => {
      const curr = currency ? currency : currencyCode;
      return (
        formatNumber(
          Number(parseFloat(price).toFixed(curr === "EUR" ? 2 : 0)).toString()
        ) +
        " " +
        curr
      );
    },
    formatPrice: (price: number | string) =>
      // Max 2 decimals, and skip any trailing zeroes
      formatNumber(Number(parseFloat(price).toFixed(2)).toString()) +
      " " +
      t(`LOCALE.CURRENCY_SHORT.${currencyCode}`),
  };
};

/* @flow */

import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { useData } from "crustate/react";
import { BrandsData } from "../../data";
import styles from "./styles.scss";
import { useTranslate } from "@out-of-home/use-translate";
import { Wrapper } from "../../components/Wrapper";

export const BrandsView = (): React$Node => {
  const t = useTranslate();
  const brandsData = useData(BrandsData);
  const brands = brandsData.state === "LOADED" ? brandsData.data : [];

  return (
    <div>
      <div className={styles.block}>
        <Wrapper className={styles.section} variant="large">
          <div className={cn(styles.row, "typography")}>
            <h1>{t("BRAND.HEADING")}</h1>
            <div className={styles.two}>
              <div>
                <p dangerouslySetInnerHTML={{ __html: t("BRAND.SECTION_1") }} />
              </div>
              <div>
                <p dangerouslySetInnerHTML={{ __html: t("BRAND.SECTION_2") }} />
              </div>
            </div>
          </div>
        </Wrapper>
        <Wrapper>
          <div className={styles.row}>
            {brands.map((x) => (
              <Link
                key={x.title}
                to={`/p/_${x.title}`}
                className={styles.column}
              >
                <div className={styles.vendor}>
                  <img src={x.icon} alt={x.title} title={x.title} loading="lazy" />
                </div>
              </Link>
            ))}
          </div>
        </Wrapper>
      </div>

      <Helmet title={t("BRAND.TITLE")} />
    </div>
  );
};

// @flow

import type { Quote } from "../types/quote.flow";
import type { CurrentItem } from "./quote-items";

export const EVENT_CUSTOMER_LOGIN: "event/login" = "event/login";
export const EVENT_CUSTOMER_LOGOUT: "event/logout" = "event/logout";
export const EVENT_CHECKOUT_CREATE_ADDRESS: "event/checkout-new-address" =
  "event/checkout-new-address";
export const EVENT_CHECKOUT_UPDATE_ADDRESS: "event/checkout-update-address" =
  "event/checkout-update-address";
export const EVENT_PLACE_ORDER_SUCCESS: "event/place_order_success" =
  "event/place_order_success";
export const EVENT_CART_MODIFY: "event/cart_modify" =
  "event/cart_modify";

export type EventCustomerLogin = {
  tag: typeof EVENT_CUSTOMER_LOGIN,
};

export type EventCustomerLogout = {
  tag: typeof EVENT_CUSTOMER_LOGOUT,
  quote: Quote,
};

export type EventPlaceOrderSuccess = {
  tag: typeof EVENT_PLACE_ORDER_SUCCESS,
  quote: Quote,
};

export type EventCartModify = {
  tag: typeof EVENT_CART_MODIFY,
  items: {  [buyRequest: string]: ?CurrentItem }
};

/* @flow */

import type { Product } from "../types/product.flow";
import type { Fetch } from "@out-of-home/fetch-utils";
import type { Storage } from "crustate";
import type {
  MyProductsRequest,
  MyProductsResponse,
} from "../state/my-products";
import {
  MY_PRODUCTS_LOAD_REQUEST,
  MY_PRODUCTS_LOAD_RESPONSE,
} from "../state/my-products";
import { stringifyParams } from "@out-of-home/location-search-string";

const makeSort =
  (attrs: Array<string> = []) =>
  (array) =>
    array.slice().sort((a, b) => {
      for (let i = 0; i < attrs.length; i++) {
        const aVal = a.attributes[attrs[i]] || "";
        const bVal = b.attributes[attrs[i]] || "";

        const result = aVal.localeCompare(bVal);

        if (result !== 0) {
          return result;
        }
      }

      return 0;
    });

export const sortShoppinglistProducts = (
  products: Array<Product>
): Array<Product> => {
  return makeSort(["manufacturer", "brand", "ooh_product_type"])(products);
};

const registerClient = (storage: Storage, restClient: Fetch) => {
  storage.addEffect({
    effect: async (msg: MyProductsRequest) => {
      if (msg.tag === MY_PRODUCTS_LOAD_REQUEST) {
        const { query } = msg;

        const data = await restClient(
          "/api/shoppinglist/groupings" + stringifyParams(query.filter)
        )
          .then((x) => x.json())
          .then((d) =>
            Promise.all(
              d.map((c) =>
                restClient(
                  "/api/shoppinglist" +
                    stringifyParams({
                      ...query.filter,
                      skipRecommended: true,
                      limit: c.actual || 100,
                      main_category: c.key,
                    })
                )
                  .then((x) => x.json())
                  .then((p) => {
                    c.products = sortShoppinglistProducts(p.products);
                    return c;
                  })
              )
            )
          );

        return ({
          tag: MY_PRODUCTS_LOAD_RESPONSE,
          data,
        }: MyProductsResponse);
      }
    },
    subscribe: { [MY_PRODUCTS_LOAD_REQUEST]: true },
  });
};

export default registerClient;

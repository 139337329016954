/* @flow */

import type { TimeWindow } from "../components/ShippingMethods/amtables-hooks";

export type HoursRange = { from: number, to: number };
export type DeliveryWindow = { from: Date, to: Date, key: string };
export type DateRange = { from: Date, to: Date };

import format from "date-fns/format";

const STEP_SIZE = 0.25;

const HOLIDAYS = [
  "2022-01-01",
  "2022-01-06",
  "2022-04-15",
  "2022-04-17",
  "2022-04-18",
  "2022-05-01",
  "2022-05-26",
  "2022-06-05",
  "2022-06-06",
  "2022-06-24",
  "2022-06-25",
  "2022-11-05",
  "2022-12-25",
  "2022-12-26",
  "2023-01-01",
  "2023-01-06",
  "2023-04-07",
  "2023-04-09",
  "2023-04-10",
  "2023-05-01",
  "2023-05-18",
  "2023-05-28",
  "2023-06-06",
  "2023-06-23",
  "2023-06-24",
  "2023-11-04",
  "2023-12-25",
  "2023-12-26",
  "2024-01-01",
  "2024-01-06",
  "2024-03-29",
  "2024-03-31",
  "2024-04-01",
  "2024-05-01",
  "2024-05-09",
  "2024-05-19",
  "2024-06-06",
  "2024-06-21",
  "2024-06-22",
  "2024-11-02",
  "2024-12-25",
  "2024-12-26",
  "2025-01-01",
  "2025-01-06",
  "2025-04-18",
  "2025-04-20",
  "2025-04-21",
  "2025-05-01",
  "2025-05-29",
  "2025-06-06",
  "2025-06-08",
  "2025-06-20",
  "2025-06-21",
  "2025-11-01",
  "2025-12-25",
  "2025-12-26",
  "2026-01-01",
  "2026-01-06",
  "2026-04-03",
  "2026-04-05",
  "2026-04-06",
  "2026-05-01",
  "2026-05-14",
  "2026-05-24",
  "2026-06-06",
  "2026-06-19",
  "2026-06-20",
  "2026-10-31",
  "2026-12-25",
  "2026-12-26",
];

export const prefixObjectKeys = (
  prefix: string,
  excludeKeysList: Array<string>,
  d: { [key: string]: string },
): { [key: string]: string } => {
  const data = Object.keys(d).reduce((acc, key) => {
    if (excludeKeysList.indexOf(key) === -1) {
      const newKey = `${prefix}${key.charAt(0).toUpperCase() + key.slice(1)}`;
      acc[newKey] = d[key];
    } else {
      acc[key] = d[key];
    }

    return acc;
  }, {});

  return data;
};

export const zeroPrefix = (x: number | string): string | number => {
  return x.toString().length === 1 ? "0" + x : x;
};

export const dateToHours = (d: Date): number => {
  const hours = d.getHours();
  const frac = d.getMinutes() * 0.6;

  return hours + frac * 60;
};

export const numberToDate = (n: number): Date => {
  const hours = n | 0;
  const frac = n - hours;
  const minutes = frac * 60;

  return new Date(1970, 1, 1, hours, minutes, 0, 0);
};

export const formatTime = (t: number): string => {
  const hours = t | 0;
  const frac = t - hours;

  return zeroPrefix(hours) + ":" + zeroPrefix(frac * 60);
};

export const formatDate = (d: Date): string => {
  const day = d.toLocaleString("sv-SE", { weekday: "short" });
  const date = d.getDate();
  const month = d.toLocaleString("sv-SE", { month: "short" });

  return `${day} ${date} ${month}`;
};

/**
 * @param {string} t eg "10:30"
 * @return {number} number 10.5
 * @example Input string "10:30" => number 10.5
 *
 * */
export const timeToNumber = (t: string): number => {
  const parts = t.split(":").map((x) => parseInt(x, 10));

  return parts[0] + parts[1] / 60;
};

export const round = (value: HoursRange): HoursRange => {
  return {
    from: Math.round(value.from / STEP_SIZE) * STEP_SIZE,
    to: Math.round(value.to / STEP_SIZE) * STEP_SIZE,
  };
};

export const validateHoursRange = (timeWindowRange: TimeWindow, hoursRange: HoursRange): boolean =>  {
  if (timeWindowRange.minimum === null || timeWindowRange.minimum < 1 || timeWindowRange.minimum > 7) {
    return false;
  }

  if (timeWindowRange.range.from > hoursRange.from) {
    return false;
  }

  if (timeWindowRange.range.to < hoursRange.to) {
    return false;
  }

  if (hoursRange.from >= hoursRange.to) {
    return false;
  }

  if ((hoursRange.to - hoursRange.from) < timeWindowRange.minimum) {
    return false;
  }

  return true;
}

export const clamp = (
  value: { from: number, to: number },
  min: number,
  max: number,
): HoursRange => {
  let from = Math.min(Math.max(value.from, min), max);
  let to = Math.max(Math.min(value.to, max), min);

  return { from, to };
};

export const ISO8601ToDate = (d: string): ?Date => {
  if (!d) {
    return null;
  }

  const p = d.split("-").map((x) => parseInt(x, 10));

  if (p.length !== 3) {
    return null;
  }

  return new Date(p[0], p[1] - 1, p[2], 0, 0, 0, 0);
};

export const dateToISO8601 = (d: Date): string => {
  return format(d, "yyyy-MM-dd");
};

export const timeWindowToHourRange = (t: string /* HH-HH */): HoursRange | null => {
  const timeParts = t ? t.split("-") : [];

  return timeParts.length === 2
    ? {
        from: timeToNumber(timeParts[0]),
        to: timeToNumber(timeParts[1]),
      }
    : null;
};

export const hoursToRemove = (t: HoursRange): number => {
  return Math.max(Math.min(13 - t.from, 1), 0) - Math.max(Math.min(13 - t.to, 1), 0);
};

export const toLocalDate = (date: string): ?Date => {
  if (!date) {
    return null;
  }

  const [y, m, d] = date.split("-");

  const x = new Date(1970, 1, 1, 0, 0, 0, 0);

  x.setFullYear(Number.parseInt(y, 10));
  x.setMonth(Number.parseInt(m, 10) - 1);
  x.setDate(Number.parseInt(d, 10));

  return x;
};

export const valueToPercent = (
  activeDeliveryWindow: { from: number, to: number },
  value: number,
): number => {
  if (!activeDeliveryWindow) {
    return 0;
  }

  const { from, to } = activeDeliveryWindow;

  return Math.abs((value - from) / (to - from)) * 100;
};

export const addDays = (date: Date, days: number): Date => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const isHoliday = (date: Date): boolean => {
  const d = dateToISO8601(date);
  return HOLIDAYS.includes(d);
};

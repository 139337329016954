/* @flow */

import type { Node as ReactNode } from "react";
import React, { useContext, createContext } from "react";

export type TTimeContext = {
  now: Date,
};

type TimeProviderProps = {
  children: ReactNode,
  value: TTimeContext,
};

export const TimeContext: React$Context<TTimeContext> =
  createContext<TTimeContext>({
    now: new Date(),
  });

export const TimeProvider = ({
  children,
  value,
}: TimeProviderProps): React$Node => (
  <TimeContext.Provider value={value}>{children}</TimeContext.Provider>
);

/* @flow */

import { useRestClient } from "../../entrypoint/shared";
import cn from "classnames";
import styles from "./styles.scss";

import InstagramIcon from "../../icons/instagram.svg";

import React from "react";

type Props = {
  url: string,
};

export default ({ url }: Props): React$Node => {
  const handle = url.split("/").pop();

  return (
    <a
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      className={styles.item}
    >
      <InstagramIcon />
      <div>
        <span>@{handle}</span>
        <div className={styles.dummyButton}>Följ</div>
      </div>
    </a>
  );
};

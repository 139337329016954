/* @flow */

import type { Quote } from "../../types/quote.flow";

import React from "react";
import styles from "./styles.scss";
import cn from "classnames";
import { useTranslate } from "@out-of-home/use-translate";
import { useFormat } from "../../helpers/use-format";

type Props = {
  quote: Quote,
  className?: string,
};

export const CartSummary = ({ quote, className }: Props): React$Node => {
  const t = useTranslate();
  const { formatPrice } = useFormat();
  const { shipping } = quote;
  const discounts = quote.discounts || [];

  return (
    <div className={cn(styles.summary, className)}>
      {quote.deposit > 0 && (
        <div className={styles.row}>
          <label className={styles.label}>{t("CART.DEPOSIT")}</label>
          <span className={styles.value}>{formatPrice(quote.deposit)}</span>
        </div>
      )}

      <div className={styles.row}>
        <label className={styles.label}>{t("CART.SUBTOTAL")}</label>
        <span className={styles.value}>
          {formatPrice(quote.subTotal.exVat)}
        </span>
      </div>

      {quote.discountTotal < 0 && (
        <div className={styles.row}>
          <label className={styles.label}>{t("CART.DISCOUNT")}</label>
          <span className={styles.value}>
            {formatPrice(quote.discountTotal)}
          </span>

          {discounts.filter((x) => x.label).length > 0 && (
            <ul className={styles.discounts}>
              {discounts
                .filter((x) => x.label)
                .map((x) => (
                  <li key={x.label}>{x.label}</li>
                ))}
            </ul>
          )}
        </div>
      )}

      {shipping ? (
        <div className={styles.row}>
          <label className={styles.label}>{shipping.method.description}</label>
          <span className={styles.value}>
            {formatPrice(shipping.total.exVat)}
          </span>
        </div>
      ) : (
        <div className={styles.row}>
          <label className={styles.label}>Laddar fraktsätt</label>
        </div>
      )}

      {quote.taxRates.map((x) => (
        <div key={x.percent} className={styles.row}>
          <label className={styles.label}>
            {t("CART.TAX")} <small>({x.percent}%)</small>
          </label>
          <span className={styles.value}>{formatPrice(x.amount)}</span>
        </div>
      ))}

      <div className={styles.row}>
        <div className={styles.grandTotalContainer}>
          <label className={styles.label}>{t("CART.TOTAL_EXCL_TAX")}</label>
          <span className={cn(styles.value, styles.grandTotal)}>
            {formatPrice(quote.grandTotal.exVat)}
          </span>
        </div>
      </div>

      <div className={styles.row}>
        <label className={styles.label}>{t("CART.TOTAL_INCL_TAX")}</label>
        <span className={styles.value}>
          {formatPrice(quote.grandTotal.incVat)}
        </span>
      </div>
    </div>
  );
};

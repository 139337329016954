/* @flow  */
import type { CustomFieldValue } from "../../types/wfm.flow";

import React, { useRef, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import cn from "classnames";
import styles from "./styles.scss";
import { useRestClient, useClient } from "../../entrypoint/shared";
import { Wrapper } from "../../components/Wrapper";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { Field } from "../../components/Field";
import { Dropdown, DropdownItem } from "../../components/Dropdown";
import { FormErrors } from "../../components/FormErrors";
import { focusInvalidField } from "../../helpers/formHelpers";
import { nestedRule } from "../../helpers/validationRules";
import { useTranslate } from "@out-of-home/use-translate";
import { useSendMessage } from "crustate/react";
import { sendJson } from "@out-of-home/fetch-utils";
import { addMessage } from "../../state/messages";
import { createNewSupplierTask } from "../../queries";

import {
  Form,
  rules,
  conditional,
  isRequired,
  match,
  isEmail,
  isNumeric,
} from "@awardit/formaggio";

type State = {
  company: ?string,
  contact_person: ?string,
  email: ?string,
  phone: ?string,
  brands: ?string,
  category: ?string,
  number_of_sellers: ?string,
  number_of_skus_foodservice: ?string,
  number_of_skus_dvh: ?string,
  wholesalers_today: ?string,
  office_location: ?string,
};

const validation = rules([
  isRequired("company"),
  isRequired("contact_person"),
  isRequired("email"),
  isEmail("email"),
  isRequired("phone"),
  isRequired("phone"),
  isRequired("brands"),
  isRequired("category"),
  conditional(
    (s) => Boolean(s.number_of_sellers),
    isNumeric("number_of_sellers")
  ),
  conditional(
    (s) => Boolean(s.number_of_skus_foodservice),
    isNumeric("number_of_skus_foodservice")
  ),
  conditional(
    (s) => Boolean(s.number_of_skus_dvh),
    isNumeric("number_of_skus_dvh")
  ),
  conditional(
    (s) => Boolean(s.wholesalers_today),
    isNumeric("wholesalers_today")
  ),
]);

const office_locations = [
  { id: "17aca576-8eb2-4cc8-822d-e5357e842949", label: "Öst" },
  { id: "7f3f5855-955b-475b-bc04-5a99229cbcd6", label: "Väst" },
  { id: "4a2af5f2-3e67-4dda-9c7e-b70b376f11f3", label: "Syd" },
  { id: "731c45f1-54b5-462d-b6f5-9194f8ed119a", label: "Norr" },
];

export const SupplierInquiryView = (): React$Node => {
  const t = useTranslate();
  const restClient = useRestClient();
  const gqlClient = useClient();
  const sendMessage = useSendMessage();
  const { push } = useHistory();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState<State>({
    company: null,
    contact_person: null,
    email: null,
    phone: null,
    brands: null,
    category: null,
    number_of_sellers: null,
    number_of_skus_foodservice: null,
    number_of_skus_dvh: null,
    wholesalers_today: null,
    office_location: null,
  });

  const errors = validation((formState: any));
  const lang = "sv_SE";

  useEffect(() => {
    restClient("/api/data_footer")
      .then((x) => x.json())
      .then((x) => {
        setCategories(x.main_category.map((x) => ({ id: x.key })));
      });
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();
    const state = {
      ...formState,
      country: lang === "sv_SE" ? "SE" : "NO",
    };
    setLoading(Boolean(state.company && state.contact_person));

    if (!state.company || !state.contact_person) {
      return;
    }

    const company = state.company;
    const contact_person = state.contact_person;

    const fields: CustomFieldValue[] = [
      {
        id: "cf1e92e8-82b6-4556-8705-9bea92e0da10",
        value: state.number_of_sellers || "",
      },
      {
        id: "299e74c5-faf2-44ed-97d0-f3aad93e46f0",
        value: state.number_of_skus_dvh || "",
      },
      {
        id: "8ec49780-251f-4ad7-a9e6-d4a466e058c1",
        value: state.number_of_skus_foodservice || "",
      },
      { id: "fd7499fc-0bd7-4e54-aebf-9f5341fe7aed", value: state.email || "" },
      {
        id: "7e3d7e52-ce32-478b-9d24-526d6e35bf5d",
        value: state.wholesalers_today || "",
      },
      {
        id: "59ced56a-0a34-4273-88c2-d6fa6c69e0b9",
        value: state.category || "",
      },
      {
        id: "4d5083ba-0a04-4cc8-aa95-803eecc587a3",
        value: state.office_location || "",
      },
      {
        id: "7958d975-33f4-435e-84e2-7fb05585c37e",
        value: state.contact_person || "",
      },
      { id: "7cc324d5-dd2d-43eb-a48c-d95e80f83d49", value: state.phone || "" },
      { id: "b3b5d3bb-11c6-4733-b7ce-07ac07f45733", value: state.brands || "" },
    ].filter((x) => x.value);

    const body = {
      name: `Ny leverantör: ${company}`,
      description: `${contact_person} från ${company} efterfrågar att bli leverantör via formuläret på outofhome.se`,
      fields,
    };

    try {
      const {
        createNewSupplierTask: { result },
      } = await gqlClient(createNewSupplierTask, body);
      setLoading(false);

      if (result === "success") {
        sendMessage(addMessage("-1502", "success"));
        push("/");
      } else {
        sendMessage(addMessage("-1503", "error"));
      }
    } catch (e) {
      setLoading(false);
      sendMessage(addMessage("-1503", "error"));
    }
  };

  return (
    <div className={styles.block}>
      <Helmet title={t("SUPPLIER_INQUIRY.TITLE")} />

      <Wrapper className={styles.wrapper} variant="small">
        <header className={styles.header}>
          <h1>{t("SUPPLIER_INQUIRY.HEADING")}</h1>
        </header>

        <p>
          Vi brinner för att erbjuda så många bra produkter som möjligt till
          våra kunder och varje månad tillkommer nya varumärken och artiklar. Vi
          är en oberoende grossist som ger dig som varumärkesleverantör
          möjligheten att nå ut till hela Sverige. Många liknar vår tjänst med
          en marknadsplats eller box-mover för leverantörer som vill distribuera
          sina produkter ut till slutkund. Fördelarna är många. Inga egna
          konkurrerande varumärken, korta ledtider, rimliga priser och
          garanterad lagerläggning - för att nämna ett par. Vill du läsa mer om
          oss, <a href="/om-oss">klicka här</a>.
        </p>

        <p>
          <i>
            "Outofhome.se är ett modernt, tydligt och enkelt alternativ för oss
            och för våra kunder. Att outofhome.se endast fokuserar på varumärken
            är ju självklart också en fördel för oss som varumärkesägare." - Per
            Grahn, Kommersiell Direktör OoH @ Löfbergs
          </i>
        </p>

        <p>
          <i>
            "En modern och transparent boxmover som på ett enkelt sätt erbjuder
            slutkunden ett komplett sortiment från varumärkeslevernatörerna.." -
            Nicklas Bengtsson, Nordisk Försäljningsdirektör Mealservice @ Duni
          </i>
        </p>

        <p>
          <i>
            "Den transparenta prissättningen till kund gör att vi kan sälja våra
            produkter mer direkt. Vår säljkår uppskattar konkurrensfördelen som
            outofhome.se ger genom försäljning i styck och snabba leveranser.
            Dessutom har vår säljkår tillgång till statistik på sin försäljning
            via outofhome.se vilket gör att vi kan vara proaktiva i vårt
            försäljningsarbete på ett helt nytt sätt." - Johan Öhrman, Customer
            Developement Manager @ Santa Maria
          </i>
        </p>

        <p>
          Just nu är det många som är intresserade av att komma bli leverantörer
          och vi jobbar för fullt med att bearbeta alla förfrågningar. Vi ber
          därför om din förståelse om vårt svar skulle dröja. Tveka dock inte på
          att anmäla ditt intresse då vi gärna vill höra om ert utbud.
        </p>

        <Form
          onSubmit={submitForm}
          onError={focusInvalidField}
          errors={errors}
          value={(formState: any)}
          onChange={(x) => setFormState({ ...formState, ...(x: any) })}
        >
          <div className={cn(styles.row, styles.splitContainer)}>
            <div className={styles.split}>
              <Field
                name="company"
                variant="float"
                label={t("SUPPLIER_INQUIRY.COMPANY")}
              />
            </div>
            <div className={styles.split}>
              <Field
                name="contact_person"
                variant="float"
                label={t("SUPPLIER_INQUIRY.CONTACT_PERSON")}
              />
            </div>
          </div>

          <div className={cn(styles.row, styles.splitContainer)}>
            <div className={styles.split}>
              <Field
                name="email"
                variant="float"
                label={t("SUPPLIER_INQUIRY.EMAIL")}
                type="email"
              />
            </div>
            <div className={styles.split}>
              <Field
                name="phone"
                variant="float"
                label={t("SUPPLIER_INQUIRY.PHONE")}
                type="tel"
              />
            </div>
          </div>

          <div className={cn(styles.row, styles.splitContainer)}>
            <div className={styles.split}>
              <Field
                name="brands"
                variant="float"
                label={t("SUPPLIER_INQUIRY.BRANDS")}
              />
            </div>
            <div className={styles.split}>
              <Dropdown
                className={styles.dropdown}
                value={formState.category}
                variant="naked"
                label={t("SUPPLIER_INQUIRY.CATEGORY")}
                maxHeight={200}
                name="category"
                onChange={(x) =>
                  setFormState({ ...formState, category: String(x.value) })
                }
              >
                {categories.map((x) => (
                  <DropdownItem key={x.id} value={x.id}>
                    {x.id}
                  </DropdownItem>
                ))}
              </Dropdown>
            </div>
          </div>

          <div className={cn(styles.row, styles.splitContainer)}>
            <div className={styles.split}>
              <Field
                name="number_of_sellers"
                variant="float"
                label={t("SUPPLIER_INQUIRY.NUMBER_OF_SELLERS")}
              />
            </div>
            <div className={styles.split}>
              <Field
                name="wholesalers_today"
                variant="float"
                label={t("SUPPLIER_INQUIRY.WHOLESALERS_TODAY")}
              />
            </div>
          </div>

          <div className={cn(styles.row, styles.splitContainer)}>
            <div className={styles.split}>
              <Field
                name="number_of_skus_foodservice"
                variant="float"
                label={t("SUPPLIER_INQUIRY.NUMBER_OF_SKUS_FOODSERVICE")}
              />
            </div>
            <div className={styles.split}>
              <Field
                name="number_of_skus_dvh"
                variant="float"
                label={t("SUPPLIER_INQUIRY.NUMBER_OF_SKUS_DVH")}
              />
            </div>
          </div>

          <div className={styles.row} />

          <div className={cn(styles.row, styles.splitContainer)}>
            <div className={styles.split}>
              <Dropdown
                value={formState.office_location}
                className={styles.dropdown}
                variant="naked"
                headerContent={(x) => {
                  const item = office_locations.find(
                    (a) => a.id === formState.office_location
                  );
                  return item?.label || null;
                }}
                label={t("SUPPLIER_INQUIRY.OFFICE_LOCATION")}
                maxHeight={200}
                name="office_location"
                onChange={(x) =>
                  setFormState({
                    ...formState,
                    office_location: String(x.value),
                  })
                }
              >
                {office_locations.map((x) => (
                  <DropdownItem key={x.id} value={x.id}>
                    {x.label}
                  </DropdownItem>
                ))}
              </Dropdown>
            </div>
          </div>

          <div className={cn(styles.row, styles.rowSubmit)}>
            <Button
              className={styles.submit}
              type="submit"
              variant="primary"
              loading={loading}
            >
              {t("SUPPLIER_INQUIRY.SUBMIT")}
            </Button>
          </div>
        </Form>
      </Wrapper>
    </div>
  );
};

/* @flow */

import type { RouterHistory, Location } from "react-router-dom";
import { useCallback, useEffect, useRef } from "react";

type Ref<T> = {| current: T |};

type OnPush<T> = (history: RouterHistory, location: Location) => T;
type OnPop<T> = (
  history: RouterHistory,
  location: Location,
  metadata: ?T
) => void;

export const useOnNavigation = <T>(
  history: RouterHistory,
  onPush: OnPush<T>,
  onPop: OnPop<T>
) => {
  const lastKey = useRef(history.location.key);
  const log = useRef([]);

  useEffect(
    () =>
      history.listen((location) => {
        const { current: currentKey } = lastKey;

        if (history.action === "PUSH") {
          // Ensure we actually are pushing after the current key, clear from the
          // end until we find currentKey

          if (currentKey) {
            for (let i = log.current.length - 1; i >= 0; i--) {
              if (log.current[i].key !== currentKey) {
                log.current.pop();

                break;
              }
            }
          }

          log.current.push({
            key: lastKey.current,
            data: onPush(history, location),
          });
        } else if (history.action === "POP") {
          let metadata = null;

          // Find the metadata for the key
          for (let i = log.current.length - 1; i >= 0; i--) {
            if (log.current[i].key === location.key) {
              metadata = log.current[i];

              break;
            }
          }

          onPop(history, location, metadata ? metadata.data : null);
        }

        lastKey.current = location.key;
      }),
    [lastKey, log, history, onPush, onPop]
  );
};

const scrollToTop = () => {
  const prevPosition = window.scrollY;

  window.scrollTo(0, 0);

  return prevPosition;
};

const scrollToMetadata = (history, location, prevPosition: ?number) => {
  // window.scrollTo(0, prevPosition || 0);
};

export const usePreserveScrollPosition = (history: RouterHistory): void =>
  useOnNavigation(history, scrollToTop, scrollToMetadata);

/* @flow */

import type { ProductAbstract } from "../../types/product.flow";

import React, { useState, useEffect } from "react";
import styles from "./styles.scss";
import cn from "classnames";
import { useData, useSendMessage } from "crustate/react";
import { useTranslate } from "@out-of-home/use-translate";
import { Foldable } from "../Foldable";
import { Wrapper } from "../Wrapper";
import { clearMessages } from "../../state/stock-messages";
import { StockMessagesData } from "../../data";
import RemoveIcon from "../../icons/close.svg";
import { useBrowserLayoutEffect } from "../../helpers";

type MessageProps = {
  markup: React$Node,
  deleting: boolean,
  onClick: (Event) => void,
};

const Message = ({ markup, deleting, onClick }: MessageProps) => {
  const t = useTranslate();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <div className={styles.messageOuter}>
      <Foldable
        open={!deleting && open}
        className={styles.message}
        onClick={onClick}
      >
        <div className={styles.itemInner}>
          <div className={styles.itemPadding}>
            <div className={styles.itemMarkup}>{markup}</div>
            <button className={styles.closeBtn}>
              <RemoveIcon />
            </button>
          </div>
        </div>
      </Foldable>
    </div>
  );
};

const StockMessages = (): React$Node => {
  const sendMessage = useSendMessage();
  const messages = useData(StockMessagesData);
  const [leaving, setLeaving] = useState(false);
  const [appearing, setAppearing] = useState(false);

  useBrowserLayoutEffect(() => {
    if (messages.length > 0) {
      setTimeout(() => {
        setAppearing(true);
      }, 0);
    } else {
      setAppearing(false);
    }
  }, [messages]);

  const closeAll = () => {
    setLeaving(true);
    setTimeout(() => {
      setLeaving(false);
      sendMessage(clearMessages());
    }, parseInt(styles.transition, 10));
  };

  if (messages.length === 0) {
    return null;
  }

  return (
    <div
      className={cn({
        [styles.outer]: true,
        [styles.appearing]: appearing,
        [styles.leaving]: leaving,
      })}
    >
      <Wrapper>
        <div className={styles.block}>
          <div className={styles.messages}>
            {messages.map((x) => (
              <Message
                {...x}
                key={x.id}
                deleting={leaving}
                onClick={(e: Event) => {
                  e.stopPropagation();
                  closeAll();
                }}
              />
            ))}
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default StockMessages;

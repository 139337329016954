/* @flow */

type Props = {
  className?: string,
  children?: React$Node,
  name?: string,
  value: any,
  onChange: (e: SyntheticInputEvent<HTMLInputElement>) => (void | Promise<void>),
  CheckedComponent?: React$ComponentType<any>,
};

import React, { Children } from "react";
import cn from "classnames";
import styles from "./styles.scss";

const DefaultCheckedComponent = ({ className }: { className?: string }) => (
  <svg
    className={className}
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path d="m0 0h20v20h-20z" />
      <path
        d="m12.8033009 3v10h-5.00000004"
        stroke="currentColor"
        strokeWidth="2"
        transform="matrix(.70710678 .70710678 -.70710678 .70710678 8.674621 -4.942388)"
      />
    </g>
  </svg>
);

export const Checkbox = ({
  children,
  name,
  value,
  onChange,
  className,
  CheckedComponent = DefaultCheckedComponent,
}: Props): React$Node => {
  const checked = typeof value === "boolean" ? value : value === "true";

  return (
    <div className={cn(styles.block, className)}>
      <input
        className={styles.input}
        id={name}
        name={name}
        checked={checked}
        type="checkbox"
        role="checkbox"
        aria-checked={checked}
        aria-labelledby={name}
        onChange={onChange}
      />

      <label htmlFor={name} className={styles.label}>
        {value && (
          <div className={styles.checked}>
            <CheckedComponent className={styles.icon} />
          </div>
        )}

        {Children.count(children) > 0 && (
          <span className={styles.children}>{children}</span>
        )}
      </label>
    </div>
  );
};

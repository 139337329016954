/* @flow */

import type { MeterType } from ".";

import React from "react";
import { Meter } from ".";

type Props = {
  amount: number,
  to: number,
  text: string,
  textFilled: string,
  // In percent
  height?: number,
  type: MeterType,
  hidden?: boolean,
};

export const SimpleMeter = ({
  amount,
  to,
  text,
  textFilled,
  height,
  type,
  hidden,
}: Props): React$Node => {
  const percent = Math.min((amount / to) * 100, 100);

  return (
    <Meter
      height={height}
      type={type}
      hidden={hidden}
      amount={amount}
      percent={percent}
      text={percent === 100 ? textFilled : text}
    />
  );
};

/* @flow */

import type { Model, EffectErrorMessage } from "crustate";
import type { Product, HintProduct } from "../types/product.flow.js";
import type { Category, HintCategory } from "../types/category.flow.js";
import type { CmsPage, HintCmsPage } from "../types/cmsPage.flow.js";

import type {
  FilterLocation,
  ProductFilterInput,
  ProductFilterInputPrice,
  ProductSortInput,
} from "../types/filter.flow.js";

import { getInputFilters, getInputSort, getPage } from "./filter";

import { updateData } from "crustate";

export type RouteResponse =
  | { tag: typeof ROUTE_RESPONSE, data: Route }
  | EffectErrorMessage;

export type RouteRequest = {
  tag: typeof ROUTE_REQUEST,
  url: string,
  filters: ?Array<ProductFilterInput | ProductFilterInputPrice>,
  sort: ?ProductSortInput,
  page: ?number,
};

export type HintRoute =
  | { type: "cms", cmspage: HintCmsPage }
  | { type: "product", product: HintProduct }
  | { type: "category", category: HintCategory };

export type Route =
  | {| type: "cms", cmspage: CmsPage |}
  | {| type: "product", product: Product |}
  | {| type: "category", category: Category |}
  | {| type: "redirect", url: string, isPermanent: boolean |};

type Data =
  | {| state: "INIT" |}
  | {| state: "LOADING", hint: ?HintRoute |}
  | {| state: "UPDATING", route: Route |}
  | {| state: "LOADED", route: Route |};

export const ROUTE_REQUEST: "request/route" = "request/route";
export const ROUTE_RESPONSE: "response/route" = "response/route";

export const initState = { state: "INIT" };

export const load = (
  location: FilterLocation,
  incVat?: boolean,
  pointsToPrice?: (number) => number
): RouteRequest => {
  const filters = getInputFilters(location, incVat, pointsToPrice);
  const sort = getInputSort(location);
  const page = getPage(location);

  return {
    tag: ROUTE_REQUEST,
    url: location.pathname,
    filters,
    sort,
    page,
  };
};

export const RouteModel: Model<
  Data,
  { location: FilterLocation, hint?: HintRoute, incVat?: boolean },
  RouteResponse
> = {
  id: "route",
  init: ({ location, hint, incVat }) =>
    updateData({ state: "LOADING", hint }, load(location, incVat)),
  update: (state, msg) => {
    switch (msg.tag) {
      case ROUTE_RESPONSE:
        if (state.state === "LOADING" || state.state === "UPDATING") {
          return updateData({ state: "LOADED", route: msg.data });
        }

        throw new Error("Invalid state transition");
      case ROUTE_REQUEST:
        if (state.state === "LOADED") {
          return updateData({ state: "UPDATING", route: state.route }, msg);
        }

        throw new Error("Invalid state transition");
      default:
    }
  },
};

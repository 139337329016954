/* @flow */

/*

ADD:
client("/alcohol_licenses", sendJson({ body: data })).then(x =>
  client("/alcohol_licenses").then(jsonResponse).catch(x => x)
)

REMOVE:
payload: client(`/alcohol_licenses/${id}`, { method: "DELETE" }).then(x =>
  client("/alcohol_licenses").then(jsonResponse).then(x => {
    dispatch(addStatus("success", "-7000"));
    dispatch(push(`/${getState().app.routes.ACCOUNT}`));

    return x;
  }).catch(x => x)
)

*/

type History = {
  push: (path: string) => void,
};

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type {
  AlcoholLicensesRequest,
  AlcoholLicensesResponse,
} from "../state/alcohol-licenses";

import { sendJson } from "@out-of-home/fetch-utils";
import { addMessage } from "../state/messages";
import {
  ALCOHOL_LICENSES_INIT_REQUEST,
  ALCOHOL_LICENSES_INIT_RESPONSE,
  ALCOHOL_LICENSES_ADD_REQUEST,
  ALCOHOL_LICENSES_ADD_RESPONSE,
  ALCOHOL_LICENSES_REMOVE_REQUEST,
  ALCOHOL_LICENSES_REMOVE_RESPONSE,
} from "../state/alcohol-licenses";
import { customer as customerQuery, login, logout } from "../queries";

const registerClient = (
  storage: Storage,
  restClient: Fetch,
  history: History
) => {
  storage.addEffect({
    effect: async () => {
      try {
        const licenses = await restClient("/api/alcohol_licenses").then((x) =>
          x.json()
        );

        return ({
          tag: ALCOHOL_LICENSES_INIT_RESPONSE,
          data: licenses,
        }: AlcoholLicensesResponse);
      } catch (e) {
        /*
         * @TODO: how should we handle error states overall?
         * Maybe just a hard crash and a 500 page?
         */
      }
    },
    subscribe: { [ALCOHOL_LICENSES_INIT_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: AlcoholLicensesRequest) => {
      if (msg.tag === ALCOHOL_LICENSES_ADD_REQUEST) {
        try {
          const data = await restClient(
            "/api/alcohol_licenses",
            sendJson({ body: (msg.data: any) })
          ).then((x) => x.json());

          const licenses = await restClient("/api/alcohol_licenses").then((x) =>
            x.json()
          );

          return ({
            tag: ALCOHOL_LICENSES_ADD_RESPONSE,
            data: licenses,
          }: AlcoholLicensesResponse);
        } catch (e) {
          /*
           * @TODO: how should we handle error states overall?
           * Maybe just a hard crash and a 500 page?
           */
        }
      }
    },
    subscribe: { [ALCOHOL_LICENSES_ADD_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: AlcoholLicensesRequest) => {
      if (msg.tag === ALCOHOL_LICENSES_REMOVE_REQUEST) {
        try {
          await restClient(`/api/alcohol_licenses/${msg.id}`, {
            method: "DELETE",
          });

          const licenses = await restClient("/api/alcohol_licenses").then((x) =>
            x.json()
          );

          return ({
            tag: ALCOHOL_LICENSES_REMOVE_RESPONSE,
            data: licenses,
          }: AlcoholLicensesResponse);
        } catch (e) {
          /*
           * @TODO: how should we handle error states overall?
           * Maybe just a hard crash and a 500 page?
           */
        }
      }
    },
    subscribe: { [ALCOHOL_LICENSES_REMOVE_REQUEST]: true },
  });
};

export default registerClient;

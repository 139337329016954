/** @flow */

import React from "react";
import styles from "./styles.scss";

type Props = {
  zIndex?: number,
  onClick: () => void
}

export const IsolatedBackground = ({ zIndex = 500, onClick }: Props): React$Node => {
  return (
    <div className={styles.isolated_background} style={{ zIndex }} onClick={onClick} />
  )
}

/* @flow */

import type {
  ProductAttributes,
  Product,
  ProductAbstract,
  ProductDetailAbstract,
  ProductCardProduct,
} from "../types/product.flow";
import type { Customer } from "../types/customer.flow";

export const outputAttribute = (
  attribute: ?string | ?number,
  postfix: ?string
): string => {
  if (attribute !== null) {
    return String(attribute) + (postfix || "");
  }
  return "-";
};

export const outputSalesUnit = (
  attributes: ProductAttributes,
  def: string = "-"
): string => {
  if (
    attributes.oohQuantity !== null &&
    attributes.oohMeasureName &&
    attributes.oohMeasureValue
  ) {
    return (
      attributes.oohQuantity +
      "x" +
      attributes.oohMeasureValue +
      attributes.oohMeasureName
    );
  }
  return def;
};

export const stockStatus = (product: ProductAbstract): number => {
  if (product.attributes.backorderByVendor) {
    return 8;
  }

  if (product.oohStock === null) {
    return 1;
  }

  if (!product.oohStock.backorders) {
    return 3;
  }

  return 2;
};

export const isExeeding = (
  status: number,
  qty: number,
  stock: number
): boolean =>
  status === 1 ||
  status === 8 ||
  (status === 2 && qty > stock) ||
  (status === 3 && qty === stock);

export const compareParts = (
  p: ProductCardProduct | ProductDetailAbstract,
  formatPrice: (number) => string
): Array<string> =>
  p.oohUnitprice != null && p.oohUnit != null
    ? [formatPrice(p.oohUnitprice), p.oohUnit || ""]
    : ["-"];

export const formatStockDate = (date: string): string | null => {
  if (!date) return null;

  let d = new Date(date.replace(" ", "T"));
  if (d.getFullYear() !== 1970) {
    return `${d.getDate()}/${d.getMonth() + 1}`;
  }
  return null;
};

type Attributes = {
  certified: Array<string>,
};

const hasCertification = (
  { certified }: ProductAttributes,
  certification: string
): boolean => (certified || []).includes(certification);

export const isCampaign = ({ attributes }: ProductAbstract): boolean => {
  return hasCertification(attributes, "Kampanjer");
};

export const isNew = ({ attributes }: ProductAbstract): boolean => {
  return hasCertification(attributes, "Nyheter");
};

export const getDiscount = (product: ProductAbstract): number => {
  return typeof product.attributes.msrp === "number"
    ? Math.round((1 - product.price.exVat / product.attributes.msrp) * 100)
    : 0;
};

export const showFoodora = (product: Product, customer: ?Customer): boolean => {
  if (!customer || Object.keys(customer).length === 0 || !customer.group) {
    return false;
  }

  if (customer.group !== "Foodora") {
    return false;
  }

  return product.attributes && product.attributes.foodora;
};

export const getImgAlt = (product: ProductDetailAbstract): string => {
  const { brand, supplierSku } = product.attributes;

  let imgAlt = product.name;
  let imgTitle = product.name;
  const salesUnit = outputSalesUnit(product.attributes);

  if (salesUnit) {
    imgAlt += " " + salesUnit;
  }

  if (brand) {
    imgAlt += " " + brand;
    imgTitle += " från " + brand;
  }

  if (supplierSku) {
    imgAlt += " #" + supplierSku;
  }

  return imgAlt;
};

export const getImgTitle = (product: ProductDetailAbstract): string => {
  let imgTitle = product.name;

  if (product.attributes.brand) {
    imgTitle += " från " + product.attributes.brand;
  }

  return imgTitle;
};

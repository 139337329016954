/* @flow */

import React, { useState, useEffect } from "react";

import { useSendMessage } from "crustate/react";
import { useTranslate } from "@out-of-home/use-translate";
import { Input } from "../Input";
import { Checkbox } from "../Checkbox";
import { Button } from "../Button";
import { setQuoteDiscountCode, removeQuoteDiscountCode } from "state/quote";
import OKIcon from "../../icons/checkmark.svg";

import styles from "./styles.scss";

type Props = {
  coupon: ?{
    code: string,
    label: string,
  },
};

export const DiscountCode = ({ coupon }: Props): React$Node => {
  const [isOpen, setIsOpen] = useState(Boolean(coupon));
  const [processing, setProcessing] = useState(false);
  const t = useTranslate();
  const [discountCode, setDiscountCode] = useState<string>(
    coupon ? coupon.code : ""
  );
  const sendMessage = useSendMessage();

  const removeCode = () => {
    setProcessing(true);
    sendMessage(removeQuoteDiscountCode()).finally(() => {
      setProcessing(false);
      setDiscountCode("");
    });
  };

  const onSubmit = (e?: Event) => {
    if (e) {
      e.preventDefault();
    }

    if (discountCode.length === 0 || processing) {
      return;
    }

    setProcessing(true);
    sendMessage(setQuoteDiscountCode(discountCode)).finally(() => {
      setProcessing(false);
    });
  };

  const onRemove = (e?: Event) => {
    if (e) {
      e.preventDefault();
    }

    if (processing === true) {
      return;
    }

    removeCode();
  };

  useEffect(() => {
    if (!isOpen && coupon) {
      removeCode();
    }
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(Boolean(coupon));
  }, [coupon]);

  return (
    <div className={styles.block}>
      <Checkbox
        id="checkDiscountCode"
        name="checkDiscountCode"
        value={isOpen}
        onChange={(x) => setIsOpen(!isOpen)}
      >
        {t("CART.COUPON_CODE")}
      </Checkbox>
      {isOpen && (
        <div className={styles.discountCode}>
          <div>
            <input
              className={styles.input}
              name="discountCode"
              disabled={coupon || processing}
              value={discountCode}
              onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                setDiscountCode(e.currentTarget.value);
              }}
              onKeyUp={(e: SyntheticKeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter") {
                  onSubmit();
                }
              }}
            />
            {coupon && (
              <div className={styles.couponIconWrapper}>
                <OKIcon className={styles.couponIcon} />
              </div>
            )}
          </div>

          {coupon ? (
            <Button
              className={styles.button}
              variant="primary"
              loading={processing}
              onClick={onRemove}
            >
              {t("CART.COUPON_DEACTIVATE")}
            </Button>
          ) : (
            <Button
              className={styles.button}
              variant="primary"
              loading={processing}
              onClick={onSubmit}
            >
              {t("CART.COUPON_ACTIVATE")}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

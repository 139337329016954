/* @flow */

import type { Product } from "../../types/product.flow";

import React from "react";
import { useTranslate } from "@out-of-home/use-translate";
import FreezeIcon from "../../icons/freeze.svg";
import ColdIcon from "../../icons/cold.svg";
import AlcoholIcon from "../../icons/alcohol.svg";

type Props = {
  product: Product,
  className?: string,
};

export const Temperature = ({ product, className }: Props): React$Node => {
  const t = useTranslate();
  const temperature = product.attributes.temperature || "";

  if (temperature.toUpperCase() === "FRYST") {
    return <FreezeIcon className={className} alt={t("PRODUCT.FROZEN")} />;
  } else if (temperature.toUpperCase() === "FÄRSKT") {
    return <ColdIcon className={className} alt={t("PRODUCT.COLD")} />;
  }

  return null;
};

export const Alcohol = ({ product, className }: Props): React$Node => {
  const t = useTranslate();

  if (product.attributes.ooh3AlcoholRequirement) {
    return <AlcoholIcon className={className} alt={t("PRODUCT.ALCOHOL")} />;
  }

  return null;
};

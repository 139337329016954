/* @flow */
import React, { useRef, useState, useEffect, useContext, useMemo } from "react";
import cn from "classnames";
import styles from "./styles.scss";
import { useTranslate } from "@out-of-home/use-translate";
import { useRestClient, StoreInfoContext } from "../../../entrypoint/shared";
import TempCategoryIcon from "../../../icons/temp-category.svg";
import { Link } from "react-router-dom";
import { UIContext } from "../../../context/ui";
import { OverflowCarousel } from "../../OverflowCarousel";
import IconApotek from "icons/categories/apotek.svg";
import IconBakverkDessert from "icons/categories/bakverk_dessert.svg";
import IconBarsHalsoSnacks from "icons/categories/bars_halsosnacks.svg";
import IconBrodBakprodukter from "icons/categories/brod_bakprodukter.svg";
import IconChipsSaltaSnacks from "icons/categories/chips_salta_snacks.svg";
import IconDjurBarn from "icons/categories/djur_barn.svg";
import IconDrinkmixers from "icons/categories/drinkmixers.svg";
import IconEnergidryckSportDrycker from "icons/categories/energidryck_sportdrycker.svg";
import IconFardigtlagat from "icons/categories/fardiglagat.svg";
import IconFiskSkaldjur from "icons/categories/fisk_skaldjur.svg";
import IconForpackningarTakeaway from "icons/categories/forpackningar_takeaway.svg";
import IconFruktGront from "icons/categories/frukt_gront.svg";
import IconGlassTillbehor from "icons/categories/glass_tillbehor.svg";
import IconGodisChoklad from "icons/categories/godis_choklad.svg";
import IconJuiceSaftHalsodrycker from "icons/categories/juice_saft_halsodrycker.svg";
import IconKaffeTeChoklad from "icons/categories/kaffe_te_choklad.svg";
import IconKoksServeringsutrustning from "icons/categories/koks_serveringsutrustning.svg";
import IconKontorsButiksutrustning from "icons/categories/kontors_butiksutrustning.svg";
import IconKonserverInlaggningar from "icons/categories/konverver_inlaggningar.svg";
import IconKottFagel from "icons/categories/kott_fagel.svg";
import IconLaskKolsyratStilla from "icons/categories/lask_kolsyrat_stilla.svg";
import IconOljaVinagerSas from "icons/categories/olja_vinager_sas.svg";
import IconOlVinSprit from "icons/categories/ol_vin_sprit.svg";
import IconOstMejeriAgg from "icons/categories/ost_mejeri_agg.svg";
import IconPasarHandskarMatfilm from "icons/categories/pasar_handskar_matfilm.svg";
import IconServetterDukning from "icons/categories/servetter_dukning.svg";
import IconSmaksattning from "icons/categories/smaksattning.svg";
import IconSmorgasarSnabblunch from "icons/categories/smorgasar_snabblunch.svg";
import IconSnus from "icons/categories/snus.svg";
import IconStadningRengoring from "icons/categories/stadning_rengoring.svg";
import IconTorkpapperKvittorullar from "icons/categories/torkpapper_kvittorullar.svg";
import IconTorrvaror from "icons/categories/torrvaror.svg";
import IconTuggumminTabletter from "icons/categories/tuggummin_tabletter.svg";
import IconVegetariskt from "icons/categories/vegetariskt.svg";


type Props = {
  className?: string,
};

const CAT_ICONS = new Map([
  [4146, <IconApotek />], // "apotek"
  [1902, <IconBakverkDessert />], // "bakverk_dessert"
  [4157, <IconBarsHalsoSnacks />], // "bars_halsosnacks"
  [4150, <IconBrodBakprodukter />], // "brod_bakprodukter"
  [4158, <IconChipsSaltaSnacks />], // "chips_salta_snacks"
  [4164, <IconDjurBarn />], // "djur_barn"
  [4152, <IconDrinkmixers />], // "drinkmixers"
  [4154, <IconEnergidryckSportDrycker />], // "energi_sportdrycker"
  [2218, <IconFardigtlagat />], // "fardiglagat"
  [1951, <IconFiskSkaldjur />], // "fisk_skaldjur"
  [4160, <IconForpackningarTakeaway />], // "forpackningar_takeaway"
  [2072, <IconFruktGront />], // "frukt_gront"
  [4162, <IconGlassTillbehor />], // "glass_tillbehor"
  [4155, <IconGodisChoklad />], // "godis_choklad"
  [4151, <IconJuiceSaftHalsodrycker />], // "juice_saft_halsodrycker"
  [1283, <IconKaffeTeChoklad />], // "kaffe_te_choklad"
  [4147, <IconKoksServeringsutrustning />], // "koks_serveringsutrustning"
  [4148, <IconKontorsButiksutrustning />], // "kontors_butiksutrustning"
  [1286, <IconKonserverInlaggningar />], // "konserver_inlaggningar"
  [2088, <IconKottFagel />], // "kott_fagel"
  [4153, <IconLaskKolsyratStilla />], // "lask_kolsyrat_stilla"
  [1288, <IconOljaVinagerSas />], // "olja_vinager_sas"
  [2652, <IconOlVinSprit />], // "ol_vin_sprit"
  [2003, <IconOstMejeriAgg />], // "ost_mejeri_agg"
  [4163, <IconPasarHandskarMatfilm />], // "pasar_handskar_matfilm"
  [4149, <IconServetterDukning />], // "servetter_dukning"
  [1285, <IconSmaksattning />], // "smaksattning"
  [4159, <IconSmorgasarSnabblunch />], // "smorgasar_snabblunch"
  [4161, <IconSnus />], // "snus"
  [1291, <IconStadningRengoring />], // "stadning_rengoring"
  [4145, <IconTorkpapperKvittorullar />], // "torkpapper_kvittorullar"
  [1287, <IconTorrvaror />], // "torrvaror"
  [4156, <IconTuggumminTabletter />], // "tuggummin_tabletter"
  [2076, <IconVegetariskt />], // "vegetariskt"
]);

export const Categories = ({ className }: Props): React$Node => {
  const t = useTranslate();
  const client = useRestClient();
  const categories = useContext(StoreInfoContext).footer.main_category;
  const { browserWidth } = useContext(UIContext);
  const ref = useRef(null);

  const [hasMore, setHasMore] = useState(false);
  const [numPerPage, setNumPerPage] = useState(null);
  const [elementWidth, setElementWidth] = useState(null);
  const [xScroll, setXScroll] = useState(0);

  useEffect(() => {
    if (ref.current) {
      const elementWidth = ref.current.children[0].children[0].clientWidth;
      setElementWidth(elementWidth);
      const numVisible = Math.ceil(browserWidth / elementWidth);
      if (numVisible > categories.length) {
        setNumPerPage(numVisible);
        setHasMore(true);
      }
      else {
        setHasMore(false);
      }
    }
  }, [browserWidth, ref]);

  const scrollRight = (e: Event) => {
    if (ref.current && elementWidth !== null && numPerPage !== null) {
      ref.current.scrollTo(numPerPage * elementWidth, 0);
      // TODO: Check stuff
      setHasMore(false);
    }
  };

  const filteredCategories = useMemo(() => {
      return categories.reduce((acc, { id, count, key }) => {
        const icon = CAT_ICONS.get(id);

      if(!icon) {
        console.log("category missing icon:", key, `(id: ${id})`);
        return acc;
      }

      acc.push({
        key,
        count,
        icon,
      });

      return acc;
    }, []);
  }, [categories]);

  return (
    <OverflowCarousel>
      {filteredCategories.map((x) => (
        <Link to={`/p/${x.key}`} key={x.key} className={styles.category}>
          <div>
            {x.icon}
            <span className={styles.name}>{x.key}</span>
          </div>
          <span className={styles.count}>{x.count}</span>
        </Link>
      ))}
    </OverflowCarousel>
  );
};

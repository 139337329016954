/* @flow */

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./styles.scss";
import footerStyles from "../../components/Footer/styles.scss";
import { Wrapper } from "../Wrapper";
import { Input } from "../Input";
import { Button } from "../Button";
import { Employee } from "../Employee";
import { Nag } from "../Nag";
import { Map } from "../Map";
import { Footer } from "../../components/Footer";
import { useTranslate } from "@out-of-home/use-translate";
import { useRestClient } from "../../entrypoint/shared";
import { useFrontChatContext } from "../../context/front-chat";

type Props = {
  layout?: "large" | "small",
  employeeClassName?: string,
};

export const CustomerService = ({ layout = "large", employeeClassName, ...props }: Props): React$Node => {
  const t = useTranslate();
  const client = useRestClient();
  const [employees, setEmployees] = useState([]);
  const metaTitle = t("CUSTOMER_SERVICE.TITLE");
  const metaData = [
    { description: t("CUSTOMER_SERVICE.TITLE") },
    { "og:title": t("CUSTOMER_SERVICE.TITLE") },
    { "og:description": t("CUSTOMER_SERVICE.TITLE") },
  ];
  const { open: openChatWindow, loading: chatLoading } = useFrontChatContext();

  useEffect(() => {
    client("/api/employee")
      .then((x) => x.json())
      .then((x) => {
        setEmployees(x);
      });
  }, []);

  return (
    <div className={cn(styles.block, [styles[`layout__${layout}`]])}>
      <section className="row--huge">
        <h1>{t("CUSTOMER_SERVICE.TITLE")}</h1>

        <p>{t("CUSTOMER_SERVICE.INFO")}</p>

        <div className={footerStyles.split}>
          <Button
            spinnerVariant="large"
            className={footerStyles.chatButton}
            onClick={openChatWindow}
            loading={chatLoading}
          >
            {t("FOOTER.CHAT_WITH_US")}
          </Button>
          <div className={footerStyles.contactContent}>
            <p>
              {t("FOOTER.TELEPHONE").split("\n")[0]}
              <br />
              {t("FOOTER.TELEPHONE").split("\n")[1]}
              <br />
              <a className="link" href={`mailto: ${t("FOOTER.CONTACT_EMAIL")}`}>
                {t("FOOTER.CONTACT_EMAIL")}
              </a>
            </p>
          </div>
        </div>
      </section>

      {employees.length > 0 && (
        <section className="row--huge">
          <h1 className={styles.heading}>{t("CUSTOMER_SERVICE.TITLE_2")}</h1>

          <div className={styles.employees}>
            {employees.map((x, i) => (
              <Employee
                key={i}
                className={employeeClassName}
                {...x}
                email={null}
                phone={null}
                mobile={null}
                meta={false}
              />
            ))}
          </div>
        </section>
      )}
    </div>
  );
};

export const CustomerServiceView = ({}: Props): React$Node => {
  const t = useTranslate();

  return (
    <div className={styles.block}>
      <Wrapper className={styles.wrapper}>
        <CustomerService />
      </Wrapper>

      <Helmet title={t("CUSTOMER_SERVICE.TITLE")}>
        <meta name="description" content={t("CUSTOMER_SERVICE.TITLE")} />
        <meta property="og:description" content={t("CUSTOMER_SERVICE.TITLE")} />
        <meta property="og:title" content={t("CUSTOMER_SERVICE.TITLE")} />
      </Helmet>
    </div>
  );
};

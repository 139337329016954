/* @flow */

import type {
  ProductCardProduct,
  ProductImage as ProductImageType,
  Gallery,
} from "../../types/product.flow";

type Location = {
  +state?: {
    [key: string]: any,
  },
};

import React, { memo } from "react";
import cn from "classnames";
import { ProductImage } from "./image";
import { useTranslate } from "@out-of-home/use-translate";
import { useBrowser } from "../../helpers/use-browser";
import { Button } from "../Button";
import { Square } from "../Square";

import styles from "./styles.scss";

type Props = {
  location: Location,
  gallery?: Gallery,
  // Usually the product's name
  alt?: string,
  currentImage: ProductImageType,
  setCurrentImage: (ProductImageType) => void,
  galleryPerRow?: 1 | 2 | 3 | 4 | 5,
  className?: string,
  children?: React$Node,
};

type HintProps = {
  location: Location,
  product: ProductCardProduct,
  className?: string,
  children?: React$Node,
};

const galleryStaggerDelay = parseInt(styles.galleryStaggerDelay, 10);

const MediaImageComponent = ({
  location,
  gallery,
  alt,
  currentImage,
  setCurrentImage,
  galleryPerRow = 2,
  className,
  children,
  ...props
}: Props) => {
  const t = useTranslate();
  const { state } = location;
  const browser = useBrowser();
  const hintImage = browser ? state && state.hint && state.hint.image : null;

  return (
    <div {...props} className={cn(styles.block, className)}>
      <ProductImage
        image={currentImage}
        alt={alt}
        hintImage={hintImage}
        className={styles.image}
      />

      {gallery && (
        <div
          className={cn(styles.gallery, [
            styles["gallery__perRow_" + galleryPerRow],
          ])}
        >
          {gallery.map((x, i) => (
            <Button
              key={x.thumb?.x1}
              aria-label={t("PRODUCT.CHANGE_IMAGE")}
              className={cn(styles.galleryItem, {
                [styles.active]:
                  x.largeImage?.x1 === currentImage.largeImage?.x1,
              })}
              style={{
                animationDelay: galleryStaggerDelay * i + "ms",
              }}
              onClick={() => {
                setCurrentImage({
                  smallImage: x.smallImage
                    ? { x1: x.smallImage.x1, x2: x.smallImage.x2 }
                    : null,
                  largeImage: x.largeImage
                    ? { x1: x.largeImage.x1, x2: x.largeImage.x2 }
                    : null,
                });
              }}
            >
              <span>
                {x.thumb && (
                  <picture>
                    <source srcSet={`${x.thumb.x1} 1x, ${x.thumb.x2} 2x`} />
                    <img alt={alt} src={x.thumb.x1} />
                  </picture>
                )}
              </span>
            </Button>
          ))}
        </div>
      )}

      {children}
    </div>
  );
};

export const HintMedia = ({
  location,
  product,
  children,
}: HintProps): React$Node => {
  const { state } = location;
  const browser = useBrowser();
  const hintImage = browser ? state && state.hint && state.hint.image : null;

  return (
    <div className={styles.block}>
      <img src={hintImage} alt={product.name} style={{ width: "100%" }} />
      {children}
    </div>
  );
};

export const MediaImage = (memo<Props>(
  MediaImageComponent
): React$AbstractComponent<Props, mixed>);

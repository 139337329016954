/* @flow */

import type { Value } from "./context";

import React, { useRef, useContext } from "react";
import cn from "classnames";
import DropdownContext from "./context";
import { childrenToText } from "../../helpers";
import styles from "./styles.scss";

type Props = {
  children?: React$Node,
  value: Value,
};

const DropdownItem = ({ value, children, ...props }: Props): React$Node => {
  const element = useRef<?HTMLButtonElement>(null);
  const {
    onSelect,
    value: dropdownValue,
    items,
    searchString,
  } = useContext(DropdownContext);
  const active = dropdownValue === value;
  const item = items.find((x) => x.value === value);

  if (!item) {
    return null;
  }

  if (
    typeof searchString === "string" &&
    searchString.length > 0 &&
    !item.label.toLowerCase().includes(searchString.toLowerCase())
  ) {
    return null;
  }

  return (
    <button
      {...props}
      ref={element}
      type="button"
      data-index={item.index}
      data-value={value}
      aria-label={childrenToText(children)}
      aria-selected={active}
      className={cn(styles.item, { [styles.active]: active })}
      onClick={() => onSelect(value)}
    >
      {children}
    </button>
  );
};

export default DropdownItem;

/* @flow */

import type { Customer } from "../../../types/customer.flow";
import type { CustomerSubType } from "../../../types/storeInfo.flow";

import React, { useState, useEffect, useContext } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useTranslate } from "@out-of-home/use-translate";
import { useSendMessage } from "crustate/react";
import { useData } from "crustate/react";
import { Wrapper } from "../../Wrapper";
import { Input } from "../../Input";
import { Button } from "../../Button";
import { Checkbox } from "../../Checkbox";
import { Field } from "../../Field";
import { Pills, MultiSelectPills } from "../../Pills";
import { FormErrors } from "../../FormErrors";
import { focusInvalidField } from "../../../helpers/formHelpers";
import { useCustomer } from "../../../helpers/use-customer";
import { StoreInfoContext, useRestClient } from "../../../entrypoint/shared";
import { arrayGte } from "../../../helpers/validationRules";
import { setCustomerType } from "../../../state/customer";
import {
  Form,
  rules,
  conditional,
  isRequired,
  isEmail,
} from "@awardit/formaggio";
import styles from "./styles.scss";

type FormState = {
  ooh3Type: string | null,
  ooh3Subtypes: Array<number>,
};

const validation = rules([isRequired("ooh3Type"), arrayGte("ooh3Subtypes", 1)]);

type Props = {
  className?: string,
};

const titleAsId = (a) =>
  a.map((x) => ({
    id: x.title,
    title: x.title,
  }));

const parseCustomerType = (x) =>
  typeof x === "string" && Boolean(x) && x !== "0" ? x : null;

export const EditCustomerType = (): React$Node => {
  const restClient = useRestClient();
  const sendMessage = useSendMessage();
  const t = useTranslate();
  const { customerTypes } = useContext(StoreInfoContext);
  const { customer, state: customerState } = useCustomer();
  const loading = customerState === "UPDATING";

  const [state, setState] = useState<FormState>(() => {
    let ooh3Subtypes = [];
    let customerOoh3Type = null;

    if (customer && customer.ooh3Type) {
      customerOoh3Type = parseCustomerType(customer.ooh3Type);
      const customerOoh3Subtypes = customer.ooh3Subtype;

      const customerType = customerTypes.find((t) => t.title === customerOoh3Type);

      ooh3Subtypes =
        customerType && customerOoh3Subtypes
          ? customerType.subtypes.filter((subtype) =>
              customerOoh3Subtypes.includes(subtype.title)
            )
          : [];
    }

    return {
      ooh3Type: customerOoh3Type,
      ooh3Subtypes: ooh3Subtypes.map((x) => x.id),
    };
  });

  const customerSubTypes: CustomerSubType[] = customerTypes.find((x) => x.title === state.ooh3Type)?.subtypes.map(x => x) || [];
  const validations = validation((state: any));

  const submit = (e) => {
    e.preventDefault();

    if (state.ooh3Type && state.ooh3Subtypes.length) {
      sendMessage(
        setCustomerType(state.ooh3Type, state.ooh3Subtypes.map(String))
      );
    }
  };

  const setType = (value: Object) => {
    setState({
      ...state,
      ooh3Type: state.ooh3Type === value ? null : value,
      ooh3Subtypes: [],
    });
  };

  const setSubType = (value: Object) => {
    setState({ ...state, ooh3Subtypes: value });
  };

  return (
    <Wrapper variant="medium" className={styles.block}>
      <Form
        autoComplete="off"
        errors={validations}
        value={(state: any)}
        onChange={(x: any) => setState({ ...state, x })}
        onError={focusInvalidField}
        onSubmit={submit}
      >
        <div className="row--large">
          <div className={styles.heading}>
            <h2>{t("REGISTER.TYPE_TITLE")}?</h2>
            <p>{t("REGISTER.TYPE_DESCRIPTION")}</p>
          </div>
          <Field
            name="ooh3_type"
            render={({ errors, dirty }) => (
              <div>
                <Pills
                  items={titleAsId(customerTypes)}
                  active={state.ooh3Type}
                  layout="twosplit"
                  onClick={setType}
                />
                <FormErrors
                  errors={errors}
                  dirty={dirty}
                  getMessage={() => t("STATUS_CODE.-6100")}
                />
              </div>
            )}
          />
        </div>

        {state.ooh3Type !== null && (
          <div className="row--large">
            <div className={styles.heading}>
              <h3>
                {t("REGISTER.SUB_TYPES_TITLE", {
                  customer_type: state.ooh3Type,
                })}
                ?<br />
                <small>{t("REGISTER.YOU_CAN_CHOOSE_MULTIPLE")}</small>
              </h3>
            </div>
            <Field
              name="ooh3_subtype"
              render={({ errors, dirty }) => (
                <div>
                  <MultiSelectPills
                    items={customerSubTypes}
                    active={state.ooh3Subtypes}
                    onClick={setSubType}
                    layout="twosplit"
                  />
                  <FormErrors
                    errors={errors}
                    dirty={dirty}
                    getMessage={() => t("STATUS_CODE.-6101")}
                  />
                </div>
              )}
            />
          </div>
        )}

        <div className={cn("row--large", styles.buttons)}>
          <Button
            disabled={loading}
            className={styles.button}
            variant="ghost large"
            to={"/" + t("ROUTES.ACCOUNT")}
          >
            {t("ACCOUNT.BACK")}
          </Button>
          <Button
            loading={loading}
            disabled={validations.length > 0}
            className={styles.button}
            variant="primary large"
            type="submit"
          >
            {t("ACCOUNT.SAVE")}
          </Button>
        </div>
      </Form>
    </Wrapper>
  );
};

/* @flow */

import React from "react";
import { useTranslate } from "@out-of-home/use-translate";
import IconFacebook from "../../icons/facebook.svg";
import IconTwitter from "../../icons/twitter.svg";
import IconInstagram from "../../icons/instagram.svg";
import IconLinkedIn from "../../icons/linkedin.svg";
import styles from "./styles.scss";

const ITEMS = [
  [

    {
      href: "SOCIAL.FACEBOOK_URL",
      icon: IconFacebook,
      alt: "Följ oss på Facebook",
    },
    {
      href: "SOCIAL.INSTAGRAM_URL",
      icon: IconInstagram,
      alt: "Följ oss på Instagram",
    }
  ], [
    { href: "SOCIAL.TWITTER_URL", icon: IconTwitter, alt: "Följ oss på Twitter" },
    {
      href: "SOCIAL.LINKEDIN_URL",
      icon: IconLinkedIn,
      alt: "Följ oss på LinkedIn",
    },
  ]
];

export const Social = (): React$Node => {
  const t = useTranslate();

  return (
    <div className={styles.block}>
      {ITEMS.map((x, i) => (
        <div key={`social_block_${i}`}>
          {x.map(y => (
            <a
              key={y.href}
              href={t(y.href)}
              alt={y.alt}
              aria-label={y.alt}
              rel="noopener noreferrer"
              className={styles.link}
              target="_blank"
              >
              <y.icon className={styles.icon} />
            </a>
          ))}
        </div>
      ))}
    </div>
  );
};

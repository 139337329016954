/* @flow */

import type { TBlogPost } from "../../types/blog.flow";

import React, { useState, useEffect, useContext } from "react";
import { useData } from "crustate/react";
import { useLocation } from "react-router-dom";
import { throttle } from "@out-of-home/diskho";
import cn from "classnames";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import styles from "./styles.scss";
import { Wrapper } from "../../components/Wrapper";
import { ProductList } from "../../components/ProductList";
import { BlogPostCard } from "../../components/BlogPostCard";
import { NotFoundView } from "../NotFoundView";
import { getScrollTop, restProductToGQLProduct } from "../../helpers";
import { useFormat } from "../../helpers/use-format";
import { getBlogPostMeta, metaToHelmetProps } from "../../helpers/get-meta";
import { useTranslate } from "@out-of-home/use-translate";
import { BlogData } from "../../data";
import { UIContext } from "../../context/ui";

import TwitterIcon from "../../icons/twitter.svg";
import FacebookIcon from "../../icons/facebook.svg";
import LinkedInIcon from "../../icons/linkedin.svg";
import { useCustomer } from "../../helpers/use-customer";

type Props = {
  headerHidden?: boolean,
  hint?: ?TBlogPost,
};

export const BlogPostView = (props: Props): React$Node => {
  const t = useTranslate();
  const { formatDate } = useFormat();
  const blogData = useData(BlogData);
  const [belowHero, setBelowHero] = useState(false);
  const { pathname } = useLocation();
  const { headerVisible, headerHeight } = useContext(UIContext);
  const { loggedIn } = useCustomer();
  let post = props.hint;

  const onScroll = () => {
    requestAnimationFrame(() => {
      const st = getScrollTop();
      const heroHeight = 520;

      if (st < heroHeight && belowHero) {
        setBelowHero(false);
        return;
      }

      if (st > heroHeight && !belowHero) {
        setBelowHero(true);
        return;
      }
    });
  };

  useEffect(() => {
    const scroll = throttle(onScroll, 190);
    document.addEventListener("scroll", scroll, { passive: true });

    return () => {
      document.removeEventListener("scroll", scroll, { passive: true });
    };
  });

  if (blogData.state === "NOT_FOUND") {
    return <NotFoundView />;
  }

  if (blogData.state === "LOADED") {
    post = blogData.data;
  }

  if (!post) {
    return null;
  }

  const category = post.categories[0];
  const meta = getBlogPostMeta(post);
  const author = post.contactPersons[0];
  const relatedPosts = post.relatedPosts.filter((x) => x.status === 2);
  const url = "https://outofhome.se" + pathname;

  // Noindex if post status is hidden
  if (post.status === 1) {
    meta.data.push({ name: "robots", content: "noindex" });
  }

  return (
    <div
      className={cn(styles.block, {
        [styles.hint]: blogData.state !== "LOADED",
      })}
      style={{ "--header-height": `${headerHeight}px` }}
    >
      <section>
        <div
          className={styles.imgLarge}
          style={{ backgroundImage: `url(${post.postThumbnail})` }}
        />
        <img
          className={styles.imgSmall}
          src={post.listThumbnail}
          alt={post.metaTitle}
        />
        <Wrapper variant="medium">
          <div className={styles.block}>
            <div className={styles.meta}>
              {category ? (
                <Link
                  to={`/nyheter?category=${category.urlKey}`}
                  className={styles.category}
                >
                  {category.name}
                </Link>
              ) : (
                <span className={styles.category}></span>
              )}
              <span className={styles.date}>
                {formatDate(new Date(post.publishedAt))}
              </span>
            </div>

            <div
              className={cn(
                styles.share,
                { [styles.share__pushed]: headerVisible && belowHero },
                { [styles.share__loggedIn]: loggedIn }
              )}
            >
              <div className={styles.share_container}>
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookIcon className={styles.facebook} width="35" />
                </a>
                <a
                  href={`https://www.linkedin.com/shareArticle?url=${url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedInIcon className={styles.linkedin} width="35" />
                </a>
                <a
                  href={`https://twitter.com/share?url=${url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterIcon className={styles.twitter} width="35" />
                </a>
              </div>
            </div>

            <h1 className={styles.title}>{post.title}</h1>
            <div
              className={cn(styles.content, "typography")}
              dangerouslySetInnerHTML={{ __html: post.fullContent }}
            />

            <div className={cn(styles.tags, "no-underline")}>
              {post.tags.map((x) => (
                <Link
                  key={x.urlKey}
                  to={`/nyheter?tag=${x.urlKey}`}
                  className={styles.tag}
                >
                  #{x.name}
                </Link>
              ))}
            </div>

            <div className={styles.contactPersons}>
              {post.contactPersons.map((x) => (
                <div
                  key={x.firstname + x.lastname}
                  className={styles.contactPerson}
                >
                  <div
                    className={styles.contactPersonImage}
                    style={{ backgroundImage: `url(${x.image})` }}
                  />
                  <div>
                    <span
                      className={styles.contactPersonName}
                    >{`${x.firstname} ${x.lastname}`}</span>
                    <span className={styles.contactPersonTitle}>{x.title}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Wrapper>
      </section>

      {post.relatedProducts.length > 0 && (
        <section className={styles.relatedProducts}>
          <Wrapper>
            <h2 className={styles.sectionTitle}>Relaterade produkter</h2>
            <ProductList
              products={post.relatedProducts.map(restProductToGQLProduct)}
              displayMode="GRID"
              variant="wide"
              listName={"blog_related_products_" + post.urlKey}
            />
          </Wrapper>
        </section>
      )}

      {relatedPosts.length > 0 && (
        <section className={styles.moreNews}>
          <Wrapper>
            <h2 className={styles.sectionTitle}>Fler nyheter</h2>
            <div className={styles.moreNewsList}>
              {relatedPosts.map((x) => (
                <BlogPostCard {...x} full={true} key={x.title} />
              ))}
            </div>
          </Wrapper>
        </section>
      )}

      {/* Almost none of this data is needed. It"s only being used for Google AMP */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "http://schema.org",
            "@type": "NewsArticle",
            mainEntityOfPage: t("LOGO.HREF") + pathname,
            headline: post.title,
            author: author && `${author.firstname} ${author.lastname}`,
            datePublished: new Date(post.publishedAt),
            dateModified: new Date(post.updatedAt),
            publisher: {
              "@type": "Organization",
              name: "OutOfHome",
              url: t("LOGO.HREF"),
              logo: {
                "@type": "ImageObject",
                url: t("LOGO.HREF") + "/assets/images/ooh_ring.png",
                width: 60,
                height: 60,
              },
            },
            image: [post.postThumbnail],
          }),
        }}
      />

      <Helmet title={meta.title}>
        {metaToHelmetProps(t, "meta", meta.data)}
      </Helmet>
    </div>
  );
};

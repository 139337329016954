/* @flow */

import type { Customer, CustomerAddress } from "../../../types/customer.flow";

import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import cn from "classnames";
import { find } from "@out-of-home/diskho";

import styles from "./styles.scss";
import { useData, useSendMessage } from "crustate/react";
import { useClient } from "../../../entrypoint/shared";
import { Wrapper } from "../../Wrapper";
import { Input } from "../../Input";
import { Button } from "../../Button";
import { Field } from "../../Field";
import { RadioField } from "../../RadioField";
import { Radio } from "../../RadioGroup";
import breakpoints from "../../../helpers/breakpoints";
import { addressLabel } from "../../Address";
import { Dropdown } from "../../Dropdown";
import { AlcoholLicenseForm } from "../AlcoholLicenseForm";
import { AlcoholLicenseList } from "../AlcoholLicenseList";
import { EditField } from "./edit-field";
import { Form } from "@awardit/formaggio";
import { useTranslate } from "@out-of-home/use-translate";
import { useCustomer } from "../../../helpers/use-customer";
import { UIContext } from "../../../context/ui";
import {
  setDefaultAddress,
  updateCustomer,
  setDefaultPaymentMethod,
} from "../../../state/customer";
import { AlcoholLicensesData, QuoteData } from "../../../data";
import { logout } from "../../../state/customer";
import {
  paymentMethods as paymentMethodsQuery,
  shippingMethods as shippingMethodsQuery,
} from "../../../queries";

const displayCustomerType = (customer: Customer) => {
  const { ooh3Type, ooh3Subtype } = customer;

  if (!ooh3Type || ooh3Type === "0") {
    return "";
  }

  if (!ooh3Subtype || !ooh3Subtype.length) {
    return ooh3Type;
  }

  return `${ooh3Type} - ${ooh3Subtype.join(", ")}`;
};

export const Dashboard = (): React$Node => {
  const t = useTranslate();
  const client = useClient();
  const sendMessage = useSendMessage();
  const { customer, state: customerState } = useCustomer();
  const { browserWidth } = useContext(UIContext);
  const alcoholLicensesData = useData(AlcoholLicensesData);
  const quoteData = useData(QuoteData);
  const alcoholLicenses = alcoholLicensesData.state !== "INITING" ? alcoholLicensesData.data : [];
  const small = browserWidth > 0 && browserWidth < breakpoints.medium;
  const customerSaving = customerState === "SAVING_ADDRESS" || customerState === "UPDATING";
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [shippingMethods, setShippingMethods] = useState([]);

  if (!customer) {
    return null;
  }

  const onLogout = () => {
    sendMessage(logout());
  };

  const { addresses } = customer;
  const addressLoading = false;

  const setDefaultAddr = (id: string) => {
    const address = find(addresses, (x) => x.id === id);
    if (!address || addressLoading) {
      return;
    }

    sendMessage(setDefaultAddress(id));
  };

  useEffect(() => {
    const p = [client(paymentMethodsQuery), client(shippingMethodsQuery)];

    Promise.all(p).then((resp) => {
      setPaymentMethods(resp[0].paymentMethods.filter((x) => x.code !== "free"));
      setShippingMethods(resp[1].shippingMethods);
    });
  }, []);

  return (
    <div>
      <Wrapper className={styles.block}>
        <section className={styles.section}>
          <aside className={styles.left}>
            <h3 className={styles.title}>{t("ACCOUNT.USER")}</h3>
          </aside>
          <div className={cn(styles.right, "no-underline")}>
            <div className={cn(styles.container, styles.row)}>
              <div className={styles.formLeft}>
                <EditField
                  label={t("ACCOUNT.USERNAME")}
                  value={customer.email}
                  cta={t("ACCOUNT.EDIT")}
                  to={"/" + t("ROUTES.ACCOUNT") + "/" + t("ROUTES.USER_DATA")}
                />
              </div>
              <div className={styles.formRight}>
                <EditField
                  label={t("ACCOUNT.PASSWORD")}
                  value="******"
                  cta={t("ACCOUNT.EDIT")}
                  to={"/" + t("ROUTES.ACCOUNT") + "/" + t("ROUTES.USER_DATA")}
                />
              </div>
            </div>

            <div className={cn(styles.container, styles.row)}>
              <div className={styles.formLeft}>
                <EditField
                  label={t("ACCOUNT.EDIT_ORDER_COPY_EMAIL")}
                  value={customer.ooh3OrderCopyEmail || ""}
                  cta={t("ACCOUNT.ADD")}
                  to={`/${t("ROUTES.ACCOUNT")}/${t("ROUTES.ORDER_COPY_EMAIL")}`}
                />
              </div>
              <div className={styles.formRight}>
                <EditField
                  label={t("ADDRESS.ORG_NUMBER")}
                  value={customer.customerPno}
                  cta={t("ACCOUNT.EDIT")}
                  to={"/" + t("ROUTES.ACCOUNT") + "/" + t("ROUTES.ORG_NUMBER")}
                />
              </div>
            </div>

            <div className={styles.row}>
              <EditField
                label={t("ACCOUNT.CUSTOMER_TYPE")}
                value={displayCustomerType(customer)}
                cta={t("ACCOUNT.EDIT")}
                to={"/" + t("ROUTES.ACCOUNT") + "/" + t("ROUTES.CUSTOMER_TYPE")}
              />
            </div>
          </div>
        </section>

        <section className={cn(styles.section, styles.section__radio)}>
          <aside className={styles.left}>
            <h3 className={styles.title}>{t("ADDRESS.ADDRESS")}</h3>
          </aside>
          <div className={styles.right}>
            {addresses.map((x) => (
              <div key={x.id} className={styles.row}>
                <RadioField
                  id={x.id}
                  disabled={addressLoading}
                  checked={x.isDefaultBilling}
                  label={t("ADDRESS.ADDRESS")}
                  value={addressLabel(x) || ""}
                  onChange={(x) => setDefaultAddr(x.id)}
                  to={`/${t("ROUTES.ACCOUNT")}/${t("ROUTES.ADDRESS")}/${String(x.id)}`}
                  name="selected_billing_address"
                />
              </div>
            ))}

            <div className={styles.row__large}>
              <Button
                className="pull--right"
                variant="ghost"
                to={"/" + t("ROUTES.ACCOUNT") + "/" + t("ROUTES.ADDRESS")}
              >
                {t("ACCOUNT.ADD")}
              </Button>
            </div>
          </div>
        </section>

        {paymentMethods.length > 0 && (
          <section className={cn(styles.section, styles.section__radio)}>
            <aside className={styles.left}>
              <h3 className={styles.title}>{t("ACCOUNT.PAYMENT_INFO")}</h3>
            </aside>
            <div className={styles.right}>
              {paymentMethods.map((x) => {
                let labelTitle = "";

                if (x.code === "Crossroads_Stripe_PaymentIntents") {
                  labelTitle = "Via Stripe";
                } else if (x.code === "CrossInvoice") {
                } else if (x.code === "Crossroads_Collector") {
                  labelTitle = customer.customerPno;
                }

                return (
                  <div key={x.code} className={styles.row}>
                    <RadioField
                      id={`payment_method_${x.code}`}
                      name="payment_method"
                      to={null}
                      disabled={customerSaving}
                      checked={x.code === customer.defaultPaymentMethodCode}
                      label={x.title}
                      value={small ? x.title : labelTitle}
                      onChange={(e) => {
                        sendMessage(setDefaultPaymentMethod(x.code));
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </section>
        )}

        <section className={cn(styles.section, styles.section__radio)}>
          <aside className={styles.left}>
            <h3 className={styles.title}>{t("ACCOUNT.ALCOHOL_CERTIFICATE")}</h3>
          </aside>
          <div className={styles.right}>
            <AlcoholLicenseList className="row" items={alcoholLicenses} />

            <AlcoholLicenseForm addresses={addresses} className={styles.alcoholLicense} />
          </div>
        </section>

        <section className={cn(styles.section, styles.logout)}>
          <Button variant="primary" onClick={onLogout}>{t("NAV.LOGOUT")}</Button>
        </section>
      </Wrapper>

      <Helmet title={t("ACCOUNT.HEADING")} />
    </div>
  );
};

/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type {
  InitRequest,
  QueueUpdates,
  ItemsResponse,
  CurrentItem,
} from "../state/quote-items";
import {
  quoteItems,
  addQuoteItem as addItemMutation,
  removeQuoteItem as removeItemMutation,
  updateQuoteItemQty as updateItemMutation,
} from "../queries";
import {
  INIT_REQUEST,
  QUEUE_UPDATES,
  ITEMS_RESPONSE,
  ITEMS_CLEAR,
} from "../state/quote-items";

import { EVENT_CART_MODIFY } from "../state/events";
import { debounce } from "../helpers";

import { updateQuote } from "../state/quote";
import { updateNone } from "crustate";

type Item = {
  // False means the item is marked as deleted, and any existing information
  // about the item row should NOT be reused.
  current: ?CurrentItem | false,
  requested: number,
};
type ItemMap = { [buyRequest: string]: Item };

type EmptyPromise = Promise<typeof undefined>;

type ResolveFn = () => void;
type RejectFn = (error: Error) => void;
type PendingPromises = Array<{ resolve: ResolveFn, reject: RejectFn }>;

const itemsToList = (
  itemMap: ItemMap
): { [buyRequest: string]: ?CurrentItem } => {
  const items = {};

  for (const buyRequest in itemMap) {
    const item = itemMap[buyRequest];

    items[buyRequest] = item.current ? item.current : null;
  }

  return items;
};

const itemsToUpdate = (
  itemMap: ItemMap
): Array<{ itemBuyRequest: string, qty: number }> => {
  const items = [];

  for (const buyRequest in itemMap) {
    const { current, requested } = itemMap[buyRequest];

    if (current) {
      const { qty, itemBuyRequest } = current;

      if (qty !== requested) {
        items.push({
          itemBuyRequest,
          qty: requested,
        });
      }
    }
  }

  return items;
};

const itemsToAdd = (
  itemMap: ItemMap
): Array<{ buyRequest: string, qty: number }> => {
  const items = [];

  for (const buyRequest in itemMap) {
    const { current, requested } = itemMap[buyRequest];

    if (!current && requested > 0) {
      items.push({
        buyRequest,
        qty: requested,
      });
    }
  }

  return items;
};

const itemsToDelete = (itemMap: ItemMap): Array<string> => {
  const items = [];

  for (const buyRequest in itemMap) {
    const { current, requested } = itemMap[buyRequest];

    if (current && requested < 1) {
      items.push(current.itemBuyRequest);

      // Mark the item as dead to not issue updates to it
      itemMap[buyRequest].current = false;
    }
  }

  return items;
};

const registerClient = (storage: Storage, client: Client<{}>) => {
  let items: ItemMap = {};
  let pending: PendingPromises = [];
  let inflightPromise: ?EmptyPromise;
  let inflight: PendingPromises = [];

  const doUpdate = (): EmptyPromise => {
    if (inflight.length > 0) {
      throw new Error("Unexpected inflight items");
    }

    const p = new Promise((resolve, reject) => {
      if (pending.length === 0) {
        inflightPromise = null;

        return resolve();
      }

      inflight = pending;
      pending = [];

      const todo = [];

      for (const { buyRequest, qty } of itemsToAdd(items)) {
        todo.push(client(addItemMutation, { buyRequest, qty }));
      }

      for (const { itemBuyRequest, qty } of itemsToUpdate(items)) {
        todo.push(client(updateItemMutation, { itemBuyRequest, qty }));
      }

      for (const itemBuyRequest of itemsToDelete(items)) {
        todo.push(client(removeItemMutation, { itemBuyRequest }));
      }

      if (todo.length === 0) {
        inflightPromise = null;
        inflight = [];

        return resolve();
      }

      // TODO: Add sets shipping mutations and similar

      resolve(
        Promise.all(todo).then(fetchUpdate, fetchUpdate).then(debouncedDoUpdate)
      );
    });

    return p;
  };

  let debouncedDoUpdate = debounce(doUpdate, 250);

  const cleanupItems = (): void => {
    // Cleanup of deleted items
    for (const buyRequest in items) {
      const { current, requested } = items[buyRequest];

      if (current == null && requested === 0) {
        delete items[buyRequest];
      }
    }
  };

  const queueUpdate = (): EmptyPromise => {
    const p = new Promise((resolve, reject) => {
      pending.push({ resolve, reject });
    });

    if (!inflightPromise) {
      inflightPromise = debouncedDoUpdate();
    } else {
      debouncedDoUpdate();
    }

    return p;
  };

  const fetchUpdate = (): EmptyPromise => {
    return client(quoteItems).then(
      ({ quote }): void => {
        // TODO: Simplify
        for (const item of quote.items) {
          const {
            itemBuyRequest,
            qty,
            product: { buyRequest },
          } = item;

          if (items[buyRequest]) {
            items[buyRequest].current = {
              itemBuyRequest,
              qty,
            };
          } else {
            items[buyRequest] = {
              current: {
                itemBuyRequest,
                qty,
              },
              requested: qty,
            };
          }
        }

        cleanupItems();

        for (const { resolve } of inflight) {
          resolve();
        }

        inflight = [];

        storage.broadcastMessage(updateQuote(quote));
      },
      (error: Error): void => {
        console.log("ERROR", error);

        cleanupItems();

        for (const { reject } of inflight) {
          reject(error);
        }

        inflight = [];
      }
    );
  };

  storage.addEffect({
    effect: async (msg: InitRequest) => {
      inflightPromise = fetchUpdate().then(doUpdate);

      await inflightPromise;

      return ({
        tag: ITEMS_RESPONSE,
        items: itemsToList(items),
      }: ItemsResponse);
    },
    subscribe: { [INIT_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QueueUpdates) => {
      for (const key in msg.items) {
        const newItem = msg.items[key];
        const currentCurrent = items[key] ? items[key].current : null;

        items[key] = {
          requested: newItem.requested,
          current: currentCurrent == null ? newItem.current : currentCurrent,
        };
      }

      await queueUpdate();

      storage.broadcastMessage({
        tag: EVENT_CART_MODIFY,
        items: itemsToList(items),
      });

      return ({
        tag: ITEMS_RESPONSE,
        items: itemsToList(items),
      }: ItemsResponse);
    },
    subscribe: { [QUEUE_UPDATES]: true },
  });

  storage.addEffect({
    effect: async (msg: any) => {
      items = {};
    },
    subscribe: { [ITEMS_CLEAR]: true },
  });
};

export default registerClient;

/* @flow */

import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

import styles from "./styles.scss";

type EditFieldProps = {
  children?: React$Node,
  label?: string,
  value?: string,
  cta?: string,
  to?: string,
  title?: string,
  className?: string,
};

export const EditField = ({
  children,
  label,
  value,
  cta,
  to,
  title,
  className,
}: EditFieldProps): React$Node => (
  <Link to={to || ""} className={cn(styles.field, className)} title={title}>
    {children !== undefined ? (
      children
    ) : (
      <div className={styles.field__content}>
        <div className={styles.field__input}>
          <span className={styles.field__key}>{label}</span>
          <span className={styles.field__value}>{value}</span>
        </div>
        {cta && <span className={styles.field__cta}>{cta}</span>}
      </div>
    )}
  </Link>
);

/* @flow */

import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { Spinner } from "../Spinner";

import styles from "./styles.scss";

export type Props = {
  className?: string,
  children: React$Node,
  variant?: string,
  size?: "mini" | "small" | "medium" | "large",
  loading?: boolean,
  disabled?: boolean,

  slotLeft?: React$Node,
  slotRight?: React$Node,

  to?: string | {},
  href?: string,
  elem?: string,

  ariaLabel?: string,
};

export const Button = ({
  loading = false,
  disabled = false,
  size = "medium",
  variant,
  slotLeft,
  slotRight,
  className,
  ...props
}: Props): React$Node => {
  const Elem = props.elem || (props.to ? Link : props.href ? "a" : "button");
  const spinnerVariant = ["ghost", "blank"].includes(variant) ? "primary" : "";
  const modArr = variant ? variant.split(" ") : [];
  const spinnerSize = modArr.includes("blank") ? "small" : "";

  return (
    <Elem
      {...props}
      disabled={disabled || loading}
      aria-label={props.ariaLabel}
      className={cn(
        "no-underline",
        "button",
        { button__loading: loading },
        { [`button_size__${size}`]: Boolean(size) },
        className,
        ...modArr.map((m) => `button__${m}`),
      )}
    >
      {Boolean(slotLeft) && (
        <div className={styles.slotLeft}>
          <div className={styles.slot}>{slotLeft}</div>
        </div>
      )}

      {props.children}
      {loading && (
        <Spinner variant={spinnerVariant} className={cn("button__spinner", spinnerSize)} />
      )}

      {Boolean(slotRight) && (
        <div className={styles.slotRight}>
          <div className={styles.slot}>{slotRight}</div>
        </div>
      )}
    </Elem>
  );
};

/* @flow */

import React, { useEffect, useState, useRef } from "react";
import { useClient } from "../entrypoint/shared";
import { isLoggedIn } from "../queries";
import { logout } from "../state/customer";
import { useSendMessage } from "crustate/react";
import { addMessage, clearMessages } from "../state/messages";
import { useHistory } from "react-router-dom";
import { useCustomer } from "./use-customer";

const TIMER = 30000;

export const useRedirectCustomerOnLostSession = (): boolean => {
  const client = useClient();
  const sendMessage = useSendMessage();
  const { push } = useHistory();
  const { loggedIn } = useCustomer();
  const interval = useRef();

  // Check if users still logged in each 30 seconds
  useEffect(() => {
    interval.current = setInterval(() => {
      if (loggedIn) {
        client(isLoggedIn).then((x) => {
          if (!Boolean(x.customer) && loggedIn) {
            sendMessage(clearMessages());
            sendMessage(addMessage("USER_NOT_LOGGED_IN_ANYMORE", "error"));
            sendMessage(logout());
            push("/");
          }
        });
      }
    }, TIMER);

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  });

  return loggedIn;
};

/* @flow */

import type { Translations } from "@out-of-home/use-translate";
import type { StatePath, Message } from "crustate";

// import "core-js/features/object/assign";
// import "core-js/features/map";

// import _settings from "../theme/_settings.scss";
import _normalize from "../theme/generic/_normalize.scss";
import _base from "../theme/generic/_base.scss";
import _typography from "../theme/generic/_typography.scss";
import _cms from "../theme/generic/_cms.scss";
// import _helpers from "../theme/generic/_helpers.scss";
// import _rows from "../theme/objects/_rows.scss";

// We need to initialize this early before we import any of the fetch wrappers
// or other resources to ensure RUM installs correctly:
import { datadogLogs } from "@datadog/browser-logs";
import { datadog } from "../config";

if (datadog) {
  datadogLogs.init(datadog);
}

import React from "react";
import { hydrate } from "react-dom";
import { APP_KEY, createRunQuery } from "./shared";
import { createBrowserHistory } from "history";
import translation from "translation";
import whatInput from "what-input";
import { markHydrated } from "../helpers/use-browser";
import { rejectOnHttpError, withCookies } from "@out-of-home/fetch-utils";

import { watch } from "@out-of-home/dev-server";
import { registerClient } from "../effects";
import { ReactEntrypoint } from "./react-entrypoint";

import { Storage } from "crustate";
import { createClient } from "@awardit/graphql-ast-client";
import {
  MessagesData,
  StockMessagesData,
  RouteData,
  QuoteData,
  CustomerData,
  WishlistToggleData,
  TestimonialsData,
  BlogData,
  BlogListData,
  FacetsData,
  FilterData,
  BrandsData,
  FrontPageRecommendedData,
  AlcoholLicensesData,
  QuoteItemsData,
  PaymentMethodsData,
  MyProductsData,
  OrderData,
  WishlistData,
  DeliveryScheduleData,
  OverdueInvoicesData,
} from "../data";

const root = document.getElementById(APP_KEY);

if (!root) {
  throw new Error("Missing app root");
}

const history = createBrowserHistory({ basename: "/" });
const storage = new Storage();

if (window.snapshot) {
  storage.addModel(MessagesData.model);
  storage.addModel(StockMessagesData.model);
  storage.addModel(RouteData.model);
  storage.addModel(QuoteData.model);
  storage.addModel(CustomerData.model);
  storage.addModel(WishlistToggleData.model);
  storage.addModel(TestimonialsData.model);
  storage.addModel(BlogData.model);
  storage.addModel(BlogListData.model);
  storage.addModel(FacetsData.model);
  storage.addModel(FilterData.model);
  storage.addModel(BrandsData.model);
  storage.addModel(FrontPageRecommendedData.model);
  storage.addModel(AlcoholLicensesData.model);
  storage.addModel(QuoteItemsData.model);
  storage.addModel(PaymentMethodsData.model);
  storage.addModel(MyProductsData.model);
  storage.addModel(OrderData.model);
  storage.addModel(WishlistData.model);
  storage.addModel(DeliveryScheduleData.model);
  storage.addModel(OverdueInvoicesData.model);

  storage.restoreSnapshot(window.snapshot);

  delete window.snapshot;
}

const translations: {} = typeof translation === "object" && translation !== null ? translation : {};

const client = createClient({
  runQuery: createRunQuery(fetch, ""),
  debounce: 5,
});

const restClient = rejectOnHttpError(withCookies(fetch));

registerClient(storage, client, restClient, history, translations);

window.storage = storage;
// window.getState = storage.getSnapshot.bind(storage);

const events = {
  unhandledMessage: "warn",
  // stateCreated: "info",
  // stateRemoved: "info",
  // stateNewData: "info",
  // snapshotRestore: "info",
  // messageQueued: "info",
  // messageMatched: "debug",
  // snapshotRestored: "debug",
};

Object.keys(events).forEach((eventName) => {
  const level = events[eventName];
  storage.addListener((eventName: any), (...data) => console[level](eventName, ...data));
});

// Boot up watch-process in dev mode to make sure we reload
if (process.env.NODE_ENV !== "production") {
  watch();
}

if (datadog) {
  storage.addListener("updateError", (e: mixed, stateData: mixed, statePath: StatePath, message: Message) =>
    datadogLogs.logger.error("crustate - updateError", { statePath, message }, e),
  );

  storage.addListener("unhandledMessage", (message: Message, statePath: StatePath) =>
    datadogLogs.logger.error(
      `Unhandled message '${message.tag}' from ${statePath.join(", ")}`,
      {
        statePath,
        message,
      },
      new Error(`Unhandled message '${message.tag}' from ${statePath.join(", ")}`),
    ),
  );
}

// startGa();

hydrate(
  <ReactEntrypoint
    isNode={false}
    shop={window.shop}
    storage={storage}
    client={client}
    restClient={restClient}
    now={new Date(window.shop.info.now)}
    translations={translations}
    history={history}
  />,
  root,
  () => {
    markHydrated();
  },
);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker?.register("/serviceworker.js");
}

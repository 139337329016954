/** @flow */

import type { ProductDetailAbstract } from "../../types/product.flow";

import React, { useMemo } from "react";
import cn from "classnames";
import { Helmet } from "react-helmet-async";
import { buildJSONLDBreadcrumbLists, getBreadcrumbParts } from "../../helpers/breadcrumbs";
import { Button } from "../Button";
import styles from "./styles.scss";
import type { BreadcrumbProduct } from "../../helpers/breadcrumbs";

type Props = {|
  product: $ReadOnly<BreadcrumbProduct>,
  className?: string
|};

export const Breadcrumbs = ({ product, className }: Props): React$Node => {
  const breadcrumbs = useMemo(() => {
    const jsonList = buildJSONLDBreadcrumbLists(product);
    const parts = jsonList.length > 0 ? getBreadcrumbParts(jsonList[0]) : [];

    return {
      jsonList,
      parts,
    };
  }, [product]);

  if (!breadcrumbs.jsonList.length || breadcrumbs.parts.length < 2) {
    return null;
  }

  return (
    <div className={cn(styles.block, className)}>
      <Helmet
        script={[
          {
            type: "application/ld+json",
            innerHTML: JSON.stringify(breadcrumbs.jsonList),
          },
        ]}
      />

      <ul className={styles.list}>
        {breadcrumbs.parts.map((x, idx) => {
          if (x.type === "link") {
            return (
              <li key={`${x.text}_${idx}`}>
                <Button to={x.url}>{x.text}</Button>
                <span>/</span>
              </li>
            );
          }

          return <li key={`${x.text}_${idx}`}>{x.text}</li>;
        })}
      </ul>
    </div>
  );
};

/* @flow  */

import styles from "../components/ShippingMethods/styles.scss";

const breakpointMedium = parseInt(styles.breakpointMedium, 10);
const breakpointSmall = parseInt(styles.breakpointSmall, 10);
const breakpointTiny = parseInt(styles.breakpointTiny, 10);

/**
 * Internal function to validate the checksum.
 * Insipred by https://github.com/bendrucker/fast-luhn
 */
function validateChecksum(number: string) {
  const array = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
  let length = number.length;
  let bit = 1;
  let sum = 0;
  let value;

  while (length) {
    value = parseInt(number.charAt(--length), 10);
    bit ^= 1;
    sum += bit ? array[value] : value;
  }

  return sum % 10 === 0;
}

export const isOrganizationNumber = (nr: string): boolean => {
  if (!nr) {
    return false;
  }

  // Strip non-digits
  nr = nr.replace(/[^\d]/g, "");

  return (
    // Length must be 10 (without dash)
    nr.length === 10 &&
    // Third digit must be at least 2
    parseInt(nr.slice(2, 3)) >= 2 &&
    // Validate the last check digit
    validateChecksum(nr)
  );
};

export const isSSN = (nr: string): boolean => {
  if (!nr) {
    return false;
  }

  // Strip non-digits
  nr = nr.replace(/[^\d]/g, "");

  return nr.length === 10 || nr.length === 12;
};

export const daySlotsToDisplay = (browserWidth: number): number => {
  const isMediumView =
    browserWidth > breakpointSmall && browserWidth < breakpointMedium;
  const isSmallView =
    browserWidth < breakpointSmall && browserWidth > breakpointTiny;
  const isTinyView = browserWidth < breakpointTiny && browserWidth > 0;

  if (isMediumView) {
    return 3;
  }

  if (isSmallView) {
    return 5;
  }

  if (isTinyView) {
    return 3;
  }

  return 5;
};

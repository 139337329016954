/* @flow */

import type { Node as ReactNode } from "react";
import type { Customer } from "../types/customer.flow";

import React, { useContext, createContext, useState, useEffect, useRef } from "react";
import { useData } from "crustate/react";
import { useCustomer } from "../helpers/use-customer";
import { useBrowser } from "../helpers/use-browser";
import { frontChatId } from "../config";
import { boot } from "../helpers/front";
import { CustomerData } from "../data";
import { usePrevious } from "../helpers";
import type { FrontChatType } from "../types/front-chat.flow";

export type TFrontChatContext = {
  open: () => Promise<void>,
  close: () => void,
  isOpen: boolean,
  loaded: boolean,
  loading: boolean,
  load: () => Promise<FrontChatType | null | void>,
  shutdown: () => void,
};

type FrontChatContextProviderProps = {
  children: ReactNode,
};

const FrontChatContext: React$Context<TFrontChatContext> = createContext<TFrontChatContext>({
  open: async () => undefined,
  close: () => undefined,
  isOpen: false,
  loaded: false,
  loading: false,
  load: async () => undefined,
  shutdown: () => undefined,
});

export const FrontChatContextProvider = ({ children }: FrontChatContextProviderProps): React$Node => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const frontChat = useRef<FrontChatType | null>(null);
  const customerIdentified = useRef<boolean>(false);

  const customerData = useData(CustomerData);
  const prevCustomerState = usePrevious(customerData.state);

  const load = async () => {
    return new Promise(async (resolve) => {
      if (!document.body) {
        resolve();
        return;
      }

      const body = document.body;

      if (!loaded && !loading) {
        setLoading(true);

        const fc = await boot(body);

        const onInitCompleted = () => {
          setLoaded(true);
          setLoading(false);
          frontChat.current = fc;

          fc("onWindowVisibilityChanged", ({ is_window_visible }) => {
            setIsOpen(is_window_visible === 1);
          });

          resolve();
        };

        if (customerData.data && !customerIdentified.current && customerData.data.frontUserHash) {
          const customer = customerData.data;
          fc("init", {
            chatId: frontChatId,
            email: customer.email,
            name: `${customer.firstname} ${customer.lastname}`,
            userHash: customer.frontUserHash,
            onInitCompleted,
            useDefaultLauncher: false,
          });

          customerIdentified.current = true;
        } else {
          fc("init", {
            chatId: frontChatId,
            onInitCompleted,
            useDefaultLauncher: false,
          });
        }
      } else {
        resolve();
      }
    });
  };

  const open = async () => {
    if (!loaded && !loading) {
      await load();
    }

    if (!frontChat.current) {
      return;
    }

    frontChat.current("show");
    setIsOpen(true);
  };

  const shutdown = () => {
    if (loaded) {
      if (frontChat.current) {
        frontChat.current("shutdown", { clearSession: true });
        frontChat.current = null;
      }
    }
  }

  const close = () => {
    if (loaded) {
      if (frontChat.current) {
        frontChat.current("hide");
      }
    }
  }

  // reset FrontChat when logged out
  useEffect(() => {
    if (prevCustomerState === "LOGGING_OUT" && customerData.state === "NOT_LOGGED_IN") {
      setLoading(false);
      setLoaded(false);
      customerIdentified.current = false;

      if (frontChat.current) {
        frontChat.current("shutdown", { clearSession: true });
        frontChat.current = null;
      }
    }
  }, [customerData.state]);

  useEffect(() => {
    if (frontChat.current && !customerIdentified.current && customerData.data && customerData.data.frontUserHash) {
      const customer = customerData.data;

      frontChat.current("identity", {
        email: customer.email,
        name: `${customer.firstname} ${customer.lastname}`,
        userHash: customer.frontUserHash,
      });

      customerIdentified.current = true;
    }
  }, [customerData]);

  return <FrontChatContext.Provider value={{ open, loaded, loading, load, shutdown, isOpen, close }}>{children}</FrontChatContext.Provider>;
};

export const useFrontChatContext = (): TFrontChatContext => {
  const context = useContext<TFrontChatContext>(FrontChatContext);

  if (!context) {
    throw new Error("useFrontChatContext must be used within a FrontChatContextProvider");
  }

  return context;
};

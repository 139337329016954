/* @flow */

import type { SetQuoteAddressInput } from "../../../../types/quote.flow";
import type { CustomerAddress, CustomerAddressInput } from "../../../../types/customer.flow";

import React, { useState } from "react";
import styles from "./styles.scss";
import { useTranslate } from "@out-of-home/use-translate";
import { Button } from "../../../Button";
import { Field } from "../../../Field";
import { isPostcode } from "../../../../helpers/validationRules";
import { focusInvalidField } from "../../../../helpers/formHelpers";

import { nestedRule } from "../../../../helpers/validationRules";

import { Form, rules, isRequired } from "@awardit/formaggio";

type Props = {
  address: CustomerAddress | { ...CustomerAddress, id: string } | "new",
  isProcessing: boolean,
  onSubmit: ({ ...SetQuoteAddressInput, id?: string }) => void,
};

export const customerAddressToCustomerAddressInput = (
  address: CustomerAddress,
): CustomerAddressInput | { ...CustomerAddressInput, id: string } => {
  const { country, isDefaultBilling, isDefaultShipping, ...rest } = address;

  return {
    ...rest,
    countryCode: country.code,
  };
};

const validations = rules([
  isRequired("firstname"),
  isRequired("lastname"),
  isRequired("company"),
  nestedRule("street", rules([isRequired("0")])),
  isRequired("postcode"),
  isPostcode("postcode"),
  isRequired("city"),
  isRequired("telephone"),
]);

export const AddressFields = (props: Props): React$Node => {
  const t = useTranslate();
  const [state, setState] = useState<any>(
    typeof props.address !== "string"
      ? customerAddressToCustomerAddressInput(props.address)
      : {
          firstname: "",
          lastname: "",
          company: "",
          telephone: "",
          street: [],
          postcode: "",
          countryCode: "SE",
          city: "",
        },
  );
  const errors = validations((state: any));

  const submit = (e) => {
    e.preventDefault();
    props.onSubmit({
      ...state,
      postcode: state.postcode.replaceAll(" ", ""),
    });
  };

  return (
    <Form
      value={(state: any)}
      onChange={(x: any) => setState(x)}
      onSubmit={submit}
      errors={errors}
      onError={focusInvalidField}
    >
      <div className={styles.row}>
        <Field
          name="firstname"
          label={t("ADDRESS.FIRSTNAME")}
          autoComplete="given-name"
          variant="box"
        />

        <Field
          name="lastname"
          label={t("ADDRESS.LASTNAME")}
          autoComplete="family-name"
          variant="box"
        />
      </div>

      <div className={styles.row}>
        <Field
          name="company"
          label={t("ADDRESS.COMPANY")}
          autoComplete="organization"
          variant="box"
        />

        <Field
          name="street.0"
          label={t("ADDRESS.STREET")}
          autoComplete="address-line1"
          variant="box"
        />
      </div>

      <div className={styles.row}>
        <Field
          name="postcode"
          label={t("ADDRESS.POSTCODE")}
          autoComplete="postal-code"
          variant="box"
        />

        <Field name="city" label={t("ADDRESS.CITY")} autoComplete="city" variant="box" />
      </div>

      <div className={styles.row}>
        <Field name="telephone" label={t("ADDRESS.TELEPHONE")} autoComplete="tel" variant="box" />

        <Field
          name="street.1"
          label={t("ADDRESS.STREET_2")}
          autoComplete="address-line2"
          variant="box"
        />
      </div>

      <div className={styles.row}>
        <div />
        <div>
          <Button
            className={styles.submit}
            type="submit"
            variant="primary"
            disabled={props.isProcessing}
            loading={props.isProcessing}
          >
            Bekräfta
          </Button>
        </div>
      </div>
    </Form>
  );
};

/* @flow */

import type { Product, ProductCardProduct, ProductCategory } from "../../types/product.flow";
import type { FrontPageBlock as FrontPageBlockType } from "../../types/my-front-page.flow";

import React, { useState, useEffect, useContext, useMemo, useRef, memo } from "react";
import { useData, useSendMessage } from "crustate/react";
import { useLocation, Link, useHistory } from "react-router-dom";
import cn from "classnames";
import { Helmet } from "react-helmet-async";
import { StoreInfoContext, useRestClient } from "../../entrypoint/shared";
import { useTranslate } from "@out-of-home/use-translate";
import { FacetsData, FilterData } from "../../data";
import { Wrapper } from "../Wrapper";
import { PaginatedProductList } from "../ProductList";
import { Filters } from "../Filters";
import { SortMenu } from "../SortMenu";
import { OffCanvasMenu } from "../OffCanvasMenu";
// import { InstagramFeed } from "../InstagramFeed";
import Instagram from "../Instagram";
import { NoResultsSearchForm } from "../NoResultsSearchForm";
import { DisplayModeSwitcher } from "../DisplayModeSwitcher";
import { ProductCard } from "../ProductCard";
import breakpoints from "../../helpers/breakpoints";
import { blocksToUIRows, infoBlockToUIRow, rowKey, removeDuplicates } from "../../helpers/myfrontpageHelpers";
import { FrontPageList } from "../FrontPageBlock/frontPageList";
import { Spinner } from "../Spinner";
import styles from "./styles.scss";
import {
  locationToQuery,
  queryToLocation,
  filterTitle,
  filterMetaDescription,
  generateCanonical,
} from "../../helpers/filterHelpers";
import { range, restProductToGQLProduct } from "../../helpers";
import { UIContext } from "../../context/ui";
import { load as loadFilter } from "../../state/filter";
import { getFilterMeta, metaToHelmetProps } from "../../helpers/get-meta";
import { ToTop } from "../ToTop";
import { parseParams, stringifyParams } from "@out-of-home/location-search-string";
import { deepEquals } from "@out-of-home/diskho";
import { FrontPageBlock } from "../FrontPageBlock";
import { filterToggleURL } from "../../helpers/filterHelpers";
import { Excerpt } from "../Excerpt";
import { useBrowser } from "../../helpers/use-browser";
import { PAGE_SIZE } from "../../effects/blog";

//const MIN_SUGGESTION_SCORE = 0.8;
const MIN_SUGGESTION_SCORE = 0;

const getNumItemsPerRow = (x) => {
  if (x > 1311) return 4;
  if (x > 1056) return 3;
  if (x > 801) return 2;

  return 1;
};

export const getPageSize = (x: number): number => {
  const a = getNumItemsPerRow(x);

  // Prevent odd numbers
  if (a === 3) {
    return 99;
  }

  return 100;
};

export const FilterView = (): React$Node => {
  const [allProducts, setAllProducts] = useState([]);
  const [allProductsLoaded, setAllProductsLoaded] = useState(false);
  const restClient = useRestClient();
  const t = useTranslate();
  const { replace } = useHistory();
  const sendMessage = useSendMessage();
  const facetsData = useData(FacetsData);
  const filterData = useData(FilterData);
  const browser = useBrowser();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const { translations } = useContext(StoreInfoContext);
  const { browserWidth, sortModalOpen, filterModalOpen, setSortModalOpen, setFilterModalOpen } =
    useContext(UIContext);
  const query = filterData.data.query || locationToQuery(location, translations.ROUTES);
  const { infoBlock } = facetsData;
  const isCompactView = browserWidth < parseInt(styles.breakpointSmall, 10);
  const allowSplit = !browserWidth || browserWidth >= breakpoints.large;
  const facetsLoading = facetsData.state === "LOADING";
  const productsLoading = filterData.state === "LOADING";
  const pages = filterData.data.pages;
  const emptySearch = Boolean(query.search && filterData.data.total === 0);
  const queryIsPristine =
    !Object.keys(query.filter || {}).length &&
    !query.search &&
    !query.customerType &&
    !query.product;
  const boundFilterToggleURL = (facet: string, bucket: string) =>
    filterToggleURL(query, facet, bucket, translations.ROUTES);
  const infoBlockRow = infoBlockToUIRow(infoBlock);

  const staticBlockRows = blocksToUIRows(allProducts);

  useEffect(() => {
    if (queryIsPristine && !allProducts.length) {
      // First call
      restClient(`/api/allProducts?page=1`).then(x => x.json()).then(x => {
        const BLOCK_PAGE_SIZE = 4;
        const pages = Math.ceil(x.total_count / BLOCK_PAGE_SIZE);
        setAllProducts(x.blocks.map(x => ({
          ...x,
          type: "PRODUCTS_FILTER",
          products: x.products.map(restProductToGQLProduct),
        })));

        const promises = range(2, pages).map(x => restClient(`/api/allProducts?page=${x}`));

        Promise.all(promises)
          .then(x => Promise.all(x.map(x => x.json())))
          .then(resps => {
            const data = resps.flatMap(resp => {
              return resp.blocks.map(x => ({
                ...x,
                type: "PRODUCTS_FILTER",
                products: x.products.map(restProductToGQLProduct),
              }));
            });

            setAllProducts(old => {
              const oldTitles = old.map(rowKey);
              const dataFiltered = data.filter(x => !oldTitles.includes(x.title));
              return [...old, ...removeDuplicates(dataFiltered)]
            });
          })
      }).then(() => {
        setAllProductsLoaded(true);
      });
    }
  }, [queryIsPristine, allProducts]);

  const suggestion = filterData.data?.suggestions[0]?.options[0];

  // @TODO: check that page is not changed. I'm going to remove it from the location search anyway
  if (emptySearch) {
    return (
      <Wrapper>
        <h1 className={styles.heading}>{t("SEARCH.NO_RESULTS", { search: query.search })}</h1>

        {suggestion &&
          <div>
            <p>Menade du <Link to={`/p?search=${suggestion.text}`}>{suggestion.text}</Link>?</p>
          </div>
        }

        <section className={styles.tip}>
          <h2>{t("PRODUCT_TIP_FORM.TITLE")}</h2>
          <NoResultsSearchForm />
        </section>
      </Wrapper>
    );
  }

  const generatedTitles = filterTitle(t, query, facetsData.facets || [], facetsData.product?.name);
  const title = generatedTitles[0];
  const subTitle = generatedTitles[1];
  let pathname = location.pathname;
  if (pathname[0] === "/") {
    pathname = pathname.substr(1);
  }

  const canonical = generateCanonical(pathname, query, facetsData.facets || [], translations.ROUTES);

  const meta = getFilterMeta(title, subTitle, query, infoBlock, t("FILTER.DESCRIPTION"), canonical);
  const metaDescription = meta.data[meta.data.findIndex((x) => x.name === "description")];

  // Mutate the description in the meta object
  if (metaDescription) {
    metaDescription.content = filterMetaDescription(t, query, meta);
  }

  // Handle fetching of new pages and updating URL when scrolling down
  const fetchMore = ({ startIndex, stopIndex }) => {
    // const page = (startIndex / PAGE_SIZE) + 1;
    const page = currentPage + 1;
    setCurrentPage(currentPage + 1);

    sendMessage(loadFilter(location, [Math.floor(page)]));
  };

  return (
    <div>
      <Wrapper>
        <div className={styles.block}>
          <div className={styles.main}>
            <h1 className={styles.heading}>{title}</h1>
            {(suggestion && suggestion.score >= MIN_SUGGESTION_SCORE) &&
              <div>
                <p>Menade du <Link to={`/p?search=${suggestion.text}`}>{suggestion.text}</Link>?</p>
              </div>
            }
            {!queryIsPristine && !isCompactView && !query.customerType && !query.product && (
              <header className={styles.controls}>
                {queryIsPristine ? <div /> : <SortMenu query={query} layout="LARGE" />}
                <DisplayModeSwitcher className={styles.displayModeSwitcher} />
              </header>
            )}

            {infoBlock && (
              <div className={styles.infoBlock}>
                {infoBlock.images.large && (
                  <img
                    className={styles.infoBlockImage}
                    src={infoBlock.images.large.url}
                    alt={infoBlock.description}
                  />
                )}
                <div className={styles.infoBlockRow}>
                  {infoBlock.description && (
                    <div
                      className={cn(styles.infoBlockDescription, "typography")}
                      dangerouslySetInnerHTML={{
                        __html: infoBlock.description,
                      }}
                    />
                  )}
                  {infoBlock.images.logo && (
                    <img
                      className={styles.infoBlockLogo}
                      src={infoBlock.images.logo.url}
                      alt={infoBlock.description}
                    />
                  )}
                </div>
              </div>
            )}

            {!queryIsPristine && infoBlockRow &&
              <div className="row row--large">
                <FrontPageList
                  rows={[infoBlockRow]}
                  listName="all_products_infoblock"
                />
              </div>
            }

            {queryIsPristine && (productsLoading || staticBlockRows.length > 0) &&
              <div className="row row--large">
                <FrontPageList
                  loading={queryIsPristine && !allProductsLoaded}
                  rows={staticBlockRows}
                  listName="all_products_static_blocks"
                />
              </div>
            }

            {(queryIsPristine && !allProductsLoaded) &&
              <Spinner variant="large" />
            }

            <Instagram infoBlock={infoBlock} />

            {!queryIsPristine && (
              <PaginatedProductList
                listName="all_products"
                isFetching={productsLoading}
                fetchMore={fetchMore}
                totalCount={filterData.data.total}
                products={pages.flatMap((x) => x.items).map(restProductToGQLProduct)}
              />
            )}
          </div>
          <div className={styles.sidebar}>
            <Filters query={query} facets={facetsData.facets} />
          </div>
        </div>

        <ToTop
          facet={
            !isCompactView && queryIsPristine && facetsData.facets.length > 0
              ? facetsData.facets[0]
              : null
          }
          filterToggleURL={(facet, bucketKey) => boundFilterToggleURL(facet, bucketKey)}
        />
      </Wrapper>

      {isCompactView && (
        <OffCanvasMenu setOpen={setFilterModalOpen} open={filterModalOpen}>
          <Filters
            layout={"SMALL"}
            location={location}
            query={query}
            facets={facetsData.facets}
            loading={facetsLoading}
          />
        </OffCanvasMenu>
      )}

      {isCompactView && !query.customerType && !query.product && (
        <OffCanvasMenu setOpen={setSortModalOpen} open={sortModalOpen}>
          <SortMenu loading={productsLoading} query={query} layout="SMALL" />
        </OffCanvasMenu>
      )}

      <Helmet title={meta.title}>
        {metaToHelmetProps(t, "meta", meta.data)}
        {metaToHelmetProps(t, "link", meta.link)}
        {infoBlock?.primaryColor && (
          <html
            data-color-primary-override="true"
            style={`--color-primary: ${infoBlock.primaryColor}`}
          />
        )}
      </Helmet>
    </div>
  );
};

/* @flow */

import type { Validator } from "@awardit/formaggio";

import React, { useRef, useEffect, useState, useContext } from "react";
import cn from "classnames";
import { QuoteData, AlcoholLicensesData, useLicenseByID } from "../../../data";
import { Link, NavLink, useParams, useHistory } from "react-router-dom";
import { TimeContext } from "../../../context/time";
import { useTranslate } from "@out-of-home/use-translate";
import { Button } from "../../Button";
import { Wrapper } from "../../Wrapper";
import { addressLabel } from "../../Address";
import { useData, useSendMessage } from "crustate/react";
import SystemMessages from "../../SystemMessages";
import { focusInvalidField } from "../../../helpers/formHelpers";
import { AlcoholLicenseFieldset } from "../../AlcoholLicenseFieldset";
import { useCustomer } from "../../../helpers/use-customer";
import { remove } from "../../../state/alcohol-licenses";
import { useFormat } from "../../../helpers/use-format";
import { AlcoholLicenseStatus } from "../../AlcoholLicenseStatus";
import styles from "./styles.scss";
import { NotFoundView } from "../../NotFoundView";

type Props = {
  className?: string,
};

export const AlcoholLicenseDetailView = ({ className }: Props): React$Node => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const { id } = useParams();
  const { push } = useHistory();
  const { formatDate } = useFormat();
  const license = useLicenseByID(id);
  const { customer } = useCustomer();
  const processing = useData(AlcoholLicensesData).state === "REMOVING";

  const onRemove = () => {
    sendMessage(remove(id)).then(() => {
      push("/" + t("ROUTES.ACCOUNT"));
    });
  };

  if (!license) {
    return <NotFoundView />;
  }

  return (
    <div>
      <Wrapper variant="thin" className={styles.block}>
        <h3 className="text-color--muted">{t("ALCOHOL_LICENSE.TITLE")}</h3>

        <div className="row--huge">
          <span className={styles.field__label}>
            {t("ALCOHOL_LICENSE.STATUS_LABEL")}
          </span>
          <span className={styles.field__value}>
            <AlcoholLicenseStatus approvedAt={license.approvedAt} t={t} />
          </span>
        </div>

        <div className="row--huge">
          <span className={styles.field__label}>
            {t("ALCOHOL_LICENSE.ADDRESS_LABEL")}
          </span>
          <span className={styles.field__value}>
            {addressLabel(license.address)}
          </span>
        </div>

        <div className="row--huge">
          <span className={styles.field__label}>
            {t("ALCOHOL_LICENSE.VALID_TO_LABEL")}
          </span>
          <span className={styles.field__value}>
            {formatDate(license.validTo)}
          </span>
        </div>

        <div className="row--huge">
          <span className={styles.field__label}>
            {t("ALCOHOL_LICENSE.FILE_LABEL")}
          </span>
          <span className={styles.field__value}>{license.filename}</span>
        </div>

        <div className="row--large">
          <Button variant="ghost" to={"/" + t("ROUTES.ACCOUNT")}>
            {t("ALCOHOL_LICENSE.BACK")}
          </Button>
        </div>

        <div className="row row--huge">
          <h4>{t("ACCOUNT.MORE_ALTERNATIVES")}</h4>
          <span
            onClick={onRemove}
            className={cn(styles.link, { [styles.processing]: processing })}
          >
            {processing
              ? t("ALCOHOL_LICENSE.DELETING")
              : t("ALCOHOL_LICENSE.DELETE")}
          </span>
        </div>
      </Wrapper>
    </div>
  );
};

/* @flow */

import type { Quote, QuoteItem as TQuoteItem } from "../../../types/quote.flow";

import React, { useState, useEffect, useContext } from "react";
import styles from "./styles.scss";
import cn from "classnames";
import { useSendMessage } from "crustate/react";
import { useTranslate } from "@out-of-home/use-translate";
import { useCustomer } from "../../../helpers/use-customer";
import { Wrapper } from "../../Wrapper";
import { ProductCard } from "../../ProductCard";
import { Button } from "../../Button";
import { CartSummary } from "../../CartSummary";
import { DiscountCode } from "../../DiscountCode";
import { removeByItemBuyRequest, setItemQty } from "../../../state/quote-items";
import { UIContext } from "../../../context/ui";
import { QuoteItem } from "../../QuoteItem";
import StockMessages from "../../StockMessages";
import { useBrowser } from "../../../helpers/use-browser";
import { AnalyticsContext } from "../../../helpers/analytics/context";

type Props = {
  quote: Quote,
  className?: string,
};

export const CheckoutCart = ({ quote, className }: Props): React$Node => {
  const browser = useBrowser();
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const { items } = quote;
  const qty = quote.items.reduce((a, c) => (a += c.qty), 0);
  const { customer, state } = useCustomer();
  const { browserWidth } = useContext(UIContext);
  const { registerModifyCart } = useContext(AnalyticsContext);
  const useSmallView =
    browserWidth > 0 && browserWidth < parseInt(styles.breakpointSmall, 10);

  if (!browser) {
    return null;
  }

  if (useSmallView) {
    return (
      <div className={cn(styles.block, className)}>
        <Wrapper className={styles.wrapper}>
          <h2>Varukorg</h2>
          <DiscountCode coupon={quote.coupon} />
        </Wrapper>
        {items.map((x) => (
          <ProductCard
            key={x.itemBuyRequest}
            displayMode="COMPACT"
            className={cn(styles.item, styles.item__compact)}
            product={x.product}
            price={x.rowTotal}
            qty={x.qty}
            listName={"checkout_cart"}
          />
        ))}

        <CartSummary className={styles.cartSummary} quote={quote} />
      </div>
    );
  }

  return (
    <Wrapper className={cn(styles.wrapper, className)}>
      <h2>Varukorg</h2>
      <DiscountCode coupon={quote.coupon} />
      <table className={styles.block}>
        <tbody>
          {items.map((x) => {
            const remove = () => {
              if (x.product.buyRequest) {
                sendMessage(setItemQty(x.product.buyRequest, 0));
              }
              else if (x.itemBuyRequest) {
                sendMessage(removeByItemBuyRequest(x.itemBuyRequest));
              }

              registerModifyCart([{ product: { ...x.product, qty: x.qty }}], "remove_from_cart", "checkout_cart");
            };

            return (
              <QuoteItem
                key={x.itemBuyRequest}
                className={styles.item}
                item={x}
                onRemove={remove}
                listName={"checkout_cart"}
              />
            );
          })}
        </tbody>
      </table>
      <CartSummary className={styles.cartSummary} quote={quote} />

      <StockMessages />
    </Wrapper>
  );
};

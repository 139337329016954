/* @flow */

import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import { useTranslate } from "@out-of-home/use-translate";
import cn from "classnames";
import { UIContext } from "../../../context/ui";
import styles from "./styles.scss";
import { Button } from "../../Button/index.js";
import TempIcon from "../../../icons/temp-gfx.svg";
import { loadScript } from "../../../helpers";
import { useIsomorphicLayoutEffect } from "../../../helpers/use-isomorphic-layout-effect";

type Props = {
  className?: string,
};

// Run this function after lottie.js is loaded
async function loadAnimation(lottie: any) {
  lottie.loadAnimation({
    container: document.getElementById("right-container"),
    autoplay: true,
    loop: true,
    renderer: "svg",
    path: "/assets/banner/data.json",
    rendererSettings: {
      filterSize: {
        width: "300%",
        height: "300%",
        x: "-100%",
        y: "-100%",
      },
    },
  });
}

export const Hero = ({ className }: Props): React$Node => {
  const t = useTranslate();
  const { setLoginModalOpen } = useContext(UIContext);

  useIsomorphicLayoutEffect(() => {
    loadScript("lottie-script", "/assets/banner/lottie.min.js", () => {
      loadAnimation(window.lottie);
    });
  }, []);

  return (
    <div className={cn(styles.block, className)}>
      <div className={styles.left}>
        <h2>
          Enklaste sättet för <br className={styles.br1} />
          företag att <br className={styles.br2} />
          handla <span>mat, dryck & sånt</span>
        </h2>
        <div className={styles.buttons}>
          <Button
            variant="ghost darkBg"
            onClick={() => {
              setLoginModalOpen(true);
            }}
          >
            Logga in
          </Button>
          <Button variant="primary darkBg" to="/registrera/1">
            Välj ditt pris
          </Button>
        </div>
      </div>
      <div className={styles.right} id="right-container">
        {/*<TempIcon className={styles.tmpGfx} />*/}
      </div>
    </div>
  );
};

/* @flow */

import React, { useContext, useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import { useTranslate } from "@out-of-home/use-translate";
import { useData } from "crustate/react";
import { FrontPageRecommendedData } from "../../data";
import { restProductToGQLProduct } from "../../helpers";
import { Excerpt } from "../Excerpt";
import { Pills } from "../Pills";
import { Wrapper } from "../Wrapper";
import styles from "./styles.scss";
import { Testimonials } from "../Testimonials";
import { Button } from "../Button";
import { StoreInfoContext } from "../../entrypoint/shared";

export const formatCountUp = (x: number): string => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

const productsByCompany = {
  "1": 4943, // Restaurang
  "2": 2341, // Café
  "4": 421, // Hotell/Konf
  "6": 1737, // Gym/Träning
  "7": 1498, // Dagligvaruhandel
  "8": 2753, // Kontor/Arbetsplats
  "9": 714, // Produktion
  "10": 508, // Offentlig verksamhet
  "11": 540, // Servicehandel
  "12": 738, // Detaljhandel
  "13": 402 // Grossists
};

const RecommendedProducts = () => {
  const t = useTranslate();
  const frontpageData = useData(FrontPageRecommendedData);
  const recommended =
    frontpageData.state === "LOADED" ? frontpageData.data.products : [];
  const { segment } = frontpageData;

  if (!segment) {
    return null;
  }

  return (
    <Wrapper>
      <Excerpt
        className={cn(styles.recommendedProducts, {
          [styles.loading]: frontpageData.state === "LOADING",
        })}
        title={t("FRONTPAGE.RECOMMENDED_PRODUCTS_TITLE", {
          segment: segment && segment.title,
        })}
        products={recommended.map(restProductToGQLProduct)}
        productListProps={{ variant: "wide" }}
        url={`/${t("ROUTES.RECOMMENDED")}/${encodeURIComponent(segment.title)}`}
      />
    </Wrapper>
  );
};


export const ReferenceCustomersSection = (): React$Node => {
  const t = useTranslate();
  const { customerTypes } = useContext(StoreInfoContext);
  const [segmentID, setSegmentID] = useState(null);

  const segment = customerTypes.find((x) => x.id === segmentID) || null;

  return (
    <div className={styles.block}>
      <section className={styles.section__testimonials}>
        <Wrapper className={styles.center}>
          <div className={styles.testimonials}>
            <Testimonials segment={segment?.title} />
          </div>
        </Wrapper>
      </section>

      <Wrapper className={styles.center}>
        <h3 className={styles.description}>Vi tar producenter och kunder närmare varandra. Vi tror att raka rör och öppen handel skapar en rättvis marknad med värde för alla. Egentligen flyttar vi bara varor från oss till er. Oftast är det livsmedel. Vissa kallar oss matgrossist.</h3>
      </Wrapper>

      <Wrapper variant="medium" className={cn(styles.center, styles.whatAboutYou)}>
        <h3>Vad kallar folk er?</h3>
        <p>{t("FRONTPAGE.ABOUT_YOU_TITLE")}</p>

        <div className={styles.customerSegment}>
          <Pills
            items={customerTypes.map((x) => ({ id: x.id, title: x.title }))}
            active={segmentID}
            onClick={(x) => setSegmentID(x)}
          />
        </div>
      </Wrapper>

      <section className={styles.section__customerSegments}>
        {segment !== null && productsByCompany[segment.id] && (
          <section className={styles.segmentInfo}>
            <Wrapper className={styles.segmentInfoContainer}>
              <div className={styles.segmentInfoLeft}>
                <h3>
                  {t("FRONTPAGE.CUSTOMER_SEGMENT_LEFT_A")}
                  <Link
                    to={t("ROUTES.CUSTOMER_SEGMENT_" + segment.id)}
                    className={cn("link", styles.countUpLink)}
                  >
                    <span
                      style={{ display: "inline-block", visibility: "hidden" }}
                    >
                      {formatCountUp(productsByCompany[segment.id])}
                    </span>
                    <CountUp
                      style={{
                        position: "absolute",
                        left: 0,
                        whiteSpace: "nowrap",
                      }}
                      start={0}
                      end={productsByCompany[segment.id]}
                      duration={1}
                      formattingFn={formatCountUp}
                    />
                  </Link>
                  {t("FRONTPAGE.CUSTOMER_SEGMENT_LEFT_" + segment.id)}
                  <Link to="/p" className={cn("link", styles.countUpLink)}>
                    <span
                      style={{ display: "inline-block", visibility: "hidden" }}
                    >
                      {formatCountUp(4594)}
                    </span>
                    <CountUp
                      style={{
                        position: "absolute",
                        left: 0,
                        whiteSpace: "nowrap",
                      }}
                      start={0}
                      end={4594}
                      duration={1}
                      formattingFn={formatCountUp}
                    />
                  </Link>
                  {t("FRONTPAGE.CUSTOMER_SEGMENT_LEFT_B")}
                </h3>
              </div>
              <div className={styles.segmentInfoRight}>
                <div>
                  <h3>
                    {t(
                      "FRONTPAGE.CUSTOMER_SEGMENT_RIGHT_" +
                        segment.id +
                        "_TITLE"
                    )}
                  </h3>
                  <p>
                    {t(
                      "FRONTPAGE.CUSTOMER_SEGMENT_RIGHT_" + segment.id + "_TEXT"
                    )}
                  </p>
                  <Link
                    to={t("ROUTES.CUSTOMER_SEGMENT_" + segment.id)}
                    className="link"
                  >
                    {t("FOOTER.READ_MORE")}
                  </Link>
                </div>
              </div>
            </Wrapper>
          </section>
        )}

        {segment !== null && (
          <FrontPageRecommendedData.Provider
            name={segment ? segment.title : "none"}
            segment={segment}
          >
            <RecommendedProducts segment={segment} />
          </FrontPageRecommendedData.Provider>
        )}
      </section>


    </div>
  );
};

/* @flow */

//types:
// - order och produkt
// - order
// - text input
// - informationstext för att hjälpa kunden
// - custom formulär

import type { WFMOrder } from "../../types/wfm.flow";

import { jsonResponse } from "@out-of-home/fetch-utils";
import React, { useRef, useState } from "react";
import cn from "classnames";
import { useTranslate } from "@out-of-home/use-translate";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { useCustomer } from "../../helpers/use-customer";
import { Pills } from "../../components/Pills";
import { Wrapper } from "../../components/Wrapper";
import { Foldable } from "../../components/Foldable";
import { Track } from "./Types/Track";
import { FaultyDelivery } from "./Types/FaultyDelivery";
import { DeliveryDamage } from "./Types/DeliveryDamage";
import { ReturnItem } from "./Types/Reclaim/ReturnItem";
import { ShortDate } from "./Types/Reclaim/ShortDate";
import { InvoiceError } from "./Types/InvoiceError";
import { ContactUs } from "./Types/ContactUs";
import { CmsPage } from "./Types/CmsPage";
import { Success } from "./Success";
import styles from "./styles.scss";
import sharedStyles from "./shared-styles.scss";
import { useFrontChatContext } from "../../context/front-chat";
import { useIsomorphicLayoutEffect } from "../../helpers/use-isomorphic-layout-effect";
import { Button } from "../Button";

type Props = {
  className?: string,
  issues: Array<Issue>,
  order?: WFMOrder,
};

type State = {
  issue: string,
  success: ?{
    title: string,
    text: string,
  },
};

type IssueId =
  | "track"
  | "faulty-delivery"
  | "delivery-damage"
  | "short-date"
  | "return-item"
  | "invoice-error"
  | "invoice-missing"
  | "contact-us"
  | "forgot-password"
  | "order-outside-sweden"
  | "order-as-private-individual"
  | "discounts"
  | "deposit";

type Issue = {|
  +id: IssueId,
  title: string,
  onlyForLoggedIn: boolean,
  onlyForLoggedOut: boolean,
  popularity: number, // percentage
  cmsUrlKey?: string,
|};

const POPULAR_LIMIT = 50;

export const ISSUES: Array<Issue> = [
  {
    id: "track",
    title: "Spåra min sändning",
    onlyForLoggedIn: true,
    onlyForLoggedOut: false,
    popularity: 90,
  },
  {
    id: "faulty-delivery", // directly to clickup
    title: "Felplock",
    onlyForLoggedIn: true,
    onlyForLoggedOut: false,
    popularity: 80,
  },
  {
    id: "delivery-damage", // directly to clickup
    title: "Transportskada",
    onlyForLoggedIn: true,
    onlyForLoggedOut: false,
    popularity: 50,
  },
  {
    id: "short-date", // directly to clickup
    title: "Kort datum vid ankomst",
    onlyForLoggedIn: true,
    onlyForLoggedOut: false,
    popularity: 49,
  },
  {
    id: "return-item", // directly to clickup
    title: "Reklamera artikel",
    onlyForLoggedIn: true,
    onlyForLoggedOut: false,
    popularity: 48,
  },
  {
    id: "invoice-error",
    title: "Felaktig faktura",
    onlyForLoggedIn: true,
    onlyForLoggedOut: false,
    popularity: 47,
  },
  {
    id: "invoice-missing",
    title: "Saknar min faktura",
    onlyForLoggedIn: true,
    onlyForLoggedOut: false,
    popularity: 46,
  },
  {
    id: "contact-us",
    title: "Kom i kontakt med personal",
    onlyForLoggedIn: false,
    onlyForLoggedOut: false,
    popularity: 20,
  },
  {
    id: "forgot-password",
    title: "Glömt lösenord",
    onlyForLoggedIn: false,
    onlyForLoggedOut: true,
    popularity: 10,
  },
  {
    id: "order-outside-sweden",
    title: "Handla till utlandet",
    onlyForLoggedIn: false,
    onlyForLoggedOut: false,
    popularity: 10,
  },
  {
    id: "order-as-private-individual",
    title: "Kan jag handla som privatperson?",
    onlyForLoggedIn: false,
    onlyForLoggedOut: false,
    popularity: 10,
  },
  {
    id: "discounts",
    title: "Hur fungerar rabatter?",
    onlyForLoggedIn: false,
    onlyForLoggedOut: false,
    popularity: 9,
    cmsUrlKey: "mina-rabatter",
  },
  {
    id: "deposit",
    title: "Kan ni hämta upp pant?",
    onlyForLoggedIn: false,
    onlyForLoggedOut: false,
    popularity: 8,
    cmsUrlKey: "faq-pant",
  },
];

export const WFM = ({ className, issues }: Props): React$Node => {
  const t = useTranslate();
  const { loggedIn, customer } = useCustomer();
  const [issueID, setIssueID] = useState<string | null>(null);
  const [success, setSuccess] = useState(null);
  const { open, loading } = useFrontChatContext();
  const { email, defaultPaymentMethod } = customer || {
    email: null,
    defaultPaymentMethod: null,
  };
  const issue = issues.find((x) => x.id === issueID) || null;

  const filterIssues = (issue: Issue) => {
    if (issue.onlyForLoggedOut && loggedIn) {
      return false;
    }

    if (issue.onlyForLoggedIn && !loggedIn) {
      return false;
    }

    return true;
  };

  const renderIssue = (issue) => {
    if (!issue) {
      return null;
    }

    const isVisma = defaultPaymentMethod === "CrossInvoice";
    const isDibs = defaultPaymentMethod === "DibsD2";

    const props = {
      onSuccess: (title, text) => {
        setIssueID(null);
        setSuccess({ title, text });
      },
    };

    switch (issue.id) {
      case "track":
        return <Track />;
      case "faulty-delivery":
        return <FaultyDelivery {...props} />;
      case "delivery-damage":
        return <DeliveryDamage {...props} />;
      case "return-item":
        return <ReturnItem {...props} />;
      case "short-date":
        return <ShortDate {...props} />;
      case "invoice-error":
        return <InvoiceError {...props} />;
      case "contact-us":
        return <ContactUs {...props} />;
      case "forgot-password":
        return (
          <p className={cn(sharedStyles.type, sharedStyles.message)}>
            Inga problem! Saknar du dina inloggningsuppgifter kan du återställa ditt lösenord{" "}
            <Link to="/glomtlosenord">här</Link>.
          </p>
        );
      case "order-outside-sweden":
        return (
          <p className={cn(sharedStyles.type, sharedStyles.message)}>
            Tyvärr levererar vi inte utanför Sverige / Unfortunately we don't deliver outside of Sweden
          </p>
        );
      case "order-as-private-individual":
        return (
          <p className={cn(sharedStyles.type, sharedStyles.message)}>
            Vi tar dessvärre inte emot beställningar från privatpersoner.
          </p>
        );
      case "invoice-missing":
        // Om avtalskund/Visma-faktura
        if (isVisma) {
          return (
            <p className={cn(sharedStyles.type, sharedStyles.message)}>
              På din <Link to="/orderhistorik">orderhistorik</Link> kan du se och ladda ner alla utställda fakturor.
            </p>
          );
        } else if (isDibs) {
          return (
            <p className={cn(sharedStyles.type, sharedStyles.message)}>
              Det ser ut som att ni har handlat med kortbetalning och inte faktura. Vänligen kontakta{" "}
              <a href="mailto:ekonomi@outofhome.se">ekonomi@outofhome.se</a> vid frågor.
            </p>
          );
        } else {
          return (
            <p className={cn(sharedStyles.type, sharedStyles.message)}>
              Fakturan har skickats till {email}. Vill du ha den skickad på nytt, vänligen kontakta oss på{" "}
              <a href="mailto:ekonomi@outofhome.se">ekonomi@outofhome.se</a>.
            </p>
          );
        }
      case "discounts":
      case "deposit":
        if (issue.cmsUrlKey) {
          return <CmsPage urlKey={issue.cmsUrlKey} />;
        }
      default:
        return <p className={cn(sharedStyles.type, sharedStyles.message)}>Maila oss här</p>;
    }
  };
  /*
  return (
    <div className={cn(styles.block, this.props.class)}>
      <Success
        title="Tack!"
        text="Om avtalskund/Visma-faktura: På din orderhistorik kan du se och ladda ner alla utställda fakturor."
      />
    </div>
  );
*/

  return (
    <div className={cn(styles.block, className)}>
      {!success && (
        <div>
          <h1>Vad kan vi hjälpa till med?</h1>

          {!loggedIn && <h2>Logga in för att se fler alternativ</h2>}

          <Pills
            items={issues
              .filter(filterIssues)
              .sort((a, b) => {
                if (a.popularity === b.popularity) {
                  return 0;
                }

                return a.popularity < b.popularity ? 1 : -1;
              })
              .map((x) => ({
                id: x.id,
                title: x.title,
                className: x.popularity > POPULAR_LIMIT ? styles.popular : "",
              }))}
            active={issueID}
            onClick={(x) => setIssueID(issueID === x ? null : x)}
          />

          {renderIssue(issue)}
        </div>
      )}

      {success && <Success {...success} />}

      {(!success && !issue) &&
        <div className={styles.chatBtn}>
          <Button variant="primary" onClick={open} loading={loading}>
            {t("CUSTOMER_SERVICE.CHAT_WITH_US")}
          </Button>
        </div>
      }
    </div>
  );
};

//const FRONT_LOAD_WAIT_MS = 3000;

export const Route = (): React$Node => {
  const t = useTranslate();

  return (
    <div className={styles.block}>
      <Wrapper variant="small" className={styles.wrapper}>
        <WFM issues={ISSUES} />
      </Wrapper>

      <Helmet title={t("CUSTOMER_SERVICE.TITLE")}>
        <meta name="description" content={t("CUSTOMER_SERVICE.DESCRIPTION")} />
        <meta property="og:description" content={t("CUSTOMER_SERVICE.DESCRIPTION")} />
        <meta property="og:title" content={t("CUSTOMER_SERVICE.TITLE")} />
      </Helmet>
    </div>
  );
};

/* @flow */

import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.scss";
import cn from "classnames";
import ArrowIcon from "../../icons/arrow.svg";

type Props = {
  children: React$Node,
  className?: string,
  to: string,
};

export const ArrowLink = ({
  children,
  className,
  to,
}: Props): React$Node => {
  return (
    <Link to={to} className={cn(className, styles.block)}>
      {children}&nbsp;
      <ArrowIcon className={styles.arrowIcon} />
    </Link>
  );
};

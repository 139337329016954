/* @flow */

import React, { useEffect, useState, useMemo, useRef } from "react";
import styles from "./styles.scss";
import { useLocation } from "react-router-dom";
import { useData } from "crustate/react";
import { escapeRegexp, throttle, deepEquals } from "@out-of-home/diskho";
import { Button } from "../Button";
import { useRestClient } from "../../entrypoint/shared";

const KEYS = {
  UP: 38,
  DOWN: 40,
};

type Props = {
  active: boolean,
  className?: string,
  query: string,
  limit: number,
  onFetch: Function,
  onReset: Function,
  onClick: Function,
};

type Suggestion = {
  text: string,
  key: string,
  label?: string,
  type: string,
};

const splitSuggestion = (x, exp): Array<{ key: string, value: React$Element<"strong"> | string }> => {
  const m: Array<string> | null = x.match(exp);

  if (!Array.isArray(m)) {
    return [{ key: x, value: x }];
  }
  const [_, pre, match, post] = m;

  const result = [];

  if (pre) {
    result.push({ key: pre, value: pre });
  }

  if (match) {
    result.push({ key: match, value: <strong>{match}</strong>})
  }

  if (post) {
    result.push({ key: post, value: post })
  }

  return result;
};

export const Suggestions = ({
  active,
  limit = 100,
  query,
  ...props
}: Props): React$Node => {
  const client = useRestClient();
  const [selected, setSelected] = useState(0);
  const [suggestions, setSuggestions] = useState<Array<Suggestion>>([]);
  const [ignoreChange, setIgnoreChange] = useState(false);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState(location);
  const buttons = useRef({});
  const exp = new RegExp("^(.*?)(" + escapeRegexp(query || "") + ")(.*)$", "i");

  const fetchSuggestions = useRef(
    throttle((q) => {
      if (q) {
        client(`/api/search/suggest?query=${q}`)
          .then((x) => x.json())
          .then((x) => {
            setSuggestions(x.suggestions);
            setOpen(x.suggestions.length > 0);
          });
      }
    }, 300)
  );

  useEffect(() => {
    if (deepEquals(location, prevLocation)) {
      fetchSuggestions.current(query);
    }

    setPrevLocation(location);
  }, [query, location]);

  useEffect(() => {
    //setTimeout(() => {
    setOpen(false);
    //}, 0);
  }, [location]);

  const onFocus = (index: number) => {
    setSelected(index);
  };

  const onClick = (suggestion) => {
    props.onClick(suggestion);
    props.onReset();
    setIgnoreChange(true);
  };

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.keyCode === KEYS.DOWN || e.keyCode === KEYS.UP) {
        e.preventDefault();
      }

      if (e.keyCode === KEYS.UP) {
        if (selected > 1) {
          setSelected(selected - 1);
          buttons.current["suggestion_" + (selected - 1)].focus();
        }
      }
      if (e.keyCode === KEYS.DOWN) {
        if (selected < Math.min(limit, suggestions.length)) {
          setSelected(selected + 1);
          buttons.current["suggestion_" + (selected + 1)].focus();
        }
      }
    };

    if (process.browser) {
      document.addEventListener("keydown", onKeyDown);
    }
    return () => {
      if (process.browser) {
        document.removeEventListener("keydown", onKeyDown);
      }
    };
  }, [buttons, suggestions, selected]);

  useEffect(() => {
    setSelected(0);

    if (ignoreChange) {
      setIgnoreChange(false);
      return;
    }
    if (!query.length) {
      props.onReset();
    }
  }, [query]);

  if (!open || !suggestions.length || !active) {
    return null;
  }

  return (
    <div className={styles.block}>
      {suggestions
        .filter((x, i) => i < limit)
        .map((x, i) => {
          const parts = splitSuggestion(x.text, exp);
          return (
            <button
              key={`${x.text}_${x.type}_${i}`}
              className={styles.suggestion}
              onFocus={() => onFocus(i + 1)}
              onClick={() => onClick(x)}
              ref={(x) => (buttons.current["suggestion_" + (i + 1)] = x)}
              tabIndex={i + 2}
            >
              <span>{parts.map(({ key, value }) => <span key={key}>{value}</span>)}&nbsp;</span>
              <span className={styles.type}>
                {x.type !== "query" ? x.label : ""}
              </span>
            </button>
          );
        })}
    </div>
  );
};

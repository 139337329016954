/** @flow  */

import type { Data as CustomerDataStates } from "../../state/customer"

import React, { useContext, useEffect, useRef, useState } from "react";
import { useData } from "crustate/react";
import { AnalyticsContext } from "./context";
import { CustomerData } from "../../data";

export const CustomerStateChange = (): React$Node => {
  const customerData = useData(CustomerData);
  const prevCustomerState = useRef<CustomerDataStates["state"]>(customerData.state);
  const { registerLogin, registerSignUp } = useContext(AnalyticsContext);

  useEffect(() => {
    if (customerData.state === "LOGGED_IN" && prevCustomerState.current === "CREATING_ACCOUNT") {
      registerSignUp();
    }
    else if (customerData.state === "LOGGED_IN" && prevCustomerState.current === "LOGGING_IN") {
      registerLogin();
    }

    prevCustomerState.current = customerData.state;
  }, [customerData.state])
  return null;
}

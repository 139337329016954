import type { TInfoBlock } from "../../types/filter.flow";

import React from "react";
import cn from "classnames";
import Account from "./account";
import styles from "./styles.scss";

type Props = {
  infoBlock: ?TInfoBlock,
};

export default ({ infoBlock }: Props) => {
  if (
    !infoBlock ||
    !infoBlock.instagramURL ||
    !infoBlock.images.medium_2x?.url
  ) {
    return null;
  }

  return (
    <div className={cn(styles.block, "no-underline")}>
      <Account url={infoBlock.instagramURL} />
      <img src={infoBlock.images.medium_2x.url} className={styles.banner} />
    </div>
  );
};

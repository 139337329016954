/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type {
  TestimonialsResponse,
  TestimonialsRequest,
} from "../state/testimonials";
import {
  TESTIMONIALS_LOAD_REQUEST,
  TESTIMONIALS_LOAD_RESPONSE,
} from "../state/testimonials";

const registerClient = (storage: Storage, restClient: Fetch) => {
  storage.addEffect({
    effect: async (msg: TestimonialsRequest) => {
      const data = await restClient("/api/review").then((x) => x.json());

      return ({ tag: TESTIMONIALS_LOAD_RESPONSE, data }: TestimonialsResponse);
    },
    subscribe: { [TESTIMONIALS_LOAD_REQUEST]: true },
  });
};

export default registerClient;

/** @flow */
import React from "react";
import styles from "./styles.scss";
import { Wrapper } from "../../Wrapper";
import { useTranslate } from "@out-of-home/use-translate";
import { Link } from "react-router-dom";
import cn from "classnames";

import UserIcon from "../../../icons/user.svg";
import TagIcon from "../../../icons/tag.svg";
import StarIcon from "../../../icons/star-hollow.svg";

const PreHeader = (): React$Node => {
  const t = useTranslate();

  return (
    <div className={cn(styles.preHeader, "no-underline")}>
        <Wrapper className={styles.preHeaderWrapper}>
          <Link to={t("PRE_HEADER.TEXT_LEFT_ROUTE")} className={styles.link}>
            <UserIcon />
            <span>
              {t("PRE_HEADER.TEXT_LEFT_TEXT")}&nbsp;{t("PRE_HEADER.TEXT_LEFT_LINK_TEXT")}
            </span>
          </Link>
          <Link to={t("PRE_HEADER.TEXT_MIDDLE_ROUTE")} className={styles.link}>
            <StarIcon />
            <span>
              {t("PRE_HEADER.TEXT_MIDDLE_TEXT")}&nbsp;{t("PRE_HEADER.TEXT_MIDDLE_LINK_TEXT" )}
            </span>
          </Link>
          <Link to={t("PRE_HEADER.TEXT_RIGHT_ROUTE")} className={styles.link}>
            <TagIcon />
            <span>
              {t("PRE_HEADER.TEXT_RIGHT_LINK_TEXT")}&nbsp;{t("PRE_HEADER.TEXT_RIGHT_TEXT")}
            </span>
          </Link>
        </Wrapper>
      </div>
  )
}

export default PreHeader;

/* @flow */

import React from "react";

type Props = {
  className?: string,
  itemClassName?: string,
  labels: Array<{ title: string, icon: ?string }>,
};

export const Prolabels = ({
  className,
  itemClassName,
  labels,
}: Props): React$Node => (
  <div className={className}>
    {labels &&
      labels
        .filter((x) => x.icon && x.icon.length)
        .map((x) => (
          <img
            key={x.title}
            alt={x.title}
            className={itemClassName}
            title={x.title}
            src={x.icon}
          />
        ))}
  </div>
);

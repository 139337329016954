/* @flow */

import type { OverdueInvoice } from "../../types/order.flow";

import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import cn from "classnames";
import { parseParams } from "@out-of-home/location-search-string";
import styles from "./styles.scss";
import { useData } from "crustate/react";
import { useTranslate } from "@out-of-home/use-translate";
import { Wrapper } from "../Wrapper";
import { Button } from "../Button";
import { Map } from "../Map";
import { Review } from "../Review";
import { Footer } from "../Footer";
import { Spinner } from "../Spinner";
import { NotFoundView } from "../NotFoundView";
import TickIcon from "../../icons/checkmark.svg";
import DownloadIcon from "../../icons/download.svg";
import { useCustomer } from "../../helpers/use-customer";
import { OrderData, OverdueInvoicesData } from "../../data";
import { useClient, useRestClient } from "../../entrypoint/shared";
import { AnalyticsContext } from "../../helpers/analytics/context";
import { lastOrder } from "../../queries";
import IconFacebook from "../../icons/facebook.svg";
import IconTwitter from "../../icons/twitter.svg";
import IconInstagram from "../../icons/instagram.svg";
import IconLinkedIn from "../../icons/linkedin.svg";
import { OverdueInvoiceList } from "../OverdueInvoicesDialogue";

export const SuccessView = (): React$Node => {
  const gaContext = useContext(AnalyticsContext);
  const t = useTranslate();
  const client = useClient();
  const restClient = useRestClient();
  const location = useLocation();
  const { orderId } = parseParams(location.search);
  const [mapVisible, setMapVisible] = useState(false);
  const { loggedIn, customer } = useCustomer();
  const [order, setOrder] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [reviewAccess, setReviewAccess] = useState(false);
  const orderData = useData(OrderData);

  // Register analytics
  useEffect(() => {
    if (orderData.state === "LOADED") {
      gaContext.registerCheckoutSuccess(orderData.data);
    }
  }, [orderData, gaContext]);

  useEffect(() => {
    // delay map to prevent animation lag
    if (process.browser) {
      setTimeout(() => {
        setMapVisible(true);
      }, 600);
    }

    restClient("/api/reviewAccess")
      .then((x) => x.json())
      .then((x) => {
        setReviewAccess(x);
      })
      .catch((e) => {
        setReviewAccess(false);
      });

    client(lastOrder).then((x) => {
      setOrder(x.lastOrder);
      setLoaded(true);
    });
  }, []);

  if (!loaded) {
    return (
      <div className={cn(styles.block, styles.loading)}>
        <Wrapper className={styles.wrapper}>
          <Spinner className={styles.spinner} variant="large inverted" />
          <center>
            <h3>Lägger er order…</h3>
          </center>
        </Wrapper>
      </div>
    );
  }

  if (!order) {
    return <NotFoundView />;
  }

  const overdueInvoicesData = useData(OverdueInvoicesData);
  const overdueInvoices: $ReadOnlyArray<OverdueInvoice> =
    overdueInvoicesData.state === "LOADED" ? overdueInvoicesData.data : [];

  const orders = customer?.orders.items || [];
  const invoiceOverdue = overdueInvoices.length > 0;
  const shippingAddress = order.addresses.find((x) => x.type === "shipping");

  if (!shippingAddress) {
    throw new Error("No shipping address found on the order " + order.id);
  }

  const address = [
    shippingAddress.street[0],
    shippingAddress.city,
    shippingAddress.postcode,
  ];

  return (
    <div className={styles.block}>
      <Wrapper className={styles.wrapper}>
        <section className={styles.container}>
          <div className={styles.left}>
            <div
              className={cn(styles.status, {
                [styles.status__overdue]: invoiceOverdue,
              })}
            >
              <div className={styles.statusTick}>
                <div className={styles.statusTickMask}>
                  <TickIcon />
                </div>
              </div>

              <div className={styles.successText}>
                {invoiceOverdue ? (
                  <h1
                    className={styles.statusHeading}
                    dangerouslySetInnerHTML={{
                      __html: t("SUCCESS.STATUS_HEADING_OVERDUE"),
                    }}
                  />
                ) : (
                  <h1 className={styles.statusHeading}>
                    {t("SUCCESS.STATUS_HEADING")}
                  </h1>
                )}
                <div
                  className={styles.statusText}
                  dangerouslySetInnerHTML={{
                    __html: t("SUCCESS.STATUS_TEXT", { number: order.id }),
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.right}>
            {reviewAccess && !invoiceOverdue && (
              <div className={styles.review}>
                <Review />
              </div>
            )}
          </div>
        </section>

        <section className={styles.container}>
          <div className={styles.map}>
            <div>
              {mapVisible && (
                <Map address={address} className={styles.mapNode} />
              )}

              <div className={styles.mapInfo}>
                <div className={styles.mapInfo__bg} />
                <p>Levereras till</p>
                <p>
                  {shippingAddress.street[0] +
                    " " +
                    shippingAddress.postcode +
                    ", " +
                    shippingAddress.city}
                </p>
              </div>
            </div>

            <div className="row">
              <div className={styles.social}>
                <p className={styles.title}>{t("REVIEW.SOCIAL_TITLE")}</p>
                <div>
                  <a
                    href={t("SOCIAL.FACEBOOK_URL")}
                    className={styles.icon__link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconFacebook className={styles.icon} />
                  </a>
                  <a
                    href={t("SOCIAL.INSTAGRAM_URL")}
                    className={styles.icon__link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconInstagram className={styles.icon} />
                  </a>
                  <a
                    href={t("SOCIAL.TWITTER_URL")}
                    className={styles.icon__link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconTwitter className={styles.icon} />
                  </a>
                  <a
                    href={t("SOCIAL.LINKEDIN_URL")}
                    className={styles.icon__link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconLinkedIn className={styles.icon} />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.steps}>
            <div className={styles.step}>
              <div className={styles.stepNumber}>&nbsp;</div>
              <div>
                <h2 className={styles.stepsHeading}>Så, vad händer nu?</h2>
              </div>
            </div>

            <div className={styles.step}>
              <div className={styles.stepNumber}>1</div>
              <div className={styles.stepContent}>
                {invoiceOverdue ? (
                  <>
                    <h2 className={styles.stepHeading}>
                      {t("SUCCESS.STEP_1_TITLE_OVERDUE_INVOICE")}
                    </h2>
                    <OverdueInvoiceList
                      className={styles.overdueInvoiceList}
                      data={overdueInvoices}
                    />
                    <p
                      className={styles.stepTextOverdue}
                      dangerouslySetInnerHTML={{
                        __html: t("SUCCESS.STEP_1_OVERDUE"),
                      }}
                    />
                  </>
                ) : (
                  <>
                    <h2 className={styles.stepHeading}>
                      {t("SUCCESS.STEP_1_TITLE")}
                    </h2>
                    <p className={styles.stepText}>
                      {t("SUCCESS.STEP_1", { email: order.email })}
                    </p>
                  </>
                )}
              </div>
            </div>

            <div className={styles.step}>
              <div className={styles.stepNumber}>2</div>
              <div className={styles.stepContent}>
                {invoiceOverdue ? (
                  <>
                    <h2 className={styles.stepHeading}>
                      {t("SUCCESS.STEP_2_TITLE_OVERDUE_INVOICE")}
                    </h2>
                    <p
                      className={styles.stepText}
                      dangerouslySetInnerHTML={{
                        __html: t("SUCCESS.STEP_2_OVERDUE_INVOICE"),
                      }}
                    />
                  </>
                ) : (
                  <>
                    <h2 className={styles.stepHeading}>
                      {t("SUCCESS.STEP_2_TITLE")}
                    </h2>
                    <p className={styles.stepText}>{t("SUCCESS.STEP_2")}</p>
                  </>
                )}
              </div>
            </div>

            <div className={styles.step}>
              <div className={styles.stepNumber}>3</div>
              <div className={styles.stepContent}>
                <h2 className={styles.stepHeading}>
                  {t("SUCCESS.STEP_3_TITLE")}
                </h2>
                <p className={styles.stepText}>{t("SUCCESS.STEP_3")}</p>
              </div>
            </div>

            <div className={styles.step}>
              <div className={styles.stepNumber}>4</div>
              <div className={styles.stepContent}>
                <h2 className={styles.stepHeading}>
                  {t("SUCCESS.STEP_4_TITLE")}
                </h2>
                <p className={styles.stepText}>{t("SUCCESS.STEP_4")}</p>
              </div>
            </div>

            {loggedIn && (
              <div className={styles.step}>
                <div className={styles.stepNumber}>&nbsp;</div>
                <div className={styles.stepContent}>
                  <h2 className={styles.stepHeading}>
                    {t("SUCCESS.QUESTIONS_TITLE")}
                  </h2>
                  <p className={styles.stepText}>
                    {t("SUCCESS.QUESTIONS_CTA")}
                  </p>
                </div>
              </div>
            )}
          </div>
        </section>
      </Wrapper>

      <Helmet title={t("SUCCESS.TITLE")} />
    </div>
  );
};

/* @flow */

import React, { useState, useEffect } from "react";
import { Spinner } from "../../../Spinner";
import { useRestClient } from "../../../../entrypoint/shared";
import sharedStyles from "../../shared-styles.scss";

type Props = {
  urlKey: string,
};

export const CmsPage = ({ urlKey }: Props): React$Node => {
  const [cms, setCms] = useState();
  const client = useRestClient();

  useEffect(() => {
    client(`/api/cms/${urlKey}`)
      .then((x) => x.json())
      .then((x) => {
        setCms(x);
      });
  }, []);

  if (!cms) {
    return (
      <div className={sharedStyles.type}>
        <Spinner variant="inverted" />
      </div>
    );
  }

  return (
    <div className={sharedStyles.type}>
      {typeof cms.contentHeading !== "undefined" && (
        <h1>{cms.contentHeading}</h1>
      )}

      <div
        dangerouslySetInnerHTML={{ __html: cms.html }}
        className="typography"
        style={{ textAlign: "left" }}
      />
    </div>
  );
};

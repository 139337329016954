/* @flow */

import type { ProductImage as ProductImageType } from "../../types/product.flow";
import type { ElementConfig } from "react";

import React from "react";
import { Square } from "../Square";

import styles from "./styles.scss";

type Props = {
  image: ProductImageType,
  hintImage?: ?string,
} & ElementConfig<"img">;

export const ProductImage = ({
  image,
  hintImage,
  children,
  alt,
  ...props
}: Props): React$Node => {
  if (!image.smallImage && !image.largeImage) {
    return (
      <div {...props}>
        <Square className={styles.imagePlaceholder} />
      </div>
    );
  }

  return (
    <div {...props}>
      <Square />

      <picture
        style={{
          zIndex: 1,
          position: "absolute",
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
          backgroundImage: hintImage ? `url(${hintImage})` : "",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
      >
        {image.smallImage && (
          <source
            srcSet={`${image.smallImage.x1}, ${image.smallImage.x2} 2x`}
            media={`(max-width: ${parseInt(styles.breakpoint, 10) - 1}px)`}
          />
        )}
        {image.largeImage && (
          <source
            srcSet={`${image.largeImage.x1}, ${image.largeImage.x2} 2x`}
            media={`(min-width: ${parseInt(styles.breakpoint, 10)}px)`}
          />
        )}
        {image.smallImage && (
          <img
            src={image.smallImage.x2}
            alt={alt}
            style={{
              width: "100%",
              zIndex: 2,
            }}
            fetchpriority="high"
          />
        )}
      </picture>
    </div>
  );
};

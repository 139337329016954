/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { WishlistResponse, WishlistRequest } from "../state/wishlist";
import {
  WISHLIST_LOAD_REQUEST,
  WISHLIST_LOAD_RESPONSE,
} from "../state/wishlist";
import {
  WISHLIST_TOGGLE_ADD_PRODUCT_RESPONSE,
  WISHLIST_TOGGLE_REMOVE_PRODUCT_RESPONSE,
} from "../state/wishlist-toggle";

const registerClient = (storage: Storage, client: Fetch) => {
  storage.addEffect({
    effect: async (msg: WishlistRequest) => {
      const data = await client("/api/wishlist").then((x) => x.json());

      if (data.errorCode) {
        return ({
          tag: WISHLIST_LOAD_RESPONSE,
          error: data.message,
        }: WishlistResponse);
      }

      return ({ tag: WISHLIST_LOAD_RESPONSE, data }: WishlistResponse);
    },
    subscribe: {
      [WISHLIST_LOAD_REQUEST]: true,
    },
  });
};

export default registerClient;

/* @flow */

type History = {
  push: (path: string) => void,
};

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type {
  DeliveryScheduleEffectResponse,
  DeliveryScheduleEffectRequest,
} from "../state/delivery-schedule";

import { sendJson } from "@out-of-home/fetch-utils";
import { addMessage } from "../state/messages";
import {
  DELIVERY_SCHEDULE_EFFECT_LOAD_REQUEST,
  DELIVERY_SCHEDULE_EFFECT_LOAD_RESPONSE,
} from "../state/delivery-schedule";
import { deliverySchedule } from "../queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: DeliveryScheduleEffectRequest) => {
      try {
        const result = await client(deliverySchedule, {
          postcode: msg.postcode,
          shippingMethod: msg.shippingMethod,
        });

        return ({
          tag: DELIVERY_SCHEDULE_EFFECT_LOAD_RESPONSE,
          data: {
            data: result.deliverySchedule.days,
            postcode: msg.postcode,
            shippingMethod: msg.shippingMethod,
          },
        }: DeliveryScheduleEffectResponse);
      } catch (e) {
        return ({
          tag: DELIVERY_SCHEDULE_EFFECT_LOAD_RESPONSE,
          error: "Error when fetching deliverySchedule",
        }: DeliveryScheduleEffectResponse);
      }
    },
    subscribe: { [DELIVERY_SCHEDULE_EFFECT_LOAD_REQUEST]: true },
  });
};

export default registerClient;

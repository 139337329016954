/* @flow */

type Config = { [key: string]: number };

export const DISCOUNT_METER_CONFIG = {
  GUEST: [
    { of: 5000, discount: 1 },
    { of: 10000, discount: 1.5 },
    { of: 15000, discount: 2 },
    { of: 20000, discount: 2.5 },
    { of: 25000, discount: 3 },
    { of: 30000, discount: 3.5 },
  ],
  GENERAL: [
    { of: 5000, discount: 1 },
    { of: 10000, discount: 1.5 },
    { of: 15000, discount: 2 },
    { of: 20000, discount: 2.5 },
    { of: 25000, discount: 3 },
    { of: 30000, discount: 3.5 },
  ],
  "LB-SB": [
    { of: 5000, discount: 1 },
    { of: 10000, discount: 1.5 },
    { of: 15000, discount: 2 },
    { of: 20000, discount: 2.5 },
    { of: 25000, discount: 3 },
    { of: 30000, discount: 3.5 },
  ],

  "LA-SB": [
    { of: 5000, discount: 1 },
    { of: 10000, discount: 1.5 },
    { of: 15000, discount: 2 },
    { of: 20000, discount: 2.5 },
    { of: 25000, discount: 3 },
    { of: 30000, discount: 3.5 },
  ],
  "LB-SA": [
    { of: 5000, discount: 1 },
    { of: 10000, discount: 1.5 },
    { of: 15000, discount: 2 },
    { of: 20000, discount: 2.5 },
    { of: 25000, discount: 3 },
    { of: 30000, discount: 3.5 },
  ],
  "LA-SA": [
    { of: 6000, discount: 1 },
    { of: 10000, discount: 1.5 },
    { of: 15000, discount: 2 },
    { of: 20000, discount: 2.5 },
    { of: 25000, discount: 3 },
    { of: 30000, discount: 3.5 },
  ],
  FOODORA: [
    { of: 30000, discount: 1 },
    { of: 60000, discount: 2 },
  ],
  "HAWAII POKE": [
    { of: 10000, discount: 1 },
    { of: 20000, discount: 1.5 },
    { of: 30000, discount: 2 },
  ],
  UHPO: [
    { of: 5000, discount: 1 },
    { of: 10000, discount: 1.5 },
    { of: 15000, discount: 2 },
    { of: 20000, discount: 2.5 },
    { of: 25000, discount: 3 },
    { of: 30000, discount: 3.5 },
  ],
  "24-SJU": [
    { of: 12000, discount: 2 },
    { of: 25000, discount: 3 },
  ]
};

export const KOLONIAL_FREE_SHIPPING_LIMIT: Config = {
  DEFAULT: 2000,
  NORDREST: 3500,
  REDFELLAS: 4000,
  SKOLFOOD: 3500,
  ["FRISKIS & SVETTIS"]: 3500,
  ["PDL"]: 4500,
  ["DÖNER KEBAB"]: 8000,

  ["LA-SA"]: 4000,
  ["LB-SA"]: 4000,
  ["LA-SB"]: 4000,
  ["LB-SB"]: 3000,

  GROSSIST: 15000,
  FACKHANDEL: 5000,
  GRUNDA: 5000,
  AHLSELL: 5000,
  LOWLY: 4000,
  LYRECO: 5000,
  OCAY: 5000,
  PACSON: 5000,
  ["ÖSTERBERGS"]: 5000,
  ["OFFICE DEPOT"]: 5000,
  ["ALLOFFICE NORDIC"]: 5000,
  ["A & G FOOD"]: 5000,
  ["XL-BYGG"]: 5000,
  ["FOODORA MARKET"]: 5000,
  ["FOODORA"]: 6000,
  ["SPISEK"]: 5000,
  ["UHPO"]: 3000,
  ["CAVERION"]: 5000,
  ["HAWAII POKE"]: 3000,
  ["S-KUND"]: 10000,
  ["MINIBAR"]: 4500,
  ["DVH-A"]: 5000,
  ["DVH-B"]: 3000,
  ["HANDLA HÅLLBART"]: 3000,
  ["KAVALL"]: 7500,
  ["WOLT MARKET"]: 7500,
  ["GRANNGÅRDEN & APOTEA"]: 5000,
  ["24-SJU"]: 7000,
  ["NEVER CLOSED"]: 10000,
  ["VÄRMLANDS KOMMUN"]: 1000,
  ["HELSINGBORGS KOMMUN"]: 0,
};

export const FROZEN_FREE_SHIPPING_LIMIT: Config = {
  DEFAULT: 2000,
  ["DÖNER KEBAB"]: 4000,
};

/* @flow */

import type { Data } from "../state/customer";
import type { Customer } from "../types/customer.flow";

import { useData } from "crustate/react";
import { CustomerData } from "../data";

export const useCustomer = ():
  | {
      +loggedIn: true,
      loggingIn: false,
      +customer: Customer,
      state: $PropertyType<Data, "state">,
    }
  | {
      +loggedIn: false,
      loggingIn: boolean,
      +customer: null,
      state: $PropertyType<Data, "state">,
    } => {
  const customerData = useData(CustomerData);
  const customer = customerData.data ? customerData.data : null;
  const loggedIn = customer !== null;
  const loggingIn = [
    "LOGGING_IN",
    "FETCHING_LOGIN_TOKEN",
    "FETCHED_LOGIN_TOKEN",
  ].includes(customerData.state);

  if (customer === null) {
    return {
      loggedIn: false,
      loggingIn,
      customer: null,
      state: customerData.state,
    };
  }

  return {
    loggedIn: true,
    loggingIn: false,
    customer,
    state: customerData.state,
  };
};

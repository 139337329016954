/** @flow */

import { Button } from "../Button";
import { Wrapper } from "../Wrapper";
import styles from "./header-styles.scss";

import * as React from "react";
import { Link } from "react-router-dom";
import { Logo } from "../AppHeader/logo";
import CloseIcon2 from "../../icons/close2.svg";

export const Header = (): React$Node => {
  return (
    <div className={styles.header}>
      <Wrapper className={styles.headerWrapper}>
        <div className={styles.headerInner}>
            <Link to='/' className={styles.logo} aria-label="Outofhome.se logo">
              <Logo />
            </Link>

            <Button
              ariaLabel="close register user button"
              className={styles.closeButton}
              to="/"
            >
              <CloseIcon2 />
            </Button>
        </div>
      </Wrapper>
    </div>
  );
};

/* @flow */

import type {
  ProductCardProduct,
  RestAPIProductCard,
} from "../types/product.flow";

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

type State = {
  products: Array<RestAPIProductCard>,
  recommended?: Array<RestAPIProductCard>,
};

export type Segment = { id: number, title: string };

type Data =
  | {| state: "LOADING", segment: ?Segment |}
  | {| state: "LOADED", segment: ?Segment, data: State |};

export type FrontpageRecommendedResponse = Response<
  typeof FRONTPAGE_RECOMMENDED_LOAD_RESPONSE,
  State
>;

export type FrontpageRecommendedRequest = {
  tag: typeof FRONTPAGE_RECOMMENDED_LOAD_REQUEST,
  segment: Segment,
};

export const FRONTPAGE_RECOMMENDED_LOAD_RESPONSE: "frontpage_recommended/load/response" =
  "frontpage_recommended/load/response";
export const FRONTPAGE_RECOMMENDED_LOAD_REQUEST: "frontpage_recommended/load/request" =
  "frontpage_recommended/load/request";
export const FRONTPAGE_RECOMMENDED_SET_SEGMENT_REQUEST: "frontpage_recommended/set_segment/request" =
  "frontpage_recommended/set_segment/request";

export const FrontpageReommendedModel: Model<
  Data,
  { segment?: Segment },
  FrontpageRecommendedRequest | FrontpageRecommendedResponse
> = {
  id: "frontpage-recommended",
  init: ({ segment }: { segment?: Segment }) =>
    updateData(
      { state: "LOADING", segment },
      { tag: FRONTPAGE_RECOMMENDED_LOAD_REQUEST, segment }
    ),
  update: (state: Data, msg) => {
    switch (msg.tag) {
      case FRONTPAGE_RECOMMENDED_LOAD_RESPONSE:
        if (msg.data && state.state === "LOADING") {
          return updateData({
            state: "LOADED",
            segment: state.segment,
            data: msg.data,
          });
        }

        break;
      default:
    }
  },
};

/* @flow */

import type { AlcoholLicense } from "../types/alcohol-license.flow";
import type {
  Customer,
  CustomerAddressInput,
  EditCustomerFormInput,
} from "../types/customer.flow";

import {
  EVENT_CHECKOUT_CREATE_ADDRESS,
  EVENT_CHECKOUT_UPDATE_ADDRESS,
} from "./events";
import type { Model, EffectErrorMessage } from "crustate";
import type { Response } from "./util";
import { updateData, EFFECT_ERROR } from "crustate";

export type Data =
  | {| +state: "INITING" |}
  | {| +state: "CREATING_ACCOUNT" |}
  | {| +state: "SAVING_ADDRESS", +data: Customer |}
  | {| +state: "LOGGING_IN" |}
  | {| +state: "LOGGING_OUT", +data: Customer |}
  | {| +state: "NOT_LOGGED_IN" |}
  | {| +state: "LOGGED_IN", +data: Customer |}
  | {| +state: "UPDATING", +data: Customer |}
  | {| +state: "RESET_PASSWORD" |};

export type CreateAccountParams = {
  customer: {
    defaultPaymentMethod: ?string,
    password: string,
    email: string,
    firstname: string,
    lastname: string,
    customer_pno: string,
    hasAlcoholLicense: boolean,
    attributes: {
      registration_code: ?string,
      vendor_id: ?string,
      ooh3_type: string,
      ooh3_subtype: Array<string>,
    },
  },
  address: CustomerAddressInput,
  alcoholLicense: ?{
    day: string,
    month: string,
    year: string,
    filename: string,
    data: string,
  },
};

export type CustomerRequest =
  | {
      tag: typeof CUSTOMER_INIT_REQUEST,
    }
  | {
      tag: typeof CUSTOMER_LOGIN_REQUEST,
      email: string,
      password: string,
    }
  | {
      tag: typeof CUSTOMER_SAVE_BILLING_ADDRESS_REQUEST,
      address: CustomerAddressInput,
    }
  | {
      tag: typeof CUSTOMER_LOGOUT_REQUEST,
    }
  | {
      tag: typeof CUSTOMER_RESET_PASSWORD_REQUEST,
      email: string,
    }
  | {
      tag: typeof CUSTOMER_UPDATE_REQUEST,
      data: EditCustomerFormInput,
    }
  | {
      tag: typeof CUSTOMER_SET_PNO_REQUEST,
      customerPno: string,
    }
  | {
      tag: typeof CUSTOMER_SET_PREFERRED_LIST_LAYOUT_REQUEST,
      layout: string,
    }
  | {
      tag: typeof CUSTOMER_SET_EMAIL_COPY_REQUEST,
      ooh3OrderCopyEmail: string,
    }
  | {
      tag: typeof CUSTOMER_CREATE_ADDRESS_REQUEST,
      address: CustomerAddressInput,
      onAccountView: boolean,
    }
  | {
      tag: typeof CUSTOMER_UPDATE_ADDRESS_REQUEST,
      id: string,
      address: CustomerAddressInput,
      onAccountView: boolean,
    }
  | {
      tag: typeof CUSTOMER_DELETE_ADDRESS_REQUEST,
      id: string,
    }
  | {
      tag: typeof CUSTOMER_SET_DEFAULT_ADDRESS_REQUEST,
      type: "billing" | "shipping",
      id: string,
    }
  | {
      tag: typeof CUSTOMER_CREATE_ACCOUNT_REQUEST,
      data: CreateAccountParams,
    }
  | {
      tag: typeof CUSTOMER_SET_DEFAULT_PAYMENT_METHOD_REQUEST,
      id: string,
    }
  | {
      tag: typeof CUSTOMER_SET_TYPE_REQUEST,
      ooh3Type: string,
      ooh3Subtype: Array<string>,
    };

export type CustomerResponse =
  | {
      tag: typeof CUSTOMER_SYNC,
      data: Customer,
    }
  | {
      tag: typeof CUSTOMER_INIT_RESPONSE,
      data: ?Customer,
    }
  | {
      tag: typeof CUSTOMER_LOGIN_RESPONSE,
      data: ?Customer,
    }
  | {
      tag: typeof CUSTOMER_SAVE_BILLING_ADDRESS_RESPONSE,
      data: Customer,
    }
  | {
      tag: typeof CUSTOMER_LOGOUT_RESPONSE,
    }
  | {
      tag: typeof CUSTOMER_RESET_PASSWORD_RESPONSE,
    }
  | Response<typeof CUSTOMER_UPDATE_RESPONSE, Customer>
  | {
      tag: typeof CUSTOMER_CREATE_ADDRESS_RESPONSE,
      result: string,
      customer: Customer,
    }
  | Response<typeof CUSTOMER_UPDATE_ADDRESS_RESPONSE, Customer>
  | {
      tag: typeof CUSTOMER_DELETE_ADDRESS_RESPONSE,
      result: string,
      id: string,
    }
  | {
      tag: typeof CUSTOMER_SET_DEFAULT_ADDRESS_RESPONSE,
      result: string,
      customer: Customer,
    }
  | Response<typeof CUSTOMER_CREATE_ACCOUNT_RESPONSE, Customer>
  | {
      tag: typeof CUSTOMER_SET_DEFAULT_PAYMENT_METHOD_RESPONSE,
      customer: Customer,
    }
  | EffectErrorMessage;

export const CUSTOMER_SYNC: "customer/sync" = "customer/sync";

export const CUSTOMER_INIT_REQUEST: "customer/init/request" =
  "customer/init/request";
export const CUSTOMER_INIT_RESPONSE: "customer/init/response" =
  "customer/init/response";

export const CUSTOMER_LOGIN_REQUEST: "customer/login/request" =
  "customer/login/request";
export const CUSTOMER_LOGIN_RESPONSE: "customer/login/response" =
  "customer/login/response";

export const CUSTOMER_LOGOUT_REQUEST: "customer/logout/request" =
  "customer/logout/request";
export const CUSTOMER_LOGOUT_RESPONSE: "customer/logout/response" =
  "customer/logout/response";

export const CUSTOMER_SAVE_BILLING_ADDRESS_REQUEST: "customer/save_billing_address/request" =
  "customer/save_billing_address/request";
export const CUSTOMER_SAVE_BILLING_ADDRESS_RESPONSE: "customer/save_billing_address/response" =
  "customer/save_billing_address/response";

export const CUSTOMER_RESET_PASSWORD_REQUEST: "customer/reset_password/request" =
  "customer/reset_password/request";
export const CUSTOMER_RESET_PASSWORD_RESPONSE: "customer/reset_password/response" =
  "customer/reset_password/response";

export const CUSTOMER_UPDATE_REQUEST: "customer/update/request" =
  "customer/update/request";
export const CUSTOMER_UPDATE_RESPONSE: "customer/update/response" =
  "customer/update/response";

export const CUSTOMER_CREATE_ADDRESS_REQUEST: "customer/create-address/request" =
  "customer/create-address/request";
export const CUSTOMER_CREATE_ADDRESS_RESPONSE: "customer/create-address/response" =
  "customer/create-address/response";

export const CUSTOMER_UPDATE_ADDRESS_REQUEST: "customer/update-address/request" =
  "customer/update-address/request";
export const CUSTOMER_UPDATE_ADDRESS_RESPONSE: "customer/update-address/response" =
  "customer/update-address/response";

export const CUSTOMER_DELETE_ADDRESS_REQUEST: "customer/delete-address/request" =
  "customer/delete-address/request";
export const CUSTOMER_DELETE_ADDRESS_RESPONSE: "customer/delete-address/response" =
  "customer/delete-address/response";

export const CUSTOMER_SET_DEFAULT_ADDRESS_REQUEST: "customer/set-default-address/request" =
  "customer/set-default-address/request";
export const CUSTOMER_SET_DEFAULT_ADDRESS_RESPONSE: "customer/set-default-address/response" =
  "customer/set-default-address/response";

export const CUSTOMER_CREATE_ACCOUNT_REQUEST: "customer/create-account/request" =
  "customer/create-account/request";
export const CUSTOMER_CREATE_ACCOUNT_RESPONSE: "customer/create-account/response" =
  "customer/create-account/response";

export const CUSTOMER_SET_DEFAULT_PAYMENT_METHOD_REQUEST: "customer/set-default-payment-method/request" =
  "customer/set-default-payment-method/request";
export const CUSTOMER_SET_DEFAULT_PAYMENT_METHOD_RESPONSE: "customer/set-default-payment-method/response" =
  "customer/set-default-payment-method/response";

export const CUSTOMER_SET_PNO_REQUEST: "customer/set-pno/request" =
  "customer/set-pno/request";
export const CUSTOMER_SET_EMAIL_COPY_REQUEST: "customer/set-email-copy/request" =
  "customer/set-email-copy/request";
export const CUSTOMER_SET_PREFERRED_LIST_LAYOUT_REQUEST: "customer/set-preferred-list-layout/request" =
  "customer/set-preferred-list-layout/request";

export const CUSTOMER_SET_TYPE_REQUEST: "customer/set-type/request" =
  "customer/set-type/request";

// Actions
export const syncCustomer = (data: Customer): CustomerResponse => ({
  tag: CUSTOMER_SYNC,
  data,
});

export const logout = (): CustomerRequest => ({
  tag: CUSTOMER_LOGOUT_REQUEST,
});

export const login = (email: string, password: string): CustomerRequest => ({
  tag: CUSTOMER_LOGIN_REQUEST,
  email,
  password,
});

export const saveAddress = (
  address: CustomerAddressInput
): CustomerRequest => ({
  tag: CUSTOMER_SAVE_BILLING_ADDRESS_REQUEST,
  address,
});

export const resetPassword = (email: string): CustomerRequest => ({
  tag: CUSTOMER_RESET_PASSWORD_REQUEST,
  email,
});

export const updateCustomer = (
  email: string,
  password: string,
  isSubscribedToNewsletter: boolean,
  data: EditCustomerFormInput
): CustomerRequest => ({
  tag: CUSTOMER_UPDATE_REQUEST,
  email,
  password,
  isSubscribedToNewsletter,
  data,
});

export const createCustomerAddress = (
  address: CustomerAddressInput,
  onAccountView: boolean = false
): CustomerRequest => ({
  tag: CUSTOMER_CREATE_ADDRESS_REQUEST,
  address,
  onAccountView,
});

export const updateCustomerAddress = (
  id: string,
  address: CustomerAddressInput,
  onAccountView: boolean = false
): CustomerRequest => ({
  tag: CUSTOMER_UPDATE_ADDRESS_REQUEST,
  id,
  address,
  onAccountView,
});

export const deleteCustomerAddress = (id: string): CustomerRequest => ({
  tag: CUSTOMER_DELETE_ADDRESS_REQUEST,
  id,
});

export const setDefaultAddress = (id: string): CustomerRequest => ({
  tag: CUSTOMER_SET_DEFAULT_ADDRESS_REQUEST,
  type: "billing",
  id,
});

export const setDefaultPaymentMethod = (id: string): CustomerRequest => ({
  tag: CUSTOMER_SET_DEFAULT_PAYMENT_METHOD_REQUEST,
  id,
});

export const createAccount = (data: CreateAccountParams): CustomerRequest => ({
  tag: CUSTOMER_CREATE_ACCOUNT_REQUEST,
  data,
});

export const setPNO = (customerPno: string): CustomerRequest => ({
  tag: CUSTOMER_SET_PNO_REQUEST,
  customerPno,
});

export const setEmailCopy = (ooh3OrderCopyEmail: string): CustomerRequest => ({
  tag: CUSTOMER_SET_EMAIL_COPY_REQUEST,
  ooh3OrderCopyEmail,
});

export const setCustomerPreferredListLayout = (
  layout: string
): CustomerRequest => ({
  tag: CUSTOMER_SET_PREFERRED_LIST_LAYOUT_REQUEST,
  layout,
});

export const setCustomerType = (
  ooh3Type: string,
  ooh3Subtype: Array<string>
): CustomerRequest => ({
  tag: CUSTOMER_SET_TYPE_REQUEST,
  ooh3Type,
  ooh3Subtype,
});

export const CustomerModel: Model<
  Data,
  {},
  CustomerRequest | CustomerResponse
> = {
  id: "customer",
  init: () => updateData({ state: "INITING" }, { tag: CUSTOMER_INIT_REQUEST }),
  update: (state, msg) => {
    switch (msg.tag) {
      case EVENT_CHECKOUT_UPDATE_ADDRESS:
        if (state.state === "LOGGED_IN") {
          return updateData(
            {
              state: "UPDATING",
              data: state.data,
            },
            updateCustomerAddress(msg.id, msg.address)
          );
        }

        throw new Error("Invalid state transition");

      case EVENT_CHECKOUT_CREATE_ADDRESS:
        if (state.state === "LOGGED_IN") {
          return updateData(
            {
              state: "UPDATING",
              data: state.data,
            },
            createCustomerAddress(msg.address)
          );
        }

        throw new Error("Invalid state transition");

      case EFFECT_ERROR:
        if (
          [
            CUSTOMER_LOGIN_REQUEST,
            CUSTOMER_LOGOUT_REQUEST,
            CUSTOMER_INIT_REQUEST,
          ].includes(msg.cause.tag)
        ) {
          return updateData({ state: "NOT_LOGGED_IN" });
        }

        return null;
      case CUSTOMER_SYNC:
        return msg.data
          ? updateData({ state: "LOGGED_IN", data: msg.data })
          : updateData({ state: "NOT_LOGGED_IN" });

      case CUSTOMER_INIT_RESPONSE:
        if (state.state === "INITING" || state.state === "NOT_LOGGED_IN") {
          return msg.data
            ? updateData({ state: "LOGGED_IN", data: msg.data })
            : updateData({ state: "NOT_LOGGED_IN" });
        }

        throw new Error("Invalid state transition");
      case CUSTOMER_LOGIN_REQUEST:
        if (state.state === "NOT_LOGGED_IN") {
          return updateData(
            {
              state: "LOGGING_IN",
            },
            msg
          );
        }

        throw new Error("Invalid state transition");
      case CUSTOMER_LOGIN_RESPONSE:
        if (state.state === "LOGGING_IN") {
          return msg.data
            ? updateData({ state: "LOGGED_IN", data: msg.data })
            : updateData({ state: "NOT_LOGGED_IN" });
        }

        throw new Error("Invalid state transition");
      case CUSTOMER_SAVE_BILLING_ADDRESS_RESPONSE:
        if (state.state === "SAVING_ADDRESS") {
          return updateData({
            state: "LOGGED_IN",
            data: msg.data,
          });
        }

        throw new Error("Invalid state transition");
      case CUSTOMER_SAVE_BILLING_ADDRESS_REQUEST:
        if (state.state === "LOGGED_IN") {
          return updateData(
            {
              state: "SAVING_ADDRESS",
              data: state.data,
            },
            msg
          );
        }

        throw new Error("Invalid state transition");
      case CUSTOMER_LOGOUT_REQUEST:
        if (state.state === "LOGGED_IN") {
          return updateData(
            {
              state: "LOGGING_OUT",
              data: state.data,
            },
            msg
          );
        }

        throw new Error("Invalid state transition");
      case CUSTOMER_LOGOUT_RESPONSE:
        if (state.state === "LOGGING_OUT") {
          return updateData({ state: "NOT_LOGGED_IN" });
        }

        throw new Error("Invalid state transition");
      case CUSTOMER_RESET_PASSWORD_REQUEST:
        return updateData(
          {
            state: "RESET_PASSWORD",
          },
          msg
        );

      case CUSTOMER_RESET_PASSWORD_RESPONSE:
        if (state.state === "RESET_PASSWORD") {
          return updateData({ state: "NOT_LOGGED_IN" });
        }

        throw new Error("Invalid state transition");

      case CUSTOMER_UPDATE_REQUEST:
      case CUSTOMER_SET_PNO_REQUEST:
      case CUSTOMER_SET_EMAIL_COPY_REQUEST:
      case CUSTOMER_SET_PREFERRED_LIST_LAYOUT_REQUEST:
      case CUSTOMER_SET_TYPE_REQUEST:
        if (state.state === "LOGGED_IN") {
          return updateData(
            {
              state: "UPDATING",
              data: state.data,
            },
            msg
          );
        }

        throw new Error("Invalid state transition");
      case CUSTOMER_UPDATE_RESPONSE:
        if (state.state === "UPDATING") {
          if (!msg.data) {
            return updateData({
              state: "LOGGED_IN",
              data: state.data,
            });
          }

          return updateData({
            state: "LOGGED_IN",
            data: msg.data,
          });
        }

        throw new Error("Invalid state transition");

      case CUSTOMER_CREATE_ADDRESS_REQUEST:
        if (state.state === "LOGGED_IN") {
          return updateData(
            {
              state: "UPDATING",
              data: state.data,
            },
            msg
          );
        }

        throw new Error("Invalid state transition");
      case CUSTOMER_CREATE_ADDRESS_RESPONSE:
        if (state.state === "UPDATING") {
          return updateData({
            state: "LOGGED_IN",
            data: msg.customer,
          });
        }

        throw new Error("Invalid state transition");
      case CUSTOMER_UPDATE_ADDRESS_REQUEST:
        if (state.state === "LOGGED_IN") {
          return updateData(
            {
              state: "UPDATING",
              data: state.data,
            },
            msg
          );
        }

        throw new Error("Invalid state transition");
      case CUSTOMER_UPDATE_ADDRESS_RESPONSE:
        if (state.state === "UPDATING") {
          if (msg.data) {
            return updateData({
              state: "LOGGED_IN",
              data: msg.data,
            });
          } else {
            return updateData({
              state: "LOGGED_IN",
              data: state.data,
            });
          }
        }

        throw new Error("Invalid state transition");

      case CUSTOMER_DELETE_ADDRESS_REQUEST:
        if (state.state === "LOGGED_IN") {
          return updateData(
            {
              state: "UPDATING",
              data: state.data,
            },
            msg
          );
        }

        throw new Error("Invalid state transition");
      case CUSTOMER_DELETE_ADDRESS_RESPONSE:
        if (state.state === "UPDATING") {
          if (msg.result === "success") {
            return updateData({
              state: "LOGGED_IN",
              data: {
                ...state.data,
                addresses: state.data.addresses.filter((x) => x.id !== msg.id),
              },
            });
          } else {
            return updateData({
              state: "LOGGED_IN",
              data: state.data,
            });
          }
        }

        throw new Error("Invalid state transition");

      case CUSTOMER_SET_DEFAULT_ADDRESS_REQUEST:
        if (state.state === "LOGGED_IN") {
          return updateData(
            {
              state: "UPDATING",
              data: state.data,
            },
            msg
          );
        }

        throw new Error("Invalid state transition");
      case CUSTOMER_SET_DEFAULT_ADDRESS_RESPONSE:
        if (state.state === "UPDATING") {
          return updateData({
            state: "LOGGED_IN",
            data: msg.customer,
          });
        }

        throw new Error("Invalid state transition");

      case CUSTOMER_SET_DEFAULT_PAYMENT_METHOD_REQUEST:
        if (state.state === "LOGGED_IN") {
          return updateData(
            {
              state: "UPDATING",
              data: state.data,
            },
            msg
          );
        }

        throw new Error("Invalid state transition");
      case CUSTOMER_SET_DEFAULT_PAYMENT_METHOD_RESPONSE:
        if (state.state === "UPDATING") {
          return updateData({
            state: "LOGGED_IN",
            data: msg.customer,
          });
        }

        throw new Error("Invalid state transition");

      case CUSTOMER_CREATE_ACCOUNT_REQUEST:
        if (state.state !== "LOGGED_IN") {
          return updateData({ state: "CREATING_ACCOUNT" }, msg);
        }

        throw new Error("Invalid state transition");
      case CUSTOMER_CREATE_ACCOUNT_RESPONSE:
        if (state.state === "CREATING_ACCOUNT") {
          if (msg.data) {
            return updateData({ state: "LOGGED_IN", data: msg.data });
          }

          return updateData({ state: "NOT_LOGGED_IN" });
        }

        throw new Error("Invalid state transition");

      default:
    }
  },
};

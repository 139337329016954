/* @flow */

import React, { useState, useEffect } from "react";
import { useTranslate } from "@out-of-home/use-translate";
import { useBrowser } from "../../helpers/use-browser";
import styles from "./styles.scss";
import cn from "classnames";
import { Button } from "../Button";
import { range } from "../../helpers";

import { addDays } from "date-fns";
import { startOfWeek } from "date-fns";
import { differenceInCalendarDays } from "date-fns";

import ChevronIcon from "../../icons/chevron.svg";

type Props = {
  className?: string,
  value: Date,
  onChange: (date: Date) => void,
  min: Date,
  max?: Date,
  isDayEnabled?: (d: Date) => boolean,
  pageSize?: number,
  pageJump?: number,
};

const WEEK_DAYS_SV_SE = ["Sön", "Mån", "Tis", "Ons", "Tors", "Fre", "Lör"];

const MONTHS_SV_SE = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Maj",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Okt",
  "Nov",
  "Dec",
];

export const DatePickerSimple = ({
  value,
  min,
  max,
  onChange,
  className,
  isDayEnabled,
  pageSize = 5,
  ...props
}: Props): React$Node => {
  const browser = useBrowser();
  const pageJump =
    typeof props.pageJump === "number" ? props.pageJump : pageSize;
  const t = useTranslate();
  const [prevPage, setPrevPage] = useState<number | null>(null);
  const [page, setPage] = useState<number>(0);
  const week = startOfWeek(min, { weekStartsOn: 1 });
  const numPages = max
    ? Math.ceil(differenceInCalendarDays(max, min) / pageJump) + 1
    : 0;
  const days = range(0, pageSize - 1).map((x) =>
    addDays(week, x + page * pageJump)
  );
  const reverseIndecies = days.map((_, i) => i).reverse();

  const prev = (e: MouseEvent) => {
    e.preventDefault();
    setPrevPage(page);
    setPage(page - 1);
  };

  const next = (e: MouseEvent) => {
    e.preventDefault();
    setPrevPage(page);
    setPage(page + 1);
  };

  const clickDay = (e: MouseEvent, date: Date) => {
    e.preventDefault();
    onChange(date);
  };

  useEffect(() => {
    if (min) {
      const week = startOfWeek(min, { weekStartsOn: 1 });
      const diff = differenceInCalendarDays(value, week);
      setPage(Math.floor(diff / pageJump));
    }
  }, [value]);

  // Skip rendering on server because of weird bug where the server classnames would persist
  if (!browser) {
    return null;
  }

  return (
    <div
      className={cn(
        styles.block,
        className,
        { [styles.block__animateIn]: prevPage === null },
        { [styles.block__animateRight]: (prevPage || 0) <= page },
        { [styles.block__animateLeft]: (prevPage || 0) > page },
        styles[`pageSize__${pageSize}`]
      )}
    >
      <Button
        className={cn(styles.nav, styles.prev)}
        disabled={page <= 0}
        onClick={prev}
      >
        <ChevronIcon />
      </Button>

      {days.map((x, i) => {
        const active =
          value &&
          value.getMonth() === x.getMonth() &&
          value.getFullYear() === x.getFullYear() &&
          value.getDate() === x.getDate();
        return (
          <Button
            key={x.getTime()}
            disabled={!(isDayEnabled === undefined || isDayEnabled(x))}
            onClick={(e) => clickDay(e, x)}
            style={{
              animationDelay: `${
                ((prevPage || 0) > page ? i : reverseIndecies[i]) * 50
              }ms`,
            }}
            className={cn(styles.day, { [styles["dayActive"]]: active })}
          >
            <div className={styles.day__date}>
              {x.getDate()} {MONTHS_SV_SE[x.getMonth()]}
            </div>
            <div className={styles.day__weekday}>
              {WEEK_DAYS_SV_SE[x.getDay()]}
            </div>
          </Button>
        );
      })}

      <Button
        className={cn(styles.nav, styles.next)}
        disabled={page > numPages - 1}
        onClick={next}
      >
        <ChevronIcon />
      </Button>
    </div>
  );
};

/* @flow */

import type { AlcoholLicense } from "../../types/alcohol-license.flow";
import type { CustomerAddressInput } from "../../types/customer.flow";
import type { FormData } from "../../helpers/formHelpers";
import type { FormData as AlcoholLicenseFieldSetData } from "../AlcoholLicenseFieldset";

import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useSendMessage } from "crustate/react";
import cn from "classnames";
import styles from "./styles.scss";
import { createAccount } from "../../state/customer";
import { useTranslate } from "@out-of-home/use-translate";
import { Wrapper } from "../Wrapper";
import { Button } from "../Button";
import { Dropdown, DropdownItem } from "../Dropdown";
import { Pills, MultiSelectPills } from "../Pills";
import { Field } from "../Field";
import { NewEmailInput } from "../NewEmailInput";
import { Checkbox } from "../Checkbox";
import { FormErrors } from "../FormErrors";
import { AlcoholLicenseFieldset } from "../AlcoholLicenseFieldset";
import { NotFoundView } from "../NotFoundView";
import { TimeContext } from "../../context/time";
import { PriceGroups, PriceSlider } from "../PriceSlider";
import TickIcon from "../../icons/checkmark.svg";
import CloseIcon from "../../icons/close.svg";
import { RangeSlider } from "../../components/RangeSlider";
import { focusInvalidField } from "../../helpers/formHelpers";
import { isOrganizationNumber } from "../../helpers/checkoutHelpers";
import { useFormat } from "../../helpers/use-format";
import { range } from "../../helpers";
import {
  emailValid,
  lengthLte,
  isPNO,
  arrayGte,
  isPassword,
  isPostcode,
  nestedRule,
} from "../../helpers/validationRules";
import { parseParams } from "@out-of-home/location-search-string";
import { useCustomer } from "../../helpers/use-customer";
import { alcoholLicenseValidationRules } from "../../components/AccountView/AlcoholLicenseForm";

import { Form, rules, conditional, isRequired, match, isEmail } from "@awardit/formaggio";

import alcoholLicenses from "../../effects/alcohol-licenses";
import { StoreInfoContext } from "../../entrypoint/shared";
import { Header } from "./header";
import { AnalyticsContext } from "../../helpers/analytics/context";

type FormState = {
  customer: {
    firstname: string,
    lastname: string,
    email: string,
    password: string,
    customer_pno: string,
    hasAlcoholLicense: boolean,
    attributes: {
      vendor_id: ?number,
      registration_code: string,
      ooh3_type: string,
      ooh3_subtype: Array<string>,
      ooh3_productpreferences: Array<string>,
      price_group_slider_value: number,
    },
  },
  address: CustomerAddressInput,
  alcoholLicense: ?AlcoholLicenseFieldSetData,
};

const titleAsId = (a) => a.map((x) => ({ id: String(x.title), title: String(x.title) }));

const getSuccessCopy = (x: string): [string, string] => {
  // Gym
  if (x === "Gym/Träning/Förening") {
    return [
      "Redo att lyfta!",
      `<p>
        Vi tror att raka rör och öppen handel skapar en rättvis marknad med värde för alla. Vi vill ta producenter och kunder närmare varandra. Vi vill förändra branchen.
      </p>
      <p>Du vill förmodligen bara handla varor. Då har du hittat rätt.</p>`,
    ];
    // Kontor
  } else if (x === "Kontor/Arbetsplats") {
    return [
      "Dagens viktigaste uppgift är snart klar.",
      `<p>
        Undersökningar (på vårt kontor) visar att alla jobbar bättre, hårdare och snabbare om det finns gott kaffe och massor, massor, massor av choklad, godis och läsk.
      </p>
      <p>Vi har förberett en startsida för er som baseras på vad andra arbetsplatser handlar.</p>`,
    ];
    // Default
  } else {
    return [
      "Vi tar upp matchen mot de stora grosshandlarna.",
      `<p>
        Vi tror att raka rör och öppen handel skapar en rättvis marknad med värde för alla.
      </p>
      <p>Kul att ni är med&excl; /Outofhome-teamet</p>`,
    ];
  }
};

// When the customer selects "säljare" we'll display an extra field
export const VENDOR_ID_SALESMAN = 364;

const step1Valid = rules([
  nestedRule(
    "customer",
    rules([nestedRule("attributes", rules([isRequired("ooh3_type"), arrayGte("ooh3_subtype", 1)]))]),
  ),
]);

const step2Valid = rules([
  nestedRule(
    "customer",
    rules([
      isEmail("email"),
      emailValid("email", "emailAvailable"),
      isRequired("password"),
      isPassword("password"),
      match("password", "password_repeat"),
      nestedRule("attributes", rules([isRequired("vendor_id")])),
    ]),
  ),
  nestedRule("address", rules([isRequired("firstname"), isRequired("lastname"), isRequired("telephone")])),
]);

const step3Valid = rules([
  nestedRule("customer", rules([isRequired("customer_pno"), isPNO("customer_pno")])),
  nestedRule(
    "address",
    rules([
      isRequired("company"),
      nestedRule("street", rules([isRequired("0"), lengthLte("1", 20)])),
      isRequired("postcode"),
      isPostcode("postcode"),
      isRequired("city"),
    ]),
  ),
  isRequired("accepted"),
  conditional(
    (s) => typeof s.customer === "object" && Boolean(s.customer.hasAlcoholLicense),
    nestedRule("alcoholLicense", rules(alcoholLicenseValidationRules)),
  ),
]);

const allStepsValid = rules([step1Valid, step2Valid, step3Valid]);

export const RegisterView = (): React$Node => {
  const { vendors, customerTypes } = useContext(StoreInfoContext);
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const { loggedIn } = useCustomer();
  const location = useLocation();
  const { code } = parseParams(location.search);
  const { push } = useHistory();
  const params = useParams();
  const { now } = useContext(TimeContext);
  const [accepted, setAccepted] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [emailAvailable, setEmailAvailable] = useState(false);
  const [highestPreviousStep, setHighestPreviousStep] = useState(0);
  const currentStepRef = useRef<number>(0);
  const [submitting, setSubmitting] = useState(false);
  const [showStepErrors, setShowStepErrors] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const { registerBeginSignUp, registerSignUpStep } = useContext(AnalyticsContext);

  // Redirect customer after login or if already logged in
  useEffect(() => {
    if (loggedIn && !showSuccess && !submitting) {
      push("/");
    }
  }, [loggedIn, showSuccess]);

  const focusInvalid = useCallback(
    (e, errors) => {
      focusInvalidField(e, errors);
      setShowStepErrors(true);
    },
    [setShowStepErrors],
  );

  const [formState, setState] = useState<FormState>({
    customer: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      customer_pno: "",
      hasAlcoholLicense: false,
      attributes: {
        vendor_id: null,
        registration_code: typeof code === "string" ? code : "",
        ooh3_type: "",
        ooh3_subtype: [],
        ooh3_productpreferences: [],
        price_group_slider_value: 1,
      },
    },
    address: {
      email: "",
      firstname: "",
      lastname: "",
      street: [],
      postcode: "",
      company: "",
      city: "",
      telephone: "",
      countryCode: "",
    },
    alcoholLicense: null,
  });

  const state = {
    ...formState,
    accepted,
    customer: {
      ...formState.customer,
      emailAvailable,
    },
  };

  const step = parseInt(params.step, 10) || 1;

  useEffect(() => {
    if (step === 1) {
      registerBeginSignUp();
    }
  }, []);

  useEffect(() => {
    if (step > 1) {
      registerSignUpStep(step);
    }
  }, [step]);

  const stepFraction = (step - 0.5) / 4;

  const validate1 = step1Valid((state: any));
  const validate2 = step2Valid((state: any));
  const validate3 = step3Valid((state: any));
  const validateAll = allStepsValid((state: any));

  const renderStep1 = () => {
    const allSubTypes = state.customer.attributes.ooh3_type
      ? customerTypes
          .filter((x) => x.title === state.customer.attributes.ooh3_type)
          .map((x) => x.subtypes)[0]
          .map((x) => ({ id: String(x.id), title: x.title }))
      : [];
    const formStateOoh3type = customerTypes.find((x) => x.title === state.customer.attributes.ooh3_type);

    return (
      <Form
        key="step1"
        errors={validate1}
        value={(state: any)}
        onError={focusInvalidField}
        onChange={updateRegistrationForm}
        onSubmit={nextStep}
        className={styles.form}
      >
        <h2 className={styles.title}>Vad kallar folk er?</h2>

        <Field
          name="type"
          render={({ errors, dirty }) => (
            <div className={styles.segment}>
              <Pills
                items={titleAsId(customerTypes)}
                active={state.customer.attributes.ooh3_type || null}
                onClick={setType}
                layout="twosplit"
              />
              <FormErrors errors={errors} dirty={dirty} getMessage={() => t("STATUS_CODE.-6100")} />
            </div>
          )}
        />

        {allSubTypes && allSubTypes.length > 0 && formStateOoh3type && (
          <div className={styles.row}>
            <h2 className={styles.title}>
              {t("REGISTER.SUB_TYPES_TITLE", {
                customer_type: formStateOoh3type.title,
              })}{" "}
              <small>{t("REGISTER.YOU_CAN_CHOOSE_MULTIPLE")}</small>
            </h2>
            <Field
              name="subType"
              render={({ errors, dirty }) => (
                <div className={styles.subSegment}>
                  <MultiSelectPills
                    items={titleAsId(allSubTypes)}
                    active={state.customer.attributes.ooh3_subtype}
                    onClick={setSubType}
                    layout="twosplit"
                  />
                  <FormErrors errors={errors} dirty={dirty} getMessage={() => t("STATUS_CODE.-6101")} />
                </div>
              )}
            />
          </div>
        )}

        <div className={styles.filler} />

        <div className={cn(styles.row, styles.rowSubmit)}>
          <div className={styles.rowSubmitInner}>
            <Button
              className={styles.submit}
              data-show={state.customer.attributes.ooh3_type && state.customer.attributes.ooh3_subtype.length > 0}
              type="submit"
              variant="primary"
            >
              {t("REGISTER.PROCEED")}
            </Button>
          </div>
        </div>
      </Form>
    );
  };

  const renderStep2 = () => {
    const activeVendors = vendors.map((x) => ({ id: String(x.vendorId), title: x.value }));

    return (
      <Form
        key="step2"
        errors={validate2}
        value={(state: any)}
        onError={focusInvalidField}
        onChange={updateRegistrationForm}
        onSubmit={nextStep}
        className={styles.form}
      >
        <section className={styles.section}>
          <h2 className={styles.title}>Inloggningsuppgifter</h2>
          <div className={styles.row}>
            <NewEmailInput
              name="customer.email"
              label={t("ADDRESS.EMAIL")}
              value={state.customer.email}
              variant="box"
              available={state.customer.emailAvailable}
              setAvailable={(x) => {
                setEmailAvailable(x);
              }}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.splitContainer}>
              <div className={styles.split}>
                <Field
                  name="customer.password"
                  label={t("ACCOUNT.PASSWORD")}
                  autoComplete="new-password"
                  type="password"
                  variant="box"
                />
              </div>
              <div className={styles.split}>
                <Field
                  name="customer.password_repeat"
                  label={t("ACCOUNT.PASSWORD_REPEAT")}
                  autoComplete="new-password"
                  type="password"
                  variant="box"
                />
              </div>
            </div>
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.title}>Kontakt</h2>

          <div className={styles.row}>
            <div className={styles.splitContainer}>
              <div className={styles.split}>
                <Field name="address.firstname" label={t("ADDRESS.FIRSTNAME")} autoComplete="email" variant="box" />
              </div>

              <div className={styles.split}>
                <Field name="address.lastname" label={t("ADDRESS.LASTNAME")} autoComplete="family-name" variant="box" />
              </div>
            </div>
          </div>
        </section>

        <div className={styles.row}>
          <div className={styles.splitContainer}>
            <div className={styles.split}>
              <Field
                name="address.telephone"
                autoComplete="tel"
                label={t("ADDRESS.TELEPHONE")}
                pattern="\d*"
                variant="box"
              />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.splitContainer}>
            <div className={styles.split}>
              <Field
                name="customer.attributes.vendor_id"
                render={(props) => (
                  <div>
                    <Dropdown
                      {...props}
                      label={t("REGISTER.HOW_DID_YOU_FIND_OUTOFHOME")}
                      headerContent={(x) => x.label}
                      className={styles.dropdown}
                      name="customer.attributes.vendor_id"
                      value={
                        state.customer.attributes.vendor_id !== null
                          ? String(state.customer.attributes.vendor_id)
                          : null
                      }
                      direction="up"
                      maxHeight={250}
                      onChange={setVendor}
                    >
                      {activeVendors.map((x) => (
                        <DropdownItem key={x.id} value={x.id}>
                          {x.title}
                        </DropdownItem>
                      ))}
                    </Dropdown>
                    <FormErrors
                      errors={props.errors}
                      dirty={props.submitted}
                      getMessage={() => "Vänligen välj ett alternativ"}
                    />
                  </div>
                )}
              />
            </div>
          </div>
        </div>

        {state.customer.attributes.vendor_id === VENDOR_ID_SALESMAN && (
          <div className={styles.row}>
            <div className={styles.splitContainer}>
              <div className={styles.split}>
                <div className={styles.row}>
                  <Field name="customer.referrerCode" label={t("ADDRESS.REFERRER_CODE")} variant="box" />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={styles.filler} />

        <div className={cn(styles.row, styles.rowSubmit)}>
          <div className={styles.rowSubmitInner}>
            <Button className={styles.submit} type="submit" data-show="true" variant="primary">
              {t("REGISTER.PROCEED")}
            </Button>
          </div>
        </div>
      </Form>
    );
  };

  const renderStep3 = () => {
    return (
      <Form
        key="step3"
        onSubmit={nextStep}
        onChange={updateRegistrationForm}
        onError={focusInvalid}
        errors={validateAll}
        value={(state: any)}
        className={styles.form}
      >
        <h2 className={styles.title}>{t("REGISTER.STEP_3")}</h2>
        <div className={styles.row}>
          <Field name="customer.customer_pno" label={t("ADDRESS.ORG_NUMBER")} variant="box" />
        </div>
        <div className={styles.row}>
          <Field name="address.company" label={t("REGISTER.COMPANY")} autoComplete="organization" variant="box" />
        </div>
        <div className={styles.row}>
          <Field name="address.street.0" label={t("ADDRESS.STREET")} autoComplete="address-line1" variant="box" />
        </div>
        <div className={styles.row}>
          <Field name="address.street.1" label={t("ADDRESS.STREET_2")} autoComplete="address-line2" variant="box" />
        </div>
        <div className={styles.row}>
          <Field name="address.postcode" label={t("ADDRESS.POSTCODE")} autoComplete="postal-code" variant="box" />
        </div>
        <div className={styles.row}>
          <Field name="address.city" label={t("ADDRESS.CITY")} autoComplete="city" variant="box" />
        </div>

        <div className={styles.row}>
          <Field name="customer.attributes.registration_code" label={t("ACCOUNT.REGISTRATION_CODE")} variant="box" />
        </div>

        <div className={styles.rowLarge}>
          <Field
            name="customer.hasAlcoholLicense"
            id="customer.hasAlcoholLicense"
            value={state.customer.hasAlcoholLicense ? "checked" : ""}
            render={(props) => (
              <Checkbox
                {...props}
                name="customer.hasAlcoholLicense"
                id="customer.hasAlcoholLicense"
                value={state.customer.hasAlcoholLicense}
                onChange={toggleHasAlcoholLicense}
                className={styles.checkbox}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: t("REGISTER.HAS_ALCOHOL_LICENSE"),
                  }}
                />
              </Checkbox>
            )}
          />
        </div>

        {state.customer.hasAlcoholLicense && state.alcoholLicense && (
          <div className={styles.row}>
            <AlcoholLicenseFieldset
              className={styles.alcoholLicenseFields}
              prefix="alcoholLicense"
              data={state.alcoholLicense}
              setData={onChangeAlcoholLicenseState}
            />
          </div>
        )}

        <div className={styles.row}>
          <div className={styles.splitContainer}>
            <div className={styles.split}>
              <Field
                name="accepted"
                id="accepted"
                value={accepted ? "checked" : ""}
                render={(props) => (
                  <div className={styles.agreementMessage}>
                    <Checkbox
                      {...props}
                      id="terms"
                      name="terms"
                      value={accepted}
                      onChange={() => setAccepted(!accepted)}
                      className={styles.checkbox}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `
                          ${t("REGISTER.TERMS_MESSAGE")}
                          <a class="link" href="${"/" + t("ROUTES.TERMS")}" target="_blank">
                            ${t("REGISTER.TERMS_AND_CONDITIONS")}
                          </a>
                            ${t("REGISTER.AND")}
                          <a class="link" href="${"/" + t("ROUTES.PRIVACY")}"" target="_blank">
                            ${t("REGISTER.PRIVACY_POLICY")}
                          </a>
                        `,
                        }}
                      />
                    </Checkbox>
                  </div>
                )}
              />
            </div>
          </div>
        </div>

        <div className={styles.filler} />

        <div className={cn(styles.row, styles.rowSubmit)}>
          <div className={styles.rowSubmitInner}>
            <Button className={styles.submit} type="submit" data-show="true" variant="primary">
              {t("REGISTER.PROCEED")}
            </Button>
          </div>
        </div>
      </Form>
    );
  };

  const renderStep4 = () => {
    return (
      <div className={styles.form}>
        {state.customer.attributes.registration_code && (
          <>
            <h1 className={styles.title}>{t("REGISTER.STEP_4_TITLE_WITH_REGISTRATION_CODE")}</h1>
            <p className={styles.largeDescription}>{t("REGISTER.STEP_4_BODY_WITH_REGISTRATION_CODE")}</p>
          </>
        )}
        {!state.customer.attributes.registration_code && (
          <>
            <h1 className={styles.title}>{t("REGISTER.STEP_4_TITLE")}</h1>
            <p className={styles.largeDescription}>{t("REGISTER.STEP_4_BODY")}</p>
          </>
        )}

        <Form
          key="step4"
          onSubmit={submitForm}
          onChange={updateRegistrationForm}
          onError={focusInvalid}
          errors={validateAll}
          value={(state: any)}
          className={styles.form}
        >
          {state.customer.attributes.registration_code && (
            <div className={styles.row}>
              <div className={styles.splitContainer}>
                <div className={styles.split}></div>
                <div className={styles.splitRight}>
                  <Button className={styles.submit} type="submit" loading={submitting} variant="primary">
                    {t("REGISTER.SUBMIT_WITH_REGISTER_CODE")}
                  </Button>
                </div>
              </div>
            </div>
          )}

          {!state.customer.attributes.registration_code && (
            <div className={styles.row}>
              <PriceSlider
                value={formState.customer.attributes.price_group_slider_value}
                ooh3Type={state.customer.attributes.ooh3_type}
                onChange={(x) => {
                  updateRegistrationForm({
                    ...formState,
                    customer: {
                      ...formState.customer,
                      attributes: {
                        ...formState.customer.attributes,
                        price_group_slider_value: x,
                      },
                    },
                  });
                }}
              />
              <PriceGroups ooh3Type={state.customer.attributes.ooh3_type} />
            </div>
          )}

          <div className={styles.filler} />

          <div className={cn(styles.row, styles.rowSubmit)}>
            <div className={styles.rowSubmitInner}>
              <Button className={styles.submit} type="submit" data-show="true" variant="primary">
                {t("REGISTER.PROCEED")}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    );
  };

  const steps = [{ render: renderStep1 }, { render: renderStep2 }, { render: renderStep3 }, { render: renderStep4 }];

  if (step <= 0 || step > steps.length) {
    return <NotFoundView />;
  }

  const submitForm = (e) => {
    e.preventDefault();

    setSubmitting(true);

    /*
    const mockData = {
      address: {
        city: "Göteborg",
        company: "Out of Home",
        countryCode: "SE",
        firstname: "Peter",
        lastname: "Asplund",
        postcode: "41140",
        street: ["Skånegatan 16"],
        telephone: "0768795050",
      },
      alcoholLicense: {
        day: "7",
        filename: "foo.jpg",
        data: "this is data",
        month: "9",
        year: "2021",
      },
      customer: {
        attributes: {
          vendor_id: 364,
          registration_code: '2345',
          ooh3_type: "Café",
          ooh3_subtype: ['Café med lagad mat'],
          ooh3_productpreferences: ['Fairtrade', 'Nyheter'],
        },
        company: "",
        countryCode: "SE",
        customer_pno: "123123-123123-123-123-123-123123-",
        defaultPaymentMethod: "DibsD2",
        email: "peterasplund" + Math.random() * 1000 + "@gmail.com",
        emailAvailable: true,
        firstname: "Peter",
        lastname: "Asplund",
        password: "abc123123",
        password_repeat: "abc123123",
        telephone: "0768795050",
        hasAlcoholLicense: true,
      },
    };

    sendMessage(createAccount(mockData));
    return;
    */

    const vendor_id = Number.isInteger(formState.customer.attributes.vendor_id)
      ? vendors.find((v) => v.vendorId === formState.customer.attributes.vendor_id)
      : null;

    const data = {
      customer: {
        ...formState.customer,
        defaultPaymentMethod: isOrganizationNumber(formState.customer.customer_pno) ? "Crossroads_Collector" : "DibsD2",
        firstname: formState.address.firstname,
        lastname: formState.address.lastname,
        company: formState.address.company,
        attributes: {
          ...formState.customer.attributes,
          vendor_id: vendor_id?.value,
        },
      },
      address: {
        ...formState.address,
        countryCode: t("LOCALE.SHORT"),
        street: formState.address.street,
        isDefaultBillingAddress: true,
        isDefaultShippingAddress: true,
        postcode: formState.address.postcode.replaceAll(" ", ""),
      },
      alcoholLicense: formState.alcoholLicense
        ? {
            day: formState.alcoholLicense.day || "",
            month: formState.alcoholLicense.month || "",
            year: formState.alcoholLicense.year || "",
            filename: formState.alcoholLicense.filename || "",
            data: formState.alcoholLicense.data || "",
          }
        : null,
    };

    if (typeof data.customer.attributes.vendor_id === "string") {
      sendMessage(createAccount(data))
        .then((_) => {
          setShowSuccess(true);
        })
        .finally((_) => {
          setSubmitting(false);
        });
    }
  };

  const nextStep = (e) => {
    e.preventDefault();

    push(`/${t("ROUTES.REGISTER")}/${step + 1}`);

    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  const setType = (value: Object) => {
    setState({
      ...state,
      customer: {
        ...state.customer,
        attributes: {
          ...state.customer.attributes,
          ooh3_type: value,
          ooh3_subtype: [],
          price_group_slider_value: 1,
        },
      },
    });
  };

  const setSubType = (value: Object) => {
    setState({
      ...state,
      customer: {
        ...state.customer,
        attributes: {
          ...state.customer.attributes,
          ooh3_subtype: value,
          price_group_slider_value: 1,
        },
      },
    });
  };

  const updateRegistrationForm = (state: any) => {
    setState(state);
  };

  const setVendor = (x) => {
    if (x === VENDOR_ID_SALESMAN) {
      setTimeout(() => {
        window.scroll({
          top: 1000,
          behavior: "smooth",
        });
      }, 0);
    }

    setState({
      ...state,
      customer: {
        ...state.customer,
        attributes: {
          ...state.customer.attributes,
          vendor_id: parseInt(x.value, 10),
        },
      },
    });
  };

  const toggleHasAlcoholLicense = () => {
    if (state.customer.hasAlcoholLicense) {
      setState({
        ...state,
        customer: {
          ...state.customer,
          hasAlcoholLicense: false,
        },
        alcoholLicense: null,
      });
    } else {
      setState({
        ...state,
        customer: {
          ...state.customer,
          hasAlcoholLicense: true,
        },
        alcoholLicense: {
          filename: null,
          data: null,
          address: null,
          year: String(now.getFullYear()),
          month: String(now.getMonth() + 1),
          day: String(now.getDate()),
        },
      });
    }
  };

  const allowLastStep = validateAll.length === 0;

  if (!allowLastStep && step === 4) {
    push("/");
    //return null;
  }

  const onChangeAlcoholLicenseState = (x: AlcoholLicenseFieldSetData) => {
    setState({ ...state, alcoholLicense: { ...x } });
  };

  const currentStep = steps[step - 1];

  useEffect(() => {
    if (step !== currentStepRef.current) {
      if (currentStepRef.current > highestPreviousStep) {
        setHighestPreviousStep(currentStepRef.current);
      }

      currentStepRef.current = step;
    }
  }, [step]);

  if (showSuccess) {
    const [successTitle, successBody] = getSuccessCopy(state.customer.attributes.ooh3_type);

    return (
      <div className={styles.success}>
        <Wrapper className={styles.success__wrapper} variant="small">
          <svg
            className={styles.success__icon}
            width="128"
            height="128"
            viewBox="0 0 128 128"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M56.4706 86.1299L37.8762 67.5355L44.9473 60.4645L56.4706 71.9877L83.0527 45.4056L90.1237 52.4767L56.4706 86.1299ZM64 118C93.8234 118 118 93.8234 118 64C118 34.1766 93.8234 10 64 10C34.1766 10 10 34.1766 10 64C10 93.8234 34.1766 118 64 118ZM64 128C99.3462 128 128 99.3462 128 64C128 28.6538 99.3462 0 64 0C28.6538 0 0 28.6538 0 64C0 99.3462 28.6538 128 64 128Z"
              fill="#79DB8D"
            />
          </svg>

          <div className={styles.success__content}>
            <h1>{successTitle}</h1>
            <div dangerouslySetInnerHTML={{ __html: successBody }} />
            <Button
              variant="primary"
              loading={isRedirecting}
              onClick={(e) => {
                e.preventDefault();
                setIsRedirecting(true);
                window.location.href = "/";
              }}
            >
              Börja handla!
            </Button>
          </div>
        </Wrapper>
      </div>
    );
  }

  return (
    <div className={styles.block}>
      <Header />
      <div className={styles.content}>
        <Wrapper className={styles.wrapper} variant="small">
          <header className={styles.subHeader}>
            <nav className={styles.steps}>
              <Button to={"/" + t("ROUTES.REGISTER") + "/1"}>
                {t("REGISTER.STEP_1")}
                {(highestPreviousStep >= 1 || showStepErrors) && (
                  <>
                    {validate1.length === 0 && <TickIcon className={styles.positive} />}
                    {validate1.length > 0 && <CloseIcon className={styles.negative} />}
                  </>
                )}
              </Button>
              <Button to={"/" + t("ROUTES.REGISTER") + "/2"}>
                {t("REGISTER.STEP_2")}
                {(highestPreviousStep >= 2 || showStepErrors) && (
                  <>
                    {validate2.length === 0 && <TickIcon className={styles.positive} />}
                    {validate2.length > 0 && <CloseIcon className={styles.negative} />}
                  </>
                )}
              </Button>
              <Button to={"/" + t("ROUTES.REGISTER") + "/3"}>
                {t("REGISTER.STEP_3")}
                {(highestPreviousStep >= 3 || showStepErrors) && (
                  <>
                    {validate3.length === 0 && <TickIcon className={styles.positive} />}
                    {validate3.length > 0 && <CloseIcon className={styles.negative} />}
                  </>
                )}
              </Button>
              <Button
                className={cn({ [styles.disabled]: !allowLastStep })}
                to={"/" + t("ROUTES.REGISTER") + "/4"}
                disabled={!allowLastStep}
                onClick={(e) => {
                  if (!allowLastStep) {
                    e.preventDefault();
                  }
                }}
              >
                {t("REGISTER.STEP_4")}
              </Button>
            </nav>
            <div className={styles.meter}>
              <div className={styles.meterFill} style={{ transform: "scaleX(" + stepFraction + ")" }} />
            </div>
          </header>
          <div className={styles.stepContent}>{currentStep.render()}</div>
        </Wrapper>
      </div>
      <Helmet title={t("REGISTER.TITLE")} />
    </div>
  );
};

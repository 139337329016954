/* @flow */

import type { SetQuoteAddressInput } from "../../../../types/quote.flow";
import type { CustomerAddress, CustomerAddressInput } from "../../../../types/customer.flow";
import type { Item } from "../../../Dropdown/context";

import React, { useCallback, useContext, useState } from "react";
import cn from "classnames";
import { useSendMessage } from "crustate/react";
import styles from "./styles.scss";
import { useTranslate } from "@out-of-home/use-translate";
import { useCustomer } from "../../../../helpers/use-customer";
import { Checkbox } from "../../../Checkbox";
import { Button } from "../../../Button";
import { Field } from "../../../Field";
import { Well } from "../../../Well";
import { NewEmailInput } from "../../../NewEmailInput";
import RemoveIcon from "../../../../icons/close.svg";
import {
  nestedRule,
  emailValid,
  isPostcode,
  isPNO,
  isPassword,
} from "../../../../helpers/validationRules";
import { focusInvalidField } from "../../../../helpers/formHelpers";
import { isOrganizationNumber } from "../../../../helpers/checkoutHelpers";
import { checkoutSaveCustomer } from "../../../../state/quote";

import { Form, rules, conditional, isRequired, isEmail, match } from "@awardit/formaggio";
import { Dropdown, DropdownItem } from "../../../Dropdown";
import { StoreInfoContext } from "../../../../entrypoint/shared";

type Props = {};

export const customerAddressToCustomerAddressInput = (
  address: CustomerAddress,
): CustomerAddressInput | { ...CustomerAddressInput, id: string } => {
  const { country, isDefaultBilling, isDefaultShipping, ...rest } = address;

  return {
    ...rest,
    countryCode: country.code,
  };
};

const validations = rules([
  isRequired("accepted"),
  nestedRule(
    "address",
    rules([
      isRequired("firstname"),
      isRequired("lastname"),
      isRequired("company"),
      nestedRule("street", rules([isRequired("0")])),
      isRequired("postcode"),
      isPostcode("postcode"),
      isRequired("city"),
      isRequired("telephone"),
    ]),
  ),

  nestedRule(
    "customer",
    rules([
      isEmail("email"),
      emailValid("email", "emailAvailable"),
      isRequired("password"),
      isPassword("password"),
      match("password", "password_repeat"),
      isRequired("customer_pno"),
      isPNO("customer_pno"),
    ]),
  ),
]);

export const NewCustomer = ({}: Props): React$Node => {
  const t = useTranslate();
  const { customerTypes } = useContext(StoreInfoContext);
  const sendMessage = useSendMessage();
  const [isProcessing, setIsProcessing] = useState(false);
  const [emailAvailable, setEmailAvailable] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [state, setState] = useState<any>({
    address: {
      firstname: "",
      lastname: "",
      company: "",
      telephone: "",
      street: [],
      postcode: "",
      countryCode: "SE",
      city: "",
    },
    customer: {
      email: "",
      password: "",
      password_repeat: "",
      customer_pno: "",
      attributes: {
        ooh3_type: null,
      }
    },
  });

  const formState = {
    ...state,
    accepted,
    customer: {
      ...state.customer,
      emailAvailable,
    },
  };

  const errors = validations((formState: any));

  const setCustomerType = useCallback((item: Item) => {
    setState(state => ({
      ...state,
      customer: {
        ...state.customer,
        attributes: {
          ...state.customer.attributes,
          ooh3_type: item.value
        }
      }
    }))
  }, [])

  const submit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { emailAvailable, ...newAddress } = state;

    const data = {
      customer: {
        ...state.customer,
        defaultPaymentMethod: isOrganizationNumber(state.customer.customer_pno)
          ? "Crossroads_Collector"
          : "DibsD2",
        firstname: state.address.firstname,
        lastname: state.address.lastname,
        company: state.address.company,
        attributes: {
          ...state.customer.attributes,
        },
      },
      address: {
        ...state.address,
        countryCode: "SE",
        street: Object.values(state.address.street),
        postcode: state.address.postcode.replaceAll(" ", ""),
      },
      alcoholLicense: null,
    };

    setIsProcessing(true);

    sendMessage(checkoutSaveCustomer(data)).finally((x) => {
      setIsProcessing(false);
    });
  };

  return (
    <Form
      value={(formState: any)}
      onChange={(x: any) => setState(x)}
      onSubmit={submit}
      errors={errors}
      onError={focusInvalidField}
    >
      <h3>Skapa konto</h3>

      <div className={styles.row}>
        <NewEmailInput
          name="customer.email"
          label={t("ADDRESS.EMAIL")}
          variant="box"
          value={state.customer.email}
          available={emailAvailable}
          setAvailable={setEmailAvailable}
          required
        />
      </div>
      <div className={styles.row}>
        <Field
          name="customer.customer_pno"
          label={t("ADDRESS.ORG_NUMBER")}
          variant="box"
          required
        />
      </div>
      <div className={styles.row}>
        <Field
          name="customer.password"
          label={t("ACCOUNT.PASSWORD")}
          autoComplete="new-password"
          type="password"
          variant="box"
          required
        />
        <Field
          name="customer.password_repeat"
          label={t("ACCOUNT.PASSWORD_REPEAT")}
          autoComplete="new-password"
          type="password"
          variant="box"
          required
        />
      </div>

      <div className={styles.row}>
        <Field
          name="address.firstname"
          label={t("ADDRESS.FIRSTNAME")}
          autoComplete="given-name"
          variant="box"
          required
        />

        <Field
          name="address.lastname"
          label={t("ADDRESS.LASTNAME")}
          autoComplete="family-name"
          variant="box"
          required
        />
      </div>

      <div className={styles.row}>
        <Field
          name="address.company"
          label={t("ADDRESS.COMPANY")}
          autoComplete="organization"
          variant="box"
          required
        />

        <Field
          name="address.street.0"
          label={t("ADDRESS.STREET")}
          autoComplete="address-line1"
          variant="box"
          required
        />
      </div>

      <div className={styles.row}>
        <Field
          name="address.postcode"
          label={t("ADDRESS.POSTCODE")}
          autoComplete="postal-code"
          variant="box"
          required
        />

        <Field
          name="address.city"
          label={t("ADDRESS.CITY")}
          autoComplete="city"
          variant="box"
          required
        />
      </div>

      <div className={styles.row}>
        <Field
          name="address.telephone"
          label={t("ADDRESS.TELEPHONE")}
          autoComplete="tel"
          variant="box"
          required
        />

        <Field
          name="address.street.1"
          label={t("ADDRESS.STREET_2")}
          autoComplete="address-line2"
          variant="box"
        />
      </div>

      <div className={styles.row}>
        <Field
          name="customer.attributes.registration_code"
          label={t("ACCOUNT.REGISTRATION_CODE")}
          variant="box"
        />
      </div>

      <div className={styles.row}>
        <Field
          name="customer.attributes.ooh3_type"
          render={(props) => (
            <div>
              <Dropdown
                {...props}
                label={"Vad kallar folk er?"}
                value={state.customer.attributes.ooh3_type}
                maxHeight={250}
                onChange={setCustomerType}
              >
                {customerTypes.map((x) => (
                  <DropdownItem key={x.id} value={x.title}>
                    {x.title}
                  </DropdownItem>
                ))}
              </Dropdown>
            </div>
          )}
        />
      </div>

      <div className={cn(styles.row, styles.terms)}>
        <Field
          name="accepted"
          id="accepted"
          value={accepted ? "checked" : ""}
          render={(props) => (
            <div className={styles.agreementMessage}>
              <Checkbox
                {...props}
                id="terms"
                name="terms"
                value={accepted}
                onChange={() => setAccepted(!accepted)}
                className={styles.checkbox}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: `
                  ${t("REGISTER.TERMS_MESSAGE")}
                  <a class="link" href="${"/" + t("ROUTES.TERMS")}" target="_blank">
                    ${t("REGISTER.TERMS_AND_CONDITIONS")}
                  </a>
                    ${t("REGISTER.AND")}
                  <a class="link" href="${"/" + t("ROUTES.PRIVACY")}"" target="_blank">
                    ${t("REGISTER.PRIVACY_POLICY")}
                  </a>
                `,
                  }}
                />
              </Checkbox>
            </div>
          )}
        />
      </div>

      <div className={cn(styles.row, styles.submitRow)}>
        <Button
          className={styles.submit}
          type="submit"
          variant="primary"
          disabled={isProcessing}
          loading={isProcessing}
        >
          Bekräfta
        </Button>
      </div>
    </Form>
  );
};

/** @flow */

import type { Tab } from "../../types/header.flow";
import type { StoreInfo } from "../../types/storeInfo.flow";

export type Link = {
  target: string,
  text: string,
};

export type SectionLink = {
  type: "section_link",
  title: string,
  links: Link[],
};

export type TabContent = {
  main: SectionLink,
  second: SectionLink,
  third: SectionLink,
};

export const getContent = (tab: Tab): TabContent => {
  return SECTIONS[tab];
};

type Category = Link & { count: number };
export type Section = {
  count: number,
  categories: Array<Category>,
};
type MainSections = {
  [Tab]: Section,
};

export const getMainSections = (storeInfo: StoreInfo): MainSections => {
  return Object.keys(SECTIONS).reduce((acc, key: Tab) => {
    const links = SECTIONS[key].main.links;
    const categories: Category[] = [];
    let sectionCount = 0;

    for (const link of links) {
      const mc = storeInfo.footer.main_category.find(x => x.key === link.text);
      const count = mc?.count || 0;
      sectionCount += count;

      categories.push({
        ...link,
        count,
      });
    }

    acc[key] = {
      count: sectionCount,
      categories,
    };

    return acc;
  }, ({}: MainSections));
};

export const SECTIONS: {| [Tab]: TabContent |} = {
  drinks: {
    main: {
      title: "Kategorier",
      type: "section_link",
      links: [
        { text: "Kaffe, Te & Choklad", target: "/p/Kaffe,%20Te%20&%20Choklad" },
        { text: "Juice, Saft & Hälsodrycker", target: "/p/Juice,%20Saft%20&%20Hälsodrycker" },
        { text: "Läsk, Kolsyrat & Stilla", target: "/p/Läsk,%20Kolsyrat%20&%20Stilla" },
        { text: "Energi- & Sportdrycker", target: "/p/Energi-%20&%20Sportdrycker" },
        { text: "Drinkmixers", target: "/p/Drinkmixers" },
        { text: "Öl, Vin & Sprit", target: "/p/Öl,%20Vin%20&%20Sprit" },
      ],
    },
    second: {
      title: "Populära produkter",
      type: "section_link",
      links: [
        { text: "Kaffe", target: "/p/Kaffe,%20Te%20&%20Choklad?ooh_product_type=Kaffe" },
        { text: "Te", target: "/p/Kaffe,%20Te%20&%20Choklad?ooh_product_type=Te" },
        { text: "Iskaffe", target: "/p/Kaffe,%20Te%20&%20Choklad?ooh_product_type=Iskaffe" },
        { text: "Juice", target: "/p/Juice,%20Saft%20&%20Hälsodrycker?ooh_product_type=Juice" },
        { text: "Fruktdryck", target: "/p/Juice,%20Saft%20&%20Hälsodrycker?ooh_product_type=Fruktdryck" },
        { text: "Lemonad", target: "/p/Juice,%20Saft%20&%20Hälsodrycker?ooh_product_type=Lemonad" },
        { text: "Smoothie", target: "/p/Juice,%20Saft%20&%20Hälsodrycker?ooh_product_type=Smoothie" },
        { text: "Kombucha", target: "/p/Juice,%20Saft%20&%20Hälsodrycker?ooh_product_type=Kombucha" },
        { text: "Läsk & Soda", target: "/p/Läsk,%20Kolsyrat%20&%20Stilla?ooh_product_type=Läsk%20%26%20Soda" },
        { text: "Energidryck", target: "/p/Energi-%20&%20Sportdrycker?ooh_product_type=Energidrycker" },
        { text: "Funktionsdryck", target: "/p/Energi-%20&%20Sportdrycker?ooh_product_type=Funktionsdryck" },
      ],
    },
    third: {
      title: "Populära varumärken",
      type: "section_link",
      links: [
        { text: "Löfbergs", target: "/p/_Löfbergs" },
        { text: "Clean Drink", target: "/p/_Clean%20Drink" },
        { text: "Kiviks Musteri", target: "/p/_Kiviks%20Musteri" },
        { text: "Coca Cola", target: "/p/?manufacturer=Coca-Cola%20European%20Partners%20Sverige%20AB" },
        { text: "Monin", target: "/p/_Monin" },
        { text: "Lavazza", target: "/p/_Lavazza" },
        { text: "Vitamin Well", target: "/p?brand=Vitamin%20Well" },
        { text: "Zoegas", target: "/p/_Zoegas" },
        { text: "Red Bull", target: "/p/_Red%20Bull" },
        { text: "NOCCO", target: "/p?brand=NOCCO" },
        { text: "Gevalia", target: "/p/_Gevalia" },
      ],
    },
  },
  eatables: {
    main: {
      title: "Kategorier",
      type: "section_link",
      links: [
        { text: "Torrvaror", target: "/p/Torrvaror" },
        { text: "Konserver & Inläggningar", target: "/p/Konserver%20&%20Inläggningar" },
        { text: "Smaksättning", target: "/p/Smaksättning" },
        { text: "Olja, Vinäger & Sås", target: "/p/Olja,%20Vinäger%20&%20Sås" },
        { text: "Bröd & Bakprodukter", target: "/p/Bröd%20&%20Bakprodukter" },
        { text: "Ost, Mejeri & Ägg", target: "/p/Ost,%20Mejeri%20&%20Ägg" },
        { text: "Frukt & Grönt", target: "/p/Frukt%20&%20Grönt" },
        { text: "Kött & Fågel", target: "/p/Kött%20&%20Fågel" },
        { text: "Fisk & Skaldjur", target: "/p/Fisk%20&%20Skaldjur" },
        { text: "Vegetariskt", target: "/p/Vegetariskt" },
        { text: "Färdiglagat", target: "/p/Färdiglagat" },
      ],
    },
    second: {
      title: "Populära produkter",
      type: "section_link",
      links: [
        { text: "Pasta", target: "/p/Torrvaror?ooh_product_type=Pasta" },
        { text: "Mjöl", target: "/p/?ooh_product_type=Mjöl" },
        { text: "Kryddor", target: "/p/?ooh_product_type=Kryddor" },
        { text: "Sås", target: "/p/?ooh_product_type=Sås" },
        { text: "Bröd", target: "/p/?ooh_product_type=Bröd" },
        { text: "Grönsakskonserver", target: "/p/?ooh_product_type=Grönsakskonserv" },
        { text: "Frukt & Bär", target: "/p/?ooh_product_type=Frukt%20%26%20Bär" },
        { text: "Kyckling", target: "/p/?ooh_product_type=Kyckling" },
        { text: "Fisk", target: "/p/?ooh_product_type=Fisk" },
        { text: "Köttbullar", target: "/p/?ooh_product_type=Köttbullar" },
        { text: "Grönsaker", target: "/p/?ooh_product_type=Grönsaker" },
      ],
    },
    third: {
      title: "Populära varumärken",
      type: "section_link",
      links: [
        { text: "Santa Maria", target: "/p/_Santa%20Maria" },
        { text: "Pedros", target: "/p/_Pedros" },
        { text: "Felix", target: "/p/_Felix" },
        { text: "Fontana", target: "/p/_Fontana" },
        { text: "Magnihill", target: "/p/_Magnihill" },
        { text: "Zeta", target: "/p/_Zeta" },
        { text: "El Taco Truck", target: "/p/_El%20Taco%20Truck" },
        { text: "McCain", target: "/p/_McCain" },
        { text: "Scan", target: "/p/_Scan" },
        { text: "Feldts Fisk", target: "/p/_Feldt's%20Fisk" },
        { text: "Guldfågeln", target: "/p/_Guldfågeln" },
      ],
    },
  },
  snacks: {
    main: {
      title: "Kategorier",
      type: "section_link",
      links: [
        { text: "Godis & Choklad", target: "/p/Godis%20&%20Choklad" },
        { text: "Chips & Salta Snacks", target: "/p/Chips%20&%20Salta%20Snacks" },
        { text: "Bars & Hälsosnacks", target: "/p/Bars%20&%20Hälsosnacks" },
        { text: "Bakverk & Dessert", target: "/p/Bakverk%20&%20Dessert" },
        { text: "Glass & Tillbehör", target: "/p/Glass%20&%20Tillbehör" },
        { text: "Tuggummin & Tabletter", target: "/p/Tuggummin%20&%20Tabletter" },
        { text: "Smörgåsar & Snabblunch", target: "/p/Smörgåsar%20&%20Snabblunch" },
        { text: "Djur & Barn", target: "/p/Djur%20&%20Barn" },
        { text: "Snus", target: "/p/Snus" },
      ],
    },
    second: {
      title: "Populära produkter",
      type: "section_link",
      links: [
        { text: "Choklad", target: "/p/Godis%20&%20Choklad?ooh_product_type=Choklad" },
        { text: "Chips", target: "/p/?ooh_product_type=Chips" },
        { text: "Bars", target: "/p/?ooh_product_type=Bars" },
        { text: "Godis", target: "/p/?ooh_product_type=Godis" },
        { text: "Nötter", target: "/p/?ooh_product_type=Nötter" },
        { text: "Oliver", target: "/p/Chips%20&%20Salta%20Snacks?ooh_product_type=Oliver" },
        { text: "Popcorn", target: "/p/Chips%20&%20Salta%20Snacks?ooh_product_type=Popcorn" },
        { text: "Ölkorv", target: "/p/Chips%20&%20Salta%20Snacks?ooh_product_type=Ölkorv" },
        { text: "Ris- & Majskakor", target: "/p/?ooh_product_type=Ris-%20%26%20Majskakor" },
        { text: "Torkad Frukt", target: "/p/Bars%20&%20Hälsosnacks?ooh_product_type=Torkad%20frukt" },
        { text: "Pinnglass", target: "/p?icecream_type=Pinnglass" },
      ],
    },
    third: {
      title: "Populära varumärken",
      type: "section_link",
      links: [
        { text: "Marabou", target: "/p/_Marabou" },
        { text: "SIA Glass", target: "/p/_SIA%20glass" },
        { text: "Estrella", target: "/p/_Estrella" },
        { text: "Delicato", target: "/p/_Delicato" },
        { text: "Triumf Glass", target: "/p/_Triumf%20Glass" },
        { text: "OLW", target: "/p/_OLW" },
        { text: "Gray's Bakery", target: "/p/_Gray's%20Bakery" },
        { text: "Fazer Konfektyr", target: "/p/_Fazer%20Konfektyr" },
        { text: "Göteborgs Kex", target: "/p/_Göteborgs%20Kex" },
        { text: "3 Vänner", target: "/p/_3%20Vänner" },
        { text: "Barebells", target: "/p/Bars%20&%20Hälsosnacks_Barebells" },
      ],
    },
  },
  accessories: {
    main: {
      title: "Kategorier",
      type: "section_link",
      links: [
        { text: "Torkpapper & Kvittorullar", target: "/p/Torkpapper%20&%20Kvittorullar" },
        { text: "Städning & Rengöring", target: "/p/Städning%20&%20Rengöring" },
        { text: "Påsar, Handskar & Matfilm", target: "/p/P%C3%A5sar,%20Handskar%20&%20Matfilm" },
        { text: "Förpackningar & Takeaway", target: "/p/F%C3%B6rpackningar%20&%20Takeaway" },
        { text: "Servetter & Dukning", target: "/p/Servetter%20&%20Dukning" },
        { text: "Köks- & Serveringsutrustning", target: "/p/K%C3%B6ks-%20&%20Serveringsutrustning" },
        { text: "Kontors- & Butiksutrustning", target: "/p/Kontors-%20&%20Butiksutrustning" },
        { text: "Apotek", target: "/p/Apotek" },
      ],
    },
    second: {
      title: "Populära produkter",
      type: "section_link",
      links: [
        { text: "Rengöringsmedel", target: "/p/?ooh_product_type=Rengöringsmedel" },
        { text: "Matboxar & Lock", target: "/p/?ooh_product_type=Matboxar%20%26%20Lock" },
        { text: "Utrustning", target: "/p/?ooh_product_type=Utrustning" },
        { text: "Servetter", target: "/p/?ooh_product_type=Servetter" },
        { text: "Städutrustning", target: "/p/?ooh_product_type=Städutrustning" },
        { text: "Kantiner & Tillbehör", target: "/p/?ooh_product_type=Kantiner%20%26%20Tillbehör" },
        { text: "Påsar", target: "/p/?ooh_product_type=Påsar" },
        { text: "Engångsartiklar", target: "/p/?ooh_product_type=Engångsartiklar" },
        { text: "Toalettpapper", target: "/p/?ooh_product_type=Toalettpapper" },
        { text: "Köksredskap", target: "/p/?ooh_product_type=Köksredskap" },
        { text: "Kastruller & Stekpannor", target: "/p/?ooh_product_type=Kastruller%20%26%20Stekpannor" },
      ],
    },
    third: {
      title: "Populära varumärken",
      type: "section_link",
      links: [
        { text: "Duni", target: "/p/_Duni" },
        { text: "Hendi", target: "/p/_Hendi" },
        { text: "ECOLAB", target: "/p/_ECOLAB" },
        { text: "Tork", target: "/p/_Tork" },
        { text: "Vileda Professional", target: "/p/_Vileda%20Professional" },
        { text: "Coffee Queen", target: "/p/_Coffee%20Queen" },
        { text: "NPA Plast", target: "/p/_NPA%20Plast" },
        { text: "Pibox", target: "/p/_Pibox" },
        { text: "Grumme", target: "/p/_Grumme" },
        { text: "Bio Gen Active", target: "/p/_Bio%20Gen%20Active" },
        { text: "Häxan", target: "/p/_Häxan" },
      ],
    },
  },
  brands: {
    main: {
      title: "Populära kategorier",
      type: "section_link",
      links: [
        { text: "Bakverk & dessert från Delicato", target: "/p/_Delicato?ooh_product_type=Bakverk%20%26%20Dessert" },
        { text: "Kryddor från Santa Maria", target: "/p/_Santa%20Maria?ooh_product_type=Kryddor" },
        { text: "Proteinbars från Barebells", target: "/p/_Barebells?ooh_product_type=Bars" },
        { text: "Choklad från Marabou", target: "/p/_Marabou?ooh_product_type=Choklad" },
        { text: "Frukt & grönt från Everfresh", target: "/p/Frukt%20&%20Gr%C3%B6nt_Everfresh" },
        { text: "Läsk från Coca Cola", target: "/p/?manufacturer=Coca-Cola%20European%20Partners%20Sverige%20AB&ooh_product_type=Läsk%20%26%20Soda" },
        { text: "Kaffe från Gevalia", target: "/p/_Gevalia?ooh_product_type=Kaffe" },
        { text: "Pasta från Zeta", target: "/p/_Zeta?ooh_product_type=Pasta" },
        { text: "Hundglass från Hugo & Celine", target: "/p/_Hugo%20&%20Celine?ooh_product_type=Hundglass" },
        { text: "Köttbullar från Scan", target: "/p/_Scan?ooh_product_type=Köttbullar" },
        { text: "Vegetariskt från Anamma", target: "/p/Vegetariskt_Anamma" },
      ],
    },
    second: {
      title: "Populära varumärken",
      type: "section_link",
      links: [
        { text: "Vitamin Well", target: "/p?brand=Vitamin%20Well" },
        { text: "Löfbergs", target: "/p?brand=L%C3%B6fbergs" },
        { text: "Pepsi", target: "/p?brand=Pepsi" },
        { text: "Zeta", target: "/p?brand=Zeta" },
        { text: "Zoegas", target: "/p?brand=Zoegas" },
        { text: "Lavazza", target: "/p?brand=Lavazza" },
        { text: "Pedros", target: "/p?brand=Pedros" },
        { text: "NOCCO", target: "/p?brand=NOCCO" },
        { text: "ECOLAB", target: "/p?brand=ECOLAB" },
        { text: "Gevalia", target: "/p?brand=Gevalia" },
        { text: "Coca cola", target: "/p?manufacturer=Coca-Cola%20European%20Partners%20Sverige%20AB" },
      ],
    },
    third: {
      title: "Nytt i sortimentet",
      type: "section_link",
      links: [
        { text: "LEWA of Sweden", target: "/p/_LEWA%20OF%20SWEDEN" },
        { text: "Lindt", target: "/p/_Lindt" },
        { text: "Dafgårds", target: "/p/_Dafg%C3%A5rds" },
        { text: "Ar Functional", target: "/p/?manufacturer=Åre%20Beverage%20Group%20AB" },
        { text: "Luxia", target: "/p/_Luxia" },
        { text: "Pändy", target: "/p/_Pändy" },
        { text: "Hugo & Celie", target: "/p/_Hugo%20&%20Celine" },
        { text: "Clean Drink", target: "/p?brand=Clean%20Drink" },
        { text: "Polarbröd", target: "/p/_Polarbröd" },
        { text: "Hooked", target: "/p/_Hooked" },
        { text: "Shewy", target: "/p/_Shewy" },
      ],
    },
  },
  customer_type: {
    main: {
      title: "Topplista för",
      type: "section_link",
      links: [
        { text: "Restaurang", target: "/rekommenderade/Restaurang" },
        { text: "Café", target: "/rekommenderade/Caf%C3%A9" },
        { text: "Hotell & konferens", target: "/rekommenderade/Hotell%2Fkonferens" },
        { text: "Servicehandel", target: "/rekommenderade/Servicehandel" },
        { text: "Dagligvaruhandel", target: "/rekommenderade/Dagligvaruhandel" },
        { text: "Detaljhandel", target: "/rekommenderade/Detaljhandel" },
        { text: "Grossist", target: "/rekommenderade/Grossist" },
        { text: "Gym, träning & förening", target: "/rekommenderade/Gym%2FTr%C3%A4ning%2FF%C3%B6rening" },
        { text: "Kontor & arbetsplats", target: "/rekommenderade/Kontor%2FArbetsplats" },
        { text: "Offentlig verksamhet", target: "/rekommenderade/Offentlig%20Verksamhet" },
        { text: "Produktion", target: "/rekommenderade/Produktion" },
      ],
    },
    second: {
      title: "Topplistor produkter",
      type: "section_link",
      links: [
        { text: "Kryddor för restaurang", target: "/rekommenderade/Restaurang/?ooh_product_type=Kryddor" },
        { text: "Pasta för restaurang", target: "/rekommenderade/Restaurang/Torrvaror?ooh_product_type=Pasta" },
        { text: "Kaffe för café", target: "/rekommenderade/Caf%C3%A9/?ooh_product_type=Kaffe" },
        { text: "Bakverk & Dessert för café", target: "/rekommenderade/Café/Bakverk%20&%20Dessert" },
        { text: "Toapapper för kontor", target: "/rekommenderade/Kontor%2FArbetsplats/Torkpapper%20&%20Kvittorullar?ooh_product_type=Toalettpapper" },
        { text: "Sportdryck för gym", target: "/rekommenderade/Gym%2FTräning%2FFörening/Energi-%20&%20Sportdrycker" },
        { text: "Choklad för kassalinjen", target: "/rekommenderade/Dagligvaruhandel/?ooh_product_type=Choklad" },
        { text: "Proteinbars för gym", target: "/rekommenderade/Gym%2FTr%C3%A4ning%2FF%C3%B6rening/?ooh_product_type=Bars" },
        { text: "Rengöringsprodukter för butik", target: "/rekommenderade/Dagligvaruhandel/Städning%20&%20Rengöring" },
        { text: "Chips för butik", target: "/rekommenderade/Dagligvaruhandel/Chips%20&%20Salta%20Snacks?ooh_product_type=Chips" },
        { text: "Ost & Mejeri för butik", target: "/rekommenderade/Dagligvaruhandel/Ost,%20Mejeri%20&%20Ägg" },
      ],
    },
    third: {
      title: "Topplistor varumärken",
      type: "section_link",
      links: [
        { text: "Santa Maria för restaurang", target: "/rekommenderade/Restaurang/_Santa%20Maria" },
        { text: "Löfbergs för café", target: "/rekommenderade/Caf%C3%A9/_L%C3%B6fbergs" },
        { text: "Duni för hotell", target: "/rekommenderade/Hotell%2Fkonferens/_Duni" },
        { text: "Barilla för butik", target: "/rekommenderade/Dagligvaruhandel/_Barilla" },
        { text: "Vitamin Well för kiosk", target: "/rekommenderade/Dagligvaruhandel/_Vitamin%20Well" },
        { text: "Marabou för kassalinjen", target: "/rekommenderade/Dagligvaruhandel/_Marabou" },
        { text: "Gainomax för gym", target: "/rekommenderade/Gym%2FTr%C3%A4ning%2FF%C3%B6rening/_Gainomax" },
        { text: "Zoegas för kontoret", target: "/rekommenderade/Kontor%2FArbetsplats/_Zoegas" },
        { text: "Estrella för butik", target: "/rekommenderade/Dagligvaruhandel/_Estrella" },
        { text: "Triumf Glass för butik", target: "/rekommenderade/Dagligvaruhandel/_Triumf%20Glass" },
        { text: "Felix för butik", target: "/rekommenderade/Dagligvaruhandel/_Felix" },
      ],
    },
  },
};

/* @flow */

import type { CustomerOrderPartial, Order } from "../types/order.flow";
import { STRIPE_PAYMENT_METHOD_CODE } from "../stripe/quote-payment-method";

type AnyCustomerOrder = CustomerOrderPartial | Order;

export const orderInvoiceStatus = (order: AnyCustomerOrder): ?string => {
  if (order.payment.code === STRIPE_PAYMENT_METHOD_CODE) {
    return "PAID";
  }

  if (
    order.payment.code === "DibsD2" &&
    (order.status === "processing" || order.status === "complete")
  ) {
    return "PAID";
  }

  // FIXME: Remove once Collector updates properly
  if (
    order.payment.code === "Crossroads_Collector" &&
    (order.status === "processing" || order.status === "complete")
  ) {
    return "PAID";
  }

  return order.status === "canceled"
    ? null
    : order.vismaInvoiceId
    ? ["PAID", "OVERDUE", "CANCELED"].indexOf(order.vismaInvoiceStatus) !== -1
      ? order.vismaInvoiceStatus
      : "INVOICED"
    : null;
};

export const orderShippingStatus = (
  order: AnyCustomerOrder,
  gotOverdueInvoice: boolean
): ?string => {
  if (order.vismaDeliveryStatus === "DELIVERED") {
    return "DELIVERED";
  }

  if (order.status === "complete") {
    return "SHIPPED";
  }

  if (order.status === "canceled") {
    return "CANCELED";
  }

  if (gotOverdueInvoice) {
    // order that been synced to visma
    if (["NEW", "DELAYED"].includes(order.vismaDeliveryStatus)) {
      return "BLOCKED";
    }

    // new order that havent been synced to visma yet
    if (
      order.vismaInvoiceId === null &&
      order.vismaDeliveryStatus === "UNKNOWN" &&
      order.vismaInvoiceStatus === "NEW"
    ) {
      return "BLOCKED";
    }
  }

  switch (order.vismaInvoiceStatus) {
    case "NEW":
    case "PICKED":
    case "PROCESSING":
      return "PROCESSING";
    case "DELAYED":
      return "DELAYED";
    case "SHIPPED":
    case "INVOICED":
    case "PAID":
    case "OVERDUE":
      return "SHIPPED";
    case "DELIVERED":
      return "DELIVERED";
    case "CANCELED":
      return "CANCELED";
    default:
      return null;
  }
};

export const mergeStatus = (invoice: ?string, shipment: ?string): string => {
  if (invoice === "OVERDUE" || invoice === "CANCELED") {
    return "ERROR";
  }

  if (shipment === "DELAYED" || shipment === "CANCELED") {
    return "ERROR";
  }

  if (invoice === "INVOICED") {
    return "WARNING";
  }

  return "SUCCESS";
};

/* @flow */

import React, { useState, useEffect, useContext, useRef } from "react";
import { useTranslate } from "@out-of-home/use-translate";
import styles from "./styles.scss";
import cn from "classnames";
import isWeekend from "date-fns/isWeekend";
import { DatePickerSimple } from "../DatePickerSimple";
import { TimeContext } from "../../context/time";
import { useData } from "crustate/react/";
import { QuoteData } from "../../data";
import { Tooltip } from "../Tooltip";
import { itemStockOverflow } from "../CheckoutView/StockPopup/helpers";

type DeliveryInfoStatus = "OK" | "NO_POSTCODE" | "NO_TIMETABLE" | "BELOW_WEIGHT";

const useGetText = () => {
  const t = useTranslate();
  const { now } = useContext(TimeContext);

  return (deliveryInfoStatus: DeliveryInfoStatus, stockOverflow: boolean): string => {
    if (stockOverflow) {
      return t("SHIPPING.STOCK_OVERFLOW");
    }

    if (deliveryInfoStatus === "NO_POSTCODE") {
      return t("SHIPPING.STOCK_NO_POSTCODE");
    }

    if (deliveryInfoStatus === "NO_TIMETABLE") {
      return t("SHIPPING.STOCK_NO_TIMETABLE");
    }

    if (deliveryInfoStatus === "BELOW_WEIGHT") {
      return t("SHIPPING.STOCK_BELOW_WEIGHT");
    }

    return t(`SHIPPING.IN_STOCK_${now.getHours() < 12 ? "BEFORE" : "AFTER"}_12`);
  }
}

type Props = {
  showTooltip?: boolean,
  deliveryInfoStatus: DeliveryInfoStatus
}
export const Stock = ({ showTooltip = true, deliveryInfoStatus }:Props): React$Node => {
  const quoteData = useData(QuoteData);
  const quote = quoteData.data || null;
  const stockOverflow = (quote?.items || []).some(itemStockOverflow);
  const getText = useGetText();

  if (!showTooltip) {
    return   (
      <div
        className={cn(styles.stockIcon, {
          [styles.stockIcon__overflow]: stockOverflow,
        })}
      />
    );
  }

  const text = getText(deliveryInfoStatus, stockOverflow);

  return (
    <Tooltip
      direction="top"
      text={text}
      width={280}
    >
      <div
        className={cn(styles.stockIcon, {
          [styles.stockIcon__overflow]: stockOverflow,
        })}
      />
    </Tooltip>
  );
};

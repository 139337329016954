/* @flow */

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

type Brand = {
  title: string,
  description: string,
  icon: string,
  iconSmall: string,
  original: string,
};

type Data = {| state: "LOADING" |} | {| state: "LOADED", data: Array<Brand> |};

export type BrandsResponse = Response<
  typeof BRANDS_LOAD_RESPONSE,
  Array<Brand>
>;

export type BrandsRequest = {
  tag: typeof BRANDS_LOAD_REQUEST,
};

export const BRANDS_LOAD_RESPONSE: "brands/load/response" =
  "brands/load/response";
export const BRANDS_LOAD_REQUEST: "brands/load/request" = "brands/load/request";

export const BrandsModel: Model<Data, {}, BrandsRequest | BrandsResponse> = {
  id: "brands",
  init: () => updateData({ state: "LOADING" }, { tag: BRANDS_LOAD_REQUEST }),
  update: (state: Data, msg) => {
    switch (msg.tag) {
      case BRANDS_LOAD_RESPONSE:
        if (msg.data && state.state === "LOADING") {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;
      default:
    }
  },
};

/* @flow */

import type {
  ProductCardProduct,
  ProductDisplayMode,
} from "../../types/product.flow";
import type { DisplayMode } from "../../context/ui";

import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.scss";
import ArrowIcon from "../../icons/arrow.svg";
import breakpoints from "../../helpers/breakpoints";
import { ProductList } from "../ProductList";
import { HeadingRow } from "../HeadingRow";
import { useBreakpoints } from "../../helpers/useBreakpoints";
import type { ProductListProps } from "../ProductList";

type Props = {
  className?: string,
  title: string,
  url?: string | null,
  description?: string,
  perRow?: number,
  products: Array<ProductCardProduct | null>,
  productListProps?: {
    listName?: ProductListProps["listName"],
    ...
  },
  alwaysShowURL?: boolean,
  productDisplayMode?: ProductDisplayMode,
  resetFilter?: Function,
  evenRows?: boolean,
  inSplitView?: boolean,
};

/**
 * An Excerpt with a "show all" link.
 */
export const Excerpt = ({
  title,
  inSplitView = false,
  url,
  description,
  products,
  productListProps,
  alwaysShowURL = false,
  productDisplayMode = "GRID",
  evenRows = false,
  perRow = 5,
  resetFilter,
  className,
}: Props): React$Node => {
  const { getNumberOfDisplayedItems } = useBreakpoints(breakpoints);
  const numDisplayedItems = getNumberOfDisplayedItems(
    {
      mini: 1,
      tiny: 2,
      small: 3,
      medium: 4,
      large: 5,
    },
    4
  );

  const fitProductList = (productList: Array<ProductCardProduct | null>) => {
    return productList.filter((x, i) => i < numDisplayedItems);
  };

  return (
    <div className={className}>
      <HeadingRow
        resetFilter={resetFilter}
        title={title}
        url={products.length > 4 || alwaysShowURL ? url : null}
      />

      {description && (
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}

      <ProductList
        {...productListProps}
        products={evenRows ? fitProductList(products) : products}
        displayMode={productDisplayMode}
        inSplitView={inSplitView}
      />
    </div>
  );
};

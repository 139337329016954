/* @flow */

import { useContext } from "react";
import { UIContext } from "../context/ui";

export const useBreakpoints = (
  styles: any
): ({
  isCurrentBreakpoint: (breakpoint: string) => boolean,
  getNumberOfDisplayedItems: (
    breakpointColMap: { [string]: number },
    defaultAmount: number
  ) => number,
}) => {
  const { browserWidth } = useContext(UIContext);

  const isCurrentBreakpoint = (breakpoint: string) =>
    browserWidth > parseInt(styles[breakpoint], 10);

  const getNumberOfDisplayedItems = (
    breakpointColMap: { [string]: number },
    defaultAmount: number
  ) => {
    const sortBreakpointsDesc = (a, b) =>
      breakpointColMap[a] < breakpointColMap[b] ? 1 : -1;

    const currentBreakpoint = Object.keys(breakpointColMap)
      .sort(sortBreakpointsDesc)
      .find(isCurrentBreakpoint);

    return !currentBreakpoint
      ? defaultAmount
      : breakpointColMap[currentBreakpoint];
  };

  return {
    isCurrentBreakpoint,
    getNumberOfDisplayedItems,
  };
};

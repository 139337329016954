/* @flow */

import React, { useEffect, useState, useRef } from "react";
import styles from "./styles.scss";
import cn from "classnames";
import { disableBodyScroll, enableBodyScroll } from "../../helpers/body-scroll-lock";

import { useModal } from "../Modal";

type Props = {
  children: React$Node,
  open: boolean,
  setOpen: (boolean) => void,
};

const TIMEOUT = parseInt(styles.transitionDuration, 10);

export const OffCanvasMenu = (props: Props): React$Node => {
  const [opening, setOpening] = useState(false);
  const [closing, setClosing] = useState(false);
  const [open, setOpen] = useState(false);
  const aside = useRef(null);
  const { Modal } = useModal(props.open, props.setOpen);

  useEffect(() => {
    if (props.open) {
      disableBodyScroll(aside.current, { allowTouchMove: () => true });
      setTimeout(() => {
        setOpening(true);
      }, 0);

      setTimeout(() => {
        setOpening(false);
        setOpen(true);
      }, TIMEOUT);
    } else {
      enableBodyScroll(aside.current);
      setTimeout(() => {
        setClosing(true);
      }, 0);

      setTimeout(() => {
        setClosing(false);
        setOpen(false);
      }, TIMEOUT);
    }
  }, [props.open]);

  const children = React.Children.map(props.children, (x) =>
    React.cloneElement(x, { close: () => setOpen(false), open }),
  );

  return (
    <div
      className={cn({
        [styles.opening]: opening,
        [styles.closing]: closing,
        [styles.open]: open,
      })}
    >
      <div className={styles.container}>
        <div
          className={cn(styles.overlay, { [styles.overlay__open]: props.open })}
          onClick={() => props.setOpen(false)}
        />
        <aside ref={aside} className={cn(styles.block, { [styles.block__open]: props.open })}>
          {children}
        </aside>
      </div>
    </div>
  );
};

/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { QuoteRequest, QuoteResponse } from "../state/quote";
import {
  EVENT_CHECKOUT_CREATE_ADDRESS,
  EVENT_CHECKOUT_UPDATE_ADDRESS,
  EVENT_PLACE_ORDER_SUCCESS,
} from "../state/events";
import {
  QUOTE_LOAD_RESPONSE,
  QUOTE_LOAD_REQUEST,
  QUOTE_SET_PAYMENT_METHOD_REQUEST,
  QUOTE_SET_PAYMENT_METHOD_RESPONSE,
  QUOTE_SET_ADDRESS_REQUEST,
  QUOTE_SET_ADDRESS_RESPONSE,
  QUOTE_PLACE_ORDER_REQUEST,
  QUOTE_PLACE_ORDER_RESPONSE,
  QUOTE_UPDATE_SHIPPING_METHOD_REQUEST,
  QUOTE_UPDATE_SHIPPING_METHOD_RESPONSE,
  QUOTE_SAVE_ADDRESS_REQUEST,
  QUOTE_SAVE_ADDRESS_RESPONSE,
  QUOTE_SET_DISCOUNT_CODE_REQUEST,
  QUOTE_SET_DISCOUNT_CODE_RESPONSE,
  QUOTE_REMOVE_DISCOUNT_CODE_REQUEST,
  QUOTE_REMOVE_DISCOUNT_CODE_RESPONSE,
  QUOTE_SET_EMAIL_REQUEST,
  QUOTE_SET_EMAIL_RESPONSE,
  QUOTE_CHECKOUT_SAVE_CUSTOMER_REQUEST,
  QUOTE_CHECKOUT_SAVE_CUSTOMER_RESPONSE,
  QUOTE_SET_ORDER_REFERENCE_REQUEST,
  QUOTE_SET_ORDER_REFERENCE_RESPONSE,
  QUOTE_SET_PREFERRED_DELIVERY_DATE_RESPONSE,
  QUOTE_SET_PREFERRED_DELIVERY_DATE_EFFECT_REQUEST,
} from "../state/quote";
import {
  updateCustomerAddress,
  createCustomerAddress,
  syncCustomer,
  createAccount,
} from "../state/customer";
import { addMessage } from "../state/messages";
import {
  quote,
  setQuotePaymentMethodFree,
  setQuotePaymentMethodCollector,
  setQuotePaymentMethodStripe,
  setQuotePaymentMethodCrossInvoice,
  setQuoteBillingAddress,
  setQuoteShippingMethodToCheapest,
  shippingMethods as shippingMethodsQuery,
  setQuoteShippingMethod,
  customer as customerQuery,
  setQuoteDiscountCode as setQuoteDiscountCodeMutation,
  removeQuoteDiscountCode,
  setQuoteEmail,
  placeOrder,
  quote as quoteQuery,
  setQuoteOrderReference,
  setQuoteTimeWindow,
  setQuotePreferredDeliveryDate,
} from "../queries";

type History = { push: (string) => void };

const setShippingMethod = async (client: Client<{}>, storage: Storage) => {
  const { shippingMethods } = await client(shippingMethodsQuery);

  const bring = shippingMethods.find((x) => x.code === "bring_standard");
  if (bring) {
    const {
      setQuoteShippingMethod: { result },
    } = await client(setQuoteShippingMethod, { method: "bring_standard" });

    // If it's suddenly unavailable, set to cheapest instead
    if (result !== "success" && result !== "notModified") {
      storage.broadcastMessage(
        addMessage("CHECKOUT_SHIPPING_METHOD_ERROR", "error")
      );
    }
  } else {
    await client(setQuoteShippingMethodToCheapest);
  }
};

const registerClient = (
  storage: Storage,
  client: Client<{}>,
  restClient: Fetch,
  history: History
) => {
  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      await client(setQuoteShippingMethodToCheapest);
      const quoteData = await client(quote);
      return ({
        tag: QUOTE_LOAD_RESPONSE,
        data: quoteData.quote,
      }: QuoteResponse);
    },
    subscribe: { [QUOTE_LOAD_REQUEST]: true },
  });

  const runQueryByMethod = (method) => {};

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag === QUOTE_SET_PAYMENT_METHOD_REQUEST) {
        let resultPromise;

        if (msg.method === "Crossroads_Collector") {
          resultPromise = client(setQuotePaymentMethodCollector, {
            ssn: msg.ssn,
            isCompany: true,
          }).then((x) => x.setQuotePaymentMethod.collectorInvoice.result);
        } else if (msg.method === "CrossInvoice") {
          resultPromise = client(setQuotePaymentMethodCrossInvoice).then(
            (x) => x.setQuotePaymentMethod.crossInvoice.result
          );
        } else if (msg.method === "Crossroads_Stripe_PaymentIntents") {
          resultPromise = client(setQuotePaymentMethodStripe).then(
            (x) => x.setQuotePaymentMethod.stripe.result
          );
        } else if (msg.method === "free") {
          resultPromise = client(setQuotePaymentMethodFree).then(
            (x) => x.setQuotePaymentMethod.free.result
          );
        } else {
          throw new Error("Invalid payment method: " + msg.method);
        }

        const [result, quoteData] = await Promise.all([
          resultPromise,
          client(quote),
        ]);

        if (result !== "success") {
          storage.broadcastMessage(
            addMessage(`setQuotePaymentMethod.${result}`, "error")
          );

          return ({
            tag: QUOTE_SET_PAYMENT_METHOD_RESPONSE,
            error: result,
          }: QuoteResponse);
        }

        if (quoteData) {
          return ({
            tag: QUOTE_SET_PAYMENT_METHOD_RESPONSE,
            data: quoteData.quote,
          }: QuoteResponse);
        }

        return ({
          tag: QUOTE_SET_PAYMENT_METHOD_RESPONSE,
          error: result,
        }: QuoteResponse);
      }
    },
    subscribe: { [QUOTE_SET_PAYMENT_METHOD_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag === QUOTE_UPDATE_SHIPPING_METHOD_REQUEST) {
        await setShippingMethod(client, storage);

        const quoteData = await client(quote);

        return ({
          tag: QUOTE_UPDATE_SHIPPING_METHOD_RESPONSE,
          data: quoteData.quote,
        }: QuoteResponse);
      }
    },
    subscribe: { [QUOTE_UPDATE_SHIPPING_METHOD_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag === QUOTE_SET_ADDRESS_REQUEST) {
        // @TODO: group some of these to improve performance?
        client(setQuoteBillingAddress, { address: msg.address });
        await setShippingMethod(client, storage);
        const quoteData = await client(quote);

        return ({
          tag: QUOTE_SET_ADDRESS_RESPONSE,
          data: quoteData.quote,
        }: QuoteResponse);
      }
    },
    subscribe: { [QUOTE_SET_ADDRESS_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag === QUOTE_SAVE_ADDRESS_REQUEST) {
        // If the address has an id it's saved to the customer
        // If not it should maybe be saved as a new address on the customer
        await storage.broadcastMessage(
          msg.id
            ? {
                tag: EVENT_CHECKOUT_UPDATE_ADDRESS,
                address: msg.address,
                id: msg.id,
              }
            : { tag: EVENT_CHECKOUT_CREATE_ADDRESS, address: msg.address }
        );

        // @TODO: group some of these to improve performance?
        await client(setQuoteBillingAddress, { address: msg.address });
        await setShippingMethod(client, storage);
        const quoteData = await client(quote);
        const { customer } = await client(customerQuery);
        await storage.broadcastMessage(syncCustomer(customer));

        return ({
          tag: QUOTE_SAVE_ADDRESS_RESPONSE,
          data: quoteData.quote,
        }: QuoteResponse);
      }
    },
    subscribe: { [QUOTE_SAVE_ADDRESS_REQUEST]: true },
  });

  /*
  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      history.push("/checkout/success");
    },
    subscribe: { [QUOTE_PLACE_ORDER_RESPONSE]: true },
  });
  */

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag === QUOTE_SET_DISCOUNT_CODE_REQUEST) {
        const { setQuoteDiscountCode } = await client(
          setQuoteDiscountCodeMutation,
          {
            code: msg.code,
          }
        );

        const { quote: data } = await client(quote);

        if (setQuoteDiscountCode.result !== "success") {
          storage.broadcastMessage(
            addMessage("DISCOUNT_CODE_FAILURE", "error")
          );
        }

        return ({
          tag: QUOTE_SET_DISCOUNT_CODE_RESPONSE,
          data,
        }: QuoteResponse);
      }
    },
    subscribe: { [QUOTE_SET_DISCOUNT_CODE_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag === QUOTE_REMOVE_DISCOUNT_CODE_REQUEST) {
        await client(removeQuoteDiscountCode);

        const { quote: data } = await client(quote);

        return ({
          tag: QUOTE_REMOVE_DISCOUNT_CODE_RESPONSE,
          data,
        }: QuoteResponse);
      }
    },
    subscribe: { [QUOTE_REMOVE_DISCOUNT_CODE_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag === QUOTE_SET_EMAIL_REQUEST) {
        // @TODO: handle errors
        await client(setQuoteEmail, { email: msg.email });

        const { quote: data } = await client(quote);

        return ({
          tag: QUOTE_SET_EMAIL_RESPONSE,
          data,
        }: QuoteResponse);
      }
    },
    subscribe: { [QUOTE_SET_EMAIL_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag === QUOTE_SET_ORDER_REFERENCE_REQUEST) {
        // @TODO: handle errors
        await client(setQuoteOrderReference, { value: msg.value });

        const { quote: data } = await client(quote);

        return ({
          tag: QUOTE_SET_ORDER_REFERENCE_RESPONSE,
          data,
        }: QuoteResponse);
      }
    },
    subscribe: { [QUOTE_SET_ORDER_REFERENCE_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag === QUOTE_SET_PREFERRED_DELIVERY_DATE_EFFECT_REQUEST) {
        // @TODO: handle errors
        client(setQuotePreferredDeliveryDate, { value: msg.value });

        return ({
          tag: QUOTE_SET_PREFERRED_DELIVERY_DATE_RESPONSE,
          data: {
            ...msg.oldQuote,
            preferredDeliveryDate: msg.value,
          },
        }: QuoteResponse);
      }
    },
    subscribe: { [QUOTE_SET_PREFERRED_DELIVERY_DATE_EFFECT_REQUEST]: true },
  });

  // effect for customer create -> setQuoteEmail -> setQuoteAddress
  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag === QUOTE_CHECKOUT_SAVE_CUSTOMER_REQUEST) {
        try {
          const { countryCode, ...newQuoteAddress } =
            msg.customerFormData.address;

          await storage.broadcastMessage(
            createAccount({
              ...msg.customerFormData,
              address: {
                ...msg.customerFormData.address,
                isDefaultBillingAddress: true,
                isDefaultShippingAddress: true,
              },
            })
          );

          client(setQuoteBillingAddress, {
            address: msg.customerFormData.address,
          });
          client(setQuoteEmail, { email: msg.customerFormData.customer.email });

          client(setQuoteShippingMethodToCheapest);

          const { quote: data } = await client(quote);

          return ({
            tag: QUOTE_CHECKOUT_SAVE_CUSTOMER_RESPONSE,
            data,
          }: QuoteResponse);
        } catch (e) {
          storage.broadcastMessage(
            addMessage("CHECKOUT_CREATE_ACCOUNT_ERROR", "error")
          );
          const { quote: data } = await client(quote);

          return ({
            tag: QUOTE_CHECKOUT_SAVE_CUSTOMER_RESPONSE,
            data,
          }: QuoteResponse);
        }
      }
    },
    subscribe: { [QUOTE_CHECKOUT_SAVE_CUSTOMER_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag === QUOTE_PLACE_ORDER_REQUEST) {
        try {
          const placeOrderData = await client(placeOrder);

          if (placeOrderData.placeOrder.result !== "success") {
            if (
              placeOrderData.placeOrder.result === "Invalid registration number"
            ) {
              storage.broadcastMessage(addMessage("INVALID_SSN", "error"));
            } else {
              storage.broadcastMessage(
                addMessage(placeOrderData.placeOrder.result, "error")
              );
            }

            return ({
              tag: QUOTE_PLACE_ORDER_RESPONSE,
              error: placeOrderData.placeOrder.result,
            }: QuoteResponse);
          }

          // Sync new customer data to get the new order data and stuff
          const { customer } = await client(customerQuery);
          storage.broadcastMessage(syncCustomer(customer));

          const newQuoteData = await client(quoteQuery);
          const newQuote = newQuoteData.quote;

          storage.broadcastMessage({
            tag: EVENT_PLACE_ORDER_SUCCESS,
            quote: newQuote,
          });

          history.push("/checkout/success");

          return ({
            tag: QUOTE_PLACE_ORDER_RESPONSE,
            data: newQuote,
          }: QuoteResponse);
        } catch (e) {
          storage.broadcastMessage(addMessage("CHECKOUT_ERROR", "error"));

          return ({
            tag: QUOTE_PLACE_ORDER_RESPONSE,
            error: "error",
          }: QuoteResponse);
        }
      }
    },
    subscribe: { [QUOTE_PLACE_ORDER_REQUEST]: true },
  });
};

export default registerClient;

/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { Customer, CustomerAddressInput, EditCustomerFormInput } from "../types/customer.flow";
import type { CustomerRequest, CustomerResponse, CreateAccountParams } from "../state/customer";
import type { Fetch } from "@out-of-home/fetch-utils";

import { sendJson } from "@out-of-home/fetch-utils";
import { handleEffectErrors } from "../helpers/effectHelpers";
import { addMessage, addMessageTranslated } from "../state/messages";
import {
  CUSTOMER_INIT_REQUEST,
  CUSTOMER_INIT_RESPONSE,
  CUSTOMER_LOGIN_REQUEST,
  CUSTOMER_LOGIN_RESPONSE,
  CUSTOMER_LOGOUT_REQUEST,
  CUSTOMER_LOGOUT_RESPONSE,
  CUSTOMER_UPDATE_REQUEST,
  CUSTOMER_UPDATE_RESPONSE,
  CUSTOMER_CREATE_ADDRESS_REQUEST,
  CUSTOMER_CREATE_ADDRESS_RESPONSE,
  CUSTOMER_UPDATE_ADDRESS_REQUEST,
  CUSTOMER_UPDATE_ADDRESS_RESPONSE,
  CUSTOMER_DELETE_ADDRESS_REQUEST,
  CUSTOMER_DELETE_ADDRESS_RESPONSE,
  CUSTOMER_SET_DEFAULT_ADDRESS_REQUEST,
  CUSTOMER_SET_DEFAULT_ADDRESS_RESPONSE,
  CUSTOMER_SET_DEFAULT_PAYMENT_METHOD_REQUEST,
  CUSTOMER_SET_DEFAULT_PAYMENT_METHOD_RESPONSE,
  CUSTOMER_CREATE_ACCOUNT_REQUEST,
  CUSTOMER_CREATE_ACCOUNT_RESPONSE,
  CUSTOMER_SET_PNO_REQUEST,
  CUSTOMER_SET_EMAIL_COPY_REQUEST,
  CUSTOMER_SET_PREFERRED_LIST_LAYOUT_REQUEST,
  CUSTOMER_SET_TYPE_REQUEST,
} from "../state/customer";
import { WISHLIST_LOAD_REQUEST } from "../state/wishlist";

import {
  customer as customerQuery,
  login,
  logout,
  updateCustomer,
  createCustomerAddress,
  updateCustomerAddress,
  updateCustomerEmail,
  removeCustomerAddress,
  setCustomerDefaultBillingAddress,
  setCustomerDefaultShippingAddress,
  quote as quoteQuery,
} from "../queries";

import { add as addAlcoholLicense } from "../state/alcohol-licenses";

import {
  EVENT_CUSTOMER_LOGIN,
  EVENT_CUSTOMER_LOGOUT,
  EVENT_PLACE_ORDER_SUCCESS,
} from "../state/events";

import { INIT_REQUEST as QUOTE_ITEMS_INIT_REQUEST } from "../state/quote-items";

type CustomerRegisterFields = {
  defaultShippingMethod?: string,
  defaultPaymentMethod?: string,
  password: string,
  email: string,
  firstname: string,
  lastname: string,
  attributes: {},
};

type History = {
  push: (path: string) => void,
};

const OK_RESPONSES = ["success", "notModified"];

const PAYMENT_METHOD_CODE_REGEX = new RegExp(/\(([^\)]+)\)/g);

// @TODO: fix this method
const parsePaymentCode = (customer: Customer): Customer => {
  const { defaultPaymentMethod } = customer;
  if (typeof defaultPaymentMethod !== "string") {
    return customer;
  }

  const matches = defaultPaymentMethod.match(PAYMENT_METHOD_CODE_REGEX);
  const defaultMethod = (matches && matches[matches.length - 1]) || "";

  return {
    ...customer,
    defaultPaymentMethodCode: defaultMethod.replace("(", "").replace(")", ""),
  };
};

const registerClient = (
  storage: Storage,
  client: Client<{}>,
  restClient: Fetch,
  history: History,
  translations: any,
) => {
  storage.addEffect({
    effect: async () => {
      try {
        const { customer } = await client(customerQuery);

        if (customer) {
          return ({
            tag: CUSTOMER_INIT_RESPONSE,
            data: parsePaymentCode(customer),
          }: CustomerResponse);
        }

        return ({
          tag: CUSTOMER_INIT_RESPONSE,
          data: parsePaymentCode(customer),
        }: CustomerResponse);
      } catch (e) {
        return ({
          tag: CUSTOMER_INIT_RESPONSE,
          data: undefined,
        }: CustomerResponse);
      }
    },
    subscribe: { [CUSTOMER_INIT_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: CustomerRequest) => {
      if (msg.tag === CUSTOMER_LOGIN_REQUEST) {
        try {
          const {
            login: { result, customer },
          } = await client(login, {
            email: msg.email,
            password: msg.password,
          });

          if (result !== "success") {
            storage.broadcastMessage({ tag: EVENT_CUSTOMER_LOGIN });
            storage.broadcastMessage(addMessage(`loginCustomer.${result}`, "error"));
            return ({
              tag: CUSTOMER_LOGIN_RESPONSE,
              data: undefined,
            }: CustomerResponse);
          }

          storage.broadcastMessage({ tag: EVENT_CUSTOMER_LOGIN });

          return ({
            tag: CUSTOMER_LOGIN_RESPONSE,
            data: parsePaymentCode(customer),
          }: CustomerResponse);
        } catch (e) {
          console.error(e);

          storage.broadcastMessage({ tag: QUOTE_ITEMS_INIT_REQUEST });
          storage.broadcastMessage({ tag: WISHLIST_LOAD_REQUEST });
          storage.broadcastMessage(addMessage(e.getMessage(), "error"));

          return ({
            tag: CUSTOMER_LOGIN_RESPONSE,
            data: undefined,
          }: CustomerResponse);
        }
      }
    },
    subscribe: { [CUSTOMER_LOGIN_REQUEST]: true },
  });

  storage.addEffect({
    effect: async () => {
      const { customer } = await client(logout);

      const newQuoteData = await client(quoteQuery);
      const newQuote = newQuoteData.quote;

      storage.broadcastMessage({
        tag: EVENT_CUSTOMER_LOGOUT,
        quote: newQuote,
      });

      history.push("/");

      return ({
        tag: CUSTOMER_LOGOUT_RESPONSE,
        data: parsePaymentCode(customer),
      }: CustomerResponse);
    },
    subscribe: { [CUSTOMER_LOGOUT_REQUEST]: true },
  });

  storage.addEffect({
    effect: async ({
      data,
      password,
      email,
      isSubscribedToNewsletter,
    }: {
      data: EditCustomerFormInput,
      password: string,
      email: string,
      isSubscribedToNewsletter: boolean,
    }) => {
      try {
        // @TODO: move this to gql when endpoint is available
        if (typeof password === "string" && password !== "") {
          await restClient("/api/customer", sendJson({ method: "PUT", body: ({ password }: any) }));
        }

        await restClient(
          "/api/customer",
          sendJson({
            method: "PUT",
            body: ({ email, isSubscribedToNewsletter }: any),
          }),
        );

        const {
          updateCustomer: { customer },
        } = await client(updateCustomer, {
          data: { firstname: data.firstname, lastname: data.lastname },
        });

        storage.broadcastMessage(addMessage("-2100", "success"));
        history.push(`/${translations.ROUTES.ACCOUNT}`);

        return ({
          tag: CUSTOMER_UPDATE_RESPONSE,
          data: parsePaymentCode(customer),
        }: CustomerResponse);
      } catch (e) {
        // e.httpBody.error is for rest API errors
        if (e.httpBody.message) {
          storage.broadcastMessage(addMessageTranslated(e.httpBody.message, "error"));

          return ({
            tag: CUSTOMER_UPDATE_RESPONSE,
            error: e.httpBody.message,
          }: CustomerResponse);
        }

        // GQL errors
        storage.broadcastMessage(addMessageTranslated(e.message, "error"));

        return ({
          tag: CUSTOMER_UPDATE_RESPONSE,
          error: e.message,
        }: CustomerResponse);
      }
    },
    subscribe: { [CUSTOMER_UPDATE_REQUEST]: true },
  });

  storage.addEffect({
    effect: async ({ customerPno }: { customerPno: string }) => {
      const {
        updateCustomer: { result: updateCustomerResponse },
      } = await client(updateCustomer, {
        data: { customerPno },
      });

      if (OK_RESPONSES.includes(updateCustomerResponse)) {
        storage.broadcastMessage(addMessage("-2100", "success"));
        history.push(`/${translations.ROUTES.ACCOUNT}`);
      }

      const { customer } = await client(customerQuery);

      return ({
        tag: CUSTOMER_UPDATE_RESPONSE,
        data: parsePaymentCode(customer),
      }: CustomerResponse);
    },
    subscribe: { [CUSTOMER_SET_PNO_REQUEST]: true },
  });

  storage.addEffect({
    effect: async ({ layout }: { layout: string }) => {
      await client(updateCustomer, {
        data: { ooh3PreferredListLayout: layout },
      });

      const { customer } = await client(customerQuery);

      return ({
        tag: CUSTOMER_UPDATE_RESPONSE,
        data: parsePaymentCode(customer),
      }: CustomerResponse);
    },
    subscribe: { [CUSTOMER_SET_PREFERRED_LIST_LAYOUT_REQUEST]: true },
  });

  storage.addEffect({
    effect: async ({ ooh3Type, ooh3Subtype }: { ooh3Type: string, ooh3Subtype: Array<string> }) => {
      const {
        updateCustomer: { result: updateCustomerResponse, customer },
      } = await client(updateCustomer, {
        data: { ooh3Type, ooh3Subtype },
      });

      if (OK_RESPONSES.includes(updateCustomerResponse)) {
        storage.broadcastMessage(addMessage("-2100", "success"));
        history.push(`/${translations.ROUTES.ACCOUNT}`);
      }

      return ({
        tag: CUSTOMER_UPDATE_RESPONSE,
        data: parsePaymentCode(customer),
      }: CustomerResponse);
    },
    subscribe: { [CUSTOMER_SET_TYPE_REQUEST]: true },
  });

  storage.addEffect({
    effect: async ({ ooh3OrderCopyEmail }: { ooh3OrderCopyEmail: string }) => {
      const {
        updateCustomer: { result: updateCustomerResponse, customer },
      } = await client(updateCustomer, {
        data: { ooh3OrderCopyEmail },
      });

      if (OK_RESPONSES.includes(updateCustomerResponse)) {
        storage.broadcastMessage(addMessage("-2100", "success"));
        history.push(`/${translations.ROUTES.ACCOUNT}`);
      }

      return ({
        tag: CUSTOMER_UPDATE_RESPONSE,
        data: parsePaymentCode(customer),
      }: CustomerResponse);
    },
    subscribe: { [CUSTOMER_SET_EMAIL_COPY_REQUEST]: true },
  });

  storage.addEffect({
    effect: async ({
      address,
      onAccountView,
    }: {
      address: CustomerAddressInput,
      onAccountView: boolean,
    }) => {
      console.log("broadcasted message");
      const { success, response } = await handleEffectErrors(
        client(createCustomerAddress, {
          address,
          setDefaultBilling: false,
          setDefaultShipping: false,
        }),
        [],
        ["errorInvalidAddress"],
      );

      const { customer, result } = response.createCustomerAddress;

      if (result === "success" && onAccountView) {
        storage.broadcastMessage(addMessage("-2001", "success"));
        history.push(`/${translations.ROUTES.ACCOUNT}`);
      }

      return ({
        tag: CUSTOMER_CREATE_ADDRESS_RESPONSE,
        result,
        customer: parsePaymentCode(customer),
      }: CustomerResponse);
    },
    subscribe: { [CUSTOMER_CREATE_ADDRESS_REQUEST]: true },
  });

  storage.addEffect({
    effect: async ({
      id,
      address,
      onAccountView,
    }: {
      id: number,
      address: CustomerAddressInput,
      onAccountView: boolean,
    }) => {
      try {
        const { success, response } = await handleEffectErrors(
          client(updateCustomerAddress, { id, address }),
          [],
          ["errorInvalidAddress"],
        );

        const { customer, result } = response.updateCustomerAddress;

        if (success) {
          if (onAccountView) {
            storage.broadcastMessage(addMessage("-2000", "success"));
            history.push(`/${translations.ROUTES.ACCOUNT}`);
          }

          return ({
            tag: CUSTOMER_UPDATE_ADDRESS_RESPONSE,
            data: parsePaymentCode(customer),
          }: CustomerResponse);
        } else {
          storage.broadcastMessage(addMessage(`updateCustomerAddress.${result}`, "error"));
        }

        return ({
          tag: CUSTOMER_UPDATE_ADDRESS_RESPONSE,
          error: result,
        }: CustomerResponse);
      } catch (e) {
        storage.broadcastMessage(addMessage("UNKNOWN", "error"));
      }
    },
    subscribe: { [CUSTOMER_UPDATE_ADDRESS_REQUEST]: true },
  });

  storage.addEffect({
    effect: async ({ id }: { id: number }) => {
      const data = await client(removeCustomerAddress, { id });
      const { result } = data.removeCustomerAddress;

      if (result === "success") {
        storage.broadcastMessage(addMessage("-2002", "success"));
        history.push(`/${translations.ROUTES.ACCOUNT}`);
      }

      return ({
        tag: CUSTOMER_DELETE_ADDRESS_RESPONSE,
        result,
        id: String(id),
      }: CustomerResponse);
    },
    subscribe: { [CUSTOMER_DELETE_ADDRESS_REQUEST]: true },
  });

  storage.addEffect({
    effect: async ({ id }: { id: number }) => {
      let data;

      const r = client(setCustomerDefaultBillingAddress, { id });
      const rs = await client(setCustomerDefaultShippingAddress, { id });
      data = rs.setCustomerDefaultShippingAddress;

      const { result, customer } = data;

      return ({
        tag: CUSTOMER_SET_DEFAULT_ADDRESS_RESPONSE,
        customer: parsePaymentCode(customer),
        result,
      }: CustomerResponse);
    },
    subscribe: { [CUSTOMER_SET_DEFAULT_ADDRESS_REQUEST]: true },
  });

  storage.addEffect({
    effect: async ({ id }: { id: string }) => {
      await restClient(
        "/api/customer",
        sendJson({ method: "PUT", body: ({ defaultPaymentMethod: id }: any) }),
      );
      const { customer } = await client(customerQuery);

      return ({
        tag: CUSTOMER_SET_DEFAULT_PAYMENT_METHOD_RESPONSE,
        customer: parsePaymentCode(customer),
      }: CustomerResponse);
    },
    subscribe: { [CUSTOMER_SET_DEFAULT_PAYMENT_METHOD_REQUEST]: true },
  });

  // see: http://doc.crossroads.se/Crossroads-API/master/#api-Customer-registerCustomer
  storage.addEffect({
    effect: async (msg: CustomerRequest) => {
      if (msg.tag === CUSTOMER_CREATE_ACCOUNT_REQUEST) {
        // This gets called twice?
        const { data } = msg;

        try {
          // @TODO: replace with gql request when it's added to the API
          const registerCustomerResponse = await restClient(
            "/api/customer",
            sendJson(({ body: data.customer }: any)),
          ).then((x) => x.json());

          const addressResult = await restClient(
            "/api/customer/addresses",
            sendJson(
              ({
                body: { ...data.address, countryId: data.address.countryCode },
              }: any),
            ),
          ).then((x) => x.json());

          const addressId = parseInt(addressResult.id, 10);
          client(setCustomerDefaultBillingAddress, { id: addressId });
          client(setCustomerDefaultShippingAddress, { id: addressId });

          if (data.customer.hasAlcoholLicense && data.alcoholLicense) {
            storage.broadcastMessage(
              addAlcoholLicense({
                ...data.alcoholLicense,
                address: addressResult,
                validTo: `${data.alcoholLicense.year}-${data.alcoholLicense.month}-${data.alcoholLicense.day}`,
              }),
            );
          }

          const { customer } = await client(customerQuery);

          storage.broadcastMessage({ tag: QUOTE_ITEMS_INIT_REQUEST });

          return ({
            tag: CUSTOMER_CREATE_ACCOUNT_RESPONSE,
            data: parsePaymentCode(customer),
          }: CustomerResponse);
        } catch (e) {
          const error = e.httpBody.message ? e.httpBody.message : e.getMessage();
          console.error(error);

          storage.broadcastMessage(addMessage("REGISTER_ERROR", "error"));

          return ({
            tag: CUSTOMER_CREATE_ACCOUNT_RESPONSE,
            error,
          }: CustomerResponse);
        }
      }
    },
    subscribe: { [CUSTOMER_CREATE_ACCOUNT_REQUEST]: true },
  });
};

export default registerClient;

/* @flow */

import type { ProductAbstract } from "../../../types/product.flow";

import React, { useContext } from "react";
import styles from "./styles.scss";
import cn from "classnames";
import { useTranslate } from "@out-of-home/use-translate";
import { TimeContext } from "../../../context/time";
import { stockStatus, formatStockDate } from "../../../helpers/productHelper";

type Props = {
  className?: string,
  qty: number,
  product: ProductAbstract,
  exceeding: boolean,
};

export const StockMessage = ({
  product,
  qty,
  exceeding,
  className,
}: Props): React$Node => {
  const t = useTranslate();
  const status = stockStatus(product);
  const { now } = useContext(TimeContext);

  const renderMessage = (status: number) => {
    const stock = product.oohStock?.qty;
    const date = new Date(product.estimatedDeliveryDate);
    const dateFormatted =
      formatStockDate(product.estimatedDeliveryDate) || "";

    if (status === 8) {
      return t("STOCK.BACKORDER");
    }

    if (stock === 0) {
      return t(
        now >= date ? "STOCK.OUT_OF_STOCK_TODAY" : "STOCK.OUT_OF_STOCK",
        { date: dateFormatted }
      );
    }

    if (!exceeding) {
      return t("STOCK.IN_STOCK");
    }

    if (status === 3) {
      return t("STOCK.STATUS_3", { stock: stock || 0 });
    }

    if (status === 2) {
      return t("STOCK.STATUS_2", {
        stock: Math.max(0, stock || 0),
        date: dateFormatted,
      });
    }
  };

  return (
    <div
      className={cn(
        styles.block,
        { [styles.block__exceeding]: exceeding },
        className
      )}
    >
      <span>{renderMessage(status)}</span>
    </div>
  );
};

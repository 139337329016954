/* @flow */

import type { Address } from "../../../types/address.flow";
import type { AlcoholLicense } from "../../../types/alcohol-license.flow";

import React, { useContext } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./styles.scss";
import { Button } from "../../Button";
import { addressLabel } from "../../Address";
import { useBrowser } from "../../../helpers/use-browser";
import { AlcoholLicenseStatus } from "../../AlcoholLicenseStatus";
import { EditField } from "../../AccountView/Dashboard/edit-field";
import FileIcon from "../../../icons/file.svg";
import { useTranslate } from "@out-of-home/use-translate";
import { useFormat } from "../../../helpers/use-format";
import { UIContext } from "../../../context/ui";

type Props = {
  className?: string,
  items: Array<AlcoholLicense>,
};

const breakpointSmall = parseInt(styles.breakpointSmall, 10);

export const AlcoholLicenseList = ({ items, className }: Props): React$Node => {
  const { browserWidth } = useContext(UIContext);
  const { formatDateLong } = useFormat();
  const t = useTranslate();
  const browser = useBrowser();
  const useSmallView = browser
    ? browserWidth > 0 && browserWidth < breakpointSmall
    : false;

  if (!items.length) {
    return null;
  }

  if (useSmallView) {
    return (
      <div className={cn(styles.block, className)}>
        <p>
          <strong>{t("ALCOHOL_LICENSE_LIST.HEADING")}</strong>
        </p>
        <p
          dangerouslySetInnerHTML={{ __html: t("ALCOHOL_LICENSE_LIST.INFO") }}
        />

        <main className={styles.body}>
          {items.map((x) => (
            <EditField
              key={x.id}
              className={styles.item}
              to={`/${t("ROUTES.ACCOUNT")}/${t("ROUTES.ALCOHOL_LICENSE")}/${
                x.id
              }`}
              title={x.filename}
            >
              <div className="row">
                <div className={styles.heading}>
                  {t("ALCOHOL_LICENSE.STATUS_LABEL")}
                </div>
                <div>
                  <AlcoholLicenseStatus approvedAt={x.approvedAt} />
                </div>
              </div>

              <div className="row">
                <div className={styles.heading}>
                  {t("ALCOHOL_LICENSE.ADDRESS_LABEL")}
                </div>
                <div className={styles.address}>{addressLabel(x.address)}</div>
              </div>

              <div className="row">
                <div className={styles.heading}>
                  {t("ALCOHOL_LICENSE.VALID_TO_LABEL")}
                </div>
                <div>{formatDateLong(x.validTo)}</div>
              </div>

              <div className="row">
                <div className={styles.heading}>
                  {t("ALCOHOL_LICENSE.FILENAME_LABEL")}
                </div>
                <div>
                  <FileIcon className={cn(styles.icon, styles.iconFile)} />
                  &nbsp;<span className={styles.filename}>{x.filename}</span>
                </div>
              </div>

              <span className={styles.edit}>Ändra</span>
            </EditField>
          ))}
        </main>
      </div>
    );
  }

  return (
    <div className={cn(styles.block, className)}>
      <p>
        <strong>{t("ALCOHOL_LICENSE_LIST.HEADING")}</strong>
      </p>
      <p dangerouslySetInnerHTML={{ __html: t("ALCOHOL_LICENSE_LIST.INFO") }} />

      <header className={styles.header}>
        <div className={styles.column}>
          {t("ALCOHOL_LICENSE.ADDRESS_LABEL")}
        </div>
        <div className={styles.column}>
          {t("ALCOHOL_LICENSE.VALID_TO_LABEL")}
        </div>
        <div className={styles.column}>
          {t("ALCOHOL_LICENSE.FILENAME_LABEL")}
        </div>
        <div className={styles.column}>{t("ALCOHOL_LICENSE.STATUS_LABEL")}</div>
        <div className={styles.column}></div>
      </header>
      <main className={styles.body}>
        {items.map((x) => (
          <EditField
            key={x.id}
            className={styles.item}
            to={`/${t("ROUTES.ACCOUNT")}/${t("ROUTES.ALCOHOL_LICENSE")}/${
              x.id
            }`}
            title={x.filename}
          >
            <div className={cn(styles.column, styles.columnName)}>
              {addressLabel(x.address)}
            </div>
            <div className={styles.column}>{formatDateLong(x.validTo)}</div>
            <div className={styles.column}>
              <FileIcon className={cn(styles.icon, styles.iconFile)} />
              &nbsp;<span className={styles.filename}>{x.filename}</span>
            </div>
            <div className={styles.column}>
              <AlcoholLicenseStatus approvedAt={x.approvedAt} />
            </div>
            <div className={styles.column}>
              <span className={styles.edit}>Ändra</span>
            </div>
          </EditField>
        ))}
      </main>
    </div>
  );
};

/* @flow */

import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./styles.scss";
import cn from "classnames";
import { Wrapper } from "../../Wrapper";
import { PriceGroups, PriceSlider } from "../../PriceSlider";
import { Button } from "../../Button";

export const Register = ({ id }: { id?: string }): React$Node => {
  const [value, setValue] = useState(1);
  return (
    <div className={styles.block} id={id}>
      <Wrapper variant="medium">
        <h3 className={styles.mainTitle}>Skapa konto</h3>
        <h4 className={styles.subTitle}>De senaste 12 månaderna har <span className="power-link">3 292</span> företag valt att skapa konto hos oss.</h4>
        <p className={styles.selectPriceHeading}>Börja med att välja ditt pris</p>
        <PriceSlider
          value={value}
          onChange={x => {
            setValue(x);
          }}
        />
        <Button to="/registrera/1" variant="primary stretch darkBg">
          Skapa konto och aktivera rabatt
        </Button>
        <PriceGroups
          className={styles.pricesInfo}
          tableClassName={styles.pricesTable}
        />
      </Wrapper>
    </div>
  );
};

/* @flow */

import type { Product, ProductCardProduct } from "../../types/product.flow";

import React, { useState, useEffect, useRef } from "react";
import { find } from "@out-of-home/diskho";
import styles from "./styles.scss";
import cn from "classnames";
import StarIcon from "../../icons/star.svg";
import {
  useWishlist,
  useWishlistProductCard,
} from "../../helpers/use-wishlist";
import { useCustomer } from "../../helpers/use-customer";
import { useIsInWishlist } from "../../data";

type Props = {
  className?: string,
  children?: React$Node,
  product: Product | ProductCardProduct,
};

export const WishlistToggle = ({ product, ...props }: Props): React$Node => {
  const { loggedIn } = useCustomer();
  const { toggleWishlist, inWishlist, loading } = useWishlist({ product });

  if (!loggedIn) {
    return null;
  }

  return (
    <div
      className={cn(styles.block, props.className)}
      onClick={(e) => {
        e.preventDefault();

        if (!loading) {
          toggleWishlist();
        }
      }}
    >
      <StarIcon
        disabled={loading}
        className={cn(styles.icon, { [styles.active]: inWishlist })}
      />
      {props.children}
    </div>
  );
};

export const ProductCardWishlistToggle = ({
  product,
  ...props
}: Props): React$Node => {
  const { loggedIn } = useCustomer();
  const { toggleWishlist, inWishlist, loading } = useWishlistProductCard({
    product,
  });

  if (!loggedIn) {
    return null;
  }

  return (
    <div
      className={cn(styles.block, props.className)}
      onClick={(e) => {
        e.preventDefault();

        if (!loading) {
          toggleWishlist();
        }
      }}
    >
      <StarIcon
        disabled={loading}
        className={cn(styles.icon, { [styles.active]: inWishlist })}
      />
      {props.children}
    </div>
  );
};

/* @flow */

/*
 * This is where we create all state objects from our models
 * I've also put some accessor helpers in the bottom of this file
 */

import type { QuoteAddress } from "./types/quote.flow";
import type { AlcoholLicense } from "./types/alcohol-license.flow";

import type { StateData } from "crustate/react";

import { createStateData, useData } from "crustate/react";
import { BrandModel } from "./state/brand";
import { MessagesModel } from "./state/messages";
import { AllProductsModel } from "./state/all-products";
import { RouteModel } from "./state/route";
import { QuoteModel } from "./state/quote";
import { CustomerModel } from "./state/customer";
import { WishlistToggleModel } from "./state/wishlist-toggle";
import { TestimonialsModel } from "./state/testimonials";
import { BlogModel } from "./state/blog";
import { BlogListModel } from "./state/blogList";
import { FacetsModel } from "./state/facets";
import { FilterModel } from "./state/filter";
import { BrandsModel } from "./state/brands";
import { FrontpageReommendedModel } from "./state/frontpage-recommended";
import { AlcoholLicensesModel } from "./state/alcohol-licenses";
import { QuoteItemsModel } from "./state/quote-items";
import { PaymentMethodsModel } from "./state/payment-methods";
import { MyProductsModel } from "./state/my-products";
import { OrderModel } from "./state/order";
import { WishlistModel } from "./state/wishlist";
import { StockMessagesModel } from "./state/stock-messages";
import { DeliveryScheduleModel } from "./state/delivery-schedule";
import { OverdueInvoicesModel } from "./state/overdue-invoices";

export const AllProductsData: StateData<typeof AllProductsModel> =
  createStateData(AllProductsModel);
export const BrandData: StateData<typeof BrandModel> =
  createStateData(BrandModel);
export const MessagesData: StateData<typeof MessagesModel> =
  createStateData(MessagesModel);
export const StockMessagesData: StateData<typeof StockMessagesModel> =
  createStateData(StockMessagesModel);
export const QuoteData: StateData<typeof QuoteModel> =
  createStateData(QuoteModel);
export const RouteData: StateData<typeof RouteModel> =
  createStateData(RouteModel);
export const CustomerData: StateData<typeof CustomerModel> =
  createStateData(CustomerModel);
export const WishlistToggleData: StateData<typeof WishlistToggleModel> =
  createStateData(WishlistToggleModel);
export const TestimonialsData: StateData<typeof TestimonialsModel> =
  createStateData(TestimonialsModel);
export const BlogData: StateData<typeof BlogModel> = createStateData(BlogModel);
export const BlogListData: StateData<typeof BlogListModel> =
  createStateData(BlogListModel);
export const FacetsData: StateData<typeof FacetsModel> =
  createStateData(FacetsModel);
export const FilterData: StateData<typeof FilterModel> =
  createStateData(FilterModel);
export const BrandsData: StateData<typeof BrandsModel> =
  createStateData(BrandsModel);
export const FrontPageRecommendedData: StateData<
  typeof FrontpageReommendedModel
> = createStateData(FrontpageReommendedModel);
export const AlcoholLicensesData: StateData<typeof AlcoholLicensesModel> =
  createStateData(AlcoholLicensesModel);
export const QuoteItemsData: StateData<typeof QuoteItemsModel> =
  createStateData(QuoteItemsModel);
export const PaymentMethodsData: StateData<typeof PaymentMethodsModel> =
  createStateData(PaymentMethodsModel);
export const MyProductsData: StateData<typeof MyProductsModel> =
  createStateData(MyProductsModel);
export const OrderData: StateData<typeof OrderModel> =
  createStateData(OrderModel);
export const WishlistData: StateData<typeof WishlistModel> =
  createStateData(WishlistModel);
export const DeliveryScheduleData: StateData<typeof DeliveryScheduleModel> =
  createStateData(DeliveryScheduleModel);
export const OverdueInvoicesData: StateData<typeof OverdueInvoicesModel> =
  createStateData(OverdueInvoicesModel);

// Acccessor functions for easier access of specific data

/*
 * Get current quote's active shipping address
 */
export const useGetQuoteShippingAddress = (): QuoteAddress | null => {
  const quoteData = useData(QuoteData);

  return quoteData.state !== "LOADING"
    ? quoteData.data.addresses[0] || null
    : null;
};

/*
 * Check if specific SKU in in the wishlist
 */
export const useIsInWishlist = (sku: string): boolean => {
  const data = useData(WishlistData);

  if (!data.data.items) {
    return false;
  }

  return Boolean(data.data.items.find((x) => x.product.sku === sku));
};

/*
 * Get quote item qty by buyRequest
 */
export const useItemQty = (buyRequest: ?string): number => {
  const data = useData(QuoteItemsData);

  // FIXME: Not string
  const item = data[buyRequest || ""];

  return item ? item.requested : 0;
};

/*
 * Get customer alcohol license by ID
 */
export const useLicenseByID = (id: string | number): AlcoholLicense | null => {
  const data = useData(AlcoholLicensesData);
  const items = data.state !== "INITING" ? data.data : [];

  return items.find((x) => String(x.id) === String(id)) || null;
};

export const useIsQuoteItemsFetching = (): boolean => {
  const data = useData(QuoteItemsData);

  Object.keys(data).forEach((k) => {
    if (data[k].requested !== data[k].current?.qty) {
      return true;
    }
  });

  return false;
};

/* @flow */

import type { Props as ButtonProps } from "../Button";

import { useCallback, useMemo } from "react";

export type Position = "top" | "bottom";

type Props = {
  open: boolean,
  setOpen: (boolean) => void,
  className?: string,
  wrapperClassName?: string,
  position?: Position,
  title?: string,
  children?: React$Node,
  primaryAction?: string,
  primaryButton?: (ButtonProps) => React$Node,
  secondaryAction?: string,
  closeIcon?: React$Node,
  onOpen?: () => void,
  onClose?: () => void,
  onPrimaryAction?: () => void,
  onSecondaryAction?: () => void,
  isolationMode?: boolean,
};

import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useModal } from "../Modal";
import styles from "./styles.scss";
import { Button } from "../Button";

const defaultCloseIcon = (
  <svg
    height="9.89941"
    viewBox="0 0 9.89941 9.89941"
    width="9.89941"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m9.899 1.414-1.414-1.414-3.535 3.536-3.536-3.536-1.414 1.414 3.536 3.536-3.536 3.535 1.414 1.414 3.536-3.535 3.535 3.535 1.414-1.414-3.535-3.535z"
      fill="currentColor"
    />
  </svg>
);

export const Dialogue = ({
  open,
  setOpen,
  className,
  wrapperClassName,
  title,
  closeIcon = defaultCloseIcon,
  primaryAction,
  primaryButton,
  secondaryAction,
  onPrimaryAction,
  onSecondaryAction,
  onOpen,
  onClose,
  children,
  isolationMode = true,
  ...props
}: Props): React$Node => {
  const [isEntering, setEnter] = useState(false);
  const [isLeaving, setLeave] = useState(false);
  const [openLocal, setOpenLocal] = useState(false);

  const PrimaryButton = primaryButton ?? Button;

  const setOpenIfReady = useCallback((x) => {
    if (!isEntering && !isLeaving) {
      setOpen(x);
    }
  }, [isEntering, isLeaving]);

  const setIsOpen = (v) => {
    if (!isEntering && !isLeaving) {
      if (v) {
        setOpenLocal(true);
        setEnter(true);
        setTimeout(() => {
          setEnter(false);

          if (typeof onOpen === "function") {
            onOpen();
          }
        }, 500);
      } else {
        setLeave(true);
        setTimeout(() => {
          setLeave(false);
          setOpenLocal(false);

          if (typeof onClose === "function") {
            onClose();
          }
        }, 500);
      }
    }
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const { Modal } = useModal(openLocal, setOpenIfReady);
  const MemoizedModal = useMemo(() => Modal, [ openLocal, setOpenIfReady ]);

  return (
    <MemoizedModal
      isolationMode={isolationMode}
      className={cn(
        styles.wrapper,
        wrapperClassName,
        { [styles.enter]: isEntering },
        { [styles.leave]: isLeaving }
      )}
    >
      <div
        {...props}
        className={cn(
          styles.block,
          className,
          { [styles["border"]]: !isolationMode },
          { [styles[props.position]]: Boolean(props.position) }
        )}
      >
        <div className={styles.main}>
          {Boolean(title) && (
            <div className={styles.topSection}>
              <div className={styles.title}>{title}</div>

              <div
                className={styles.closeIcon}
                onClick={() => setOpenIfReady(false)}
              >
                {closeIcon}
              </div>
            </div>
          )}
          <div className={styles.body} testid="body">
            {children}
          </div>
        </div>
        {(Boolean(primaryAction) || Boolean(secondaryAction)) && (
          <div className={styles.actions}>
            {secondaryAction != null && (
              <Button
                className={styles.secondary}
                size="small"
                variant="ghost"
                onClick={() => onSecondaryAction && onSecondaryAction()}
              >
                {secondaryAction}
              </Button>
            )}

            {primaryAction != null && (
              <PrimaryButton
                size="small"
                variant="primary"
                onClick={() => onPrimaryAction && onPrimaryAction()}
              >
                {primaryAction}
              </PrimaryButton>
            )}
          </div>
        )}
      </div>

      {isolationMode && (
        <div
          className={styles.overlay}
          onClick={() => {
            setOpenIfReady(false);
          }}
        />
      )}
    </MemoizedModal>
  );
};

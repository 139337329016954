/* @flow */

import React from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./styles.scss";
import { Wrapper } from "../Wrapper";
import { Input } from "../Input";
import { Button } from "../Button";
import { Employee } from "../Employee";
import { Nag } from "../Nag";
import { Map } from "../Map";
import { useTranslate } from "@out-of-home/use-translate";
import { useRestClient } from "../../entrypoint/shared";
import { useFrontChatContext } from "../../context/front-chat";

export const EmployeesView = (): React$Node => {
  const t = useTranslate();
  const client = useRestClient();
  const [employees, setEmployees] = useState([]);
  const { open: openChatWindow, loading: chatLoading } = useFrontChatContext();

  useEffect(() => {
    client("/api/employee")
      .then((x) => x.json())
      .then(setEmployees);
  }, []);

  if (!employees) {
    return null;
  }

  return (
    <div className={styles.block}>
      <Wrapper>
        <section className={styles.info}>
          <h1 className={styles.heading}>{t("EMPLOYEES.TITLE")}</h1>
          <p>
            {t("EMPLOYEES.INFO")}
            <a onClick={openChatWindow}>{t("EMPLOYEES.CHAT_WITH_US")}</a>
          </p>
        </section>

        <div className={styles.employees}>
          {employees.map((x) => (
            <Employee key={x.id} {...x} />
          ))}
        </div>
      </Wrapper>

      <Map className={styles.map} address={["Lagergatan 1", "41511", "Göteborg"]} />

      <Helmet title={t("EMPLOYEES.TITLE")}>
        <meta name="description" content={t("EMPLOYEES.DESCRIPTION")} />
        <meta property="og:description" content={t("EMPLOYEES.DESCRIPTION")} />
        <meta property="og:title" content={t("EMPLOYEES.TITLE")} />
      </Helmet>
    </div>
  );
};

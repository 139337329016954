/* @flow */

import type { Query, Client } from "@awardit/graphql-ast-client";
import type { QuoteAddressBilling } from ".";
import type { StripePaymentReq } from "./events";

import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useStripe, Elements } from "@stripe/react-stripe-js";
import { paymentRequestButtonShow, useCompletePayment } from "./events";

type StripeType = {
  handleCardAction: (
    clientSecret: string
  ) => Promise<
    {| error: { message: string } |} | {| paymentIntent: { id: string } |}
  >,
  createPaymentMethod: ({
    type: string,
    card: any,
    billing_details: any,
  }) => Promise<
    | {| error: { message: string } |}
    | {|
        paymentIntent: { id: string },
        paymentMethod: { id: string },
      |}
  >,
};

// Button is the default case. Set to card when showing the form
export type PaymentMode = "CARD" | "DEFAULT";

export type CreateStripePaymentIntent = {
  result: string,
  error: string,
  clientSecret: string,
};

type StripePaymentProviderProps = {
  children: React$Node,
  amountToPay: number,
  storeInfo: StoreInfo,
  client: Client<{}>,
  confirmStripePaymentIntentQuery: Query<{ intent: string }, any>,
  createStripePaymentIntentQuery: Query<
    { paymentMethod: string },
    { createStripePaymentIntent: CreateStripePaymentIntent }
  >,
};

type StoreInfo = {
  baseCurrencyCode: string,
  name: string,
  defaultCountry: {
    code: string,
  },
};

type StripeContextType = {
  paymentMode: PaymentMode,
  setPaymentMode: (PaymentMode) => void,
  stripePaymentReq: ?StripePaymentReq,
  setStripePaymentReq: (StripePaymentReq) => void,
  browser: ?string,
  setBrowser: (string) => void,
  payWithStripe: (void) => Promise<mixed>,
  canMakeButtonPayment: boolean,
  setCanMakeButtonPayment: (boolean) => void,
  amountToPay: number,
  billingAddress: ?QuoteAddressBilling,
  setBillingAddress: (?QuoteAddressBilling) => void,
  email: ?string,
  setEmail: (?string) => void,
  storeInfo: StoreInfo,
  stripe: StripeType,
  confirmStripePaymentIntentQuery: Query<{ intent: string }, any>,
  createStripePaymentIntentQuery: Query<
    { paymentMethod: string },
    { createStripePaymentIntent: CreateStripePaymentIntent }
  >,
};

export const SUBCURRENCY_MULTIPLIER = 100;
export const StripeContext = React.createContext<StripeContextType>({});

const StripePaymentProviderInner = ({
  client,
  amountToPay,
  storeInfo,
  confirmStripePaymentIntentQuery,
  createStripePaymentIntentQuery,
  children,
}: StripePaymentProviderProps) => {
  const [browser, setBrowser] = useState<string | null>(null);
  const [stripePaymentReq, setStripePaymentReq] = useState(null);
  const stripe = useStripe();
  const [paymentMode, setPaymentMode] = useState<PaymentMode>("DEFAULT");
  const [canMakeButtonPayment, setCanMakeButtonPayment] =
    useState<boolean>(false);
  const [billingAddress, setBillingAddress] =
    useState<?QuoteAddressBilling>(null);
  const [email, setEmail] = useState<?string>(null);
  const completePayment = useCompletePayment(email, billingAddress);

  const payWithStripe = () => {
    if (!stripePaymentReq) {
      throw new Error("Stripe not loaded");
    }

    // Card payment
    if (paymentMode === "CARD") {
      return completePayment({
        stripe,
        client,
        paymentMode,
        confirmStripePaymentIntentQuery,
        createStripePaymentIntentQuery,
      });
    }

    // Button payment
    if (paymentMode === "DEFAULT") {
      return paymentRequestButtonShow(stripePaymentReq).then((ev) => {
        return completePayment({
          stripe,
          client,
          paymentMode,
          paymentMethodID: ev.paymentMethod.id,
          confirmStripePaymentIntentQuery,
          createStripePaymentIntentQuery,
        });
      });
    }

    throw new Error("Invalid stripeMethod");
  };

  // Give Stripe the data when amountToPay is updated
  useEffect(() => {
    if (stripe) {
      const shopName = storeInfo.name;
      const country = storeInfo.defaultCountry.code;
      const currency = storeInfo.baseCurrencyCode.toLowerCase();

      setStripePaymentReq(
        stripe.paymentRequest({
          country,
          currency,
          total: {
            label: shopName,
            amount: Math.floor(amountToPay * SUBCURRENCY_MULTIPLIER),
          },
        })
      );
    }
  }, [stripe, amountToPay, storeInfo]);

  // Set can make button payment
  useEffect(() => {
    if (stripePaymentReq) {
      stripePaymentReq.canMakePayment().then((result) => {
        if (result) {
          setBrowser(result.applePay ? "apple" : "other");
          setCanMakeButtonPayment(Boolean(result));
        }
      });
    }
  }, [stripePaymentReq, stripe, setBrowser]);

  return (
    <StripeContext.Provider
      value={{
        confirmStripePaymentIntentQuery,
        createStripePaymentIntentQuery,
        paymentMode,
        setPaymentMode,
        stripePaymentReq,
        setStripePaymentReq,
        browser,
        setBrowser,
        payWithStripe,
        stripe,
        storeInfo,
        amountToPay,
        canMakeButtonPayment,
        setCanMakeButtonPayment,
        billingAddress,
        setBillingAddress,
        email,
        setEmail,
      }}
    >
      {children}
    </StripeContext.Provider>
  );
};

export const StripePaymentProvider = ({
  stripeKey,
  ...props
}: StripePaymentProviderProps & { stripeKey: string }) => {
  const [stripePromise, setStripePromise] = useState(null);

  /*
   * Load stripe script here instead of StripePaymentProviderInner
   * beacuse it's needed in <Elements />. The rest of the effects
   * is placed in  StripePaymentProviderInner
   */
  useEffect(() => {
    if (!stripePromise && stripeKey) {
      setStripePromise(loadStripe(stripeKey));
    }
  }, [stripeKey]);

  return (
    <Elements stripe={stripePromise}>
      <StripePaymentProviderInner {...props} />
    </Elements>
  );
};

/* @flow */

import type {
  WishlistToggleLoadRequest,
  WishlistToggleLoadResponse,
  WishlistToggleAddProductRequest,
  WishlistToggleAddProductResponse,
  WishlistToggleRemoveProductRequest,
  WishlistToggleRemoveProductResponse,
} from "../state/wishlist-toggle";

import type { Storage } from "crustate";
import { addMessage } from "../state/messages";
import type { Client } from "@awardit/graphql-ast-client";
import {
  wishlist as wishlistQuery,
  addToWishlist as addToWishlistMutation,
  removeFromWishlist as removeFromWishlistMutation,
} from "../queries";

import {
  WISHLIST_TOGGLE_LOAD_REQUEST,
  WISHLIST_TOGGLE_LOAD_RESPONSE,
  WISHLIST_TOGGLE_ADD_PRODUCT_REQUEST,
  WISHLIST_TOGGLE_ADD_PRODUCT_RESPONSE,
  WISHLIST_TOGGLE_REMOVE_PRODUCT_REQUEST,
  WISHLIST_TOGGLE_REMOVE_PRODUCT_RESPONSE,
} from "../state/wishlist-toggle";

import { WISHLIST_LOAD_REQUEST } from "../state/wishlist";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: WishlistToggleLoadRequest) => {
      const { wishlist } = await client(wishlistQuery, undefined, {
        cache: false,
      });

      return ({
        tag: WISHLIST_TOGGLE_LOAD_RESPONSE,
        data: wishlist.items,
      }: WishlistToggleLoadResponse);
    },
    subscribe: { [WISHLIST_TOGGLE_LOAD_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: WishlistToggleAddProductRequest) => {
      const { addToWishlist } = await client(addToWishlistMutation, {
        buyRequest: msg.buyRequest,
      });

      if (addToWishlist.result !== "success") {
        return storage.broadcastMessage(
          addMessage(addToWishlist.result, "error")
        );
      }

      storage.broadcastMessage({ tag: WISHLIST_LOAD_REQUEST });

      return ({
        tag: WISHLIST_TOGGLE_ADD_PRODUCT_RESPONSE,
        data: addToWishlist,
        sku: msg.sku,
      }: WishlistToggleAddProductResponse);
    },
    subscribe: { [WISHLIST_TOGGLE_ADD_PRODUCT_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: WishlistToggleRemoveProductRequest) => {
      const { removeFromWishlist } = await client(removeFromWishlistMutation, {
        itemId: msg.itemId,
      });

      if (removeFromWishlist.result !== "success") {
        return storage.broadcastMessage(
          addMessage(removeFromWishlist.result, "error")
        );
      }

      storage.broadcastMessage({ tag: WISHLIST_LOAD_REQUEST });

      return ({
        tag: WISHLIST_TOGGLE_REMOVE_PRODUCT_RESPONSE,
        itemId: msg.itemId,
        sku: msg.sku,
      }: WishlistToggleRemoveProductResponse);
    },
    subscribe: { [WISHLIST_TOGGLE_REMOVE_PRODUCT_REQUEST]: true },
  });
};

export default registerClient;

/* @flow */

import type { WFMOrder } from "../../../../types/wfm.flow";

import React, { useState } from "react";
import cn from "classnames";
import { useSendMessage } from "crustate/react";
import { sendJson } from "@out-of-home/fetch-utils";
import { Button } from "../../../Button";
import { useTranslate } from "@out-of-home/use-translate";
import { OrderSelect } from "../../order-select";
import sharedStyles from "../../shared-styles.scss";

import {
  Form,
  rules,
  conditional,
  isRequired,
  isEmail,
} from "@awardit/formaggio";

type Props = {
  className?: string,
};

type State = {
  department: "sales" | "finance" | "customer-service",
  inquiry: string,
  inquiry_describe: string,
  feedback: string,
  feedback_phone: string | null,
  loggedIn: boolean,
};

// @TODO: use Addimotion instead
export const Track = (props: Props): React$Node => {
  const sendMessage = useSendMessage();
  const t = useTranslate();
  const [order, setOrder] = useState<WFMOrder | null>(null);
  console.log(order?.trackingUrls);

  return (
    <div className={sharedStyles.type}>
      <div className="row--large">
        <OrderSelect order={order} setOrder={(x) => setOrder(x)} />
      </div>

      {order && (
        <div className="row--large">
          {order.trackingUrls.map((track, i) => (
            <div className="row" key={track.code}>
              <a href={track.url} target="_blank" rel="noopener noreferrer">
                <Button variant="ghost small">
                  {order.trackingUrls.length > 1
                    ? t("ORDER.TRACK", { index: "#" + (i + 1) })
                    : t("ORDER.TRACK", { index: "" })}
                </Button>
              </a>
            </div>
          ))}
          {order.trackingUrls.length === 0 && (
            <p className={sharedStyles.message}>
              Inga spårningsnummer hittades på din order. Maila{" "}
              <a href="mailto:kundservice@outofhome.se">
                kundservice@outofhome.se
              </a>{" "}
              så hjälper vi dig!
            </p>
          )}
        </div>
      )}
    </div>
  );
};

/* @flow */

import type { MeterType } from ".";

import React, { useState, useEffect } from "react";
import { useTranslate } from "@out-of-home/use-translate";
import { useFormat } from "../../helpers/use-format";
import { usePrevious } from "../../helpers";
import { Meter, ANIMATION_TIME } from ".";

type Props = {
  amount: number,
  text: string,
  textFilled: string,
  of: Array<number>,
  discount: Array<number>,
  hidden: boolean,
  // In percent
  height?: number,
  type: MeterType,
};

const percentOf = (l: number, of: Array<number>, amount: number) => {
  const prevOf = of[l - 2] || 0;
  const currentOf = of[l - 1];

  const calculatedAmount = prevOf ? amount - prevOf : amount;
  const calculatedOf = currentOf - prevOf;

  return Math.min((calculatedAmount / (calculatedOf || 0)) * 100, 100);
};

/**
 * Used in cart to display the amount left until free shipping and such
 */
export const LevelledMeter = ({
  amount,
  type,
  hidden,
  height,
  ...props
}: Props): React$Node => {
  const t = useTranslate();
  const { formatPrice } = useFormat();
  const [levelup, setLevelup] = useState(false);
  const [hasTransition, setHasTransition] = useState(true);
  const [overridePercent, setOverridePercent] = useState(null);

  // get current level in "of" array
  const level = props.of.reduce(
    (acc, current) => (amount > current ? acc + 1 : acc),
    1
  );

  const of = props.of[level - 1]; // || props.of[level - 2];
  let percent = percentOf(level, props.of, amount);
  const text = percent === 100 ? props.textFilled : props.text;
  const discountPercent =
    props.discount[level - 1] || props.discount[level - 2];
  const discountAmount = amount * (discountPercent / 100);

  const prevLevel = usePrevious(level);
  const prevPercent = usePrevious(percent);
  const prevAmount = usePrevious(amount);

  if (prevLevel === level - 1) {
    percent = 100;
  }

  useEffect(() => {
    const shouldLevelUp = level > prevLevel;

    if (shouldLevelUp) {
      setLevelup(true);
      setOverridePercent(100);
      setTimeout(() => {
        setHasTransition(false);

        setTimeout(() => {
          setOverridePercent(0);
          setTimeout(() => {
            setHasTransition(true);
            setLevelup(false);
            setOverridePercent(null);
          }, 0);
        }, ANIMATION_TIME * 2);
      }, ANIMATION_TIME);
    }
  }, [level]);

  return (
    <Meter
      hidden={hidden}
      height={height}
      type={type}
      text={t(text, {
        discountPercent: discountPercent,
        amount: formatPrice(of - amount),
      })}
      amount={amount}
      percent={overridePercent !== null ? overridePercent : percent}
      hasTransition={hasTransition}
      levelup={levelup}
    />
  );
};

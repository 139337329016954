/* @flow */

import React from "react";
import styles from "./styles.scss";
import { Route } from "react-router";
import { Helmet } from "react-helmet-async";
import { Wrapper } from "../Wrapper";
import { Button } from "../Button";
import { useTranslate } from "@out-of-home/use-translate";

/**
 * Generic 404 view for when a route couldn"t resolve.
 */
const NotFound = (): React$Node => {
  const t = useTranslate();

  return (
    <div className={styles.block}>
      <Wrapper className={styles.wrapper}>
        <h1 className={styles.heading}>{t("404.HEADING")}</h1>

        <p
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: t("404.TEXT") }}
        />
        <Button to="/" variant="primary">
          {t("404.BUTTON")}
        </Button>
      </Wrapper>

      <Helmet title={t("404.TITLE")} />
    </div>
  );
};

export const NotFoundView = (): React$Node => (
  <Route
    render={({ staticContext }) => {
      if (staticContext) {
        staticContext.status = 404;
      }

      return <NotFound />;
    }}
  />
);

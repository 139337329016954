/* @flow */

import React from "react";
import styles from "./styles.scss";

type Props = {
  meta: boolean,
  image: string,
  firstname: string,
  lastname: string,
  title: string,
  email: string,
  phone: string,
  mobile: string,
};

export const Employee = ({
  image,
  firstname,
  lastname,
  title,
  email,
  phone,
  mobile,
  meta = true,
}: Props): React$Node => (
  <div className={styles.block}>
    <img src={image} alt={`${firstname} ${lastname}`} />
    <span className={styles.name}>{`${firstname} ${lastname}`}</span>
    <span className={styles.title}>{title}</span>
    {meta && (
      <div className={styles.meta}>
        <p>{email}</p>
        <p>{phone}</p>
        <p>{mobile}</p>
      </div>
    )}
  </div>
);

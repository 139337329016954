/* @flow */

import type { FrontPageBlock as FrontPageBlockType } from "../../types/my-front-page.flow";

import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useTranslate } from "@out-of-home/use-translate";
import { UIContext } from "../../context/ui";
import { StoreInfoContext } from "../../entrypoint/shared";
import { Wrapper } from "../Wrapper";
import { ProductList } from "../ProductList";
import { Excerpt } from "../Excerpt";
import { ProductCard } from "../ProductCard";
import { HeadingRow } from "../HeadingRow";
import { queryToLocation } from "../../helpers/filterHelpers";
import { zeroPrefix } from "../../helpers/deliveryCalendarHelpers";
import styles from "./styles.scss";
import { restProductToGQLProduct } from "../../helpers";

type Props = {
  block: FrontPageBlockType,
  inSplitView?: boolean,
  fullWidth?: boolean,
  listName: string,
};

const TYPE_EXCERPT = [
  "PRODUCTS_FILTER",
  "PRODUCTS_HISTORY",
  "PRODUCTS_FAVOURITES",
  "PRODUCTS_CONTRACT",
  "PRODUCTS_FEATURED",
  "PRODUCTS_BY_IDS",
  "MY_CAT_TAKEOVER",
];

const useGetURL = (block: FrontPageBlockType) => {
  const t = useTranslate();
  const routes = useContext(StoreInfoContext).translations.ROUTES;

  switch (block.type) {
    case "PRODUCTS_HISTORY":
    case "PRODUCTS_CONTRACT":
      return t("ROUTES.CONTRACT");
    case "PRODUCTS_FAVOURITES":
      return t("ROUTES.MY_PRODUCTS");
    case "PRODUCTS_FILTER":
      if (!block.filter) {
        return null;
      }

      const filter = Object.freeze(block.filter);

      if (
        filter.hasOwnProperty("main_category") &&
        !Array.isArray(filter.main_category)
      ) {
        filter.main_category = [filter.main_category];
      }

      const l = queryToLocation({ filter }, routes);

      return l.pathname + l.search;
    case "MY_CAT_TAKEOVER":
      return block.url;
  }

  return null;
};

export const FrontPageBlock = ({
  block,
  inSplitView = false,
  fullWidth = false,
  listName
}: Props): React$Node => {
  const { displayMode } = useContext(UIContext);
  const url = useGetURL(block);

  if (block.type === "CMS_BLOCK") {
    return (
      <div>
        <HeadingRow title={block.title} />
        <div
          className={cn(styles.block, "typography")}
          dangerouslySetInnerHTML={{
            __html: block.cmsBlock.content || block.description || "",
          }}
        />
      </div>
    );
  }

  if (TYPE_EXCERPT.indexOf(block.type) !== -1) {
    return (
      <div
        className={cn(styles.block, [
          styles["block__" + block.products.length],
          { [styles["block__fullWidth"]]: Boolean(fullWidth) },
        ])}
      >
        <Excerpt
          products={block.products}
          description={block.description}
          inSplitView={Boolean(inSplitView)}
          productDisplayMode={Boolean(block.featured) ? "LARGE" : displayMode}
          title={block.title}
          url={url}
          alwaysShowURL={true}
          evenRows={true}
          perRow={4 /* sidebar is taking up one column */}
          productListProps={{
            fullWidth: Boolean(fullWidth),
            perRow: block.products.length === 2 ? 2 : 5,
            listName,
          }}
        />
      </div>
    );
  }

  return null;
};

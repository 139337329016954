/* @flow */

import React, { useState, useRef, useEffect } from "react";
import { useTranslate } from "@out-of-home/use-translate";
import { useSendMessage, useData } from "crustate/react";
import cn from "classnames";
import { NavLink, Link, useHistory } from "react-router-dom";
import { NewEmailInput } from "../NewEmailInput";
import { Route, Switch } from "react-router";
import { Button } from "../Button";
import { Input } from "../Input";
import { Wrapper } from "../Wrapper";
import { Field } from "../Field";
import styles from "./styles.scss";
import { CustomerData } from "../../data";
import { login } from "../../state/customer";

type Props = {
  className?: string,
  children?: React$Node,
};

export const LoginForm = ({ className, children}: Props): React$Node => {
  const t = useTranslate();
  const [state, setState] = useState({ email: "", password: "" });
  const { state: customerState } = useData(CustomerData);
  const isLoggingIn = customerState === "LOGGING_IN";
  const sendMessage = useSendMessage();

  const submit = (e) => {
    e.preventDefault();

    if (!isLoggingIn) {
      sendMessage(login(state.email, state.password));
    }
  };

  return (
    <div className={cn(styles.block, className)}>
    <form className={styles.body} onSubmit={submit} disabled={isLoggingIn}>
      <div className={styles.row}>
        <Input
          id="email"
          value={state.email}
          onChange={(e) => setState({ ...state, email: e.target.value })}
          label={t("ADDRESS.EMAIL")}
          variant="box"
          type="email"
        />
      </div>
      <div className={styles.row}>
        <Input
          id="password"
          value={state.password}
          onChange={(e) => setState({ ...state, password: e.target.value })}
          label={t("ACCOUNT.PASSWORD")}
          variant="box"
          type="password"
        />
      </div>

      <footer className={styles.footer}>
        <span className={styles.links}>
          <Link to={"/" + t("ROUTES.FORGOT_PASSWORD")}>
              Glömt lösenord?
          </Link>

          <Link to={"/" + t("ROUTES.REGISTER")}>
              Skapa konto
          </Link>
        </span>

        <Button variant="primary" loading={isLoggingIn} type="submit">
          Logga in
        </Button>
      </footer>
    </form>
    </div>
  );
};

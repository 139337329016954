/* @flow */

import React from "react";
import { Link } from "react-router-dom";
import ChevronIcon from "../../icons/chevron.svg";
import styles from "./styles.scss";
import cn from "classnames";

type Props = {
  children: React$Node,
  title: string,
  onClickTitle: () => void,
  open?: boolean,
};

type ItemProps = {
  className?: string,
  children: React$Node,
  disabled?: boolean,
  active: boolean,
  to?: string,
  onClick?: () => void,
};

export const Drawer = ({
  children,
  title,
  onClickTitle,
  open = false,
}: Props): React$Node => {
  return (
    <div>
      <div
        className={cn(styles.item, styles.title, {
          [styles.title__open]: open,
        })}
        onClick={onClickTitle}
      >
        {title}&nbsp;
        <ChevronIcon className={styles.chevron} />
      </div>
      {open && children}
    </div>
  );
};

export const DrawerItem = ({
  className,
  children,
  onClick,
  disabled,
  active,
  to,
}: ItemProps): React$Node => {
  const classes = cn(styles.item, { [styles.item__active]: active }, className);
  if (to) {
    return (
      <Link
        onClick={(e) => {
          if (Boolean(disabled)) {
            e.preventDefault();
          }
        }}
        to={to}
        className={classes}
      >
        {children}
      </Link>
    );
  }

  return (
    <div className={classes} onClick={!Boolean(disabled) && onClick}>
      {children}
    </div>
  );
};

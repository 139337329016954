/** @flow */

import type { Config } from "../src/types/config.flow";

module.exports = ({
  host: 'localhost',
  port: 9100,
  apiHost: {
    server: 'http://127.0.0.1',
    client: '',
    proxyHeaders: ['Ssl', 'Ssl-Offload', 'X-Forward-Proto', 'Host', 'X-Real-Ip', 'X-Forwarded-For', 'Cookie', 'User-Agent'],
    passHeaders:  ['Set-Cookie'],
    headers: []
  },
  googleAnalytics: [
    { store: "se", currencyCode: "SEK", accounts: ["G-Z4GGFPS72K"] },
    { store: "no", currencyCode: "NOK", accounts: [] },
  ],
  datadog: {
    clientToken: "pub46530441821694313838f3a791952fac",
    site: "datadoghq.eu",
    service: "outofhome_frontend",
    sessionSampleRate: 100,
    forwardConsoleLogs: ["warn", "error"],
    env: "production",
  },
  frontChatId: "7badd03d106c4f09c668ff6703090bf8",
}: Config);

/* @flow */

import React from "react";
import cn from "classnames";
import { Link, useLocation } from "react-router-dom";
import styles from "../../components/Filters/styles.scss";
import { Button } from "../../components/Button";
import { useTranslate } from "@out-of-home/use-translate";

import { Drawer, DrawerItem } from "../../components/Drawer";

type Props = {
  items: Array<string>,
  loading: boolean,
  layout?: "LARGE" | "SMALL",

  showContracted: boolean,
  onClose?: () => void,
};

type ItemProps = {
  item: { url: string, title: string },
};

const Item = ({ item }: ItemProps) => {
  const location = useLocation();
  const pathname = encodeURI(location.pathname);
  const active = pathname + location.search === item.url;

  return (
    <Button
      className={cn(styles.link, { [styles.link__active]: active })}
      to={item.url}
    >
      {item.title}
    </Button>
  );
};

const mapItems = (a) => [
  ...a.map((x) => ({ url: "/produktlista/" + encodeURI(x), title: x })),
];
/*
const favorites = { url: "/inkopslista/?shoppinglist=Favoriter", title: "Favoriter" };
const earlierPurchased = { url: "/inkopslista", title: "Tidigare köpta varor" };
const contracted = { url: "/inkopslista/?shoppinglist=Avtalade%20produkter", title: "Avtalade produkter" };
*/

const favorites = {
  url: "/inkopslista/?shoppinglist=favoriter",
  title: "Favoriter",
};
const earlierPurchased = { url: "/inkopslista", title: "Tidigare köpta varor" };
const contracted = {
  url: "/inkopslista/?shoppinglist=Avtalade%20produkter",
  title: "Avtalade produkter",
};

export const Menu = ({
  layout = "LARGE",
  loading,
  showContracted,
  onClose,
  ...props
}: Props): React$Node => {
  const t = useTranslate();
  const items = showContracted
    ? [earlierPurchased, favorites, contracted, ...mapItems(props.items)]
    : [earlierPurchased, favorites, ...mapItems(props.items)];

  return (
    <div className={styles.blockWrapper}>
      {layout === "SMALL" && (
        <div className={styles.block} tabIndex="-1">
          <div className={styles.top}>
            {items.map((x) => (
              <Item key={x.title} item={x} />
            ))}
            <div className={styles.topInner} />
          </div>
          <div className={styles.bottom}>
            <Button
              className={styles.button}
              modifier="primary"
              onClick={onClose}
            >
              {t("FILTER.ACTIVATE_FILTER")}
            </Button>
          </div>
        </div>
      )}
      {layout !== "SMALL" && (
        <div className={styles.block}>
          <div className={styles.facetRow}>
            <div className={styles.facetRowInner}>
              <span className={styles.heading}>Inköpslista</span>
            </div>
          </div>
          {items.map((x) => (
            <div key={x.title}>
              <Item item={x} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

/* @flow */

import type { TFacet } from "../../types/filter.flow";
import { StoreInfoContext } from "../../entrypoint/shared";

import React, { useRef, useState, useEffect, useContext } from "react";
import cn from "classnames";
import { useLocation, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import styles from "./styles.scss";
import { Wrapper } from "../Wrapper";
import { Button } from "../Button";
import { Social } from "../Social";
import { Foldable } from "../Foldable";
import { useTranslate } from "@out-of-home/use-translate";
import { useRestClient } from "../../entrypoint/shared";
import TermsIcon from "../../icons/chat.svg";
import CustomerServiceIcon from "../../icons/document.svg";
import { stringifyParams } from "@out-of-home/location-search-string";
import { UIContext } from "../../context/ui";
import { TimeContext } from "../../context/time";
import { ArrowLink } from "../ArrowLink";

type Props = {
  className?: string,
  children?: React$Node,
};

const CATEGORIES_LIMIT = 5;

type CategoryDrawerProps = {
  facet: TFacet,
  layout: "SMALL" | "LARGE",
};

export const CategoryDrawer = ({
  facet,
  layout,
}: CategoryDrawerProps): React$Node => {
  const t = useTranslate();
  const [open, setOpen] = useState(false);
  const location = useLocation();

  // Close on page change
  useEffect(() => {
    setOpen(false);
  }, [location]);

  const toggle = () => {
    setOpen(!open);
  };

  const renderChildren = () => {
    return (
      <div className={styles.categoryChildren}>
        {facet.ooh_product_type
          .filter((x, i) => i < CATEGORIES_LIMIT)
          .map((c) => (
            <Link
              key={facet.key + c.key}
              className={styles.categoryChild}
              to={`/p/${facet.key}${stringifyParams({
                ooh_product_type: c.key,
              })}`}
            >
              <span>{c.key}</span>
              <span className={styles.categoryCount}>({c.count})</span>
            </Link>
          ))}
      </div>
    );
  };

  return (
    <div className={styles.category}>
      {layout === "SMALL" && (
        <div className={styles.foldableParent}>
          <div className={styles.toggler} onClick={toggle}>
            <span>{facet.key}</span>
            <span>({facet.count})</span>
          </div>
          <Foldable open={open}>{renderChildren()}</Foldable>
        </div>
      )}
      {layout !== "SMALL" && (
        <div>
          <Link className={styles.categoryLink} to={`/p/${facet.key}`}>
            {facet.key}
          </Link>
          {renderChildren()}
          <ArrowLink className={styles.categoryMore} to={`/p/${facet.key}`}>
            {t("FRONTPAGE.CATEGORIES_CTA")}
          </ArrowLink>
        </div>
      )}
    </div>
  );
};

export const Footer = ({ children, className }: Props): React$Node => {
  const t = useTranslate();
  const { now } = useContext(TimeContext);
  const year = now.getFullYear();
  const client = useRestClient();
  const element = useRef();
  const facets = useContext(StoreInfoContext).footer.main_category;
  const { browserWidth } = useContext(UIContext);
  const useSmallView =
    browserWidth > 0 && browserWidth < parseInt(styles.breakpointSmall, 10);

  return (
    <footer className={cn(styles.block, className, "no-underline")} ref={element}>
      <Wrapper className={styles.categoriesWrapper}>
        <div className={styles.categories}>
          {facets.map((x) => (
            <CategoryDrawer
              key={x.key}
              facet={x}
              layout={useSmallView ? "SMALL" : "LARGE"}
            />
          ))}
        </div>
      </Wrapper>
      <div className={styles.columnsWrapper}>
        <Wrapper>
          <div className={styles.container}>
            <div className={styles.column}>
              <div className={styles.columnIcon}>
                <TermsIcon />
              </div>
              <span className={styles.columnTitle}>
                {t("FOOTER.TERMS_AND_CONDITIONS")}
              </span>
              <div className={styles.columnText}>
                <p>{t("FOOTER.TERMS_AND_CONDITIONS_TEXT")}</p>
              </div>
              <p className={styles.columnLinks}>
                <ArrowLink
                  to={"/" + t("ROUTES.TERMS")}
                  className={styles.terms_link}
                >
                  {t("FOOTER.TERMS_LINK")}
                </ArrowLink>
                <br />
                <ArrowLink
                  to={"/" + t("ROUTES.PRIVACY")}
                  className={styles.terms_link}
                >
                  {t("FOOTER.PRIVACY_LINK")}
                </ArrowLink>
              </p>
            </div>
            <div className={styles.column}>
              <div className={cn(styles.columnIcon, styles.columnIconCustomerService)}>
                <CustomerServiceIcon />
              </div>
              <span className={styles.columnTitle}>
                {t("FOOTER.CUSTOMER_SERVICE")}
              </span>
              <div className={styles.columnText}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("FOOTER.CUSTOMER_SERVICE_TEXT"),
                  }}
                />

                <div className={styles.split}>
                  <ArrowLink to="/kundservice">
                    Gå till Kundservice
                  </ArrowLink>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </div>

      <section className={styles.links}>
        <Wrapper className={styles.links__wrapper}>
          <div>
            <span className={cn("h5", styles.heading)}>{t("FOOTER_LINKS.INFORMATION")}</span>
            <ul>
              <li>
                <Link to={"/" + t("ROUTES.ABOUT_US")}>
                  {t("FOOTER_LINKS.ABOUT_US")}
                </Link>
              </li>
              <li>
                <Link to={"/" + t("ROUTES.FAQ")}>
                  {t("FOOTER_LINKS.FAQ")}
                </Link>
              </li>
              <li>
                <Link to={"/" + t("ROUTES.CUSTOMER_SERVICE")}>
                  {t("FOOTER_LINKS.CUSTOMER_SERVICE")}
                </Link>
              </li>
              <li>
                <Link to={"/" + t("ROUTES.NEWS")}>
                  {t("FOOTER_LINKS.NEWS")}
                </Link>
              </li>
              <li>
                <Link to={"/" + t("ROUTES.REGISTER") + "/1"}>
                  {t("FOOTER_LINKS.REGISTER")}
                </Link>
              </li>

              <li>
                <Link to={"/" + t("ROUTES.FORGOT_PASSWORD")}>
                  {t("FOOTER_LINKS.FORGOT_PASSWORD")}
                </Link>
              </li>
              <li>
                <Link to={"/" + t("ROUTES.CLIMATE_COMPENSATION")}>
                  {t("FOOTER_LINKS.CLIMATE_COMPENSATION")}
                </Link>
              </li>
              <li>
                <a
                  href="https://jobb.outofhome.se/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("FOOTER_LINKS.WORK_FOR_US")}
                </a>
              </li>
            </ul>
          </div>
          <div>
            <span className={cn("h5", styles.heading)}>{t("FOOTER_LINKS.STOCK")}</span>
            <ul>
              <li>
                <Link to={"/" + t("ROUTES.BRANDS")}>
                  {t("FOOTER_LINKS.OUR_SUPPLIERS")}
                </Link>
              </li>
              <li>
                <Link to={"/" + t("ROUTES.BECOME_SUPPLIER")}>
                  {t("FOOTER_LINKS.BECOME_SUPPLIER")}
                </Link>
              </li>
              <li>
                <Link to="/p">{t("FOOTER_LINKS.ALL_PRODUCTS")}</Link>
              </li>
              <li>
                <Link to={"/" + t("ROUTES.CAMPAIGNS")}>
                  {t("FOOTER_LINKS.CAMPAIGNS")}
                </Link>
              </li>
              <li>
                <Link to={"/" + t("ROUTES.OUT_OF_DATE")}>
                  {t("FOOTER_LINKS.OUT_OF_DATE")}
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <span className={cn("h5", styles.heading)}>{t("FOOTER_LINKS.WHOLESALER_FOR")}</span>
            <ul>
              <li>
                <Link to={t("ROUTES.CUSTOMER_SEGMENT_1")}>
                  {t("FOOTER_LINKS.RESTAURANT")}
                </Link>
              </li>
              <li>
                <Link to={t("ROUTES.CUSTOMER_SEGMENT_2")}>
                  {t("FOOTER_LINKS.CAFE")}
                </Link>
              </li>
              <li>
                <Link to={t("ROUTES.CUSTOMER_SEGMENT_4")}>
                  {t("FOOTER_LINKS.HOTEL")}
                </Link>
              </li>
              <li>
                <Link to={t("ROUTES.CUSTOMER_SEGMENT_8")}>
                  {t("FOOTER_LINKS.OFFICE")}
                </Link>
              </li>
              <li>
                <Link to={t("ROUTES.CUSTOMER_SEGMENT_6")}>
                  {t("FOOTER_LINKS.GYM")}
                </Link>
              </li>
              <li>
                <Link to={t("ROUTES.CUSTOMER_SEGMENT_7")}>
                  {t("FOOTER_LINKS.GROCERY_STORE")}
                </Link>
              </li>
              <li>
                <Link to={t("ROUTES.CUSTOMER_SEGMENT_9")}>
                  {t("FOOTER_LINKS.PRODUCTION")}
                </Link>
              </li>
              <li>
                <Link to={t("ROUTES.CUSTOMER_SEGMENT_10")}>
                  {t("FOOTER_LINKS.PUBLIC_SECTOR")}
                </Link>
              </li>
              <li>
                <Link to={t("ROUTES.CUSTOMER_SEGMENT_11")}>
                  {t("FOOTER_LINKS.CONVENIENCE_STORES")}
                </Link>
              </li>
              <li>
                <Link to={t("ROUTES.CUSTOMER_SEGMENT_12")}>
                  {t("FOOTER_LINKS.RETAIL_STORE")}
                </Link>
              </li>
              <li>
                <Link to={t("ROUTES.CUSTOMER_SEGMENT_13")}>
                  {t("FOOTER_LINKS.WHOLESALE")}
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <span className={cn("h5", styles.heading)}>{t("FOOTER_LINKS.OPEN_TRADE")}</span>
            <ul>
              <li>
                <Link to={t("ROUTES.OPEN_TRADE_1")}>
                  {t("FOOTER_LINKS.OPEN_TRADE_1")}
                </Link>
              </li>
              <li>
                <Link to={t("ROUTES.OPEN_TRADE_2")}>
                  {t("FOOTER_LINKS.OPEN_TRADE_2")}
                </Link>
              </li>
              <li>
                <Link to={t("ROUTES.OPEN_TRADE_3")}>
                  {t("FOOTER_LINKS.OPEN_TRADE_3")}
                </Link>
              </li>
              <li>
                <Link to={t("ROUTES.OPEN_TRADE_4")}>
                  {t("FOOTER_LINKS.OPEN_TRADE_4")}
                </Link>
              </li>
            </ul>
          </div>
        </Wrapper>
      </section>

      <div className={styles.copyright}>
        <Wrapper>
          <div>
            <p><b>{t("FOOTER.COPYRIGHT", { year })}</b></p>
            <p dangerouslySetInnerHTML={{ __html: t("FOOTER.ADDRESS") }} />
          </div>
          <Social />
        </Wrapper>
      </div>
    </footer>
  );
};

/* @flow */

import cn from "classnames";
import React, { useEffect, useRef } from "react";
import { Button } from "../Button";
import styles from "./styles.scss";
import { debounce } from "@out-of-home/diskho";
import ChevronBtnIcon from "../../icons/chevronbtn.svg";

type OverflowCarouselProps = {
  children: React$Node;
  className?: string;
}

const OVERFLOW_LIMIT = 10;

export const OverflowCarousel = ({ children, className }: OverflowCarouselProps): React$Node => {
  const contentRef = useRef<HTMLDivElement|null>(null);
  const blockRef = useRef<HTMLDivElement|null>(null);
  const [overflowDir, setOverflowDir] = React.useState<"left" | "right" | "both" | "none">("none");
  const hasScrolled = useRef<boolean>(false);

  useEffect(() => {
    const content = contentRef.current;

    const onScroll = debounce((): void => {
      if (contentRef.current) {
        const contentWidth = contentRef.current.clientWidth;
        const contentScrollWidth = contentRef.current.scrollWidth;
        const scrollLeft = contentRef.current.scrollLeft;

        if (scrollLeft > 0) {
          hasScrolled.current = true;
        }

        const overflowLeft = scrollLeft > OVERFLOW_LIMIT;
        const overflowRight = (scrollLeft + contentWidth) < contentScrollWidth - 1 - OVERFLOW_LIMIT;

        if (overflowLeft && overflowRight) {
          setOverflowDir("both");
        }
        else if (overflowLeft) {
          setOverflowDir("left");
        }
        else if (overflowRight) {
          setOverflowDir("right");
        }
        else {
          setOverflowDir("none");
        }
      }
    }, 100);

    if (contentRef.current) {
      contentRef.current.addEventListener("scroll", onScroll);
    }

    const observer = new ResizeObserver(onScroll);

    if (contentRef.current) {
      observer.observe(contentRef.current);
    }

    // run onScroll once to set initial state
    onScroll();

    return () => {
      if (contentRef.current) {
        contentRef.current.removeEventListener("scroll", onScroll);
      }
      observer.disconnect();
    };
  }, []);

  return (
    <div className={cn(styles.block, className)} ref={blockRef}>
      {(overflowDir === "both" || overflowDir ===  "left" )&&
        <div className={styles.buttonLeft}>
          <Button
            className={cn({ [styles.hasScrolled]: hasScrolled.current })}
            ariaLabel="Föregående sida"
            onClick={(): void => {
              if (contentRef.current && blockRef.current) {
                contentRef.current.scrollLeft = contentRef.current.scrollLeft - blockRef.current.clientWidth;
              }
            }}
          >
            <ChevronBtnIcon
              alt="Föregående sida"
              aria-label="Föregående sida"
              style={{
                transform: "rotate(180deg)",
              }}
            />
          </Button>
        </div>
      }

      <div ref={contentRef} className={styles.overflowCarousel}>
        {children}
      </div>

      {(overflowDir === "both" || overflowDir ===  "right" )&&
        <div className={styles.buttonRight}>
          <Button
            className={cn({ [styles.hasScrolled]: hasScrolled.current })}
            ariaLabel="Nästa sida"
            onClick={(): void => {
              if (contentRef.current && blockRef.current) {
                contentRef.current.scrollLeft = contentRef.current.scrollLeft + blockRef.current.clientWidth;
              }
            }}
          >
            <ChevronBtnIcon
              alt="Nästa sida"
              aria-label="Nästa sida"
              style={{
                transform: "rotate(0deg)",
              }}
            />
          </Button>
        </div>
      }
    </div>
  );
};

/* @flow */

import type { FrontPageBlock as FrontPageBlockType } from "../../types/my-front-page.flow";

import React, { useState, useContext, useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import cn from "classnames";
import { useData } from "crustate/react";
import { useTranslate } from "@out-of-home/use-translate";
import { FacetsData } from "../../data";
import { useCustomer } from "../../helpers/use-customer";
import { useRestClient } from "../../entrypoint/shared";
import { Wrapper } from "../Wrapper";
import { Button } from "../Button";
import { FrontPageBlock } from "../FrontPageBlock";
import { FrontPageList } from "../FrontPageBlock/frontPageList";
import { blocksToUIRows, rowKey, removeDuplicates } from "../../helpers/myfrontpageHelpers";
import breakpoints from "../../helpers/breakpoints";
import StarIcon from "../../icons/star.svg";
import styles from "./styles.scss";
import { range, restProductToGQLProduct } from "../../helpers";
import { DisplayModeSwitcher } from "../DisplayModeSwitcher";
import { UIContext } from "../../context/ui";

export const MyFrontPageView = (): React$Node => {
  const client = useRestClient();
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [rows, setRows] = useState([]);
  const [blocksFetching, setBlocksFetching] = useState(true);
  const facetsData = useData(FacetsData);
  const facets = facetsData.facets;

  useEffect(() => {
    client(`/api/attributes/main_category`)
      .then((x) => x.json())
      .then((x) => {
        setCategories(x.main_category);
      });

      // First call
      client(`/api/myfrontpage?page=1`).then(x => x.json()).then(x => {
        const BLOCK_PAGE_SIZE = 4;
        const pages = Math.ceil(x.total_count / BLOCK_PAGE_SIZE);

        setRows(
          blocksToUIRows(
            x.blocks.map((x) => ({
              ...x,
              products: x.products
                ? x.products.map(restProductToGQLProduct)
                : [],
            }))
          )
        );

        setBlocksFetching(false);

        const promises = range(2, pages).map(x => client(`/api/myfrontpage?page=${x}`));

        Promise.all(promises)
          .then(x => Promise.all(x.map(x => x.json())))
          .then(resps => {
            const data = resps.flatMap(x => {
              return blocksToUIRows(x.blocks.map((x) => ({
                  ...x,
                  products: x.products
                    ? x.products.map(restProductToGQLProduct)
                    : [],
                })));
            });

            setRows(old => {
              const oldTitles = old.map(rowKey);
              // Filter out duplicates because of bug where Elasticsearch filters products
              // after pagination: https://linear.app/ooh/issue/OOH-238/dubbla-produkt-block-pa-alla-produkter-and-min-startsida
              const dataFiltered = data.filter(x => !oldTitles.includes(x.title));

              return [...old, ...removeDuplicates(dataFiltered)]
            });
          })
          .then(() => {
          });
      });
  }, []);

  const t = useTranslate();
  const { customer } = useCustomer();

  if (!customer) {
    /* @TODO:
     Maybe check this in routes and skip
     the rendering of this page if no customer */
    return null;
  }

  const customerType = customer.ooh3Type || "";

  return (
    <Wrapper className={styles.wrapper}>
      <div className={styles.block}>
        <div className={cn(styles.sidebar, "no-underline")}>
          <nav className={styles.navlist}>
            <span className={styles.navlistTitle}>
              {t("NAV.QUICK_LINKS")}
              <StarIcon />
            </span>

            <ul className={styles.navlistList}>
              {/* <li className={styles.navlistItem}>
                <Link
                  className={styles.navlistLink}
                  to="/p/?season=Jul"
                >
                  Julsortiment
                </Link>
              </li> */}
              <li className={styles.navlistItem}>
                <Link
                  className={styles.navlistLink}
                  to="/p/?certified=Kampanjer"
                >
                  {t("NAV.QUICK_LINKS_ITEMS.CAMPAIGNS")}
                </Link>
              </li>

              <li className={styles.navlistItem}>
                <Link className={styles.navlistLink} to="/p/?certified=Nyheter">
                  {t("NAV.QUICK_LINKS_ITEMS.NEWS")}
                </Link>
              </li>

              <li className={styles.navlistItem}>
                <Link
                  className={styles.navlistLink}
                  to={`/rekommenderade/${encodeURIComponent(customerType)}`}
                >
                  {t("NAV.QUICK_LINKS_ITEMS.POPULAR", { type: customerType })}
                </Link>
              </li>
            </ul>
          </nav>

          <nav className={styles.navlist}>
            <span className={styles.navlistTitle}>Huvudkategorier</span>
            <ul className={styles.navlistList}>
              {categories.map((x) => (
                <li key={x.key} className={styles.navlistItem}>
                  <Link className={styles.navlistLink} to={"/p/" + x.key}>
                    {x.key}{" "}
                    <span className={styles.navlistCount}>({x.count})</span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className={styles.main}>
          <div className={styles.controls}>
            <DisplayModeSwitcher className={styles.displaymodeswitcher} />
          </div>

          <FrontPageList loading={blocksFetching} rows={rows} listName="my_frontpage" />
        </div>
      </div>

      <Helmet title={t("MY_FRONTPAGE.TITLE")} />
    </Wrapper>
  );
};

/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { Translations } from "@out-of-home/use-translate";
import type { StoreInfo } from "../types/storeInfo.flow";

import { TimeProvider } from "../context/time";
import { UIProvider } from "../context/ui";
import { HelmetProvider } from "react-helmet-async";
import {
  MessagesData,
  StockMessagesData,
  QuoteData,
  CustomerData,
  WishlistToggleData,
  QuoteItemsData,
  AlcoholLicensesData,
  WishlistData,
  FacetsData,
  OverdueInvoicesData,
} from "../data";
import { StorageProvider } from "crustate/react";
import { StoreInfoContext, ClientProvider, RestClientProvider } from "./shared";
import { TranslationProvider } from "@out-of-home/use-translate";
import { Router } from "react-router";
import { Routes } from "./routes";
import { WithAnalytics } from "../helpers/analytics/context";
import { googleAnalytics } from "../config";

import React from "react";
import { FrontChatContextProvider } from "../context/front-chat";

type Props = {
  isNode: boolean,
  shop: StoreInfo,
  storage: Storage,
  client: Client<{}>,
  restClient: Fetch,
  translations: {},
  now: Date,
  children?: React$Node,
  history: {
    push: (path: string) => void,
  },
};

const filterAnalyticsAccounts = (accounts, shop: StoreInfo): Array<string> => {
  return (
    (accounts || [])
      .filter((x) => x.store === shop.info.defaultCountry.code.toLowerCase())
      .filter((x) => x.currencyCode === shop.info.baseCurrencyCode)
      .map((x) => x.accounts)[0] || []
  );
};

const ConditionalHelmetProvider = ({ wrap, children }) =>
  wrap ? <HelmetProvider>{children}</HelmetProvider> : children;

export const ReactEntrypoint = ({
  isNode,
  now,
  shop,
  storage,
  client,
  restClient,
  translations,
  history,
  children,
}: Props): React$Node => (
  <StoreInfoContext.Provider value={shop}>
    <ClientProvider value={client}>
      <RestClientProvider value={restClient}>
        <ConditionalHelmetProvider wrap={!isNode}>
          <TranslationProvider translations={(translations: any)}>
            <StorageProvider storage={storage}>
              <WishlistData.Provider>
                <CustomerData.Provider>
                  <OverdueInvoicesData.Provider>
                    <UIProvider>
                      <TimeProvider value={{ now }}>
                        <MessagesData.Provider>
                          <StockMessagesData.Provider>
                            <AlcoholLicensesData.Provider>
                              <QuoteData.Provider>
                                <QuoteItemsData.Provider>
                                  <WishlistToggleData.Provider>
                                    <FacetsData.Provider>
                                      {typeof children !== "undefined" ? (
                                        children
                                      ) : (
                                        <>
                                          {isNode ? (
                                            <Routes />
                                          ) : (
                                            <FrontChatContextProvider>
                                              <Router history={history}>
                                                <WithAnalytics
                                                  currencyCode={shop.info.baseCurrencyCode}
                                                  accounts={filterAnalyticsAccounts(googleAnalytics, shop)}
                                                >
                                                  <Routes />
                                                </WithAnalytics>
                                              </Router>
                                            </FrontChatContextProvider>
                                          )}
                                        </>
                                      )}
                                    </FacetsData.Provider>
                                  </WishlistToggleData.Provider>
                                </QuoteItemsData.Provider>
                              </QuoteData.Provider>
                            </AlcoholLicensesData.Provider>
                          </StockMessagesData.Provider>
                        </MessagesData.Provider>
                      </TimeProvider>
                    </UIProvider>
                  </OverdueInvoicesData.Provider>
                </CustomerData.Provider>
              </WishlistData.Provider>
            </StorageProvider>
          </TranslationProvider>
        </ConditionalHelmetProvider>
      </RestClientProvider>
    </ClientProvider>
  </StoreInfoContext.Provider>
);

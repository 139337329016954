/* @flow */

import React, { useContext } from "react";
import styles from "./styles.scss";
import { useLocation } from "react-router-dom";
import { useTranslate } from "@out-of-home/use-translate";
import cn from "classnames";
import { UIContext } from "../../../context/ui";
import { useData } from "crustate/react";
import { StoreInfoContext } from "../../../entrypoint/shared";
import { locationToQuery } from "../../../helpers/filterHelpers";

type Props = {
  className?: string,
};

const FILTER_ROUTES = ["/p"];

export const FilterAndSortTabs = ({ className }: Props): React$Node => {
  const {
    sortModalOpen,
    filterModalOpen,
    setSortModalOpen,
    setFilterModalOpen,
  } = useContext(UIContext);
  const t = useTranslate();
  const location = useLocation();
  const { translations } = useContext(StoreInfoContext);
  const query = locationToQuery(location, translations);
  const numFilters = Object.keys(query.filter || {}).length;
  const filters = numFilters ? `(${numFilters})` : "";
  const sorts = query.sort ? "(1)" : "";
  const page = location.pathname.split("/").filter((x) => x)[0];

  const showSort =
    !query.search &&
    !query.customerType &&
    !query.product &&
    page !== t("ROUTES.MY_PRODUCTS");

  return (
    <div>
      <div className={cn(styles.block, className)}>
        <div className={styles.tabs}>
          <button
            className={cn(styles.title, {
              [styles.title__muted]: filterModalOpen,
            })}
            onClick={() => setFilterModalOpen(true)}
          >
            {t("OTHER.FILTER")} <span className={styles.num}>{filters}</span>
          </button>

          {showSort && (
            <button
              className={cn(styles.title, {
                [styles.title__muted]: sortModalOpen,
              })}
              onClick={() => setSortModalOpen(true)}
            >
              {t("OTHER.SORT")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export const FilterAndSortTabsSpacer = (): React$Node => (
  <div className={styles.spacer} />
);

/* @flow */

import type { WFMOrder, ServiceTaskBody } from "../types/wfm.flow";
import type { Customer } from "../types/customer.flow";

import { useSendMessage } from "crustate/react";
import { useTranslate } from "@out-of-home/use-translate";
import { addMessageTranslated } from "../state/messages";
import { useClient } from "../entrypoint/shared";
import { createCustomerServiceTask } from "../queries";
import { useCustomer } from "./use-customer";

function useSubmitCustomerServiceTask<T>(
  { items, order }: { items: T, order: ?WFMOrder },
  prepareBodyFunction: (
    order: WFMOrder,
    items: T,
    customer: Customer
  ) => ServiceTaskBody,
  setProcessing: (boolean) => void,
  onSuccess: () => void
): (e: SyntheticEvent<HTMLFormElement>) => Promise<?null> {
  const sendMessage = useSendMessage();
  const { customer } = useCustomer();
  const client = useClient();
  const t = useTranslate();

  return async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!order || !customer) {
      return null;
    }

    setProcessing(true);
    const body = prepareBodyFunction(order, items, customer);

    try {
      const {
        createCustomerServiceTask: { result },
      } = await client(createCustomerServiceTask, body);
      setProcessing(false);

      if (result === "success") {
        onSuccess();
      } else {
        sendMessage(
          addMessageTranslated(
            t(`STATUS_CODE.WFM__${result.toUpperCase()}`),
            "error"
          )
        );
      }
    } catch (e) {
      setProcessing(false);
      sendMessage(addMessageTranslated(t("STATUS_CODE.WFM__ERROR"), "error"));
    }
  };
}

export { useSubmitCustomerServiceTask };

/* @flow */

import React from "react";
import styles from "./styles.scss";
import cn from "classnames";

type Props = {
  children: React$Node,
  element?: string,
  className?: string,
  variant?: string,
};

/**
 * Component for wrapping other components in the site wrapper
 */
export const Wrapper = ({
  children,
  element,
  variant,
  ...props
}: Props): React$Node => {
  const Elem = element || "div";
  const modArr = variant ? variant.split(" ") : [];

  const modifiers = modArr.reduce((obj, modifier) => {
    obj[styles[`block__${modifier}`]] = true;

    return obj;
  }, {});

  return (
    <Elem className={cn(styles.block, props.className, { ...modifiers })}>
      {children}
    </Elem>
  );
};

/* @flow */

import React, { useState, useEffect, useContext, useCallback } from "react";
import { throttle } from "@out-of-home/diskho";
import { UIContext } from "../../context/ui";
import { getScrollTop, usePrevious } from "../../helpers";

/**
 * Handles the hiding and showing of the menu when you scroll up/down
 */
export const useAutoHide = (
  headerHeight: number,
  cartOpen: boolean,
): ({
  hidden: boolean,
  setHidden: (hidden: boolean) => void,
}) => {
  const [scrollTop, setScrollTop] = useState(0);
  const [hidden, setHidden] = useState(false);
  const { setHeaderVisible } = useContext(UIContext);
  const prevScrollTop = usePrevious(scrollTop);

  useEffect(() => {
    let id;
    const setHeaderState = () => {
      id = requestAnimationFrame(() => {
        setScrollTop(getScrollTop());
      });
    };

    const throttledSetState = throttle(setHeaderState, 100);
    document.addEventListener("scroll", throttledSetState, { passive: true });

    return () => {
      cancelAnimationFrame(id);
      document.removeEventListener("scroll", throttledSetState);
    };
  }, []);

  useEffect(() => {
    const hidden = !cartOpen && scrollTop > headerHeight && scrollTop > (prevScrollTop || 0);
    setHidden(hidden);
    setHeaderVisible(!hidden);
  }, [scrollTop, headerHeight, cartOpen]);

  const _setHidden = useCallback((hidden: boolean) => {
    setHidden(hidden);
    setHeaderVisible(!hidden);
  }, []);

  return {
    hidden,
    setHidden: _setHidden,
  };
};

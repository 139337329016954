/* @flow */

type Wishlist = {
  items: Array<{
    id: string,
    product: {
      sku: number,
    },
  }>,
};

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";
import { EVENT_CUSTOMER_LOGIN, EVENT_CUSTOMER_LOGOUT } from "./events";

type Data =
  | { state: "LOADING", data: Wishlist }
  | { state: "LOADED", data: Wishlist };

export type WishlistResponse = Response<
  typeof WISHLIST_LOAD_RESPONSE,
  Wishlist
>;

export type WishlistRequest = {
  tag: typeof WISHLIST_LOAD_REQUEST,
};

export const WISHLIST_LOAD_RESPONSE: "wishlist/load/response" =
  "wishlist/load/response";
export const WISHLIST_LOAD_REQUEST: "wishlist/load/request" =
  "wishlist/load/request";

export const load = (): WishlistRequest => ({ tag: WISHLIST_LOAD_REQUEST });

export const WishlistModel: Model<
  Data,
  {},
  WishlistRequest | WishlistResponse
> = {
  id: "wishlist",
  init: () =>
    updateData(
      { state: "LOADING", data: { items: [] } },
      { tag: WISHLIST_LOAD_REQUEST }
    ),
  update: (state: Data, msg) => {
    switch (msg.tag) {
      case WISHLIST_LOAD_REQUEST:
        return updateData(state, msg);
      case EVENT_CUSTOMER_LOGIN:
      case EVENT_CUSTOMER_LOGOUT:
        return updateData(
          { state: "LOADING", data: { items: [] } },
          { tag: WISHLIST_LOAD_REQUEST }
        );

      case WISHLIST_LOAD_RESPONSE:
        if (msg.error) {
          return updateData({ state: "LOADING", data: state.data });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;
      default:
    }
  },
};

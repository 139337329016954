/* @flow */

import type { Storage } from "crustate";
import type { Fetch } from "@out-of-home/fetch-utils";
import type { FilterRequest, FilterResponse } from "../state/filter";
import type { FrontPageBlock } from "../types/my-front-page.flow";
import type { Translations } from "@out-of-home/use-translate";

import { jsonResponse } from "@out-of-home/fetch-utils";
import { stringifyParams } from "@out-of-home/location-search-string";

import { FILTER_LOAD_REQUEST, FILTER_LOAD_RESPONSE } from "../state/filter";

import {
  removeSearchURL,
  removeBrandURL,
  removeCategoryURL,
  filterToggleURL,
  filterResetURL,
  setSortURL,
  queryToLocation,
  locationToQuery,
} from "../helpers/filterHelpers";
import { FACETS_LOAD_RESPONSE } from "../state/facets";
import { restProductToGQLProduct } from "../helpers";
import type { TFacet } from "../types/filter.flow";

const handleProductsResponse = (x) => {
  if (x.status === 404) {
    return {
      total: 0,
      products: [],
      facets: null,
    };
  } else {
    return jsonResponse(x);
  }
};

const registerClient = (
  storage: Storage,
  restClient: Fetch,
  translations: Object
) => {
  let allProductsCache = null;

  let filterCache = {};
  let facetCache = {};

  const loadFacets = async (location: any) => {
    const query = locationToQuery(location, translations.ROUTES);
    const parts = location.pathname.split("/").filter((x) => x);
    const routes = translations.ROUTES;
    (routes: { [string]: string });

    const params: any = {
      ...query.filter,
      limit: 0,
      aggregate: true,
    };

    let url = "";
    if (parts[0] === routes["MY_PRODUCTS"]) {
      url = "shoppinglist";
      params.skipRecommended = true;
    } else if (query.customerType) {
      url = "products_customertype/" + query.customerType;
    } else if (query.product) {
      url = "products_recommended/" + query.product;
      params.product = true;
    } else if (query.search) {
      url = "search";
      params.query = query.search + "*";
    } else {
      url = "products_faceted";
    }

    const req = await restClient(`/api/${url}${stringifyParams(params)}`);

    // Handle 404 as a normal response
    if (req.status === 404) {
      return {
        total: 0,
        product: [],
        facets: null,
      };
    }

    return req.json();
  };

  loadFacets({ pathname: "/p", search: "" }).then((x: { facets: { key: string }[] }) => {
    facetCache[""] = {
      ...x,
      facets: x.facets.filter(facet => facet.key !== "ooh_product_type"),
    };
  });

  storage.addEffect({
    effect: async (msg: FilterRequest) => {
      if (msg.tag !== FILTER_LOAD_REQUEST) {
        return;
      }

      const query = locationToQuery(msg.location, translations.ROUTES);
      const queryIsPristine =
        !Object.keys(query.filter || {}).length &&
        !query.search &&
        !query.customerType &&
        !query.product;
      // Skip page 1, use canonical URL for it
      const currentPage =
        msg.requestedPages[0] == 1 ? undefined : msg.requestedPages[0];
      query.page = currentPage;

      // use brand -> ooh_product_type -> popularity when sorting on brand
      const sort =
        query.sort === "brand"
          ? "manufacturer,brand,ooh_product_type,name"
          : query.sort;

      const location = queryToLocation(query, translations.ROUTES);
      const params = stringifyParams((query.filter: any));
      const cacheParams = (query.product
        ? params + "&recommended=" + query.product
        : params) + (query.search || "");

      if (facetCache[cacheParams]) {
        storage.broadcastMessage({
          tag: FACETS_LOAD_RESPONSE,
          data: {
            ...facetCache[cacheParams],
            query,
          },
        });
      } else {
        let facets = await loadFacets(msg.location);

        if (query.search && facets.facets) {
          // if the result is from an active search, sort the buckets in descending order based on products in it
          facets.facets = (facets.facets: TFacet[])
            .map(f => ({
              ...f,
              buckets: f.buckets.map(x => x).sort((a, b) => b.count - a.count)
            }));
        }

        facetCache[cacheParams] = facets;

        if (facets.infoBlock?.featuredProducts?.items?.length) {
          facets.infoBlock.featuredProducts.items =
            facets.infoBlock.featuredProducts.items.map(
              restProductToGQLProduct
            );
        }

        storage.broadcastMessage({
          tag: FACETS_LOAD_RESPONSE,
          data: {
            ...facets,
            query,
          },
        });
      }

      const limit = String(msg.pageSize);

      const promises = queryIsPristine
        ? []
        : msg.requestedPages
            // .filter(p => /*!stateIsEqual || */msg.requestedPages.indexOf(p) === -1 && loaded.indexOf(p) === -1)
            .map((p) => {
              const handlePayload = (x) => ({
                page: p,
                total: x.total,
                items: x.products,
                suggestions: x.suggestions || [],
              });

              if (query.onWishlist === true) {
                return restClient(
                  "/api/shoppinglist/groupings" + stringifyParams(query.filter)
                )
                  .then(handleProductsResponse)
                  .then(handlePayload);
              }
              if (query.product) {
                return restClient(
                  "/api/products_recommended/" +
                    query.product +
                    stringifyParams({ ...query.filter, page: String(p), limit })
                )
                  .then(handleProductsResponse)
                  .then(handlePayload);
              }
              if (query.customerType) {
                return restClient(
                  "/api/products_customertype/" +
                    query.customerType +
                    stringifyParams({ ...query.filter, page: String(p), limit })
                )
                  .then(handleProductsResponse)
                  .then(handlePayload);
              }
              if (query.search) {
                return restClient(
                  "/api/search" +
                    stringifyParams({
                      ...query.filter,
                      query: query.search,
                      page: String(p),
                      limit,
                    })
                )
                  .then(handleProductsResponse)
                  .then(handlePayload);
              }

              const params = stringifyParams({
                ...query.filter,
                sort: sort || "",
                page: String(p),
                limit,
              });

              return new Promise((resolve) => {
                if (filterCache[params]) {
                  return resolve(filterCache[params]);
                } else {
                  return restClient("/api/products_faceted" + params)
                    .then(jsonResponse)
                    .then((payload) => ({
                      page: p,
                      total: payload.total || 0,
                      items: payload.products || [],
                    }))
                    .then((x) => {
                      filterCache[params] = x;

                      return resolve(x);
                    });
                }
              });
            });

      const d = await Promise.all(promises);
      const total = d.length > 0 ? parseInt(d[0].total, 10) : 60;
      const suggestions = d[0] && Array.isArray(d[0].suggestions) ? d[0].suggestions : [];

      return ({
        tag: FILTER_LOAD_RESPONSE,
        data: {
          pages: d,
          total,
          staticBlocks: [],
          query,
          suggestions,
        },
      }: FilterResponse);
    },
    subscribe: { [FILTER_LOAD_REQUEST]: true },
  });
};

export default registerClient;

/* @flow */

import type { ProductDetailAbstract } from "../../../types/product.flow";

import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { useTranslate } from "@out-of-home/use-translate";
import {
  outputAttribute,
  outputSalesUnit,
} from "../../../helpers/productHelper";
import styles from "./styles.scss";

type Props = {
  product: {
    ...ProductDetailAbstract,
    attributes: {
      ...ProductDetailAbstract["attributes"],
      gtinBox: ?string,
      gtinPcs: ?string
    }
  },
};

// eslint-disable-next-line complexity
export const Specification = ({ product }: Props): React$Node => {
  const {
    manufacturer,
    supplierSku,
    brand,
    oohStPrPkt,
    oohPktPrPall,
    oohDabas,
  } = product.attributes;
  const t = useTranslate();

  return (
    <div className={cn(styles.specification, "cf")}>
      <div className={styles.specificationColumn}>
        <span className={styles.specificationLabel}>
          {t("PRODUCT.SUPPLIER")}
        </span>
        <Link
          className={cn(styles.specificationValue, styles.manufacturer)}
          to={`/p/?manufacturer=${encodeURIComponent(manufacturer)}`}
        >
          {outputAttribute(manufacturer)}
        </Link>
      </div>
      <div className={styles.specificationColumn}>
        <span className={styles.specificationLabel}>{t("PRODUCT.BRAND")}</span>
        <span className={styles.specificationValue}>
          {outputAttribute(brand)}
        </span>
      </div>
      <div className={styles.specificationColumn}>
        <span className={styles.specificationLabel}>
          {t("PRODUCT.SALES_UNIT")}
        </span>
        <span className={styles.specificationValue}>
          {outputSalesUnit(product.attributes)}
        </span>
      </div>

      <div className={styles.specificationColumn}>
        <span className={styles.specificationLabel}>
          {t("PRODUCT.OUR_SKU")}
        </span>
        <span className={styles.specificationValue}>
          {outputAttribute(product.sku)}
        </span>
      </div>
      <div className={styles.specificationColumn}>
        <span className={styles.specificationLabel}>{t("PRODUCT.SKU")}</span>
        <span className={styles.specificationValue}>
          {outputAttribute(supplierSku)}
        </span>
      </div>
      <div className={styles.specificationColumn}>
        <span className={styles.specificationLabel}>
          {t("PRODUCT.QTY_IN_PACKAGE")}
        </span>
        <span className={styles.specificationValue}>
          {outputAttribute(oohStPrPkt, " " + t("PRODUCT.PCS"))}
        </span>
      </div>

      <div className={styles.specificationColumn}>
        <span className={styles.specificationLabel}>
          {t("PRODUCT.PACKAGES_IN_PALLET")}
        </span>
        <span className={styles.specificationValue}>
          {outputAttribute(oohPktPrPall, " " + t("PRODUCT.PCS"))}
        </span>
      </div>
      <div className={styles.specificationColumn}>
        <span className={styles.specificationLabel}>
          {t("PRODUCT.EAN_PCS")}
        </span>
        <span className={styles.specificationValue}>
          {product.attributes.gtinPcs?.trim() ? product.attributes.gtinPcs : "-" }
        </span>
      </div>
      <div className={styles.specificationColumn}>
        <span className={styles.specificationLabel}>
        {t("PRODUCT.EAN_BOX")}
        </span>
        <span className={styles.specificationValue}>
          {product.attributes.gtinBox?.trim() ? product.attributes.gtinBox : "-" }
        </span>
      </div>
      {oohDabas !== null && oohDabas != " " && (
        <div className={styles.specificationColumn}>
          <span className={styles.specificationLabel}>
            {t("PRODUCT.DABAS")}
          </span>
          <a
            className={cn(styles.specificationValue, "link")}
            target="_blank"
            rel="noopener noreferrer"
            href={outputAttribute(oohDabas)}
            title={t("PRODUCT.LINK")}
          >
            {t("PRODUCT.LINK")}
          </a>
        </div>
      )}
    </div>
  );
};

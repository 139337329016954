/* @flow */

import type { Customer } from "../../../types/customer.flow";

import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useSendMessage } from "crustate/react";
import { useTranslate } from "@out-of-home/use-translate";
import styles from "../EditUser/styles.scss";
import { Wrapper } from "../../Wrapper";
import { Input } from "../../Input";
import { Button } from "../../Button";
import { Checkbox } from "../../Checkbox";
import { Field } from "../../Field";
import { focusInvalidField } from "../../../helpers/formHelpers";
import { useCustomer } from "../../../helpers/use-customer";
import { setPNO } from "../../../state/customer";
import {
  Form,
  rules,
  conditional,
  isRequired,
  isEmail,
} from "@awardit/formaggio";

const validation = rules([isRequired("customerPno")]);

type Props = {
  className?: string,
};

export const EditOrgNumber = (): React$Node => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const { customer, state: customerState } = useCustomer();
  const loading = customerState === "UPDATING";

  const [state, setState] = useState({
    customerPno: customer?.customerPno || "",
  });

  const validations = validation(state);

  const submit = (e) => {
    e.preventDefault();
    sendMessage(setPNO(state.customerPno));
  };

  return (
    <Wrapper variant="thin" className={styles.block}>
      <h3 className="text-color--muted">{t("ACCOUNT.EDIT_ORG_NUMBER")}</h3>

      <Form
        onSubmit={submit}
        autoComplete="off"
        onError={focusInvalidField}
        errors={validations}
        value={(state: any)}
        onChange={(x) => setState({ ...state, ...(x: any) })}
      >
        <div className="row row--large">
          <Field
            name="customerPno"
            label={t("ADDRESS.ORG_NUMBER")}
            variant="box"
          />
        </div>

        <div className={cn("row", "row--large", styles.buttons)}>
          <Button
            disabled={loading}
            className={styles.button}
            variant="ghost large"
            to={"/" + t("ROUTES.ACCOUNT")}
          >
            {t("ACCOUNT.BACK")}
          </Button>
          <Button
            loading={loading}
            className={styles.button}
            variant="primary large"
            type="submit"
          >
            {t("ACCOUNT.SAVE")}
          </Button>
        </div>
      </Form>
    </Wrapper>
  );
};

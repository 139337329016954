/** @flow */

import type { ProductDetailAbstract } from "../types/product.flow";

type ListItem = {
  "@type": "ListItem",
  position: number,
  name: string,
  item?: string,
};

type BreadcrumbList = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  itemListElement: ListItem[],
};

type BreadcrumbLink = {
  type: "link",
  text: string,
  url: string,
};

type BreadcrumbName = {
  type: "name",
  text: string,
};

type Breadcrumb = BreadcrumbLink | BreadcrumbName;
export type BreadcrumbProduct = { name: string, attributes: { mainCategory: string[], oohProductType: string }};

export const buildJSONLDBreadcrumbLists = (product: $ReadOnly<BreadcrumbProduct>): BreadcrumbList[] => {
  const list: BreadcrumbList[] = product.attributes.mainCategory.reduce((a, c) => {
    a.push({
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Hela sortimentet",
          item: "https://outofhome.se/p",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: c,
          item: `https://outofhome.se/p/${encodeURIComponent(c)}`,
        },
        {
          "@type": "ListItem",
          position: 3,
          name: product.attributes.oohProductType,
          item: `https://outofhome.se/p/${encodeURIComponent(c)}?ooh_product_type=${encodeURIComponent(product.attributes.oohProductType)}`,
        },
        {
          "@type": "ListItem",
          position: 4,
          name: product.name,
        },
      ],
    });

    return a;
  }, []);

  return list;
};

export const getBreadcrumbParts = (list: BreadcrumbList): Array<Breadcrumb> => {
  return list.itemListElement.map((x) => {
    if (x.item) {
      return {
        type: "link",
        text: x.name,
        url: x.item.replace("https://outofhome.se", ""),
      };
    }

    return {
      type: "name",
      text: x.name,
    };
  });
};

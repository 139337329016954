/* @flow */

import type { Node as ReactNode } from "react";
import React, { useContext, createContext, useState, useEffect } from "react";
import { useCustomer } from "../helpers/use-customer";
import { throttle } from "@out-of-home/diskho";
import { useBrowser } from "../helpers/use-browser";

const DEFAULT_BROWSER_WIDTH = 1350;
const DEFAULT_BROWSER_HEIGHT = 900;

export type DisplayMode = "GRID" | "LIST";

export type TUIContext = {
  displayMode: DisplayMode,
  headerVisible: boolean,
  /** the headers height in pixels, without the preheader */
  headerHeight: number,
  setDisplayMode: (DisplayMode) => void,
  setHeaderVisible: (boolean) => void,
  /** set the header height, in pixels, without the preheader */
  setHeaderHeight: (number) => void,
  setSortModalOpen: (boolean) => void,
  setFilterModalOpen: (boolean) => void,
  setLoginModalOpen: (boolean) => void,
  sortModalOpen: boolean,
  loginModalOpen: boolean,
  filterModalOpen: boolean,
  browserWidth: number,
  browserHeight: number,
  showNotificationBar: boolean,
  setShowNotificationBar: (boolean) => void,
};

type UIProviderProps = {
  children: ReactNode,
};

export const UIContext: React$Context<TUIContext> = createContext<TUIContext>({
  displayMode: "GRID",
  headerVisible: false,
  headerHeight: 72,
  setDisplayMode: (d: DisplayMode) => undefined,
  setHeaderVisible: (d: boolean) => undefined,
  setHeaderHeight: (height: number) => undefined,
  setSortModalOpen: (d: boolean) => undefined,
  setFilterModalOpen: (d: boolean) => undefined,
  setLoginModalOpen: (d: boolean) => undefined,
  sortModalOpen: false,
  filterModalOpen: false,
  loginModalOpen: false,
  browserWidth: DEFAULT_BROWSER_WIDTH,
  browserHeight: DEFAULT_BROWSER_HEIGHT,
  showNotificationBar: false,
  setShowNotificationBar: (show: boolean) => undefined,
});

export const UIProvider = ({ children }: UIProviderProps): React$Node => {
  const [displayMode, setDisplayMode] = useState<DisplayMode>("GRID");
  const [headerVisible, setHeaderVisible] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(72);
  const [modalOpen, setModalOpen] = useState("");
  const { loggedIn, customer } = useCustomer();
  const browser = useBrowser();
  const [showNotificationBar, setShowNotificationBar] = useState(true);

  const [width, setWidth] = useState(browser ? window.innerWidth : DEFAULT_BROWSER_WIDTH);
  const [height, setHeight] = useState(browser ? window.innerHeight : DEFAULT_BROWSER_HEIGHT);

  useEffect(() => {
    if (browser) {
      const handleResize = throttle(() => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      }, 1000);
      window.addEventListener("resize", handleResize);

      // set width and height once directly
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);

      return () => window.removeEventListener("resize", handleResize);
    }
  }, [browser]);

  useEffect(() => {
    if (modalOpen === "login") {
      setModalOpen("");
    }
  }, [loggedIn]);

  const value = {
    displayMode: loggedIn && customer ? customer.ooh3PreferredListLayout : displayMode,
    browserWidth: width,
    browserHeight: height,
    setDisplayMode,
    headerVisible,
    setHeaderVisible,
    setSortModalOpen: (x) => (x ? setModalOpen("sort") : setModalOpen("")),
    setFilterModalOpen: (x) => (x ? setModalOpen("filter") : setModalOpen("")),
    setLoginModalOpen: (x) => (x ? setModalOpen("login") : setModalOpen("")),
    sortModalOpen: modalOpen === "sort",
    filterModalOpen: modalOpen === "filter",
    loginModalOpen: modalOpen === "login",
    headerHeight,
    setHeaderHeight,
    showNotificationBar,
    setShowNotificationBar,
  };

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};

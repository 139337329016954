/* @flow */

import type { Value } from "./context";

import React, { useState, useEffect, useRef, useCallback } from "react";
import cn from "classnames";
import { useClickOutside, childrenToText, focusedElement } from "../../helpers";
import useTypeahead, { KEY_ESC, KEY_UP, KEY_DOWN } from "./use-typeahead";
import DropdownContext from "./context";
import styles from "./styles.scss";

type Direction = "Down" | "Up";

type Item = {
  index: number,
  value: string | number,
  label: string,
};

type Props = {
  className?: string,
  label?: string,
  headerContent?: (Item) => React$Node,
  children: React$Node,
  onChange: (Item) => void,
  value: ?string,
  variant?: string,
  disabled?: boolean,
  maxHeight?: number,
  style?: Object,
};

const Chevron = ({ className }: { className: string }) => (
  <svg
    className={className}
    height="6.36377"
    viewBox="0 0 9.89941 6.36377"
    width="9.89941"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m4.95 6.364-4.95-4.95 1.414-1.414 3.536 3.536 3.535-3.536 1.414 1.414z"
      fill="currentColor"
    />
  </svg>
);

const Dropdown = ({
  className,
  label,
  headerContent,
  variant,
  disabled,
  ...props
}: Props): React$Node => {
  const element = useRef(null);
  const [open, setOpen] = useState(false);
  const [visible, setVisbile] = useState(false);
  const [direction, setDirection] = useState<Direction>("Down");
  const children = Array.isArray(props.children) ? props.children : [];
  const items = children.map((x, i) => ({
    index: i,
    value: x.props.value,
    label: childrenToText(x.props.children),
  }));

  useEffect(() => {
    if (disabled === true) {
      closeDropdown();
    }
  }, [disabled]);

  const closeDropdown = () => {
    setOpen(false);
    setVisbile(false);
  };

  const openDropdown = () => {
    if (disabled === true) {
      return;
    }

    setVisbile(false);
    setOpen(true);
  };

  // Calc height after opened
  useEffect(() => {
    const calcDirection = (element: Element) => {
      const main = element.querySelector("main");

      if (!main || !document.documentElement) {
        return;
      }

      const screenHeight = document.documentElement
        ? document.documentElement.clientHeight
        : 0;
      const dropdownRect = element.getBoundingClientRect();
      const menuHeight = main.clientHeight;
      const spaceAtTheBottom =
        screenHeight - dropdownRect.top - dropdownRect.height - menuHeight;
      const spaceAtTheTop = dropdownRect.top - menuHeight;

      const upward = spaceAtTheBottom < 0 && spaceAtTheTop > spaceAtTheBottom;

      // Set state only if there's a relevant difference
      if (upward && direction !== "Up") {
        setDirection("Up");
      }

      if (!upward && direction !== "Down") {
        setDirection("Down");
      }
    };

    if (element.current && open) {
      calcDirection(element.current);
      setVisbile(true);
    }
  }, [open, direction]);

  const focusAtIndex = useCallback(
    (index: ?number) => {
      const item = items.find((x) => x.index === index);

      if (index === null && document.activeElement) {
        document.activeElement.blur();
      }

      if (element.current && item && item.value) {
        const itemElement = element.current.querySelector(
          `[data-value="${item.value}"]`
        );
        if (itemElement) {
          itemElement.focus();
        }
      }
    },
    [element, items]
  );

  const [typeaheadValue, setTypeaheadValue] = useState("");
  useTypeahead(element.current, typeaheadValue, (str) => {
    setTypeaheadValue(str);

    if (!str) {
      focusAtIndex(null);
      return;
    }

    const foundItem =
      items.filter((item) => {
        return item.label.slice(0, str.length) === str.toLowerCase();
      })[0] || items[0];

    if (foundItem) {
      focusAtIndex(foundItem.index);
    }
  });

  useClickOutside(element, () => closeDropdown());

  const onSelect = (id) => {
    const item = items.find((x) => x.value === id);

    if (item) {
      props.onChange(item);
    }

    closeDropdown();
  };

  useEffect(() => {
    const { current } = element;

    const focusPrev = (element: ?Element) => {
      if (!element) {
        return;
      }

      const index = parseInt(element.getAttribute("data-index"), 10);
      focusAtIndex(Number.isNaN(index) ? 0 : index - 1);
    };

    const focusNext = (element: ?Element) => {
      if (!element) {
        return;
      }

      const index = parseInt(element.getAttribute("data-index"), 10);
      focusAtIndex(Number.isNaN(index) ? 0 : index + 1);
    };

    if (current) {
      const onKeyDown = (e: KeyboardEvent) => {
        switch (e.which) {
          case KEY_ESC:
            return closeDropdown();
          case KEY_UP:
            return focusPrev(focusedElement(current));
          case KEY_DOWN:
            return focusNext(focusedElement(current));
          default:
        }
      };

      current.addEventListener("keydown", onKeyDown);

      return () => current.removeEventListener("keydown", onKeyDown);
    }
  }, [element, focusAtIndex]);

  const selectedChild =
    Array.isArray(props.children) &&
    props.children.find((x) => x.props.value === props.value);
  const selectedInner = selectedChild && selectedChild.props.children;
  const buttonText = selectedInner ? childrenToText(selectedInner) : label;

  const renderHeadContent = () => {
    // Return default label if no value
    if (props.value === undefined || (props.value === null && label)) {
      return label;
    }

    if (typeof headerContent === "function") {
      const item = items.find((x) => x.value === props.value);

      if (item) {
        return headerContent(item);
      }
    }

    if (headerContent !== undefined) {
      return headerContent;
    }

    const activeChild = Array.isArray(props.children)
      ? props.children.find((x) => x.value === props.value)
      : null;

    if (activeChild?.props.children) {
      return activeChild.props.children;
    }

    return props.value;
  };

  return (
    <div
      ref={element}
      data-open={open ? "true" : "false"}
      className={cn(
        styles.block,
        className,
        { [styles.blockOpen]: open },
        { [styles.hasValue]: selectedInner },
        [styles[`block${direction}`]],
        { [styles.hasVariant]: Boolean(variant) },
        { [styles[variant]]: Boolean(variant) }
      )}
      style={props.style}
    >
      <button
        disabled={disabled === true}
        type="button"
        aria-label={buttonText}
        className={styles.head}
        onClick={() => {
          if (open) {
            closeDropdown();
          } else {
            openDropdown();
          }
        }}
      >
        <label className={styles.label}>{label}</label>

        <div className={styles.headInner}>{renderHeadContent()}</div>
        <div className={styles.chevronArea}>
          <Chevron className={styles.chevron} />
        </div>
      </button>
      {open && (
        <main
          className={styles.body}
          style={{
            visibility: visible ? "visible" : "hidden",
            maxHeight:
              props.maxHeight !== undefined ? `${props.maxHeight}px` : "auto",
          }}
        >
          <DropdownContext.Provider
            value={{ onSelect, value: props.value, styles, items }}
          >
            {props.children}
          </DropdownContext.Provider>
        </main>
      )}
    </div>
  );
};

export default Dropdown;

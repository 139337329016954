/* @flow */

import type { Fetch } from "@out-of-home/fetch-utils";

import type { Storage } from "crustate";
import type { BlogRequest, BlogResponse } from "../state/blog";
import { BLOG_LOAD_REQUEST, BLOG_LOAD_RESPONSE } from "../state/blog";
import { restProductToGQLProduct } from "../helpers";

export const PAGE_SIZE = 32;

const registerClient = (storage: Storage, restClient: Fetch) => {
  storage.addEffect({
    effect: async (msg: BlogRequest) => {
      const response = await restClient(`/api/blog/${msg.slug}`);

      if (response.status === 404) {
        return ({
          tag: BLOG_LOAD_RESPONSE,
          error: "not found",
        }: BlogResponse);
      }

      const data = await response.json();

      data.tags = data.tags || [];

      return ({
        tag: BLOG_LOAD_RESPONSE,
        data,
      }: BlogResponse);
    },
    subscribe: { [BLOG_LOAD_REQUEST]: true },
  });
};

export default registerClient;

/* @flow */

import React from "react";
import { Link } from "react-router-dom";
import { useTranslate } from "@out-of-home/use-translate";
import cn from "classnames";
import styles from "./styles.scss";
import ArrowIcon from "../../icons/arrow.svg";

type Props = {
  title: string,
  url?: string | null,
  resetFilter?: () => void,
};

export const HeadingRow = ({ title, url, resetFilter }: Props): React$Node => {
  const t = useTranslate();

  return (
    <div className={styles.header}>
      <h2 className={cn("h3", styles.title)}>{title}</h2>
      {url && (
        <Link
          onClick={resetFilter && resetFilter}
          className={cn(styles.link, "no-underline")}
          to={url}
        >
          {t("OTHER.DISPLAY_ALL")} <ArrowIcon className={styles.icon} />
        </Link>
      )}
    </div>
  );
};

/* @flow */

import React, { useState, useEffect, useRef } from "react";
import { useTranslate } from "@out-of-home/use-translate";
import cn from "classnames";
import addSeconds from "date-fns/addSeconds";
import addDays from "date-fns/addDays";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import { zeroPrefix } from "../../helpers/deliveryCalendarHelpers";
import styles from "./styles.scss";
import { addMilliseconds } from "date-fns/esm";

type Props = {
  className?: string,
};

const getNextDelivery = (currentTime: Date): Date => {
  let nextDelivery = new Date(currentTime);

  if (currentTime.getHours() >= 12) {
    nextDelivery = addDays(nextDelivery, 1);
  }

  // if week day
  while (nextDelivery.getDay() < 1 || nextDelivery.getDay() > 5) {
    nextDelivery = addDays(nextDelivery, 1);
  }

  nextDelivery.setHours(12);
  nextDelivery.setMinutes(0);
  nextDelivery.setSeconds(0);

  return nextDelivery;
};

export const Counter = ({ className }: Props): React$Node => {
  const t = useTranslate();
  const [time, setTime] = useState(new Date());
  const [nextDelivery, setNextDelivery] = useState(getNextDelivery(time));

  const ms = differenceInMilliseconds(nextDelivery, time);
  const seconds = parseInt((ms / 1000) % 60, 10);
  const minutes = parseInt((ms / (1000 * 60)) % 60, 10);
  const hours = parseInt(ms / (1000 * 60 * 60), 10);

  // Shift to next delivery date when passing the old one
  useEffect(() => {
    if (time >= nextDelivery) {
      setNextDelivery(getNextDelivery(time));
    }
  }, [time]);

  // Increase current time every second
  useEffect(() => {
    const id = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <div className={cn(styles.block, className)}>
      <p>
        <span className={styles.label}>{t("CART.NEXT_DELIVERY")}</span>
        <span className={styles.time}>
          {zeroPrefix(hours)}:{zeroPrefix(minutes)}:{zeroPrefix(seconds)}
        </span>
      </p>
    </div>
  );
};

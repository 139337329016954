/* @flow */

import type { TFilterQuery, TInfoBlock } from "../types/filter.flow";

import React from "react";
import { outputSalesUnit } from "./productHelper";
import { stripHTML } from "./";
import config from "../config.js";
import type {
  ProductAbstract,
  ProductDetailAbstract,
} from "../types/product.flow";
import type { TBlogPost } from "../types/blog.flow";

let decoder;

function decodeInBrowser(html: string): string {
  decoder = decoder || document.createElement("div");
  // Escape HTML before decoding for HTML Entities
  html = escape(html)
    .replace(/%26/g, "&")
    .replace(/%23/g, "#")
    .replace(/%3B/g, ";");
  // decoding
  decoder.innerHTML = html;

  return unescape(decoder.textContent);
}

let decode;

if (process.browser) {
  decode = decodeInBrowser;
} else {
  decode = require("he").decode;
}

type Meta = {
  title: string,
  description?: string,
  canonicals?: Array<{
    rel: string,
    href: string,
  }>,
  og?: {
    title: string,
    description: string,
    type?: string,
    url?: string,
    image?: string,
  },
  alternates?: Array<{
    url: string,
    lang: string,
  }>,
  twitter?: {},
  product?: {},
  price?: {},
  prefix?: {},
};

export type MetaResponse = {
  title: string,
  link: Array<{
    rel: string,
    href: string,
  }>,
  data: Array<{ [key: string]: ?string }>,
};

function ID(x) {
  return x;
}

function formatMeta({
  title,
  description,
  canonicals,
  alternates,
  og = {},
  twitter = {},
  product = {},
  price = {},
  prefix,
}: Meta): MetaResponse {
  const meta = {
    title: title,
    data: [],
    link: [],
  };
  const openGraph = {
    title,
    description,
    ...og,
  };
  Object.keys(openGraph)
    .filter((k) => openGraph[k])
    .forEach((k) => {
      meta.data.push({ property: `og:${k}`, content: openGraph[k] });
    });
  Object.keys(twitter)
    .filter((k) => twitter[k])
    .forEach((k) => {
      meta.data.push({ property: `twitter:${k}`, content: twitter[k] });
    });
  Object.keys(product)
    .filter((k) => product[k])
    .forEach((k) => {
      meta.data.push({ property: `product:${k}`, content: product[k] });
    });
  Object.keys(price)
    .filter((k) => price[k])
    .forEach((k) => {
      meta.data.push({ property: `product:price:${k}`, content: price[k] });
    });

  if (prefix) {
    Object.keys(prefix)
      .filter((k) => prefix[k])
      .forEach((k) => {
        meta.data.push({
          prefix: `og: http://ogp.me/ns# fb: http://ogp.me/ns/fb# ${k}: http://ogp.me/ns/${k}#`,
        });
      });
  }

  if (description) {
    meta.data.push({ name: "description", content: description });
  }

  if (alternates) {
    alternates.forEach((a) => {
      meta.link.push({
        rel: "alternate",
        hreflang: a.lang,
        href: config.altDomains[a.lang] + a.url,
      });
    });
  }

  if (canonicals) {
    canonicals.forEach((a) => {
      meta.link.push({
        rel: "canonical",
        href: a.href,
      });
    });
  }

  return meta;
}

export function getFrontPageMeta(
  title: string,
  description: string
): MetaResponse {
  return formatMeta({
    title: title,
    description: description,
    canonicals: [{ rel: "canonical", href: "/" }],
    og: {
      title: title,
      description: description,
      image: "/assets/images/ooh_ring.png",
    },
  });
}

export function getProductMeta(
  product: ProductDetailAbstract,
  t: (string) => string
): MetaResponse {
  if (!product) {
    return {};
  }

  const {
    metaDescription,
    shortDescription,
    supplierSku,
    brand,
    largeImage,
    smallImage,
  } = product.attributes;

  const isAvailable = product.oohStock && product.buyRequest;
  const image = largeImage ? largeImage.x1 : smallImage?.x1 || "";
  const url = `${config.proxyHost}/${product.url}`; // No relative urls
  const priceAmount = product.price;
  const priceCurrency = t("LOCALE.CURRENCY");
  const availability = isAvailable ? "in stock" : "out of stock";
  const description = decode(metaDescription || shortDescription || "").replace(
    /<\/?[^>]+(>|$)/g,
    ""
  );
  const title =
    product.attributes.metaTitle ||
    [
      product.name,
      outputSalesUnit(product.attributes),
      [product.attributes.brand, supplierSku ? "#" + supplierSku : null]
        .filter(ID)
        .join(" "),
    ]
      .filter(ID)
      .join(", ");

  return formatMeta({
    title: title,
    description: description,
    prefix: { product: "product" },
    og: {
      type: "product",
      title: title,
      url: url,
      description: description,
      image: image,
    },
    twitter: {
      card: "product",
      title: title,
      description: description,
      image: image,
    },
    product: {
      retailer_item_id: supplierSku,
      brand: brand,
      availability: availability,
    },
    price: {
      amount: priceAmount,
      currency: priceCurrency,
    },
  });
}

export function getBlogPostMeta(post: TBlogPost): MetaResponse {
  if (!post) {
    return {};
  }

  return formatMeta({
    title: post.metaTitle,
    description:
      post.metaDescription ||
      (post.shortContent.length ? post.shortContent[0] : ""),
    og: {
      title: post.metaTitle,
      description: post.metaDescription,
      image: post.listThumbnail,
    },
    twitter: {
      card: "summary",
      title: post.metaTitle,
      description: post.metaDescription,
      image: post.listThumbnail,
    },
  });
}

export function getFilterMeta(
  heading: string,
  subHeading: string,
  query: TFilterQuery,
  infoBlock: ?TInfoBlock,
  defaultDescription: string,
  canonicalUrl: { pathname: string, search: string }
): MetaResponse {
  const title = infoBlock?.title || heading + " " + subHeading;
  const description =
    (infoBlock &&
      (decode(infoBlock.metaDescription || "") ||
        stripHTML(decode(infoBlock.description || "")).substr(0, 160))) ||
    decode(defaultDescription || "");

  return formatMeta({
    title: title,
    description: description,
    canonicals: [
      {
        rel: "canonical",
        href: `${canonicalUrl.pathname}${canonicalUrl.search}`,
      },
    ],
    og: {
      title: title,
      description: description,
      image: infoBlock?.images.large?.url || "",
    },
  });
}

export function getCmsMeta(page?: {
  title: string,
  metaTitle: string,
  metaDescription: string,
  urlKey: string,
}): MetaResponse {
  if (!page) {
    return {};
  }

  const title = page.metaTitle || page.title,
    description = page.metaDescription;

  return formatMeta({
    title: title,
    description: description,
    canonicals: [{ rel: "canonical", href: `${page.url}` }],
    og: {
      title: title,
      description: description,
    },
  });
}

export const metaToHelmetProps = (t: Function, tag: string, x: any): any => (
  <React.Fragment>
    {x.map((x) => React.createElement(tag, { ...x, key: tag + "__" + x }))}
  </React.Fragment>
);

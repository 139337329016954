// @flow

import type { Model, EffectErrorMessage } from "crustate";
import type {
  FilterLocation as Location,
  ProductFilterInput,
  ProductFilterInputPrice,
  ProductSortInput,
  SortableProductList,
} from "../types/filter.flow.js";

import { getInputFilters, getInputSort, getPage } from "./filter";

import { updateData, EFFECT_ERROR } from "crustate";

type Data =
  | { state: "INIT" }
  | { state: "LOADING", brand: string }
  | { state: "UPDATING", brand: string, data: SortableProductList }
  | { state: "LOADED", brand: string, data: SortableProductList };

export type BrandResponse =
  | { tag: typeof BRAND_RESPONSE, data: SortableProductList }
  | EffectErrorMessage;

export type BrandRequest = {
  tag: typeof BRAND_REQUEST,
  filters: Array<ProductFilterInput | ProductFilterInputPrice>,
  sort: ?ProductSortInput,
  page: ?number,
};

export const BRAND_RESPONSE: "response/brand" = "response/brand";
export const BRAND_REQUEST: "request/brand" = "request/brand";

export const load = (
  brand: string,
  location: Location,
  incVat?: boolean,
  pointsToPrice?: (number) => number
): BrandRequest => {
  const brandFilter = { code: "manufacturer", value: brand };
  const filters = getInputFilters(location, incVat, pointsToPrice);
  filters.push(brandFilter);

  const sort = getInputSort(location);
  const page = getPage(location);

  return {
    tag: BRAND_REQUEST,
    filters,
    page,
    sort,
  };
};

export const BrandModel: Model<
  Data,
  {
    brand: string,
    location: Location,
    incVat?: boolean,
  },
  BrandRequest | BrandResponse
> = {
  id: "brand",
  init: ({ brand, location, incVat }) =>
    updateData({ state: "LOADING", brand }, load(brand, location, incVat)),
  update: (state: Data, msg) => {
    switch (msg.tag) {
      case EFFECT_ERROR:
        throw new Error("Route load failed");
      case BRAND_RESPONSE:
        if (
          msg.data &&
          (state.state === "LOADING" || state.state === "UPDATING")
        ) {
          return updateData({
            state: "LOADED",
            brand: state.brand,
            data: msg.data,
          });
        }

        throw new Error("Failed to fetch search results");

      case BRAND_REQUEST:
        if (state.state === "LOADED") {
          return updateData(
            { state: "UPDATING", brand: state.brand, data: state.data },
            msg
          );
        }

        break;

      default:
    }
  },
};

/* @flow */

import React from "react";
import { Helmet } from "react-helmet-async";
import styles from "./styles.scss";
import { Wrapper } from "../Wrapper";
import { Button } from "../Button";
import { useTranslate } from "@out-of-home/use-translate";

type Props = {
  children: React$Node,
};

export const Nag = ({ children }: Props): React$Node => {
  const t = useTranslate();
  return (
    <section className={styles.block}>
      <Wrapper variant="thin">
        <p>{t("NAG.TEXT")}</p>
        <div className={styles.buttons}>
          {children}
          <p className={styles.registerPayoff} dangerouslySetInnerHTML={{ __html: t("NAG.PAYOFF") }} />
        </div>
      </Wrapper>
    </section>
  );
};

/* @flow */

import type { CustomerOrderPartial } from "../../../types/order.flow";

import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useTranslate } from "@out-of-home/use-translate";
import { useFormat } from "../../../helpers/use-format";
import {
  orderShippingStatus,
  orderInvoiceStatus,
  mergeStatus,
} from "../../../helpers/orderHelpers";

import styles from "./styles.scss";

import OKIcon from "../../../icons/checkmark.svg";
import DownloadIcon from "../../../icons/download.svg";
import ExclamationIcon from "../../../icons/exclamation.svg";
import ShippingIcon from "../../../icons/shipping.svg";
import PackedIcon from "../../../icons/list.svg";
import ArrivedIcon from "../../../icons/arrow.svg";
import LoadingIcon from "../../../icons/loading.svg";

type Props = {
  order: CustomerOrderPartial,
  gotOverdueInvoice: boolean,
};

const renderShipmentStatus = (
  { shipmentStatus, addimotion, order: { id } },
  t
) => {
  /*
  if (addimotion[id] === ADDI_LOADING) {
    return <div className={cn(styles.orderColumn, styles.shipment)}>
      <span className={cn(styles.iconWrapper, styles.iconWrapper__warning)}><LoadingIcon className={styles.icon} /></span>
      <span className={styles.statusText}>{t("ADDIMOTION.LOADING")}</span>
    </div>
  }

  const addimotionBox = addimotion[id] && addimotion[id].YourBoxes ? addimotion[id].YourBoxes[0] : null;
  if (addimotionBox) {
    {/* Valid statuses: Packed, En route, Arrived, Delivered */ /*}
    return <div className={cn(styles.orderColumn, styles.shipment)}>
      {addimotionBox.SStatusTxt === "Delivered" && <span className={cn(styles.iconWrapper, styles.iconWrapper__success)}><OKIcon className={styles.icon} /></span>}
      {addimotionBox.SStatusTxt === "Arrived" && <span className={cn(styles.iconWrapper, styles.iconWrapper__success)}><ArrivedIcon className={styles.icon} /></span>}
      {addimotionBox.SStatusTxt === "En route" && <span className={cn(styles.iconWrapper, styles.iconWrapper__success)}><ShippingIcon className={styles.icon} /></span>}
      {addimotionBox.SStatusTxt === "Packed" && <span className={cn(styles.iconWrapper, styles.iconWrapper__success)}><PackedIcon className={styles.icon} /></span>}
      {["Delivered", "Arrived", "En route", "Packed"].includes(addimotionBox.SStatusTxt) || <span className={cn(styles.iconWrapper, styles.iconWrapper__error)}><ExclamationIcon className={styles.icon} /></span>}

      <span className={styles.statusText}>{t("ADDIMOTION." + addimotionBox.SStatusTxt)}</span>
    </div>;
  }
  */

  if (shipmentStatus) {
    return (
      <div className={cn(styles.orderColumn, styles.shipment)}>
        {["DELIVERED", "PICKED", "SHIPPED"].indexOf(shipmentStatus) !== -1 && (
          <span className={cn(styles.iconWrapper, styles.iconWrapper__success)}>
            <OKIcon className={styles.icon} />
          </span>
        )}
        {["DELAYED", "CANCELED", "BLOCKED"].indexOf(shipmentStatus) !== -1 && (
          <span className={cn(styles.iconWrapper, styles.iconWrapper__error)}>
            <ExclamationIcon className={styles.icon} />
          </span>
        )}
        {[
          "DELAYED",
          "PICKED",
          "DELIVERED",
          "SHIPPED",
          "CANCELED",
          "BLOCKED",
        ].indexOf(shipmentStatus) === -1 && (
          <span className={cn(styles.iconWrapper, styles.iconWrapper__warning)}>
            <ExclamationIcon className={styles.icon} />
          </span>
        )}

        <span>{t("SHIPMENT." + shipmentStatus)}</span>
      </div>
    );
  }

  return <div className={cn(styles.orderColumn, styles.shipment)}>&mdash;</div>;
};

export const OrderHistoryItem = ({
  order,
  gotOverdueInvoice,
}: Props): React$Node => {
  const t = useTranslate();
  const { formatDateLong, formatPrice } = useFormat();
  const { vismaInvoiceId } = order;

  const invoiceStatus = orderInvoiceStatus(order);
  const shipmentStatus = orderShippingStatus(order, gotOverdueInvoice);

  const addimotion = null;

  return (
    <Link
      to={`/orderhistorik/${order.id}`}
      className={cn(
        styles.order,
        styles[
          "order__mergestatus_" + mergeStatus(invoiceStatus, shipmentStatus)
        ],
        styles["order__invoice_" + (invoiceStatus || "")],
        styles["order__shipment_" + (shipmentStatus || "")]
      )}
    >
      <div className={cn(styles.orderColumn, styles.number)}>
        <span>{order.id}</span>
      </div>
      <div className={cn(styles.orderColumn, styles.date)}>
        <span>{formatDateLong(new Date(order.createdAt))}</span>
      </div>
      <div className={cn(styles.orderColumn, styles.total)}>
        <span>{formatPrice(order.grandTotal.exVat)}</span>
      </div>

      {renderShipmentStatus({ shipmentStatus, addimotion, order }, t)}

      {invoiceStatus ? (
        <div className={cn(styles.orderColumn, styles.invoice)}>
          {invoiceStatus === "PAID" && (
            <span
              className={cn(styles.iconWrapper, styles.iconWrapper__success)}
            >
              <OKIcon className={styles.icon} />
            </span>
          )}
          {invoiceStatus === "INVOICED" && (
            <span
              className={cn(styles.iconWrapper, styles.iconWrapper__warning)}
            >
              <ExclamationIcon className={styles.icon} />
            </span>
          )}
          {(invoiceStatus === "OVERDUE" || invoiceStatus === "CANCELED") && (
            <span className={cn(styles.iconWrapper, styles.iconWrapper__error)}>
              <ExclamationIcon className={styles.icon} />
            </span>
          )}

          <div>
            <span>
              {order.payment.code === "Crossroads_Collector" &&
              invoiceStatus === "PAID"
                ? t("VISMA.PAID_WITH_COLLECTOR")
                : t("VISMA." + invoiceStatus)}
            </span>
            {vismaInvoiceId &&
              (order.payment.code === "CrossInvoice" || order.payment.code === "Crossroads_Stripe_PaymentIntents") && (
                <a
                  className={cn("link", styles.invoiceDownload)}
                  href={`/api/vismainvoice/getpdf?visma_invoice_id=${vismaInvoiceId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  <DownloadIcon className={styles.icon} />
                  {order.payment.code === "Crossroads_Stripe_PaymentIntents"
                    ? t("ORDER.DOWNLOAD_STRIPE")
                    : t("ORDER.DOWNLOAD")}
                </a>
              )}
          </div>
        </div>
      ) : (
        <div className={cn(styles.orderColumn, styles.invoice)}>&mdash;</div>
      )}

      <div className={cn(styles.orderColumn, styles.open)}>
        <span className={styles.link} to={`/orderhistorik/${order.id}`}>
          {t("OTHER.OPEN")}
        </span>
      </div>
    </Link>
  );
};

export const OrderHistoryItemMock = (): React$Node => (
  <div className={styles.order} />
);

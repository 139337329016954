/* @flow */

import type { TBlogPost } from "../types/blog.flow";

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

type Data =
  | { state: "LOADING" }
  | { state: "NOT_FOUND" }
  | { state: "LOADED", data: TBlogPost };

export type BlogResponse = Response<typeof BLOG_LOAD_RESPONSE, TBlogPost>;
export type BlogRequest = { tag: typeof BLOG_LOAD_REQUEST, slug: string };

export const BLOG_LOAD_RESPONSE: "blog/load/response" = "blog/load/response";
export const BLOG_LOAD_REQUEST: "blog/load/request" = "blog/load/request";

export const BlogModel: Model<
  Data,
  { slug: string },
  BlogRequest | BlogResponse
> = {
  id: "blog",
  init: ({ slug }: { slug: string }) =>
    updateData({ state: "LOADING" }, { tag: BLOG_LOAD_REQUEST, slug }),
  update: (state: Data, msg) => {
    switch (msg.tag) {
      case BLOG_LOAD_RESPONSE:
        if (msg.error) {
          return updateData({ state: "NOT_FOUND" });
        }

        if (msg.data && state.state === "LOADING") {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;
      default:
    }
  },
};

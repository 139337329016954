/* @flow */

import type { ProductAbstract } from "../../types/product.flow";
import type { Node } from "react";

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AnalyticsContext } from "./context";

export type ProductLinkProps = {|
  className?: string,
  product: ProductAbstract,
  category?: string,
  brand?: string,
  listName?: string,
  position?: number,
  // Attributes passed to <Link />
  onClick?: (event: MouseEvent) => void,
  target?: string,
  replace?: boolean,
  to: string | { pathname: string, state: {}  },
  innerRef?: string | ((el: Element) => void),

  children: React$Node,
|};

/**
 * <Link /> replacement notifying google analytics about the clicked product,
 * accepts all of <Link />'s props
 * in addition to the ones listed on this class.
 */
export const ProductLink = (props: ProductLinkProps): Node => {
  const context = useContext(AnalyticsContext);
  // Exclude <ProductLink /> specific attributes
  const { product, category, listName, position, ...linkProps } = props;

  const onClickLink = (e: MouseEvent): void => {
    if (
      process.env.NODE_ENV !== "production" &&
      !context &&
      typeof window !== "undefined"
    ) {
      throw new Error(
        "<ProductLink /> must be wrapped in a <AnalyticsProvider />"
      );
    }

    context.registerListProductClicked({ ...product, qty: 1 }, listName, category, position);

    if (props.onClick !== undefined) {
      props.onClick(e);
    }
  };

  return (
    <Link {...linkProps} onClick={onClickLink}>
      {props.children}
    </Link>
  );
};

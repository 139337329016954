/* @flow */

import type {
  ProductCardProduct,
  ProductDisplayMode,
} from "../../types/product.flow";
import type { Pages } from "../../state/filter";

import React, { useContext, memo } from "react";
import styles from "./styles.scss";
import cn from "classnames";
import { useTranslate } from "@out-of-home/use-translate";
import { Link } from "react-router-dom";
import { UIContext } from "../../context/ui";
import { ProductCard } from "../ProductCard";
import { Button } from "../Button";

export type ProductListProps = {
  className?: string,
  variant?: string,
  listName?: string,
  products: Array<ProductCardProduct | null>,
  recommendedProducts?: Array<ProductCardProduct>,
  fullWidth?: boolean,
  inSplitView?: boolean,
  children?: React$Node,
  displayMode: ProductDisplayMode,
  totalCount?: number,
};

type PaginatedProductListProps = {
  className?: string,
  listName?: string,
  children?: React$Node,
  products: Array<ProductCardProduct | null>,
  fetchMore: ({ startIndex: number, stopIndex: number }) => void,
  totalCount: number,
  isFetching: boolean,
};

const breakpointSmall = parseInt(styles.small, 10);

const DummyProduct = () => (
  <div className={styles.item}>
    <div className={styles.dummyProduct} />
  </div>
);

/**
 * Generic product list.
 */
export const ProductList = ({
  children,
  listName,
  products = [],
  recommendedProducts = [],
  fullWidth = false,
  inSplitView = false,
  totalCount = 0,
  ...props
}: ProductListProps): React$Node => {
  const { displayMode, browserWidth } = useContext(UIContext);
  const isCompact = browserWidth > 0 && browserWidth < breakpointSmall;
  const showDummyCards = totalCount > 0 && products.length === 0;
  const usedDisplayMode = isCompact
    ? "COMPACT"
    : props.displayMode || displayMode;

  if (products.length > 0 || recommendedProducts.length > 0 || showDummyCards) {
    return (
      <div
        className={cn(
          {
            [styles["block"]]: true,
            [styles["block__fullWidth"]]: fullWidth,
            [styles[
              "block__" +
                (isCompact ? "COMPACT" : usedDisplayMode).toLowerCase()
            ]]: true,
            [styles["block__" + (props.variant || "")]]: props.variant,
          },
          props.className
        )}
      >
        {children}
        {products.map((product, i) => (
          <ProductListItem
            i={i}
            key={product ? product.sku : i}
            inSplitView={inSplitView}
            product={product}
            listName={listName}
            displayMode={usedDisplayMode}
          />
        ))}

        {recommendedProducts.map((product, i) => (
          <ProductListItem
            i={i}
            key={"recommended_" + product.sku}
            inSplitView={inSplitView}
            product={product}
            recommended={true}
            listName={listName}
            displayMode={usedDisplayMode}
          />
        ))}
        {showDummyCards && (
          <>
            {[0, 1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((_, i) => (
              <DummyProduct key={i} />
            ))}
          </>
        )}
      </div>
    );
  }

  return null;
};

/**
 * Generic product list.
 */
export const PaginatedProductList: React$AbstractComponent<
  PaginatedProductListProps,
  mixed
> = memo(
  ({
    children,
    listName,
    className,
    products,
    fetchMore,
    isFetching,
    totalCount,
  }: PaginatedProductListProps): React$Node => {
    const { displayMode, browserWidth } = useContext(UIContext);
    const isCompact = browserWidth > 0 && browserWidth < breakpointSmall;
    const usedDisplayMode = isCompact ? "COMPACT" : displayMode || displayMode;
    const t = useTranslate();

    if (totalCount === 0) {
      return (
        <div>
          <p>{t("FILTER.NO_PRODUCTS_FOUND")}</p>
          <Link to="/p">Tillbaka till alla produkter</Link>
        </div>
      );
    }

    return (
      <>
        <div className={className}>
          <ProductList
            hasMore={products.length < totalCount}
            products={products}
            listName={listName}
            displayMode={displayMode}
            isFetching={isFetching}
            totalCount={totalCount}
            DummyProduct={<DummyProduct />}
          />
        </div>
        {products.length > 0 && products.length < totalCount && (
          <div className={styles.fetchMore}>
            <center>
              <Button variant="ghost" loading={isFetching} onClick={fetchMore}>
                ladda fler
              </Button>
            </center>
          </div>
        )}
      </>
    );
  }
);

type ItemProps = {
  i: number,
  product?: ?ProductCardProduct,
  idPrefix?: string,
  listName?: string,
  displayMode: TDisplayMode,
  recommended?: boolean,
  inSplitView?: boolean,
  itemsPerRow?: number,
  idx?: number,
};

// Acts as a dummy product if product = null, requires useCompactView then
const ProductListItem = memo<ItemProps>(
  ({
    i,
    idx,
    idPrefix,
    listName,
    product,
    displayMode,
    recommended = false,
    itemsPerRow = 4,
    inSplitView = false,
  }): React$Node => {
    return (
      <div
        data-idx={idx}
        id={"product-list-item-" + i}
        className={cn(styles.item, {
          [styles["item__in_splitview"]]: inSplitView && displayMode === "LIST",
        })}
      >
        {product ? (
          <ProductCard
            product={product}
            position={i}
            listName={listName}
            displayMode={displayMode}
            recommended={recommended}
          />
        ) : (
          <div
            className={cn(
              styles.dummyProduct,
              styles["dummyProduct__" + displayMode.toLowerCase()]
            )}
          />
        )}
      </div>
    );
  }
);

/* @flow */

import type { FrontPageBlock as FrontPageBlockType } from "../../types/my-front-page.flow";

import React, { useContext } from "react";
import cn from "classnames";
import { FrontPageBlock } from "../FrontPageBlock";
import { blocksToUIRows } from "../../helpers/myfrontpageHelpers";
import breakpoints from "../../helpers/breakpoints";
import { restProductToGQLProduct } from "../../helpers";
import { UIContext } from "../../context/ui";
import styles from "./styles.scss";
import { Excerpt } from "../Excerpt";

type Props = {
  rows: Array<FrontPageBlockType | Array<FrontPageBlockType>>,
  loading?: boolean,
  listName: string,
};

type SplitProps = {
  block: Array<FrontPageBlockType>,
  allowSplit: boolean,
  listName: string,
};

const Split = ({ block, allowSplit, listName }: SplitProps) => {
  if (!allowSplit) {
    return block.map((j, i) => (
      <div key={i} className={cn("row--large", styles.row)}>
        <FrontPageBlock block={j} fullWidth={true} listName={listName} />
      </div>
    ));
  }

  return (
    <div className={cn("row--large", styles.row)}>
      {block.map((j, i) => (
        <FrontPageBlock key={i} inSplitView={true} block={j} listName={listName} />
      ))}
    </div>
  );
};

export const FrontPageList = ({ rows, loading, listName }: Props): React$Node => {
  const { browserWidth, displayMode } = useContext(UIContext);
  const allowSplit = browserWidth >= breakpoints.large;

  // Show skeleton view if loading
  if (loading === true) {
    return (
      <div>
        {[1, 2, 3].map((x) => (
          <div key={x} className={cn("row--large", styles.row)}>
            <div
              className={cn(
                styles.block,
                styles.block__4,
                styles.block__fullWidth
              )}
            >
              <Excerpt
                products={[null, null, null, null]}
                productDisplayMode={displayMode}
                title="&nbsp;"
                evenRows={true}
                perRow={4 /* sidebar is taking up one column */}
                productListProps={{
                  fullWidth: true,
                  perRow: 5,
                  listName,
                }}
              />
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>
      {rows.map((x, i) =>
        Array.isArray(x) ? (
          <Split key={`r_${i}`} block={x} allowSplit={allowSplit} listName={listName} />
        ) : (
          <div key={`r_${i}`} className={cn("row--large", styles.row)}>
            <FrontPageBlock block={x} fullWidth={true} key={i} listName={listName} />
          </div>
        )
      )}
    </div>
  );
};

/* @flow */

import type {
  RestAPIProductCard,
  ProductCardProduct,
} from "../types/product.flow";
import type { CustomerAddress } from "../types/customer.flow";
import type { Quote, QuoteAddress } from "../types/quote.flow";
import { useCallback } from "react";

type MQDirection = "lt" | "gt";

import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { throttle } from "@out-of-home/diskho";

export const stripHTML = (str: string): string =>
  str
    .replace(/<[^>]*>/g, "")
    .replace(/\s{2,}/g, " ")
    .trim();

export const clientHtmlDecode = (html: string): string => {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

export const range = (from: number, to: number): Array<number> => {
  return Array.from({ length: to - from + 1 }, (_, i) => i + from);
};

let counter = 0;

export const getNextInteger = (): number => {
  const next = counter;

  counter = (counter + 1) | 0;

  return next;
};

type Ref = { current: null | React$ElementRef<any> };

export const useClickOutside = (ref: Ref, callback: Event => void): void => {
  const onClick = useCallback(
    (e: MouseEvent) => {
      if (ref && ref.current && !ref.current.contains(e.target) && typeof callback === "function") {
        callback(e);
      }
    },
    [callback],
  );

  useEffect(() => {
    if (window) {
      window.addEventListener("click", onClick);
    }

    return () => {
      if (window && onClick) {
        window.removeEventListener("click", onClick);
      }
    };
  }, [onClick]);
};


export const childrenToText = (children?: React$Node): string => {
  const childArray = React.Children.toArray(children);

  return childArray.filter((x) => typeof x === "string").join(" ");
};

export const focusedElement = (element: HTMLElement): ?HTMLElement => {
  return element.querySelector(":focus");
};

export const useBrowserLayoutEffect: typeof useLayoutEffect = process.browser
  ? useLayoutEffect
  : () => {};

export const useDevice = (
  direction: MQDirection,
  breakpoint: number
): boolean => {
  const [width, setWidth] = useState(
    process.browser ? window.innerWidth : 1200
  );

  useEffect(() => {
    if (process.browser) {
      const handleResize = throttle(() => setWidth(window.innerWidth), 1000);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [width]);

  return direction === "gt" ? width > breakpoint : width < breakpoint;
};

export const usePrevious = function<T>(value: T): (T | void) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const restProductToGQLProduct = (
  p: RestAPIProductCard
): ProductCardProduct => {
  // @TODO: use product type
  const type = "simple";

  return {
    name: p.name,
    sku: p.sku,
    type: type,
    url: p.urlKey[0] === "/" ? p.urlKey.slice(1) : p.urlKey,
    buyRequest: p.gqlBuyRequest,
    oohContracted: p.oohContracted,
    stockBackorders: p.stockBackorders,
    oohUnitprice: p.oohUnitprice,
    oohUnit: p.oohUnit,
    estimatedDeliveryDate: p.estimatedDeliveryDate,
    attributeLabels: p.attributeLabels,
    oohStock:
      (typeof p.stockQty === "number" && p.stockQty > 0) ||
      p.stockBackorders === true
        ? {
            qty: p.stockQty || 0,
            backorders: p.stockBackorders,
          }
        : null,
    originalPrice: {
      exVat: p.originalPrice,
      vat: 0,
    },
    price: {
      exVat: p.price,
      vat: 0,
    },
    // @TODO: implement wishlist in some way
    wishlist: {
      selected: false,
      addedAt: "",
      itemId: "",
    },
    attributes: {
      largeImage: {
        x1: p.smallImage,
        x2: p.smallImage,
      },
      cheeseType: p.attributes.cheese_type,
      featuredImage: p.oohFeaturedImage || p.attributes.image || p.smallImage,
      foodora: p.attributes.foodora === "1",
      supplierSku: p.attributes.supplier_sku,
      msrp: p.msrp,
      description: p.shortDescription,
      shortDescription: p.shortDescription,
      tinyImage: { x1: p.thumbImage, x2: p.thumbImage },
      smallImage: { x1: p.smallImage, x2: p.smallImage },
      manufacturer: p.attributes.manufacturer,
      brand: p.attributes.brand,
      stockQty: p.stockQty,
      certified: p.attributes.certified,
      oohMeasureName: p.attributes.ooh_measure_name,
      oohMeasureValue: p.attributes.ooh_measure_value,
      oohMeasureComparison: p.attributes.ooh_measure_comparison,
      ooh3AlcoholRequirement: p.attributes.ooh3_alcohol_requirement === "1",
      oohQuantity: p.attributes.ooh_quantity,
      backorderByVendor: p.attributes.backorder_by_vendor === "1",
      temperature: p.attributes.temperature,
      textCampaign: p.attributes.text_campaign,
      mainCategory: p.attributes.main_category,
      oohProductType: p.attributes.ooh_product_type,
    },
    customOptions: [],
  };
};

export const useCurrentSrc = (
  ref: { current: ?HTMLImageElement },
  defaultSrc: string
): string => {
  const [image, setImage] = useState(defaultSrc);

  useEffect(() => {
    const { current } = ref;

    if (current && current.currentSrc) {
      setImage(current.currentSrc);
    }
  }, [ref]);

  return image;
};

export const getScrollTop = (): number =>
  window.pageYOffset ||
  (document.documentElement && document.documentElement.scrollTop) ||
  0;

export const customerAddressToQuoteAddress = ({
  id,
  ...c
}: CustomerAddress): QuoteAddress => ({
  ...c,
  type: "billing",
  isUsedAsShipping: true,
});

export const debounce = <T, F: () => Promise<T> | T>(
  f: F,
  timeout: number = 50
): (() => Promise<T>) => {
  let t = null;
  let p = null;
  let res = null;
  let err = null;

  return function () {
    let args = arguments;
    let ctx = this;

    if (!p) {
      p = new Promise((resolve, reject) => {
        res = resolve;
        err = reject;
      });
    }

    clearTimeout(t);

    t = setTimeout(() => {
      const resolve = res;
      const reject = err;

      t = null;
      p = null;
      res = null;
      err = null;

      // TODO: Asserts på resolve/reject, att de inte är null

      try {
        resolve(f.apply(ctx, args));
      } catch (e) {
        reject(e);
      }
    }, timeout);

    return p;
  };
};

export const useEffectOnChange = (effect: Function, deps?: Array<any>) => {
  const initialRender = useRef(true);

  useEffect(() => {
    let effectReturns: void | (() => void) = () => {};

    if (initialRender.current) {
      initialRender.current = false;
    } else {
      effectReturns = effect();
    }

    if (effectReturns && typeof effectReturns === "function") {
      return effectReturns;
    }
  }, deps);
};

export const useOnShippingChange = (
  cb: (Quote, Quote) => void,
  quote: ?Quote
) => {
  const prevQuote = usePrevious(quote);

  useEffect(() => {
    if (quote && prevQuote) {
      cb(prevQuote, quote);
    }
  }, [quote?.shipping]);
};

export const shuffle = <T>(array: Array<T>): Array<T> => {
  const shuffled = array.slice();
  let currentIndex = shuffled.length;
  let temporaryValue, randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = shuffled[currentIndex];
    shuffled[currentIndex] = shuffled[randomIndex];
    shuffled[randomIndex] = temporaryValue;
  }
  return shuffled;
};

export function loadScript(id: string, src: string, callback: () => void) {
  if (!document.body) {
    throw new Error("This will only run on client");
  }

  const existingScript = document.getElementById(id);

  if (!existingScript) {
    const script = document.createElement("script");
    script.src = src;
    script.id = id;

    document.body?.appendChild(script);
    script.onload = () => {
      callback();
    };
  }

  if (existingScript && callback) callback();
}

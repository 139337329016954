/* @flow */

import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "./styles.scss";
import cn from "classnames";

type Props = {
  children: React$Node,
  className?: string,
  wrapperClass?: string,
  open: boolean,
};

const TRANSITION_TIME = parseInt(styles.timeout, 10);
const MUTATION_OBSERVER_OPT = {
  attributes: true,
  childList: true,
  subtree: true,
  characterData: true,
};

const useBrowserLayoutEffect: typeof useLayoutEffect = process.browser
  ? useLayoutEffect
  : () => {};

/**
 * A foldable wrapping component
 */
export const Foldable = ({
  className = "",
  wrapperClass = "",
  open = false,
  children,
  ...props
}: Props): React$Node => {
  const [overflowHidden, setOverflowHidden] = useState(!open);
  const firstUpdate = useRef(true);
  const _open = useRef(open);
  const timeout = useRef<TimeoutID | null>(null);
  const element = useRef<HTMLDivElement | null>(null);
  const wrapper = useRef<HTMLDivElement | null>(null);

  const transitionEnd = (opening: boolean) => {
    setOverflowHidden(!opening);
  };

  useBrowserLayoutEffect(() => {
    _open.current = open;

    const recalcHeight = () => {
      if (!firstUpdate.current) {
        setOverflowHidden(true);

        if (timeout.current) {
          clearTimeout(timeout.current);
        }

        timeout.current = setTimeout(
          () => transitionEnd(_open.current),
          TRANSITION_TIME
        );
      }

      if (element.current && wrapper.current) {
        element.current.style.height = _open.current
          ? `${wrapper.current.getBoundingClientRect().height}px`
          : "0px";
      }

      if (firstUpdate.current) {
        firstUpdate.current = false;
      }
    };

    recalcHeight();

    if (!_open.current) {
      return;
    }

    let observer;

    // Re-calculate height when the wrappers content size changes
    if (process.browser) {
      if ("ResizeObserver" in window) {
        observer = new ResizeObserver(() => {
          if (_open.current) {
            recalcHeight();
          }
        });

        if (wrapper.current) {
          observer.observe(wrapper.current);
        }
      } else if ("MutationObserver" in window) {
        observer = new MutationObserver(() => {
          if (_open.current) {
            recalcHeight();
          }
        });

        if (wrapper.current) {
          observer.observe(wrapper.current, MUTATION_OBSERVER_OPT);
        }
      }
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [open]);

  // Clear timeout on unmount
  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  return (
    <div
      {...props}
      ref={element}
      style={{ overflow: overflowHidden ? "hidden" : "visible" }}
      data-foldable="true"
      className={cn({
        [styles.block]: true,
        [(wrapperClass: string)]: Boolean(wrapperClass),
      })}
    >
      <div ref={wrapper} className={className}>
        {children}
      </div>
    </div>
  );
};

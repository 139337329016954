/* @flow */

import React from "react";
import styles from "./styles.scss";
import CloseIcon from "../../../icons/close.svg";

type Props<T> = {
  class?: string,
  id: string,
  setState: ({ [key: string]: T }) => void,
  rows: { [key: string]: T },
  children: React$Node,
  headerTitle: string,
};

function Row<T>({
  id,
  rows,
  setState,
  headerTitle,
  children,
}: Props<T>): React$Node {
  const row = rows[id];

  const remove = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const newRows = {};

    for (const k of Object.keys(rows)) {
      if (k !== id) {
        newRows[k] = rows[k];
      }
    }

    setState(newRows);
  };

  return (
    <div className={styles.row}>
      <header className={styles.row__header}>
        <h2>{headerTitle}</h2>
        <button
          className={styles.row__remove}
          title="Ta bort rad"
          onClick={remove}
        >
          <CloseIcon />
        </button>
      </header>

      <div className={styles.row__body}>{children}</div>
    </div>
  );
}

export { Row };

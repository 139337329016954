/* @flow */

import type { Testimonial } from "../../types/testimonial.flow";

import React, { useState, useEffect, useCallback } from "react";
import cn from "classnames";
// import { useData } from "crustate/react";
// import { TestimonialsData } from "../../data";
import { find } from "@out-of-home/diskho";
import { Link } from "react-router-dom";
import styles from "./styles.scss";
import StarIcon from "../../icons/star.svg";
import ChevronBtnIcon from "../../icons/chevronbtn.svg";
import { useIsomorphicLayoutEffect } from "../../helpers/use-isomorphic-layout-effect";

const TESTIMONIALS: Testimonial[] = [
  {
    id: 1,
    company: "BABEL BAZAAR",
    firstname: "Anna-Moa Näsman",
    feedback: "Bra webbshop och älskar att inte behöva förhandla om priser utan allt är satt rättvist :)",
    image: "/assets/testimonials/babel_bazaar.webp",
    rating: 5,
  },
  {
    id: 2,
    company: "Chill Out sushi AB",
    firstname: "Christian Dahlberg",
    feedback: "Snabbt & enkelt – trevlig, tillmötesgående & hjälpsam personal.",
    image: "/assets/testimonials/chill_out_sushi.webp",
    rating: 5,
  },
  {
    id: 3,
    company: "Go North Group",
    firstname: "Alexandra Österman",
    feedback: "Bra utbud med bra priser, snabba leveranser och alltid trevlig personal!",
    image: "/assets/testimonials/go_north_group.webp",
    rating: 5,
  },
  {
    id: 4,
    company: "Hotell Siesta",
    firstname: "Fredrik Harrysson",
    feedback: "Toppen",
    image: "/assets/testimonials/hotell_siesta.webp",
    rating: 5,
  },
  {
    id: 5,
    company: "WAP Lillån AB",
    firstname: "Karl Johansson",
    feedback: "Snabbt och smidigt",
    image: "/assets/testimonials/wap_helsingborg.webp",
    rating: 5,
  },
  {
    id: 6,
    company: "Vedugnen i Alvik AB",
    firstname: "Tony Voltaire",
    feedback: "Riktigt bra service över chatt, mail och luren. Snabbt och effektivt. Bra!",
    image: "/assets/testimonials/vedugnen_i_alvik.webp",
    rating: 5,
  },
  {
    id: 7,
    company: "Ängelholms FF",
    firstname: "Megan Koch",
    feedback: "Super lätt att beställa",
    image: "/assets/testimonials/angelholms_ff.webp",
    rating: 5,
  },
  {
    id: 8,
    company: "Foodora Market",
    firstname: "Rebecca Jarlheden",
    feedback: "Outofhome är en flexibel utmanare som skapar ett stort värde och möter våra växande behov i den snabba tillväxtresa vi är på. 5/5.",
    image: "/assets/testimonials/foodora_market.webp",
    rating: 5,
  },
  {
    id: 9,
    company: "ARC",
    firstname: "Jeanette Elfström",
    feedback: "Lätthanterlig hemsida med brett sortiment och snabba leveranser, för att inte tala om den kompetenta och trevliga personalen",
    image: "/assets/testimonials/arc.webp",
    rating: 5
  }
];

const MAX_SCORE = 5;

type Props = {
  className?: string,
  segment?: string,
};

// @TODO: show different testimonials depending on selected customer segment

const Score = ({ rating }) => {
  return (
    <div>
      {Array(MAX_SCORE)
        .fill()
        .map((_, i) => (
          <img
            key={i}
            alt="Betyg"
            src="/assets/icons/star.svg"
            className={cn(styles.star, { [styles.active]: i <= rating })}
          />
        ))}
    </div>
  );
};

export const Testimonials = (props: Props): React$Node => {
  // const testimonialsData = useData(TestimonialsData);
  const [current, setCurrent] = useState(0);

  useIsomorphicLayoutEffect(() => {
    setTimeout(() => {
      for (let i = 1; i < TESTIMONIALS.length; i++) {
        const img = new Image();
        img.src = TESTIMONIALS[i].image;
      }
    }, 1500);
  }, []);

  /*
  const testimonials =
    testimonialsData.state === "LOADED" ? testimonialsData.data : [];
  */
  const testimonials = TESTIMONIALS;
  const testimonial = testimonials[current];


  if (!testimonials) {
    return null;
  }

  if (!testimonial || testimonials.length < 2) {
    return null;
  }

  const next = () => {
    if (current + 1 < testimonials.length) {
      setCurrent(current + 1);
    } else {
      setCurrent(0);
    }
  };

  const prev = () => {
    if (current - 1 >= 0) {
      setCurrent(current - 1);
    } else {
      setCurrent(testimonials.length - 1);
    }
  };

  const goto = (i: number) => {
    setCurrent(i);
  };

  const prevIndex = testimonials[current - 1]
    ? current - 1
    : testimonials.length - 1;
  const nextIndex = testimonials[current + 1] ? current + 1 : 0;

  const navItems = [
    testimonials[current - 1] || testimonials[testimonials.length - 1],
    testimonials[current],
    testimonials[current + 1] || testimonials[0],
  ];

  return (
    <div className={styles.block}>
      <div key={current} className={styles.container}>
        <div className={styles.left}>
          <div key={testimonial.image} style={{ backgroundImage: `url("${testimonial.image}")` }} />
        </div>
        <div className={styles.right}>
          <div>
            <div className={styles.top}>
              <div>
                <div className={styles.rating}>
                  <Score rating={testimonial.rating} />
                </div>
                <nav className={styles.nav}>
                  <button className={styles.prev} aria-label="Föregående sida">
                    <ChevronBtnIcon onClick={prev} />
                  </button>
                  <button className={styles.next} aria-label="Nästa sida">
                    <ChevronBtnIcon onClick={next} />
                  </button>
                </nav>
              </div>

              <div className={styles.content}>
                <div className={styles.testimonial}>
                  <p>{testimonial.feedback}</p>
                </div>
              </div>
            </div>
            <div className={styles.footer}>
              <div className={styles.author}>
                {/*
                <img className={styles.avatar} src="https://placekitten.com/64/64" />
                    */}
                <div>
                  <p className={styles.author__name}>{testimonial.firstname}</p>
                  <p className={styles.author__company}>{testimonial.company}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/* @flow */

import type { ValidationError } from "../../helpers/formHelpers";

import React, { useState } from "react";
import { useFormField } from "@awardit/formaggio";
import { useTranslate } from "@out-of-home/use-translate";
import { Input } from "../Input";

type RenderProps = {
  submitted: boolean,
  dirty: boolean,
  errors: Array<ValidationError>,
};

type InputProps = {
  name: string,
  value?: string,
  label?: string,
  forceShowError?: boolean,
  component?: React$ComponentType<any>,
  render?: (RenderProps) => React$Node,
};

export const Field = ({
  component,
  render,
  forceShowError = false,
  ...props
}: InputProps): React$Node => {
  const t = useTranslate();
  const { submitted, dirty, errors, inputProps } = useFormField(props.name, "");
  const Component = component || Input;

  const inputErrors =
    submitted || forceShowError
      ? errors.map((x) => {
          const { error, ...rest } = x;
          return t(`VALIDATION.${x.error}`, { ...rest, field: props.label });
        })
      : [];

  if (render) {
    return render({ submitted, dirty, errors });
  }

  return (
    <Component
      {...props}
      {...inputProps}
      dirty={dirty}
      submitted={submitted}
      errors={inputErrors}
    />
  );
};

/* @flow */

import type { Quote } from "../../types/quote.flow";

import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { useTranslate } from "@out-of-home/use-translate";
import styles from "./styles.scss";
import cn from "classnames";
import { DatePickerSimple } from "../DatePickerSimple";
import { TimeContext } from "../../context/time";
import { UIContext } from "../../context/ui";
import { Spinner } from "../Spinner";
import { Checkbox } from "../Checkbox";
import { daySlotsToDisplay } from "../../helpers/checkoutHelpers";
import { useFormat } from "../../helpers/use-format";
import { useCustomer } from "../../helpers/use-customer";
import {
  addDays,
  formatTime,
  timeToNumber,
  dateToHours,
  dateToISO8601,
} from "../../helpers/deliveryCalendarHelpers";
import { useSendMessage } from "crustate/react";
import { Stock } from "./Stock";
import { itemStockOverflow } from "../CheckoutView/StockPopup/helpers";
import { Button } from "../Button";
import {
  fromHoursRangeToString,
  useDeliverySchedule,
  usePreferredDeliveryDate,
  useTimeWindow,
} from "./amtables-hooks";
import { Pips } from "../Pips";
import { RangeSlider } from "../RangeSlider";
import { Input } from "../Input";
import type {
  DeliveryWindow,
} from "../../helpers/deliveryCalendarHelpers";
import { useClient } from "../../entrypoint/shared";

import {
  clamp,
  round,
} from "../../helpers/deliveryCalendarHelpers";
import type {
  DeliveryInfo,
  DeliveryInfoError,
  DeliveryInfoOK,
  TimeWindow,
  UseDeliveryScheduleReturn,
  UseDeliveryScheduleReturnOK,
} from "./amtables-hooks";
import type { Customer } from "../../types/customer.flow";

const breakpointSmall = parseInt(styles.breakpointSmall, 10);

type Props = {
  className?: string,
  loading: boolean,
  quote: Quote,
  setLoading: (boolean) => void,
  openStockPopup: () => void,
};

const DATE_FORMAT = { weekday: "long", day: "numeric", month: "long" };
const ucfirst = (x) => x.substr(0, 1).toUpperCase() + x.substr(1);
const fmtDate = (x: Date) =>
  ucfirst(x.toLocaleDateString("sv-SE", DATE_FORMAT));


const showTimeWindowComponent = (timeWindow: TimeWindow) => {
  return timeWindow.minimum !== null &&
            timeWindow.minimum >= 1 &&
            timeWindow.minimum <= 7;
}

export const AMTables = ({
  quote,
  loading,
  setLoading,
  openStockPopup,
}: Props): React$Node => {
  const t = useTranslate();
  const { now } = useContext(TimeContext);
  const { customer } = useCustomer();
  const { browserWidth } = useContext(UIContext);
  const daysToDisplay = daySlotsToDisplay(browserWidth);
  const deliverySchedule = useDeliverySchedule(quote, setLoading);
  const preferredDeliveryDate = usePreferredDeliveryDate(quote, deliverySchedule.isDayEnabled);
  const prefDateWeekDayNum = preferredDeliveryDate.date ? preferredDeliveryDate.date.getDay() : 1;

  return (
    <div className={cn(styles.block, styles.block__amtables)}>

      <h3 className={styles.stepHeading}>2. Leveransdag</h3>

      {deliverySchedule.type === "LOADING" &&
        <div style={{ height: "150px", backgroundColor: "transparent" }}>
          <Spinner variant="large inverted" />
        </div>
      }

      {/* TODO: visa error om det inte kunde laddas något */}

      {deliverySchedule.type === "OK" &&
        <>
          <DeliveryDay
            deliverySchedule={deliverySchedule}
            quote={quote}
            openStockPopup={openStockPopup}
            preferredDeliveryDate={preferredDeliveryDate}
            />

          {deliverySchedule.deliveryInfo.status === "OK" && showTimeWindowComponent(deliverySchedule.deliveryInfo.timeWindow[prefDateWeekDayNum - 1]) &&
            <TimeWindowComponent
              carrierTimewindow={deliverySchedule.deliveryInfo.timeWindow[prefDateWeekDayNum - 1]}
              quote={quote}
              customer={customer}
              setLoadingExternal={setLoading}
              browserWidth= {browserWidth}
            />
          }
        </>
      }

    </div>
  );
};

type DeliveryInfoProps = {
  quote: Quote,
  deliverySchedule: UseDeliveryScheduleReturnOK,
  openStockPopup: () => void,
}
const DeliveryDay = ({ quote, deliverySchedule, openStockPopup, preferredDeliveryDate }: DeliveryInfoProps) => {
  const t = useTranslate();
  const { now } = useContext(TimeContext);
  const { customer } = useCustomer();
  const { browserWidth } = useContext(UIContext);
  const daysToDisplay = daySlotsToDisplay(browserWidth);
  const stockOverflow = useMemo(() => quote.items.some(itemStockOverflow), [quote]);
  const prefDateWeekDayNum = preferredDeliveryDate.date ? preferredDeliveryDate.date.getDay() : 1;

  return (
    <>
      <div className={styles.deliveryInfo}>

        <div className={styles.deliveryDate}>
          <p className={styles.noMargin}>
            Önskad leveransdag: <strong>{fmtDate(new Date(quote.preferredDeliveryDate || quote.nextDeliveryDate))}</strong>
          </p>

          <Stock
            showTooltip={quote.preferredDeliveryDate === quote.nextDeliveryDate}
            deliveryInfoStatus={deliverySchedule.deliveryInfo.status}
          />

          {stockOverflow && (
            <Button variant="primary" size="mini" onClick={openStockPopup}>
              {t("SHIPPING.STOCK_POPUP_DELIVERY_IMMEDIATELY")}
            </Button>
          )}
        </div>

        {deliverySchedule.deliveryInfo.status === "OK" &&
          <DeliveryInfoMessageOK deliveryInfo={deliverySchedule.deliveryInfo} preferredWeekday={prefDateWeekDayNum} />
        }

        {deliverySchedule.deliveryInfo.status === "NO_TIMETABLE" &&
          <p>
            {t("AMTABLES.NO_TIMETABLE")}
          </p>
        }

        {deliverySchedule.deliveryInfo.status === "NO_POSTCODE" &&
          <p>
            {t("AMTABLES.NO_POSTCODE")}
          </p>
        }

        {deliverySchedule.deliveryInfo.status === "BELOW_WEIGHT" &&
          <p>
            {t("AMTABLES.BELOW_WEIGHT")}
          </p>
        }
      </div>

      {preferredDeliveryDate.date &&
        <div className="row--large">
          <DatePickerSimple
            value={preferredDeliveryDate.date}
            isDayEnabled={deliverySchedule.isDayEnabled}
            min={deliverySchedule.minDate}
            max={addDays(now, 100)}
            pageSize={daysToDisplay}
            pageJump={daysToDisplay >= 5 ? 7 : undefined}
            onChange={(x) => {
              preferredDeliveryDate.set(x);
            }}
            />
        </div>
      }
    </>
  );
};

const DeliveryInfoMessageOK = ({ deliveryInfo, preferredWeekday }: { deliveryInfo: DeliveryInfoOK, preferredWeekday: number }) => {
  const t = useTranslate();
  const timeWindow = deliveryInfo.timeWindow[preferredWeekday];

  return (
    <>
      <p className={styles.noMargin}>
        {t("AMTABLES.CARRIER")}: <strong>{deliveryInfo.carrier}</strong>
      </p>
      {!showTimeWindowComponent(timeWindow) &&
        <p className={styles.noMargin}>
          {t("AMTABLES.TIME_WINDOW_RANGE")}: <strong>{fromHoursRangeToString(timeWindow.range)}</strong>
        </p>
      }
      <p>
        {t("AMTABLES.OK")}
      </p>
    </>
  )
}

type TimeWindowComponentProps = {
  carrierTimewindow: TimeWindow,
  quote: Quote,
  customer: ?Customer,
  setLoadingExternal: (value: boolean) => void,
  browserWidth: number
}

const TimeWindowComponent = ({ carrierTimewindow, quote, customer, setLoadingExternal, browserWidth }: TimeWindowComponentProps) => {
  const timeWindowHelper = useTimeWindow(carrierTimewindow, quote, customer, setLoadingExternal);
  const onInputChange = useCallback((e: DOMEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const key = e.target.name;

    timeWindowHelper.set({
      ...timeWindowHelper.range,
      [key]: timeToNumber(e.target.value),
    });
  }, [timeWindowHelper]);

  return (
    <>
      <h3 className={styles.stepHeading}>3. Leveranstid</h3>
      {(
        <div className={styles.slider}>
          <Pips
            minValue={timeWindowHelper.range.from}
            maxValue={timeWindowHelper.range.to}
          />
          <RangeSlider
            ariaLabel="Välj tidspann"
            step={1}
            min={timeWindowHelper.range.from}
            max={timeWindowHelper.range.to}
            values={[timeWindowHelper.current.from, timeWindowHelper.current.to]}
            onChange={(values) => {
              timeWindowHelper.set({ from: values[0], to: values[1] })
            }}
          />
        </div>
      )}

      {browserWidth <= breakpointSmall && (
        <div className="row--large">
          <div className={styles.timeInputs}>
            <Input
              className={styles.input}
              label="Från"
              name="from"
              type="time"
              variant="box"
              value={formatTime(timeWindowHelper.current.from)}
              onChange={onInputChange}
            />
            <span className={styles.separator}>-</span>
            <Input
              className={styles.input}
              label="Till"
              name="to"
              type="time"
              variant="box"
              value={formatTime(timeWindowHelper.current.to)}
              onChange={onInputChange}
            />
          </div>
        </div>
      )}
    </>
  )
}

// @flow

import type { Model, EffectErrorMessage } from "crustate";
import type {
  SortableProductList,
  FilterLocation,
  ProductFilterInput,
  ProductFilterInputPrice,
  ProductSortInput,
} from "../types/filter.flow.js";

import { getInputFilters, getInputSort, getPage } from "./filter";

import { updateData, EFFECT_ERROR } from "crustate";

type Data =
  | {| state: "INIT" |}
  | {| state: "LOADING" |}
  | {| state: "UPDATING", data: SortableProductList |}
  | {| state: "LOADED", data: SortableProductList |};

export type AllProductsResponse =
  | { tag: typeof ALL_PRODUCTS_RESPONSE, data: ?SortableProductList }
  | EffectErrorMessage;

export type AllProductsRequest = {
  tag: typeof ALL_PRODUCTS_REQUEST,
  filters: ?Array<ProductFilterInput | ProductFilterInputPrice>,
  sort: ?ProductSortInput,
  page: ?number,
};

export const ALL_PRODUCTS_RESPONSE: "response/all-products" =
  "response/all-products";
export const ALL_PRODUCTS_REQUEST: "request/all-products" =
  "request/all-products";

export const allProducts = (
  location: FilterLocation,
  incVat?: boolean,
  pointsToPrice?: (number) => number
): AllProductsRequest => {
  const filters = getInputFilters(location, incVat, pointsToPrice);
  const sort = getInputSort(location);
  const page = getPage(location);

  return {
    tag: ALL_PRODUCTS_REQUEST,
    filters,
    sort,
    page,
  };
};

export const AllProductsModel: Model<
  Data,
  {
    location: FilterLocation,
    incVat?: boolean,
  },
  AllProductsRequest | AllProductsResponse
> = {
  id: "all-products",
  init: ({ location, incVat }) =>
    updateData({ state: "LOADING" }, allProducts(location, incVat)),
  update: (state, msg) => {
    switch (msg.tag) {
      case EFFECT_ERROR:
        throw new Error("Route load failed");
      case ALL_PRODUCTS_REQUEST:
        if (state.state === "LOADED") {
          return updateData({ state: "UPDATING", data: state.data }, msg);
        }

        break;
      case ALL_PRODUCTS_RESPONSE:
        if (
          msg.data &&
          (state.state === "LOADING" || state.state === "UPDATING")
        ) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        throw new Error("Failed to fetch all-products results");
      default:
    }
  },
};

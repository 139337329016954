/* @flow */

import type { TBlogPostPartial } from "../types/blog.flow";

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

type State = {|
  filters: Params,
  numPages: number,
  posts: Array<TBlogPostPartial>,
|};

type Data = {| state: "LOADING" |} | {| state: "LOADED", data: State |};

export type BlogResponse = Response<typeof BLOG_LIST_LOAD_RESPONSE, State>;

export type BlogRequest = {
  tag: typeof BLOG_LIST_LOAD_REQUEST,
  params?: Params,
  page: number,
  pageSize?: number,
};

export type Params = {
  category: ?string,
  tag: ?string,
};

export const PER_PAGE = 9;

export const BLOG_LIST_LOAD_RESPONSE: "blog_list/load/response" =
  "blog_list/load/response";
export const BLOG_LIST_LOAD_REQUEST: "blog_list/load/request" =
  "blog_list/load/request";

export const BlogListModel: Model<
  Data,
  { page: number, pageSize?: number, params?: Params },
  BlogRequest | BlogResponse
> = {
  id: "blog-list",
  init: ({
    params,
    page,
    pageSize,
  }: {
    page: number,
    pageSize?: number,
    params?: Params,
  }) =>
    updateData(
      { state: "LOADING" },
      {
        tag: BLOG_LIST_LOAD_REQUEST,
        params,
        page,
        pageSize,
      }
    ),
  update: (state: Data, msg) => {
    switch (msg.tag) {
      case BLOG_LIST_LOAD_RESPONSE:
        if (msg.data && state.state === "LOADING") {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;
      default:
    }
  },
};

/* @flow */

import React, { useEffect } from "react";

export const KEY_ENTER = 13;
export const KEY_ESC = 27;
export const KEY_UP = 38;
export const KEY_DOWN = 40;
export const KEY_TAB = 9;
export const alphanum: RegExp = /^[a-z0-9åäö]$/i;

let debounce;

const useTypeahead = (
  element: HTMLElement | null,
  value: string,
  setValue: (v: string) => void,
  maxKeyDelay: number = 1000
) => {
  useEffect(() => {
    if (element !== null) {
      const setSearchString = (char: string) => {
        setValue(value + char);
        clearTimeout(debounce);

        debounce = setTimeout(() => setValue(""), maxKeyDelay);
      };

      const onKeyDown = (event: KeyboardEvent) => {
        /**
         * Don"t do anything if a modifier key is pressed. shift is
         * excluded from this list since users may capitalize their
         * text when typing to get to an option.
         **/
        if (event.metaKey || event.ctrlKey || event.altKey) {
          return;
        }

        if (
          ![KEY_ENTER, KEY_ESC, KEY_UP, KEY_DOWN, KEY_TAB].includes(event.which)
        ) {
          event.preventDefault();
          event.stopPropagation();
        }

        if (alphanum.test(String.fromCharCode(event.which))) {
          setSearchString(String.fromCharCode(event.which));
        }
      };

      element.addEventListener("keydown", onKeyDown);

      return () => element.removeEventListener("keydown", onKeyDown);
    }
  }, [element, maxKeyDelay, value, setValue]);
};

export default useTypeahead;

/* @flow */

import React, { useRef, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import cn from "classnames";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import { useTranslate } from "@out-of-home/use-translate";
import { useData } from "crustate/react";
import { FrontPageRecommendedData } from "../../data";
import { restProductToGQLProduct } from "../../helpers";
import { Excerpt } from "../Excerpt";
import { Wrapper } from "../Wrapper";
import styles from "./styles.scss";
import { Testimonials } from "../Testimonials";
import { Nag } from "../Nag";
import { Button } from "../Button";
import { ReferenceCustomersSection } from "./reference-customers-section";

export const ReferenceCustomersView = (): React$Node => {
  const t = useTranslate();

  return (
    <div className={styles.block}>
      <section className={styles.heading}>
        <Wrapper variant="medium" className={styles.center}>
          <h1>{t("CUSTOMERS_VIEW.TITLE")}</h1>
          <p>{t("CUSTOMERS_VIEW.TEXT")}</p>
        </Wrapper>
      </section>

      <ReferenceCustomersSection />

      <section className={cn(styles.section__referenceCustomers, styles.center)}>
        <h3>{t("FOOTER_LINKS.CUSTOMERS")}</h3>
        <ul className={styles.referenceCustomers}>
          <li>
            <Link to={t("ROUTES.CUSTOMERS_1")}>
              {t("FOOTER_LINKS.CUSTOMERS_1")}
            </Link>
          </li>
          <li>
            <Link to={t("ROUTES.CUSTOMERS_2")}>
              {t("FOOTER_LINKS.CUSTOMERS_2")}
            </Link>
          </li>
          <li>
            <Link to={t("ROUTES.CUSTOMERS_3")}>
              {t("FOOTER_LINKS.CUSTOMERS_3")}
            </Link>
          </li>
          <li>
            <Link to={t("ROUTES.CUSTOMERS_4")}>
              {t("FOOTER_LINKS.CUSTOMERS_4")}
            </Link>
          </li>
          <li>
            <Link to={t("ROUTES.CUSTOMERS_5")}>
              {t("FOOTER_LINKS.CUSTOMERS_5")}
            </Link>
          </li>
          <li>
            <Link to={t("ROUTES.CUSTOMERS_7")}>
              {t("FOOTER_LINKS.CUSTOMERS_7")}
            </Link>
          </li>
          <li>
            <Link to={t("ROUTES.CUSTOMERS_8")}>
              {t("FOOTER_LINKS.CUSTOMERS_8")}
            </Link>
          </li>
          <li>
            <Link to={t("ROUTES.CUSTOMERS_9")}>
              {t("FOOTER_LINKS.CUSTOMERS_9")}
            </Link>
          </li>
          <li>
            <Link to={t("ROUTES.CUSTOMERS_10")}>
              {t("FOOTER_LINKS.CUSTOMERS_10")}
            </Link>
          </li>
          <li>
            <Link to={t("ROUTES.CUSTOMERS_11")}>
              {t("FOOTER_LINKS.CUSTOMERS_11")}
            </Link>
          </li>
        </ul>
      </section>

      <Helmet title={t("CUSTOMERS_VIEW.TITLE")}>
        <meta name="description" content={t("CUSTOMERS_VIEW.DESCRIPTION")} />
        <meta property="og:description" content={t("CUSTOMERS_VIEW.DESCRIPTION")} />
        <meta property="og:title" content={t("CUSTOMERS_VIEW.TITLE")} />
      </Helmet>
    </div>
  );
};

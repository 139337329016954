/* @flow */

import type { OverdueInvoice } from "../types/order.flow";

import { useState, useEffect } from "react";
import { useData } from "crustate/react";
import { OverdueInvoicesData } from "../data";

type ReturnType = {
  data: $ReadOnlyArray<OverdueInvoice>,
  gotOverdueInvoice: boolean,
  loading: boolean,
};

export const useOverdueInvoices = (): ReturnType => {
  const overdueInvoicesData = useData(OverdueInvoicesData);
  const [data, setData] = useState<$ReadOnlyArray<OverdueInvoice>>([]);
  const [gotOverdueInvoice, setGotOverdueInvoice] = useState(data.length > 0);
  const [loading, setLoading] = useState(
    overdueInvoicesData.state !== "LOADED"
  );

  useEffect(() => {
    if (overdueInvoicesData.state === "LOADED") {
      setData(overdueInvoicesData.data);
      setGotOverdueInvoice(overdueInvoicesData.data.length > 0);
      setLoading(false);
    }
  }, [overdueInvoicesData.state]);

  return { data, gotOverdueInvoice, loading };
};

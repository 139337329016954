// @flow

/*
  validationRules.js
  ------------------------------------------------------------

  Here goes all the validation rules.
*/

import type { FormData, ValidationError, Validator } from "./formHelpers";

const PNO_REGEX = /^[0-9-( )]+$/;
const POSTCODE_REGEX = /^[0-9]{3}\s?[0-9]{2}$/;

export const nestedRule =
  <T>(k: string, v: Validator<FormData>): Validator<FormData> =>
  (t: FormData): Array<ValidationError> =>
    typeof t === "object" && t != null && typeof t[k] === "object"
      ? v(t[k]).map(
          ({ error, field, ...rest }: ValidationError): ValidationError => ({
            ...rest,
            error,
            field: k + "." + field,
          })
        )
      : [];

export const emailValid =
  (f: string, validationKey: string): Validator<FormData> =>
  (s: FormData): Array<ValidationError> =>
    s[validationKey] === true
      ? []
      : [{ error: "EMAIL_NOT_AVAILABLE", field: f }];

export const lengthLte =
  (f: string, l: number): Validator<FormData> =>
  (s: FormData): Array<ValidationError> => {
    if (!s.hasOwnProperty(f) || !s[f]) {
      return [];
    }

    return s.hasOwnProperty(f) && typeof s[f] === "string" && s[f].length <= l
      ? []
      : [{ error: "LENGTH_LTE", field: f, lengthLte: l }];
  };

export const isPNO =
  (f: string): Validator<FormData> =>
  (t: FormData): Array<ValidationError> =>
    typeof t[f] === "string" && PNO_REGEX.test(t[f])
      ? []
      : [{ error: "ORG_NR_NOT_VALID", field: f }];

export const arrayGte =
  (f: string, l: number): Validator<FormData> =>
  (t: FormData): Array<ValidationError> =>
    Array.isArray(t[f]) && t[f].length >= l
      ? []
      : [{ error: "ARRAY_LENGTH_GTE", field: f, arrayLengthGte: l }];

export const isPostcode =
  (f: string): Validator<FormData> =>
  (t: FormData): Array<ValidationError> =>
    typeof t[f] === "string" && POSTCODE_REGEX.test(t[f])
      ? []
      : [{ error: "POSTCODE_NOT_VALID", field: f }];

export const isPassword =
  (f: string): Validator<FormData> =>
  (t: FormData): Array<ValidationError> =>
    typeof t[f] === "string" && t[f].length >= 7
      ? []
      : [{ error: "IS_PASSWORD", field: f }];

/* @flow */

import type { TBlogPostPartial } from "../../types/blog.flow";

import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./styles.scss";
import { useTranslate } from "@out-of-home/use-translate";
import { useFormat } from "../../helpers/use-format";

export const BlogPostCard = ({
  title,
  metaTitle,
  urlKey,
  categories,
  publishedAt,
  listThumbnail,
  shortContent,
  grayBg = false,
}: TBlogPostPartial & { grayBg?: boolean }): React$Node => {
  const t = useTranslate();
  const { formatDate } = useFormat();
  const category = categories && categories[0];
  const url = `/${t("ROUTES.NEWSPOST")}/${urlKey}`;

  return (
    <div className={cn(styles.block, { [styles.grayBg]: grayBg })}>
      <Link
        to={{
          pathname: url,
          state: {
            hint: {
              title,
              metaTitle,
              listThumbnail,
              postThumbnail: listThumbnail,
              publishedAt,
              categories,
              meta: {},
              shortContent,
              fullContent: shortContent,
              tags: [],
              contactPersons: [],
              relatedProducts: [],
              relatedPosts: [],
            },
          },
        }}
      >
        <div className={styles.img}>
          <img src={listThumbnail} alt={metaTitle} loading="lazy" />
        </div>
      </Link>
      <div className={styles.meta}>
        {category ? (
          <Link to={`/${t("ROUTES.NEWS")}?category=${category.urlKey}`} className={styles.category}>
            {category.name}
          </Link>
        ) : (
          <span className={styles.category}></span>
        )}
        <span className={styles.date}>{formatDate(new Date(publishedAt))}</span>
      </div>
      <Link className={styles.title} to={url}>
        <h3 className={"h4"}>{title}</h3>
      </Link>
      <div className={styles.content}>
        {shortContent.map((x, i) => (
          <p key={i} dangerouslySetInnerHTML={{ __html: x }} />
        ))}
      </div>
    </div>
  );
};

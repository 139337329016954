/* @flow */

import type { Quote, QuoteAddress, QuoteAddressBilling } from ".";

import React, { useEffect, useContext } from "react";
import { StripeContext } from "./context";

export const STRIPE_PAYMENT_METHOD_CODE = "Crossroads_Stripe_PaymentIntents";

const getBillingAddress = (
  addresses: $ReadOnlyArray<QuoteAddress>
): ?QuoteAddressBilling => (addresses.find((a) => a.type === "billing"): any);

export const useInitStripeQuotePaymentMethod = <T: ?Quote>(quote: T): void => {
  const { setBillingAddress, setEmail } = useContext(StripeContext);
  const { payment, addresses, email } = quote || {};
  const billingAddress = getBillingAddress(addresses || []);
  const paymentCode = payment?.code;
  const stripeKey =
    payment && payment.code === STRIPE_PAYMENT_METHOD_CODE
      ? payment.publishableKey
      : null;

  // Update stripe billing address on quote update
  useEffect(() => {
    if (billingAddress) {
      setBillingAddress(billingAddress);
    }
  }, [billingAddress, setBillingAddress]);

  // Update stripe email on quote update
  useEffect(() => {
    if (typeof email === "string") {
      setEmail(email);
    }
  }, [email, setEmail]);

  if (!stripeKey && paymentCode === STRIPE_PAYMENT_METHOD_CODE) {
    throw new Error(
      "Could not load Stripe: No publishableKey found in quote.payment"
    );
  }
};

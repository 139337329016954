/* @flow */

import type { Customer } from "../../types/customer.flow";
import type { Address } from "../../types/address.flow";
import type {
  WFMOrder,
  WFMOrderProduct,
  FaultyDeliveryRows,
  FaultyDeliveryRowQty,
  FaultyDeliveryRowSku,
  WFMRows,
  DeliveryDamageRows,
  CustomFieldValue,
  ServiceTaskBody,
  WFMOrderItem,
  ServiceTaskImage,
} from "../../types/wfm.flow";

type CreateGenericTaskBodyItems = {
  +[key: string]: {
    qty: number,
    description: string,
    +product: {
      name: string,
      sku: string,
    },
  },
};

const CUSTOM_FIELD_ID_ORDER_ID = "06871d5b-8024-4c75-9b71-785032156291";
const CUSTOM_FIELD_ID_FAULTY_ORDER_ROWS =
  "3bcc82c5-e230-4c6f-bac3-35bd427a3feb";
const CUSTOM_FIELD_ID_CUSTOMER_NAME = "24313e7e-1c90-4621-ac7b-7fcd59d737b9";
const CUSTOM_FIELD_ID_CUSTOMER_COMPANY = "89bf2ebd-e333-4dcc-b64e-50212c04e57c";
const CUSTOM_FIELD_ID_CUSTOMER_EMAIL = "c9a8459b-31c2-4306-95fc-9c1580bffa7e";
const CUSTOM_FIELD_ID_CUSTOMER_ORDERED = "af47c772-7501-4df2-b276-1832eda672ef";
const CUSTOM_FIELD_ID_CUSTOMER_DELIVERED =
  "94da5902-8305-459b-bdd9-5ba7b494fade";
const CUSTOM_FIELD_ID_CUSTOMER_ADDRESS = "6d2681d7-fac5-4c25-acee-70fdecc16129";
const CUSTOM_FIELD_ID_RETURN_WEIGHT = "f82e126b-8dad-4fb5-9271-e1580fc09b8e";
const CUSTOM_FIELD_ID_RECLAIM_DAMAGED_PRODUCT_ATTACHMENT =
  "7835aa56-f1ee-47ff-80f8-6d925efdd870";
const CUSTOM_FIELD_ID_RECLAIM_VALUE = "d589c465-d992-4054-a233-fe86e9182537";

const CUSTOM_FIELD_ID_TEMP = "9bc6ed63-4fd8-42d8-a57b-d2a0233af232";
const CUSTOM_FIELD_TEMP_ID_DRY = "0fd55add-efbb-4fb3-aa18-c59e9923ecc5";
const CUSTOM_FIELD_TEMP_ID_COLD = "837b7db8-1520-4547-93ab-b3ceacb1db28";
const CUSTOM_FIELD_TEMP_ID_FROZEN = "15648c99-afd7-487d-bdd4-2019afd6b485";
const CUSTOM_FIELD_TEMP_ID_MIXED = "8316ba1b-d2ca-4751-98f3-387e53fca8f0";

const CUSTOM_FIELD_LABEL_ID_WRONG_QTY = "be7a9c50-dfba-4f8d-ac8c-c3051f90d319";
const CUSTOM_FIELD_LABEL_ID_WRONG_SKUS = "daa43ba4-07f4-4524-a845-02d3d91b81fa";
const CUSTOM_FIELD_LABEL_ID_MISSING_ORDERLINE =
  "f84eb378-1974-4aac-8524-19b32933395f";
const CUSTOM_FIELD_LABEL_ID_RETURN_PRODUCT =
  "23289397-612f-46f4-8932-ec2cc82b047f";
const CUSTOM_FIELD_LABEL_ID_TRANSPORT_DAMAGE =
  "275f7c02-8f41-42d9-8165-eaf20d4f5b61";

export const nextKeyId = (o: { [string]: any }): string => {
  if (Object.keys(o).length === 0) {
    return "0";
  }

  const ids = Object.keys(o).map((k) => parseInt(k, 10));

  return (Math.max(...ids) + 1).toString(10);
};

const getOrderItemsWeight = (items: Array<WFMOrderItem>) =>
  items.reduce(
    (a, c) => a + c.qty * Number(c.product.attributes.weight || 0),
    0
  );
const formatOrderedItemsList = (items: Array<WFMOrderItem>): string =>
  items.reduce(
    (a, c) =>
      a +
      `${c.qty} x ${c.product.sku} ${c.product.attributes.supplierSku} ${c.product.name}\n`,
    ""
  );
const formatItemDescription = (
  item: { +product: { sku: string, name: string }, description: string },
  type: string
): string =>
  `${item.product.name}, ${item.product.sku} - ${item.description} [${type}]\n`;
const getItemsValue = (
  order: WFMOrder,
  items: { +[key: string]: { +product: { sku: string }, qty: number } }
): string => {
  return String(
    Object.keys(items)
      .reduce((a, key) => {
        const item = items[key];

        const orderItem = order.items.find(
          (x) => x.product.sku === item.product.sku
        );

        if (!orderItem) {
          return a;
        }

        const qty = Math.min(item.qty, orderItem.qty);

        return a + qty * orderItem.product.price.exVat;
      }, 0)
      .toFixed(2)
  );
};

export const formatOrderedItemsFaultyDelivery = (
  order: WFMOrder,
  qty: Array<FaultyDeliveryRowQty>,
  skus: Array<FaultyDeliveryRowSku>
): string => {
  let ordered = qty.reduce((a, c) => {
    const product = order.items.find((x) => x.product.sku === c.ordered_sku);

    if (!product) {
      return a;
    }

    a += `${c.ordered_qty} x ${c.ordered_sku} ${product.product.attributes.supplierSku} ${product.product.name}\n`;
    return a;
  }, "");

  ordered += skus.reduce((a, c) => {
    const product = order.items.find((x) => x.product.sku === c.missing_sku);

    if (!product) {
      return a;
    }

    a += `${c.missing_qty} x ${c.missing_sku} ${product.product.attributes.supplierSku} ${product.product.name}\n`;
    return a;
  }, "");

  return ordered;
};

export const formatDeliveredItems = (
  order: WFMOrder,
  qty: Array<FaultyDeliveryRowQty>,
  skus: Array<FaultyDeliveryRowSku>
): string => {
  let delivered = qty.reduce((a, c) => {
    const product = order.items.find((x) => x.product.sku === c.ordered_sku);

    if (!product) {
      return a;
    }

    a += `${c.delivered_qty} x ${c.ordered_sku} ${product.product.attributes.supplierSku} ${product.product.name}\n`;
    return a;
  }, "");

  delivered += skus.reduce(
    (a, c) => `${a}${c.delivered_qty} x ${c.delivered_sku}\n`,
    ""
  );

  return delivered;
};

export const getFaultyDeliveryReturnWeight = (
  order: WFMOrder,
  qtys: Array<FaultyDeliveryRowQty>
): number => {
  return qtys.reduce((a, c) => {
    const diff =
      Number.parseInt(c.delivered_qty) - Number.parseInt(c.ordered_qty);

    if (!Boolean(diff)) {
      return a;
    }

    const item = order.items.find((x) => x.product.sku === c.ordered_sku);

    if (!item) {
      return a;
    }

    return a + diff * Number(item.product.attributes.weight || 0);
  }, 0);
};

const getTemperatures = (order: WFMOrder, skus: Array<string>): Set<string> => {
  const temperatures = new Set<string>();

  for (const sku of skus) {
    const item = order.items.find((x) => x.product.sku === sku);

    if (!item) {
      continue;
    }

    const temp = item.product.attributes.temperature;

    if (temp) {
      temperatures.add(temp.toUpperCase());
    } else {
      temperatures.add("TORR");
    }
  }

  return temperatures;
};

export const getItemsTemperature = (
  order: WFMOrder,
  skus: Array<string>
): string => {
  const temperatures = getTemperatures(order, skus);

  if (temperatures.size > 1) {
    return CUSTOM_FIELD_TEMP_ID_MIXED;
  }

  if (temperatures.has("FÄRSKT")) {
    return CUSTOM_FIELD_TEMP_ID_COLD;
  }

  if (temperatures.has("FRYST")) {
    return CUSTOM_FIELD_TEMP_ID_FROZEN;
  }

  return CUSTOM_FIELD_TEMP_ID_DRY;
};

const getFaultyDeliveryTypes = (
  qty: Array<FaultyDeliveryRowQty>,
  skus: Array<FaultyDeliveryRowSku>
) => {
  const errorTypes = [];

  if (qty.length) {
    const types = qty.reduce((a, c) => {
      if (c.delivered_qty === "0") {
        // if the qty delivered was 0, add label "Saknad orderrad"
        a.add(CUSTOM_FIELD_LABEL_ID_MISSING_ORDERLINE);
      } else {
        a.add(CUSTOM_FIELD_LABEL_ID_WRONG_QTY);
      }

      return a;
    }, new Set());

    errorTypes.push(...types);
  }

  if (skus.length) {
    errorTypes.push(CUSTOM_FIELD_LABEL_ID_WRONG_SKUS);
  }

  return errorTypes;
};

const getBaseFields = (
  order: WFMOrder,
  customer: Customer
): Array<CustomFieldValue> => {
  const fields = [
    { id: CUSTOM_FIELD_ID_ORDER_ID, value: String(order.id) }, // order id
    {
      id: CUSTOM_FIELD_ID_CUSTOMER_NAME,
      value: `${customer.firstname} ${customer.lastname}`,
    }, // customers name
    { id: CUSTOM_FIELD_ID_CUSTOMER_COMPANY, value: customer.company || "" }, // customers company
    { id: CUSTOM_FIELD_ID_CUSTOMER_EMAIL, value: customer.email }, // customers email
  ];

  const defaultAddress = customer.addresses.find((a) => a.isDefaultBilling);

  return fields;
};

export const createTaskBodyFaultyDelivery = (
  order: WFMOrder,
  items: FaultyDeliveryRows,
  customer: Customer
): ServiceTaskBody => {
  const qtys: Array<FaultyDeliveryRowQty> = Object.keys(items).reduce(
    (a, c) => {
      if (items[c].type === "qty") {
        a.push(items[c]);
      }
      return a;
    },
    []
  );

  const skus: Array<FaultyDeliveryRowSku> = Object.keys(items).reduce(
    (a, c) => {
      if (items[c].type === "sku") {
        a.push(items[c]);
      }
      return a;
    },
    []
  );

  const fields: Array<CustomFieldValue> = getBaseFields(order, customer);

  const errorTypes = getFaultyDeliveryTypes(qtys, skus);
  const ordered = formatOrderedItemsFaultyDelivery(order, qtys, skus);
  const delivered = formatDeliveredItems(order, qtys, skus);
  const temperature = getItemsTemperature(order, [
    ...qtys.map((x) => x.ordered_sku),
    ...skus.map((x) => x.delivered_sku),
  ]);
  const returnWeight = getFaultyDeliveryReturnWeight(order, qtys);

  fields.push(
    {
      id: CUSTOM_FIELD_ID_FAULTY_ORDER_ROWS,
      value: String(qtys.length + skus.length),
    }, // number of faulty orderrows
    { id: CUSTOM_FIELD_ID_CUSTOMER_ORDERED, value: ordered }, // customer ordered
    { id: CUSTOM_FIELD_ID_CUSTOMER_DELIVERED, value: delivered }, // delivered to customer
    { id: CUSTOM_FIELD_ID_TEMP, value: temperature }, // temperature on products
  );

  if (returnWeight > 0) {
    fields.push({
      id: CUSTOM_FIELD_ID_RETURN_WEIGHT,
      value: String(returnWeight),
    }); // weight if return
  }

  return {
    orderId: order.id,
    name: `Rapport - Felplock - ${order.id}`,
    description: ``,
    labels: errorTypes,
    fields,
    images: null,
  };
};

const createGenericTaskBody = (
  type: string,
  errorTypes: string[],
  order: WFMOrder,
  items: CreateGenericTaskBodyItems,
  customer: Customer,
  extraFields?: CustomFieldValue[]
): ServiceTaskBody => {
  const faultyItems = [];
  const name = `Rapport - ${type} - ${order.id}`;
  let description = "";

  for (const key of Object.keys(items)) {
    const item = items[key];
    const orderItem = order.items.find(
      (x) => x.product.sku === item.product.sku
    );

    if (!orderItem) {
      continue;
    }

    faultyItems.push(orderItem);
    description += formatItemDescription(item, type);
  }

  const reclaimValue = getItemsValue(order, items);
  const ordered: string = formatOrderedItemsList(faultyItems);
  const returnWeight: number = getOrderItemsWeight(faultyItems);
  const temperature = getItemsTemperature(
    order,
    faultyItems.map((x) => x.product.sku)
  );
  const fields: Array<CustomFieldValue> = getBaseFields(order, customer);

  fields.push(
    {
      id: CUSTOM_FIELD_ID_FAULTY_ORDER_ROWS,
      value: String(faultyItems.length),
    }, // number of faulty orderrows
    { id: CUSTOM_FIELD_ID_CUSTOMER_ORDERED, value: ordered }, // customer ordered
    { id: CUSTOM_FIELD_ID_TEMP, value: temperature }, // temperature on products
    { id: CUSTOM_FIELD_ID_RECLAIM_VALUE, value: reclaimValue }, // value on products in SEK
  );

  if (returnWeight > 0) {
    fields.push({
      id: CUSTOM_FIELD_ID_RETURN_WEIGHT,
      value: String(returnWeight),
    }); // weight if return
  }

  if (extraFields) {
    fields.push(...extraFields);
  }

  return {
    orderId: order.id,
    name,
    description,
    labels: errorTypes,
    fields,
    images: null,
  };
};

export const createTaskBodyShortDate = (
  order: WFMOrder,
  items: WFMRows,
  customer: Customer
): ServiceTaskBody => {
  const images: ServiceTaskImage[] = Object.keys(items).reduce((acc, key) => {
    if (items[key].image) {
      acc.push({
        content: items[key].image.content,
        filename: items[key].image.filename,
      });
    }

    return acc;
  }, []);

  const body = createGenericTaskBody(
    "Kort datum",
    [CUSTOM_FIELD_LABEL_ID_RETURN_PRODUCT],
    order,
    items,
    customer
  );

  body["images"] = images;

  return body;
};

export const createTaskBodyDeliveryDamage = (
  order: WFMOrder,
  items: DeliveryDamageRows,
  customer: Customer
): ServiceTaskBody => {
  const images: ServiceTaskImage[] = Object.keys(items).map((key) => ({
    content: items[key].image,
    filename: items[key].imageName,
  }));

  const body = createGenericTaskBody(
    "Transportskada",
    [CUSTOM_FIELD_LABEL_ID_TRANSPORT_DAMAGE],
    order,
    items,
    customer
  );

  body["images"] = images;

  return body;
};

export const createTaskBodyReturnItem = (
  order: WFMOrder,
  items: WFMRows,
  customer: Customer
): ServiceTaskBody => {
  const images: ServiceTaskImage[] = Object.keys(items).reduce((acc, key) => {
    if (items[key].image) {
      acc.push({
        content: items[key].image.content,
        filename: items[key].image.filename,
      });
    }

    return acc;
  }, []);

  const body = createGenericTaskBody(
    "Reklamera artikel",
    [CUSTOM_FIELD_LABEL_ID_RETURN_PRODUCT],
    order,
    items,
    customer
  );

  body["images"] = images;

  return body;
};

/* @flow */

import type { Storage } from "crustate";
import type { BlogRequest, BlogResponse } from "../state/blogList";
import {
  BLOG_LIST_LOAD_REQUEST,
  BLOG_LIST_LOAD_RESPONSE,
} from "../state/blogList";
import { stringifyParams } from "@out-of-home/location-search-string";

export const PAGE_SIZE = 32;

const registerClient = (storage: Storage, restClient: Fetch) => {
  storage.addEffect({
    effect: async (msg: BlogRequest) => {
      const params = {
        category: msg.params?.category || "",
        tag: msg.params?.tag || "",
        page: String(msg.page),
        limit: String(
          typeof msg.pageSize === "number" ? msg.pageSize : PAGE_SIZE
        ),
      };

      const data = await restClient(`/api/blog${stringifyParams(params)}`).then(
        (x) => x.json()
      );

      return ({
        tag: BLOG_LIST_LOAD_RESPONSE,
        data,
      }: BlogResponse);
    },
    subscribe: { [BLOG_LIST_LOAD_REQUEST]: true },
  });
};

export default registerClient;

/* @flow */

import type { Product } from "../../../types/product.flow";

import React from "react";
import { useFormat } from "../../../helpers/use-format";
import { useData } from "crustate/react";
import cn from "classnames";
import { CustomerData } from "../../../data";
import { useCustomer } from "../../../helpers/use-customer";
import { QtyPicker } from "../../QtyPicker";
import { ProductPrice } from "../../ProductPrice";
import { outputSalesUnit, compareParts } from "../../../helpers/productHelper";
import styles from "./styles.scss";

type Props = {
  product: Product,
  listName?: string,
};

export const Cells = ({ product, listName }: Props): React$Node => {
  const customerData = useData(CustomerData);
  const { formatPrice } = useFormat();

  const customer =
    customerData.state === "LOGGED_IN" ? customerData.data : null;
  const { attributes } = product;
  const comparePrice = compareParts(product, formatPrice);

  return (
    <div className={styles.cells}>
      <div className={cn(styles.cell, styles.cell__weight)}>
        {outputSalesUnit(attributes)}
      </div>
      <div className={cn(styles.cell, styles.cell__sku)}>
        #{attributes.supplierSku}
      </div>
      <div className={cn(styles.cell, styles.cell__comparePrice)}>
        {comparePrice.join("/")}
      </div>
      <div className={cn(styles.cell, styles.cell__tall, styles.cell__price)}>
        <ProductPrice price={product.price.exVat} />
      </div>
      <div className={cn(styles.cell, styles.cell__tall, styles.cell__double)}>
        <QtyPicker product={product} variant="detail" customer={customer} listName={listName} />
      </div>
    </div>
  );
};

/* @flow */

import type {
  Product,
  ProductCardProduct,
  ProductCategory,
  ProductDetailAbstract,
} from "../../types/product.flow";

import React, { useState, useEffect, useContext, useRef } from "react";
import cn from "classnames";
import { useData } from "crustate/react";
import { useLocation, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../helpers/analytics/context";
import { useTranslate } from "@out-of-home/use-translate";
import { CustomerData, useIsInWishlist } from "../../data";
import { getProductMeta, metaToHelmetProps } from "../../helpers/get-meta";
import { useWishlist } from "../../helpers/use-wishlist";
import { restProductToGQLProduct } from "../../helpers";
import { WishlistToggle } from "../WishlistToggle";
import { Wrapper } from "../Wrapper";
import { Excerpt } from "../Excerpt";
import { Media } from "../Media";
import { Button } from "../Button";
import { Section } from "../Section";
import { ProductPrice } from "../ProductPrice";
import { AlcoholMessage } from "../ProductCard/AlcoholMessage";
import { Breadcrumbs } from "../Breadcrumbs";
import {
  outputSalesUnit,
  getImgAlt,
  getImgTitle,
} from "../../helpers/productHelper";
import { useFormat } from "../../helpers/use-format";
import { CarbonCloud } from "./CarbonCloud";
import { Cells } from "./Cells";
import { Specification } from "./Specification";
import { MediaLabels } from "./MediaLabels";
import { JSONLDProduct } from "./jsonld-product";
import { UIContext } from "../../context/ui";
import { useRestClient } from "../../entrypoint/shared";
import styles from "./styles.scss";
import { PickYourPrice } from "./PickYourPrice";

type ProductViewProps = {
  product: {
    ...ProductDetailAbstract,
    attributes: {
      ...ProductDetailAbstract["attributes"],
      gtinBox: ?string,
      gtinPcs: ?string,
      salesUnit: ?string,
    }
  },
};

type ProductViewHintProps = {
  product: ProductCardProduct,
};

const isInStock = (x: ProductCardProduct) => x.oohStock && x.oohStock.qty > 0;

export const ProductView = (props: ProductViewProps): React$Node => {
  const product = props.product;
  const location = useLocation();
  const productInWishlist = useIsInWishlist(product.sku);
  const gaContext = useContext(AnalyticsContext);
  const { headerVisible, headerHeight } = useContext(UIContext);
  const scrollPastRef = useRef(null);
  const [scrolledPastForm, setScrolledPastForm] = useState(false);
  const attrLabels = product.attributeLabels;
  const customerData = useData(CustomerData);
  const { formatPrice } = useFormat();
  const sPrice = product.s_price;
  const brandInfo = product.attrDescriptions.brand;
  const restClient = useRestClient();
  const [similarProducts, setSimilarProducts] = useState<Array<ProductCardProduct | null>>([null, null, null, null]);

  const customer =
    customerData.state === "LOGGED_IN" ? customerData.data : null;
  const loggedIn = customerData.state === "LOGGED_IN";

  const { attributes } = product;
  const brand = attributes.brand;
  const t = useTranslate();
  const meta = getProductMeta(product, t);
  const { smallImage, largeImage } = attributes;

  const [currentImage, setCurrentImage] = useState({
    smallImage: smallImage ? { x1: smallImage.x1, x2: smallImage.x2 } : null,
    largeImage: largeImage ? { x1: largeImage.x1, x2: largeImage.x2 } : null,
  });

  useEffect(() => {
    restClient(`/api/products_similarProducts/${product.sku}`)
      .then(x => x.json())
      .then(x => {
        setSimilarProducts(
          x.products
            .map(restProductToGQLProduct)
            .filter(isInStock)
            .slice(0, 5)
        );
      });

    // TODO: unmount observer?
    const { current } = scrollPastRef;
    if (current) {
      const observer = new IntersectionObserver((entries) => {
        setScrolledPastForm(!entries[0].isIntersecting);
      });

      observer.observe(current);

      return () => {
        observer.unobserve(current);
      };
    }
  }, []);

  useEffect(() => {
    gaContext.registerProductDetailsView({
      sku: product.sku,
      name: product.name,
      price: product.price,
      qty: 1,
      attributes: {
        manufacturer: attributes.manufacturer,
      },
    });
  }, []);

  useEffect(() => {
    setCurrentImage({
      smallImage,
      largeImage,
    });
  }, [smallImage, largeImage]);

  return (
    <Wrapper className={styles.block}>
      <Helmet
        title={t("PRODUCT.TITLE", {
          name: product.name,
          brand: product.attributes.brand,
          product_type: product.attributes.oohProductType,
        })}
      >
        {metaToHelmetProps(t, "meta", meta.data)}
        {metaToHelmetProps(t, "link", meta.link)}
      </Helmet>

      <Breadcrumbs product={product} className={styles.breadcrumbs} />
      <div className={styles.container} style={{ "--header-height": `${headerHeight}px`}}>
        <aside className={styles.right}>
          <div
            className={cn(
              styles.rightInner,
              { [styles.active]: scrolledPastForm },
              { [styles.headerVisible]: headerVisible }
            )}
          >
            <Media
              className={styles.media}
              alt={getImgAlt(product)}
              title={getImgTitle(product)}
              image={currentImage}
              gallery={product.gallery}
              location={{
                ...location,
                state: location.state ? location.state : {},
              }}
            >
              <MediaLabels className={styles.mediaLabels} product={product} />
            </Media>

            <div ref={scrollPastRef} />
            <div className={styles.waypointPusher} />

            <div className={styles.stickyWrapper}>
              <div className={styles.stickyNameBrand}>
                <span className={styles.name}>{product.name}</span>

                {brand && (
                  <h2>
                    <Link to={`/p/_${brand}`} className={styles.brand}>
                      {brand}
                    </Link>
                  </h2>
                )}
              </div>

              <div className={styles.form}>
                <Cells product={product} listName="product_view" />

                {product.attributes.ooh3AlcoholRequirement === true &&
                  !customer?.hasAlcoholLicense && (
                    <AlcoholMessage className={styles.alcoholMessage}>
                      {t("ALCOHOL_LICENSE.PRODUCT_WARNING")}{" "}
                      {loggedIn && (
                        <Link
                          className={styles.link}
                          to={"/" + t("ROUTES.ACCOUNT")}
                        >
                          {t("ALCOHOL_LICENSE.CREATE")}
                        </Link>
                      )}
                    </AlcoholMessage>
                  )}
              </div>
            </div>
            {!loggedIn && sPrice !== null && <PickYourPrice sPrice={sPrice} price={product.price.exVat} />}
          </div>
        </aside>
        <main className={styles.left}>
          <header className={styles.header__inner}>
            <h1 className={styles.name}>{product.name}</h1>
            <Link
              className={styles.brand}
              to={{
                pathname: `/p/_${brand}`,
                state: {
                  hint: { type: "category", category: { name: brand } },
                },
              }}
            >
              <h2>
                {brand}
              </h2>
            </Link>
          </header>

          <div
            className={cn(styles.description, "typography")}
            dangerouslySetInnerHTML={{ __html: attributes.description }}
          />

          <div className="row--large">
            <h3 className={cn("h4", styles.subtitle)}>Produktspecifikation</h3>
            <Specification product={product} />
          </div>

          <CarbonCloud product={product} />

          <div className="row--large">
            <WishlistToggle
              className={styles.wishlistToggle}
              product={product}
              newApi={true}
            >
              <span className={styles.wishlistText}>
                {productInWishlist
                  ? t("PRODUCT.REMOVE_FAVORITE")
                  : t("PRODUCT.ADD_FAVORITE")}
              </span>
            </WishlistToggle>
          </div>

          <div className="row--large">
            <div className={styles.brandLabel}>
              {brandInfo !== null && brandInfo !== undefined && (
                <>
                  <h4>
                    {t("PRODUCT.ABOUT")}{" "}
                    <Link to={`/p/_${brandInfo.title}`}>{brandInfo.title}</Link>
                  </h4>
                  {brandInfo.description && <p>{brandInfo.description}</p>}
                  <img src={brandInfo.icon} alt={brandInfo.title} />
                </>
              )}
            </div>
          </div>
        </main>
      </div>

      {product.relatedProducts.items.length > 0 && (
        <div className="row--large">
          <Excerpt
            className="row--large"
            title={t("PRODUCT.RELATED_PRODUCTS_HEADING")}
            products={product.relatedProducts.items}
            productListProps={{
              listName: "product_view_related",
              modifier: "wide",
            }}
          />
        </div>
      )}

      {similarProducts.length > 0 && (
        <div className="row--large">
          <Excerpt
            className="row--large"
            title={t("PRODUCT.SIMILAR_PRODUCTS_HEADING")}
            products={similarProducts}
            productListProps={{
              listName: "product_view_related",
              modifier: "wide",
            }}
          />
        </div>
      )}

      {product.recommendedProducts.length > 0 && (
        <div className="row--large">
          <Excerpt
            className="row--large"
            title={t("PRODUCT.RECOMMENDED_PRODUCTS_HEADING")}
            products={product.recommendedProducts}
            url={`${t("ROUTES.RECOMMENDED")}/${t("ROUTES.PRODUCT")}/${
              product.url
            }`}
            productListProps={{
              listName: "product_view_recommended",
              modifier: "wide",
            }}
          />
        </div>
      )}

      <JSONLDProduct product={product} />
    </Wrapper>
  );
};

export const ProductViewHint = (props: ProductViewHintProps): React$Node => {
  const t = useTranslate();
  const product = props.product;
  const location = useLocation();
  const hintImage = location.state.hint?.image;
  const productInWishlist = useIsInWishlist(product.sku);
  const { headerVisible } = useContext(UIContext);
  const customerData = useData(CustomerData);
  const { formatPrice } = useFormat();

  const customer =
    customerData.state === "LOGGED_IN" ? customerData.data : null;
  const loggedIn = customerData.state === "LOGGED_IN";

  const { attributes } = product;
  const brand = attributes.brand;

  return (
    <Wrapper className={styles.block}>
      <Breadcrumbs product={product} className={styles.breadcrumbs} />
      <div className={styles.container}>
        <aside className={styles.right}>
          <div
            className={cn(styles.rightInner, {
              [styles.headerVisible]: headerVisible,
            })}
          >
            {hintImage && (
              <Media
                className={styles.media}
                image={{
                  smallImage: { x1: hintImage, x2: hintImage },
                  largeImage: { x1: hintImage, x2: hintImage },
                }}
                gallery={[]}
                location={{
                  ...location,
                  state: location.state ? location.state : {},
                }}
              >
                <MediaLabels className={styles.mediaLabels} product={product} />
              </Media>
            )}

            <div className={styles.waypointPusher} />
            <div className={styles.stickyWrapper}>
              <div className={styles.stickyNameBrand}>
                <span className={styles.name}>{product.name}</span>

                {brand && (
                  <Link to={`/p/_${brand}`} className={styles.brand}>
                    {brand}
                  </Link>
                )}
              </div>

              <div className={styles.form}>
                <Cells product={product} listName="product_view" />

                {product.attributes.ooh3AlcoholRequirement === true &&
                  !customer?.hasAlcoholLicense && (
                    <AlcoholMessage className={styles.alcoholMessage}>
                      {t("ALCOHOL_LICENSE.PRODUCT_WARNING")}{" "}
                      {loggedIn && (
                        <Link
                          className={styles.link}
                          to={"/" + t("ROUTES.ACCOUNT")}
                        >
                          {t("ALCOHOL_LICENSE.CREATE")}
                        </Link>
                      )}
                    </AlcoholMessage>
                  )}
              </div>
            </div>
          </div>
        </aside>
        <main className={styles.left}>
          <header className={styles.header__inner}>
            <h1 className={styles.name}>{product.name}</h1>
            <Link
              className={styles.brand}
              to={{
                pathname: `/p/_${brand}`,
                state: {
                  hint: { type: "category", category: { name: brand } },
                },
              }}
            >
              {brand}
            </Link>
          </header>

          <div
            className={cn(styles.description, "typography")}
            dangerouslySetInnerHTML={{ __html: attributes.shortDescription }}
          />
        </main>
      </div>
    </Wrapper>
  );
};

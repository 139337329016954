/* @flow */

import type { Context as ReactContext } from "react";

import React from "react";

export type Value = string | number;

export type Item = {
  index: number,
  value: Value,
  label: string,
};

export type Context = {
  onSelect: (Value) => void,
  value: ?Value,
  items: Array<Item>,
  searchString?: string,
};

const DropdownContext: React$Context<Context> = React.createContext({
  onSelect: () => undefined,
  value: null,
  items: [],
  searchString: "",
});

export default DropdownContext;

/* @flow */

import isWeekend from "date-fns/isWeekend";

export const getNextWeekDay = (date: Date): Date => {
  if (isWeekend(date)) {
    date.setDate(date.getDate() + ((1 + 7 - date.getDay()) % 7));
  }

  return date;
};

export const addTwoWeekDays = (date: Date): Date => {
  if (isWeekend(date) && date.getDay() !== 7) {
    getNextWeekDay(date);
  } else {
    date.setDate(date.getDate() + 2);
  }

  if (isWeekend(date)) {
    getNextWeekDay(date);
  }

  return date;
};

/* @flow */

import type { Level, Content } from "../../state/messages";

import React, { useState, useEffect } from "react";
import styles from "./styles.scss";
import cn from "classnames";
import { useData, useSendMessage } from "crustate/react";
import { useTranslate } from "@out-of-home/use-translate";
import { Wrapper } from "../Wrapper";
import { Foldable } from "../Foldable";
import { clearMessages } from "../../state/messages";
import { MessagesData } from "../../data";
import RemoveIcon from "../../icons/close.svg";
import { useBrowserLayoutEffect } from "../../helpers";

type MessageProps = {
  content: Content,
  level: Level,
  translated: boolean,
  deleting: boolean,
  onClick: () => void,
};

const Message = ({
  content,
  level,
  translated,
  deleting,
  onClick,
}: MessageProps) => {
  const t = useTranslate();
  const [open, setOpen] = useState(false);

  const text =
    typeof content === "string"
      ? t("STATUS_CODE." + content)
      : t("STATUS_CODE." + content.translationKey, content.variable);

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <Foldable
      open={!deleting && open}
      className={cn(styles.message, styles[level])}
      onClick={onClick}
    >
      <div className={styles.itemInner}>
        <div className={styles.itemPadding}>
          <Wrapper className={styles.wrapper}>
            <p>{translated && typeof content === "string" ? content : text}</p>
          </Wrapper>
          <RemoveIcon className={styles.icon} />
        </div>
      </div>
    </Foldable>
  );
};

const SystemMessages = (): React$Node => {
  const sendMessage = useSendMessage();
  const messages = useData(MessagesData);
  const [leaving, setLeaving] = useState(false);
  const [appearing, setAppearing] = useState(false);

  useBrowserLayoutEffect(() => {
    if (messages.length > 0) {
      setTimeout(() => {
        setAppearing(true);
      }, 0);
    } else {
      setAppearing(false);
    }
  }, [messages]);

  const closeAll = () => {
    setLeaving(true);
    setTimeout(() => {
      setLeaving(false);
      sendMessage(clearMessages());
    }, parseInt(styles.transition, 10));
  };

  return (
    <div
      className={cn({
        [styles.appearing]: appearing,
        [styles.leaving]: leaving,
      })}
    >
      {messages.length > 0 && <div className={styles.dim} onClick={closeAll} />}
      <div className={styles.block}>
        <div className={styles.messages}>
          {messages.map((x) => (
            <Message {...x} key={x.id} deleting={leaving} onClick={closeAll} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SystemMessages;

/* @flow */

import type { OverdueInvoice } from "../../types/order.flow";

import React, { useState, useEffect, memo } from "react";
import cn from "classnames";
import { useTranslate } from "@out-of-home/use-translate";
import { useData } from "crustate/react";
import { Dialogue } from "../Dialogue";
import { Button } from "../Button";
import { OverdueInvoicesData } from "../../data";
import DownloadIcon from "../../icons/download.svg";
import CloseIcon from "../../icons/close.svg";
import styles from "./styles.scss";
import { useBrowser } from "../../helpers/use-browser";
import { useFormat } from "../../helpers/use-format";
import { useCookie } from "../../helpers/use-cookie";
import { useOverdueInvoices } from "../../helpers/use-overdue-invoices";

type Props = {};

type ListProps = {
  className?: string,
  data: $ReadOnlyArray<OverdueInvoice>,
};

export const OverdueInvoiceList = ({
  data,
  className,
}: ListProps): React$Node => {
  const t = useTranslate();

  return (
    <ul className={cn(styles.list, className)}>
      {data.map((x) => (
        <li key={x.invoiceId}>
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: t("OVERDUE_INVOICE.DIALOGUE_LISTITEM", { ...x }),
              }}
            />
          </div>
          <div>
            <p>
              <a
                target="_blank"
                href={`/api/vismainvoice/getpdf?visma_invoice_id=${x.invoiceId}`}
              >
                <span>{t("ORDER.DOWNLOAD_INVOICE")}</span>
                <DownloadIcon className={styles.downloadIcon} />
              </a>
            </p>
          </div>
        </li>
      ))}
    </ul>
  );
};

export const OverdueInvoicesDialogue: React$AbstractComponent<Props, mixed> =
  memo((): React$Node => {
    const t = useTranslate();
    const [open, setOpen] = useState(false);
    const { data, gotOverdueInvoice } = useOverdueInvoices();
    const overdueInvoicesData = useData(OverdueInvoicesData);
    const browser = useBrowser();
    const domain = browser ? window.location.host.replace(/:\d+$/, "") : "";
    const [shown, setShown, cookieRead] = useCookie(
      "overdue-invoice-shown",
      "0",
      { domain }
    );

    const close = () => {
      setShown("1");
      setOpen(false);
    };

    useEffect(() => {
      if (cookieRead && shown === "0" && gotOverdueInvoice && !open) {
        setOpen(true);
      }
    }, [gotOverdueInvoice, cookieRead]);

    return (
      <>
        {open && (
          <Dialogue
            open={true}
            setOpen={close}
            title={t("OVERDUE_INVOICE.DIALOGUE_TITLE")}
            className={styles.dialogue}
            wrapperClassName={styles.dialogueWrapper}
          >
            <p
              dangerouslySetInnerHTML={{
                __html: t("OVERDUE_INVOICE.DIALOGUE_DESCRIPTION"),
              }}
            />
            <OverdueInvoiceList data={data} />
          </Dialogue>
        )}
      </>
    );
  });

/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type {
  OverdueInvoicesEffectRequest,
  OverdueInvoicesEffectResponse,
} from "../state/overdue-invoices";
import {
  OVERDUE_INVOICES_EFFECT_REQUEST,
  OVERDUE_INVOICES_EFFECT_RESPONSE,
} from "../state/overdue-invoices";

import { overdueInvoices } from "../queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async () => {
      try {
        const result = await client(overdueInvoices);

        return ({
          tag: OVERDUE_INVOICES_EFFECT_RESPONSE,
          data: result.overdueInvoices,
        }: OverdueInvoicesEffectResponse);
      } catch (e) {
        return ({
          tag: OVERDUE_INVOICES_EFFECT_RESPONSE,
          error: "Error when fetching overdue invoices",
        }: OverdueInvoicesEffectResponse);
      }
    },
    subscribe: { [OVERDUE_INVOICES_EFFECT_REQUEST]: true },
  });
};

export default registerClient;

/* @flow */

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Route } from "react-router";
import { useClient } from "../../entrypoint/shared";
import cn from "classnames";
import { useTranslate } from "@out-of-home/use-translate";
import { Footer } from "../Footer";
import { OrderHistoryItem, OrderHistoryItemMock } from "./Item";
import { Wrapper } from "../Wrapper";
import { Spinner } from "../Spinner";
import { Well } from "../Well";
import { useOverdueInvoices } from "../../helpers/use-overdue-invoices";
import styles from "./styles.scss";
import { useCustomer } from "../../helpers/use-customer";
import { customerOrders } from "../../queries";

export const OrderHistoryView = (): React$Node => {
  const t = useTranslate();
  const client = useClient();
  const {
    data: overdueInvoices,
    gotOverdueInvoice,
    loading: overdueInvoicesLoading,
  } = useOverdueInvoices();
  const customer = useCustomer();
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState(null);

  useEffect(() => {
    client(customerOrders).then((x) => {
      setOrders(x.customer.orders.items);
      setLoading(false);
    });
  }, []);

  if (loading === false && orders === null) {
    // error state. @TODO: how to handle?
    return null;
  }

  return (
    <>
      <Wrapper className={styles.block}>
        <Helmet title={t("ORDER_HISTORY.TITLE")} />

        {(loading || (Array.isArray(orders) && orders.length > 0)) && (
          <div className={styles.header}>
            <div className={cn(styles.headerColumn, styles.number)}>
              {t("ORDER.ORDER_NUMBER")}
            </div>
            <div className={cn(styles.headerColumn, styles.date)}>
              {t("ORDER.DATE")}
            </div>
            <div className={styles.headerColumn}>{t("ORDER.TOTAL")}</div>
            <div className={cn(styles.headerColumn, styles.shipment)}>
              {t("ORDER.SHIPMENT")}
            </div>
            <div className={cn(styles.headerColumn, styles.invoice)}>
              {t("ORDER.INVOICE")}
            </div>
            <div className={cn(styles.headerColumn, styles.dummy)}></div>
          </div>
        )}

        {Array.isArray(orders) && orders.length === 0 && !loading && (
          <>
            <h1>Orderhistorik</h1>
            <Well>Du har inte lagt några ordrar ännu.</Well>
          </>
        )}

        {loading || overdueInvoicesLoading || !orders
          ? [1, 2, 3, 4].map((x) => <OrderHistoryItemMock key={x} />)
          : orders.map((x) => (
              <OrderHistoryItem
                key={x.id}
                order={x}
                gotOverdueInvoice={gotOverdueInvoice}
              />
            ))}
      </Wrapper>
    </>
  );
};

/* @flow */

import type { PaymentMethod } from "../types/quote.flow";
import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

type Data =
  | {| state: "LOADING", data: Array<PaymentMethod> |}
  | {| state: "LOADED", data: Array<PaymentMethod> |};

export type PaymentMethodsResponse = Response<
  typeof PAYMENT_METHODS_LOAD_RESPONSE,
  Array<PaymentMethod>
>;

export type PaymentMethodsRequest = {
  tag: typeof PAYMENT_METHODS_LOAD_REQUEST,
};

export const PAYMENT_METHODS_LOAD_RESPONSE: "payment-methods/init/response" =
  "payment-methods/init/response";
export const PAYMENT_METHODS_LOAD_REQUEST: "payment-methods/init/request" =
  "payment-methods/init/request";

export const PaymentMethodsModel: Model<
  Data,
  {},
  PaymentMethodsRequest | PaymentMethodsResponse
> = {
  id: "payment-methods",
  init: () =>
    updateData(
      { state: "LOADING", data: [] },
      { tag: PAYMENT_METHODS_LOAD_REQUEST }
    ),
  update: (state: Data, msg) => {
    switch (msg.tag) {
      case PAYMENT_METHODS_LOAD_RESPONSE:
        if (msg.data && state.state === "LOADING") {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;
      default:
    }
  },
};

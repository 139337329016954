/* @flow */

import React, { useEffect, useContext } from "react";
import { AppHeader } from "../AppHeader";
import { Footer } from "../Footer";
import { CookieDialogue } from "../CookieDialogue";
import { Helmet } from "react-helmet-async";
import { Cart } from "../Cart";
import { useHistory, useLocation } from "react-router-dom";
import { UIContext } from "../../context/ui";
import { usePreserveScrollPosition } from "../../helpers/react-router-on-navigation";
import { metaToHelmetProps } from "../../helpers/get-meta";
import styles from "./styles.scss";
import { useTranslate } from "@out-of-home/use-translate";
import { useSendMessage } from "crustate/react";
import { clearMessages } from "../../state/stock-messages";
import { OverdueInvoicesDialogue } from "../OverdueInvoicesDialogue";
import { useCustomer } from "../../helpers/use-customer";


type Props = {
  children: React$Node,
  hideFooter?: boolean,
  hideHeader?: boolean,
};

export const App = ({ children, hideFooter = false, hideHeader = false, }: Props): React$Node => {
  const history = useHistory();
  const location = useLocation();
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const { loggedIn } = useCustomer();

  usePreserveScrollPosition(history);

  // Clear stock messages on navigation
  useEffect(() => {
    sendMessage(clearMessages());
  }, [location]);

  let pathname = location.pathname;
  if (pathname[0] === "/") {
    pathname = pathname.substr(1);
  }
  const parts = pathname.split("/").filter((x) => x);
  const langAlt = t("LOCALE.LONG") === "sv-SE" ? "no" : "se";
  const url = `https://outofhome.se/${pathname}`;
  const urlAlt = `https://outofhome.${langAlt}/${pathname}`;
  const atHomeView = pathname === "" && !loggedIn;

  const head = {
    meta: [
      { name: "description", content: t("HEAD.DESCRIPTION") },
      { prefix: "og: http://ogp.me/ns# fb: http://ogp.me/ns/fb#" },
      { property: "og:description", content: t("HEAD.DESCRIPTION") },
      { property: "og:site_name", content: t("HEAD.DEFAULT_SITE_NAME") },
      { property: "og:locale", content: t("LOCALE.LONG").replace("-", "_") },
      { property: "og:title", content: t("HEAD.DEFAULT_TITLE") },
      { property: "og:image", content: `${url}/assets/images/ooh_ring.png` },
      { property: "og:url", content: url },
      { property: "twitter:card", content: "summary" },
      { property: "twitter:site", content: "@outofhome_AB" },
      { property: "twitter:creator", content: "@outofhome_AB" },
      { name: "msapplication-config", content: "/favicons/browserconfig.xml" },
      { name: "theme-color", content: "#ffffff" },
    ],
    link: [
      {
        rel: "apple-touch-icon",
        sizes: "180x180",
        href: "/apple-touch-icon.png",
      },
      {
        rel: "icon",
        type: "image/png",
        href: "/favicon-32x32.png",
        sizes: "32x32",
      },
      {
        rel: "icon",
        type: "image/png",
        href: "/favicon-16x16.png",
        sizes: "16x16",
      },
      { rel: "manifest", href: "/manifest.json" },
      { rel: "shortcut icon", href: "/favicon.ico" },
      {
        rel: "alternate",
        hreflang: t("LOCALE.SHORT").toLowerCase(),
        href: url,
      },
      { rel: "canonical", href: url },
    ],
    script: [],
  };

  return (
    <div className={styles.block}>
      <Helmet titleTemplate={t("HEAD.TITLE_TEMPLATE").replace(":", "%")}>
        {metaToHelmetProps(t, "meta", head.meta)}
        {metaToHelmetProps(t, "link", head.link)}
        {metaToHelmetProps(t, "script", head.script)}
      </Helmet>
      {!hideHeader &&
        <AppHeader />
      }

      <div className={styles.body}>{children}</div>

      {!hideFooter && (
        <>
          <Footer className={atHomeView ? styles.homeviewFooter : ""} />
        </>
      )}

      <CookieDialogue />
      <OverdueInvoicesDialogue />
    </div>
  );
};

/* @flow */

import React from "react";
import styles from "./styles.scss";
import cn from "classnames";

type Props = {
  children?: React$Node,
  className?: string,
  variant?: string,
};

export const Spinner = ({
  className,
  children,
  variant = "",
}: Props): React$Node => {
  return (
    <div
      className={cn(
        className,
        styles.block,
        ...variant.split(" ").map((x) => styles["block__" + x])
      )}
    >
      <div className={styles.wrap}>
        <span className={styles.dot} />
        <span className={styles.dot} />
        <span className={styles.dot} />
        <span className={styles.dot} />
        <span className={styles.dot} />

        {children !== undefined && (
          <div className={styles.children}>{children}</div>
        )}
      </div>
    </div>
  );
};

/* @flow */

import React, { useEffect, useRef, useState } from "react";

type Props = {
  className?: string,
  zoom?: number,
  address: Array<string>,
};

//<script defer="defer" async="async" src="https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_KEY}&callback=initGoogleMaps"></script>

const MAX_TRIES = 10;
const GOOGLE_MAPS_KEY = "AIzaSyBGoKP9Vyj1sXY_fQ_Zln_TJTxkW3bvPOw";

const loadGoogleMaps = () => {
  const script = document.createElement("script");
  script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_KEY}&callback=initGoogleMaps`;
  document.body?.appendChild(script);
};

export const Map = ({ address, zoom, className }: Props): React$Node => {
  const [isLoading, setIsLoading] = useState(false);
  // Only load on client and if it's not loaded
  if (
    typeof globalGoogleMapsLoaded !== "undefined" &&
    !globalGoogleMapsLoaded &&
    !isLoading
  ) {
    loadGoogleMaps();
    setIsLoading(true);
  }

  if (process.env.NODE_ENV !== "production" && !address) {
    throw new Error("You need to pass a address to the <Map /> component");
  }

  const mapRef = useRef(null);

  const loadMap = (counter = 1) => {
    const map = mapRef.current;

    if (!map) {
      return;
    }

    if (!globalGoogleMapsLoaded) {
      if (counter > MAX_TRIES) {
        return;
      }

      setTimeout(() => {
        loadMap(counter + 1);
      }, counter * 100);
      return;
    }

    const geocoder = new google.maps.Geocoder();

    geocoder.geocode(
      {
        address: address.join(" "),
      },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const options = {
            zoom,
            center: results[0].geometry.location,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
          };

          const mapInstance = new google.maps.Map(map, options);

          if (results[0]) {
            /* eslint-disable no-new */
            new google.maps.Marker({
              map: mapInstance,
              position: results[0].geometry.location,
            });
            /* eslint-enable no-new */
          }
        }
      }
    );
  };

  useEffect(() => {
    loadMap();
  }, [mapRef]);

  return (
    <div ref={mapRef} style={{ background: "#eee" }} className={className} />
  );
};

Map.defaultProps = {
  zoom: 14,
};

/* @flow */

import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { useRestClient } from "../../../entrypoint/shared";
import cn from "classnames";
import styles from "./styles.scss";
import { useTranslate } from "@out-of-home/use-translate";
import { useModal } from "../../Modal";
import { throttle } from "@out-of-home/diskho";
import { UIContext } from "../../../context/ui";

type Props = {
  className?: string,
  setActive: boolean => void,
  active: boolean,
};

export const CategoryNav = ({ className, active, setActive }: Props): React$Node => {
  const t = useTranslate();
  const client = useRestClient();
  const [categoriesOpen, setCategoriesOpen] = useState(false);
  const [brandsOpen, setBrandsOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const CategoriesModal = useModal(categoriesOpen, setCategoriesOpen);
  const BrandsModal = useModal(brandsOpen, setBrandsOpen);
  const [tabsHeight, setTabsHeight] = useState(51);
  const { headerVisible, headerHeight } = useContext(UIContext);
  const tabsRef = useRef<?React$ElementRef<string>>();

  useEffect(() => {
    const el = tabsRef.current;

    if (!el) {
      return;
    }

    setTabsHeight(el.getBoundingClientRect().height);
  }, []);

  useEffect(() => {
    client(`/api/attributes/brand,main_category`)
      .then(x => x.json())
      .then((x: any) => {
        setBrands(x.brand.sort((a, b) => a.key.localeCompare(b.key)));
        setCategories(x.main_category);
      });
  }, []);

  useEffect(() => {
    if (!active) {
      setBrandsOpen(false);
      setCategoriesOpen(false);
    }
  }, [active]);


  const clickLink = () => {
    setCategoriesOpen(false);
    setBrandsOpen(false);
    setActive(false);
  };

  const toggleCategories = (e: Event) => {
    setBrandsOpen(false);
    setActive(!categoriesOpen);
    setCategoriesOpen(!categoriesOpen);
  };

  const toggleBrands = (e: Event) => {
    setCategoriesOpen(false);
    setActive(!brandsOpen);
    setBrandsOpen(!brandsOpen);
  };

  return (
    <div
      className={cn(styles.block, className)}
      style={{
        "--tabs-bottom": `${(headerVisible ? headerHeight : 0) + tabsHeight}px`,
        "--tabs-height": tabsHeight,
      }}
    >
      <div className={styles.tabs} ref={tabsRef}>
        <button
          className={cn(styles.title, {
            [styles.title__muted]: categoriesOpen,
          })}
          onClick={toggleCategories}
        >
          {t("NAV.CATEGORIES")}
        </button>
        <button
          className={cn(styles.title, { [styles.title__muted]: brandsOpen })}
          onClick={toggleBrands}
        >
          {t("NAV.BRANDS")}
        </button>
      </div>

      {categoriesOpen && (
        <ul className={styles.list}>
          {categories.map(x => (
            <li key={x.key} className={styles.item}>
              <Link
                onClick={toggleCategories}
                className={styles.link}
                onClick={clickLink}
                to={"/p/" + x.key}
              >
                {x.key}
              </Link>
            </li>
          ))}
        </ul>
      )}

      {brandsOpen && (
        <ul className={styles.list}>
          {brands.map(x => (
            <li key={x.key} className={styles.item}>
              <Link className={styles.link} onClick={clickLink} to={"/p/_" + x.key}>
                {x.key}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

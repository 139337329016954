/* @flow */

import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import cn from "classnames";
import { Button } from "../Button";
import styles from "./styles.scss";
import { UIContext } from "../../context/ui";

type Props<T: string | number> = {
  options: Array<{ value: T, label: string }>,
  value: ?T,
  onChange: T => void,
  className?: string,
};

const ERROR_MARGIN = 5;
const ELLIPSIS_BUTTON_WIDTH = 55;

const overflowingAt = (max, arr) => {
  let a = 0;

  if (arr.length === 0) {
    return 0;
  }

  for (let i = 0; i < arr.length; i++) {
    a += arr[i];

    if (i === 0) {
      a += ELLIPSIS_BUTTON_WIDTH;
    }

    if (a > max) {
      return Math.max(i, 1);
    }
  }

  return arr.length;
};

/*
 * A list of buttons or tabs. Will automatically collapse if wider than outer container.
 */
export const ButtonGroup = <T: string | number>({
  className,
  options,
  value,
  onChange,
}: Props<T>): React$Node => {
  const container = useRef<?HTMLElement>();
  const group = useRef<?HTMLElement>();
  const [containerWidth, setContainerWidth] = useState(9999);
  const [buttonWidths, setButtonWidths] = useState([99999]);
  const { browserWidth } = useContext(UIContext);
  const buttonsCombinedWidth = buttonWidths.reduce((a, c) => (a += c), 0);
  const numButtonsVisible = overflowingAt(containerWidth, buttonWidths);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const visibleButtons = options.filter((_, i) => i < numButtonsVisible);
  const hiddenButtons = options.filter((_, i) => i > numButtonsVisible - 1);
  const activeInHidden = hiddenButtons.some((x) => x.value === value);

  const calcButtonsWidths = (group) =>
    requestAnimationFrame(() => {
      if (group.current && group.current.childNodes.length > 0) {
        const c = group.current.childNodes;
        let widths = [];

        for (let i = 0; i < c.length; i++) {
          if (c[i] instanceof HTMLElement) {
            widths.push(Math.ceil(c[i].clientWidth + ERROR_MARGIN));
          }
        }

        setButtonWidths(widths);
      }
    });

  useLayoutEffect(() => {
    if (container.current) {
      setContainerWidth(container.current.clientWidth);
    }
  }, [container, browserWidth]);

  useLayoutEffect(() => {
    calcButtonsWidths(group);
  }, [group]);

  return (
    <div className={className} ref={container}>
      <div className={styles.radioGroup}>
        <div style={{ visibility: "hidden", position: "absolute" }} ref={group}>
          {options.map((x) => (
            <Button
              key={x.value}
              variant="primary"
              size="small"
              className={styles.nav__button}
            >
              {x.label}
            </Button>
          ))}
        </div>

        <div className={styles.buttons__visible}>
          {visibleButtons.map((x) => (
            <Button
              key={x.value}
              variant="primary"
              size="small"
              className={cn(styles.nav__button, {
                [styles.active]: value === x.value,
              })}
              onClick={(e) => {
                e.preventDefault();

                if (value !== x.value) {
                  onChange(x.value);
                }
              }}
            >
              {x.label}
            </Button>
          ))}

          {hiddenButtons.length > 0 && (
            <div style={{ display: "inline-block" }}>
              <div
                className={cn(styles.buttons__hidden, {
                  [styles.open]: dropdownOpen,
                })}
              >
                {hiddenButtons.map((x) => (
                  <Button
                    key={x.value}
                    variant="primary"
                    size="small"
                    className={cn(styles.dropdown__button, {
                      [styles.active]: value === x.value,
                    })}
                    onClick={(e) => {
                      e.preventDefault();

                      if (value !== x.value) {
                        onChange(x.value);
                        setDropdownOpen(false);
                      }
                    }}
                  >
                    {x.label}
                  </Button>
                ))}
              </div>
              <Button
                className={cn(styles.nav__button, {
                  [styles.active]: activeInHidden,
                })}
                variant="primary"
                size="small"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                …
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

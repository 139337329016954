/* @flow */

import type { DisplayMode } from "../../context/ui";

import React, { useContext } from "react";
import styles from "./styles.scss";
import cn from "classnames";
import ListIcon from "../../icons/list.svg";
import GridIcon from "../../icons/grid.svg";
import { useTranslate } from "@out-of-home/use-translate";
import { UIContext } from "../../context/ui";
import { useCustomer } from "../../helpers/use-customer";
import { useSendMessage } from "crustate/react";
import { setCustomerPreferredListLayout } from "../../state/customer";

type Props = {
  className?: string,
};

const MODE_LABELS = {
  LIST: "List View",
  GRID: "Grid View",
};

/**
 * For switching the product card listin between catalog and list mode.
 */
export const DisplayModeSwitcher = ({ className }: Props): React$Node => {
  const t = useTranslate();
  const { displayMode, setDisplayMode } = useContext(UIContext);
  const { loggedIn } = useCustomer();
  const sendMessage = useSendMessage();

  const set = (mode: DisplayMode) => {
    if (loggedIn) {
      sendMessage(setCustomerPreferredListLayout(MODE_LABELS[mode]));
    }

    setDisplayMode(mode);
  };

  return (
    <div className={cn(styles.block, className)}>
      <button
        className={cn("button", styles.button, {
          [styles["button__active"]]: displayMode === "LIST",
        })}
        aria-label="Visa produkter i en lista"
        onClick={() => set("LIST")}
      >
        <ListIcon className={styles.icon} />
      </button>
      <button
        className={cn("button", styles.button, {
          [styles["button__active"]]: displayMode === "GRID",
        })}
        aria-label="Visa produkter i ett rutnät"
        onClick={() => set("GRID")}
      >
        <GridIcon className={styles.icon} />
      </button>
    </div>
  );
};

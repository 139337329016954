// @flow

import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.scss";
import { zeroPrefix } from "../../helpers/deliveryCalendarHelpers";

type Props = {
  className?: string,
  minValue: number,
  maxValue: number,
  stepSize?: number,
};

const range = (
  start: number,
  end: number,
  stepSize: number = 1
): Array<number> => {
  const arr = new Array(Math.floor((end - start) / stepSize) + 1);

  for (let i = 0; i < arr.length; i++) {
    arr[i] = start + i * stepSize;
  }

  return arr;
};

export const Pips = ({
  minValue,
  maxValue,
  stepSize = 1,
}: Props): React$Node => {
  const [width, setWidth] = useState<number>(0);
  const element = useRef<HTMLElement | void | null>();

  const onResize = (e) => {
    setWidth(element.current?.getBoundingClientRect().width || 0);
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);

    setTimeout(() => {
      onResize();
    }, 10);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const pips = range(minValue, maxValue, stepSize).map((x, i) => ({
    number: x,
  }));

  const pipWidth = (width || 1) / pips.length;

  return (
    <div>
      <div
        className={styles.pips}
        ref={element}
        style={{
          margin: `0 -${pipWidth / 2}px`,
          visibility: width !== 0 ? "visible" : "hidden",
        }}
      >
        {pips.map((x) => (
          <span key={x.number} className={styles.pip}>
            <span className={styles.number}>{zeroPrefix(x.number)}</span>
          </span>
        ))}
      </div>
    </div>
  );
};

/** @flow  */


import { stripHTML } from "../../helpers";
import { useTranslate } from "@out-of-home/use-translate";
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

type ProductSlimmed = $ReadOnly<{
  name: string,
  attributes: $ReadOnly<{
    largeImage: ?$ReadOnly<{
      x1: string,
    }>,
    smallImage: ?$ReadOnly<{
      x1: string
    }>,
    description: string,
    supplierSku: string,
    brand: string,
    gtinBox: ?string,
    gtinPcs: ?string,
    salesUnit: ?string,
  }>,
  price: $ReadOnly<{
    exVat: number,
  }>,
}>;

type OfferJSONLD = {
  "@type": "Offer",
  priceCurrency: string,
  price: number,
  availability: "http://schema.org/InStock",
};

type BrandJSONLD = {
  "@type": "Brand",
  name: string,
}

type ProductJSONLD = {
  url: string,
  "@context": "http://schema.org/",
  "@type": "Product",
  sku: string,
  image: string[],
  name: string,
  description: string,
  gtin: string,
  offers: OfferJSONLD,
  brand: BrandJSONLD,
}

const NO_SUBMIT_REGEX = /^(?:02|04|2|98|99).*/;
const ONLY_DIGITS_REGEX = /^\d+$/;
export const JSONLDProduct = ({ product }: { product: ProductSlimmed }): React$Node => {
  const t = useTranslate();
  const location = useLocation();

  const innerHtml: ProductJSONLD = useMemo(() => {
    let gtin = "";

    if (product.attributes.salesUnit === "ST") {
      gtin = product.attributes.gtinPcs?.trim() || "";
    }

    if (product.attributes.salesUnit === "FP") {
      gtin = product.attributes.gtinBox?.trim() || "";
    }

    if (gtin.length > 0 && (NO_SUBMIT_REGEX.test(gtin) || !ONLY_DIGITS_REGEX.test(gtin))) {
      gtin = "";
    }

    return {
      "@context": "http://schema.org/",
      "@type": "Product",
      sku: product.attributes.supplierSku,
      image: [product.attributes.largeImage?.x1 || product.attributes.smallImage?.x1 || ""],
      url: "https://outofhome.se" + location.pathname,
      name: product.name,
      description: stripHTML(product.attributes.description),
      gtin,
      offers: {
        "@type": "Offer",
        priceCurrency: t("LOCALE.CURRENCY"),
        price: product.price.exVat,
        availability: "http://schema.org/InStock",
      },
      brand: {
        "@type": "Brand",
        name: product.attributes.brand,
      },
    };
  }, [product]);

  return (
    <Helmet
      script={[
        {
          type: "application/ld+json",
          innerHTML: JSON.stringify(innerHtml),
        },
      ]}
    />
  );
};

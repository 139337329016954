/* @flow */

import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useSendMessage } from "crustate/react";
import { useTranslate } from "@out-of-home/use-translate";
import styles from "./styles.scss";
import UploadIcon from "../../icons/upload.svg";
import { useFormField } from "@awardit/formaggio";

type Props = {
  className?: string,
  children?: Node,
  name: string,
  class?: string,
  errors?: Array<{}>,
  onChange?: Function,
  dirty?: boolean,
  submitted?: boolean,
};

type State = {
  active: boolean,
  title: ?string,
};

const Content = ({ title, active }: { title: ?string, active: boolean }) => {
  const t = useTranslate();

  if (title) {
    return <span>{title}</span>;
  }

  return (
    <span>
      <span className={styles.textDesktop}>
        {active ? (
          <span className={styles.info}>
            {t("FILE_UPLOAD.DESKTOP_TITLE__ACTIVE")}
          </span>
        ) : (
          <span
            className={styles.info}
            dangerouslySetInnerHTML={{ __html: t("FILE_UPLOAD.DESKTOP_TITLE") }}
          />
        )}
      </span>
      <span className={styles.textTouch}>
        <span className={styles.info}>{t("FILE_UPLOAD.TOUCH_TITLE")}</span>
      </span>
    </span>
  );
};

export const FileUpload = ({ children, name, ...props }: Props): React$Node => {
  const [active, setActive] = useState(false);
  const [title, setTitle] = useState<?string>(null);
  const { submitted, dirty, errors, inputProps } = useFormField(name, "");

  const onChange = (e: DOMEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files[0]) {
      setBlob(files[0]);
      setTitle(files[0].name);
    }
  };

  const setBlob = (file: File) => {
    setTitle(file.name);

    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      if (props.onChange) {
        props.onChange({ name: file.name, content: reader.result });
      }
    };
  };

  const onDrop = (e: DragEvent) => {
    e.preventDefault();

    setActive(false);

    const files = e.dataTransfer?.files || [];
    if (files[0]) {
      setBlob(files[0]);
    }
  };

  const onDragOver = (e: Event) => {
    e.preventDefault();
  };

  const onDragEnter = (e: Event) => {
    e.preventDefault();
    setActive(true);
  };

  const onDragLeave = (e: Event) => {
    e.preventDefault();
    setActive(false);
  };

  return (
    <div
      className={cn(
        styles.block,
        props.className,
        { [styles.active]: active },
        {
          [styles["block__error"]]:
            (Boolean(dirty) || submitted) && errors && errors.length > 0,
        }
      )}
    >
      <input
        className={styles.input}
        type="file"
        id={inputProps.id}
        onChange={onChange}
      />
      <label
        className={styles.label}
        htmlFor={inputProps.id}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
      >
        <UploadIcon className={styles.icon} />

        <Content title={title} active={active} />
      </label>
    </div>
  );
};

/* @flow */

import type {
  Product,
  ProductCardProduct,
  ProductCategory,
} from "../../../types/product.flow";

import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { useData } from "crustate/react";
import { useLocation, Link } from "react-router-dom";
import cn from "classnames";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@out-of-home/use-translate";
import { Prolabels } from "../../Prolabels";
import { Temperature, Alcohol } from "../../ProductComponents";
import {
  isCampaign,
  isNew,
  getDiscount,
  showFoodora,
} from "../../../helpers/productHelper";
import { useCustomer } from "../../../helpers/use-customer";

import FoodoraIcon from "../../../icons/foodora.svg";
import styles from "./styles.scss";

type Props = {
  product: Product,
  className?: string,
};

export const MediaLabels = ({ product, className }: Props): React$Node => {
  const t = useTranslate();
  const { customer } = useCustomer();
  const attributes = product.attributes;
  const isTimedCampaign = isCampaign(product);
  const isTimedNew = isNew(product);
  const discount = getDiscount(product);
  const msrp = attributes.msrp ?? product.price.exVat;
  const _showFoodora = showFoodora(product, customer);
  const campaignText = product.attributes.textCampaign
    ? product.attributes.textCampaign.replace(" 20", "\n20")
    : null;

  return (
    <div className={className}>
      <div className={styles.timed}>
        {_showFoodora && (
          <div className={styles.foodora}>
            <FoodoraIcon />
          </div>
        )}
        {campaignText && discount > 0 && (
          <div className={styles.campaignText}>{campaignText}</div>
          )}
        {isTimedNew && (
          <div className={styles.timed__new}>{t("PRODUCT.NEWS")}</div>
        )}
        {discount > 0 && (
          <div
            className={cn(styles.timed__discount, {
              [styles.timed__campaign]: isTimedCampaign,
            })}
          >
            {t("PRODUCT.DISCOUNT", { discount: discount })}
          </div>
        )}
      </div>

      <Temperature product={product} className={styles.temperature} />

      {customer !== null && customer.hasAlcoholLicense && (
        <Alcohol product={product} className={styles.alcohol} />
      )}

      {product.attrDescriptions && (
        <Prolabels
          className={styles.prolabels}
          itemClassName={styles.prolabel}
          labels={product.attributeLabels.certified}
        />
      )}
    </div>
  );
};

/* @flow */

import type { Storage } from "crustate";
import type {
  FrontpageRecommendedRequest,
  FrontpageRecommendedResponse,
} from "../state/frontpage-recommended";
import {
  FRONTPAGE_RECOMMENDED_LOAD_REQUEST,
  FRONTPAGE_RECOMMENDED_LOAD_RESPONSE,
} from "../state/frontpage-recommended";

const registerClient = (storage: Storage, restClient: Fetch) => {
  storage.addEffect({
    effect: async (msg: FrontpageRecommendedRequest) => {
      const { segment } = msg;
      if (!segment) {
        return ({
          tag: FRONTPAGE_RECOMMENDED_LOAD_RESPONSE,
          error: "no-segment",
        }: FrontpageRecommendedResponse);
      }

      const data = await restClient(
        `/api/products_customertype/${encodeURIComponent(
          segment.title
        )}?limit=10`
      ).then((x) => x.json());

      return ({
        tag: FRONTPAGE_RECOMMENDED_LOAD_RESPONSE,
        data,
      }: FrontpageRecommendedResponse);
    },
    subscribe: { [FRONTPAGE_RECOMMENDED_LOAD_REQUEST]: true },
  });
};

export default registerClient;

/* @flow */

import type { Testimonial } from "../types/testimonial.flow";

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

type Data =
  | { state: "LOADING" }
  | { state: "LOADED", data: Array<Testimonial> }
  | { state: "ERROR", error: string };

export type TestimonialsResponse = Response<
  typeof TESTIMONIALS_LOAD_RESPONSE,
  ?Array<Testimonial>
>;

export type TestimonialsRequest = {
  tag: typeof TESTIMONIALS_LOAD_REQUEST,
};

export const TESTIMONIALS_LOAD_RESPONSE: "testimonials/load/response" =
  "testimonials/load/response";
export const TESTIMONIALS_LOAD_REQUEST: "testimonials/load/request" =
  "testimonials/load/request";

export const load = (): TestimonialsRequest => ({
  tag: TESTIMONIALS_LOAD_REQUEST,
});

export const TestimonialsModel: Model<
  Data,
  {},
  TestimonialsRequest | TestimonialsResponse
> = {
  id: "testimonials",
  init: () =>
    updateData({ state: "LOADING" }, { tag: TESTIMONIALS_LOAD_REQUEST }),
  update: (state: Data, msg) => {
    switch (msg.tag) {
      case TESTIMONIALS_LOAD_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data && state.state === "LOADING") {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;
      default:
    }
  },
};

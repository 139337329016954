/* @flow */

import type { TBlogPostPartial } from "../../types/blog.flow";
import type { Params } from "../../state/blogList";

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { useData } from "crustate/react";
import { BlogListData } from "../../data";
import cn from "classnames";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import styles from "./styles.scss";
import { Wrapper } from "../Wrapper";
import { BlogPostCard } from "../BlogPostCard";
import { Button } from "../Button";
import { NotFoundView } from "../NotFoundView";
import { Spinner } from "../Spinner";
import ChevronIcon from "../../icons/chevron.svg";
import { useTranslate } from "@out-of-home/use-translate";
import {
  parseParams,
  stringifyParams,
} from "@out-of-home/location-search-string";

type Props = {
  children?: React$Node,
  className?: string,
  page: number,
  params: Params,
};

const useGetTitle = (filters?: Object) => {
  const t = useTranslate();

  if (!filters) {
    return "";
  }

  if (filters.category) {
    return filters.category;
  }
  if (filters.tag) {
    return t("NEWS.TAG_TITLE", { tag: "#" + filters.tag });
  }

  return t("NEWS.DEFAULT_TITLE");
};

export const BlogListView = ({ page, params }: Props): React$Node => {
  const { search } = useLocation();
  const searchParams = parseParams(search);
  const t = useTranslate();
  const blogList = useData(BlogListData);
  const title = useGetTitle(
    blogList.state === "LOADED" ? blogList.data.filters : null
  );
  const loading = blogList.state === "LOADING";
  const numPages = blogList.state === "LOADED" ? blogList.data.numPages : 1;

  if (blogList.state === "LOADED" && blogList.data.filters) {
    if (
      (params.tag && !blogList.data.filters.tag) ||
      (params.category && !blogList.data.filters.category)
    ) {
      return <NotFoundView />;
    }
  }

  return (
    <div className={cn(styles.block, "no-underline")}>
      <section className={cn(styles.blockInner, { [styles.loading]: loading })}>
        <Wrapper>
          <div className={styles.news}>
            <h1 className={styles.title}>{title}</h1>
            {blogList.state === "LOADED" &&
              blogList.data.posts.map((x) => (
                <BlogPostCard {...x} key={x.id} full={true} />
              ))}
          </div>
          <div className={styles.bottom}>
            {page > 1 && (
              <Button
                className={styles.prev}
                to={`/nyheter${stringifyParams({
                  ...searchParams,
                  page: String(page - 1),
                })}`}
                variant="ghost"
              >
                <ChevronIcon />
              </Button>
            )}
            {page < numPages && (
              <Button
                className={styles.next}
                to={`/nyheter${stringifyParams({
                  ...searchParams,
                  page: String(page + 1),
                })}`}
                variant="ghost"
              >
                <ChevronIcon />
              </Button>
            )}
          </div>
        </Wrapper>
      </section>

      <Helmet title={t("NEWS.NEWS") + " | " + title} />
    </div>
  );
};

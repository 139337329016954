/* @flow */

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

type Config = {
  scrollDelay?: number,
  smoothScrollIntoView: boolean,
};
export const useScrollTopOrElement = (config: ?Config, loading: boolean = false) => {
  const { pathname, hash, key } = useLocation();
  const scrollDelay: number = config?.scrollDelay || 0;

  useEffect(() => {
    if (loading) {
      return;
    }
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView(config?.smoothScrollIntoView ? { behavior: "smooth" } : undefined);
        }
      }, scrollDelay);
    }
  }, [pathname, hash, key, loading]); // do this on route change
};

/* @flow */

import type { QuotePayment } from "../../types/quote.flow";

import React, { useContext, useEffect, useState, useRef } from "react";
import cn from "classnames";
import { useSendMessage } from "crustate/react";
import { Button } from "../Button";
import { Well } from "../Well";
import { RadioField } from "../RadioField";
import { StripeContext, StripeCardComponent } from "../../stripe";
import { useData } from "crustate/react";
import { useTranslate } from "@out-of-home/use-translate";
import { Checkbox } from "../Checkbox";
import { Input } from "../Input";
import styles from "./styles.scss";
import { setOrderReference } from "../../state/quote";
import { useBrowser } from "../../helpers/use-browser";

import { QuoteData } from "../../data";

import VisaLogo from "../../icons/payment/visa.svg";
import MasterCardLogo from "../../icons/payment/mastercard.svg";
import AmexLogo from "../../icons/payment/amex.svg";
import { AnalyticsContext } from "../../helpers/analytics/context";

type Props = {
  processing: boolean,
  methods: Array<{
    code: string,
    title: string,
  }>,
  value: string | null,
  onChange: (code: string, ssn: string) => void,

  customerOrgNumber?: ?string,
};

const stripeStyles = {
  style: {
    base: {
      color: styles.textColor,
      letterSpacing: "0.025em",
      fontSize: "16px",
      "::placeholder": {
        color: styles.colorMuted,
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

export const PaymentMethods = ({
  processing,
  methods,
  value,
  onChange,
  ...props
}: Props): React$Node => {
  const browser = useBrowser();
  const sendMessage = useSendMessage();
  const t = useTranslate();
  const { registerAddPaymentInfo } = useContext(AnalyticsContext);
  const { paymentMode, setPaymentMode, canMakeButtonPayment } =
    useContext(StripeContext);
  const [settingPayment, setSettingPayment] = useState(null);
  const [showReference, setShowReference] = useState(false);
  const quoteData = useData(QuoteData);
  const quote = quoteData.state !== "LOADING" ? quoteData.data : null;
  const prevPayment = useRef<QuotePayment["code"] | null>(null);

  const [reference, setReference] = useState(quote?.orderReference || "");
  const [orgNumber, setOrgNumber] = useState(props.customerOrgNumber || "");
  const terms = true;
  const disabled = false;

  useEffect(() => {
    if (quoteData.state === "LOADED" && quoteData.data.orderReference) {
      setReference(quoteData.data.orderReference);
      setShowReference(true);
    }
  }, [quoteData.state]);

  // Update local ssn when customer ssn is changed
  useEffect(() => {
    setOrgNumber(props.customerOrgNumber || "");
  }, [props.customerOrgNumber]);

  useEffect(() => {
    setSettingPayment(null);
  }, [value]);

  useEffect(() => {
    setPaymentMode("CARD");
  }, []);

  // send event add_payment_info to GA on initial load and when updated
  useEffect(() => {
    if (quoteData.state === "LOADED") {
      const payment = quoteData.data.payment;
      const prevPaymentCode = prevPayment.current;

      if (payment && payment.code !== prevPaymentCode) {
        prevPayment.current = payment.code;
        registerAddPaymentInfo({ ...quoteData.data, payment })
      }
    }
  }, [quoteData.state]);

  if (!browser) {
    return null;
  }

  return (
    <div>
      <Well className={styles.methods}>
        {methods.map((x) => (
          <RadioField
            key={x.code}
            className={styles.method}
            value={x.title}
            checked={value === x.code}
            processing={settingPayment === x.code}
            disabled={processing}
            onChange={(e) => {
              if (!settingPayment && !processing && value !== x.code) {
                setSettingPayment(x.code);
                onChange(x.code, orgNumber);
              }
            }}
          />
        ))}
      </Well>
      <Well className={styles.openMethod}>
        <main className={styles.submitBlock}>
          <div>
            <Checkbox
              className={styles.referenceCheckbox}
              name="showReference"
              value={showReference}
              disabled={processing || disabled || !terms}
              onChange={(e) => {
                if (showReference) {
                  setReference("");
                  sendMessage(setOrderReference(""));
                }

                setShowReference(!showReference);
              }}
            >
              Använd referens
            </Checkbox>
          </div>

          {showReference && (
            <div className={styles.referenceInput}>
              <Input
                variant="box"
                label="Referens"
                value={reference}
                disabled={processing || disabled || !terms}
                onChange={(e) => {
                  setReference(e.target.value);
                }}
                onBlur={(e) => {
                  sendMessage(setOrderReference(reference));
                }}
              />
            </div>
          )}

          {value === "Crossroads_Collector" && (
            <>
              <div className={styles.referenceInput}>
                <p>
                  Företagsnamn måste stämma med org. nummer för att Walley skall
                  godkänna beställningen.
                </p>
                <Input
                  variant="box"
                  label="Organisationsnummer"
                  value={orgNumber}
                  required
                  onChange={(e) => setOrgNumber(e.target.value)}
                  onBlur={(e) => {
                    onChange(value, e.target.value);
                  }}
                />
              </div>
            </>
          )}
          {value === "Crossroads_Stripe_PaymentIntents" && (
            <>
              <div className={styles.stripeInputs}>
                <StripeCardComponent
                  Button={() => null}
                  disabled={processing || disabled || !terms}
                  processing={processing}
                  styles={styles}
                  options={stripeStyles}
                />
              </div>

              <footer className={styles.stripeFooter}>
                <span>Kortbetalning sker via Stripe</span>
                <div>
                  <MasterCardLogo />
                  <VisaLogo />
                  <AmexLogo />
                </div>
              </footer>
            </>
          )}
        </main>
      </Well>
    </div>
  );
};

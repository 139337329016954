/* @flow */

import React from "react";
import styles from "./styles.scss";
import cn from "classnames";
import CloseIcon from "../../icons/close.svg";

type Pill<T: string | number> = {
  +id: T | null,
  title: any,
  className?: string,
};

type Props<T: string | number> = {
  className?: string,
  items: Array<Pill<T>>,
  active: Array<T>,
  onClick: (Array<T>) => void,
};

export const MultiSelectPills = <T: string | number>({
  className,
  onClick,
  items,
  active,
}: Props<T>): React$Node => {
  if (!items) {
    return null;
  }

  // normalize to be able to pass both arrays and single strings

  let activeIds = [];
  for (const activeItem of active) {
    const ids = items.filter((x) => x.id === activeItem).map((x) => x.id);
    activeIds = [...activeIds, ...ids];
  }

  const filteredItems = items
    .filter((x) => x)
    .map((x) => ({ ...x, active: activeIds.includes(x.id) }));

  return (
    <div
      className={cn(styles.block, className, styles.multi, {
        [styles.active]: active.length > 0,
      })}
    >
      {filteredItems.map((x) => (
        <div
          key={String(x.id)}
          className={cn(styles.segment, x.className, {
            [styles.active]: x.active,
          })}
          onClick={(e) => {
            if (x.active) {
              onClick(active.filter((a) => a !== x.id));
            } else {
              if (x.id !== null) {
                onClick([...active, x.id]);
              }
            }
          }}
        >
          {x.title}
          {x.active && (
            <svg className={styles.icon} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="14" cy="14" r="14" fill="#FFD966"/>
              <rect x="8.88885" y="8" width="15.7134" height="1.25708" transform="rotate(45 8.88885 8)" fill="currentColor"/>
              <rect x="8" y="19.1113" width="15.7134" height="1.25708" transform="rotate(-45 8 19.1113)" fill="currentColor"/>
            </svg>
          )}
        </div>
      ))}
    </div>
  );
};

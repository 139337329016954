/* @flow */

import React, { useState, useContext, useEffect, useRef } from "react";
import cn from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import { parseParams } from "@out-of-home/location-search-string";
import Redirect from "@out-of-home/react-router-redirect";
import { sendJson } from "@out-of-home/fetch-utils";
import { useTranslate } from "@out-of-home/use-translate";
import { Helmet } from "react-helmet-async";
import { Wrapper } from "../Wrapper";
import { Input } from "../Input";
import { Button } from "../Button";
import { Field } from "../Field";
import { useSendMessage } from "crustate/react";
import { useRestClient, useClient } from "../../entrypoint/shared";
import { addMessage, clearMessages } from "../../state/messages";
import styles from "./styles.scss";
import { resetPassword } from "../../queries";
import { Form, rules, isRequired, match, isEmail } from "@awardit/formaggio";
import { isPassword } from "../../helpers/validationRules";

// @TODO: Some possible memory leak when unmounting?
export const ForgotPasswordView = (): React$Node => {
  const t = useTranslate();
  const client = useRestClient();
  const sendMessage = useSendMessage();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({ email: "" });
  const { push } = useHistory();

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    client(
      "/api/customer/forgotpassword",
      sendJson({ method: "POST", body: ({ email: state.email }: any) })
    )
      .then(() => {
        sendMessage(clearMessages());
        sendMessage(addMessage("FORGOT_PASSWORD", "success"));
        push("/");
      })
      .catch((err) => {
        sendMessage(clearMessages());
        if (err.errorCode === 3011) {
          sendMessage(addMessage("FORGOT_PASSWORD_UNKNOWN_EMAIL", "error"));
        } else {
          sendMessage(addMessage("UNKNOWN", "error"));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Wrapper className={styles.block} variant="thin">
      <h3 className="text-color--muted">{t("FORGOT_PASSWORD.TITLE")}</h3>

      <Form value={state} onChange={setState} onSubmit={submit}>
        <Field
          name="email"
          type="email"
          label={t("ADDRESS.EMAIL")}
          variant="box"
          required
        />

        <div className={cn("row", "row--large", styles.buttons)}>
          <Button
            className={styles.button}
            to="/"
            disabled={loading}
            variant="ghost"
            size="large"
            type="button"
          >
            {t("ACCOUNT.BACK")}
          </Button>
          <Button
            className={styles.button}
            loading={loading}
            type="submit"
            variant="primary"
            size="large"
            type="submit"
          >
            {t("FORGOT_PASSWORD.SEND")}
          </Button>
        </div>
      </Form>
      <Helmet title={t("FORGOT_PASSWORD.TITLE")} />
    </Wrapper>
  );
};

const newPasswordValidations = rules([
  isRequired("password"),
  isPassword("password"),
  match("password", "password_repeat"),
]);

export const NewPasswordView = (): React$Node => {
  const t = useTranslate();
  const { push } = useHistory();
  const client = useClient();
  const sendMessage = useSendMessage();
  const { search } = useLocation();
  const [state, setState] = useState({ password: "", passwordRepeat: "" });
  const [loading, setLoading] = useState(false);

  const params = parseParams(search);
  const token = String(params.token);
  const customer_id = String(params.id);

  if (!token || !customer_id) {
    return <Redirect to="/" />;
  }

  const errors = newPasswordValidations(state);

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    client(resetPassword, { token, password: state.password, customer_id })
      .then(({ resetPassword: { result } }) => {
        if (result !== "success") {
          sendMessage(clearMessages());
          sendMessage(addMessage(`RESET_PASSWORD.${result}`, "error"));
        } else {
          sendMessage(clearMessages());
          sendMessage(addMessage(`RESET_PASSWORD.${result}`, "success"));
          push("/");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Wrapper className={styles.block} variant="thin">
      <h3 className="text-color--muted">Välj nytt lösenord</h3>

      <Form
        value={state}
        onChange={(x) => setState((x: any))}
        onSubmit={submit}
        errors={errors}
      >
        <div className={cn("row", "row--large")}>
          <Field
            name="password"
            type="password"
            label={t("ACCOUNT.PASSWORD")}
            variant="box"
            required
          />
        </div>

        <div className={cn("row", "row--large")}>
          <Field
            name="password_repeat"
            type="password"
            label={t("ACCOUNT.PASSWORD_REPEAT")}
            variant="box"
            required
          />
        </div>

        <div className={cn("row", "row--large", styles.buttons)}>
          <Button
            className={styles.button}
            to="/"
            disabled={loading}
            variant="ghost"
            size="large"
            type="button"
          >
            {t("ACCOUNT.BACK")}
          </Button>
          <Button
            className={styles.button}
            loading={loading}
            type="submit"
            variant="primary"
            size="large"
            type="submit"
          >
            {t("FORGOT_PASSWORD.SEND")}
          </Button>
        </div>
      </Form>
      <Helmet title={t("FORGOT_PASSWORD.TITLE")} />
    </Wrapper>
  );
};

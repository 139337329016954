/* @flow */

import type {
  DeliveryDamageRows,
  WFMOrder,
  WFMOrderProduct,
} from "../../../../types/wfm.flow";

import React, { useState, useCallback } from "react";
import cn from "classnames";
import { Dropdown, DropdownItem } from "../../../Dropdown";
import { Input } from "../../../Input";
import { TextArea } from "../../../TextArea";
import { Button } from "../../../Button";
import { FileUpload } from "../../../FileUpload";
import { createTaskBodyDeliveryDamage, nextKeyId } from "../../helpers";
import { useCustomer } from "../../../../helpers/use-customer";
import { useTranslate } from "@out-of-home/use-translate";
import { useRestClient } from "../../../../entrypoint/shared";
import { OrderSelect } from "../../order-select";
import { Row } from "../../Row";
import { Field } from "../../../Field";
import { focusInvalidField } from "../../../../helpers/formHelpers";
import { addMessageTranslated } from "../../../../state/messages";
import styles from "./styles.scss";
import sharedStyles from "../../shared-styles.scss";
import { nestedRule } from "../../../../helpers/validationRules";
import { Form, rules, isRequired, isNumeric } from "@awardit/formaggio";
import { useSubmitCustomerServiceTask } from "../../../../helpers/use-submit-customer-service-task";

type Props = {
  class?: string,
  onSuccess: (title: string, text: string) => void,
};

type State = {
  items: DeliveryDamageRows,
  order: ?WFMOrder,
};

export const DeliveryDamage = (props: Props): React$Node => {
  const t = useTranslate();
  const [state, setState] = useState<State>({ items: {}, order: null });
  const [productToAdd, setProductToAdd] =
    useState<WFMOrderProduct | null>(null);
  const [processing, setProcessing] = useState(false);
  const onSuccess = useCallback(
    () =>
      props.onSuccess("Tack!", t("STATUS_CODE.WFM_DELIVERY_DAMAGE__SUCCESS")),
    [t]
  );
  const submit = useSubmitCustomerServiceTask(
    state,
    createTaskBodyDeliveryDamage,
    setProcessing,
    onSuccess
  );

  const renderRow = (id: string) => {
    const item = state.items[id];

    return (
      <Row
        key={id}
        id={id}
        headerTitle={item.product.name}
        rows={state.items}
        setState={(x) => setState({ ...state, items: x })}
      >
        <div>
          <div className={sharedStyles.inputRow}>
            <div>
              <Field
                label="Hur många är obrukbara?"
                name={`items.${id}.qty`}
                variant="box"
                type="number"
              />
            </div>
          </div>
          <div
            className={cn(sharedStyles.inputRow, sharedStyles.inputRow__tall)}
          >
            <div>
              <Field
                label="Övrig information"
                rows={7}
                name={`items.${id}.description`}
                variant="box"
                component={TextArea}
              />
            </div>
          </div>

          <div className={sharedStyles.inputRow}>
            <Field
              name={`items.${id}.image`}
              render={({ errors, ...props }) => (
                <FileUpload
                  {...props}
                  id={`items.${id}.image`}
                  className={styles.fileUpload}
                  name={`items.${id}.image`}
                  accept="application/pdf, image/*"
                  onChange={(x) => {
                    setState({
                      ...state,
                      items: {
                        ...state.items,
                        [id]: { ...item, imageName: x.name, image: x.content },
                      },
                    });
                  }}
                />
              )}
            />
          </div>
        </div>
      </Row>
    );
  };

  const validation = rules(
    Object.keys(state.items).map((x) =>
      rules([
        nestedRule(
          "items",
          rules([
            nestedRule(
              x,
              rules([
                isRequired("description"),
                isRequired("qty"),
                isNumeric("qty"),
                isRequired("image"),
              ])
            ),
          ])
        ),
      ])
    )
  );

  const errors = validation((state: any));

  const addRow = (e) => {
    e.preventDefault();

    if (productToAdd !== null) {
      setProductToAdd(null);
      setState({
        ...state,
        items: {
          ...state.items,
          [nextKeyId(state.items)]: {
            product: productToAdd,
          },
        },
      });
    }
  };

  return (
    <Form
      className={sharedStyles.type}
      value={(state: any)}
      errors={errors}
      onError={focusInvalidField}
      onChange={(x: any) => {
        setState({ ...state, items: x.items });
      }}
      onSubmit={submit}
    >
      {!state.order && (
        <div className="row--large">
          <OrderSelect
            order={state.order}
            setOrder={(x) => setState({ ...state, order: x })}
          />
        </div>
      )}

      {state.order && (
        <div className={"row--large"}>
          <div className="row--large">
            <div className={cn(sharedStyles.rows, "row--large")}>
              {Object.keys(state.items).map((k) => renderRow(k))}
            </div>

            {Object.keys(state.items).length > 0 && (
              <h2>Någon annan artikel?</h2>
            )}

            <div
              className={cn(
                sharedStyles.inputRow,
                sharedStyles.inputRow__shrinkLastChild,
                "row--large"
              )}
            >
              <Dropdown
                name="productToAdd"
                maxHeight={260}
                value={productToAdd?.sku}
                label="Artikel"
                headerContent={(x) => {
                  if (!productToAdd) {
                    return null;
                  }
                  const item = state.order?.items.find(
                    (a) => a.product.sku === productToAdd.sku
                  );

                  if (!item) {
                    return <span>Artikel</span>;
                  }

                  return (
                    <span>
                      {item.product.name}&nbsp;&nbsp;&nbsp;
                      <small>({item.product.attributes.supplierSku})</small>
                    </span>
                  );
                }}
                onChange={(x) => {
                  const item = state.order?.items.find(
                    (a) => a.product.sku === x.value
                  );

                  if (item) {
                    setProductToAdd(item.product);
                  }
                }}
              >
                {(state.order?.items || []).map((x) => (
                  <DropdownItem key={x.product.sku} value={x.product.sku}>
                    <span>
                      {x.product.name}&nbsp;&nbsp;&nbsp;
                      <small>({x.product.attributes.supplierSku})</small>
                    </span>
                  </DropdownItem>
                ))}
              </Dropdown>

              <Button variant="primary" onClick={addRow}>
                Lägg till
              </Button>
            </div>
          </div>
          <div className="row--large">
            {Object.keys(state.items).length > 0 && (
              <Button type="submit" loading={processing} variant="primary">
                Skicka
              </Button>
            )}
          </div>
        </div>
      )}
    </Form>
  );
};

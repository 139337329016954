/* @flow */

import type { Location } from "react-router-dom";

import { useState, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { parseParams } from "@out-of-home/location-search-string";

const useGetReturnUrl = (): ?string => {
  const location = useLocation();
  const { push } = useHistory();
  const [returnUrl, setReturnUrl] = useState<?string>(null);
  const params = parseParams(location.hash.replace("#logga-in", ""));

  if (typeof params.return_url === "string" && !returnUrl) {
    setReturnUrl(decodeURIComponent(params.return_url));
  }

  if (typeof params.return_url === "undefined" && returnUrl) {
    setReturnUrl(null);
  }

  return returnUrl;
};

const useCreateReturnUrl = (
  { pathname, search }: Location,
  loggedIn: boolean
): string => {
  return useMemo(() => {
    if (pathname === "/" || pathname === "/p" || loggedIn) {
      return "";
    }
    return `?return_url=${encodeURIComponent(pathname + search)}`;
  }, [pathname, search, loggedIn]);
};

export { useGetReturnUrl, useCreateReturnUrl };

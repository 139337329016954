/* @flow */

import React, { useState, useEffect } from "react";
import styles from "./styles.scss";
import cn from "classnames";
import CheckIcon from "../../icons/checkmark-circle.svg";

export type MeterType =
  | "freeshipping_colonial"
  | "freeshipping_frozen"
  | "quantity_discount";
type Props = {
  className?: string,
  percent: number,
  amount: number,
  hidden?: boolean,
  // to: number,
  // In percent
  height?: number,
  text: string,
  type: MeterType,
  hasTransition?: boolean,
  levelup?: boolean,
};

export const ANIMATION_TIME = 550;

export const Meter = ({
  hasTransition = true,
  levelup = false,
  ...props
}: Props): React$Node => {
  const [xScale, setXScale] = useState(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, ANIMATION_TIME);
  }, []);

  const { text, type, hidden, height } = props;
  const percent = mounted ? props.percent : 0;

  return (
    <div>
      <span
        className={cn(
          styles.text,
          { [styles["text__hidden"]]: hidden },
          { [styles["text__large"]]: height === 100 }
        )}
      >
        {percent === 100 && <CheckIcon className={styles.icon} />}
        {text}
      </span>

      <div
        className={cn(props.className, styles.block, styles["block__" + type], {
          [styles.hidden]: hidden,
        })}
        style={
          height !== undefined
            ? {
                maxHeight: `${height}%`,
              }
            : {}
        }
      >
        <div
          className={cn(
            styles.fill,
            { [styles.isTransitioning]: hasTransition },
            { [styles.levelup]: levelup }
          )}
          style={{
            transform:
              "scaleX(" + (xScale !== null ? xScale : percent / 100) + ")",
          }}
        >
          <div className={styles.animation} />
        </div>

      </div>
    </div>
  );
};

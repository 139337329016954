/* @flow */

import React, { useState, useEffect, useContext, useRef } from "react";
import { Range, getTrackBackground } from "react-range";
import cn from "classnames";
import styles from "./styles.scss";

type Props = {
  values: number[],
  step: number,
  min: number,
  max: number,
  ariaLabel: string,
  onChange: (values: number[]) => void,
  draggableTrack?: boolean,
};

const FG = "#ffd966";
const BG = "#d8d8d8";

export const RangeSlider = ({
  values,
  step,
  min,
  max,
  ariaLabel,
  onChange,
  ...props
}: Props): React$Node => {
  const percent = (value: number) => ((value - min) / (max - min)) * 100;

  const numSteps = (max - min + 1) / step;
  const slowTransition = numSteps < 8;

  let colors = [FG, BG];

  if (values.length === 2) {
    colors = [BG, FG, BG];
  }

  const transform =
    values.length === 1
      ? `
    scaleX(${percent(values[0])}%)
   `
      : `
    translateX(${percent(values[0])}%)
    scaleX(${percent(values[1]) - percent(values[0])}%)
  `;

  return (
    <Range
      step={step}
      min={min}
      max={max}
      values={values}
      onChange={onChange}
      {...props}
      renderTrack={({ props, children }) => (
        <div
          className={cn(styles.track, {
            [styles.slowTransition]: slowTransition,
          })}
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          style={{
            ...props.style,
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div className={styles.trackBg} />
          <div
            className={styles.trackFg}
            style={{
              position: "absolute",
              transformOrigin: "left",
              transform,
              background: FG,
              alignSelf: "center",
            }}
          />

          <div ref={props.ref} className={styles.trackInner}>
            {children}
          </div>
        </div>
      )}
      renderThumb={({ props, isDragged }) => (
        <div
          {...props}
          aria-label={ariaLabel}
          className={cn(styles.thumb, { [styles.dragged]: isDragged })}
          style={{
            ...props.style,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      )}
    />
  );
};

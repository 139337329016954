// @flow

import type { ProductDetailAbstract } from "../../../types/product.flow";

import React, { useMemo } from "react";
import { outputSalesUnit } from "../../../helpers/productHelper";
import styles from "../styles.scss";

type CarbonCloudProps = {
  product: {
    ...ProductDetailAbstract,
    attributes: {
      ...ProductDetailAbstract["attributes"],
      gtinBox: ?string,
      gtinPcs: ?string,
      salesUnit: ?string,
    },
  },
};

export const CarbonCloud = ({ product }: CarbonCloudProps): React$Node => {
  const carbonCloud = useMemo(() => product.carbonCloud, [product]);
  const salesUnit = outputSalesUnit(product.attributes);

  if (!carbonCloud) {
    return null;
  }

  return (
    <div className="row--large">
      <p className={styles.carbonCloudHeading}>
        Klimatpåverkan {product.name} {product.attributes.supplierSku ? "#" + product.attributes.supplierSku : ""}
      </p>
      <p className={styles.carbonCloudFootprint}>{String(carbonCloud.footprintPerKg).replace(".", ",")} kg CO₂e/kg</p>

      <p className={styles.carbonCloudParagraph}>
        Produktion av {product.name}
        {salesUnit !== "-" ? ` ${salesUnit}` : ""} från {product.attributes.brand} motsvarar ca{" "}
        {String(carbonCloud.footprint).replace(".", ",")} kg av koldioxidutsläpp. Mer information om produktens
        miljöpåverkan och uträkningen hos{" "}
        <a href={carbonCloud.url} rel="noopener noreferrer" target="_blank">
          Carbon Cloud
        </a>
        .
      </p>
    </div>
  );
};

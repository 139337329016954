// @flow

const breakpoints = {
  mini: 400,
  tiny: 580,
  small: 802,
  medium: 1057,
  large: 1312,
};

export default breakpoints;

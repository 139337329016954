/* @flow */

import type {
  Product,
  ProductCardProduct,
  ProductDetailAbstract,
} from "../types/product.flow";

import { useState, useEffect } from "react";
import { useData, useSendMessage } from "crustate/react";
import { addMessage } from "../state/messages";
import { addToWishlist, removeFromWishlist } from "../state/wishlist-toggle";
import { WishlistToggleData, WishlistData } from "../data";

type Option = { [key: string]: string };

type Props = {
  product: ProductDetailAbstract | ProductCardProduct,
  selected?: Option,
};

const getBuyRequest = (selected: Option) => {
  const keys = Object.keys(selected);

  for (const key of keys) {
    if (selected[key]) {
      return selected[key];
    }
  }

  return "";
};

export const useWishlist = ({
  product,
  selected,
}: Props): ({
  toggleWishlist: () => void,
  inWishlist: boolean,
  loading: boolean,
  loaded: boolean,
}) => {
  const wishlistData = useData(WishlistToggleData);
  const cache = wishlistData.data;
  const loading = wishlistData.state === "LOADING";
  const loaded = wishlistData.state === "LOADED";
  const sendMessage = useSendMessage();
  const [inWishlist, setInWishlist] = useState(product.wishlist.selected);

  useEffect(() => {
    if (cache[product.sku]) {
      setInWishlist(true);
    } else if (cache[product.sku] === null) {
      setInWishlist(false);
    }
  }, []);

  const toggleWishlist = () => {
    if (loading) {
      return;
    }

    // Is the product in the wishlist?, if so remove it
    if (inWishlist) {
      if (product.type === "configurable") {
        setInWishlist(false);
        sendMessage(
          removeFromWishlist(
            cache && cache[product.sku]
              ? cache[product.sku].itemId
              : product.wishlist.itemId,
            product.sku
          )
        );
      } else {
        setInWishlist(false);
        sendMessage(
          removeFromWishlist(
            cache && cache[product.sku]
              ? cache[product.sku].itemId
              : product.wishlist.itemId,
            product.sku
          )
        );
      }
    } else if (product.type === "configurable") {
      if (!selected || (selected && Object.keys(selected).length === 0)) {
        sendMessage(addMessage("WISHLIST_VARIANT", "error"));
        return;
      }

      const buyRequest = getBuyRequest(selected);

      if (buyRequest) {
        sendMessage(addToWishlist(buyRequest, product.sku));
        setInWishlist(true);
      }
    } else if (product.buyRequest) {
      sendMessage(addToWishlist(product.buyRequest, product.sku));
      setInWishlist(true);
    }
  };

  return { toggleWishlist, inWishlist, loading, loaded };
};

export const useWishlistProductCard = ({
  product,
}: Props): ({
  toggleWishlist: () => void,
  inWishlist: boolean,
  loading: boolean,
  loaded: boolean,
}) => {
  const wishlistData = useData(WishlistData);
  const wishlistProduct = wishlistData.data.items.find(
    (x) => x.product.sku === product.sku
  );
  const wishlistToggleData = useData(WishlistToggleData);
  const cache = wishlistToggleData.data;
  const loading = wishlistToggleData.state === "LOADING";
  const loaded = wishlistToggleData.state === "LOADED";
  const sendMessage = useSendMessage();
  const [inWishlist, setInWishlist] = useState(Boolean(wishlistProduct));

  useEffect(() => {
    if (cache[product.sku]) {
      setInWishlist(true);
    } else if (cache[product.sku] === null) {
      setInWishlist(false);
    }
  }, []);

  const toggleWishlist = () => {
    if (loading) {
      return;
    }

    // Is the product in the wishlist?, if so remove it
    if (inWishlist) {
      setInWishlist(false);
      sendMessage(
        removeFromWishlist(
          cache && cache[product.sku]
            ? cache[product.sku].itemId
            : wishlistProduct?.id || "",
          product.sku
        )
      );
    } else if (product.buyRequest) {
      sendMessage(addToWishlist(product.buyRequest, product.sku));
      setInWishlist(true);
    }
  };

  return { toggleWishlist, inWishlist, loading, loaded };
};

/** @flow */

import React from "react";
import cn from "classnames";
import CartIcon from "../../../icons/cart.svg";
import { useData } from "crustate/react";
import { QuoteData } from "../../../data";
import styles from "./styles.scss";

type Props = {
  cartOpen: boolean,
  setCartOpen: (boolean) => void,
  quoteItemsCount: number,
  pulse?: boolean,
};

export const Cart = ({ cartOpen, setCartOpen, quoteItemsCount, pulse = false }: Props): React$Node => {
  return (
    <div
      className={cn(styles.block, {
        [styles.open]: cartOpen,
        [styles.gotItems]: quoteItemsCount > 0,
        [styles.pulse]: pulse,
      })}
      role="button"
      aria-label="cart"
      onClick={() => setCartOpen(!cartOpen)}
    >
      <CartIcon />
      {quoteItemsCount > 0 && <span>{quoteItemsCount}</span>}
    </div>
  );
};

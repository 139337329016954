/* @flow */

import type { ProductCardProduct } from "../../types/product.flow";
import type { Customer } from "../../types/customer.flow";

import React, { useState, useContext, useEffect, useRef } from "react";
import styles from "./styles.scss";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useTranslate } from "@out-of-home/use-translate";
import { QtyPicker } from "../QtyPicker";
import { Temperature, Alcohol } from "../ProductComponents";
import { ProductCardWishlistToggle } from "../WishlistToggle";
import { Prolabels } from "../Prolabels";
import { StockMessage } from "./StockMessage";
import { AlcoholMessage } from "./AlcoholMessage";
import FoodoraIcon from "../../icons/foodora.svg";
import { useData } from "crustate/react";
import { useFormat } from "../../helpers/use-format";
import { useCurrentSrc } from "../../helpers";
import { CustomerData, useItemQty } from "../../data";
import { ProductLink as GALink } from "../../helpers/analytics/product-link";
// @TODO: ProductLink needs a gql product but product card is fetched from REST API
import { AnalyticsContext } from "../../helpers/analytics/context";
import { UIContext } from "../../context/ui";

import {
  outputAttribute,
  outputSalesUnit,
  stockStatus,
  compareParts,
  isCampaign,
  isNew,
  getDiscount,
  showFoodora,
} from "../../helpers/productHelper";

type Props = {
  className?: string,
  product: ProductCardProduct,
  image?: string,
  position?: number,
  listName?: string,
  displayMode: TDisplayMode,
  recommended?: boolean,
  price?: { exVat: number },
};

const breakpointSmall = parseInt(styles.breakpointSmall, 10);

type ProductLinkProps = {|
  product: ProductCardProduct,
  className?: string,
  children: React$Node,
  image?: ?string,
  listName?: string,
  position?: number,
|}

export const ProductLink: React$AbstractComponent<ProductLinkProps, mixed> = React.memo<ProductLinkProps>(
  ({
    product,
    className,
    children,
    image,
    listName,
    position
  }) => {
    return (
      <GALink
        listName={listName}
        position={position}
        product={product}
        className={cn(styles.linkable, className)}
        to={{
          pathname: product.url[0] === "/" ? product.url : "/" + product.url,
          state: {
            hint: {
              type: "product",
              product: {
                ...product,
                // @TODO: map things here on maybe in restProductToGQLProduct?
                attrDescriptions: [],
              },
              image,
            },
          },
        }}
      >
        {children}
      </GALink>
    );
  }
);

const ProductPrice = React.memo(({ price }) => {
  const { formatPrice } = useFormat();

  return <span className={styles.price}>{formatPrice(price)}</span>;
});

/**
 * Styles for a product item in a list of products.
 *
 * There"s 3 type of modifiers for the product card:
 *
 * GRID
 * LIST
 * COMPACT (used for mobile)
 *
 */

export const ProductCard: React$AbstractComponent<Props, mixed> = React.memo(
  ({
    product,
    image,
    listName,
    position,
    recommended = false,
    ...props
  }: Props) => {
    const imageRef = useRef();
    const qty = useItemQty(product.buyRequest);
    const t = useTranslate();
    // const gaRef = useRef();
    const gaContext = useContext(AnalyticsContext);
    const { formatDate, formatPrice } = useFormat();
    const customer = useData(CustomerData);
    const status = stockStatus(product);
    const stock = product.attributes.stockQty || 0;
    const exceeding =
      ((status === 2 || status === 8) && qty > stock) ||
      (status === 3 && qty === stock);
    const isTimedCampaign = isCampaign(product);
    const isTimedNew = isNew(product);
    const discount = getDiscount(product);
    const isContracted = product.oohContracted;
    const customerData = customer.data || null;
    const showFoodoraIcon = showFoodora(product, customerData);
    const campaignText = product.attributes.textCampaign
      ? product.attributes.textCampaign.replace(" 20", "\n20")
      : null;
    // const imageSrc = useCurrentSrc(imageRef, image);
    const imageSrc = image || product.attributes.smallImage?.x1 || "";

    const attributes = product.attributes;
    const brand = product.attributes && product.attributes.brand;
    const brandURL = `/p/_${decodeURIComponent(brand || "")}`;
    const comparePrice = compareParts(product, formatPrice);
    const { browserWidth } = useContext(UIContext);

    const isCompactView = browserWidth < breakpointSmall;
    const displayMode = isCompactView ? "COMPACT" : props.displayMode;
    const showAlcoholMsg =
      product.attributes.ooh3AlcoholRequirement && !customer.hasAlcoholLicense;
    const showExeedingMsg = exceeding;
    const price = props.price ? props.price.exVat : product.price.exVat;

    const classes = cn(
      "no-underline",
      props.className,
      { [styles.block__inCart]: qty > 0 },
      { [styles.block__recommended]: recommended },
      { [styles.hasStockMessage]: showAlcoholMsg || showExeedingMsg },
      styles.block,
      styles["block__" + displayMode.toLowerCase()]
    );

    if (displayMode === "LARGE") {
      return (
        <div key="LARGE" className={classes}>
          <div className={styles.image}>
            <img
              alt={product.name}
              ref={imageRef}
              width={375}
              height={375}
              src={product.attributes.featuredImage}
            />
          </div>
          <div className={styles.right}>
            <div className={styles.timed}>
              {showFoodoraIcon && (
                <div className={styles.foodora}>
                  <FoodoraIcon />
                </div>
              )}
              {isTimedNew && (
                <div className={styles.timed__new}>{t("PRODUCT.NEWS")}</div>
              )}
              {isContracted && (
                <div className={styles.timed__contracted}>
                  {t("PRODUCT.CONTRACTED__SHORT")}
                </div>
              )}
              {discount > 0 && (
                <div
                  className={cn(styles.timed__discount, {
                    [styles.timed__campaign]: isTimedCampaign,
                  })}
                >
                  {t("PRODUCT.DISCOUNT", { discount: discount })}
                </div>
              )}
            </div>
            <Prolabels
              className={styles.prolabels}
              itemClassName={styles.prolabel}
              labels={product.attributeLabels.certified}
            />
            <div className={styles.wrapper}>
              <div className={styles.top} product={product} position={position}>
                <ProductLink
                  image={imageSrc}
                  product={product}
                  position={position}
                  listName={listName}
                >
                  <h2 className={styles.name}>{product.name}</h2>
                </ProductLink>
                <div className={styles.brand}>
                  {brand && (
                    <Link to={brandURL} className={styles.brand}>
                      {brand}
                    </Link>
                  )}{" "}
                  {!brand && <span>&nbsp;</span>}
                </div>
                <ProductLink
                  image={imageSrc}
                  product={product}
                  position={position}
                  listName={listName}
                >
                  <div
                    className={cn(styles.description, "typography")}
                    dangerouslySetInnerHTML={{
                      __html: product.attributes.shortDescription,
                    }}
                  />
                </ProductLink>
              </div>
              <div className={styles.bottom}>
                <div className={cn(styles.cell, styles.cell__weight)}>
                  {outputSalesUnit(attributes)}
                </div>
                <div
                  className={cn(styles.cell, styles.cell__sku)}
                  title={"#" + (product.attributes.supplierSku || "")}
                >
                  <span>#{product.attributes.supplierSku}</span>
                </div>
                <div className={cn(styles.cell, styles.cell__comparePrice)}>
                  {comparePrice.join("/")}
                </div>
                <div className={cn(styles.cell, styles.cell__price)}>
                  <ProductPrice className={styles.price} price={price} />
                </div>
                <div className={cn(styles.cell, styles.cell__double)}>
                  <QtyPicker
                    product={product}
                    qty={qty}
                    position={position}
                    listName={listName}
                    customer={customerData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (displayMode === "LIST") {
      return (
        <div key="LIST" className={classes}>
          <ProductLink
            image={imageSrc}
            className={styles.media}
            product={product}
            position={position}
            listName={listName}
          >
            <img
              ref={imageRef}
              className={styles.image}
              src={image || product.attributes.smallImage?.x1}
              alt={product.name}
              width="35"
            />
          </ProductLink>

          <div className={styles.timed}>
            {isContracted && (
              <div className={styles.timed__contracted}>
                {t("PRODUCT.CONTRACTED__SHORT")}
              </div>
            )}
            {discount > 0 && (
              <div
                className={cn(styles.timed__discount, {
                  [styles.timed__campaign]: isTimedCampaign,
                })}
              >
                {t("PRODUCT.DISCOUNT", { discount: discount })}
              </div>
            )}
          </div>
          <div className={styles.wrapper}>
            {recommended && (
              <span className={styles.recommended}>
                {t("PRODUCT.RECOMMENDED")}
              </span>
            )}
            <div className={styles.top}>
              <div className={styles.flexrow}>
                <ProductLink
                  image={imageSrc}
                  className={styles.link}
                  product={product}
                  position={position}
                  listName={listName}
                >
                  <h2 className={styles.name}>{product.name}</h2>
                </ProductLink>
                <Prolabels
                  className={styles.prolabels}
                  itemClassName={styles.prolabel}
                  labels={product.attributeLabels.certified}
                />
              </div>
              <div className={styles.brandprice}>
                {brand && (
                  <Link to={brandURL} className={styles.brand}>
                    {brand}
                  </Link>
                )}
                <ProductPrice className={styles.price} price={price} />
              </div>
            </div>

            <div className={styles.bottom}>
              <div className={styles.cell}>{outputSalesUnit(attributes)}</div>

              <div
                className={cn(styles.cell, styles.cell__sku)}
                title={"#" + (product.attributes.supplierSku || "")}
              >
                <span>#{product.attributes.supplierSku}</span>
              </div>
              <div className={cn(styles.cell, styles.cell__double)}>
                <QtyPicker
                  product={product}
                  qty={qty}
                  position={position}
                  listName={listName}
                  customer={customerData}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else if (displayMode === "GRID") {
      return (
        <div key="GRID" className={classes}>
          <div className={styles.wrapper}>
            <div className={styles.top}>
              {qty > 0 ? <div className={styles.inCartMarker} /> : null}
              <ProductLink
                image={imageSrc}
                className={styles.topHover}
                product={product}
                position={position}
                listName={listName}
              >
                <div
                  className={cn(styles.description, "typography")}
                  dangerouslySetInnerHTML={{
                    __html: product.attributes.shortDescription,
                  }}
                />
                <div className={styles.topHoverBottom}>
                  <StockMessage
                    product={product}
                    qty={qty}
                    className={styles.stockMessage__hover}
                    exceeding={exceeding}
                  />
                  <ProductCardWishlistToggle
                    className={styles.wishlistToggle}
                    product={product}
                  />
                </div>
              </ProductLink>
              <ProductLink
                image={imageSrc}
                product={product}
                position={position}
                listName={listName}
              >
                <h2 className={styles.name}>{product.name}</h2>
              </ProductLink>
              <Link to={brandURL} className={styles.brand}>
                {brand ? brand : <span>&nbsp;</span>}
              </Link>

              <img
                ref={imageRef}
                className={styles.image}
                src={image || product.attributes.smallImage?.x1}
                alt={product.name}
                width="201"
                height="201"
              />

              <div className={styles.timed}>
                {showFoodoraIcon && (
                  <div className={styles.foodora}>
                    <FoodoraIcon />
                  </div>
                )}
                {campaignText && discount > 0 && (
                  <div className={styles.campaignText}>{campaignText}</div>
                )}
                {isTimedNew && (
                  <div className={styles.timed__new}>{t("PRODUCT.NEWS")}</div>
                )}
                {isContracted && (
                  <div className={styles.timed__contracted}>
                    {t("PRODUCT.CONTRACTED__SHORT")}
                  </div>
                )}
                {discount > 0 && (
                  <div
                    className={cn(styles.timed__discount, {
                      [styles.timed__campaign]: isTimedCampaign,
                    })}
                  >
                    {t("PRODUCT.DISCOUNT", { discount: discount })}
                  </div>
                )}
              </div>

              <Prolabels
                className={styles.prolabels}
                itemClassName={styles.prolabel}
                labels={product.attributeLabels.certified}
              />
              <Temperature product={product} className={styles.temperature} />
              {customerData?.hasAlcoholLicense && (
                <Alcohol product={product} className={styles.alcohol} />
              )}
              {exceeding && (
                <StockMessage
                  className={styles.stockMessage}
                  qty={qty}
                  product={product}
                  exceeding={exceeding}
                />
              )}
              {product.attributes.ooh3AlcoholRequirement &&
                !customerData?.hasAlcoholLicense && (
                  <AlcoholMessage className={styles.stockMessage}>
                    {t("ALCOHOL_LICENSE.PRODUCT_WARNING")}
                  </AlcoholMessage>
                )}
            </div>

            <div className={styles.bottom}>
              <div className={cn(styles.cell, styles.cell__weight)}>
                {outputSalesUnit(attributes)}
              </div>
              <div
                className={cn(styles.cell, styles.cell__sku)}
                title={"#" + (product.attributes.supplierSku || "")}
              >
                <span>#{product.attributes.supplierSku}</span>
              </div>
              <div className={cn(styles.cell, styles.cell__comparePrice)}>
                {comparePrice.join("/")}
              </div>
              <div className={cn(styles.cell, styles.cell__price)}>
                <ProductPrice className={styles.price} price={price} />
              </div>
              <div className={cn(styles.cell, styles.cell__double)}>
                <QtyPicker
                  product={product}
                  qty={qty}
                  position={position}
                  listName={listName}
                  customer={customerData}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else if (displayMode === "COMPACT") {
      return (
        <div key="COMPACT" className={classes}>
          <div className={styles.wrapper}>
            {recommended && (
              <span className={styles.recommended}>
                {t("PRODUCT.RECOMMENDED")}
              </span>
            )}
            <div className={styles.top}>
              <ProductLink
                image={imageSrc}
                className={styles.compactName}
                product={product}
                position={position}
                listName={listName}
              >
                <h2 className={styles.name}>{product.name}</h2>
              </ProductLink>
              <div className={styles.flexrow}>
                {brand && <span className={styles.brand}>{brand}</span>}
                <Prolabels
                  className={styles.prolabels}
                  itemClassName={styles.prolabel}
                  labels={product.attributeLabels.certified}
                />
              </div>

              <ProductLink
                image={imageSrc}
                className={styles.media}
                product={product}
                position={position}
                listName={listName}
              >
                <img
                  ref={imageRef}
                  className={styles.image}
                  src={image || product.attributes.smallImage?.x1}
                  alt={product.name}
                />
              </ProductLink>

              <div className={styles.timed}>
                {showFoodoraIcon && (
                  <div className={styles.foodora}>
                    <FoodoraIcon />
                  </div>
                )}
                {isTimedNew && (
                  <div className={styles.timed__new}>
                    {t("PRODUCT.NEWS__SHORT")}
                  </div>
                )}
                {isContracted && (
                  <div className={styles.timed__contracted}>
                    {t("PRODUCT.CONTRACTED__SHORT")}
                  </div>
                )}
                {discount > 0 && (
                  <div
                    className={cn(styles.timed__discount, {
                      [styles.timed__campaign]: isTimedCampaign,
                    })}
                  >
                    {t("PRODUCT.DISCOUNT", { discount: discount })}
                  </div>
                )}
              </div>
            </div>

            <div className={styles.bottom}>
              <div className={cn(styles.cell, styles.cell__weight)}>
                {outputSalesUnit(attributes)}
              </div>
              <div className={cn(styles.cell, styles.cell__price)}>
                <ProductPrice className={styles.price} price={price} />
              </div>
              <div className={cn(styles.cell, styles.cell__double)}>
                <QtyPicker
                  product={product}
                  qty={qty}
                  position={position}
                  listName={listName}
                  customer={customerData}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (process.env.NODE_ENV !== "production") {
      throw new Error('Unknown display mode "' + displayMode + '"');
    }

    return null;
  }
);

/* @flow */

import type { Validator } from "@awardit/formaggio";

import React, { useRef, useEffect, useState, useContext } from "react";
import cn from "classnames";
import { QuoteData, AlcoholLicensesData } from "../../../data";
import { Link, NavLink } from "react-router-dom";
import { TimeContext } from "../../../context/time";
import { useTranslate } from "@out-of-home/use-translate";
import { Button } from "../../Button";
import { useData, useSendMessage } from "crustate/react";
import SystemMessages from "../../SystemMessages";
import { focusInvalidField } from "../../../helpers/formHelpers";
import { AlcoholLicenseFieldset } from "../../AlcoholLicenseFieldset";
import { useCustomer } from "../../../helpers/use-customer";
import { add } from "../../../state/alcohol-licenses";

import {
  Form,
  rules,
  conditional,
  isRequired,
  isEmail,
} from "@awardit/formaggio";
import type { AlcoholLicenseInput } from "../../../types/alcohol-license.flow";

export type FormState = {
  year: string,
  month: string,
  day: string,
  filename: string,
  data: string,
};

type Props = {
  className?: string,
};

const required = ["year", "month", "day", "filename", "data"];

export const alcoholLicenseValidationRules: Array<Validator<any>> =
  required.map((x) => isRequired(x));

const validation = rules([
  ...alcoholLicenseValidationRules,
  isRequired("address"),
]);

export const AlcoholLicenseForm = ({ className }: Props): React$Node => {
  const t = useTranslate();
  const { now } = useContext(TimeContext);
  const sendMessage = useSendMessage();
  const { customer } = useCustomer();
  const alcoholLicensesData = useData(AlcoholLicensesData);
  const [state, setState] = useState<{
    filename: ?string,
    year: ?string,
    month: ?string,
    day: ?string,
    data: ?string,
    address: ?string,
  }>({
    filename: "",
    year: String(now.getFullYear()),
    month: String(now.getMonth() + 1),
    day: String(now.getDate()),
    data: null,
    address: null,
  });

  const errors = validation((state: any));
  const licenses = alcoholLicensesData.data || [];
  const processing = alcoholLicensesData.state !== "LOADED";
  const addresses = customer?.addresses || [];

  const submit = (e) => {
    e.preventDefault();

    const address = addresses.find((x) => x.id === state.address);

    if (
      address &&
      state.year &&
      state.filename &&
      state.data &&
      state.month &&
      state.day
    ) {
      sendMessage(
        add({
          address,
          filename: state.filename,
          data: state.data,
          validTo: `${state.year}-${state.month}-${state.day}`,
        })
      );
    }
  };

  return (
    <div className="row--huge">
      {licenses.length > 0 && (
        <p className="text-weight--bold">{t("ALCOHOL_LICENSE.HEADING")}</p>
      )}
      <Form
        value={(state: any)}
        errors={errors}
        onError={focusInvalidField}
        onChange={(x) => {
          setState({ ...state, ...(x: any) });
        }}
        onSubmit={submit}
      >
        <div className="row">
          <AlcoholLicenseFieldset
            data={state}
            setData={(x) => {
              setState({ ...state, ...x });
            }}
            addresses={addresses}
          />
        </div>
        <div className="row cf">
          <Button
            type="submit"
            className="pull--right"
            loading={processing}
            variant="ghost"
          >
            {t("ALCOHOL_LICENSE.SUBMIT")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

/** @flow */

import React from "react";
import { useEffect, useState, useContext, useRef } from "react";
import styles from "./styles.scss";
import PreHeader from "./PreHeader";
import { Wrapper } from "../Wrapper";
import { useCustomer } from "../../helpers/use-customer";
import { Search } from "../Search";
import SystemMessages from "../SystemMessages";
import { Link, useLocation } from "react-router-dom";
import { Logo } from "./logo";
import { Button } from "../Button";
import { Cart } from "./Cart";
import { Cart as AppCart } from "../Cart";
import { UIContext } from "../../context/ui";
import { MegaMenu } from "./MegaMenu";
import HamburgerIcon from "../../icons/hamburger.svg";
import UserIcon from "../../icons/user.svg";
import CloseIcon from "../../icons/close2.svg";
import { IsolatedBackground } from "./IsolatedBackground";
import { HamburgerMenu } from "./HamburgerMenu";
import { useTranslate } from "@out-of-home/use-translate";
import { useData, useSendMessage } from "crustate/react";
import { QuoteData } from "../../data";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "../../helpers/body-scroll-lock";
import { CategoryNav } from "./CategoryNav";
import { FilterAndSortTabs } from "./FilterAndSortTabs";
import { StoreInfoContext } from "../../entrypoint/shared";
import { LoginModal } from "../LoginModal";
import { clearMessages } from "../../state/messages";
import { usePrevious } from "../../helpers";
import { useAutoHide } from "./use-auto-hide";
import RemoveIcon from "../../icons/close.svg";
import { TimeContext } from "../../context/time";
import cn from "classnames";

type ActiveOverlay = "mega_menu" | "cart" | "search" | "hamburger_menu" | "category_nav";

const NotificationBar = ({ onClose, href }: { onClose: () => void, href?: string }) => {
  const Elem = href ? Link : "span";
  return (
    <div className={styles.notificationBar}>
      <Wrapper>
        <Elem className={cn(styles.notificationBar__text, styles.notificationBar__textLarge)} to={href || ""}>
          Kampanj kalla drycker – riktigt bra priser på läsk & iskaffe 24/6 - 7/7
        </Elem>
        <Elem className={cn(styles.notificationBar__text, styles.notificationBar__textSmall)} to={href || ""}>
          Kampanj kalla drycker – riktigt bra priser på läsk & iskaffe 24/6 - 7/7
        </Elem>
        <div className={styles.notificationBar__icon} onClick={onClose}>
          <RemoveIcon />
        </div>
      </Wrapper>
    </div>
  );
};

function usePulse(duration = 2500) {
  const [active, setActive] = useState<boolean>(false);
  const timer = useRef<TimeoutID | null>(null);

  function pulse() {
    setActive(true);

    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      setActive(false);
    }, duration);
  }

  return { pulse, active };
}

export const AppHeader = (): React$Node => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const customerData = useCustomer();
  const quoteData = useData(QuoteData);
  const [activeOverlay, setActiveOverlay] = useState<?ActiveOverlay>();
  const [headerTop, setHeaderTop] = useState<number>(0);
  const location = useLocation();
  const [cartOpen, setCartOpen] = useState<boolean>(false);
  const pulse = usePulse();
  const { setLoginModalOpen, loginModalOpen, showNotificationBar, setShowNotificationBar } = useContext(UIContext);
  const prevLoginModalOpen = usePrevious(loginModalOpen);
  const { translations } = useContext(StoreInfoContext);
  const headerRef = useRef<?React$ElementRef<"div">>();
  const [headerHeight, setHeaderHeight] = useState<number>(104);
  const { browserWidth, setHeaderHeight: setHeaderHeightContext } = useContext(UIContext);
  const quoteItemsCount = quoteData.data ? quoteData.data.items.length : 0;
  const prevQuoteItemsCount = useRef(quoteItemsCount);
  const { hidden, setHidden } = useAutoHide(headerHeight, cartOpen);
  const onMyFrontPage = location.pathname === "/" && customerData.loggedIn;
  const onFilterView =
    location.pathname === "/p" ||
    location.pathname === "/p/" ||
    location.pathname.startsWith("/p?") ||
    location.pathname.startsWith("/p/") ||
    location.pathname.startsWith("/" + translations.ROUTES.MY_PRODUCTS) ||
    location.pathname.startsWith("/" + translations.ROUTES.RECOMMENDED);

  // show header when count of products change
  useEffect(() => {
    if (quoteItemsCount > prevQuoteItemsCount.current) {
      setHidden(false);
      pulse.pulse();
    }

    prevQuoteItemsCount.current = quoteItemsCount;
  }, [quoteItemsCount]);

  // clear all scroll-locks when header is unmounted
  useEffect(() => () => clearAllBodyScrollLocks(), []);

  useEffect(() => {
    const onResize = () => {
      if (window.visualViewport) {
        setHeaderTop(window.visualViewport.offsetTop);
      }
    };

    window.visualViewport.addEventListener("resize", onResize, { passive: true });
    window.visualViewport.addEventListener("scroll", onResize, { passive: true });

    return () => {
      window.visualViewport.addEventListener("resize", onResize);
      window.visualViewport.addEventListener("scroll", onResize);
    };
  }, []);

  useEffect(() => {
    if (headerRef.current) {
      const boundingClientRect = headerRef.current.getBoundingClientRect();
      setHeaderHeight(boundingClientRect.height + boundingClientRect.top);
      setHeaderHeightContext(boundingClientRect.height);
    }
  }, [browserWidth, headerRef]);

  // Close stuff when location change
  useEffect(() => {
    setActiveOverlay();
    setLoginModalOpen(false);
  }, [location]);

  // lock scroll on body then anything in the header is open.
  // reserveScrollBarGap is set to true to prevent page from moving horizontally
  // when the scrollbar is removed.
  useEffect(() => {
    if (!headerRef.current) {
      return;
    }

    if (activeOverlay) {
      disableBodyScroll(headerRef.current, { reserveScrollBarGap: true });
    } else {
      enableBodyScroll(headerRef.current);
    }

    return () => {
      if (headerRef.current) {
        enableBodyScroll(headerRef.current);
      }
    };
  }, [activeOverlay]);

  // Close messages after closing login modal
  useEffect(() => {
    if (Boolean(prevLoginModalOpen) && !loginModalOpen) {
      sendMessage(clearMessages());
    }
  }, [prevLoginModalOpen, loginModalOpen]);

  const headerStyle: { [string]: string | number } = {
    "--header-height": `${headerHeight}px`,
    top: activeOverlay === "search" ? `${Math.abs(headerTop)}px` : "0px",
  };

  if (hidden && activeOverlay !== "search") {
    headerStyle["transform"] = `translateY(-${headerHeight}px)`;
  }

  return (
    <>
      {(activeOverlay === "mega_menu" || activeOverlay === "hamburger_menu" || activeOverlay === "category_nav") && (
        <IsolatedBackground onClick={() => setActiveOverlay()} />
      )}
      <PreHeader />
      {false && showNotificationBar && (
        <NotificationBar
          onClose={() => setShowNotificationBar(false)}
          href="/nyheter/kampanj-lask-iskaffe?utm_source=website&utm_medium=banner&utm_campaign=lask-iskaffe&utm_id=kalla-drycker"
        />
      )}
      <header className={styles.header} style={headerStyle}>
        <div ref={headerRef}>
          <Wrapper>
            <div className={styles.header_inner}>
              <div>
                <Link to="/" className={styles.logo} aria-label="Outofhome.se logo">
                  <Logo />
                </Link>
              </div>
              <div className={styles.search}>
                <Search
                  placement="desktop"
                  active={activeOverlay === "search"}
                  setActive={(value: boolean) => setActiveOverlay(value ? "search" : null)}
                />
              </div>
              <div className={styles.links}>
                {customerData.loggedIn && (
                  <Button className={styles.button} to="/konto">
                    <UserIcon />
                  </Button>
                )}

                {!customerData.loggedIn && (
                  <>
                    <Button
                      className={styles.buttonDesktop}
                      onClick={() => {
                        setLoginModalOpen(true);
                      }}
                    >
                      {t("NAV.LOGIN")}
                    </Button>
                    <Button className={styles.buttonDesktop} to={"/" + t("ROUTES.REGISTER")}>
                      {t("NAV.REGISTER")}
                    </Button>
                  </>
                )}

                <div className={styles.cartButton}>
                  <Cart
                    cartOpen={cartOpen}
                    setCartOpen={setCartOpen}
                    quoteItemsCount={quoteItemsCount}
                    pulse={pulse.active}
                  />
                </div>

                <Button
                  ariaLabel="hamburger menu button"
                  className={styles.buttonMobile}
                  onClick={() =>
                    setActiveOverlay((oldValue) =>
                      !oldValue || oldValue !== "hamburger_menu" ? "hamburger_menu" : null,
                    )
                  }
                >
                  {activeOverlay === "hamburger_menu" ? <CloseIcon /> : <HamburgerIcon />}
                </Button>
              </div>
            </div>
          </Wrapper>

          <div className={styles.megaMenu}>
            <MegaMenu
              active={activeOverlay === "mega_menu"}
              setActive={(value: boolean) => setActiveOverlay(value ? "mega_menu" : null)}
            />
          </div>

          <div className={styles.mobile}>
            <div className={styles.searchMobile}>
              <Wrapper>
                <Search
                  placement="mobile"
                  active={activeOverlay === "search"}
                  setActive={(value: boolean) => setActiveOverlay(value ? "search" : null)}
                />
              </Wrapper>
            </div>

            <div className={styles.hamburgerMenu}>
              {activeOverlay === "hamburger_menu" && (
                <HamburgerMenu loggedIn={customerData.loggedIn} close={() => setActiveOverlay(null)} />
              )}
            </div>
          </div>

          {cartOpen && (
            <Wrapper className={styles.appCartWrapper}>
              <AppCart setCartOpen={setCartOpen} />
            </Wrapper>
          )}

          {!loginModalOpen && <SystemMessages />}

          {loginModalOpen && <LoginModal open={loginModalOpen} setOpen={setLoginModalOpen} />}
        </div>
        {onMyFrontPage && (
          <CategoryNav
            active={activeOverlay === "category_nav"}
            setActive={(value: boolean) => setActiveOverlay(value ? "category_nav" : null)}
          />
        )}
        {onFilterView && <FilterAndSortTabs />}
      </header>
    </>
  );
};

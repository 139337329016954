/* @flow */

import type { Quote } from "../types/quote.flow";

import type { Model } from "crustate";
import type { Response } from "./util";
import type { EventCustomerLogin } from "./events";
import { updateData, updateNone } from "crustate";

import {
  EVENT_CUSTOMER_LOGIN,
  EVENT_CUSTOMER_LOGOUT,
  EVENT_PLACE_ORDER_SUCCESS,
} from "./events";
import { QUOTE_SYNC_RESPONSE } from "./quote";

/*
export type Item = {
  buyRequest: string,
  qty: number,
};
*/

export type CurrentItem = {
  itemBuyRequest: string,
  qty: number,
};

export type Item = {
  current: ?CurrentItem,
  requested: number,
};

type Data = { [buyRequest: string]: Item };

// Component messages
export type IncrementItemQty = {|
  tag: typeof INCREMENT_ITEM_QTY,
  buyRequest: string,
  qtyIncrement: number,
|};

export type SetItemQty = {|
  tag: typeof SET_ITEM_QTY,
  buyRequest: string,
  qty: number,
|};

export type RemoveByItemBuyRequest = {|
  tag: typeof REMOVE_BY_ITEM_BUY_REQUEST,
    itemBuyRequest: string,
|};

// Effect messages
export type InitRequest = {
  tag: typeof INIT_REQUEST,
};

export type QueueUpdates = {
  tag: typeof QUEUE_UPDATES,
  items: Data,
};

export type ItemsResponse = {
  tag: typeof ITEMS_RESPONSE,
  items: { [buyRequest: string]: ?CurrentItem },
};

// Component messages
const INCREMENT_ITEM_QTY: "quote-items/increment-item-qty" =
  "quote-items/increment-item-qty";
const SET_ITEM_QTY: "quote-items/set-item-qty" = "quote-items/set-item-qty";
const REMOVE_BY_ITEM_BUY_REQUEST: "quote-items/remove-by-item-buy-request" = "quote-items/remove-by-item-buy-request";

// Effect messages
export const INIT_REQUEST: "quote-items/init/request" =
  "quote-items/init/request";
export const QUEUE_UPDATES: "quote-items/queue-updates" =
  "quote-items/queue-updates";
export const ITEMS_RESPONSE: "quote-items/items-response" =
  "quote-items/items-response";

export const ITEMS_CLEAR: "quote-items/items-clear" = "quote-items/items-clear";

export const incrementItemQty = (
  buyRequest: string,
  qtyIncrement: number
): IncrementItemQty => ({
  tag: INCREMENT_ITEM_QTY,
  buyRequest,
  qtyIncrement,
});

export const setItemQty = (buyRequest: string, qty: number): SetItemQty => ({
  tag: SET_ITEM_QTY,
  buyRequest,
  qty,
});

export const removeByItemBuyRequest = (itemBuyRequest: string): RemoveByItemBuyRequest => ({
  tag: REMOVE_BY_ITEM_BUY_REQUEST,
  itemBuyRequest,
});

export const QuoteItemsModel: Model<
  Data,
  {},
  | IncrementItemQty
  | SetItemQty
  | ItemsResponse
  | InitRequest
  | EventCustomerLogin
> = {
  id: "quote-items",
  init: () => updateData({}, { tag: INIT_REQUEST }),
  update: (items: Data, msg) => {
    switch (msg.tag) {
      case EVENT_CUSTOMER_LOGIN:
        // @TODO: sync correctly on login
        return updateData({}, { tag: INIT_REQUEST });
      case INCREMENT_ITEM_QTY: {
        const newItems = {
          ...items,
          [msg.buyRequest]: items[msg.buyRequest]
            ? {
                current: items[msg.buyRequest].current,
                requested: Math.max(
                  items[msg.buyRequest].requested + msg.qtyIncrement,
                  0
                ),
              }
            : {
                current: null,
                requested: Math.max(msg.qtyIncrement, 0),
              },
        };

        if (
          newItems[msg.buyRequest].current == null &&
          newItems[msg.buyRequest].requested <= 0
        ) {
          return updateNone();
        }

        return updateData(newItems, { tag: QUEUE_UPDATES, items: newItems });

        /*
            const idx = oldRequestedItems.findIndex(({ buyRequest }: Item): bool => buyRequest === msg.buyRequest);

            if(idx >= 0) {
              const requestedItems = [
                ...oldRequestedItems.slice().splice(idx, -1),
                {
                  ...oldRequestedItems[idx],
                  qty: Math.max(oldRequestedItems[idx].qty + msg.qtyIncrement, 0),
                },
              ];

              return updateData({
                items: oldItems,
                requestedItems,
              }, { tag: QUEUE_UPDATES, items: requestedItems });
            }
            else {
              const idx = oldItems.findIndex(({ buyRequest }: Item): bool => buyRequest === msg.buyRequest);

              if(idx >= 0) {
                const requestedItems = [
                  ...oldRequestedItems,
                  {
                    buyRequest: msg.buyRequest,
                    qty: Math.max(oldItems[idx].qty + msg.qtyIncrement, 0),
                  },
                ];

                return updateData({
                  items: oldItems,
                  requestedItems,
                }, { tag: QUEUE_UPDATES, items: requestedItems });
              }
              else if(msg.qtyIncrement > 0) {
                const requestedItems = [
                  ...oldRequestedItems,
                  {
                    buyRequest: msg.buyRequest,
                    qty: msg.qtyIncrement,
                  },
                ];

                return updateData({
                  items: oldItems,
                  requestedItems,
                }, { tag: QUEUE_UPDATES, items: requestedItems });
              }
            }

            return updateNone();
            */
      }

      case SET_ITEM_QTY: {
        const newItems = {
          ...items,
          [msg.buyRequest]: items[msg.buyRequest]
            ? {
                current: items[msg.buyRequest].current,
                requested: Math.max(msg.qty, 0),
              }
            : {
                current: null,
                requested: Math.max(msg.qty, 0),
              },
        };

        return updateData(newItems, { tag: QUEUE_UPDATES, items: newItems });

        /*
            const idx = oldRequestedItems.findIndex(({ buyRequest }: Item): bool => buyRequest === msg.buyRequest);

            if(idx) {
              const requestedItems = [
                ...oldRequestedItems.slice().splice(idx, -1),
                {
                  buyRequest: msg.buyRequest,
                  qty: Math.max(msg.qty, 0),
                }
              ];

              return updateData({
                items: oldItems,
                requestedItems,
              }, { tag: QUEUE_UPDATES, items: requestedItems });
            }
            else if(msg.qty > 0) {
              const requestedItems = [
                ...oldRequestedItems,
                {
                  buyRequest: msg.buyRequest,
                  qty: Math.max(msg.qty, 0),
                },
              ];

              return updateData({
                items: oldItems,
                requestedItems,
              }, { tag: QUEUE_UPDATES, items: requestedItems });
            }

            return updateNone();
            */
      }

      case REMOVE_BY_ITEM_BUY_REQUEST: {
        const key = Object.keys(items).find((key) => items[key].current?.itemBuyRequest === msg.itemBuyRequest);

        if (!key) {
          return updateNone();
        }

        const item = items[key];
        item.requested = 0;

        const newItems = {
          ...items,
          [key]: item,
        };

        return updateData(newItems, { tag: QUEUE_UPDATES, items: newItems });
      }

      case ITEMS_RESPONSE: {
        // TODO: Revert requested when we error on some items
        let newItems = items;

        for (const key in msg.items) {
          if (msg.items[key]) {
            newItems = {
              ...newItems,
              [key]: {
                current: msg.items[key],
                requested: items[key]
                  ? items[key].requested
                  : msg.items[key].qty,
              },
            };
          } else {
            newItems = {
              ...newItems,
              [key]: {
                current: null,
                requested: items[key] ? items[key].requested : 0,
              },
            };
          }
        }

        for (const key in newItems) {
          if (newItems[key].requested === 0 && newItems.current == null) {
            if (newItems === items) {
              // Make sure to duplicate
              newItems = { ...newItems };
            }

            delete newItems[key];
          }
        }

        // TODO: Do we send update?
        return items === newItems ? updateNone() : updateData(newItems);

        /*
            const requestedItems = oldRequestedItems.filter(({ buyRequest: a, qty }) => {
              const item = items.find(({ buyRequest: b }) => a === b);

              return item && qty === 0 || !item || item.qty !== qty;
            });

            return updateData({ items, requestedItems });
            */
      }
      case EVENT_CUSTOMER_LOGOUT:
      case EVENT_PLACE_ORDER_SUCCESS: {
        return updateData({}, { tag: ITEMS_CLEAR });
      }
    }
  },
};

// @flow

import React, { memo, useContext } from "react";
import styles from "./styles.scss";
import cn from "classnames";
import { Button } from "../../Button";
import { useFormat } from "../../../helpers/use-format";
import { AnalyticsContext } from "../../../helpers/analytics/context";

type Props = { price: number, sPrice: number };

export const PickYourPrice: React$AbstractComponent<Props, mixed> = memo<Props>(({ price, sPrice }) => {
  const { formatPrice } = useFormat();
  const diff = price - sPrice;
  const diffPercent = Math.floor((diff / price) * 100);
  const { registerToChoosePrice } = useContext(AnalyticsContext);

  if (!sPrice || diffPercent < 5) {
    return null;
  }

  return (
    <div className={cn(styles.pickYourPrice, "row--large")}>
      <div className={styles.left}>
        <p>
          Spara upp till {formatPrice(diff)} ({diffPercent}%)
        </p>
        <p className={styles.price}>{formatPrice(price)}</p>
      </div>
      <div className={styles.right}>
        <Button to="/#register" onClick={async () => registerToChoosePrice()} variant="primary">
          Välj ditt pris
        </Button>
      </div>
    </div>
  );
});

/* @flow */

import type { Translations } from "@out-of-home/use-translate";
import type { Node as ReactNode } from "react";
import type {
  Client,
  GraphQLResponse,
  QueryRunner,
} from "@awardit/graphql-ast-client";
import type { StoreInfo } from "../types/storeInfo.flow";

import React, { useContext, createContext } from "react";

export type ClientProviderProps = {
  children: ReactNode,
  value: Client<{}>,
};

export type RestClientProviderProps = {
  children: ReactNode,
  value: Fetch,
};

export const StoreInfoContext: React$Context<StoreInfo> =
  createContext<StoreInfo>({
    info: {
      name: "",
      locale: "sv_SE",
      defaultDescription: "",
      titlePrefix: "",
      titleSuffix: "",
      defaultTitle: "",
      baseCurrencyCode: "",
      defaultCountry: {
        code: "SE",
      },
      countries: [],
      baseUrl: "",
      now: new Date(),
    },
    categories: [],
    brands: [],
    date: "",
    translations: {},
    footer: {
      main_category: [],
    },
    menuCategoryBlocks: {},
    customerTypes: [],
    vendors: [],
    productPreferences: [],
  });

export const createRunQuery =
  (fetch: typeof fetch, prefix: string): QueryRunner =>
  (request) =>
    fetch(`${prefix}/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    }).then(handleFetchResponse);

export const handleFetchResponse = (
  response: Response
): Promise<GraphQLResponse<mixed>> =>
  response.text().then((bodyText: string): GraphQLResponse<mixed> => {
    try {
      // Attempt to parse all, ignore response.ok
      return JSON.parse(bodyText);
    } catch (e) {
      const error = new Error(
        `JSON Parse error: ${
          e && typeof e.getMessage === "function" ? e.getMessage() : e
        }: ${bodyText}`
      );

      (error: any).bodyText = bodyText;
      (error: any).response = response;
      (error: any).statusCode = response.status;

      throw error;
    }
  });

const ClientContext = createContext<Client<{}> | null>(null);
const RestClientContext = createContext<Fetch | null>(null);

export const ClientProvider = ({
  children,
  value,
}: ClientProviderProps): React$Node => (
  <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
);

export const RestClientProvider = ({
  children,
  value,
}: RestClientProviderProps): React$Node => (
  <RestClientContext.Provider value={value}>
    {children}
  </RestClientContext.Provider>
);

export const useClient = (): Client<{}> => {
  const client = useContext(ClientContext);

  if (!client) {
    throw new Error(
      "useClient(): Usage must be wrapped in a <ClientProvider />."
    );
  }

  // $FlowIssue
  return client;
};

export const useRestClient = (): Fetch => {
  const client = useContext(RestClientContext);

  if (!client) {
    throw new Error(
      "useRestClient(): Usage must be wrapped in a <RestClientProvider />."
    );
  }

  // $FlowIssue
  return client;
};

export const APP_KEY = "root";

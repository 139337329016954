/* @flow */

import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import styles from "./styles.scss";
import { useTranslate } from "@out-of-home/use-translate";
import { StoreInfoContext } from "../../entrypoint/shared";
import { useData, useSendMessage } from "crustate/react";
import { locationToQuery } from "../../helpers/filterHelpers";
import { parseParams } from "@out-of-home/location-search-string";
import { FilterData } from "../../data";
import cn from "classnames";
import { throttle } from "@out-of-home/diskho";
import { Button } from "../Button";
import { Input } from "../Input";
import { Suggestions } from "../Suggestions";
import Icon from "../../icons/search.svg";
import CloseIcon from "../../icons/close.svg";
import { IsolatedBackground } from "../AppHeader/IsolatedBackground";

export const KEY_ESC = 27;
export const KEY_TAB = 9;

type Suggestion = {
  text: string,
  key: string,
  label?: string,
};

type Props = {
  className?: string,
  children?: React$Node,
  onSubmit?: Function,
  active: boolean,
  setActive: boolean => void,
  placement: string
};

export const Search = ({
  className,
  children,
  active,
  setActive,
  placement,
}: Props): React$Node => {
  const t = useTranslate();
  const location = useLocation();
  const { pathname, search } = location;
  const { push } = useHistory();
  const params = parseParams(search);
  const { search: searchQuery } = params;
  const [query, setQuery] = useState<string>(
    typeof searchQuery === "string" ? searchQuery : ""
  );
  const inputRef = useRef();
  const routes = useContext(StoreInfoContext).translations.ROUTES;

  const focus = () => {
    inputRef.current && inputRef.current.focus();
  };

  // Update search query when filter gets new search
  useEffect(() => {
    setQuery(typeof searchQuery === "string" ? searchQuery : "");
  }, [searchQuery]);

  const submit = (suggestion: Suggestion) => {
    const query = encodeURIComponent(suggestion.text);
    const key = suggestion.key;
    let search = "/p/?search=" + query;

    if (key !== "query") {
      search = `/p?${key}=${query}`;
    }

    setQuery("");
    push(search);

    inputRef.current && inputRef.current.blur();
    setActive(false);
  };

  const handleSubmit = (event: Event) => {
    event.preventDefault();

    if (!query) {
      focus();
      return;
    }

    submit({ text: query, key: "query" });
  };

  const onInput = (e: DOMEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const onSuggestionClick = (suggestion) => {
    submit(suggestion);
  };

  const selectInputText = () => {
    setActive(true);

    if (inputRef.current) {
      inputRef.current.setSelectionRange(0, inputRef.current.value.length);
    }
  };

  const onKeyDown = useCallback((event: KeyboardEvent) => {
    /**
     * Don"t do anything if a modifier key is pressed. shift is
     * excluded from this list since users may capitalize their
     * text when typing to get to an option.
     **/
    if (event.metaKey || event.ctrlKey || event.altKey) {
      return;
    }

    if ([KEY_ESC, KEY_TAB].includes(event.which)) {
      setActive(false);
      return;
    }

    setActive(true);
  }, [setActive]);


  const inputProps = {
    id: `search-input_${placement}`,
    name: "query",
    type: "search",
    value: query,
    autoComplete: "off",
    className: styles.input,
    wrapperClassName: styles.inputWrapper,
    placeholder: t("SEARCH.PLACEHOLDER"),
    onFocus: selectInputText,
    onInput: onInput,
    onKeyDown: onKeyDown,
    forwardedRef: inputRef,
  };

  return (
    <div className={cn(styles.block, className)}>
      {active && <IsolatedBackground onClick={() => setActive(false)} zIndex={2} />}
      <form onSubmit={handleSubmit} autoComplete="off" className={styles.form}>
        <Input
          {...inputProps}
          slotRight={
            <Button
              className={styles.submit}
              variant="primary"
              type="submit"
              ariaLabel="Genomför sökning"
            >
              <Icon className={styles.submitIcon} />
            </Button>
          }
        />
      </form>


      <div className={styles.suggestions}>
        {query && query !== params.search && (
          <Suggestions
            active={active}
            query={query}
            limit={5}
            onFetch={() => {}}
            onReset={() => {}}
            onClick={onSuggestionClick}
          />
        )}
      </div>
    </div>
  );
};

/* @flow */

import React, { useContext, useEffect } from "react";
import { Route, Switch } from "react-router";
import Redirect from "@out-of-home/react-router-redirect";
import { Link } from "react-router-dom";
import {
  RouteData,
  CustomerData,
  AllProductsData,
  BlogData,
  BlogListData,
  FilterData,
  BrandsData,
  TestimonialsData,
  PaymentMethodsData,
  MyProductsData,
  OrderData,
  DeliveryScheduleData,
} from "../data";
import { useTranslate } from "@out-of-home/use-translate";
import { useHistory } from "react-router-dom";
import { useSendMessage } from "crustate/react";
import { useData } from "crustate/react";
import { App } from "../components/App";
import {
  parseParams,
  stringifyParams,
} from "@out-of-home/location-search-string";
import { CmsView } from "../components/CmsView";
import { NotFoundView } from "../components/NotFoundView";
import { BlogPostView } from "../components/BlogPostView";
import { BlogListView } from "../components/BlogListView";
import { FilterView, getPageSize } from "../components/FilterView";
import {
  ForgotPasswordView,
  NewPasswordView,
} from "../components/ForgotPasswordView";
import { BrandsView } from "../components/BrandsView";
import { HomeView } from "../components/HomeView";
import { Route as WFMView } from "../components/WFM";
import { ProductView, ProductViewHint } from "../components/ProductView";
import { RegisterView } from "../components/RegisterView";
import { CustomerServiceView } from "../components/CustomerServiceView";
import { AccountView } from "../components/AccountView";
import { SupplierInquiryView } from "../components/SupplierInquiryView";
import { OrderHistoryView } from "../components/OrderHistoryView";
import { OrderHistoryDetailView } from "../components/OrderHistoryDetailView";
import { MyFrontPageView } from "../components/MyFrontPageView";
import { useCustomer } from "../helpers/use-customer.js";
import { AboutUsView } from "../components/AboutUsView";
import { ReferenceCustomersView } from "../components/ReferenceCustomersView";
import { EmployeesView } from "../components/EmployeesView";
import { CheckoutView } from "../components/CheckoutView";
import { MyProductsView } from "../components/MyProductsView";
import { SuccessView } from "../components/SuccessView";
import { locationToQuery } from "../helpers/filterHelpers";
import { StoreInfoContext } from "./shared";
import { UIContext } from "../context/ui";
import { addMessage } from "../state/messages";
import { useRedirectCustomerOnLostSession } from "../helpers/use-redirect-customer-on-lost-session";
import { useRedirectCustomerOnMissingCustomerType } from "../helpers/use-redirect-customer-on-missing-customer-type";
import { useGetReturnUrl } from "../helpers/return-url";

/*
import PopularView from "../components/PopularView";
import { locationWithDefaults } from "../helpers/use-update-product-list";
*/

const locationWithDefaults = (x) => x;

type RoutesProps = {
  location: Location,
  history: History,
};

type RouteViewProps = {
  location: Location,
  history: History,
};

const ForgotPasswordCallback = () => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const history = useHistory();

  React.useEffect(() => {
    sendMessage(addMessage("FORGOT_PASSWORD_SUCCESS", "success"));
    history.push("/");
  }, []);

  return null;
};

const AuthRoute = (props) => {
  const customerData = useData(CustomerData);
  const history = useHistory();

  if (customerData.state === "NOT_LOGGED_IN") {
    history.push("/");
    return null;
  }

  return <Route {...props} />;
};

const RouteView = ({ location, history }: RouteViewProps): React$Node => {
  const data = useData(RouteData);

  if (data.state === "LOADING") {
    if (data.hint) {
      switch (data.hint.type) {
        case "product":
          return <ProductViewHint product={data.hint.product} />;
        case "category":
          return <NotFoundView />;
        case "cms_page":
          return <CmsView cmsPage={data.hint.cmsPage} />;
        default:
      }
    }

    return null;
    // return <LoadingView />;
  }

  if (!data.route) {
    // TODO: Check error msg
    return <NotFoundView />;
  }

  switch (data.route.type) {
    case "product":
      return <ProductView product={data.route.product} />;
    case "category":
      return <NotFoundView />;
    case "cms_page":
      return <CmsView cmsPage={data.route.cmsPage} />;
    case "redirect":
      return <Redirect to={data.route.url} />;
    default:
      return null;
  }
};

const RoutesInner = ({ location, history }: RoutesProps): React$Node => {
  const t = useTranslate();
  const { loggedIn } = useCustomer();
  const { translations } = useContext(StoreInfoContext);
  const { browserWidth } = useContext(UIContext);
  const sendMessage = useSendMessage();
  const filterCache = React.useRef({});
  const returnUrl = useGetReturnUrl();

  useRedirectCustomerOnLostSession();
  useRedirectCustomerOnMissingCustomerType();

  if (loggedIn && returnUrl) {
    return (
      <Switch>
        <Redirect to={returnUrl} />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route
        path="/checkout/success"
        render={() => (
          <App>
            <OrderData.Provider>
              <SuccessView />
            </OrderData.Provider>
          </App>
        )}
      />

      <Route
        path="/checkout"
        render={({ location }) => (
          <DeliveryScheduleData.Provider>
            <PaymentMethodsData.Provider>
              <CheckoutView />
            </PaymentMethodsData.Provider>
          </DeliveryScheduleData.Provider>
        )}
      />

      <Route
        path={["/registrera/:step", "/registrera"]}
        render={() => (
          <App hideFooter hideHeader>
              <RegisterView />
          </App>
        )}
      />

      <App>
        <Switch>
          <Route
            path="/"
            exact
            render={() => (
              <BlogListData.Provider name="bloglist_home" page={1} pageSize={6}>
                  <BrandsData.Provider>
                    <TestimonialsData.Provider>
                      {!loggedIn /*|| animationState === POSTLOGIN*/ ? (
                        <HomeView />
                      ) : (
                        <MyFrontPageView />
                      )}
                    </TestimonialsData.Provider>
                  </BrandsData.Provider>
              </BlogListData.Provider>
            )}
          />

          <AuthRoute
            path={"/" + t("ROUTES.ACCOUNT")}
            render={() => <AccountView />}
          />

          <AuthRoute
            path={"/" + t("ROUTES.TARGETED_PRODUCTS") + "/:id"}
            render={({ location }) => {
              const query = locationToQuery(location, translations.ROUTES);

              return (
                <MyProductsData.Provider query={query}>
                  <MyProductsView />
                </MyProductsData.Provider>
              );
            }}
          />

          <AuthRoute
            path={"/" + t("ROUTES.MY_PRODUCTS")}
            render={({ location }) => {
              const query = locationToQuery(location, translations.ROUTES);

              return (
                <MyProductsData.Provider
                  name={location.pathname + location.search}
                  query={query}
                >
                  <MyProductsView />
                </MyProductsData.Provider>
              );
            }}
          />

          <Route
            exact
            path={"/" + t("ROUTES.ORDER_HISTORY")}
            render={() => <OrderHistoryView />}
          />

          <Route
            path={"/" + t("ROUTES.ORDER_HISTORY") + "/:id"}
            render={({ match }) => (
              <OrderHistoryDetailView orderId={match.params.id} />
            )}
          />

          <Route
            path={"/nytt_losenord"}
            render={({ match }) => <NewPasswordView />}
          />

          <Route
            path={"/" + t("ROUTES.BRANDS")}
            render={() => (
              <BrandsData.Provider>
                <BrandsView />
              </BrandsData.Provider>
            )}
          />

          <Route
            exact
            path="/nyheter"
            render={({ match, location: { search, state } }) => {
              let { page, tag, category } = parseParams(search);
              const clampedPage = Math.max(parseInt(page, 10) || 1);

              const params = {
                tag: Array.isArray(tag) ? null : tag,
                category: Array.isArray(category) ? null : category,
              };

              return (
                <BlogListData.Provider
                  name={`bloglist_${search}`}
                  page={clampedPage}
                  params={params}
                >
                  <BlogListView page={clampedPage} params={params} />
                </BlogListData.Provider>
              );
            }}
          />

          <Route
            path="/nyheter/:slug"
            render={({ match, location: { search, state } }) => {
              return (
                <BlogData.Provider name={match.params.slug} slug={match.params.slug}>
                  <BlogPostView hint={state && state.hint} />
                </BlogData.Provider>
              );
            }}
          />

          <Route path="/glomtlosenord" component={ForgotPasswordView} />
          <Route path="/om-oss/medarbetare" component={EmployeesView} />
          <Route path="/om-oss" component={AboutUsView} />
          <Route
            exact
            path="/kunder"
            render={() => (
                <TestimonialsData.Provider>
                  <ReferenceCustomersView />
                </TestimonialsData.Provider>
            )}
          />

          <Route path="/kundservice" component={WFMView} />
          <Route path="/bli-leverantor" component={SupplierInquiryView} />

          <Route
            path={["/p", "/" + t("ROUTES.RECOMMENDED")]}
            render={({ location: { pathname, search, state }, history }) => {
              const params = parseParams(search);
              const page = parseInt(params.page, 10) || 1;
              const pages =
                page > 1 ? [page - 1, page, page + 1] : [page, page + 1];
              const { page: _page, ...searchWithoutPage } = params;
              const pageSize = getPageSize(browserWidth);

              return (
                <FilterData.Provider
                  name={pathname + stringifyParams(searchWithoutPage)}
                  location={location}
                  requestedPages={[1]}
                  pageSize={pageSize}
                  previousData={filterCache.current[location.pathname]}
                  setPreviousData={(location, data) =>
                    (filterCache.current[location.pathname] = data)
                  }
                >
                  <FilterView />
                </FilterData.Provider>
              );
            }}
          />

          {/* ugly way to handle the callback on forgot password. Magento sends the user here afterwards */}
          <Route
            path="/customer/account/login"
            component={ForgotPasswordCallback}
          />

          <Route
            path="/(.+)"
            render={({ location: { pathname, search, state }, history }) => {
              const { page } = parseParams(search);

              return (
                <RouteData.Provider
                  location={location}
                  url={pathname}
                  name={`route_${pathname}`}
                  page={page}
                  hint={state && state.hint}
                >
                  <RouteView location={location} history={history} />
                </RouteData.Provider>
              );
            }}
          />
        </Switch>
      </App>
    </Switch>
  );
};

export const Routes = (): React$Node => <Route component={RoutesInner} />;

/* @flow */

import type { Props as ButtonProps } from "../Button";

import React, { useState, useEffect, useRef, memo, useContext } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useTranslate } from "@out-of-home/use-translate";
import { Dialogue } from "../Dialogue";
import { useBrowser } from "../../helpers/use-browser";
import { useCookie } from "../../helpers/use-cookie";
import { useCustomer } from "../../helpers/use-customer";
import { AnalyticsContext } from "../../helpers/analytics/context";
import { Button } from "../Button";
import CookieIcon from "../../icons/cookie.svg";

import styles from "./styles.scss";

type Props = {};

const POPUP_DELAY = 7000;
const COOKIE_INITIAL = "initial";
const COOKIE_ACCEPTED = "accepted";
const COOKIE_DECLINED = "declined";

const PrimaryButton = ({ children, ...rest }: ButtonProps) => (
  <Button {...rest}>
    <div className={styles.primaryButtonInner}>
      <CookieIcon />
      {children}
    </div>
  </Button>
);

export const CookieDialogue: React$AbstractComponent<Props, mixed> = memo(
  (): React$Node => {
    // chrome version >= 104, use 400days as expires/max-age limit https://chromestatus.com/feature/4887741241229312
    const t = useTranslate();
    const browser = useBrowser();
    const domain = browser ? window.location.host.replace(/:\d+$/, "") : "";
    const [consent, setConsent, cookieRead] = useCookie(
      "cookie-consent",
      "initial",
      { domain, expires: 3650 }
    );
    const [open, setOpen] = useState(false);
    const timer = useRef(null);
    const { grantConsent } = useContext(AnalyticsContext);
    const { loggedIn } = useCustomer();

    useEffect(() => {
      return () => {
        if (timer.current) {
          clearTimeout(timer.current);
        }
      };
    }, []);

    // automatically give consent for logged-in customers
    useEffect(() => {
      if (loggedIn && cookieRead && consent !== COOKIE_ACCEPTED) {
        setConsent(COOKIE_ACCEPTED);
        setOpen(false);
      }
    }, [loggedIn, cookieRead, consent]);

    // open popup if consent to cookies havent been answered
    // also grant consent to ga if accepted
    useEffect(() => {
      if (!open && !timer.current && cookieRead && consent === COOKIE_INITIAL) {
        timer.current = setTimeout(() => {
          timer.current = null;
          setOpen(true);
        }, POPUP_DELAY);
      }

      if (cookieRead && consent === COOKIE_ACCEPTED) {
        clearTimeout(timer.current);
        setOpen(false);
        grantConsent();
      }
    }, [consent, cookieRead]);

    const acceptAction = () => {
      setConsent(COOKIE_ACCEPTED);
      setOpen(false);
    };

    const declinedAction = () => {
      setConsent(COOKIE_DECLINED);
      setOpen(false);
    };

    return (
      <>
        {open && (
          <Dialogue
            open={true}
            isolationMode={false}
            setOpen={setOpen}
            className={styles.dialogue}
            wrapperClassName={styles.dialogueWrapper}
            primaryAction={t("COOKIE_DIALOGUE.PRIMARY_ACTION")}
            primaryButton={PrimaryButton}
            onPrimaryAction={acceptAction}
            secondaryAction={t("COOKIE_DIALOGUE.SECONDARY_ACTION")}
            onSecondaryAction={declinedAction}
          >
            {t("COOKIE_DIALOGUE.DESCRIPTION")}
            &nbsp;
            <Link to={t("COOKIE_DIALOGUE.READ_MORE_LINK")}>
              {t("COOKIE_DIALOGUE.READ_MORE_TEXT")}
            </Link>
          </Dialogue>
        )}
      </>
    );
  }
);

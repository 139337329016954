/* @flow */

type Direction =
  | "top"
  | "right"
  | "bottom"
  | "left"
  | "bottom_left"
  | "bottom_right"
  | "top_left"
  | "top_right";

type Props = {
  children?: React$Node,
  className?: string,
  innerClassName?: string,
  title?: string,
  text: string,
  direction?: Direction,
  width?: number,
  viewportMargin?: number,
};

import styles from "./styles.scss";
import React, { useState, useRef, useEffect } from "react";
import { useIsomorphicLayoutEffect } from "../../helpers/use-isomorphic-layout-effect";
import cn from "classnames";

const DECREASE_PIXELS = 2;

export const Tooltip = ({
  children,
  className,
  innerClassName,
  title,
  text,
  direction = "bottom",
  width,
  viewportMargin = 10,
  ...props
}: Props): React$Node => {
  const [visible, setVisible] = useState(false);
  const tooltip = useRef<HTMLDivElement | null>(null);
  const [style, setStyle] = useState({
    width: typeof width !== "undefined" ? `${width}px` : "auto",
    whiteSpace: typeof width !== "undefined" ? "normal" : "nowrap",
  });

  const onEnter = () => {
    setVisible(true);
  };

  const onLeave = () => {
    setVisible(false);
    setStyle({
      width: typeof width === "number" ? `${width}px` : "auto",
      whiteSpace: typeof width !== "undefined" ? "normal" : "nowrap",
    });
  };

  useIsomorphicLayoutEffect(() => {
    calculateTooltipPosition();
  }, [visible]);

  const calculateTooltipPosition = () => {
    if (
      visible &&
      tooltip.current &&
      (direction === "top" || direction === "bottom")
    ) {
      const tooltipBoundaries = tooltip.current.getBoundingClientRect();
      const bodyBoundaries = document.body
        ? document.body.getBoundingClientRect()
        : { width: 0, top: 0, left: 0, height: 0 };
      const viewportBoundaries = {
        top: bodyBoundaries.top + viewportMargin,
        right: bodyBoundaries.width - viewportMargin,
        bottom: bodyBoundaries.height - viewportMargin,
        left: viewportMargin,
      };

      const isOutsideLeft = tooltipBoundaries.left < viewportBoundaries.left;
      const isOutsideRight =
        tooltipBoundaries.left + tooltipBoundaries.width >
        viewportBoundaries.right;

      if (isOutsideLeft || isOutsideRight) {
        const viewportWidth =
          viewportBoundaries.right - viewportBoundaries.left;
        let newTooltipWidth = tooltipBoundaries.width;

        // Resize tooltip width if to large
        if (tooltipBoundaries.width > viewportWidth) {
          newTooltipWidth = viewportWidth - DECREASE_PIXELS;
        }

        const offset = isOutsideLeft
          ? tooltipBoundaries.width / 2 -
            (Math.abs(tooltipBoundaries.left) + viewportMargin)
          : viewportBoundaries.right -
            (tooltipBoundaries.left + tooltipBoundaries.width / 2);

        setStyle({
          ...style,
          position: "absolute",
          width: `${newTooltipWidth}px`,
          whiteSpace: "normal",
          left: isOutsideLeft ? `-${offset}px` : "auto",
          right: isOutsideLeft ? "auto" : `-${offset}px`,
        });
      }
    }
  };

  useEffect(() => {
    if (process.browser) {
      // hookup listeneres on resize event
      window.addEventListener("resize", onLeave);

      // remove listeners
      return () => {
        if (onLeave) {
          window.removeEventListener("resize", onLeave);
        }
      };
    }
  }, []);

  return (
    <div
      {...props}
      className={cn(styles.block, [styles["block__" + direction]], className)}
      tabIndex={0}
      onFocus={onEnter}
      onBlur={onLeave}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
    >
      {visible && (
        <div className={cn(styles.tooltip, innerClassName)}>
          <div style={{ position: "relative" }}>
            <div ref={tooltip} style={style} className={styles.tooltipInner}>
              {typeof title !== "undefined" && title !== "" && (
                <div className={styles.title}>{title}</div>
              )}
              {text}
            </div>
          </div>
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};
